<template>
  <div class="engagement-campaigns-container">
    <my-campaigns
      :loading="loading"
      :coin="coinData"
      :campaigns="campaigns.data"
      :paginate="campaigns"
      @link-click="handleLinkClick"
      @update-campaign="handleUpdateCampaign"
      @new-order="handleNewCampaign"
      @confirm-cancel="handleCancelOrder"
      @submit-search="handleGetCampaigns"
    />
    <onboarding-card
      v-if="showOnboarding"
      :steps="onboardingSteps"
    />
    <campaign-details-modal
      :show-modal="showCampaignDetailsModal"
      :coin="coinData"
      :campaign="campaign"
      @close="showCampaignDetailsModal = false"
    />
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
import MyCampaigns from '~/components/Engagement/Campaigns/MyCampaigns'
import OnboardingCard from '~/components/General/OnboardingCard'
import CampaignDetailsModal from '~/components/Engagement/Campaigns/Details/CampaignDetailsModal'
import {
  COIN,
  GET_COIN,
  FEEDBACK,
  COMPANY,
  UPDATE_ENGAGEMENT_CAMPAIGN,
  GET_ENGAGEMENT_CAMPAIGNS,
  ENGAGEMENT_CAMPAIGNS,
  ENGAGEMENT_CAMPAIGNS_STEP,
  GET_ENGAGEMENT_CAMPAIGN_DETAILS
} from '@/store/types'

export default {
  name: 'Campaigns',
  components: { MyCampaigns, OnboardingCard, CampaignDetailsModal },
  data () {
    return {
      campaign: {},
      lastFilters: null,
      loading: true,
      hasSomeSearchSubmitted: false,
      showCampaignDetailsModal: false,
      onboardingSteps: [
        {
          icon: 'pencil-square1',
          title: 'Criação',
          content: 'Primeiro, você deve criar a sua campanha clicando no botão "Nova Campanha". O processo é bem simples, você precisa preencher informações como: nome, descrição, recompensa, duração e uma imagem personalizada.'
        },
        {
          icon: 'users',
          iconSize: '35',
          title: 'Divulgação no Clube',
          content: 'Após a criação, sua campanha estará disponível em seu clube para os usuários. Lá eles irão visualizar todas as informações que você preencheu.'
        },
        {
          icon: 'ph-coins-light',
          title: 'Distribuição de Moedas',
          content: 'Você poderá distribuir moedas para os usuários que realizaram a proposta da sua campanha. Assim, os ganhadores poderão utilizar as moedas para resgatar gift cards no seu clube.'
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      campaigns: ENGAGEMENT_CAMPAIGNS,
      coinData: COIN,
      company: COMPANY
    }),
    showOnboarding () {
      return !(this.loading || (this.campaigns.data && this.campaigns.data.length) || this.hasSomeSearchSubmitted)
    }
  },
  async mounted () {
    this.loading = true
    await this.getCoin()
    await this.handleGetCampaigns()
    this.loading = false
  },
  methods: {
    ...mapMutations({
      updateEngagementStep: ENGAGEMENT_CAMPAIGNS_STEP
    }),
    ...mapActions({
      feedback: FEEDBACK,
      getCampaigns: GET_ENGAGEMENT_CAMPAIGNS,
      getCampaignDetails: GET_ENGAGEMENT_CAMPAIGN_DETAILS,
      updateCampaign: UPDATE_ENGAGEMENT_CAMPAIGN,
      getCoin: GET_COIN
    }),
    handleAnalytics (event, data) {
      window.dataLayer.push(
        {
          event,
          ...data
        }
      )
    },
    async handleLinkClick (campaign) {
      window.dataLayer.push({
        event: 'pontos-campanhas-listagem-detalhes'
      })
      const campaignInfo = await this.getCampaignDetails({
        id: campaign.id
      })
      if (Object.keys(campaignInfo).length > 0) {
        this.campaign = campaignInfo
        this.showCampaignDetailsModal = true
      }
    },
    handleNewCampaign () {
      this.updateEngagementStep(1)
      this.handleAnalytics('pontos-campanha-criacao', { company_name: this.company.slug })
      this.$router.push('/engajamento/campanhas/nova-campanha')
    },
    async handleCancelOrder (id) {
      this.loading = true
      await this.cancelOrder({ id })
      this.loading = false
    },
    async handleUpdateCampaign (campaign) {
      window.dataLayer.push({
        event: 'pontos-campanhas-listagem-toggle'
      })
      await this.updateCampaign(campaign)
      await this.handleGetCampaigns(this.lastFilters)
    },
    async handleGetCampaigns (filters) {
      this.lastFilters = filters
      if (filters) { this.hasSomeSearchSubmitted = true }
      await this.getCampaigns(filters)
        .catch(() => {
          this.feedback({
            type: 'error',
            title: 'Houve um erro ao carregar as campanhas',
            text: 'Tente novamente mais tarde'
          })
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.engagement-campaigns-container {
  > .my-campaigns {
    margin-bottom: 48px;
  }
}
</style>
