<template>
  <div class="container">
    <h2
      class="title"
      data-cy="enviroment-container-title"
    >
      Seu ambiente
    </h2>
    <div class="domain">
      <h2>URL</h2>
      <h3>Esse é o endereço que seus usuários usarão para acessar seu clube</h3>
      <div class="selection-buttons">
        <div
          v-for="(item, index) in domainOptions"
          :key="index"
          :class="buttonClass(item)"
          @click="selectItem(index)"
        >
          {{ item.name }}
        </div>
        <premium-tag v-if="company.trial" />
      </div>
    </div>
    <div class="url">
      <h2>URL do seu clube</h2>
      <url-input
        :value="data.url"
        @input="e => data.url = e"
        :disabled="disabled"
        data-cy="url-input"
      />
    </div>
    <div class="name">
      <h2>Nome do seu clube</h2>
      <h3>Nome para controle interno</h3>
      <c-input
        :disabled="disabled"
        class="input"
        placeholder="Ex: Meu Clube"
        label="Nome do seu clube"
        v-model="data.name"
        data-cy="clube-name-input"
      />
    </div>
    <warn-label
      class="warn"
      :updated="company.trial"
      :type="disabled ? 2 : 1"
      data-cy="warn-label"
    />
    <footer-buttons
      v-if="!disabled"
      @save="confirmUpdate = true"
      :is-valid="!isValid"
      :is-processing="loading || !isValid"
      @back="$router.push('/configuracao')"
    />
    <confirm-modal
      :is-opened="confirmUpdate"
      @confirm="save()"
      @close="confirmUpdate = false"
    />
  </div>
</template>

<script>
import PremiumTag from '@/components/Settings/general/PremiumTag'
import UrlInput from '@/components/Settings/UrlInput'
import WarnLabel from '@/components/Settings/general/WarnLabel'
import FooterButtons from '@/components/Settings/general/FooterButtons'
import ConfirmModal from '@/components/Settings/general/ConfirmModal.vue'
import { COMPANY, COMPANY_SETTINGS } from '@/store/types'
import { mapGetters, mapActions } from 'vuex'
export default {
  components: {
    PremiumTag,
    UrlInput,
    WarnLabel,
    FooterButtons,
    ConfirmModal
  },

  data () {
    return {
      loading: false,
      data: {},
      domainOptions: [
        {
          name: 'utilizar domínio kaledo',
          disabled: false,
          type: 'kaledo',
          selected: true
        },
        {
          name: 'utilizar seu dominio',
          disabled: true,
          type: 'proper',
          selected: false
        }
      ],
      confirmUpdate: false
    }
  },

  computed: {
    ...mapGetters({
      company: COMPANY
    }),

    isValid () {
      const conditions = [
        (this.data && this.data.name && this.data.name.length > 4),
        (this.data && this.data.url && this.data.url.length > 5)
      ]
      return conditions[0] && conditions[1]
    },

    disabled () {
      return !!(this.company.url && this.company.url.length > 5)
    }
  },

  mounted () {
    this.data = JSON.parse(JSON.stringify(this.company))
    if (this.data.url && !this.data.url.includes('.kaledo')) {
      this.domainOptions[0].selected = false
    } else if (this.data.url && this.data.url.includes('.kaledo')) {
      const idx = this.data.url.indexOf('.kaledo.com.br')
      this.data.url = this.data.url.slice(-this.data.url.length, idx)
    }
    if (this.disabled) this.domainOptions[0].disabled = true
  },

  methods: {
    ...mapActions({
      companySettings: COMPANY_SETTINGS
    }),

    selectItem (index) {
      if (!this.domainOptions[index].disabled) {
        this.domainOptions.forEach(domain => {
          domain.selected = false
        })
        this.domainOptions[index].selected = true
      }
    },

    buttonClass (obj) {
      return 'selection-button ' +
      (obj.selected ? ' --selected' : '') +
      (obj.disabled ? ' --disabled' : '') +
      (obj.disabled && obj.type === 'kaledo' ? ' --blocked' : '')
    },

    async save () {
      this.loading = true
      if (this.domainOptions[0].selected) this.data.url = `https://${this.data.url}.kaledo.com.br`
      const response = await this.companySettings(this.data)
      if (response === 'success') {
        this.$router.push('/configuracao')
      }
      this.loading = false
    }
  }
}
</script>

<style lang='scss' scoped>
@import '~@/styles/reference.scss';
.container {
  max-width: 1020px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 48px;
  & > .title {
    color: $neutral-dark-blue;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Nunito Sans;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
    padding-bottom: 48px;
  }
  & > .domain {
    & > h2 {
      color: $neutral-gray-200;
      font-feature-settings: 'clig' off, 'liga' off;
      font-family: Nunito Sans;
      font-size: 16px;
      font-style: bold;
      font-weight: 700;
      line-height: 140%;
      margin-bottom: 8px;
    }
    & > h3 {
      color: $neutral-dark-blue;
      font-feature-settings: 'clig' off, 'liga' off;
      font-family: Nunito Sans;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%;
    }
     & > .selection-buttons {
      display: flex;
      align-items: flex-end;
      margin: 24px 0;
      & > .selection-button {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 8px;
        border-radius: 8px;
        border: 1px solid $neutral-gray-200;
        background: #FFF;
        width: 207px;
        height: 40px;
        color: $neutral-gray-200;
        text-align: center;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: Nunito Sans;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 16px;
        text-transform: uppercase;
        &:hover {
          cursor: pointer;
        }
      }
      & > .selection-button:first-child {
        margin-right: 24px !important;
      }
      & > .--selected {
        border: 2px solid $base-color !important;
        background: $neutral-light-white !important;
        color: $neutral-dark-blue;
      }

      & > .--disabled {
        border: 1px solid $feedback-inactive-50 !important;
        background: #FFF !important;
        color: $feedback-inactive-50;
        &:hover {
          cursor: not-allowed !important;
        }
      }

      & > .--blocked {
        color: $neutral-gray-200 !important;
        border: 2px solid $neutral-gray-200 !important;
        background: $feedback-inactive-50 !important;
        &:hover {
          cursor: not-allowed !important;
        }
      }
    }
  }
  & > .url {
    margin-bottom: 48px;
    & > h2 {
      margin-bottom: 8px;
      color: $neutral-gray-200;
      font-feature-settings: 'clig' off, 'liga' off;
      font-family: Nunito Sans;
      font-size: 11px;
      font-style: normal;
      font-weight: 400;
      line-height: 13.5px;
      letter-spacing: 0.5px;
      text-transform: uppercase;
    }

    & > .input {
      width: 247px;
    }
  }
  & > .name {
    margin-bottom: 48px;
    & > h2 {
      margin-bottom: 8px;
      color: $neutral-gray-200;
      font-family: Nunito Sans;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 140%;
    }
    & > h3 {
      color: $neutral-dark-blue;
      font-feature-settings: 'clig' off, 'liga' off;
      font-family: Nunito Sans;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%;
      margin-bottom: 24px;
    }
    & > .input {
      width: 247px;
    }
  }
  & > .warn {
    margin: 48px 0;
  }
}

</style>
