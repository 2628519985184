<template>
  <c-modal
    v-bind="$attrs"
    v-on="$listeners"
    class="award-campaign-info-modal"
    name="award-campaign-info-modal"
  >
    <c-title
      data-cy="awards-campaign-info-modal-title"
      secondary-icon
      icon="award-star"
    >
      Campanha de premiação
    </c-title>
    <div class="modal-content">
      <h5 class="subtitle">
        O que é?
      </h5>
      <p>
        Campanha é uma maneira de agrupar pedidos com o mesmo objetivo, por isso, todos os pedidos inseridos em uma campanha utilizam das mesmas comunicações (email, pop-up e notificação).
      </p>
    </div>
  </c-modal>
</template>

<script>
import CModal from '@/components/CComponents/CModal'

export default {
  components: { CModal }
}
</script>

<style lang="scss">
@import '~@/styles/reference';

.award-campaign-info-modal {
  background-color: #FFF !important;
  z-index: 30 !important;

  > .content {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    padding-bottom: 20px;

    @include mobile { justify-content: center; }

    .c-title {
      margin-bottom: 30px;

      .icon {
        font-size: 20px;
        margin-right: 10px;
      }

    }

    .modal-content {
      display: flex;
      flex-direction: column;

      > .subtitle {
        font-weight: 400;
        font-size: 14px;
        color: #5E6684;
        text-transform: uppercase;
        margin-bottom: 8px;
      }

      > p {
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        color: #202950;

        &:not(:last-child) {
          margin-bottom: 40px;
        }
      }
    }
  }
}
</style>
