<template>
  <c-modal
    v-bind="$attrs"
    v-on="$listeners"
    :is-fullscreen="false"
    class="report-modal"
    name="report-modal"
  >
    <div class="report-modal-content">
      <c-character
        class="character"
        type="happy"
      />
      <div class="text">
        <c-title
          :show-border="false"
          class="title"
        >
          O relatório será enviado para o seu e-mail!
        </c-title>
        <p class="paragraph">
          {{ text }}
        </p>
      </div>

      <div
        class="select-content"
        v-if="isPeriodic"
      >
        <c-radio-group
          v-if="isPeriodic"
          class="radio-input"
          required
          inline
          :options="options"
          :value="period"
          @input="$emit('update', $event)"
        />

        <div class="dates">
          <c-dashed-input
            class="input"
            :disabled="period !== 'sp'"
            icon="pencil"
            icon-size="15"
            placeholder="DD/MM/AAAA"
            mask="##/##/####"
            label="DATA INICIAL"
            name="start_date"
            v-model="startDate"
            autocomplete="off"
            :validation="startValidationMessage"
            @input="validateDate(startDate, 'start')"
          />

          <c-dashed-input
            class="input"
            :disabled="period !== 'sp'"
            icon="pencil"
            icon-size="15"
            placeholder="DD/MM/AAAA"
            mask="##/##/####"
            label="DATA FINAL"
            name="end_date"
            v-model="endDate"
            autocomplete="off"
            :validation="endValidationMessage"
            @input="validateDate(endDate, 'end')"
            style="padding-left: 15px;"
          />
        </div>
      </div>

      <c-button
        class="action"
        primary
        size="lg"
        @click="sendPeriod()"
        :disabled="!period && isPeriodic"
      >
        Enviar
      </c-button>
    </div>
  </c-modal>
</template>

<script>
import CCharacter from '@/components/CComponents/CCharacter'
import CModal from '@/components/CComponents/CModal'
import CRadioGroup from '@/components/CComponents/CRadioGroup'
import CDashedInput from '@/components/CComponents/CDashedInput'
import moment from 'moment'

export default {
  components: {
    CCharacter,
    CModal,
    CRadioGroup,
    CDashedInput
  },

  props: {
    isPeriodic: Boolean,
    options: {
      type: Array,
      required: true
    },
    period: String
  },

  data () {
    return {
      startDate: '',
      endDate: '',
      startValidationMessage: null,
      endValidationMessage: null,
      startValid: true,
      endValid: true
    }
  },

  computed: {
    text () {
      return this.isPeriodic
        ? 'Para continuar, selecione o período do relatório que deseja gerar ou, para cancelar, clique em fechar.'
        : 'Para prosseguir com a solicitação clique em "enviar". Para cancelar, basta fechar esta janela.'
    }
  },

  methods: {
    validateDate (date, field) {
      if (date.length === 10) {
        const formatedDate = new Date(moment(date, 'DD/MM/YYYY').format('MM/DD/YYYY'))

        if (isNaN(formatedDate)) {
          if (field === 'start') {
            this.startValid = false
            return this.startValidationMessage = 'Data inválida'
          } else {
            this.endValid = false
            return this.endValidationMessage = 'Data inválida'
          }
        } else if (field === 'start' && !isNaN(formatedDate) && this.endDate.length === 10) {
          this.checkStart(formatedDate)
        } else if (field === 'end' && !isNaN(formatedDate) && this.startDate.length === 10) { this.checkEnd(formatedDate) }

        if (this.startValid) this.startValidationMessage = null
        if (this.endValid) this.endValidationMessage = null
      }

      field === 'start'
        ? this.startValid = true
        : this.endValid = true
    },

    checkStart (formatedDate) {
      if (formatedDate > new Date(moment(this.endDate, 'DD/MM/YYYY').format('MM/DD/YYYY'))) {
        this.startValid = false
        return this.startValidationMessage = 'Data inválida'
      }
    },

    checkEnd (formatedDate) {
      if (formatedDate < new Date(moment(this.startDate, 'DD/MM/YYYY').format('MM/DD/YYYY'))) {
        this.endValid = false
        return this.endValidationMessage = 'Data inválida'
      }
    },

    sendPeriod () {
      if (this.period === 'sp') {
        const selectedPeriod = {
          period: 'sp',
          start: moment(this.startDate, 'DD/MM/YYYY').format('YYYY-MM-DD'),
          end: moment(this.endDate, 'DD/MM/YYYY').format('YYYY-MM-DD')
        }
        this.$emit('submit', selectedPeriod)
      } else {
        this.$emit('submit', this.period)
      }
    }
  }
}
</script>

<style lang="scss">
@import '~@/styles/reference';

.report-modal.c-modal {
  @include mobile {
    margin: 0 10px;
    max-height: 100%;
  }

  @include desktop {
    max-width: 600px;
  }
}

.report-modal-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;

  @include desktop {
    padding: 10px 50px;
  }

  & > .character {
    width: 100px;
    height: 100px;
    padding: 50px;
  }

  & >.text {
    margin: 30px 0;

    .c-title {
      .text {
        text-align: center;
        justify-content: center;
      }
    }

    & >.title {
      margin-bottom: 15px;
    }

    & > .paragraph {
      text-align: center;
    }
  }

  & > .select-content {
    width: 100%;
    position: relative;
    padding-bottom: 40px;

    & > .dates {
      display: flex;
      position: absolute;
      top: 65px;
      right: 15px;
      width: 238px;
      font-size: 12px;

      & > .date-field {
        width: 100px;
        height: 40px;
      }
    }
  }

  & > .radio-input {
    margin-bottom: 15px;

    & > .option {
      margin-left: 10px;
    }
  }

  & > .action {
    padding: 0 35px;
  }
}
</style>
