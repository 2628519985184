<template>
  <awards-new-orders-container />
</template>

<script>
import AwardsNewOrdersContainer from '~/containers/Awards/AwardsNewOrdersContainer'
export default {
  components: { AwardsNewOrdersContainer }
}
</script>

<style>

</style>
