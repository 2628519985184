<template>
  <component
    v-if="!isLoading"
    :is="!isDesktop ? 'c-modal' : 'div'"
    is-opened
    name="user-import-create"
    class="user-import-create"
    :btn-props="{ defaultBorder: true }"
    @close="$router.push({ name: 'usuarios-importacao' })"
  >
    <c-title
      class="title"
      icon="import"
    >
      Importação de usuário
    </c-title>

    <user-import-step
      step="1"
      :title="texts.type"
    >
      <user-import-type-selection v-model="type" />
    </user-import-step>

    <user-import-step
      step="2"
      :title="secondStepText"
    >
      <user-import-form
        v-if="!isFile"
        @update-document="updateDocument"
        @update-documents="v => documents = [ ...v ]"
      />

      <user-import-file-selection
        v-else
        @input="v =>
          file = v && v.length
            ? (v[0] || {}).file
            : null"
        @download-cpfs-enabled="downloadCpfsEnabled"
      />
    </user-import-step>

    <user-import-confirmation
      is-manual
      class="confirmation"
      v-if="!!status"
      :is-opened="!!status"
      :status="status"
      @procceed="procceed"
      @close="procceed"
    />

    <div class="actions">
      <c-button
        v-if="isDesktop || file || documents.length"
        primary
        class="submit"
        size="lg"
        :block="!isDesktop"
        :disabled="isDesktop && disabled || loading"
        @click="handleSubmit"
      >
        Importar usuários
      </c-button>
    </div>
  </component>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import * as types from '@/store/types'
import Loadable from '@/modules/loadable'

const texts = {
  type: 'Selecione a forma de importação que deseja',
  file: 'Selecione o arquivo csv para importação ou baixe o template',
  form: 'Insira os CPFs dos usuários que deseja habilitar'
}

export default {
  components: {
    UserImportStep: () => import('@/components/User/Import/UserImportStep'),
    UserImportTypeSelection: () => import('@/components/User/Import/UserImportTypeSelection'),
    UserImportFileSelection: () => import('@/components/User/Import/UserImportFileSelection'),
    UserImportForm: () => import('@/components/User/Import/UserImportForm'),
    UserImportConfirmation: () => import('@/components/User/Import/UserImportConfirmation'),
    CModal: () => import('@/components/CComponents/CModal')
  },
  mixins: [Loadable],
  data () {
    return {
      texts,
      type: 'update',
      documents: [],
      file: null,
      status: undefined,
      loading: false
    }
  },
  computed: {
    ...mapGetters({ company: types.COMPANY }),
    secondStepText () {
      return this.texts[
        this.isFile
          ? 'file'
          : 'form'
      ]
    },
    disabled () {
      return this.isFile
        ? !this.file
        : !this.documents.length
    },
    isFile () {
      return this.type !== 'manual'
    }
  },
  mounted () {
    if (this.company.trial) {
      this.type = 'manual'
    }
  },
  methods: {
    downloadCpfsEnabled () {
      this.getCpfsEnabled()
    },
    updateDocument ({ index, value }) {
      const updatedDocuments = [...this.documents]
      updatedDocuments[index] = value
      this.documents = [...updatedDocuments]
    },
    async handleSubmit () {
      this.loading = true
      await this.submit()
      this.loading = false
    },
    async submit () {
      let status

      if (this.isFile) {
        status = await this.importFile({
          type: this.type === 'update'
            ? 0
            : 1,
          file: this.file
        })
      } else {
        status = await this.importManual({
          documents: this.documents.filter(_ => !!_ && _.length)
        })
      }

      if (status) {
        if (this.file) { this.$router.push({ name: 'usuarios-importacao' }) } else {
          this.status = Object.keys(status).reduce((statuses, item) => ({
            ...statuses,
            [item]: typeof status[item] === 'number'
              ? status[item]
              : (status[item] || []).length || 0
          }), {})
        }
      }
      this.handleAnalytics('botao-importar-confirmar', { company_name: this.company.name })
    },
    procceed () {
      this.status = undefined
      this.$router.push({ name: 'usuarios-importacao' })
    },
    async load () {
      const isProcessing = await this.getProcessingStatus()

      if (isProcessing) { this.$router.push({ name: 'usuarios-importacao' }) }
    },
    ...mapActions({
      importFile: types.USER_IMPORT_FILE,
      importManual: types.USER_IMPORT_MANUAL,
      getProcessingStatus: types.USER_IMPORT_PROCESSING,
      getCpfsEnabled: types.USER_IMPORT_DOWNLOAD_CPFS_ENABLED
    }),
    handleAnalytics (event, data) {
      window.dataLayer.push(
        {
          event,
          ...data
        }
      )
    }
  }
}
</script>

<style lang="scss">
@import '~@/styles/reference';

.user-import-create {
  overflow: auto;
  -webkit-overflow-scrolling: touch;

  @include container;

  & > .title {
    margin-bottom: 30px;

  }

  & > .c-modal{
    padding-left: 5px;
    padding-right: 5px;

    & > .content {
      padding-top:  10px;

      & > .title {
        margin-bottom: 30px;
        padding: 10px 5px 0 5px;
      }
    }
  }
  & > .actions {
    border-top: $border;
    padding: 20px 0;
    margin: 20px 0;
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-end;
    align-items: center;

    @include mobile {
      justify-content: center;
      width: calc(100% - 20px)
    }

    & > * + * { margin-left: 10px; }
  }
}
</style>
