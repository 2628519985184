<template>
  <div
    :class="'card-container' + setStyle()"
    data-cy="config-card"
  >
    <div class="left-container">
      <span class="card-title">
        <k-icon
          class="icon"
          :icon="settings.titleIcon"
          size="20"
        />
        <span class="title">{{ settings.title }}</span>
      </span>
      <ul>
        <li
          v-for="(item, index) in settings.itens"
          :key="index"
        >
          {{ item }}
        </li>
      </ul>
    </div>
    <div class="right-container">
      <k-button
        class="button"
        icon="pen-edit5"
        data-cy="edit-button"
        @click.native.stop="$router.push('configuracao/' + settings.route )"
        no-icon
      >
        Editar
        <k-icon
          class="button-icon"
          icon="pen-edit5"
          size="20"
        />
      </k-button>
    </div>
  </div>
</template>

<script>
import { KButton, KIcon } from 'kaledo-components'
export default {
  components: {
    KButton,
    KIcon
  },
  props: {
    settings: Object,
    cardIndex: Number
  },
  methods: {
    setStyle () {
      const isInt = ((this.cardIndex + 1) % 2)
      return isInt === 0 ? ' -rigth-card' : ''
    }
  }
}
</script>

<style lang='scss' scoped>
@import '~@/styles/reference.scss';

.card-container {
  display: flex;
  padding: 24px;
  align-items: flex-start;
  width: 499px;
  height: 146px;
  border-radius: 8px;
  border: 1px solid $neutral-gray-100;
  background: $neutral-light-white;

  @include desktop {
    max-width: 479px;
  }
  @include tablet {
    max-width: 439px;
    width: 100%;
  }

  & > .left-container {
    width: 100%;

    & > .card-title {
      display: flex;
      & > .title {
        color: $neutral-dark-blue;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: Nunito Sans;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 120%;
      }
      & > .icon {
        padding-right: 8px;
      }
    }

    & > ul {
      padding-left: 24px;

      & > li {
        padding-top: 16px;
        color: $neutral-gray-200;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: Nunito Sans;
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 140%;
      }
    }
  }

  & > .right-container {
    width: 111px;
    & > .button {
      width: 111px;
      height: 40px;
      color: $neutral-gray-200;
      border-radius: 32px;
      border: 1px solid $neutral-gray-200;
      background: #FFF;
      font-family: Nunito Sans;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
      text-transform: uppercase;
      display: flex;
      align-items: center;
      transition: 0.1s;
      &:hover {
        border: 2px solid rgba(94, 102, 132, 1);
        color: $primary-color-placeholder;
      }
      .button-icon {
        margin-left: 8px;
      }
    }
  }
}
</style>
