<template>
  <div class="modal-info-container">
    <button
      class="icon-button"
      :class="info ? '-clicable' : ''"
      @click="showModal = !showModal"
    >
      <k-icon
        class="icon"
        :icon="icon"
        :is-colorful="isColorful"
        :number-of-colors="3"
      />
    </button>
    <transition
      name="fade"
    >
      <div
        v-if="modal && showModal && info"
        class="modal-info"
      >
        <div class="modal-header">
          <h6 class="title">
            <k-icon
              icon="-email-mail-info"
              class="header-icon"
            />
            {{ info.title }}
          </h6>
        </div>
        <div class="modal-body">
          <p class="text">
            {{ info.text }}
          </p>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { KIcon } from 'kaledo-components'

export default {
  name: 'InfoButton',
  components: {
    KIcon
  },
  props: {
    icon: String,
    modal: {
      type: Boolean,
      default: true
    },
    info: Object,
    isColorful: {
      type: Boolean,
      default: false
    },
    numberOfColors: {
      type: Number,
      default: 3
    }
  },
  data () {
    return {
      showModal: false
    }
  }
}
</script>

<style lang='scss' scoped>
@import '~@/styles/reference.scss';

.modal-info-container {
  position: relative;

  .icon-button {
    background: none;
    border: none;
    &.-clicable {
      cursor: pointer;
    }
    .icon {
      color: #99A4D6;
      transition: all ease 0.3s;
      &:hover {
        color: $base-color;
      }
    }
  }

  .modal-info {
    position: absolute;
    z-index: 5;
    top: 20px;
    right: 0px;
    width: 100%;
    height: 90px;
    background: white;
    min-width: 300px;
    max-width: 500px;
    padding: 8px 16px;
    border-radius: 8px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.16);
    overflow-y: auto;
    text-transform: initial;
    text-align: left;
    @include mobile {
      min-width: 150px;
    }
    @include tablet {
      min-width: 150px;
    }
    .modal-header {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      .header-icon {
        color: #00B2E3;
        font-size: 14px;
        padding-right: 4px;
      }
      .title {
        font-size: 12px;
        line-height: 14px;
        font-weight: 600;
        color: $base-color;
      }
      @include mobile {
        .header-icon {
          font-size: 14px;
        }
        .title {
          font-size: 12px;
        }
      }
      @include tablet {
        .header-icon {
          font-size: 14px;
        }
        .title {
          font-size: 12px;
        }
      }
    }

    .modal-body {
      margin-top: 8px;
      width: 100%;
      .text {
        font-size: 12px;
        color: #5E6684;
        @include mobile {
          font-size: 10px;
        }
        @include tablet {
          font-size: 10px;
        }
      }
    }
  }
}

</style>
