<template>
  <c-modal
    class="sheets-instruction-modal"
    name="sheets-instruction-modal"
    :is-opened="isOpened"
    @close="$emit('close')"
  >
    <c-title
      class="title"
      size="3"
      data-cy="sheets-instruction-modal-title"
    >
      Inserindo usuários via planilha
    </c-title>
    <div class="content">
      <instruction
        :step="1"
      >
        <span class="text-container">
          Baixe a
          <a
            data-cy="model-download-link"
            class="link"
            href="https://clube-newcore.s3.us-west-2.amazonaws.com/public-content/model-importacao-premios.csv"
          >
            planilha modelo
          </a>
          <k-icon
            icon="download-circle"
            class="icon"
          />
        </span>
      </instruction>
      <instruction
        :step="2"
        :text="`Insira o CPF e email dos usuários ${usersText} em cada linha, como no exemplo abaixo.`"
      />
      <img
        data-cy="example-image"
        class="example-image"
        src="/img/sheets-example.png"
        :alt="`Exemplo de usuários ${usersText} no padrão: 'cpf, email, linha 1: cpf com apenas números, email etc'`"
      />

      <instruction
        :step="3"
        text="Após preencher, salve a planilha no seu computador. No seu pedido, importe o documento e clique em Enviar."
      />
    </div>
  </c-modal>
</template>

<script>
import { KIcon } from 'kaledo-components'
import CModal from '@/components/CComponents/CModal'
import Instruction from '@/components/General/Instruction'

export default {
  name: 'SheetsInstructionModal',
  components: {
    CModal,
    Instruction,
    KIcon
  },
  props: {
    isOpened: {
      type: Boolean,
      required: true
    },
    useCpfMask: {
      type: Boolean,
      default: true
    },
    isAwards: Boolean,
    users: Array
  },
  computed: {
    usersText () {
      return this.isAwards ? 'premiados' : 'recompensados'
    }
  },
  methods: {
    handleClick () {
      this.$emit('delete-users')
      this.$emit('close')
    }
  }
}
</script>

<style lang='scss' scoped>
@import '~@/styles/reference';

.sheets-instruction-modal {
  .link {
    cursor: pointer;
  }
  :deep .c-modal {
    max-height: 600px !important;
    max-width: 432px;

    .title {
      margin-bottom: 20px;
    }
    .example-image {
      margin: 24px 0;
    }
  }
}
</style>
