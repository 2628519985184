<template>
  <c-modal
    v-bind="$attrs"
    v-on="$listeners"
    class="account-creation-error-modal"
    name="account-creation-error-modal"
  >
    <div class="modal-content">
      <h2 class="title">
        {{ modalTitle }}
      </h2>
      <p class="description">
        {{ modalDescription }}
      </p>
      <k-button
        class="action-button"
        no-icon
        @click.native="$emit('action-click')"
      >
        {{ actionButtonText }}
      </k-button>
    </div>
  </c-modal>
</template>

<script>
import { KButton } from 'kaledo-components'
import CModal from '@/components/CComponents/CModal'

export default {
  components: { KButton, CModal },
  props: {
    error: String
  },
  computed: {
    modalTitle () {
      switch (this.error) {
      case 'company_exists':
        return 'O CNPJ informado já foi utilizado'
      case 'user_exists':
        return 'Cadastro existente'
      case 'email':
        return 'E-mail inválido'
      default:
        return 'Erro no cadastro'
      }
    },
    modalDescription () {
      switch (this.error) {
      case 'company_exists':
        return 'Se você possui interesse em nossos serviços, entre em contato com o nosso time de vendas.'
      case 'user_exists':
        return 'Encontramos um cadastro com os seus dados em nossa base. Faça seu login utilizando o seu e-mail ou entre em contato com o nosso suporte.'
      case 'email':
        return 'Apenas e-mails corporativos são aceitos.'
      default:
        return 'Tente novamente mais tarde ou entre em contato com o nosso suporte.'
      }
    },
    actionButtonText () {
      switch (this.error) {
      case 'company_exists':
        return 'Falar com vendas'
      case 'user_exists':
        return 'Falar com suporte'
      case 'email':
        return 'Tentar novamente'
      default:
        return 'Falar com suporte'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/styles/reference';

.account-creation-error-modal {
  background-color: #FFF !important;
  z-index: 30 !important;

  > .content {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    padding-bottom: 20px;

    @include mobile { justify-content: center; }

    .modal-content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 350px;

      @include mobile { height: 100%; }

      .title {
        color: #202950;
        text-align: center;
        font-size: 24px;
        font-weight: 500;
        opacity: 0.9;
        margin-bottom: 8px;
      }

      .description {
        color: #202950;
        text-align: center;
        font-size: 16px;
        font-weight: 400;
        margin-bottom: 24px;
      }

      .action-button {
        height: 56px;
        border-radius: 8px;
        background:  #FFD200;
        color: #202950;
        box-shadow: 0px 4px 8px 0px rgba(140, 146, 177, 0.24);

        &:hover {
          opacity: 0.8;
        }
      }
    }
  }
}
</style>
