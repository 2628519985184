<template>
  <div :class="['dashed-input-container', { '-disabled': disabled}]">
    <c-input-container
      v-bind="$attrs"
      :label="label"
      :validation="validation"
      is-dashed-input
    >
      <div class="input-wrapper">
        <input
          class="input"
          :placeholder="placeholder"
          :disabled="disabled"
          :type="type"
          v-mask="mask"
          v-bind="$attrs"
          :value="value"
          @input="$emit('input', $event.target.value)"
        />

        <c-icon
          v-if="icon"
          class="icon"
          :icon="icon"
          :size="iconSize"
        />
      </div>
    </c-input-container>
  </div>
</template>

<script>
import { mask } from 'vue-the-mask'
import CInputContainer from '@/components/CComponents/CInputContainer'

export default {
  components: {
    CInputContainer
  },

  directives: {
    mask (el, binding) {
      if (!binding.value || !binding.value.length) return

      return mask(el, binding)
    }
  },

  props: {
    label: String,
    required: Boolean,
    mask: [String, Array],
    placeholder: String,
    type: String,
    disabled: Boolean,
    value: [String, Number, Date],
    icon: String,
    iconSize: {
      type: String,
      default: '20'
    },
    validation: [Boolean, String]
  }
}
</script>

<style lang="scss">
@import '~@/styles/reference';

$c-input-border-color:              #e9eaee;
$c-input-disabled-color:            #bdc0d1 !default;
$c-input-disabled-background-color: #eaedef !default;

.dashed-input-container {
  display: flex;
  flex-direction: column;
  color: $title-color;

  & .c-input-container > .input-wrapper {
    display: flex;
    width: 100%;
    margin-top: 5px;
    height: 20px;
    font-family: "Nunito sans";
    border: none;
    border-bottom: 2px dashed $c-input-border-color;

    & > .input {
      width: 80%;
      border: none;
      font-size: 12px;
      padding-right: 8px;
      color: $title-color;

      &:focus { outline: 0; }
    }
  }

  &.-disabled {
    opacity: 0.2;

    & > .input-wrapper {
      & > .input {
        background-color: #fff;
      }
    }
  }
}
</style>
