<template>
  <div class="graph-card">
    <div class="cards">
      <campaigns-bar-card
        class="card"
        :class="selectedTab === 0 ? 'selected-tab' : ''"
        :title="data.tabs[0][0].title"
        :data="data.tabs[0]"
        :loading="loading"
        :info="data.tabs[0][0].info"
        icon="info-information-circle"
        @click.native="changeTab(0)"
      />
      <campaigns-bar-card
        class="card"
        :class="selectedTab === 1 ? 'selected-tab' : ''"
        :title="data.tabs[1][0].title"
        :data="data.tabs[1]"
        :loading="loading"
        :info="data.tabs[1][0].info"
        icon="info-information-circle"
        @click.native="changeTab(1)"
      />
    </div>
    <div class="container">
      <spine-chart
        v-if="correctGraph.length > 0"
        :columns="correctGraph"
        :title="chartTitle"
      />
    </div>
  </div>
</template>

<script>
import CampaignsBarCard from '@/components/Dashboard/cards/CampaignsBarCard.vue'
import SpineChart from '@/components/Dashboard/base/SpineChart.vue'
export default {
  name: 'BannersTable',
  components: {
    CampaignsBarCard,
    SpineChart
  },
  props: {
    data: Object,
    loading: Boolean
  },
  data () {
    return {
      selectedTab: 0
    }
  },
  computed: {
    correctGraph () {
      if (!this.data) return []
      return this.data.columns[this.selectedTab]
    },
    chartTitle () {
      if (!this.graphData) return ''
      return this.graphData.tabs[this.selectedTab].chart_title
    }
  },
  methods: {
    changeTab (index) {
      this.selectedTab = index
    }
  }
}
</script>

<style lang='scss' scoped>
@import '~@/styles/reference.scss';
.graph-card {
  .cards {
    display: flex;
    .card {
      position: relative;
      z-index: 1;
      padding: 16px;
      width: 100%;
      border-radius: 6px 6px 0px 0px;
      margin-right: 10px;
      cursor: pointer;
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.16);
      background: none;

      &.selected-tab {
        background: #FDFDFD !important;
      }
    }
  }
  .cards div:last-child {
        margin-right: 0;
      }
  .container {
    position: relative;
    z-index: 3;
    background: #FDFDFD;
    padding: 16px;
    box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.16);
    border-radius: 0px 0px 6px 6px;
  }
}
</style>
