<template>
  <section
    v-if="!loading"
    class="container"
  >
    <div class="steps">
      <step-count
        :current-step="step"
        :steps="steps"
        @step-click="--step"
      />
    </div>
    <div>
      <brand
        v-if="step === 1"
        :company="companyData"
        @nextStep="updateData($event), ++step "
      />
      <colors
        v-else
        :company="companyData"
        @save="save($event)"
      />
    </div>
  </section>
  <div
    v-else
    class="load"
  >
    <div class="loader" />
  </div>
</template>

<script>
import StepCount from '@/components/General/StepCount'
import Colors from './Steps/Colors'
import Brand from './Steps/Brand.vue'
import { COMPANY, COMPANY_STYLE_LOGIN } from '@/store/types'
import { mapGetters, mapActions } from 'vuex'
export default {

  components: {
    StepCount,
    Brand,
    Colors
  },
  data () {
    return {
      step: 1,
      companyData: {
        title: '',
        intro: '',
        signInLogo: '',
        favicon: '',
        backgroundImage: '',
        mainButton: '',
        mainButtonText: '',
        mainText: '',
        divisors: '',
        or: '',
        secondaryButton: '',
        forgotPassword: '',
        negativeFeedback: '',
        background: '#FFF'
      },
      steps: [
        {
          number: 1,
          text: 'Sua marca'
        },
        {
          number: 2,
          text: 'Suas cores'
        }
      ],
      loading: false
    }
  },

  computed: {
    ...mapGetters({
      company: COMPANY
    })
  },

  mounted () {
    this.loading = true
    this.companyData = JSON.parse(JSON.stringify(this.company.config))
    this.companyData.trial = this.company.trial ? this.company.trial : false
    this.companyData.developedByConvenia = true
    this.companyData.backgroundImage = this.company.config.background
    this.companyData.signInLogo = this.company.config.signInLogo
    this.companyData.favicon = this.company.config.favicon
    this.companyData.signIn = this.company.config.signIn
    this.loading = false
  },

  methods: {
    ...mapActions({
      companyStyle: COMPANY_STYLE_LOGIN
    }),

    updateData (payload) {
      this.companyData = { ...this.companyData, ...payload }
    },

    removeKeys () {
      const removeKeys = ['brands', 'backgroundImage', 'colors', 'email_after_activation', 'has_initial_page', 'logout', 'logout_url', 'redirect_to_activation', 'redirect_logout', 'save_users_email', 'support_link', 'unified_registration']
      removeKeys.forEach(key => {
        delete this.companyData[key]
      })
    },

    deleteImageKeys () {
      const imageKeys = ['logo', 'background', 'favicon', 'signInLogo']
      imageKeys.forEach(key => {
        if (
          typeof this.companyData[key] === 'string' ||
          this.companyData[key] === null ||
          this.companyData[key] ===
          undefined
        ) delete this.companyData[key]
      })
    },

    async save (payload) {
      const backupData = this.companyData
      const newPayload = {
        signInLogo: payload.signInLogo ? payload.signInLogo : null,
        favicon: this.companyData.favicon ? this.companyData.favicon : null,
        background: payload.background ? payload.background : null,
        signIn: payload,
        developedByConvenia: payload.developedByConvenia
      }

      this.updateData(newPayload)
      if (newPayload.favicon) this.companyData.favicon = newPayload.favicon
      this.loading = true
      this.companyData.background = this.companyData.signIn.backgroundImage
      delete this.companyData.signIn.backgroundImage
      this.deleteImageKeys()
      this.removeKeys()
      const response = await this.companyStyle(this.companyData)
      if (response === 'success') {
        this.$router.push('/personalizacao')
      } else { this.companyData = backupData }
      this.loading = false
    }
  }
}
</script>

<style lang='scss' scoped>
@import '~@/styles/reference';
.container {
  max-width: 1020px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 48px;
}
.load {
    height: 325px;
    display: flex;
    justify-content: center;
    align-items: center;
    & > .loader {
      border: 4px solid #f3f3f3;
      border-top: 4px solid var(--color-primary);
      border-radius: 50%;
      width: 30px;
      height: 30px;
      animation: spin 1.2s linear infinite;
    }

    @keyframes spin {
      0% { transform: rotate(0deg); }
      100% { transform: rotate(360deg); }
    }
  }
</style>
