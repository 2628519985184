<template>
  <component
    active-class="-active"
    exact-active-class="-active"
    :is="component"
    :to="!isExternal ? item.path : null"
    :href="item.path"
    :target="isExternal ? '_blank' : null"
    :disabled="item.disabled"
    :class="classes"
    @click="clickAction(item.path)"
    @click.native="clickAction(item.path)"
    :data-cy="item.path.name"
  >
    <k-icon
      v-if="item.icon"
      :icon="item.icon"
      size="20"
      class="c-menu-icon"
    />
    <span class="name">{{ item.name }}</span>
  </component>
</template>

<script>
import isExternal from '@/modules/isExternal'
import { KIcon } from 'kaledo-components'

export default {
  components: {
    KIcon
  },
  props: {
    item: {
      type: Object,
      required: true,
      validator ({ name, path }) {
        const isValid = name && path
        return isValid
      }
    }
  },
  computed: {
    classes () {
      const classes = [
        'c-menu-item',
        { '-disabled': this.item.disabled }
      ]
      return classes
    },
    isExternal () {
      const result = isExternal(this.item.path)
      return result
    },
    component () {
      const component = this.isExternal ? 'a' : 'nuxt-link'
      return component
    }
  },
  methods: {
    clickAction (path) {
      if (path.name === this.$route.name) return
      this.$emit('click')
    }
  }
}
</script>

<style lang="scss">
@import '~@/styles/reference';

// Item do menu
$c-menu-item-font-size: 16px !default;
$c-menu-item-font-weight: 600 !default;
$c-menu-item-font-family: $c-font-family-base !default;
$c-menu-item-color: #5E6684 !default;

// Item do menu com :hover
$c-menu-item-hover-name-color: $base-color !default;
$c-menu-item-hover-icon-color: $base-color !default;

// Item do menu ativo
$c-menu-item-active-name-color: $primary-color-placeholder !default;
$c-menu-item-active-icon-color: $primary-color-placeholder !default;

.c-menu-icon {
  color: $neutral-gray-200;
}

.c-menu-item {
  text-decoration: none;
  cursor: pointer;
  &:not(:last-child) { margin-right: 43px; }

  & > .name {
    font-size: $c-menu-item-font-size;
    font-weight: $c-menu-item-font-weight;
    font-family: $c-menu-item-font-family;
    text-decoration: none;
    letter-spacing: 0.5px;
    color: $c-menu-item-color;
  }

  .c-menu-icon {
    position: relative;
    margin-right: 8px;
    bottom: -2px;
  }

  & > .icon > path {
    fill: #bec1d4;
    transition: fill 0.3s ease;
    will-change: fill;
  }

  &.-disabled { cursor: default; }

  &:hover {
    & > .name { color: $c-menu-item-hover-name-color; }
    & > .icon > path { fill: $c-menu-item-hover-icon-color; }
  }

  &.-active {
    & > .name { color: $c-menu-item-active-name-color; font-weight: bold; }

    & > .icon > path { fill: $c-menu-item-active-icon-color; }
  }
}
</style>
