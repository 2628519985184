<template>
  <NewDistributionContainer />
</template>

<script>
import NewDistributionContainer from '~/containers/Engagement/pages/NewDistributionContainer'
export default {
  components: { NewDistributionContainer }
}
</script>

<style>
</style>
