<template>
  <div
    :class="['wrapper-c-input', { '-has-error': !!validation || feedbackShow }]"
    data-cy="c-input"
  >
    <c-input-container
      v-bind="$attrs"
      :validation="validation || feedbackShow"
      :class="['c-input', { '-has-icon': !!icon, '-disabled': disabled, '-flex': flex }]"
    >
      <c-icon
        v-if="icon"
        :icon="icon"
        :size="iconSize"
        class="icon"
        :class="{'-flex': flex}"
      />

      <div
        v-if="type === 'file'"
        class="input-file-container"
      >
        <div
          v-if="hasImage && !multiple"
          class="file-preview"
        >
          <img
            ref="preview"
            :src="url || imagePlaceholder"
            :alt="
              files.length !== 0 && !Object.keys(errors.items).length
                ? files[0].name
                : ''
            "
            class="img-preview"
          />
        </div>

        <div class="container">
          <label
            class="placeholder"
            :for="id"
          >{{
            fileName && !Object.keys(errors.items).length
              ? fileName
              : placeholder
          }}</label>

          <input
            class="input-file"
            type="file"
            ref="file"
            required
            name="Arquivo"
            v-validate="{ required: true, size: fileSize, ext: exts }"
            data-vv-delay="10"
            :id="id"
            :multiple="multiple"
            @change="change"
            v-bind="$attrs"
          />

          <label
            class="label-file"
            :for="id"
          >
            <c-button
              class="button-file"
              primary
              size="lg"
            >
              {{ text }}
            </c-button>
          </label>
        </div>
      </div>

      <input
        v-else-if="!textarea"
        :class="classes"
        :placeholder="placeholder"
        v-mask="mask"
        v-model="internalValue"
        :disabled="disabled"
        :type="type"
        v-bind="$attrs"
        :id="'input'"
      />

      <textarea
        v-else
        :class="classes"
        :placeholder="placeholder"
        v-model="internalValue"
        :rows="rows"
        v-bind="$attrs"
      />
      <div
        v-if="countCharacters && typeof internalValue === 'string'"
        :class="`character-counter ${internalValue.length > 255 ? 'error': ''}`"
      >
        {{ internalValue.length }}/255
      </div>
    </c-input-container>

    <transition
      name="c-input-feedback"
      class="errorfeedback"
    >
      <span
        data-cy="cinput-feedback"
        v-if="feedbackShow && feedbackMessage"
        :class="feedbackClasses"
      >
        <c-icon
          v-if="validationIcon"
          :icon="validationIcon"
          size="15"
          class="erroricon"
        />
        {{ feedbackMessage }}
      </span>
    </transition>
  </div>
</template>

<script>
import { mask } from 'vue-the-mask'
import CInputContainer from '@/components/CComponents/CInputContainer'

export default {
  components: { CInputContainer },
  directives: {
    mask (el, binding) {
      if (!binding.value || !binding.value.length) {
        return
      }
      return mask(el, binding)
    }
  },
  props: {
    disabled: Boolean,
    icon: String,
    iconSize: {
      type: String,
      default: '20'
    },
    placeholder: String,
    mask: [String, Array],
    value: [String, Number, Date],
    textarea: Boolean,
    countCharacters: {
      type: Boolean,
      default: false
    },
    type: String,
    url: {
      type: String,
      default: ''
    },
    exts: Array,
    multiple: Boolean,
    rows: {
      type: String,
      default: '4'
    },
    text: {
      type: String,
      default: 'Selecionar arquivo'
    },
    validation: [Boolean, String],
    validationIcon: {
      type: String,
      default: 'warning-circle'
    },
    success: Boolean,
    round: Boolean,
    background: Boolean,
    hasImage: Boolean,
    feedbackShow: {
      type: Boolean,
      default: false
    },
    feedbackMessage: {
      type: String,
      default: ''
    },
    feedbackType: {
      type: String,
      default: 'error'
    },
    fileSize: {
      type: [String, Number],
      default: 50000
    },
    watch: {
      type: Boolean,
      default: false
    },
    flex: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      internalValue: this.value,
      files: [],
      fileName: '',
      imagePlaceholder: require('@/assets/img/campaign-placeholder.svg'),
      firstWatch: false
    }
  },
  computed: {
    classes () {
      const classes = [
        'input', {
          '-has-validation': this.validation || this.success,
          '-success': this.success || this.feedbackType === 'success',
          '-textarea': this.textarea,
          '-round': this.round,
          '-background': this.background,
          '-showFeedback': this.feedbackShow,
          '-error': this.feedbackType === 'error',
          '-flex': this.flex
        }
      ]
      return classes
    },

    feedbackClasses () {
      const classes = [
        'feedback', {
          '-error': this.feedbackType === 'error',
          '-flex': this.flex,
          '-success': this.feedbackType === 'success'
        }
      ]

      return classes
    },

    id () {
      return 'input-file-' + this._uid
    }
  },

  watch: {
    internalValue (v) {
      this.$emit('input', v)
    },
    value (v) {
      if (!this.firstWatch || this.watch) { this.internalValue = this.value }

      this.firstWatch = true
    }
  },
  mounted () {
    const input = document.getElementById('input') || document.getElementById(this.id)
    if (input) {
      input.addEventListener('focusout', () => {
        this.$emit('focusout')
      })
    }
  },
  beforeDestroy () {
    const input = document.getElementById('input') || document.getElementById(this.id)
    if (input) {
      input.removeEventListener('focusout', () => { this.$emit('focusout') })
    }
  },

  methods: {
    change (event) {
      const files = [...event.target.files].map(
        (file) => ({
          file,
          name: file.name,
          id: Math.random() * Date.now()
        })
      )

      setTimeout(() => {
        if (this.errors.has('Arquivo')) {
          this.files = []
          this.fileName = ''
          return
        }

        this.files = files
        this.fileName = files[0].name
        this.$nextTick(function () {
          this.previewFile()
        })
        this.$emit('change', this.files)
      }, 0)
    },

    previewFile () {
      const preview = this.$refs.preview
      const file = this.files[0].file
      const reader = new FileReader()

      reader.onloadend = (e) => {
        if (preview) { preview.src = e.target.result }
      }
      reader.readAsDataURL(file)
    }
  }
}
</script>

<style lang='scss'>
@import '~@/styles/reference';

// TODO: Use themed variables

$c-input-success-color: darken(#59eeb2, 20) !default;
$c-input-success-border-color: darken(#59efb2, 5) !default;

$c-input-error-border-color: #ff7987 !default;
$c-input-error-color: #ff7986 !default;

$c-input-feedback-font-size: 12px !default;

$c-input-border-color: #e9eaee;
$c-input-disabled-background-color: #eaedef !default;
$c-input-disabled-color: #bdc0d1 !default;

.wrapper-c-input {
  position: relative;

  .character-counter {
    position: absolute;
    right: 0;
    bottom: -20px;
    font-size: 11px;
    color: $text-color;
    &.error {
      color: $c-input-error-color
    }
  }

  & > .c-input-container > .input-file-container {
    display: flex;
    flex-direction: column;

    & > .file-preview {
      border: 1px dotted #e9eaee;
      border-radius: 3px;
      background-color: #ffffff;
      margin: 0 auto;
      margin-bottom: 10px;

      & > .img-preview {
        max-width: 180px;
        max-height: 130px;
      }
    }

    & > .container {
      display: flex;
      justify-content: flex-end;
      border-radius: 20px;
      border: 2px solid $border-color;
      cursor: pointer;
      background-color: #fff;
      height: 41px;

      & > .placeholder {
        display: flex;
        flex-grow: 1;
        align-items: center;
        padding: {
          left: 25px;
          right: 25px;
        }
        opacity: 0.5;
        color: $title-color;
        font-size: 12px;
        cursor: pointer;
      }

      & > .input-file {
        display: none;
      }

      & > .label-file {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #fdfdfd;
        cursor: pointer;
        border-radius: 20px;

        & > .button-file {
          pointer-events: none;
          border-radius: 20px;
          height: 35px;
        }
      }
    }
  }

  & > .feedback {
    font-size: $c-input-feedback-font-size;

    position: absolute;
    bottom: -20px;
    left: 0;
    right: 0;
    line-height: 1;
    z-index: 1;

    &.-success {
      color: $c-input-success-color;
    }
    &.-error {
      display: flex;
      align-items: center;
      color: $c-input-error-color;

      svg {
        margin-right: 5px;
        fill: #ff7987;
      }
    }
  }
}

.c-input {
  & > .input {
    @extend %c-input-input;
    outline: none !important;
  }

  &.-has-icon {
    position: relative;
    padding: 0;

    & > .icon {
      position: absolute;
      bottom: 10px;
      left: 10px;
      fill: $icon-color;
      &.-flex {
        right: 10px;
        left: auto;
      }
    }

    & > .input {
      height: 20px;
      margin: 0;
      padding: 0;
      padding-left: 40px;
      padding-right: 10px;
      &.-flex {
        padding-left: 10px;
      }
    }
  }

  &.-disabled {
    &:hover {
      cursor: default;
      pointer-events: none;
    }

    & > .input {
      background-color: $c-input-disabled-background-color;
      border-color: $c-input-disabled-background-color;

      color: $c-input-disabled-color;
      font-weight: 300;
      pointer-events: none;
    }
  }

  &.-showFeedback {
    padding-bottom: outer-base();
  }

  &.-showFeedback.-success {
    color: $c-input-success-color;
    border-color: $c-input-success-border-color;
    & > .icon {
      fill: $c-input-success-color;
    }
  }

  &.-showFeedback.-error {
    & > .feedback,
    & > .inner > .input {
      color: $c-input-error-color;
    }
    & > .inner > .icon {
      fill: $c-input-error-color;
    }
    & > .inner {
      border-color: $c-input-error-border-color;
    }
  }
}

%c-input-input {
  background-color: white;
  color: $title-color;
  border-radius: 3px;
  font-family: 'Nunito sans';
  font-size: 14px;
  width: 100%;
  box-shadow: none;
  -webkit-appearance: none;
  border: 1px solid $c-input-border-color;
  padding: 10px;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
  will-change: border-color, box-shadow;
  cursor: pointer;

  &.-disabled {
    color: red;
  }

  &:focus {
    outline: 0;
  }

  &::placeholder {
    font-family: 'Nunito sans';
    font-size: 14px;
    color: $placeholder-color;
    font-weight: 400;
  }

  &:not(.-textarea) {
    height: 40px;
  }

  &:not(.-has-validation) {
    &:hover,
    &:active,
    &:focus {
      border-color: $secondary-color-placeholder;
      border-color: var(--color-secondary);
      box-shadow: 0 0 0 2px $secondary-color-placeholder;
      box-shadow: 0 0 0 2px var(--color-secondary);
    }
  }

  &.-has-validation {
    box-shadow: none;
    outline: none;
    outline-offset: 0;
    border-color: $failure-color;

    &.-success {
      border-color: $success-color;
    }
  }

  &.-round {
    border-radius: 20px;
  }

  &.-background {
    background-color: #fff;
  }
}
</style>
