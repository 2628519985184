<template>
  <div :class="[ 'limit-codes-component', { '-margin-top': threeSteps } ]">
    <c-input
      required
      class="limit-code"
      label="LIMITE DE CÓDIGOS"
      validation-icon="warning-circle"
      placeholder="Insira um limite de códigos por dia"
      type="number"
      v-validate="'required'"
      data-vv-delay="10"
      name="limite de códigos"
      :feedback-show="errors.has('limite de códigos')"
      :feedback-message="errors.first('limite de códigos')"
      :value="limit"
      @input="v => emitter('eventData', v)"
    />

    <span
      class="description"
      v-once
    >
      <c-icon
        class="icon"
        icon="warning-circle"
        size="15"
      />
      O limite acima é aplicado <strong> &nbsp;por dia.</strong>
    </span>
  </div>
</template>

<script>

export default {
  props: {
    limit: [String, Number],
    threeSteps: Boolean
  },

  methods: {
    emitter (key, value) {
      setTimeout(() => {
        this.$emit('update', {
          [key]: {
            limit: parseInt(value)
          },
          error: this.errors.all()
        })
      }, 50)
    }
  }
}
</script>

<style lang="scss">
@import '~@/styles/reference';

.limit-codes-component {
  margin-top: 20px;

  &.-margin-top { margin-top: 25px; }

  & > .limit-code {
    width: 100%;
    padding-left: 80px;
    padding-right: 20px;

    @include mobile { padding: 0; }

    & > .feedback { position: unset; padding-top: 6px; }
  }

  & > .description {
    width: 100%;
    padding-left: 80px;
    padding-right: 20px;
    font-size: 14px;
    color: $icon-color;
    display: flex;
    align-items: center;
    margin-top: 5px;

    @include mobile { padding: 0; }

    & >.icon { margin-right: 5px; }
  }
}
</style>
