<template>
  <c-modal
    v-bind="$attrs"
    v-on="$listeners"
    :close-on-outside-click="false"
    :show-close="false"
    class="order-name-modal"
    name="order-name-modal"
  >
    <c-title
      data-cy="modal-title"
    >
      Nome do pedido
    </c-title>
    <div class="modal-content">
      <div class="order-input-container">
        <c-input
          data-cy="order-name-input"
          class="order-name"
          v-model="name"
          label="Dê um nome para o seu pedido"
          placeholder="Nome do pedido"
          name="order-name"
          maxlength="50"
          required
          data-vv-delay="10"
          v-validate="'required'"
        />
        <transition name="fade">
          <span
            class="subtext"
            data-cy="input-subtext"
          >
            Máx. 50 caracteres
          </span>
        </transition>
      </div>
    </div>
    <div class="modal-actions">
      <c-button
        data-cy="cancel-order-button"
        class="button cancel-button"
        @click="handleCancel()"
      >
        Cancelar
      </c-button>
      <c-button
        data-cy="confirm-order-button"
        class="button confirm-button"
        icon="chevron-right"
        primary
        :disabled="!name"
        @click="handleConfirm"
      >
        Continuar
      </c-button>
    </div>
  </c-modal>
</template>

<script>
import { KIcon } from 'kaledo-components'
import CModal from '@/components/CComponents/CModal'

export default {
  components: { KIcon, CModal },
  data () {
    return {
      name: ''
    }
  },
  methods: {
    handleConfirm () {
      this.$emit('close')
      this.$emit('confirm', this.name)
    },
    handleCancel () {
     window.dataLayer.push('pontos-pedido-abandonar')
     this.$router.push({ name: 'engajamento-distribuicao' })
    }
  }
}
</script>

<style lang="scss">
@import '~@/styles/reference';

.order-name-modal {
  z-index: 30 !important;
  height: 100%;
  width: 99%;
  max-height: 285px !important;
  max-width: 430px !important;

  > .content {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: space-between;
    padding-bottom: 10px;

    @include mobile { justify-content: center; }

    .modal-content {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;

      .order-input-container {
        width: 100%;

        .subtext {
          margin-top: 6px;
          font-size: 12px;
          color: #5E6684;
        }
      }
    }
    .modal-actions {
      width: 100%;
      display: flex;
      gap: 16px;

      .button {
        height: 40px;
        width: 200px;
        border-radius: 20px;
      }
      .cancel-button {
        border: 1px solid rgba(94, 102, 132, 1);
        border: 1px solid var(--color-secondary)
      }
    }
  }
}
</style>
