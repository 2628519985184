<template>
  <div>
    <c-header
      v-if="$can('dashboard')"
      :show-button="false"
      :nav-tabs="navTabs"
      @click="handleAnalytics('botao-novo-parceiro', { company_name: company.name})"
    />
    <div class="dashboard-page">
      <transition name="fade">
        <c-spinner
          v-if="isLoading"
          size="lg"
        />
        <div v-else>
          <dashboard-container />
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import CHeader from '@/components/CComponents/CHeader.vue'
import DashboardContainer from '@/containers/Dashboard/DashboardContainer.vue'

export default {
  name: 'Dashboard',
  components: {
    CHeader,
    DashboardContainer
  },
  props: {
    graphData: Object
  },
  data () {
    return {
      isLoading: true
    }
  },
  computed: {
    navTabs () {
      return [
        {
          name: 'Dashboard',
          value: 'relatorios'
        },
        {
          name: 'Relatorios',
          value: 'relatorios-relatorios'
        }
      ]
    }
  },
  mounted () {
    if (this.$can('dashboard')) { this.isLoading = false } else {
      this.$router.push({ name: 'relatorios-relatorios' })
    }
  }
}
</script>

<style lang='scss' scoped>
  @import '~@/styles/reference';

  .dashboard-page {
    margin-top: 35px;
    padding: 0 10px;

    @include desktop { @include container; }
  }
</style>
