<template>
  <div>
    <component-header @return="goToWallet()" />
    <new-order />
  </div>
</template>

<script>
import NewOrder from '@/containers/User/Wallet/NewOrder.vue'
import ComponentHeader from '@/components/General/ComponentHeader.vue'
import { mapGetters } from 'vuex'
import { COMPANY } from '@/store/types'

export default {
  components: { NewOrder, ComponentHeader },
  computed: {
    ...mapGetters({
      company: COMPANY
    })
  },
  methods: {
    goToWallet () {
      window.dataLayer.push({
        event: 'carteira-acessar',
        company_name: this.company.slug
      })
      this.$router.push({ name: 'carteira' })
    }
  }
}
</script>

<style>
</style>
