<template>
  <div class="user-form">
    <c-title
      class="title"
      icon="pencil"
    >
      Editar dados de {{ user.name }}
    </c-title>

    <div class="form">
      <div class="group-label">
        <span class="label">Nome</span>
        <span class="label">Sobrenome</span>
        <span class="label -required">E-mail</span>
        <span class="label">Telefone</span>
        <span class="label">{{ isDesktop ? 'Data de nascimento' : 'Data nasc.' }}</span>
        <span class="label">Gênero</span>
        <span class="label">Estado</span>
        <span
          v-if="!isDesktop"
          class="label"
        >Cidade</span>
      </div>

      <div class="group-input">
        <c-input
          name="nome"
          class="input"
          validation-icon="warning-circle"
          placeholder="Insira seu nome"
          data-vv-delay="10"
          :feedback-show="errors.has('nome')"
          :feedback-message="errors.first('nome')"
          :value="user.name"
          @input="v => emitter('name', v)"
        />

        <c-input
          name="sobrenome"
          class="input"
          validation-icon="warning-circle"
          placeholder="Insira seu sobrenome"
          data-vv-delay="10"
          :feedback-show="errors.has('sobrenome')"
          :feedback-message="errors.first('sobrenome')"
          :value="user.last_name"
          @input="v => emitter('last_name', v)"
        />

        <c-input
          required
          name="email"
          class="input"
          validation-icon="warning-circle"
          placeholder="Insira seu email"
          data-vv-delay="10"
          v-validate="'required|email'"
          :feedback-show="errors.has('email')"
          :feedback-message="errors.first('email')"
          :value="user.email"
          @input="v => emitter('email', v)"
        />

        <c-input
          name="telefone"
          class="input"
          placeholder="Insira seu telefone"
          :mask="['(##) ####-####', '(##) #####-####']"
          data-vv-as="celular"
          validation-icon="warning-circle"
          v-validate="{ required: true, regex: /^\([1-9]{2}\) [2-9][0-9]{3,4}\-[0-9]{4}$/ }"
          data-vv-delay="10"
          :feedback-show="errors.has('telefone')"
          :feedback-message="errors.first('telefone')"
          v-model="phone"
        />

        <c-input
          name="birthday"
          class="input"
          validation-icon="warning-circle"
          placeholder="Insira sua data de nascimento"
          data-vv-as="data de nascimento"
          data-vv-delay="10"
          v-validate="{ required: false, date_format: 'dd/MM/yyyy' }"
          :feedback-show="errors.has('birthday')"
          :feedback-message="errors.first('birthday')"
          mask="##/##/####"
          :value="user.birthday"
          @input="v => emitter('birthday', v)"
        />

        <c-select
          class="input -gen"
          :items="genderOptions"
          :value="form.gender"
          track-by="name"
          display-by="name"
          @input="v => emitter('gender', v)"
        />

        <div class="group-select">
          <c-select
            class="select"
            :items="optionsState"
            :value="form.state"
            track-by="sigla"
            display-by="sigla"
            @input="v => emitter('state', v)"
          />

          <span
            v-if="isDesktop"
            class="label-select"
          >Cidade</span>
          <c-select
            class="select -last"
            :disabled="!form.state"
            :items="optionsCity"
            :value="form.city"
            track-by="cidade"
            display-by="cidade"
            @input="v => emitter('city', v)"
          />
        </div>
      </div>
    </div>

    <div class="actions">
      <c-button
        size="lg"
        @click="$router.push({ name: 'usuarios-userId', params: { userId: user.id } })"
      >
        CANCELAR
      </c-button>

      <c-button
        class="submit"
        icon="chevron-right"
        icon-size="sm"
        size="lg"
        primary
        :disabled="!isValid"
        @click="$emit('submit')"
      >
        Salvar
      </c-button>
    </div>
  </div>
</template>

<script>

export default {

  props: {
    user: {
      type: Object,
      required: true
    },
    form: {
      type: Object,
      required: true
    },
    places: {
      type: Array
    },
    isValid: Boolean
  },

  data () {
    return {
      phone: this.user.phone,
      genderOptions: [
        { name: 'Feminino' },
        { name: 'Masculino' },
        { name: 'Outro' }
      ]
    }
  },

  computed: {
    optionsState () {
      return this.places.map(place => {
        return { sigla: place.sigla }
      })
    },

    optionsCity () {
      if (this.form.state) {
        const cities = (this.places.find(({ sigla }) => sigla === this.form.state) || {}).cidades || []
        const mapped = cities.map(city => {
          return { cidade: city }
        })
        return mapped
      } else {
        return []
      }
    }
  },

  watch: {
    'form.state' (newValue, oldValue) {
      if ((this.form.state === null) || newValue !== oldValue) {
        this.$emit('reset-city')
      }
    },

    phone (phone) {
      this.emitter('phone', phone)
    }
  },

  methods: {
    emitter (key, value) {
      setTimeout(() => {
        this.$emit('synchronize', { [key]: value, error: this.errors.all() })
      }, 50)
    }
  }
}
</script>

<style lang="scss">
@import '~@/styles/reference';

.user-form {
  margin-top: 30px;

  @include container;

  & > .title { margin-bottom: 50px; }

  & > .form {
    @include container;

    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    flex-basis: 100%;

    & > .group-label {
      display: flex;
      flex-direction: column;
      flex-basis: 20%;

      & > .label {
        opacity: 0.8;
        color: $base-color;
        font-size: 12px;
        text-align: right;
        padding: { right: 15px; top: 10px; bottom: 45px; }
        text-transform: uppercase;

        &.-required {
          &:after {
            content: '*';
            margin-left: 3px;
          }
        }
      }
    }

    & > .group-input {
      display: flex;
      flex-direction: column;
      flex-basis: 80%;

      & > .input {
        width: 680px;
        margin-bottom: 30px;

        @include mobile {
          width: calc(100% - 10px);
        }
      }
      & >.-gen {
        text-transform: capitalize;
      }

      & > .group-select {
        display: flex;
        flex-direction: row;

        @include mobile {
          flex-direction: column;
        }

        & > .select {
          width: 295px;

          @include mobile {
            width: calc(100% - 10px);
          }
        }
        & > .-last {
          @include mobile {
            margin-top: 25px;
          }
        }

        & > .label-select {
          opacity: 0.8;
          color: $base-color;
          font-size: 12px;
          text-transform: uppercase;
          padding: { left: 30px; right: 15px; top: 10px }
        }
      }
    }
  }

  & > .actions {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;

    max-width: 680px;
    margin-left: 20%;

    //@include mobile { justify-content: center; }

    & > .submit { margin-left: 10px; }
  }
}
</style>
