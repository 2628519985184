<template>
  <c-modal
    class="user-import-processing-modal"
    name="user-import-processing-modal"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <div class="character">
      <c-character
        class="image"
        type="embarrassed"
      />
    </div>

    <c-title
      class="title"
      size="3"
      :show-border="false"
    >
      Importação em andamento
    </c-title>

    <p class="description">
      Aguarde a conclusão ou cancele a importação em andamento
      <br />
      em "histórico de importação".
    </p>

    <c-button
      primary
      class="confirm"
      size="lg"
      @click="$emit('close')"
    >
      Entendi
    </c-button>
  </c-modal>
</template>

<script>
export default {
  components: {
    CCharacter: () =>
      import('@/components/CComponents/CCharacter'),
    CModal: () =>
      import('@/components/CComponents/CModal')
  }
}
</script>

<style lang="scss">
.user-import-processing-modal > .content {
  text-align: center;

  & > .character > .image {
    margin: auto;
    width: 100px;
    height: 100px;
    padding: 50px;
  }
  & > * { margin-bottom: 20px; }
  & > .description > .bold {
    font-weight: bold;
  }
  & > .title {
    & > .c-title > .text { display: block; }
  }
}
</style>
