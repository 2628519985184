<template>
  <user-detail></user-detail>
</template>

<script>
import UserDetail from '~/containers/User/Details/UserDetail'
export default {
  components: { UserDetail }
}
</script>

<style>

</style>
