<template>
  <div
    data-cy="password-checks"
    class="checks">
    <div
      class="check"
      v-for="(rule, index) in rules"
      :key="`rule-${index}`"
    >
      <span
        data-cy="check-ball"
        class="ball"
        :class="checkRule(index) ? '-success' : '-error' "
      />
      <p
        class="text"
        v-html="rule"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'Checks',
  props: {
    password: String
  },
  computed: {
    rules () {
      return [
        'Mínimo 8 caracteres',
        'Uma letra maiúscula',
        'Um número',
        'Uma letra minúscula',
        'Um caracter especial<br/> (Ex: !#@$*&)'
      ]
    },
    isValid () {
      const allRules = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[^\w\d\s])/
      if (!this.password) return false
      return !!(this.password.length >= 8 && this.password.match(allRules))
    }
  },
  watch: {
    isValid (newval) {
      this.$emit('is-valid', newval)
    }
  },
  methods: {
    checkRule (index) {
      const hasCapitalLetter = /^(?=.*[A-Z])/g
      const hasLowerCase = /^(?=.*[a-z])/
      const hasNumber = /^(?=.*\d)/
      const hasSpecialCharacter = /^(?=.*[^\w\d\s])/
      switch (index) {
      case 0:
        return (this.password && this.password.length >= 8)
      case 1:
        return this.password.match(hasCapitalLetter)
      case 2:
        return this.password.match(hasNumber)
      case 3:
        return this.password.match(hasLowerCase)
      case 4:
        return this.password.match(hasSpecialCharacter)
      }
      return false
    }
  }
}
</script>

<style lang='scss' scoped>
@import '~@/styles/reference';
.checks {
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin-left: 0 auto;
  column-gap: 30px;
  width: 350px;
  margin-top: 20px;
  @include mobile {
    width: 100%;
    max-width: 540px;
    justify-content: flex-start;
  }
  .check {
    display: flex;
    align-items: baseline;
    margin-bottom: 16px;
    .ball {
      display: block;
      width: 8px;
      height: 8px;
      background: $failure-color;
      border-radius: 50%;
      margin-right: 8px;
      transition: all ease 0.3s;
      &.-error {
        background: $failure-color;
      }
      &.-success {
        background: $success-color;
      }
    }
  }
}
</style>
