<template>
  <div class="order-communication-preview-card">
    <img
      class="image"
      :src="image || require('@/assets/img/coin-logo.png')"
    />
    <div class="content">
      <span class="title">
        {{ title }}
      </span>
      <p class="message">
        {{ message }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    message: String,
    image: String
  }
}
</script>

<style lang="scss" scoped>
.order-communication-preview-card {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 420px;
  height: 84px;
  gap: 16px;
  padding: 10px 30px;
  background: #FFFFFF;

  .image {
    width: 64px;
    height: 64px;
  }
  .content {
    width: 100%;
  }
  .title {
    font-weight: 700;
    color: #565F7D;
    font-size: 10px;
    text-transform: uppercase;
  }
  .message {
    height: 40px;
    font-size: 12px;
    line-height: 15px;
    color: #565F7D;
  }
}
</style>
