<template>
  <div class="password">
    <div
      class="password-input-container"
    >
      <c-input
        class="password-input"
        :class="hasError ? '-error' : ''"
        v-validate="'required'"
        name="Senha"
        :disabled="disabled"
        :type="showPassword ? 'text' : 'password'"
        :label="label"
        :feedback-show="hasError"
        :feedback-message="feedbackMessage"
        placeholder="Insira sua nova senha"
        @input="checkInput"
      />
      <button
        class="password-button"
        @click="showPassword = !showPassword"
      >
        <k-icon
          :icon="showPassword ? 'eye-show-visible' : 'eye-hidden'"
          :class="showPassword ? 'icon-visible' : 'icon-hidden'"
        />
      </button>
    </div>
    <checks
      v-if="hasChecks"
      :password="password"
      @is-valid="$emit('is-valid', $event)"
    />
  </div>
</template>

<script>
import { KIcon } from 'kaledo-components'
import Checks from '@/components/CComponents/Checks.vue'
export default {
  name: 'Password',
  components: {
    KIcon,
    Checks
  },
  props: {
    disabled: Boolean,
    hasChecks: Boolean,
    feedbackMessage: String,
    showError: Boolean,
    label: {
      type: String,
      default: 'Nova senha'
    }
  },
  data () {
    return {
      password: '',
      showPassword: false
    }
  },
  computed: {
    hasError () {
      return this.errors.has('Senha') || this.showError
    }
  },
  methods: {
    checkInput (input) {
      this.$emit('input', input)
      this.password = input
    }
  }
}
</script>

<style lang='scss'>
@import '~@/styles/reference';
.password {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin: 0px auto 5px auto;
  @include mobile {
    width: 100%;
  }
  .password-input-container {
    position: relative;
    @include mobile {
      width: 100%;
      max-width: 540px;
    }
  }
  .password-input {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 10px;

    .c-input {
      @include mobile {
        width: 100%;
        max-width: 540px;
      }
    }
    .input {
      width: 374px;
      @include mobile {
        width: 100%;
        max-width: 540px;
      }
    }
    .input:hover, .input:focus {
      border: 2px solid rgba(32, 41, 80, 1) !important;
      box-shadow: none !important;
    }

    &.-error .input{
      border-color: rgba(255, 89, 103, 1);
    }
  }
  .password-button {
    background: none;
    border: none;
    cursor: pointer;
    position: absolute;
    right: 10px;
    top: 34px;
    font-size: 20px;
    .icon-visible {
      color: #5E6684;
    }
    .icon-hidden {
      color: #D8D8D8;
    }
  }
}
</style>
