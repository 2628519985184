<template>
  <c-card class="user-import-processing">
    <div class="character-processing">
      <c-character
        class="image-process"
        type="happy"
      />
    </div>

    <div class="content">
      <c-title
        class="title"
        size="3"
        :show-border="false"
      >
        Importação em andamento
      </c-title>

      <p class="description">
        O arquivo importado está em análise. Assim que finalizada, você poderá
        consultar o resumo da importação e concluir a atividade. Continue
        realizando suas atividades no painel e te avisaremos por email quando
        a análise for concluída.
      </p>
    </div>
  </c-card>
</template>

<script>
export default {
  components: {
    CCharacter: () =>
      import('@/components/CComponents/CCharacter'),
    CCard: () =>
      import('@/components/CComponents/CCard')
  }
}
</script>

<style lang="scss">
.user-import-processing {
  margin-bottom: 20px;
  padding: 20px;
  overflow: hidden;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;

  & > .character-processing {
    padding-right: 20px;
    margin-bottom: -50px;
  }

  & > .content > .title {
    margin-bottom: 10px;
  }
}
</style>
