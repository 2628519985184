<template>
  <c-popover
    v-if="show"
    :position="['top', 'center']"
    :target="target"
    @blur="$emit('change-show', false)"
  >
    <div class="filters">
      <filter-group
        class="popover-filter-group"
        title="Ordenar por"
      >
        <c-button
          slot="actions"
          @click.native.stop="$emit('clean', 'order')"
          size="lg"
        >
          Limpar seleção
        </c-button>
        <filter-toggler
          class="filter"
          v-for="ordinator in ordinators"
          :key="ordinator.name"
          :name="ordinator.name"
          :icon="ordinator.icon"
          :selected="params[ordinator.action]"
          @click.native.stop="$emit('handleOrder', ordinator.action)"
        />
      </filter-group>
    </div>

    <c-button
      class="popover-filter-apply"
      icon="chevron-right"
      size="sm"
      primary
      block
      @click="$emit('apply-filters'); $emit('change-show', false)"
    >
      Aplicar
    </c-button>
  </c-popover>
</template>

<script>
import CPopover from '@/components/CComponents/CPopover'
import FilterGroup from '@/components/Filter/FilterGroup'
import FilterToggler from '@/components/Filter/FilterToggler'

export default {
  name: 'OrderPopover',
  components: {
    CPopover,
    FilterGroup,
    FilterToggler
  },

  props: {
    show: Boolean,
    ordinators: Array,
    params: Object,
    target: [String, HTMLElement, Object]
  }
}
</script>

<style lang='scss' scoped>
@import '~@/styles/reference';

.c-popover > .content > .filters { @include popoverFilter; }
.popover-filter-group { @include popoverFilterGroup; }
.popover-filter-apply.c-button.-block { position: absolute; }
</style>
