<template>
  <div :class="isMobile ? 'login-content mobile' : 'login-content'">
    <div
      class="left"
      ref="background"
      :style="style"
      v-if="!isMobile"
    />

    <div
      class="right"
      :style="{ backgroundColor: background }"
    >
      <slot />
    </div>

    <footer-component
      v-if="!isMobile"
      v-bind="$attrs"
      :developed-by-convenia="developedByConvenia"
    />
  </div>
</template>

<script>
import FooterComponent from './FooterComponent'
export default {
  components: { FooterComponent },
  props: {
    developedByConvenia: Boolean,
    backgroundImage: [File, Array, String],
    background: {
      type: String,
      default: 'white'
    },
    isMobile: Boolean
  },
  computed: {
    style () {
      if (this.backgroundImage.name) {
        return this.changeBackground(this.backgroundImage)
      } else if (this.backgroundImage) {
        return { backgroundImage: `url('${this.backgroundImage}')` }
      } else { return 'background: rgba(216, 216, 216, 0.40)' }
    }
  },

  methods: {
    changeBackground (file) {
      if (!file) return
      const reader = new FileReader()

      reader.onloadend = () => {
        this.$refs.background.style.backgroundImage = `url('${reader.result}')`
      }
      reader.readAsDataURL(file)
    }
  }
}
</script>

<style lang="scss">
.mobile {
  width: 345px;
}
.login-content {
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  box-shadow: 0px 4px 8px 0px rgba(94, 102, 132, 0.12);

  & >.left {
    width: 675px;
    height: 768px;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
  & >.right {
    width: 345px;
    height: 768px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 15px 40px;
  }
}
</style>
