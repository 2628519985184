<template>
  <div class="users-page">
    <user-header class="header" />
    <nuxt-child />
  </div>
</template>

<script>
import UserHeader from '@/components/User/UserHeader'

export default {
  components: { UserHeader }
}
</script>

<style lang="scss">
.users-page {
  margin-bottom: 100px;

  & > .header { margin-bottom: 30px; }
}
</style>
