// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/img/pattern-oferta.svg");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".fade-enter-active,.fade-leave-active{transition:opacity .3s}.fade-enter,.fade-enter-from,.fade-leave-to{opacity:0}.user-profile{padding-bottom:18px;padding-top:46px;position:relative}.user-profile:before{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-repeat:repeat-y;content:\"\";height:130px;left:0;position:absolute;top:-20px;width:100%}.user-profile>.name{color:rgba(18,30,72,.8);font-size:14px;line-height:19px;margin-bottom:3px;margin-top:14px;text-transform:uppercase}.user-profile>.withdraw{color:rgba(18,30,72,.5);font-size:12px;line-height:13px;margin-bottom:12px}.user-profile>.withdraw>span{font-weight:700}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
module.exports = ___CSS_LOADER_EXPORT___;
