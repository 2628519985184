<template>
  <div class="filter-group">
    <div class="header">
      <h4 class="title">{{ title }}</h4>
      <div class="actions">
        <slot name="actions" />
      </div>
    </div>

    <div class="filters">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: String
  }
}
</script>

<style lang="scss">
@import '~@/styles/reference';

$filter-group-spacing: 10px;

.filter-group {
  padding-top: 20px;
  @include desktop { padding: 20px; }

  & > .header {
    display: flex;
    flex-flow: row nowrap;
    margin-bottom: 20px;
    justify-content: space-between;
    align-items: center;

    & > .title,
    & > .actions { flex: 1 0 0; }

    & > .actions { text-align: right; }

    & > .title {
      color: $title-color;
      line-height: 19px;
      margin-right: 10px;
    }
  }

  & > .filters {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;

    & > .filter {
      flex: calc(50% - (#{$filter-group-spacing} / 2)) 0 0;
      margin-bottom: $filter-group-spacing;
    }

    & > .filter:only-child {
      flex: calc(100% - (#{$filter-group-spacing} / 2)) 0 0;
    }
  }
}
</style>
