<template>
  <div
    data-cy="my-orders"
    class="my-orders"
  >
    <div class="title-header">
      <c-title
        class="title"
      >
        Meus Pedidos
      </c-title>
      <div
        v-if="orders.length || showEmpty"
        class="actions"
      >
        <search-field
          data-cy="order-name-filter-input"
          class="order-search"
          placeholder="Procurar pelo nome do pedido"
          icon-size="20"
          :is-loading="false"
          :term="search"
          @input="search = $event"
          @submit="submitSearch"
          @reset="resetSearch"
          @focusout="$emit('focusout')"
        />
        <filters-popover
          :filters-by-status="filtersByStatus"
          :show-filter="showFilter"
          :has-filter="hasFilters"
          @select-filter="filterOrders"
          @blur="showFilter = false"
          @show-filter="showFilter = !showFilter"
          @clear-filter="clearFilter"
          @apply-filter="submitSearch"
          @close="showFilter = false"
        />
      </div>
    </div>
    <transition
      name="fade"
    >
      <c-spinner v-if="loading" />
      <c-empty
        v-else-if="showEmpty"
        class="empty-search"
        title="Ops!"
        character="searching"
        message="Não encontramos nenhum pedido relacionado à busca"
      />
      <listing-table
        v-else
        data-cy="my-orders-table"
        empty-banner-text="Você ainda não possui um pedido de distribuição de moedas"
        :rows="orders"
        :cols="tableCols"
        :paginate="paginate"
        :has-empty-banner="!search"
        @update-paginate="handlePaginate"
        @cancel-click="order => handleCancelClick(order)"
        @details-click="order => handleDetailsClick(order)"
        @new-order="$emit('new-order')"
      />
    </transition>
    <c-confirm-modal
      :btn-props="{ primary: true }"
      cancel-text="Voltar"
      confirm-text="Cancelar Pedido"
      character="embarrassed"
      title="Tem certeza que deseja cancelar o seu pedido?"
      message="Se cancelar, seu pedido não será processado."
      :is-opened="openCancelModal"
      @close="openCancelModal = false; orderId = null"
      @confirm="handleConfirmCancel"
    />
  </div>
</template>

<script>
import FiltersPopover from '@/components/General/FiltersPopover'
import SearchField from '@/components/CComponents/SearchField'
import ListingTable from '@/components/General/ListingTable.vue'
import CConfirmModal from '@/components/CComponents/CConfirmModal'
const ordersStatuses = ['canceled', 'confirmed', 'waiting_payment', 'schedulled']

export default {
  components: {
    FiltersPopover,
    SearchField,
    ListingTable,
    CConfirmModal
  },
  props: {
    loading: Boolean,
    orders: {
      type: Array,
      default () {
        return []
      }
    },
    paginate: [Object, Array]
  },
  data () {
    return {
      orderId: null,
      openCancelModal: false,
      tableCols: [
        { label: 'Nº', align: 'center' },
        { label: 'Pedido', align: 'center' },
        { label: 'Valor', align: 'center' },
        { label: 'Agendado para', align: 'center' },
        { label: 'Status', align: 'center' },
        { label: 'Ação', align: 'center' }
      ],
      search: '',
      someSearchHasSubmitted: false,
      showFilter: false,
      filters: {},
      paramsChanged: false,
      params: {
        limit: 10,
        page: 1
      },
      filtersByStatus: [
        { name: 'Aguardando pagamento', icon: 'barcode', status: 'waiting_payment', selected: false },
        { name: 'Entrega agendada', icon: 'clipboard-check', status: 'schedulled', selected: false },
        { name: 'Pedido entregue', icon: 'award-star', status: 'confirmed', selected: false },
        { name: 'Pedido cancelado', icon: 'cross-circle2', status: 'canceled', selected: false }
      ]
    }
  },
  computed: {
    hasFilters () {
      return this.filters && this.filters.type && this.filters.type.length > 0
    },
    showEmpty () {
      const hasSearch = this.search && this.search.length > 0
      const rowsEmpty = this.rows && this.rows.length === 0
      return !this.loading && (this.someSearchHasSubmitted || hasSearch || this.hasFilters) && rowsEmpty
    },
    rows () {
      return this.orders || []
    }
  },
  methods: {
    async handlePaginate (paginate) {
      this.params = paginate
      this.paramsChanged = true
      await this.submitSearch()
    },
    handleDetailsClick (orderId) {
      window.dataLayer.push({
        event: 'pontos-pedidos-listagem-detalhes'
      })
      this.$router.push({ path: `distribuicao/detalhes/${orderId}` })
    },
    filterOrders (property) {
      const hasType = ordersStatuses.some(type => type.includes(this.filters.type))

      hasType && this.filters.type === property.type
        ? this.filters = {}
        : this.filters = { ...this.filters, ...property }

      const changeStatus = filter => {
        filter.status === property.type
          ? filter.selected = !filter.selected
          : filter.selected = false
        return filter
      }
      this.filtersByStatus.map(changeStatus)
    },
    async clearFilter () {
      this.filters = {}
      this.filtersByStatus = this.filtersByStatus.map(filter => ({ ...filter, selected: false }))
      await this.submitSearch()
    },
    async resetSearch () {
      this.search = ''
      await this.submitSearch()
    },
    handleCancelClick (id) {
      this.orderId = id
      this.openCancelModal = true
    },
    handleConfirmCancel () {
      this.$emit('cancel-order', this.orderId)
      this.openCancelModal = false
      this.orderId = null
    },
    async submitSearch () {
      this.someSearchHasSubmitted = true
      const type = Object.values(this.filters)[0] // mal time
      const hasType = ordersStatuses.some(v => v === type)
      if (this.search || this.filters.manager || hasType || this.paramsChanged) {
        this.$emit('submit-search', { title: this.search, status: type, ...this.params })
        this.paramsChanged = false
      } else {
        this.params.page = 1
        this.$emit('submit-search')
      }
      this.showFilter = false
    }
  }
}
</script>

<style lang="scss">
@import '~@/styles/reference';

.my-orders {
  max-width: 1020px;
  margin-top: 35px;
  padding: 0 10px;

  @include desktop { @include container; }

  .title-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 1020px;
    margin: 48px auto 28px auto;

    > .title {
      max-width: 200px;
    }

    .actions {
      display: flex;
      justify-content: flex-end;

      .order-search {
        margin-right: 20px;

        .input {
          width: 350px;
          background: #FDFDFD;
          border: 1px solid rgba(86, 95, 125, 1);
          border: 1px solid var(--color-secondary);
          transition: all .1s ease;
        }
      }
    }
  }
}
</style>
