<template>
  <c-modal
    class="confirm-unified"
    name="confirm-unified-registration"
    :is-opened="isOpened"
    :is-fullscreen="false"
    @close="$emit('close-confirm-unified')"
  >
    <div class="confirm-unified-content">
      <c-character
        class="character"
        type="embarrassed"
      />

      <div class="information">
        <c-title
          class="title"
          :show-border="false"
        >
          Tem certeza que deseja cadastrar duplicadamente?
        </c-title>

        <p class="warning">
          Lembre-se, com o
          <span class="atention">Cadastro Unificado ativado</span>,
          esta oferta também será cadastrada em outros Clubes aliados à sua conta.
        </p>
      </div>

      <div class="btn-group">
        <c-button
          class="action"
          size="lg"
          @click="$emit('close-confirm-unified')"
        >
          Cancelar
        </c-button>

        <c-button
          class="action"
          primary
          size="lg"
          @click="$emit('accept-confirm-unified')"
        >
          Cadastrar
        </c-button>
      </div>
    </div>
  </c-modal>
</template>

<script>
import CCharacter from '@/components/CComponents/CCharacter'
import CModal from '@/components/CComponents/CModal'

export default {
  components: {
    CCharacter,
    CModal
  },

  props: {
    isOpened: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss">
@import '~@/styles/reference';

.confirm-unified.c-modal {
  @include mobile {
    margin: 0 10px;
    top: 20%;
  }

  @include desktop {
    max-width: 500px;
  }
}

.confirm-unified-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;

  @include desktop {
    padding: 30px 50px;
  }

  & > .information {
    margin: 30px 0;

    .c-title {
      .text {
        justify-content: center;
      }
    }

    & >.title {
      margin-bottom: 30px;
    }

    & > .warning {
      text-align: center;

      > .atention {
        font-weight: bold;
      }
    }
  }

  & > .btn-group {
    display: flex;
    justify-content: center;
    width: 100%;

    & > .action {
      padding: 0 35px;
    }

    & > :first-child {
      margin-right: 20px;
    }
  }
}
</style>
