<template>
  <div class="filters-containers">
    <div class="title">
      <span class="text">
        Filtrar por status
      </span> <c-button @click="$emit('clearFilters'), clearSelection()">
        Limpar seleção
      </c-button>
    </div>
    <filter-group
      class="popover-filter-group"
    >
      <filter-toggler
        v-for="(filter, index) in filtersByStatus"
        class="filter"
        :key="index"
        :name="filter.name"
        :icon="filter.icon"
        :selected="filter.selected"
        @click.native.stop="selectFilter(index)"
      />
    </filter-group>
    <c-button
      class="popover-filter-apply"
      icon="chevron-right"
      size="sm"
      primary
      block
      @click="$emit('apply-filters', applyFilters());"
    >
      Aplicar
    </c-button>
  </div>
</template>

<script>
import FilterToggler from '@/components/Filter/FilterToggler.vue'
import FilterGroup from '@/components/Filter/FilterGroup.vue'
export default {
  components: {
    FilterToggler,
    FilterGroup
  },
  props: {
    activeFilter: Array
  },
  data () {
    return {
      filtersByStatus: [
        { name: 'Aguardando pagamento', icon: 'icon-scaner-barcode-select', status: 'pending', selected: false },
        { name: 'Debitado', icon: 'cash-banknote-hand-arrow', status: 'completed', selected: false },
        { name: 'Pago', icon: 'check', status: 'paid', selected: false },
        { name: 'Cancelado', icon: 'icon-circles-delete-cross', status: 'canceled', selected: false }
      ]
    }
  },
  created () {
    if (this.activeFilter.length > 0) {
      this.filtersByStatus = this.filtersByStatus.map(filter => {
        if (this.activeFilter[0].status === filter.status) {
          return { ...filter, selected: true }
        }
        return filter
      })
    }
  },
  methods: {
    resetFilters () {
      return this.filtersByStatus.map(filter => {
        if (filter.selected) {
          return { ...filter, selected: false }
        }
        return filter
      })
    },

    selectFilter (selectedFilterIndex) {
      this.filtersByStatus = this.filtersByStatus.map((filter, index) => {
        return {
          ...filter,
          selected: (index === selectedFilterIndex) ? !filter.selected : false
        }
      })
    },
    applyFilters () {
      const filters = []
      this.filtersByStatus.forEach(filter => {
        if (filter.selected) filters.push({ status: filter.status })
      })
      return filters
    },

    clearSelection () {
      this.filtersByStatus = this.filtersByStatus.map(element => {
        return { ...element, selected: false }
      })
    }
  }
}
</script>

<style scoped lang='scss'>
@import '~@/styles/reference.scss';
  .filters-containers {
    height: 390px;
    width: 390px;
    padding: 20px;
    .popover-filter-group {
      padding: 0px;
      @include popoverFilterGroup;
    }
    .popover-filter-apply.c-button.-block { position: absolute; }
    h2 {
      font-size: 14px;
      font-weight: 600;
      color:#202950;
      margin-bottom: 8px;
      margin-top: 32px
    }
    .title {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 22px;

      .text {
        font-size: 14px;
        font-weight: 600;
        color:#202950;
        font-family: "Ubuntu", sans-serif;
      }
    }
    .time-period {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      .period {
        width: 205px;
        margin-right: 10px;
        z-index: 4;
      }
      .date {
        width: 205px;
        margin-top: 25px;
      }
    }
    .datepickers {
      // display: flex;
      background: white;
      padding: 25px 20px;
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.16);
      border-radius: 6px;

      .buttons {
        text-align: right;
        position: relative;
        .datepicker-button {
          height: 32px;
          width: 115px;
        }
        .datepicker-button + .datepicker-button {
          margin-left: 16px;
        }
      }
    }
  }

</style>

<style lang="scss">
@import '~@/styles/reference.scss';
.datepicker {
  .vdp-datepicker__calendar {
    border: none;
    padding: 0 20px;
  }
  &.start-date {
    border-right: 0.5px solid #BEC0D3;
  }
  .vdp-datepicker__calendar .cell.selected {
    background: $base-color;
    color: white;
    border-radius: 50%;
  }
}
.initial-calendar {
  .vfc-main-container {
    box-shadow: none;
  }
  .vfc-week .vfc-day span.vfc-span-day.vfc-today {
    background-color: $hover-background-color;
    color: $c-color;
  }
  .vfc-week .vfc-day span.vfc-span-day.vfc-hover:hover, .vfc-week .vfc-day span.vfc-span-day.vfc-hovered {
    background-color: #00B2E3;
  }
  .vfc-week .vfc-day span.vfc-span-day.vfc-marked:before, .vfc-week .vfc-day .vfc-base-start, .vfc-week .vfc-day .vfc-base-end {
    background-color: #D8E0F6;
  }
  .vfc-week .vfc-day span.vfc-span-day.vfc-hover:hover, .initial-calendar .vfc-week .vfc-day span.vfc-span-day.vfc-hovered {
    background-color: #00B2E3;
  }
  .vfc-week .vfc-day span.vfc-span-day.vfc-marked.vfc-borderd:before, .vfc-week .vfc-day span.vfc-span-day.vfc-marked.vfc-start-marked:before, .vfc-week .vfc-day span.vfc-span-day.vfc-marked.vfc-end-marked:before {
    background-color: $base-color;
    border-radius: 50%;
  }
  .vfc-week .vfc-day span.vfc-span-day,
  .vfc-top-date span,
  .vfc-dayNames span {
    color: #5E6684;
  }
  .vfc-navigation-buttons div .vfc-arrow-left, .vfc-separately-navigation-buttons div .vfc-arrow-left,
  .vfc-navigation-buttons div .vfc-arrow-right, .vfc-separately-navigation-buttons div .vfc-arrow-right {
    border-color: #5E6684;
  }

}
</style>
