<template>
  <div class="graph-card">
    <h4 class="title">
      {{ title }}
      <info-button
        icon="info-information-circle"
        class="title-icon"
        :info="info"
      />
    </h4>
    <loading-card v-if="loading" />
    <not-available
      v-else-if="isDataNotAvailable"
      :type="(mainInterestsData && mainInterestsData.error) ? 'error' : 'empty'"
    />

    <div
      v-else
      class="content"
    >
      <div
        class="interest-item"
        v-for="(interest, index) in mainInterestsData.data"
        :key="index"
      >
        <span>{{ index + 1 }}</span>
        <dash-button
          :label="interest.name"
          :icon="interest.icon"
          @click.native="tab = index"
        />
      </div>
    </div>
  </div>
</template>

<script>
import InfoButton from '@/components/Dashboard/base/InfoButton.vue'
import NotAvailable from '@/components/Dashboard/base/notAvailable.vue'
import LoadingCard from '@/components/Dashboard/cards/LoadingCard.vue'
import DashButton from '@/components/Dashboard/base/DashButton.vue'
import { mapGetters, mapActions } from 'vuex'
import { DASH_INTERESTS_WITH_MOST_USERS } from '@/store/types'
export default {
  name: 'MainInterestsCard',
  components: {
    InfoButton,
    LoadingCard,
    DashButton,
    NotAvailable
  },
  props: {
    reloadDataQuery: Boolean
  },
  data () {
    return {
      loading: true,
      title: 'Principais Interesses',
      info: {
        title: 'Principais interesses',
        text: 'Itens mais selecionados como principais interesses por usuários no momento da ativação da conta.'
      }
    }
  },
  computed: {
    ...mapGetters({
      mainInterestsData: DASH_INTERESTS_WITH_MOST_USERS
    }),
    isDataNotAvailable () {
      if (!this.mainInterestsData) return true
      return this.mainInterestsData.error || this.mainInterestsData.data.length === 0
    }
  },
  watch: {
    reloadDataQuery () {
      if (this.reloadDataQuery) { this.getMainInterests() }
    }
  },
  mounted () {
    this.getMainInterests()
  },
  methods: {
    ...mapActions({
      getMainInterestsData: DASH_INTERESTS_WITH_MOST_USERS
    }),
    getMainInterests () {
      this.loading = true
      this.getMainInterestsData().then((res) => {
        this.loading = false
      })
    }
  }
}
</script>

<style lang='scss' scoped>
@import '~@/styles/reference.scss';

.graph-card {
  width: 100%;
  max-width: 334px;
  height: 421px;
  padding: 30px 0 20px 0;
  background: white;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.16);
  border-radius: 6px;

  .title {
    font-family: $base-font-family;
    text-transform: uppercase;
    text-align: center;
    font-weight: 700;
    font-size: 14px;
    color: $text-color;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    .title-icon {
      margin-left: 5px;
    }
  }
  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    & .interest-item {
      display: flex;
      justify-content: space-between;
      align-items: center;

      > span {
        font-weight: 700;
        font-size: 16px;
        margin-right: 10px;
      }

      >  .dash-button {
        width: 260px;
        background: rgba(0, 178, 227, 0.2);
        border: 1px solid #00B2E3;
        margin: 5px;
        cursor: auto;
      }
    }
  }
}
.graph-card + .graph-card {
    .content {
    border-right: 2px solid rgba(18,30,72,0.1);
  }
}
</style>
