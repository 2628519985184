 <template>
  <c-table-container
    class="user-import-table"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <data-table
      :sort="false"
      :cols="cols"
      :rows="rows"
    >
      <template
        slot="row"
        slot-scope="{ row }"
      >
        <td class="data-tablee-cell -content -center">
          <c-button
            v-if="row.report_link && statuses[row.status] && ![4, 5, 6].includes(+row.status)"
            :to="row.report_link"
            is-bold
            size="lg"
            class="result"
            external
            target="_blank"
            @click="$emit('handleAnalytics', 'botao-resultado-importacao')"
          >
            Resultado
          </c-button>
        </td>
        <td
          class="data-tablee-cell -content -center"
          v-if="isDesktop"
        >
          <p>{{ row.user.email }}</p>
        </td>
        <td class="data-tablee-cell -content -center">
          <p>{{ row.created_at | toDate }}</p>
        </td>
        <td class="data-tablee-cell -content -center">
          <c-button
            size="lg"
            class="user-import-table-preview-button"
            v-if="+row.status === 0"
            @click="$emit('cancel-import', row.id)"
          >
            Cancelar
          </c-button>

          <c-button
            primary
            size="lg"
            class="user-import-table-preview-button"
            v-else-if="+row.status === 1"
            @click="$emit('get-status', row.id)"
          >
            Confirmar
          </c-button>

          <c-label
            v-else-if="statuses[row.status]"
            class="user-import-table-status"
            :color="statuses[row.status].color"
          >
            {{ statuses[row.status].label }}
          </c-label>
        </td>
      </template>
    </data-table>
  </c-table-container>
</template>

<script>
const statuses = {
  2: {
    label: 'Processando',
    color: '#f5a623'
  },
  3: {
    label: 'Concluído',
    color: '#4CAD50'
  },
  4: {
    label: 'Cancelado',
    color: '#EE5250'
  },
  5: {
    label: 'Erro',
    color: '#EE5250'
  },
  6: {
    label: 'Arquivo inválido',
    color: '#EE5250'
  }
}

export default {
  components: {
    DataTable: () => import('vue-data-tablee'),
    CTableContainer: () => import('@/components/CComponents/CTableContainer'),
    CLabel: () => import('@/components/CComponents/CLabel')
  },
  filters: {
    toDate (date) {
      return new Date(date * 1000).toLocaleDateString('pt-BR', { timeZone: 'America/Sao_Paulo' })
    }
  },
  props: {
    rows: Array
  },
  data () {
    return {
      statuses
    }
  },
  computed: {
    cols () {
      return [
        { label: 'Arquivo', align: 'center' },
        ...(this.isDesktop ? [{ label: 'Solicitante', align: 'center' }] : []),
        { label: 'Data', align: 'center' },
        { label: 'Status', align: 'center' }
      ]
    }
  }
}
</script>

<style lang="scss">
@import '~@/styles/reference';

//.user-import-table > .table { background-color: white; }
.result { border: 1px solid rgba(18, 30, 72, 0.05); }
.user-import-table-status { width: 135px; }
.user-import-table-preview-button { width: 135px; }
</style>
