<template>
  <under-construction-card
    v-if="underConstruction"
    :tab-name="tabName"
  />
  <div
    v-else
    class="dashboard-users-tab"
  >
    <div class="donuts-cards">
      <banners-table
        :loading="loading"
        :table-data="banners"
        :selectedfilters="['search']"
      />
    </div>
    <div class="donuts-cards">
      <banners-table
        :loading="loading"
        :table-data="banners2"
      />
    </div>
    <div class="donuts-cards">
      <communication-spine
        :loading="loading"
        :data="usersAccessesData"
      />
    </div>
    <div class="donuts-cards">
      <banners-table
        :loading="loading"
        :table-data="notifications"
        :selectedfilters="['search']"
      >
        <banner-graph />
      </banners-table>
    </div>
  </div>
</template>

<script>
import CommunicationSpine from '@/components/Dashboard/graphics/CommunicationSpine.vue'
import BannersTable from '@/components/Dashboard/BannersTable.vue'
import BannerGraph from '@/components/Dashboard/graphics/BannerGraph.vue'
import UnderConstructionCard from '@/components/Dashboard/cards/UnderConstructionCard.vue'

export default {
  name: 'DashboardCommunicationTab',
  components: {
    CommunicationSpine,
    BannersTable,
    BannerGraph,
    UnderConstructionCard
  },
  props: {
    underConstruction: Boolean,
    tabName: String
  },
  data () {
    return {
      loading: false,
      usersAccessesData: {
        tabs: [
          [
            {
              title: 'Banner home',
              total: 32,
              label: 'Banners ativos no período',
              icon: 'info-information-circle',
              comparisonValue: 10,
              data: [],
              info: {
                title: 'Banner home',
                text: 'Banner home'
              }
            },
            {
              total: 10000,
              label: 'Cliques em banners no período',
              icon: 'info-information-circle',
              comparisonValue: 10,
              data: []
            }
          ],
          [
            {
              title: 'Banner de resultado',
              total: 200,
              label: 'Banners ativos no período',
              icon: 'info-information-circle',
              comparisonValue: 10,
              data: [],
              info: {
                title: 'Banner de resultado',
                text: 'banner de resultado'
              }
            },
            {
              total: 4000,
              label: 'Cliques em banners no período',
              icon: 'info-information-circle',
              comparisonValue: 10,
              data: []
            }
          ]
        ],
        columns: [ [
          ['x', '2013-01-01', '2013-01-02', '2013-01-03', '2013-01-04', '2013-01-05'],
          ['data1', 30, 200, 100, 400, 150],
          ['data2', 130, 340, 200, 500, 250]
        ],
        [
          ['x', '2013-01-01', '2013-01-02', '2013-01-03', '2013-01-04', '2013-01-05', '2013-01-06'],
          ['data1', 300, 20, 10, 40, 150, 250],
          ['data2', 150, 360, 400, 200, 250, 350]
        ] ]
      },
      banners: {
        data: [
          [
            {
              banner: 'https://clube-newcore.s3.us-west-2.amazonaws.com/perks-logo/XvoPrdPh3E8uVYPZ.png',
              clicks: '1000 (50%)',
              partner: 'Playstation Store',
              campaign: 'Aproveite roupas, perfumes e',
              online: 21,
              dates: 'de 10/09/2022 \naté 09/11/2010'
            },
            {
              banner: 'https://clube-newcore.s3.us-west-2.amazonaws.com/perks-logo/XvoPrdPh3E8uVYPZ.png',
              clicks: '1000 (50%)',
              partner: 'Playstation Store',
              campaign: 'Aproveite roupas, perfumes e',
              online: 21,
              dates: 'de 10/09/2022 \naté 09/11/2010'
            },
            {
              banner: 'https://clube-newcore.s3.us-west-2.amazonaws.com/perks-logo/XvoPrdPh3E8uVYPZ.png',
              clicks: '1000 (50%)',
              partner: 'Playstation Store',
              campaign: 'Aproveite roupas, perfumes e',
              online: 21,
              dates: 'de 10/09/2022 \naté 09/11/2010'
            },
            {
              banner: 'https://clube-newcore.s3.us-west-2.amazonaws.com/perks-logo/XvoPrdPh3E8uVYPZ.png',
              clicks: '1000 (50%)',
              partner: 'Playstation Store',
              campaign: 'Aproveite roupas, perfumes e',
              online: 21,
              dates: 'de 10/09/2022 \naté 09/11/2010'
            }
          ]
        ],
        labels: [
          [
            {
              name: 'Banner',
              column: 'banner',
              isImage: true
            },
            {
              name: 'Cliques no período',
              column: 'clicks'
            },
            {
              name: 'Parceria',
              column: 'partner'
            },
            {
              name: 'Oferta',
              column: 'campaign'
            },
            {
              name: 'Dias no ar',
              column: 'online'
            },
            {
              name: 'Data inicial/final',
              column: 'dates'
            }
          ],
          [
            {
              name: 'Banner',
              column: 'banner',
              isImage: true
            },
            {
              name: 'Cliques no período',
              column: 'clicks'
            },
            {
              name: 'Parceria',
              column: 'partner'
            },
            {
              name: 'Oferta',
              column: 'campaign'
            },
            {
              name: 'Dias no ar',
              column: 'online'
            },
            {
              name: 'Data inicial/final',
              column: 'dates'
            }
          ]
        ],
        tabs: [
          [
            {
              title: 'Banner Home - Exclusivos',
              total: 10,
              label: 'Banners Exclusivos ativos no período',
              icon: 'info-information-circle',
              comparisonValue: 10,
              data: [],
              info: {
                title: 'Banner home - exclusivos',
                text: 'banner home - exclusivos'
              }
            },
            {
              total: 10,
              label: 'Cliques no período',
              icon: 'info-information-circle',
              comparisonValue: 10,
              data: []
            }
          ],
          [
            {
              title: 'Banner Home - Gerais',
              total: 100,
              label: 'Banners home ativos no período',
              icon: 'info-information-circle',
              comparisonValue: 100,
              data: [],
              info: {
                title: 'Banner home - gerais',
                text: 'banner home - gerais'
              }
            },
            {
              total: 100,
              label: 'Cliques no período',
              icon: 'info-information-circle',
              comparisonValue: 10,
              data: []
            }
          ]
        ]
      },
      banners2: {
        data: [
          [
            {
              banner: 'https://clube-newcore.s3.us-west-2.amazonaws.com/perks-logo/XvoPrdPh3E8uVYPZ.png',
              clicks: '1000 (50%)',
              partner: 'Playstation Store',
              campaign: 'Aproveite roupas, perfumes e',
              online: 21,
              dates: 'de 10/09/2022 \naté 09/11/2010'
            },
            {
              banner: 'https://clube-newcore.s3.us-west-2.amazonaws.com/perks-logo/XvoPrdPh3E8uVYPZ.png',
              clicks: '1000 (50%)',
              partner: 'Playstation Store',
              campaign: 'Aproveite roupas, perfumes e',
              online: 21,
              dates: 'de 10/09/2022 \naté 09/11/2010'
            },
            {
              banner: 'https://clube-newcore.s3.us-west-2.amazonaws.com/perks-logo/XvoPrdPh3E8uVYPZ.png',
              clicks: '1000 (50%)',
              partner: 'Playstation Store',
              campaign: 'Aproveite roupas, perfumes e',
              online: 21,
              dates: 'de 10/09/2022 \naté 09/11/2010'
            },
            {
              banner: 'https://clube-newcore.s3.us-west-2.amazonaws.com/perks-logo/XvoPrdPh3E8uVYPZ.png',
              clicks: '1000 (50%)',
              partner: 'Playstation Store',
              campaign: 'Aproveite roupas, perfumes e',
              online: 21,
              dates: 'de 10/09/2022 \naté 09/11/2010'
            }
          ],
          [
            {
              banner: 'https://clube-newcore.s3.us-west-2.amazonaws.com/perks-logo/XvoPrdPh3E8uVYPZ.png',
              conversion: '50%',
              views: '1.000',
              campaign: 'Aproveite roupas, perfumes e',
              online: 21,
              dates: 'de 10/09/2022 \naté 09/11/2010'
            },
            {
              banner: 'https://clube-newcore.s3.us-west-2.amazonaws.com/perks-logo/XvoPrdPh3E8uVYPZ.png',
              conversion: '50%',
              views: '1.000',
              campaign: 'Aproveite roupas, perfumes e',
              online: 21,
              dates: 'de 10/09/2022 \naté 09/11/2010'
            },
            {
              banner: 'https://clube-newcore.s3.us-west-2.amazonaws.com/perks-logo/XvoPrdPh3E8uVYPZ.png',
              conversion: '50%',
              views: '1.000',
              campaign: 'Aproveite roupas, perfumes e',
              online: 21,
              dates: 'de 10/09/2022 \naté 09/11/2010'
            },
            {
              banner: 'https://clube-newcore.s3.us-west-2.amazonaws.com/perks-logo/XvoPrdPh3E8uVYPZ.png',
              conversion: '50%',
              views: '1.000',
              campaign: 'Aproveite roupas, perfumes e',
              online: 21,
              dates: 'de 10/09/2022 \naté 09/11/2010'
            }
          ]
        ],
        labels: [
          [
            {
              name: 'Banner',
              column: 'banner',
              isImage: true
            },
            {
              name: 'Cliques no período',
              column: 'clicks'
            },
            {
              name: 'Parceria',
              column: 'partner'
            },
            {
              name: 'Oferta',
              column: 'campaign'
            },
            {
              name: 'Dias no ar',
              column: 'online'
            },
            {
              name: 'Data inicial/final',
              column: 'dates'
            }
          ],
          [
            {
              name: 'Banner',
              column: 'banner',
              isImage: true
            },
            {
              name: 'Taxa de conversão',
              column: 'conversion'
            },
            {
              name: 'visualizações no período ',
              column: 'views'
            },
            {
              name: 'Oferta',
              column: 'campaign'
            },
            {
              name: 'Dias no ar',
              column: 'online'
            },
            {
              name: 'Data inicial/final',
              column: 'dates'
            }
          ]
        ],
        tabs: [
          [
            {
              title: 'Banner Resultados',
              total: 10,
              label: 'Banners de Resultados ativos no período',
              icon: 'info-information-circle',
              comparisonValue: 10,
              data: [],
              filterTitle: 'Resultados',
              info: {
                title: 'Banner resultados',
                text: 'banner resultados'
              }
            },
            {
              total: 10,
              label: 'Cliques no período',
              icon: 'info-information-circle',
              comparisonValue: 10,
              data: []
            }
          ],
          [
            {
              title: 'Banner pop-up',
              total: 100,
              label: 'Banners pop-up ativos no período',
              icon: 'info-information-circle',
              comparisonValue: 100,
              data: [],
              filterTitle: 'Pop-up',
              info: {
                title: 'Banner pop up',
                text: 'Banner pop up'
              }
            },
            {
              total: 100,
              label: 'Cliques no período',
              icon: 'info-information-circle',
              comparisonValue: 10,
              data: []
            }
          ]
        ]
      },
      notifications: {
        slot: true,
        slotTable: 1,
        data: [
          [
            {
              banner: 'https://clube-newcore.s3.us-west-2.amazonaws.com/perks-logo/XvoPrdPh3E8uVYPZ.png',
              clicks: '1000 (50%)',
              partner: 'Abbraccio',
              campaign: 'Abbraccio compre e ganhe D',
              online: 28,
              dates: 'de 10/09/2022 \naté 09/11/2010'
            },
            {
              banner: 'https://clube-newcore.s3.us-west-2.amazonaws.com/perks-logo/XvoPrdPh3E8uVYPZ.png',
              clicks: '1000 (50%)',
              partner: 'Playstation Store',
              campaign: 'Aproveite roupas, perfumes e',
              online: 27,
              dates: 'de 10/09/2022 \naté 09/11/2010'
            },
            {
              banner: 'https://clube-newcore.s3.us-west-2.amazonaws.com/perks-logo/XvoPrdPh3E8uVYPZ.png',
              clicks: '1000 (50%)',
              partner: 'Playstation Store',
              campaign: 'Aproveite roupas, perfumes e',
              online: 60,
              dates: 'de 10/09/2022 \naté 09/11/2010'
            },
            {
              banner: 'https://clube-newcore.s3.us-west-2.amazonaws.com/perks-logo/XvoPrdPh3E8uVYPZ.png',
              clicks: '1000 (50%)',
              partner: 'Playstation Store',
              campaign: 'Aproveite roupas, perfumes e',
              online: 60,
              dates: 'de 10/09/2022 \naté 09/11/2010'
            }
          ]
        ],
        labels: [
          [{
            name: 'Banner',
            column: 'banner',
            isImage: true
          },
          {
            name: 'Cliques no período',
            column: 'clicks'
          },
          {
            name: 'Parceria',
            column: 'partner'
          },
          {
            name: 'Oferta',
            column: 'campaign'
          },
          {
            name: 'Dias no ar',
            column: 'online'
          },
          {
            name: 'Data inicial/final',
            column: 'dates'
          }
          ],
          [{
            name: 'Banner',
            column: 'banner',
            isImage: true
          },
          {
            name: 'Cliques no período',
            column: 'clicks'
          },
          {
            name: 'Parceria',
            column: 'partner'
          },
          {
            name: 'Oferta',
            column: 'campaign'
          },
          {
            name: 'Dias no ar',
            column: 'online'
          },
          {
            name: 'Data inicial/final',
            column: 'dates'
          }
          ]
        ],
        tabs: [
          [
            {
              title: 'Notificações',
              total: 10,
              label: 'Banners Exclusivos ativos no período',
              icon: 'info-information-circle',
              comparisonValue: 10,
              data: [],
              info: {
                title: 'Notificações',
                text: 'notificações'
              }
            },
            {
              total: 10,
              label: 'Cliques no período',
              icon: 'info-information-circle',
              comparisonValue: 10,
              data: []
            }
          ],
          [
            {
              title: 'Email newsletter kaledo',
              total: 8000,
              label: 'Total de usuários optantes\nno período',
              icon: 'info-information-circle',
              comparisonValue: 'not-set',
              data: [],
              info: {
                title: 'Email newsletter kaledo',
                text: 'email newsletter kaledo'
              }
            }
          ]
        ]
      }
    }
  },
  mounted () {
    this.loading = true
    setTimeout(() => {
      this.loading = false
    }, 5000)
  }
}
</script>

<style lang="scss" scoped>
@import '~@/styles/reference.scss';

.dashboard-users-tab {
  width: 100%;
  .donuts-cards {
    display: grid;
    max-width: 100%;
    margin-top: 20px;
    .graph-card .registration-info-card {
      position: relative;
      z-index: 3;
      background: #FDFDFD;
      padding: 16px;
      box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.16);
      border-radius: 0px 0px 6px 6px;
      > .graph-card {
        width: 100%;
        min-width: 290px;
        box-shadow: none;
      }
    }
  }
}

</style>
