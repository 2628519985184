<template>
  <c-popover
    v-if="show"
    :position="['top', 'right']"
    :target="target"
    @blur="$emit('change-show', false)"
  >
    <div class="filters">
      <filter-group
        v-if="categories"
        class="popover-filter-group"
        title="Por categorias"
      >
        <c-button
          slot="actions"
          size="lg"
          @click.native.stop="$emit('clean', 'filter')"
        >
          Limpar seleção
        </c-button>

        <filter-toggler
          class="filter"
          v-for="filter in categories"
          :key="filter.name"
          :name="filter.name"
          :icon="filter.icon"
          :selected="params[filter.action]"
          @click.native.stop="$emit('handleCategory', filter.action)"
        />
      </filter-group>

      <filter-group
        class="popover-filter-group"
        title="Por status"
      >
        <c-button
          v-if="!categories"
          slot="actions"
          size="lg"
          @click.native.stop="$emit('clean', 'filter')"
        >
          Limpar seleção
        </c-button>
        <filter-toggler
          class="filter"
          v-for="filter in status"
          :key="filter.name"
          :name="filter.name"
          :icon="filter.icon"
          :selected="params[filter.action]"
          @click.native.stop="$emit('handleStatus', filter.action)"
        />
      </filter-group>
    </div>

    <c-button
      class="popover-filter-apply"
      icon="chevron-right"
      size="sm"
      primary
      block
      @click="$emit('apply-filters'); $emit('change-show', false)"
    >
      Aplicar
    </c-button>
  </c-popover>
</template>

<script>
import CPopover from '@/components/CComponents/CPopover'
import FilterGroup from '@/components/Filter/FilterGroup'
import FilterToggler from '@/components/Filter/FilterToggler'

export default {
  name: 'FilterPopover',
  components: {
    CPopover,
    FilterGroup,
    FilterToggler
  },
  props: {
    show: Boolean,
    target: [String, HTMLElement, Object],
    categories: [Array, Boolean],
    params: Object,
    status: Array
  }
}
</script>

<style lang='scss' scoped>
@import '~@/styles/reference';

.c-popover > .content > .filters { @include popoverFilter; }

.popover-filter-group { @include popoverFilterGroup; }
.popover-filter-apply.c-button.-block { position: absolute; }
</style>
