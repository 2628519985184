<template>
  <div
    class="catalog"
    data-cy="catalog"
  >
    <c-title>
      Catálogo de Recompensas
    </c-title>
    <div class="catalog-body">
      <transition
        name="fade"
        mode="out-in"
      >
        <c-spinner v-if="loading" />
        <div
          v-else-if="catalog && catalog.length > 0"
          class="catalog-container"
        >
          <div class="catalog-list">
            <catalog-card
              v-for="ctlg in catalog"
              :key="ctlg.id"
              :catalog="ctlg"
              @toggle-catalog-status="handleToggleCatalogStatus"
            />
          </div>
          <c-paginate
            class="paginate"
            :is-fetching="loading"
            :paginate="catalogPagination"
            @update-paginate="updatePaginate($event)"
          />
        </div>
        <c-empty
          v-else
          class="empty-catalog"
          title="Ops!"
          character="searching"
          message="Não encontramos nenhuma oferta no catálogo"
        />
      </transition>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { CATALOG, GET_CATALOG, CATALOG_PAGINATION, PARTNER_TOGGLE_STATUS } from '@/store/types'
import CPaginate from '@/components/CComponents/CPaginate'
import CatalogCard from '@/components/Engagement/Personalization/CatalogCard.vue'
export default {
  name: 'Catalog',
  components: {
    CatalogCard,
    CPaginate
  },
  data () {
    return {
      loading: true
    }
  },
  computed: {
    ...mapGetters({
      catalog: CATALOG,
      catalogPagination: CATALOG_PAGINATION
    })
  },
  mounted () {
    this.initCatalog(1, 12)
  },
  methods: {
    ...mapActions({
      getCatalog: GET_CATALOG,
      toggleCatalogStatus: PARTNER_TOGGLE_STATUS
    }),
    async initCatalog (page, limit) {
      this.loading = true
      await this.getCatalog({
        page,
        limit
      })
      this.loading = false
    },
    updatePaginate (newPaginate) {
      const { page, limit } = newPaginate
      this.initCatalog(page, limit)
    },
    handleToggleCatalogStatus (payload) {
      this.toggleCatalogStatus(payload)
    }
  }
}
</script>

<style lang='scss' scoped>
@import '~@/styles/reference';
.catalog {
  margin-top: 48px;
  .catalog-body {
    min-height: 576px;
  }
  .catalog-container {
    margin-top: 24px;
    .catalog-list {
      display: grid;
      gap: 10px;
      margin-bottom: 10px;
      grid-template-columns: repeat(6,1fr);
      @include mobile {
        grid-template-columns: 1fr 1fr;
      }
      @include tablet {
        grid-template-columns: repeat(4, 1fr);
        gap: 16px;
      }
      @media only screen and (max-width: ($mobile-break - 1)) {
        grid-template-columns: 1fr;
      }
    }
  }
}
</style>
