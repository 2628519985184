<template>
  <div class="distribution-details-container">
    <order-details
      :loading="loading"
      :order="order"
      @cancel-order="handleCancelOrder"
      @send-invoice="openInvoicePerEmailModal = true"
    />
    <invoice-per-email-modal
      show-close
      :is-opened="openInvoicePerEmailModal"
      @close="openInvoicePerEmailModal = false"
    />
  </div>
</template>

<script>
import OrderDetails from '~/components/Engagement/Distribution/Details/OrderDetails'
import InvoicePerEmailModal from '~/components/General/modals/InvoicePerEmailModal'
import Loadable from '@/modules/loadable'
import { COMPANY, DISTRIBUTION_ORDER, GET_DISTRIBUTION_ORDER_DETAILS, CANCEL_DISTRIBUTION_ORDER } from '@/store/types'
import { mapGetters, mapActions } from 'vuex'

export default {
  components: { OrderDetails, InvoicePerEmailModal },
  mixins: [Loadable],
  data () {
    return {
      loading: true,
      orderId: null,
      openInvoicePerEmailModal: false
    }
  },
  computed: {
    ...mapGetters({
      order: DISTRIBUTION_ORDER
    })
  },
  methods: {
    ...mapActions({
      getOrder: GET_DISTRIBUTION_ORDER_DETAILS,
      cancelOrder: CANCEL_DISTRIBUTION_ORDER,
      getCompany: COMPANY
    }),
    async load () {
      this.loading = true
      this.orderId = this.$route.params.orderId
      await this.getOrder(this.orderId)
      this.loading = false
    },
    handleLinkClick () {
      this.openAwardOrderModalInfo = false
    },
    async handleCancelOrder () {
      this.loading = true
      const response = await this.cancelOrder({
        id: this.orderId
      })
      this.loading = false
      if (response && response.data && response.data.success) {
        this.$router.push({ name: 'engajamento-distribuicao' })
        await this.getCompany()
      }
    }
  }
}
</script>

<style>
</style>
