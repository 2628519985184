<template>
  <div>
    <component-header @return="$router.push('/carteira')" />
    <orders-details />
  </div>
</template>

<script>
import OrdersDetails from '@/containers/User/Wallet/OrdersDetails.vue'
import ComponentHeader from '@/components/General/ComponentHeader.vue'
export default {
  components: { OrdersDetails, ComponentHeader }

}
</script>

<style>
</style>
