<template>
  <div
    data-cy="selected-rows-total-tag"
    class="c-tag"
  >
    <slot/>
    <k-icon
      v-if="icon"
      :icon="icon"
    />
  </div>
</template>

<script>
import { KIcon } from 'kaledo-components'

export default {
  components: { KIcon },
  props: {
    icon: String,
  }
}
</script>

<style lang="scss" scoped>
.c-tag {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 173px;
  padding: 4px 8px;
  gap: 8px;
  background: rgba(190, 192, 211, 0.6);
  border-radius: 8px;
  font-weight: 700;
  font-size: 12px;
  line-height: 18px;
  color: #202950;
}
</style>
