<template>
  <div
    class="select-account-content"
    data-cy="select-account-content"
  >
    <div class="content-box">
      <c-title
        pink
        class="title"
      >
        <slot>Olá,</slot>
      </c-title>

      <p class="text">
        Você está ativo no(s) seguinte(s) clube(s) abaixo.<br />
        Clique em qual deseja acessar.
      </p>

      <swiper
        :class="carouselClasses"
        :options="swiperOptions"
      >
        <swiper-slide
          v-for="company in companies"
          :key="company.id"
        >
          <c-button
            pink-border
            class="club-logo"
            :disabled="isLoading"
            @click="$emit('submit', company.id)"
          >
            <c-image
              class="partner-logo"
              :src="company.logo"
              :placeholder="imagePlaceholder"
            />
          </c-button>

          <p class="club-name">
            {{ company.name }}
          </p>
        </swiper-slide>

        <div
          class="swiper-button -prev"
          slot="button-prev"
        >
          <c-icon
            class="icon"
            icon="chevron-left"
            size="22"
          />
        </div>

        <div
          class="swiper-button -next"
          slot="button-next"
        >
          <c-icon
            class="icon"
            icon="chevron-right"
            size="22"
          />
        </div>
      </swiper>

      <p class="text-info">
        Não encontrou o clube que deseja acessar?
        <router-link
          :to="{ name: 'ativacao-ativar-conta' }"
          class="link"
        >
          Clique aqui
        </router-link>
        para ativar sua conta ou clique em "cancelar" para voltar.
      </p>

      <div class="btn-group">
        <c-button
          size="lg"
          class="btn"
          default-border
          :disabled="isLoading"
          @click="$router.push({ name: 'login' })"
        >
          Cancelar
        </c-button>
      </div>
    </div>
  </div>
</template>

<script>
import CImage from '@/components/CComponents/CImage'
import { swiper, swiperSlide } from 'vue-awesome-swiper'

export default {
  components: {
    CImage,
    swiper,
    swiperSlide
  },
  props: {
    companies: {
      type: Array,
      required: true
    },
    isLoading: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      imagePlaceholder: require('@/assets/img/campaign-placeholder.svg'),
      swiperOptions: {
        spaceBetween: 20,
        slidesPerView: 3,
        navigation: {
          nextEl: '.swiper-button.-next',
          prevEl: '.swiper-button.-prev',
          disabledClass: '-disabled',
          hiddenClass: '-hidden'
        },
        breakpoints: {
          500: { slidesPerView: 2, spaceBetween: 40 },
          768: { slidesPerView: 3, spaceBetween: 60 }
        }
      }
    }
  },
  computed: {
    carouselClasses () {
      return [
        'carousel',
        { '-center-2': this.companies.length === 2,
          '-center': this.companies.length === 1
        }
      ]
    }
  }
}
</script>

<style lang="scss">
@import '~@/styles/reference';

.select-account-content {
  @include LoginContent;

  & > .content-box {
    @include LoginBox;
    @include desktop { width: 500px; padding: 50px; }

    flex-direction: column !important;
    padding: 20px 30px;

    & >.title {
      & > .content {
        & > .text {
          font-size: 20px;
          font-weight: 500;
        }
      }
    }
    & >.text { margin-top: 20px; font-size: 16px; }

    & >.carousel {
      margin: 0 auto;
      margin-top: 45px;
      position: relative;
      overflow: hidden;
      width: 100%;

      &.-center-2 .swiper-wrapper { justify-content: space-around; }
      &.-center-2 .swiper-slide:last-child { margin-right: 0px !important; }

      &.-center .swiper-wrapper { justify-content: center; }
      &.-center .swiper-slide:last-child { margin-right: 0px !important; }

      .swiper-wrapper { display: flex; }

      .swiper-slide {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 130px;
        min-width: 100px;

        & > .club-logo {
          width: 85px;
          height: 85px;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          border: 1px solid rgba(18, 30, 72, 0.1);
          border-radius: 50%;

          box-shadow: 1px 1px 4px 0 rgba(0,0,0,0.1);

          &:hover { border: 3px solid #DF449B; }
          img { width: 45px; display: block; }
        }

        & > .club-name {
          text-align: center;
          font-size: 14px;
          font-weight: bold;
          opacity: 0.8;
          color: #121E48;

          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          width: calc(100% - 15px);

          margin-top: 10px;
        }

        & > .item {
          width: 150px;
          height: 75px;
          display: flex;
          justify-content: center;
          align-items: center;

          img { max-width: 60px; max-height: 40px; }
        }

        & > .text {
          margin-top: 5px;
          text-transform: uppercase;
          font-size: 11px;
          color: rgba(18,30,72,0.8);
        }
      }

      & > .swiper-button {
        z-index: 20;
        display: block;
        position: absolute;
        cursor: pointer;
        top: calc(50% - 15px);
        border-radius: 50%;
        width: 60px;
        height: 60px;
        transition: opacity .3s ease;

        background-color: rgba(#FFF, 0.8);
        transition: background-color 200ms;

        &:hover { background-color: #FFF; }

        & > .c-icon.icon {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
        }

        &.-disabled,
        &.-hidden { opacity: 0; }

        &.-prev {
          left: 0;
          transform: translate(-50%, -50%);

          & > .c-icon.icon { right: 8px; }
        }

        &.-next {
          right: 0;
          transform: translate(50%, -50%);

          & > .c-icon.icon { left: 8px; }
        }
      }
    }

    & > .text-info {
      font-size: 14px;
      line-height: 19px;
      margin: 30px 0;

      & > .link {
        display: inline-block;
        color: $color-primary-login;
        font-weight: 600;
      }
    }

    & > .btn-group {
      display: flex;
      justify-content: space-around;

      & > .btn { width: calc(100% - 5px); }
    }
  }
}
</style>
