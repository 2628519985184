<template>
  <c-table-container
    class="listing-table"
    :class="isRowsEmpty ? '-inactive' : ''"
    v-bind="$attrs"
    v-on="$listeners"
    :paginate="paginate"
  >
    <data-table
      :sort="false"
      :cols="cols"
      :rows="rows"
      class="-table"
    >
      <template
        slot="row"
        slot-scope="{ row }"
      >
        <td :class="tdClass">
          <span
            class="td-link"
            @click="goToDetails(row.id)"
          >
            {{ row.order_id }}
          </span>
        </td>
        <td :class="tdClass">
          <div class="td-content">
            <k-icon
              v-if="row.type === 1"
              class="icon-up"
              icon="arrow-up1"
              size="20"
            />
            <k-icon
              v-else
              class="icon-down"
              icon="arrow-down"
              size="20"
            />
            <span lass="td-span">{{ formatCurrency(row.value) }}</span>
          </div>
        </td>
        <td :class="tdClass">
          <span>
            {{ formatString (row.type) }}
          </span>
        </td>
        <td :class="tdClass">
          <span class="td-span">{{ row.user.first_name }}</span>
        </td>
        <td :class="tdClass">
          <span class="td-span">{{ formatDate(row.created_at) }}</span>
        </td>
        <td :class="tdClass">
          <c-label
            :class="row.status === 3 ? 'table-status-complete table-status' : 'table-status'"
            :color="statusColor(row)"
            :text-color="statusText(row)"
          >
            {{ statusLabel(row) }}
          </c-label>
        </td>
      </template>
    </data-table>
    <div
      class="empty-content"
      v-if="rows.length === 0 && hasEmptyBanner"
    >
      <empty-banner
        button="Adicionar saldo"
        :title="emptyBannerText"
        @button-click="$emit('new-order')"
      />
    </div>
  </c-table-container>
</template>

<script>
import { timestampToOnlyDate } from '@/modules/validate/helpers.js'
import EmptyBanner from '@/components/General/EmptyBanner'
import { KIcon } from 'kaledo-components'
import { COMPANY } from '@/store/types'
import { mapGetters } from 'vuex'

const statuses = {
  0: {
    label: 'Cancelado',
    color: '#FF5967'
  },
  1: {
    label: 'Pago',
    color: '#3CE797'
  },
  2: {
    label: 'Aguardando Pagamento',
    color: '#FFC330'
  },
  3: {
    label: 'Debitado',
    color: '#003396',
    textColor: 'white'
  }

}

export default {
  components: {
    KIcon,
    EmptyBanner,
    DataTable: () => import('vue-data-tablee'),
    CTableContainer: () => import('@/components/CComponents/CTableContainer'),
    CLabel: () => import('@/components/CComponents/CLabel')
  },
  props: {
    rows: Array,
    cols: Array,
    emptyBannerText: String,
    hasEmptyBanner: Boolean,
    paginate: Object
  },
  data () {
    return {
      statuses,
      tdClass: 'data-tablee-cell -content -center'
    }
  },
  computed: {
    ...mapGetters({
      company: COMPANY
    }),
    formatedRows () {
      if (this.rows.length && this.rows.length < 5) {
        let array = []
        const diff = 5 - this.rows.length
        for (let i = 0; i < diff; i++) {
          array.push({})
        }
        array = this.rows.concat(array)
        return array
      }
      return this.rows
    },
    isRowsEmpty () {
      return this.rows.length === 0
    }
  },
  methods: {
    goToDetails (id) {
      window.dataLayer.push({
        event: 'carteira-visualizar-detalhes',
        company_name: this.company.slug
      })
      this.$emit('details-click', id)
    },
    disableCancelFunction (status) {
      return status === 0 || status === 1 || status === 4
    },
    formatCurrency (value) {
      return (value / 100).toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL'
      })
    },
    formatString (type) {
      if (type === 1) return 'Adição de saldo'
      else if (type === 2) return 'Distribuição de prêmios'
      else return 'Distribuição de pontos'
    },
    formatDate (date) {
      const formatedDate = timestampToOnlyDate(date)
      return formatedDate
    },
    statusText (row) {
      if (!row || !this.statuses[row.status].textColor) return 'inherit'
      return this.statuses[row.status].textColor
    },
    statusColor (row) {
      if (!row || !this.statuses[row.status].color) return '#000'
      return this.statuses[row.status].color
    },
    statusLabel (row) {
      if (!row || !this.statuses[row.status].label) return 'Carregando...'
      return this.statuses[row.status].label
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/styles/reference';

.result { border: 1px solid rgba(18, 30, 72, 0.05); }
.listing-table-preview-button { width: 135px; }
.listing-table {
  &.-inactive {
    :deep > .table .-header .data-tablee-text {
      color: #D8D8D8;
    }
  }
  :deep > .table {
    border: none;
    .-header {
      background: #FDFDFD;
      padding: 20px;
    }
  }
  .-table {
    background: #FDFDFD;
    box-shadow: 0px 4px 8px rgba(94, 102, 132, 0.08);
    border-radius: 4px;
    border: none;
  }
  td.span {
    color: #5E6684;
    font-size: 14px;
  }
  .td-link {
    color: #608CEA;
    font-size: 14px;
    cursor: pointer;
    text-decoration: underline;

    :visited {
      color: #608CEA;
    }
  }
  td {
    height: 80px;
  }
  .td-content {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }
  .icon-up {
    color: #3CE797;
  }
  .icon-down {
    color: #FF5967;
  }
  .table-status-complete > :deep .text{
    color: #FDFDFD !important;
  }
  .table-status {
    width: 151px;
    height: 30px;

    > :deep .text{
      text-transform: none;
      font-size: 12px;
      font-weight: 400;
    }
  }
  .table-actions {
    display: flex;
    justify-content: center;

    .icon-action {
      font-size: 20px;
      margin: 3px;
      cursor: pointer;
      padding: 10px;
      border: 1px solid rgba(94, 102, 132, 1);
      border-radius: 50px;
      transition: all .1s ease;

      &:hover {
        border-width: 2px;
      }

      &.-disabled {
        background: rgba(216, 216, 216, 1);
        border: none;
      }
    }
  }

  .empty-content {
    padding: 20px;
    background: white;
  }
}
</style>
