<template>
  <div class="filters-popover">
    <div class="header-popover">
      <component
        :is="isDesktop ? 'c-popover' : 'c-modal'"
        v-if="showFilter"
        :is-opened="showFilter"
        name="filter"
        :position="['top', 'right']"
        :target="$refs['filterToggle']"
        v-on="$listeners"
      >
        <div class="filters">
          <filter-group
            data-cy="filter-group"
            class="popover-filter-group"
            title="Por status"
          >
            <c-button
              data-cy="clear-filters-button"
              class="clear-button"
              slot="actions"
              size="lg"
              @click.native.stop="$emit('clear-filter')"
            >
              Limpar seleção
            </c-button>

            <filter-toggler
              :data-cy="`${filter.status}-filter-button`"
              class="filter"
              v-for="(filter, index) in filtersByStatus"
              :key="index"
              :name="filter.name"
              :icon="filter.icon"
              :selected="filter.selected"
              @click.native.stop="$emit('select-filter', { type: filter.status })"
            />
          </filter-group>

          <c-button
            data-cy="apply-filters-button"
            class="popover-filter-apply"
            icon="chevron-right"
            size="sm"
            primary
            block
            @click.native="$emit('apply-filter')"
          >
            Aplicar
          </c-button>
        </div>
      </component>
    </div>

    <c-button
      data-cy="open-filters-button"
      class="filter-button"
      size="lg"
      after
      :icon="showIcon"
      icon-size="sm"
      ref="filterToggle"
      @click.native="$emit('show-filter')"
    >
      Filtrar
    </c-button>
  </div>
</template>

<script>
import FilterToggler from '@/components/Filter/FilterToggler'
import FilterGroup from '@/components/Filter/FilterGroup'
import CPopover from '@/components/CComponents/CPopover'
import CModal from '@/components/CComponents/CModal'

export default {
  components: { FilterToggler, FilterGroup, CPopover, CModal },

  props: {
    filtersByStatus: Array,
    showFilter: Boolean
  },

  computed: {
    showIcon () {
      return this.isDesktop
        ? 'chevron-down'
        : ''
    }
  }
}
</script>

<style lang="scss">
@import '~@/styles/reference';

.header-popover > .c-popover > .content > .filters {
    max-height: 600px;
    overflow: auto;
    padding-bottom: 3rem;

    .header {
      height: 50px;
    }
  @include popoverFilter;
}

.popover-filter-group { @include popoverFilterGroup; }

.popover-filter-apply.c-button.-block{
  position: absolute;
  &:hover {
    opacity: 0.9;
  }
}

.filter-button {
  width: 130px;
  border: 1px solid rgba(94, 102, 132, 1);
  border: 1px solid var(--color-secondary);
  transition: all .1s ease;
}

.clear-button {
  border: 1px solid rgba(94, 102, 132, 1);
  border: 1px solid var(--color-secondary);
  transition: all .1s ease;
}
</style>
