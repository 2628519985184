<template>
  <engagement-new-orders
    :images="images"
    :loading="loading"
    @create-order="order => handleCreateOrder(order)"
  />
</template>

<script>
import moment from 'moment'
import EngagementNewOrders from '@/components/Engagement/Distribution/New/EngagementNewOrders'
import { mapGetters, mapActions } from 'vuex'
import { COMPANY, ENGAGEMENT_CAMPAIGNS_IMAGES, GET_ENGAGEMENT_CAMPAIGNS_IMAGES, CREATE_DISTRIBUTION_ORDER, FEEDBACK } from '@/store/types'

export default {
  components: {
    EngagementNewOrders
  },
  data () {
    return {
      loading: false
    }
  },
  computed: {
    ...mapGetters({
      company: COMPANY,
      images: ENGAGEMENT_CAMPAIGNS_IMAGES
    })
  },
  methods: {
    ...mapActions({
      feedback: FEEDBACK,
      getImages: GET_ENGAGEMENT_CAMPAIGNS_IMAGES,
      createOrder: CREATE_DISTRIBUTION_ORDER,
      getCompany: COMPANY
    }),
    handleAnalytics (event, data) {
      window.dataLayer.push(
        {
          event,
          ...data
        }
      )
    },
    async handleCreateOrder (order) {
      this.loading = true
      const initialOrder = order
      if (initialOrder.release_date === '') {
        delete initialOrder.release_date
      } else {
        initialOrder.release_date = moment(order.release_date).format('YYYY-MM-DD')
      }
      const success = await this.createOrder(initialOrder)
      if (success) {
        this.handleAnalytics('pontos-pedido-finalizar', { company_name: this.company.slug })
        this.$router.push({ name: 'engajamento-distribuicao' })
        await this.getCompany()
      } else {
        this.feedback({
          type: 'error',
          title: 'Houve um erro ao processar o seu pedido',
          text: 'Por favor, tente novamente mais tarde'
        })
      }
      this.loading = false
    }
  }
}
</script>

<style>
</style>
