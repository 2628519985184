<template>
  <label
    role="checkbox"
    :class="className"
    @click.stop
    data-cy="c-toggle"
  >
    <!-- :aria-checked="ariaChecked" -->

    <span
      v-if="formLabel"
      class="label"
    >{{ formLabel }}</span>

    <input
      type="checkbox"
      class="input"
      :checked="toggled"
      @change.stop="toggle"
    />

    <div
      :class="['core', {'-future': isFuture, '-engagement': isEngagement}]"
      :style="coreStyle"
    >
      <div
        class="button"
        :style="buttonStyle"
      />

      <template v-if="labels">
        <span
          v-if="toggled"
          class="label -left"
          :style="labelStyle"
          v-html="labelChecked"
        />
        <span
          v-else
          class="label -right"
          :style="labelStyle"
          v-html="labelUnchecked"
        />
      </template>
    </div>
  </label>
</template>

<script>

const constants = {
  labelChecked: 'Sim',
  labelUnchecked: 'Não',
  width: 80,
  height: 40,
  margin: 3
}

const contains = (object, title) => {
  return typeof object === 'object' && object.hasOwnProperty(title)
}

const px = v => v + 'px'

export default {
  name: 'ToggleButton',
  props: {
    value: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    sync: {
      type: Boolean,
      default: false
    },
    await: {
      type: Boolean,
      default: false
    },
    isFuture: {
      type: Boolean,
      default: false
    },
    isEngagement: {
      type: Boolean,
      default: false
    },
    activeColor: {
      type: String,
      default: '#4CAD50'
    },
    labels: {
      type: [Boolean, Object],
      default: false,
      validator (value) {
        return typeof value === 'object'
          ? (value.checked || value.unchecked)
          : typeof value === 'boolean'
      }
    },
    formLabel: {
      type: String,
      default: ''
    },
    height: {
      type: [Number, String],
      default: constants.height
    },
    width: {
      type: [Number, String],
      default: constants.width
    }
  },
  data () {
    return {
      toggled: this.value
    }
  },
  computed: {
    className () {
      const { toggled, disabled } = this

      return [
        'c-toggle', {
          '-toggled': toggled,
          '-disabled': disabled
        }
      ]
    },

    ariaChecked () {
      return this.toggled.toString()
    },

    coreStyle () {
      return {
        width: px(this.width),
        height: px(this.height),
        borderRadius: px(Math.round(this.height / 2)),
        ...(this.toggled) && { background: this.activeColor }
      }
    },

    buttonRadius () {
      return this.height - constants.margin * 2
    },

    distance () {
      return px(this.width - this.height + constants.margin)
    },

    buttonStyle () {
      return {
        width: px(this.buttonRadius),
        height: px(this.buttonRadius),
        transform: this.toggled
          ? `translate3d(${this.distance}, 3px, 0px)`
          : null
      }
    },

    labelStyle () {
      return {
        lineHeight: px(this.height)
      }
    },

    labelChecked () {
      return contains(this.labels, 'checked')
        ? this.labels.checked
        : constants.labelChecked
    },

    labelUnchecked () {
      return contains(this.labels, 'unchecked')
        ? this.labels.unchecked
        : constants.labelUnchecked
    }
  },
  watch: {
    value (value) {
      if (this.sync) {
        this.toggled = !!value
      }
    }
  },
  methods: {
    toggle (event) {
      if (this.disabled) return

      if (!this.await) {
        this.toggled = !this.toggled
      }

      this.$emit('input', this.toggled)
      this.$emit('change', {
        value: this.toggled,
        srcEvent: event
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/styles/reference.scss';

$c-toggle-font-size: 13px !default;
$c-toggle-label-font-size: 10px !default;
$c-toggle-font-family: $c-font-family-base !default;

$c-toggle-unchecked-background: #bec1d4 !default;
$c-toggle-checked-background: var(--color-primary) !default;
$c-toggle-label-color: #8c92b2 !default;

.c-toggle {
  & {
    display: inline-block;
    position: relative;
    overflow: hidden;
    vertical-align: middle;
    user-select: none;
    font-size: $c-toggle-font-size;
    &:not(.-disabled) { cursor: pointer; }
    &.-disabled { cursor: default; }
  }

  & > .label {
    color: $c-toggle-label-color;
    font-size: $c-toggle-label-font-size;
    text-transform: uppercase;
    margin-bottom: inner-base(0.5);
    display: block;
    line-height: 1;
  }

  & > .input { display: none; }

  & > .core { @extend %c-toggle-core; }

  &.-toggled .core {
    background: #4CAD50;
    &.-future {
      background: #F49C20;
    }
    &.-engagement {
      background: #4457AB;
    }
    &.-notification {
      background: #202950;
    }
  }
}

%c-toggle-core {
  & {
    display: block;
    position: relative;
    outline: 0;
    margin: 0;
    transition: border-color .3s, background-color .3s;
    will-change: border-color, background-color;
    user-select: none;
    background: #DFE1E8
  }

  & > .button {
    display: block;
    position: absolute;
    overflow: hidden;
    top: 0;
    left: 0;
    z-index: 3;
    transition: transform 0.3s ease;
    transform: translate3d(3px, 3px, 0);
    border-radius: 100%;
    background-color: #fff;
  }

  & > .label {
    position: absolute;
    bottom: 0;
    color: white;

    text-transform: uppercase;
    letter-spacing: 0.5px;
    font-size: 12px;
  }

  & > .label.-left { left: 10px; }
  & > .label.-right { right: 10px; }
}
</style>
