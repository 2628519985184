<template>
  <c-table-container
    class="listing-table"
    :class="isRowsEmpty ? '-inactive' : ''"
    v-bind="$attrs"
    v-on="$listeners"
    :paginate="paginate"
  >
    <data-table
      :sort="false"
      :cols="cols"
      :rows="rows"
      class="-table"
    >
      <template
        slot="row"
        slot-scope="{ row }"
      >
        <td :class="tdClass">
          <span
            class="td-link"
            @click="$emit('details-click', row)"
          >
            {{ formatString (row.name) }}
          </span>
        </td>
        <td :class="tdClass">
          <span class="td-span">{{ formatDate(row.start_date) }}</span>
        </td>
        <td :class="tdClass">
          <span class="td-span">{{ formatDate(row.end_date) }}</span>
        </td>
        <td :class="tdClass">
          <span class="td-span">{{ row.reads }}</span>
        </td>
        <td :class="tdClass">
          <c-label
            class="table-status"
            :color="statusColor(row)"
            text-color="#0A0D1A"
          >
            {{ statusLabel(row) }}
          </c-label>
        </td>
        <td
          :class="tdClass"
          class="table-actions"
        >
          <c-action-button
            @click.native="$emit('action-click', row.id)"
            text="Editar"
            icon="pen-edit17"
          />
        </td>
      </template>
    </data-table>
    <div
      class="empty-content"
      v-if="rows.length === 0 && hasEmptyBanner"
    >
      <empty-banner
        button="Nova notificação"
        :title="emptyBannerText"
        @button-click="$emit('empty-banner-action')"
      />
    </div>
  </c-table-container>
</template>

<script>
import { timestampToOnlyDate } from '@/modules/validate/helpers.js'
import EmptyBanner from '@/components/General/EmptyBanner'
import CActionButton from '@/components/CComponents/CActionButton'

const statuses = [
  {
    label: 'Inativo',
    color: '#FF5967'
  },
  {
    label: 'Ativo',
    color: '#3CE797'
  },
  {
    label: 'Agendado',
    color: '#FFC330'
  }
]

export default {
  components: {
    EmptyBanner,
    CActionButton,
    DataTable: () => import('vue-data-tablee'),
    CTableContainer: () => import('@/components/CComponents/CTableContainer'),
    CLabel: () => import('@/components/CComponents/CLabel')
  },
  props: {
    rows: Array,
    cols: Array,
    emptyBannerText: String,
    hasEmptyBanner: Boolean,
    paginate: Object
  },
  data () {
    return {
      statuses,
      tdClass: 'data-tablee-cell -content -center'
    }
  },
  computed: {
    formatedRows () {
      if (this.rows.length && this.rows.length < 5) {
        let array = []
        const diff = 5 - this.rows.length
        for (let i = 0; i < diff; i++) {
          array.push({})
        }
        array = this.rows.concat(array)
        return array
      }
      return this.rows
    },
    isRowsEmpty () {
      return this.rows.length === 0
    }
  },
  methods: {
    formatString (string) {
      if (!string || string.length < 43) return string
      return string.substring(0, 43) + '...'
    },
    formatDate (date) {
      const formatedDate = timestampToOnlyDate(date)
      return formatedDate
    },
    statusColor (row) {
      const filteredStatus = this.statuses.filter(status => status.label === row.status)
      if (!row || !filteredStatus.length) return '#000'
      return filteredStatus[0].color
    },
    statusLabel (row) {
      const filteredStatus = this.statuses.filter(status => status.label === row.status)
      if (!row || !filteredStatus.length) return 'Carregando...'
      return filteredStatus[0].label
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/styles/reference';

.result { border: 1px solid rgba(18, 30, 72, 0.05); }
.listing-table-preview-button { width: 135px; }
.listing-table {
  &.-inactive {
    :deep > .table .-header .data-tablee-text {
      color: #D8D8D8;
    }
  }
  :deep > .table {
    border: none;
    .-header {
      background: #FDFDFD;
      padding: 20px;
    }
  }
  .-table {
    background: #FDFDFD;
    box-shadow: 0px 4px 8px rgba(94, 102, 132, 0.08);
    border-radius: 4px;
    border: none;
  }

  td {
    height: 80px;
  }
  .td-span {
    color: #5E6684;
    font-size: 14px;
  }
  .td-link {
    color: #608CEA;
    font-size: 14px;
    cursor: pointer;
    text-decoration: underline;

    :visited {
      color: #608CEA;
    }
  }
  .table-status {
    width: 135px;
    height: 30px;

    > .text{
      text-transform: none;
      font-size: 12px;
    }
  }
  .table-actions {
    display: flex;
    justify-content: center;

    .icon-action {
      font-size: 20px;
      margin: 3px;
      cursor: pointer;
      padding: 10px;
      border: 1px solid rgba(94, 102, 132, 1);
      border-radius: 50px;
      transition: all .1s ease;

      &:hover {
        border-width: 2px;
      }

      &.-disabled {
        background: rgba(216, 216, 216, 1);
        border: none;
      }
    }
  }

  .empty-content {
    padding: 20px;
    background: white;
  }
}
</style>
