<template>
  <div class="dropdown-menu">
    <ul class="dropdown-menu-list">
      <li
        class="dropdown-menu-item"
        v-for="option in options"
        :key="option.title"
      >
        <router-link
          :to="option.path"
          :data-cy="`dropdown-menu-link-${option.name}`"
          class="dropdown-menu-link"
          @click.native="$emit('close')"
        >
          <div class="header">
            <k-icon
              :icon="option.icon"
              class="icon"
            />
            <h6 class="title">
              {{ option.name }}
            </h6>
          </div>

          <p class="description">
            {{ option.description }}
          </p>
        </router-link>
      </li>
    </ul>
  </div>
</template>

<script>
import { KIcon } from 'kaledo-components'
export default {
  name: 'CDropdownMenu',
  components: {
    KIcon
  },
  props: {
    options: Array
  }
}
</script>

<style lang="scss" scoped>
@import '~@/styles/reference';
.dropdown-menu {
  width: 325px;
  border-radius: 8px;
  background: #FDFDFD;
  border: 1px solid #F7F8FC;
  box-shadow: 0px 4px 8px 0px #5E66841F;
  padding: 24px;
  .dropdown-menu-list {
    padding: 0;
    margin: 0;
    list-style-type: none;
    .dropdown-menu-item {
      .dropdown-menu-link {
        text-decoration: none;
        transition: all ease 0.3s;

        .header {
          display: flex;
          align-items: center;
          color: #5E6684;
          margin-bottom: 8px;
          .icon {
            font-size: 20px;
            margin-right: 8px;
          }
          .title {
            font-size: 16px;
            font-weight: 600;
          }
        }
        &.nuxt-link-exact-active, &:hover {
          .header {
            color: $base-color;
          }
        }
      }
    }
    .dropdown-menu-item + .dropdown-menu-item {
      margin-top: 32px;
    }
  }
}
</style>
