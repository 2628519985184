<template>
  <div class="modal-info-container">
    <button
      class="icon-button"
      :class="disabled ? '-disabled' : ''"
      @mouseover="showModal = true"
      @mouseout="showModal = false"
    >
      <k-icon
        class="icon"
        :icon="icon"
      />
    </button>
    <transition
      name="fade"
    >
      <div
        v-if="modal && showModal && !disabled"
        class="modal-info"
      >
        <div class="modal-body">
          <p class="text">
            {{ text }}
          </p>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { KIcon } from 'kaledo-components'
export default {
  name: 'InfoButton',
  components: {
    KIcon
  },
  props: {
    disabled: Boolean,
    icon: String,
    text: String,
    modal: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      showModal: false
    }
  }
}
</script>

<style lang='scss' scoped>
@import '~@/styles/reference.scss';
.modal-info-container {
  position: relative;
  .icon-button {
    background: rgba(255, 255, 255, 1);
    cursor: pointer;
    padding: 10px;
    border: 1px solid rgba(94, 102, 132, 1);
    border-radius: 50px;
    transition: all .1s ease;

    &:hover {
      background: rgba(94, 102, 132, 1);
      color: white;
    }

    &.-disabled {
      background: rgba(216, 216, 216, 1);
      border: none;

      cursor: default;

      &:hover {
        color: unset;
      }
    }

    .icon {
      font-size: 20px;
      pointer-events: none;
    }
  }
  .modal-info {
    position: absolute;
    display: flex;
    align-items: center;
    color: white;
    z-index: 5;
    bottom: 45px;
    right: -14px;
    min-width: 130px;
    width: 100%;
    height: 60px;
    padding: 8px 16px;
    border-radius: 4px;
    overflow-y: auto;
    text-transform: initial;
    text-align: center;

    .modal-body {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 4px;
      width: 100%;
      height: 35px;
      background: var(--color-primary);
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }

    .modal-body:after{
      content: "";
      width: 0;
      height: 0;
      position: absolute;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-top: 10px solid var(--color-primary);
      bottom: -10px;
      right: 10%;
    }

    @include mobile {
      min-width: 150px;
    }
    @include tablet {
      min-width: 150px;
    }
    .text {
      font-size: 11px;
      color: #FFFFFF;
      text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
      font-weight: 700;
      @include mobile {
        font-size: 10px;
      }
      @include tablet {
        font-size: 10px;
      }
    }
  }
}
</style>
