<template>
  <div class="partner-card">
    <div class="logo-container">
      <img
        class="logo"
        :src="partner.logo"
        :alt="`Logo de ${partner.name}`"
      />
    </div>

    <div class="content">
      <h4 class="title">
        {{ partner.name }}
      </h4>
      <p class="description">
        {{ partner.description }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PartnerCard',
  props: {
    partner: Object
  }
}
</script>

<style lang='scss' scoped>
@import '~@/styles/reference.scss';

.partner-card {
  display: flex;
  align-items: center;
  width: 347px;
  height: 77px;
  background: white;
  box-shadow: 1px 1px 4px 0 rgba(0,0,0,0.1);
  border-bottom: 1px solid rgba(18,30,72,0.05);
  @include mobile {
    flex-wrap: wrap;
  }
  .logo-container {
    padding: 30px;
    @include mobile {
      padding: 15px
    }
  }
  .logo {
    max-height: 20px;
    max-width: 92px;
  }
  .content {
    @include mobile {
      text-align: center;
    }
  }
}
</style>
