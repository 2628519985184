<template>
  <c-modal
    class="change-account-modal"
    name="change-account-modal"
    is-change-account
    :is-opened="isOpened"
    @close="$emit('close')"
  >
    <c-title
      class="title"
      size="3"
      icon="home-user"
    >
      Clubes
    </c-title>

    <div class="search-header">
      <span class="text-header">Selecione o clube desejado: </span>

      <search-field
        class="search-field"
        placeholder="Encontre um clube"
        :term="searchTerm"
        @input="term => updateSearch(term)"
        @reset="updateSearch('')"
      />
    </div>

    <div class="accounts-wrapper">
      <div
        class="accounts"
        v-for="(company) in filteredCompanies"
        :key="company.id"
        :title="company.name"
      >
        <c-button
          class="company-logo"
          @click="$emit('change-account', company.id)"
        >
          <c-image
            :src="company.logo"
            :placeholder="imagePlaceholder"
          />
        </c-button>

        <p class="company-name">
          {{ company.name }}
        </p>
      </div>
    </div>

    <c-empty
      v-if="filteredCompanies.length === 0"
      class="empty-search"
      title="Ops!"
      character="searching"
      message="Não encontramos nenhum clube relacionado à busca"
    />
  </c-modal>
</template>

<script>
import CModal from '@/components/CComponents/CModal'
import CTitle from '@/components/CComponents/CTitle'
import CImage from '@/components/CComponents/CImage'
import CButton from '@/components/CComponents/CButton'
import CEmpty from '@/components/CComponents/CEmpty'
import SearchField from '@/components/CComponents/SearchField'

export default {
  components: {
    CModal,
    CTitle,
    CImage,
    CButton,
    CEmpty,
    SearchField
  },

  props: {
    isOpened: {
      type: Boolean,
      required: true
    },
    companies: {
      type: Array,
      required: true
    }
  },

  data () {
    return {
      imagePlaceholder: require('@/assets/img/campaign-placeholder.svg'),
      searchTerm: '',
      filteredCompanies: ''
    }
  },

  mounted () {
    this.filteredCompanies = this.companies
  },

  methods: {
    updateSearch (term) {
      this.searchTerm = term

      if (term === '') {
        this.filteredCompanies = this.companies
        return
      }

      let regex = new RegExp(term, 'i')

      let companies = this.companies.filter(company => {
        if (company.name.match(regex) !== null) { return company }
      })

      this.filteredCompanies = companies
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/styles/reference';

.change-account-modal {
  .search-header {
    padding-top: 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    & > .text-header {
      font-size: 16px;
      opacity: 0.5;
      color: #121E48;
    }
  }

  .accounts-wrapper {
    display: flex;
    flex-wrap: wrap;
    overflow-y: auto;
    height: 370px;
    margin-top: 30px;

    -ms-overflow-style: none;  /* hide scrollbar IE and Edge */
    scrollbar-width: none;  /* hide scrollbar Firefox */

    &::-webkit-scrollbar { /* hide scrollbar Chrome, Safari and Opera */
      display: none;
    }

    & > .accounts {
      width: 80px;
      height: 106px;
      margin-top: 30px;
      margin-right: 50px;

      &:nth-of-type(-n+5) {
        margin-top: 20px;
      }

      &:nth-of-type(5n) {
        margin-right: 0;
      }

      &:last-child {
        margin-bottom: 150px;
      }

      & > .company-logo {
        width: 80px;
        height: 80px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        border: 1px solid rgba(18, 30, 72, 0.1);
        border-radius: 50%;
        box-shadow: 1px 1px 4px 0 rgba(0,0,0,0.1);

        &:hover {
          border: 3px solid var(--color-secondary);
        }

        img {
          width: 56px;
          display: block;
        }
      }

      & > .company-name {
        text-align: center;
        font-size: 14px;
        font-weight: bold;
        opacity: 0.8;
        color: #121E48;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-top: 10px;
      }
    }
  }

  .empty-search {
    position: absolute;
    left: 0;
    right: 0;
    top: 150px;
    margin-left: auto;
    margin-right: auto;
  }
}
</style>
