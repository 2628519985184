<template />
<script>
import * as types from '@/store/types'

export default {
  layout: 'unauthenticated',
  transition: {
    beforeEnter () {
      this.$store.dispatch(types.AUTH_LOGOUT)
      this.$router.push({ name: 'login' })
    }
  }
}
</script>
