<template>
  <c-modal
    class="modal"
    :is-opened="show"
    :is-fullscreen="false"
    name="quest-modal"
    hide-cancel
    @close="$emit('close')"
  >
    <transition
      name="fade"
      mode="out-in"
    >
      <div data-cy="quest-modal-not-completed">
        <div v-if="!isCompleted">
          <c-title
            data-cy="quest-modal-title"
            class="title"
          >
            Suas missões
          </c-title>
          <div class="quest-modal-content">
            <ul class="quest-list">
              <quest-item
                v-for="quest in quests"
                :key="quest.id"
                :quest="quest"
                :is-completed="quest.isCompleted"
                @close="$emit('close')"
              />
            </ul>
            <gift
              :number-steps="TasksCompleted"
              @complete-quest="isCompleted = true"
            />
          </div>
        </div>
        <div
          v-if="isCompleted"
          class="completed-container"
        >
          <h3 class="title">
            Parabéns!
          </h3>
          <p class="text">
            Você completou todas as missões e ganhou uma recompensa:
          </p>
          <div class="gift-reveal">
            <p class="gift-text">
              50% da primeira mensalidade convertida em prêmios ou pontos para distribuir para sua base
            </p>
          </div>
          <p class="text">
            Mais detalhes da sua recompensa foram enviados para o seu email.
          </p>
          <gift
            :number-steps="TasksCompleted"
            :is-completed="true"
            @complete-quest="isCompleted = true"
          />
        </div>
      </div>
    </transition>
  </c-modal>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { COMPANY, USER_COUNT } from '@/store/types'
import CModal from '@/components/CComponents/CModal'
import QuestItem from '@/components/Trial/components/QuestItem.vue'
import Gift from '@/components/Trial/components/Gift.vue'

export default {
  name: 'QuestModal',
  components: {
    CModal,
    QuestItem,
    Gift
  },
  props: {
    show: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      showQuestsModal: false,
      isCompleted: false,
      quests: [
        {
          id: '1',
          title: 'Ative seu clube',
          description: 'Configure a URL e o nome do seu clube para torná-lo ativo.',
          path: '/configuracao/ambiente',
          isCompleted: false
        },
        {
          id: '2',
          title: 'Personalize seu clube',
          description: 'Insira sua identidade na página de acesso e ambientes internos.',
          path: '/personalizacao',
          isCompleted: false
        },
        {
          id: '3',
          title: 'Convide usuários',
          description: 'Convide até 10 usuários para o seu clube inserindo CPFs.',
          path: '/usuarios/importacao',

          isCompleted: false
        }
      ]
    }
  },

  computed: {
    ...mapGetters({
      company: COMPANY,
      userCount: USER_COUNT
    }),
    TasksCompleted () {
      return this.quests.filter(quest => quest.isCompleted).length
    },
    hasCompletedFirstMission () {
      const clubeUrl = this.company.url && this.company.url.length > 0
      const clubeName = this.company.name && this.company.name.length > 0
      return clubeUrl && clubeName
    },
    hasCompletedSecondMission () {
      const hasLogo = typeof this.company.logo === 'string' && this.company.logo.length > 0
      const hasPrimaryColor = this.company.config.colors && this.company.config.colors.primary && this.company.config.colors.primary !== null
      const hasSecondaryColor = this.company.config.colors && this.company.config.colors.secondary && this.company.config.colors.secondary !== null
      const hasTerciaryColor = this.company.config.colors && this.company.config.colors.tertiary && this.company.config.colors.tertiary !== null
      const hasColors = hasPrimaryColor && hasSecondaryColor && hasTerciaryColor
      return hasLogo && hasColors
    },
    hasCompletedThirdMission () {
      if (this.userCount && this.userCount.total_users) {
        return parseInt(this.userCount.total_users) > 1
      }
      return false
    }
  },
  mounted () {
    if (!this.hasCompletedThirdMission) {
      this.getUserCount()
    }
    this.checkCompletedQuests()
  },
  methods: {
    ...mapActions({
      getUserCount: USER_COUNT
    }),
    close () {
      this.$emit('close')
    },
    checkCompletedQuests () {
      if (this.hasCompletedFirstMission) {
        this.quests[0].isCompleted = true
      }
      if (this.hasCompletedSecondMission) {
        this.quests[1].isCompleted = true
      }
      if (this.hasCompletedThirdMission) {
        this.quests[2].isCompleted = true
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/styles/reference.scss';
.quest-modal-content {
  margin-top: 42px;
}
.modal {
  :deep .quest-modal {
    max-width: 700px;

    @media screen and (max-height: 780px) {
      top: 0;
    }
  }
}
.completed-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  .title {
    font-size: 28px;
    font-weight: 700;
    color: $base-color;
    text-align: center;
    margin-bottom: 42px;
  }
  .text {
    font-size: 18px;
    color: $base-color;
    font-weight: 400;
    margin-bottom: 32px;
  }
  .gift-reveal {
    border: 1px solid rgba(71, 215, 255, 1);
    border-radius: 8px;
    padding: 32px;
    margin-bottom: 32px;
    .gift-text {
      font-size: 16px;
      color: $base-color;
      font-weight: 700;
      text-align: center;
    }
  }
}
.quest-list {
  list-style: none;
}
</style>
