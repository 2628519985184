<template>
  <div class="painel-mobile">
    <div class="menu">
      <div
        class="menu-item first"
        :style="{borderColor: data.secondary || ''}"
      >
        <k-icon
          icon="cash-banknote-percent"
          class="icon"
        />
        <span class="text">Descontos</span>
      </div>
      <div class="menu-item">
        <k-icon
          icon="star-favorite"
          class="icon"
        />
        <span class="text">Recompensas</span>
      </div>
    </div>
  </div>
</template>

<script>
import { KIcon } from 'kaledo-components'
export default {
  name: 'PainelMobile',
  components: { KIcon },
  props: {
    data: {
      type: Object,
      default: () => {}
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/styles/reference';
.painel-mobile {
  background: #FDFDFD;
  width: 100%;
  height: 45px;
  .menu {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .menu-item {
      width: 100%;
      height: 45px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-bottom: 2px solid #FDFDFD;
      .icon {
        font-size: 20px;
        color: #5E6684;
        margin-right: 4px;
      }
      .text {
        font-size: 12px;
        color: $base-color;
      }
      &.first {
        border-bottom: 2px solid #D8D8D8;
        .icon {
          color: $base-color;
        }
        .text {
          font-weight: 700;
        }
      }
    }
  }
}
</style>
