// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/img/account-creation-bg.jpeg");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../assets/img/account-creation-bg-mobile.jpeg");
var ___CSS_LOADER_URL_IMPORT_2___ = require("../../assets/img/account-creation-bg-tablet.jpeg");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".fade-enter-active[data-v-094745ae],.fade-leave-active[data-v-094745ae]{transition:opacity .3s}.fade-enter[data-v-094745ae],.fade-enter-from[data-v-094745ae],.fade-leave-to[data-v-094745ae]{opacity:0}.account-creation-container[data-v-094745ae]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-position:50%;background-repeat:no-repeat;background-size:cover;display:flex;flex-direction:column;justify-content:center}.account-creation-container.-email-confirmation[data-v-094745ae]{height:100vh}.account-creation-container .container[data-v-094745ae]{margin:0 auto 46px;max-width:1200px;width:100%}@media only screen and (max-width:768px){.account-creation-container[data-v-094745ae]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");background-repeat:repeat}}@media screen and (min-width:768px)and (max-width:1023px){.account-creation-container[data-v-094745ae]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ")}}@media only screen and (min-width:769px)and (max-width:991px){.account-creation-container[data-v-094745ae]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ")}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
module.exports = ___CSS_LOADER_EXPORT___;
