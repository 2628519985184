<template>
  <div
    data-cy="step-three-users-table"
    class="step-three-users-table"
  >
    <div class="header">
      <k-input
        data-cy="users-table-search-input"
        class="search"
        icon="search1"
        placeholder="Buscar CPF ou email"
        @input="$emit('input', $event)"
      />
      <div class="tag-container">
        <div
          data-cy="selected-rows-total-tag"
          v-show="selectedRows.length !== 0"
          class="tag"
        >
          {{ selectedRows.length }} {{ selectedUsersText }}
        </div>
      </div>
      <k-input
        data-cy="bulk-award-value-input"
        class="price"
        :class="priceInputFocus ? '-focus' : ''"
        type="money"
        @click.native="priceInputFocus = true"
        v-click-outside="handlePriceFocus"
        :disabled="selectedRows.length === 0"
        v-model="generalValue"
      />
      <k-icon
        data-cy="bulk-award-value-button"
        icon="checkmark-circle-1"
        size="40"
        class="check"
        :class="selectedRows.length === 0 ? '-disabled' : ''"
        @click.native="selectedRows.length > 0 ? updateSelectedValues() : ''"
      />
    </div>
    <c-table-container
      class="awards-user-table-import"
      :paginate-bg="false"
      :paginate="paginate"
      @update-paginate="updatePaginate"
    >
      <table class="data-table">
        <tr class="table-header">
          <th
            class="-cell"
          >
            <input
              data-cy="all-users-select"
              type="checkbox"
              :class="['-select', '-all']"
              :checked="allRowsSelected"
              :value="allRowsSelected"
              @input="selectAllRows()"
            />
          </th>

          <th
            v-for="(col, index) in tableData.cols"
            class="-cell"
            :key="index"
          >
            <span class="-text">{{ col.label }}</span>
          </th>
        </tr>
        <tr
          class="table-row"
          :class="isSelected(row.id)? '-selected' : ''"
          v-for="(row, index) in tableDataRowsCopy"
          :key="index"
        >
          <td
            class="-cell"
          >
            <input
              data-cy="user-select"
              :disabled="row.document === ''"
              :checked="isSelected(row.id)"
              :value="isSelected(row.id)"
              @input="selectRow(row)"
              type="checkbox"
            />
          </td>
          <td
            class="-cell"
          >
            <p class="text">
              {{ cpfRow(row.document) }}
            </p>
          </td>
          <td
            class="-cell"
          >
            <p class="text">
              {{ row.email }}
            </p>
          </td>
          <td
            class="-cell"
          >
            <money
              data-cy="user-value"
              ref="money"
              class="search"
              :key="reloadInput"
              :value="row.value"
              v-bind="currencyMask"
              @input="updateUserValue($event, row)"
            />
          </td>
        </tr>
      </table>
    </c-table-container>
  </div>
</template>

<script>
import ClickOutside from 'vue-click-outside'
import CTableContainer from '@/components/CComponents/CTableContainer'
import { KIcon, KInput } from 'kaledo-components'
import { Money } from 'v-money'

export default {
  directives: {
    ClickOutside
  },

  components: {
    CTableContainer,
    KIcon,
    KInput,
    Money
  },
  props: {
    tableData: {
      type: Object,
      required: true
    },
    paginate: {
      type: Object,
      default: () => {
        return {
          current_page: 1,
          from: 1,
          per_page: 5,
          to: 5,
          total: 5
        }
      }
    },
    useCpfMask: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      generalValue: '',
      selectedRows: [],
      reloadInput: 0,
      priceInputFocus: false,
      currencyMask: {
        prefix: 'R$ ',
        decimal: ',',
        thousands: '.',
        precision: 2,
        masked: false
      },
      from: 0,
      to: 5,
      tableDataRowsCopy: []
    }
  },
  computed: {
    allRowsSelected () {
      return this.selectedRows.length === this.tableData.rows.length
    },
    selectedUsersText () {
      return this.selectedRows.length > 1 ? 'usuários selecionados' : 'usuário selecionado'
    }
  },
  watch: {
    tableData (newvalue) {
      const total = newvalue.rows.length
      this.tableDataRowsCopy = total > 5 ? this.tableData.rows.slice(this.from, this.to) : this.tableData.rows
    }
  },
  mounted () {
    this.initTableCopy()
    this.selectAllRows()
  },
  methods: {
    isSelected (userId) {
      if (this.selectedRows.includes(userId)) return true
      else return false
    },
    selectAllRows () {
      if (!this.allRowsSelected) {
        this.selectedRows = this.tableData.rows.map(user => {
          return user.id
        })
      } else {
        this.selectedRows = []
      }
    },
    selectRow (row) {
      if (this.selectedRows.includes(row.id)) {
        const idx = this.selectedRows.findIndex(userId => userId === row.id)
        this.selectedRows.splice(idx, 1)
      } else {
        this.selectedRows.push(row.id)
      }
    },
    cpfRow (value) {
      if (!value) return ''
      if (this.useCpfMask && value.length > 0) return `${value.substring(0, 3)}.${value.substring(3, 6)}.${value.substring(6, 9)}-${value.substring(9, 11)}`
      return value
    },
    handlePriceFocus () {
      this.priceInputFocus = false
    },
    handleSelectEmit () {
      this.$emit('select-all-rows', this.isSomeRowUnselected)
    },
    updateSelectedValues () {
      const newTableData = JSON.parse(JSON.stringify(this.tableData))
      newTableData.rows = newTableData.rows.map(user => {
        if (this.selectedRows.includes(user.id)) {
          user.value = this.generalValue
        }
        return user
      })
      this.reloadInput++
      this.$emit('update-values', newTableData)
    },
    updateUserValue (value, row) {
      const newTableData = JSON.parse(JSON.stringify(this.tableData))
      const idx = this.tableData.rows.findIndex(user => user.id === row.id)

      if (newTableData.rows[idx].value === value) return false
      newTableData.rows[idx].value = value
      this.$emit('update-values', newTableData)
    },
    initTableCopy () {
      if (this.tableData.rows.length > 0) {
        const total = this.tableData.rows.length
        const to = total > 5 ? 5 : total
        this.tableDataRowsCopy = total > 5 ? this.tableData.rows.slice(0, 5) : this.tableData.rows
        this.$emit('update-paginate', {
          ...this.paginate,
          to,
          total
        })
      }
    },
    updatePaginate (e) {
      const rowsReference = this.tableData.rows
      this.from = ((e.page - 1) * e.limit)
      this.to = this.from + 5 > rowsReference.length ? rowsReference.length : (this.from + 5)
      this.tableDataRowsCopy = rowsReference.slice(this.from, this.to)
      this.$emit('update-paginate', {
        current_page: e.page,
        from: this.from + 1,
        per_page: e.limit,
        to: this.to,
        total: rowsReference.length
      })
    }
  }
}
</script>

<style lang='scss' scoped>
@import '~@/styles/reference';
.step-three-users-table {
  border-radius: 8px;
  .header {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px 16px 8px 24px;
    background: #FDFDFD;
    border: 1px solid #eaedef;
    border-radius: 8px 8px 0px 0px;

    .search {
      width: 100%;
      max-width: 304px;
      display: flex;
      align-items: center;
      padding: 8px 16px;
      transition: all ease 0.3s;
      background: #FFFFFF;

      :deep .k-input {
        padding-top: 2px;
        border-radius: 16px;
        height: 40px;
        box-shadow: none;
        border: 1px solid #D8D9DE;
      }
    }
    .price {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 8px;
      width: 167px;
      border-radius: 4px;
      flex: none;
      margin-right: 16px;

      :deep .k-input {
        width: 100%;
        padding-top: 2px;
        border-radius: 4px;
        height: 40px;
        box-shadow: none;
        border: 1px solid #D8D9DE;
      }

      &.-focus {
        :deep .k-input {
          border: 2px solid #202950;
        }
      }
    }
    .tag-container {
      width: 100%;
      height: 28px;
      .tag {
        width: 100%;
        max-width: 173px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 4px 8px;
        gap: 8px;
        background: rgba(190, 192, 211, 0.6);
        border-radius: 8px;
        flex: none;
        font-family: 'Nunito Sans';
        font-style: normal;
        font-weight: 700;
        font-size: 12px;
        line-height: 18px;
        letter-spacing: -0.121905px;
        color: #202950;
      }
    }
    .check {
      color: #5E6684;

      &:hover:not(.-disabled) {
        color: #202950;
        cursor: pointer;
        -webkit-text-stroke: 1px #202950;
      }
      &.-disabled {
        color: #D8D8D8;
        cursor: default;
        -webkit-text-stroke: none;
      }
    }
  }
  :deep .table {
    border-radius: 0px 0px 8px 8px;
  }
  .data-table {
    box-shadow: none;
    border: none;
    border-radius: 0px 0px 8px 8px;
    width: 100%;
    margin-top: -2px;

    .table-header {
      height: 60px;
      background: white;
      width: 100%;
      display: flex;
      border-bottom: 1px solid #eaedef;
      border-top: 1px solid #eaedef;
    }

    .table-row {
      display: flex;
      width: 100%;
      height: 60px;
      background: white;
      display: flex;
      border-bottom: 1px solid #eaedef;

      &.-selected {
        background: #F8F9FD;
      }
      &:last-of-type {
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
      }
    }

    .-cell {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      text-align: center;
      width: 100%;
      height: 100%;
      padding: 0 18px;

      span {
        opacity: 0.5;
        font-weight: 700;
        text-transform: uppercase;
        color: #121E48;
      }

      &:first-of-type {
        max-width: 120px;
      }

      .search {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 8px 16px;
        gap: 8px;
        width: 160px;
        height: 40px;
        background: #FFFFFF;
        border: 1px solid #D8D9DE;
        border-radius: 4px;
      }
    }
  }
}
</style>
