<template>
  <div
    data-cy="account-creation-footer"
    class="account-creation-footer"
  >
    <c-logo
      class="logo"
      kaledo-width="115px"
      height="25px"
      kaledo
    />
    <ul class="links">
      <li
        v-for="link in links"
        :data-cy="link.dataCy"
        :key="link.to"
        class="link"
      >
        <a
          :href="link.to"
          target="_blank"
        >{{ link.label }}</a>
      </li>
    </ul>
  </div>
</template>

<script>
import CLogo from '@/components/CComponents/CLogo'

export default {
  name: 'AccountCreationFooter',
  components: {
    CLogo
  },
  computed: {
    links () {
      return [
        {
          dataCy: 'email-link',
          label: 'kaledo@kaledo.com.br',
          to: 'mailto:kaledo@kaledo.com.br'
        },
        {
          dataCy: 'terms-link',
          label: 'Termos de serviços',
          to: 'https://kaledo.com.br/terms'
        },
        {
          dataCy: 'privacy-politics-link',
          label: 'Política de privacidade',
          to: 'https://kaledo.com.br/privacy-policy'
        }

      ]
    }
  }
}
</script>

<style lang='scss' scoped>
@import '~@/styles/reference';
.account-creation-footer {
  max-width: 1200px;
  margin: 0 auto;
  width: 100%;
  height: 80px;
  padding: 10px 80px;
  margin-top: 40px;

  @include tablet {
    height: auto;
    padding: 40px 24px;
    max-width: 640px;
    padding-left: 0;
    padding-right: 0;
    margin-top: 0;
  }
  @include mobile {
    height: auto;
    padding: 40px 24px;
    max-width: 508px;
    margin-top: 0;
  }
  .logo {
    margin-bottom: 40px;
    display: none;

    @include tablet {
      display: block;
    }
    @include mobile {
      display: block;
    }
  }
  .links {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    list-style: none;
    @include tablet {
      flex-direction: column;
      justify-content: flex-start;
    }
    @include mobile {
      flex-direction: column;
      justify-content: flex-start;
    }
    .link + .link {
      margin-left: 40px;
      @include tablet {
        margin-left: 0;
        margin-top: 15px;
        padding-top: 15px;
        border-top: 1px solid $border-color;
      }
      @include mobile {
        margin-left: 0;
        margin-top: 15px;
        padding-top: 15px;
        border-top: 1px solid $border-color;
      }
    }
    .link {
      font-size: 16px;

      @include tablet {
        text-align: left;
        width: 100%;
      }
      @include mobile {
        text-align: left;
        width: 100%;
      }
      a {
        text-decoration: none;
        color: #FF6362;
      }
    }
  }
}
</style>
