<template>
  <div>
    <component-header @return="$router.push('/parceiros')"/>
    <customization-container />
  </div>
</template>

<script>
import CustomizationContainer from '@/containers/Customization/CustomizationContainer'
import ComponentHeader from '@/components/General/ComponentHeader'
export default {
  components: {
    CustomizationContainer,
    ComponentHeader
  }
}
</script>

<style lang='scss' scoped>
.header-container {
  box-shadow: 0px 4px 1px -1px rgba(213, 214, 226, 0.4);
  background-color: #FFF;
  height: 59px;
  align-items: center;
  display: flex;
  & > .header {
    max-width: 1020px;
    width: 100%;
    padding: 0 10px;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: flex-end;
  }
}
</style>
