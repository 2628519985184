<template>
  <c-table-container
    class="awards-user-table-delete"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <data-table
      :sort="true"
      :cols="cols"
      :rows="rows"
    >
      <template
        slot="row"
        slot-scope="{ row }"
      >
        <td
          class="data-tablee-cell -content -center"
        >
          <p class="text">
            {{ cpfFormatted(row.document) }}
          </p>
        </td>
        <td
          class="data-tablee-cell -content -center"
        >
          <p class="text">
            {{ row.email }}
          </p>
        </td>
      </template>
    </data-table>
  </c-table-container>
</template>

<script>
import DataTable from 'vue-data-tablee'
import CTableContainer from '@/components/CComponents/CTableContainer'

export default {
  name: 'DeleteUsersTable',
  components: {
    DataTable,
    CTableContainer
  },
  props: {
    rows: Array,
    useCpfMask: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    cols () {
      return [
        { label: 'CPF', align: 'center' },
        { label: 'E-Mail', align: 'center' }
      ]
    }
  },
  methods: {
    cpfFormatted (value) {
      if (!this.useCpfMask || value.length === 0) return value
      return `${value.substring(0, 3)}.${value.substring(3, 6)}.${value.substring(6, 9)}-${value.substring(9, 11)}`
    }
  }
}
</script>
<style lang="scss" scoped>
.c-modal.-is-change-account::after {
  background: none;
}
</style>
<style lang='scss'>
@import '~@/styles/reference';

.awards-user-table-delete {
  .table .data-tablee {
    box-shadow: none;
    border: none;
    border-radius: 0px 0px 8px 8px;
  }
  .table .data-tablee .data-tablee-row {
    .data-tablee-cell.-header {
      height: 30px;
      background: #4457AB;
      background: var(--color-primary);
      > .data-tablee-text, .data-tablee-cell.-header > .data-tablee-icon {
        color: white;
      }
    }
    .data-tablee-cell.-header.-sortable > .data-tablee-icon {
      display: none;
    }
    .text {
      color: #0A0D1A;
    }
  }
}
</style>
