<template>
  <!-- user header -->
  <div class="user-controls">
    <div class="list-heading">
      <c-title
        icon="user"
        :show-border="false"
      >
        <div
          data-cy="user-search-title"
          class="title"
        >
          Usuários ativos <c-info-button :popover-label="popoverLabel" />
        </div>
        <div
          data-cy="count-wrapper"
          class="count-wrapper"
        >
          <span
            data-cy="active-users-count"
            class="count active"
          >
            {{ userCount.active_users }}
          </span>
          <span
            data-cy="total-users-count"
            class="count"
          >
            /{{ userCount.total_users }}
          </span>
        </div>
      </c-title>
      <c-progress :value="percentage" />
    </div>

    <div class="actions">
      <user-filter
        v-on="$listeners"
        v-bind="$attrs"
      />

      <div class="wrapper-search-field">
        <search-field
          data-cy="search-user-input"
          :is-loading="isLoading"
          :term="term"
          icon-size="20"
          @input="search => $emit('update-search', { search })"
          @submit="$emit('submit')"
          placeholder="Encontre um usuário"
          @reset="$emit('update-search', { search: '' })"
          @focusout="$emit('focusout')"
        />
      </div>
    </div>
  </div>
</template>

<script>
import CProgress from '@/components/CComponents/CProgress'
import SearchField from '@/components/CComponents/SearchField'
import CInfoButton from '@/components/CComponents/CInfoButton'
import UserFilter from '@/components/User/UserFilter'

export default {
  components: { CProgress, SearchField, UserFilter, CInfoButton },
  props: {
    isLoading: Boolean,

    term: {
      type: String
    },

    userCount: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      popoverLabel: 'Relação entre os usuários com status de ativo e o total (soma de todos os status) '
    }
  },

  computed: {
    percentage () {
      const active = this.userCount.active_users || 0
      const total = this.userCount.total_users || 1

      if (total === '0') return 0

      return ((active * 100) / (total || 1)) + ''
    }
  }
}
</script>

<style lang="scss">
  @import '~@/styles/reference';

  .user-controls {
    @include container;
    @include desktop { padding: 0 !important; }

    display: flex;
    justify-content: space-between;
    margin-top: 30px;
    margin-bottom: 20px;
    padding: 0 5px;

    @include mobile {
      flex-direction: column;
    }

    & >.list-heading {
      flex-grow: 1;
      width: 100%;
      max-width: 600px;

      .c-title {
        margin-bottom: 9px;
        .title {
          display: flex;
          justify-content: space-around;
          width: 28%;
          align-items: center;
        }
        .count-wrapper {
          font-size: 24px;

          .count.active {
            font-weight: bold;
            color: $primary-color-placeholder;
            color: var(--color-primary);
          }
          .count {
            font-weight: 200;
          }
        }
      }
    }

    & > .actions {
      display: flex;
      flex-flow: row nowrap;
      justify-content: flex-end;
      align-items: center;

      @include mobile {
        margin-top: 10px;
        flex-direction: column-reverse;
      }

      & > .wrapper-search-field {
        min-width: 200px;

        @include mobile {
          margin-bottom: 10px;
          margin-left: 0px;
          width: 100%;
        }

        @include desktop {
          min-width: 230px;
        }
      }

      & > * + * { margin-left: 10px; }
    }
  }
</style>
