<template>
  <c-modal
    name="user-manager-confirmation"
    class="user-manager-confirmation"
    :is-opened="showModalManager"
    @close="$emit('close')"
  >
    <c-character type="embarrassed" />

    <div class="container">
      <h3 class="title">
        Tem certeza que deseja {{ !isManager ? 'desativar este' : 'tornar esse usuário' }} gestor?
      </h3>

      <p
        v-if="isManager"
        class="sub-title"
      >
        Se ativá-lo como gestor, ele poderá ter acesso a este painel.
        Para isso, ele terá que ativar a conta em até 24 horas. Deseja continuar?
      </p>
      <p
        v-else
        class="sub-title"
      >
        Se desativá-lo, ele não poderá mais ter acesso a este painel. Deseja continuar?
      </p>
    </div>

    <div class="actions">
      <c-button
        size="lg"
        class="cancel"
        @click="$emit('cancel')"
      >
        Cancelar
      </c-button>
      <c-button
        primary
        size="lg"
        @click="$emit('change-status-manager')"
      >
        {{ !isManager ? 'Desativar' : 'Ativar' }}
      </c-button>
    </div>
  </c-modal>
</template>

<script>
import CModal from '@/components/CComponents/CModal'
import CCharacter from '@/components/CComponents/CCharacter'

export default {
  components: { CModal, CCharacter },

  props: {
    showModalManager: Boolean,
    isManager: Boolean
  }
}
</script>

<style lang="scss">
@import '~@/styles/reference';

.user-manager-confirmation {
  display: flex;
  justify-content: center;

  .c-character {
    width: 100px;
    height: 100px;
    padding: 50px;
  }

  & > .content {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 20px;

    & > .container {
      margin-top: 20px;

      & > .title {
        margin-top: 20px;
        text-align: center;
        color: $title-color;
      }

      & > .sub-title {
        margin-top: 20px;
        text-align: center;
      }
    }

    & > .actions {
      margin-top: 20px;

      & > .cancel {
        margin-right: 10px;
      }
    }
  }
}
</style>
