<template>
  <div class="container">
    <c-title
      data-cy="step-title"
      class="title"
      size="3"
    >
      Distribuir Moedas
    </c-title>
    <div class="header">
      <p>
        Escolha a quantidade de moedas que deseja distribuir considerando que <strong>1 moeda = R$ 1,00.</strong>
        É possível digitar uma quantidade para todos os usuários no campo presente na barra de ações da tabela ou digitar quantidades diferentes no campo individual na linha da tabela.
      </p>
    </div>
    <div
      data-cy="total-card"
      class="total-card"
    >
      Total a pagar:
      <span
        data-cy="total-value"
        class="total"
      >
        {{ formatCurrency(totalValue) }}
      </span>
    </div>
    <users-values-table
      use-cpf-mask
      :table-data="filteredUsers"
      :paginate="paginate"
      @input="search = $event"
      @update-values="updateUserValues"
      @update-paginate="updatePaginate"
    />
  </div>
</template>

<script>
import UsersValuesTable from './Components/StepTwo/UsersValuesTable.vue'
export default {
  components: {
    UsersValuesTable
  },
  props: {
    tableData: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      search: '',
      tableDataCopy: [],
      paginate: {
        current_page: 1,
        from: 1,
        per_page: 5,
        to: 5,
        total: 5
      }
    }
  },
  computed: {
    totalValue () {
      if (typeof this.tableDataCopy.rows === 'undefined') { return 0 }
      let totalValue = 0
      this.tableDataCopy.rows.forEach(user => {
        if (user.hasOwnProperty('value')) {
          totalValue = totalValue + Number(user.value)
        }
      })
      return totalValue
    },
    atLeastOneValueComplited () {
      const valuesFilled = this.tableDataCopy.rows.filter(user => typeof user.value !== 'undefined' && user.value > 0)
      return valuesFilled.length > 0
    },
    filteredUsers () {
      const tdc = JSON.parse(JSON.stringify(this.tableDataCopy))
      const formatedCpfValue = this.formatCpfSearch(this.search)
      const filteredRows = (!this.search)
        ? tdc.rows
        : tdc.rows.map(user => {
          if (this.formatCpfSearch(user.document).includes(formatedCpfValue) || user.email.includes(this.search)) {
            return user
          }
        }).filter(element => {
          return element !== undefined
        })

      this.paginate = {
        current_page: 1,
        from: filteredRows.length ? 1 : 0,
        per_page: 5,
        to: filteredRows.length >= 5 ? 5 : filteredRows.length,
        total: filteredRows.length
      }

      return {
        ...this.tableDataCopy,
        rows: filteredRows
      }
    }
  },
  created () {
    this.tableDataCopy = this.tableData
  },
  methods: {
    updatePaginate (newPaginate) {
      this.paginate = newPaginate
    },
    getDefaultPaginate () {
      this.paginate = {
        current_page: 1,
        from: this.tableDataCopy.rows.length ? 1 : 0,
        per_page: 5,
        to: this.tableDataCopy.rows.length >= 5 ? 5 : this.tableDataCopy.rows.length,
        total: this.tableDataCopy.rows.length
      }
    },
    formatCpfSearch (value) {
      return value.replace(/[.-]/gi, '')
    },
    formatCurrency (value) {
      if (!value) return 'R$ 0,00'
      return value.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
    },
    updateUserValues (data) {
      if (!this.search) {
        this.tableDataCopy = data
      } else {
        data.rows.forEach((user) => {
          const matchedUserIndex = this.tableDataCopy.rows.findIndex(row => row.id === user.id)
          this.tableDataCopy.rows[matchedUserIndex].value = user.value
        })
      }

      if (this.atLeastOneValueComplited) {
        this.$emit('input', { users: this.tableDataCopy.rows, value: this.totalValue })
      } else {
        this.$emit('input', { users: null })
      }
    }
  }
}
</script>

<style lang='scss' scoped>
.container {
  max-width: 1020px;
  margin-top: 40px;
  & > .title {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #202950;
  }
  & > .header {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    margin-top: 24px;
    margin-bottom: 48px;

    p {
      font-size: 16px;
      color: #202950;
    }
  }
  & > .total-card {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px;
    gap: 6px;
    width: -moz-fit-content;
    width: fit-content;
    min-width: 200px;
    height: 38px;
    background: #FDFDFD;
    border: 1px solid #BEC0D3;
    box-shadow: 0px 2px 4px rgba(94, 102, 132, 0.08);
    border-radius: 4px;
    margin-bottom: 16px;
    font-size: 14px;

    .total {
      font-weight: 700;
    }
  }
}
</style>
