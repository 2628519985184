var render = function render(){var _vm=this,_c=_vm._self._c;return _c('login-template',{staticClass:"login-page",attrs:{"background":_vm.data.background,"background-image":_vm.backgroundImage,"is-mobile":_vm.isMobile,"developed-by-convenia":_vm.data.developedByConvenia}},[(_vm.data.signInLogo)?_c('c-image',{ref:"signInLogo",staticClass:"logo-form",attrs:{"src":_vm.data.signInLogo,"placeholder":_vm.data.signInLogo,"width":"125px"}}):_c('c-logo',{staticClass:"logo-form",attrs:{"kaledo":""}}),_vm._v(" "),_c('div',{staticClass:"form"},[_c('p',{staticClass:"text",style:({
        color: _vm.data.mainText
      })},[_vm._v("\n      "+_vm._s(_vm.data.intro)+"\n    ")]),_vm._v(" "),_c('c-input',{staticClass:"input-data",style:({
        color: _vm.data.negativeFeedback
      }),attrs:{"required":"","autocomplete":"new-password","icon":"contacts","placeholder":"Insira seu CPF","mask":'###.###.###-##',"validation-icon":"warning-circle","validation":"CPF inválido."}}),_vm._v(" "),_c('c-input',{staticClass:"input-data",style:({
        color: _vm.data.negativeFeedback
      }),attrs:{"required":"","autocomplete":"new-password","icon":"lock2","placeholder":"Insira sua senha","validation-icon":"warning-circle","validation":"Senha incorreta."}}),_vm._v(" "),_c('div',{staticClass:"under-input"},[_c('a',{staticClass:"forget-pwd",style:({
          color: _vm.data.forgotPassword
        }),on:{"click":function($event){$event.preventDefault();}}},[_vm._v("\n        Esqueci a senha\n      ")])]),_vm._v(" "),_c('div',{staticClass:"btn-group"},[_c('c-button',{staticClass:"btn main-button",style:({
          backgroundColor: _vm.data.mainButton,
          color: _vm.data.mainButtonText
        }),attrs:{"preview":"","size":"lg"},on:{"click":function($event){$event.preventDefault();}}},[_vm._v("\n        Entrar\n      ")]),_vm._v(" "),_c('span',{staticClass:"lines",style:({
          color: _vm.data.or
        })},[_vm._v("OU")]),_vm._v(" "),_c('c-button',{staticClass:"btn",style:({
          borderColor: _vm.data.secondaryButton,
          color: _vm.data.secondaryButton
        }),attrs:{"preview-alternative":"","size":"lg"},on:{"click":function($event){$event.preventDefault();}}},[_vm._v("\n        Ativar Conta\n      ")])],1),_vm._v(" "),_c('h3',{staticClass:"redefine"},[_vm._v("\n      Não consegue acessar sua conta? "),_c('span',{staticClass:"click-here"},[_vm._v("Clique aqui")])])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }