<template>
  <section>
    <c-spinner
      v-if="isLoading"
      size="md"
    />
    <div
      class="campaign-container"
      v-else
    >
      <campaign-header
        v-if="!isEdit"
        :partner="partner"
        :nav-tabs="navTabs"
        :campaign="campaign"
        :is-future="isFuture"
        :active-tab="activeTab"
        :is-exclusive="isExclusive"
        :is-loading="loadingStatus"
        @change-active-tab="(tab) => activeTab = tab"
        @delete-campaign="displayDeleteWarning = true"
        @toggle-campaign-status="toggleStatus"
      />

      <campaign-information
        v-if="isEdit && campaign"
        :campaign="campaign"
        :partner="partner"
      />

      <campaign-details
        v-else
        :partner="partner"
        :campaign="campaign"
        :active-tab="activeTab"
        :is-exclusive="isExclusive"
        @copy="showFeedback"
      />

      <c-confirm-modal
        :btn-props="{ danger: true }"
        confirm-text="Excluir"
        character="embarrassed"
        title="Tem certeza que deseja excluir essa oferta?"
        message="Se excluí-la, as mídias armazenadas hoje também serão deletadas permanentemente"
        :is-opened="displayDeleteWarning"
        @close="displayDeleteWarning = false"
        @confirm="confirmDelete(campaign.id)"
      />

      <c-confirm-modal
        :btn-props="{ primary: true }"
        confirm-text="Inativar"
        character="embarrassed"
        title="Tem certeza que deseja inativar essa oferta?"
        message="Se inativá-la, não será possível visualizar qualquer conteúdo referente à ela no seu clube"
        :is-opened="displayStatusWarning"
        @close="closeStatusConfirm"
        @confirm="confirmStatus"
      />
    </div>
  </section>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Loadable from '@/modules/loadable'
import isFuture from '@/modules/isFuture'
import * as types from '@/store/types'

import CConfirmModal from '@/components/CComponents/CConfirmModal'

import CampaignInformation from '@/containers/Campaign/Information/CampaignInformation'
import CampaignHeader from '@/components/Campaign/Details/CampaignHeader'
import CampaignDetails from '@/components/Campaign/Details/CampaignDetails'

const navTabs =
[
  { name: 'Benefício',
    value: 'campaign'
  },
  { name: 'Parceiro',
    value: 'partner'
  }
]

export default {

  components: {
    CConfirmModal,
    CampaignHeader,
    CampaignDetails,
    CampaignInformation
  },
  mixins: [ Loadable ],

  props: { isEdit: Boolean },

  data () {
    return {
      navTabs,
      activeTab: this.$route.query['activeTab'] || navTabs[0].value,
      displayDeleteWarning: false,
      displayStatusWarning: false,
      loadingStatus: false
    }
  },

  computed: {
    ...mapGetters({
      campaign: types.CAMPAIGN,
      partner: types.PARTNER
    }),
    isExclusive () {
      return !!this.partner.company_id
    },
    isFuture () {
      return isFuture(this.campaign.start_date * 1000)
    }
  },
  methods: {
    ...mapActions({
      toggleCampaignStatus: types.CAMPAIGN_TOGGLE_STATUS,
      deleteCampaign: types.CAMPAIGN_DELETE,
      campaingClear: types.CAMPAIGN_CLEAR,
      showFeedback: types.FEEDBACK,
      getCampaign: types.CAMPAIGN,
      getPartner: types.PARTNER
    }),
    async load () {
      const partner = this.$route.params['slug']
      const campaign = this.$route.params['campaign']
      const promises = [
        this.getPartner({ slug: partner }),
        campaign && this.getCampaign({ slug: campaign })
      ]

      await Promise.all(promises)
    },
    async confirmDelete (id) {
      const slug = this.$route.params.slug
      await this.deleteCampaign({ id })

      this.displayDeleteWarning = false
      this.$router.push({ name: 'parceiros-slug-campanhas', params: { slug } })
    },
    async confirmStatus () {
      await this.toggleCampaignStatus({ slug: this.campaign.slug })
      this.closeStatusConfirm()
    },
    async toggleStatus () {
      const status = this.campaign.status ? 0 : this.isFuture ? 2 : 1
      this.loadingStatus = true

      if (!status) this.displayStatusWarning = true
      else await this.confirmStatus()
    },
    closeStatusConfirm () {
      this.displayStatusWarning = false
      this.loadingStatus = false
    }
  },
  beforeDestroy () {
    this.campaingClear()
  }
}
</script>
