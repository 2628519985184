<template>
  <section class="layout-header">
    <div class="content">
      <div class="header-branding">
        <c-button
          v-if="!isDesktop"
          icon="menu"
          size="lg"
          class="button menu-toggle"
          @click="$emit('toggle-sidebar')"
        />

        <c-image
          class="logo"
          is-background
          :src="logo"
          :placeholder="require('@/assets/img/logoPlaceholder.png')"
          @click="$router.push({ name: 'index' })"
        />
      </div>

      <c-menu
        v-if="isDesktop"
        exact
        :items="menuItems"
        :permissions="permissions"
      />

      <div class="header-actions">
        <button
          v-if="isTrialUser"
          id="missions-button"
          class="quest-button"
          @click="handleQuestButtonClick"
        >
          <k-icon
            data-cy="quest-button"
            :is-colorful="true"
            :number-of-colors="3"
            class="quest-icon"
            icon="kaledo-vida"
            size="24px"
          />
          <span class="text">Missões</span>
        </button>
        <quest-modal
          v-if="showQuestsModal"
          :show="showQuestsModal"
          @close="showQuestsModal = false"
        />

        <c-button
          v-if="isDesktop"
          data-cy="user-menu-button"
          class="button profile"
          icon="user"
          size="lg"
          ref="popoverTrigger"
          @click="showPopover = !showPopover"
        />

        <c-popover
          class="itens-popover"
          v-if="showPopover && isDesktop"
          :position="['top', 'right']"
          :target="$refs['popoverTrigger']"
          @blur="showPopover = false"
        >
          <layout-menu
            :user="user"
            :companies="companies"
            :support-path="supportPath"
            :club-url="clubUrl"
            v-bind="$attrs"
            @change-account="changeAccount"
            @close="showPopover = false"
            @logout="$router.push({ name: 'logout' })"
          />
        </c-popover>
      </div>
    </div>
  </section>
</template>

<script>
import { KIcon } from 'kaledo-components'
import CMenu from '@/components/CComponents/CMenu'
import CPopover from '@/components/CComponents/CPopover'
import LayoutMenu from '@/components/Layout/LayoutMenu'
import CImage from '@/components/CComponents/CImage'
import { primaryMenu } from '@/content/menuItems'
import { mapActions, mapGetters } from 'vuex'
import QuestModal from '@/components/Trial/Modals/QuestsModal.vue'
import {
  CHANGE_ACCOUNT,
  AUTH,
  CONFIG_COMPANY_LIST,
  COMPANY,
  CHANGE_TO_COMPANY
} from '@/store/types'

export default {
  components: {
    CMenu,
    CPopover,
    LayoutMenu,
    CImage,
    KIcon,
    QuestModal
  },

  props: {
    user: Object,
    logo: String,
    clubUrl: {
      type: String,
      required: true
    },
    supportLink: String,
    permissions: {
      type: Array
    }
  },

  data () {
    return {
      showPopover: false,
      modifyQuestButtonDiv: false,
      showQuestsModal: false,
      supportPath: this.supportLink || 'https://clubealelo.kayako.com/'
    }
  },
  computed: {
    ...mapGetters({
      auth: AUTH,
      companies: CONFIG_COMPANY_LIST,
      company: COMPANY,
      changeToCompany: CHANGE_TO_COMPANY
    }),

    img () {
      return this.logo || require('@/assets/img/logoPlaceholder.png')
    },

    menuItems () {
      return primaryMenu(this.permissions)
    },
    isTrialUser () {
      return this.company.trial
    }
  },
  created () {
    this.$bus.$on('modify-quest-div', () => {
      this.modifyQuestButtonDiv = true
    })
  },
  methods: {
    ...mapActions({
      getToken: CHANGE_ACCOUNT
    }),

    async changeAccount (account) {
      await this.getToken(account)

      if (this.auth.token) {
        await window.dataLayer.push({
          event: 'troca-de-contas',
          company_name: this.company.name,
          changed_to_company: this.changeToCompany.name
        })

        document.location.reload()
      }
    },
    handleQuestButtonClick () {
      this.modifyQuestButtonDiv = false
      this.$bus.$emit('close-trial-onboarding')
      this.$bus.$emit('close-sidebar-menu')
      this.showQuestsModal = true
      window.dataLayer.push({
        event: 'visualizar-missoes',
        company_name: this.company.name
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/styles/reference';

.layout-header {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  flex-shrink: 0;

  position: relative;
  z-index: 5;

  height: 70px;
  padding: 10px;

  box-shadow: 0px 4px 5px -2px rgba(213, 214, 226, 0.24);
  background-color: #FFF;

  & > .content {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;

    width: 100%;
    @include desktop { @include container; }

    & > .header-branding {
      display: flex;
      align-items: center;

      @include desktop { margin-right: 80px; }

      & > .logo {
        height: 60px;
        width: 100px;
        padding: 30px 50px;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center;
        cursor: pointer;
      }

      & > .menu-toggle { margin: 0 10px; }
    }

    & > .header-actions {
      display: flex;
      align-items: center;
      justify-content: center;

      .quest-button {
        width: 137px;
        height: 48px;
        color: #FDFDFD;
        background: $base-color;
        border: none;
        border-radius: 8px;
        padding: 16px 24px;
        display: flex;
        align-items: center;
        cursor: pointer;
        transition: all 0.2s ease-in-out;
        box-shadow: 0px 4px 8px 0px rgba(32, 41, 80, 0.24);
        &:hover {
          background: #8C92B1;
        }
        .quest-icon {
          font-size: 20px;
          margin-right: 8px;
        }
        .text {
          font-family: $title-font-family;
          font-size: 13px;
          font-weight: 700;
          text-transform: uppercase;
        }
      }

      & > .itens-popover {
        z-index: 50;
      }

      & > .c-button:not(.-icon-only):not(:last-child) { margin-right: 19px; }
      & > .c-button:not(:first-child) { margin-left: 12px; }
    }
  }
}
</style>

<style lang="scss">
.k-button {
  &.quest-button {
    width: 137px;
    height: 48px;
    .icon {
      left: 10px;
      bottom: 13px;
    }
  }
}
</style>
