<template>
  <div class="forget-pwd-content">
    <div class="forget-pwd-box">
      <c-modal
        name="password-recuperation-failed"
        :is-opened="failedModal"
        @close="$emit('toggle-failed-modal')"
      >
        <div class="modal">
          <c-character
            type="sad"
            class="character"
          />
          <p class="modal-title">
            CPF não habilitado para ser gestor
          </p>
          <p class="modal-text">
            Ops! Você <span>não está habilitado</span> como gestor desta conta.
          </p>
          <c-button
            primary
            size="lg"
            class="btn"
            @click="$emit('toggle-failed-modal')"
          >
            Ok
          </c-button>
        </div>
      </c-modal>

      <c-modal
        name="password-recuperation-sucess"
        :is-opened="sucessModal"
        @close="$router.push({ name: 'login' })"
      >
        <div class="modal">
          <c-character
            type="happy"
            class="character"
          />
          <p class="modal-title">
            Recuperação de senha solicitada com sucesso!
          </p>
          <p class="modal-text">
            Foi enviado um e-mail para <span>{{ email }}</span> com o passo a passo para recuperar a sua senha
          </p>
          <c-button
            primary
            size="lg"
            class="btn"
            @click="$emit('toggle-sucess-modal')"
          >
            Ok
          </c-button>
        </div>
      </c-modal>

      <login-leftside />

      <div class="forget-pwd-rightside">
        <div class="logo">
          <c-logo
            kaledo
          />
        </div>

        <c-form
          @submit="$emit('forget-password', cpf)"
          class="form"
        >
          <span class="title">Esqueceu sua senha?</span>
          <p>Insira CPF no campo abaixo e te enviaremos um o passo a passo para redefinir a sua senha com segurança.</p>

          <c-input
            data-cy="forget-password-document-input"
            required
            icon="contacts"
            placeholder="Insira seu CPF"
            class="input-data"
            name="CPF"
            v-validate="'required'"
            data-vv-delay="10"
            :feedback-show="errors.has('CPF') || !isDocumentValid"
            :feedback-message="!isDocumentValid ? 'CPF inválido' : errors.first('CPF')"
            :mask="'###.###.###-##'"
            :value="cpf"
            @click.native="touched = true"
            @input="(documentcpf) => cpf = documentcpf = documentcpf.replace(/\D/g, '')"
          />
          <div class="btngroup">
            <c-button
              data-cy="cancel-forget-password-button"
              default
              size="lg"
              class="btn"
              type="button"
              @click="$router.push({ name: 'login' })"
            >
              Cancelar
            </c-button>

            <c-button
              data-cy="send-forget-password-email-button"
              primary
              size="lg"
              class="btn"
              :disabled="!isFormValid"
            >
              Enviar e-mail
            </c-button>
          </div>
        </c-form>
      </div>
    </div>
  </div>
</template>

<script>
import CForm from '@/components/CComponents/CForm'
import CLogo from '@/components/CComponents/CLogo'
import CModal from '@/components/CComponents/CModal'
import CCharacter from '@/components/CComponents/CCharacter'
import LoginLeftside from '@/components/Login/LoginLeftside'
import { isCPF } from '@/modules/validate/validators.js'

export default {
  components: {
    CForm,
    CLogo,
    CModal,
    CCharacter,
    LoginLeftside
  },
  props: {
    failedModal: {
      type: Boolean,
      default: false
    },
    sucessModal: {
      type: Boolean,
      default: false
    },
    email: {
      type: String
    }
  },
  data () {
    return {
      isOpened: false,
      CPFState: true,
      cpf: '',
      touched: false
    }
  },
  computed: {
    isDocumentValid () {
      return isCPF(this.cpf) || !this.touched || !this.cpf
    },
    isFormValid () {
      return this.cpf.length === 11 && isCPF(this.cpf)
    }
  }
}
</script>

<style lang="scss">
  @import '~@/styles/reference';

  .forget-pwd-content {
    @include LoginContent;

    & > .forget-pwd-box {
      @include LoginBox;

      @include desktop {
        width: 780px;
        min-width: 600px;
      }

      & > .modal {
        @include desktop {
          top: 50%;
        }
      }

      & > .forget-pwd-rightside {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 0 40px;

        @include desktop {
          width: 55%;
        }
        & > .logo {
          margin-top: 50px;
          margin-bottom: 40px;
        }
        & > .form {
          margin-bottom: 50px;
          max-width: 320px;

          & > .title {
            font-family: $title-font-family;
            font-weight: 500;
            color: $title-color;
          }
          & > .input-data {
            margin-top: 20px;
            input {
              &:hover,
              &:active,
              &:focus {
                border-color: $color-primary-login;
                box-shadow: 0 0 0 1px $color-primary-login;
                outline: $color-primary-login;
              }
            }
          }
          & > .btngroup {
            display: flex;
            justify-content: space-between;
            margin-top: 30px;

            button{
              width: 45%;
              &:hover,
              &:active,
              &:focus {
                border-color: $color-primary-login;
                box-shadow: 0 0 0 1px $color-primary-login;
                outline: $color-primary-login;
              }
            }
          }
        }
      }
    }
  }
  .modal {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    @include desktop {
      min-width: 400px;
      min-height: 300px;
    }

    & >.character {
      width: 100px;
      height: 100px;
      padding: 50px;
    }

    & > .modal-title {
      font-size: 16px;
      margin-top: 30px;
      margin-bottom: 10px;
      text-align: center;
      font-family: $base-font-family;
      font-weight: bold;
      color: $title-color;
    }

    & > .modal-text {
      font-size: 13px;
      text-align: center;
      font-family: $base-font-family;
      margin-bottom: 30px;

      span {
        font-weight: bold;
      }
    }
    & > .btn {
      width: 30%;
    }
  }
</style>
