<template>
  <c-card
    data-cy="partner-card"
    :class="['partner-card', { '-inactive': !localStatus }]"
  >
    <div class="top-section">
      <partner-tag
        class="tag"
        v-if="isExclusive"
        :is-exclusive="isExclusive"
      />

      <c-image
        class="partner-logo"
        :src="logo || imagePlaceholder"
        :alt="name"
        placeholder="~/assets/img/campaign-placeholder.svg"
        is-background
      />
    </div>

    <div class="bottom-section">
      <div class="description">
        <p class="partner-name">
          {{ name }}
        </p>
        <p class="partner-category">
          {{ category }}
        </p>
        <div class="campaign-count">
          <span
            class="icon"
            data-cy="partner-card-edit-button"
          >
            <c-icon
              icon="target"
              size="15"
            />
          </span>
          <span class="campaign-count-text">{{ campaignCount }}</span>
          <span class="text">Ofertas</span>
        </div>
      </div>
      <div class="partner-actions">
        <c-toggle
          :height="30"
          :width="100"
          :labels="{ checked: 'Ativo', unchecked: 'Inativo' }"
          :value="localStatus"
          @input="toggleStatus"
        />
      </div>
    </div>
  </c-card>
</template>

<script>
import CCard from '@/components/CComponents/CCard'
import CImage from '@/components/CComponents/CImage'
import PartnerTag from '@/components/Partners/PartnerTag'

export default {
  components: { CCard, CImage, PartnerTag },
  props: {
    id: String,
    name: String,
    logo: String,
    slug: String,
    status: Boolean,
    category: String,
    isExclusive: Boolean,
    campaignCount: Number
  },
  data () {
    return {
      localStatus: this.status || false,
      imagePlaceholder: require('@/assets/img/campaign-placeholder.svg')
    }
  },
  mounted () {
    this.localStatus = this.status
  },
  methods: {
    toggleStatus (ev) {
      this.localStatus = !this.localStatus

      this.$emit('toggle-partner-status', {
        slug: this.slug,
        name: this.name,
        status: this.localStatus,
        isExclusive: this.isExclusive
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/styles/reference';

.partner-card {
  position: relative;
  cursor: pointer;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  @include desktop { width: 230px; }

  &.-inactive { background-color: #FBFCFE; }

  .top-section {
    display: flex;
    align-items: center;
    flex-direction: column;

    width: 100%;

    & > .partner-tag {
      position: absolute;
      left: calc(50% + 18.5px);
      top: 0;

      transform: translateX(-50%);
    }

    .partner-logo {
      flex: 50%;

      max-width: 100px;
      max-height: 90px;

      margin: 45px 20px 10px 20px;

      background-size: contain;
      background-clip: border-box;
      background-repeat: no-repeat;
      background-position: center;
    }
  }

  .bottom-section {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 1;

    padding: 9px 0;
    margin: 0 10px;

    .description {
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: flex-end;
      padding-bottom: 10px;
      border-bottom: 1px solid #F3F3F4;

      flex-grow: 1;
      width: 100%;

      .partner-name {
        color: $title-color;
        font-weight: bold;

        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: calc(100% - 40px);
      }

      .campaign-count {
        display: flex;
        position: relative;
        align-items: center;
        justify-content: center;
        color: $title-color;
        font-size: 14px;

        padding-left: 25px;
        margin-top: 5px;

        .icon {
          display: flex;
          position: absolute;
          left: 0px;
        }

        .campaign-count-text { font-weight: bold; }
        .text:not(:only-child) { margin-left: 5px; }
      }
    }

    .partner-actions {
      display: flex;
      align-items: center;
      justify-content: flex-end;

      padding-top: 10px;
      flex-shrink: 0;
      height: 50px;

      & > .details {
        margin: 5px 0 5px 5px;
        padding-left: 1px;
      }
    }
  }
}
</style>
