<template>
  <div class="details-card">
    <div class="details-header">
      <c-title
        class="title"
      >
        {{ title }}
      </c-title>
      <slot name="header" />
    </div>
    <div class="details-content">
      <slot name="content" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: String
  }
}
</script>

<style lang="scss" scoped>
@import '~@/styles/reference';
.details-card {
  width: 100%;
  height: 100%;
  max-width: 378px;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.16);
  background: #FDFDFD;
  padding: 16px;

  @include mobile { max-width: 100%; }

  .details-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    > .title {
      max-width: 200px;

    }
  }
}
</style>
