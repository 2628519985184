<template>
  <c-modal
    v-bind="$attrs"
    v-on="$listeners"
    class="c-confirm-modal"
    name="c-confirm-modal"
  >
    <div class="c-confirm-modal-body">
      <template v-if="!$slots.body">
        <c-character
          :type="character"
          v-if="character"
        />
        <div class="warning-text">
          <h3 class="lead">
            {{ title }}
          </h3>
          <p class="text">
            {{ message }}
          </p>
        </div>
      </template>

      <slot name="body" />
    </div>

    <div class="c-confirm-modal-actions" v-if="!noButtons">
      <c-button
        v-if="!hideCancel"
        size="lg"
        class="btn-action -cancel"
        :is-loading="isLoading"
        @click="$emit('close')"
      >
        {{ cancelText }}
      </c-button>
      <c-button
        v-bind="btnProps"
        size="lg"
        class="btn-action -confirm"
        :disabled="isLoading"
        @click="$emit('confirm')"
      >
        {{ confirmText }}
      </c-button>
    </div>
  </c-modal>
</template>

<script>
import CModal from '@/components/CComponents/CModal'
import CCharacter from '@/components/CComponents/CCharacter'

export default {
  components: { CModal, CCharacter },
  props: {
    noButtons: {
      type: Boolean,
      default: false
    },
    character: {
      type: String,
      required: false
    },
    title: {
      type: String,
      required: true
    },
    message: {
      type: String,
      default: 'Tem certeza que deseja fazer isso?'
    },
    cancelText: {
      type: String,
      default: 'Cancelar'
    },
    confirmText: {
      type: String,
      default: 'OK'
    },
    isLoading: {
      type: Boolean,
      default: false
    },
    showClose: {
      type: Boolean,
      default: false
    },
    btnProps: {
      type: Object,
      default: () => ({ primary: true })
    },
    hideCancel: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss">
@import '~@/styles/reference';

.c-confirm-modal {
  background-color: #FFF !important;
  z-index: 30 !important;

  & > .content {
    display: flex;
    align-items: center;
    flex-direction: column;

    @include mobile { justify-content: center; }

    .c-confirm-modal-body {
      display: flex;
      align-items: center;
      flex-direction: column;

      margin-top: 20px;

      & > .c-character {
        width: 70px;
        height: 70px;
        padding: 50px;
      }

      & > .warning-text {
        text-align: center;

        & > .lead { color: $title-color; margin-top: 20px; }
        & > .text {
          font-size: 14px;
          color: $text-color;

          max-width: 410px;
          margin: 17px 0 20px 0;

          &:last-child { margin-bottom: 40px; }
        }
      }
    }

    .c-confirm-modal-actions {
      width: 100%;
      display: flex;
      justify-content: center;

      margin-bottom: 25px;

      & > .btn-action {
        width: 100%;

        @include desktop { max-width: 180px; }
        &:not(:last-child) { margin-right: 10px; }

        &.-cancel {
          border: 1px solid rgba(94, 102, 132, 1);
          border: 1px solid var(--color-secondary);
          transition: all 0.1s ease;

          &:hover {
            border-width: 2px;
          }
        }
        &.-confirm {
          background: #121E48;
          background: var(--color-primary);
          transition: all 0.3s ease;

          &:hover {
            opacity: 0.9;
            background: var(--color-primary-8);
          }
        }
      }
    }
  }
}
</style>
