<template>
  <div
    class="c-popover"
    :class="classy"
    :style="style"
  >
    <header
      v-if="$slots.header"
      class="header"
    >
      <slot name="header" />
    </header>

    <div class="content">
      <slot />
    </div>
  </div>
</template>

<script>
import getParents from '@/modules/getParents'

export default {
  props: {
    target: {
      type: [String, HTMLElement, Object],
      required: true
    },
    arrow: {
      type: Boolean,
      default: true
    },
    position: {
      validator (value) {
        const positions = ['top', 'bottom']
        const directions = ['right', 'center', 'left']
        const valid = Array.isArray(value) && positions.includes(value[0]) && directions.includes(value[1])

        return valid
      }
    },
    leftPixels: String
  },

  data () {
    return {
      style: {}
    }
  },

  computed: {
    classy () {
      const [position, direction] = this.position
      const classy = {
        [this.arrow
          ? `-${position}-${direction}-arrow`
          : '-no-arrow']: true
      }
      return classy
    },

    element () {
      return this.target instanceof String
        ? document.querySelector(this.target)
        : this.target instanceof HTMLElement
          ? this.target
          : this.target.$el
    }
  },

  watch: {
    target () {
      setTimeout(() => { this.calcStyle() }, 0)
    }
  },

  mounted () {
    this.calcStyle()
    window.addEventListener('click', this.blurEmitter)
    window.addEventListener('resize', this.calcStyle)
    window.addEventListener('scroll', this.calcStyle)
  },

  beforeDestroy () {
    window.removeEventListener('click', this.blurEmitter)
    window.removeEventListener('resize', this.calcStyle)
    window.removeEventListener('scroll', this.calcStyle)
  },

  methods: {
    calcStyle () {
      this.$nextTick(() => {
        const popoverPosition = this.$el.getBoundingClientRect()
        const targetPosition = this.element.getBoundingClientRect()
        let posX = targetPosition.left + (targetPosition.width / 2)

        if (this.position[1] === 'right') {
          posX = posX - popoverPosition.width + 20
        }

        if (this.position[1] === 'left') {
          posX = posX - 20
        }

        if (this.position[1] === 'center') {
          posX = posX - popoverPosition.width / 2
        }

        // mal time
        let top = {}
        if (this.position[0] === 'top') {
          top = (this.element.offsetTop + targetPosition.height + 8) + 'px'
        } else if (this.position[0] === 'bottom') {
          top = (this.element.offsetTop - targetPosition.height - popoverPosition.height + 8) + 'px'
        }

        this.style = { top, left: (this.leftPixels || posX + 'px') }
      })
    },

    blurEmitter (event) {
      const targets = event.target ? [event.target, ...getParents(event.target)] : []
      const isTarget = targets.includes(this.element)
      const isOutside = !targets.includes(this.$el)

      if (!isTarget && isOutside) this.$emit('blur')
    }
  }
}
</script>

<style lang="scss">
@import '~@/styles/reference';

$c-popover-arrow-size: 4px                      !default;
$c-popover-background: #fff                   !default;
$c-popover-border: 0;
$c-popover-header-background: $background-color !default;

@mixin box-shadow-as-border($border, $position: top) {
  $size: nth($border, 1) / 2;
  $color: nth($border, 3);

  @if ($position == top) {
    box-shadow: -#{$size + 1} -#{$size + 1} 0 #{$size - 1} $color;
  }
}

%c-popover-arrow-top {
  &::before {
    @extend %c-popover-arrow;
    top: 0;
    transform: rotate(45deg);
    border-color: $c-popover-background;
  }
}

%c-popover-arrow-bottom {
  &::before {
    @extend %c-popover-arrow;
    bottom: 0;
    transform: rotate(45deg);
    border-color: $c-popover-background;
  }
}

.c-popover {
  position: absolute;
  background-color: $c-popover-background;
  border: $c-popover-border;
  border-radius: 3px;
  box-shadow:
    0 0 1px rgba(#8e92a7, 0.1),
    0 0 6px rgba(#8e92a7, 0.2);
    z-index: 999;
  box-shadow: 0 2px 6px 0 rgba(0,0,0,0.2);

  & > .header,
  & > .content { box-sizing: border-box; }

  & > .header { @extend %c-popover-header; }

  &.-top-center-arrow {
    @extend %c-popover-arrow-top;
    &::before {
      left: 50%;
      transform: translate(-50%, -$c-popover-arrow-size) rotate(45deg);
    }
  }

  &.-top-right-arrow {
    @extend %c-popover-arrow-top;
    &::before {
      right: 3px;
      transform: translate(-50%, -$c-popover-arrow-size) rotate(45deg);
    }
  }

  &.-top-left-arrow {
    @extend %c-popover-arrow-top;
    &::before {
      left: 0;
      transform: translate(50%, -$c-popover-arrow-size) rotate(45deg);
    }
  }

  &.-bottom-left-arrow {
    @extend %c-popover-arrow-bottom;
    &::before {
      left: 15px;
      transform: translate(50%, $c-popover-arrow-size) rotate(45deg);
      z-index: 0 !important;
      box-shadow: 4px 4px 6px -1.5px rgba(0, 0, 0, 0.2) !important;
    }
  }
}

%c-popover-arrow {
  position: absolute;
  width: 0;
  height: 0;
  content: '';
  border: 8px solid white;
  box-shadow: -1px -1px 5px -2.5px rgba(0, 0, 0, 0.2);
}

%c-popover-header {
  background-color: $c-popover-header-background;
  border-bottom: 1px solid #e9eaee;
}
</style>
