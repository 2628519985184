<template>
  <div class="recovery">
    <login-modal
      :opened="showSuccess"
      maintext="Senha alterada com sucesso!"
      text="Para acessar o painel, basta clicar no botão abaixo e você será redirecionado para efetuar o login"
      type="happy"
      button="Fazer login"
      redirect="login"
      @close="$emit('close-success')"
    />

    <!-- conteudo -->
    <div class="content">
      <div class="header">
        <c-logo
          kaledo
        />
      </div>
      <c-form
        class="form"
      >
        <div class="text">
          <p class="first">
            <span class="featured-text">Olá, </span>
            Para redefinir a sua senha com segurança, preencha os campos indicados abaixo:
          </p>
          <p class="underline-text">
            Atenção
          </p>
          <p class="last">
            A nova senha será utilizada para acessar qualquer conta do clube atrelado ao seu CPF.
          </p>
        </div>
        <password
          has-checks
          v-model="passwordData.password"
          :feedback-message="'Senha inválida'"
          @is-valid="(isValid) => isPasswordValid = isValid"
        />
        <password
          label="Confirmar Senha"
          v-model="passwordData.confirm_password"
          :show-error="!matchPasswords"
          :feedback-message="'Senhas não coincidem'"
          @click.native="touched = true"
          @is-valid="(isValid) => isPasswordValid = isValid"
        />
        <c-button
          primary
          class="button"
          size="lg"
          :disabled="!isFormValid || isProcessing"
          @click="$emit('submit-form', passwordData)"
        >
          Redefinir Senha
        </c-button>
      </c-form>
    </div>
  </div>
</template>

<script>
import CForm from '@/components/CComponents/CForm'
import CLogo from '@/components/CComponents/CLogo'
import Password from '@/components/CComponents/Password.vue'
import LoginModal from '@/components/Login/LoginModal'

export default {
  components: {
    CForm,
    CLogo,
    Password,
    LoginModal
  },
  props: {
    isProcessing: {
      type: Boolean,
      required: true
    },
    showSuccess: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      passwordData: {
        password: '',
        confirm_password: ''
      },
      isPasswordValid: false,
      touched: false,
      imagePlaceholder: require('@/assets/img/clube_logo.svg')
    }
  },
  computed: {
    matchPasswords () {
      return !this.touched || this.passwordData.password === this.passwordData.confirm_password
    },
    isFormValid () {
      return this.isPasswordValid &&
        this.passwordData.password === this.passwordData.confirm_password
    }
  }
}
</script>

<style lang="scss">
@import '~@/styles/reference';

.recovery {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 100vh;

    & >.content {
      min-height: 100vh;
      min-width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      background-image: url('~../../assets/img/BGform.png');
      background-repeat: no-repeat;
      background-size: 100%;
      background-position: top;

      & >.header {
        width: 100%;
        display: flex;
        justify-content: center;
        padding: 15px;
        margin-top: 65px;
      }

      & >.form {
        width: 400px;
        min-width: 280px;
        display: flex;
        flex-direction: column;
        align-items: center;

        @include mobile {
          width: auto;
          padding: 10px;
        }

        & >.text {
          margin-top: 20px;
          margin-bottom: 20px;

          & >.first {
            display: flex;
            flex-direction: column;

            font-size: 16px;
            font-weight: bold;
            text-align: center;
            color: rgba(18,30,72,0.8);
            margin-bottom: 30px;

            & >.featured-text {
              font-size: 18px;
              font-family: $title-font-family;
              color: $color-primary-login;
              margin-bottom: 10px;
            }
          }
          & >.underline-text {
            margin: 0 auto;
            text-align: center;
            display: table;
            font-weight: 600;
            opacity: 0.8;
            margin-bottom: 10px;
            color: #202950;
            font-size: 14px;
            font-family: $title-font-family;

            &::after {
              width: 100px;
              height: 3px;
              display: block;
              margin-top: 5px;
              background-color: $color-primary-login;
              content: '';
            }
          }
          & >.last {
            opacity: 0.5;
            color: rgb(18,30,72);
            font-size: 14px;
            text-align: center;
            margin: 0 40px;
          }
        }
        & >.input-data {
          margin-left: 40px;
          margin-right: 40px;
        }
        input {
          &:hover,
          &:active,
          &:focus {
            border-color: $color-primary-login;
            box-shadow: 0 0 0 1px $color-primary-login;
            outline: $color-primary-login;
          }
        }
        .password:last-of-type{
          margin-top: 0px;
        }

        & >.button {
          margin: 0 auto;
          max-width: 374px;
          width: 100%;

          &:not(:first-child) { margin-top: 20px; }

          @include mobile {
            max-width: 100%;
          }
        }
      }
    }

    & > .modal-login > .modal-login {
      width: 500px;

      & > .content > .modal-content {
        margin-top: 10px;

        & > .text { max-width: 400px; }
        & > .button { max-width: 140px; width: 100%; margin-bottom: 15px; }
      }
    }
  }
</style>
