<template>
  <c-modal
    v-bind="$attrs"
    v-on="$listeners"
    :close-on-outside-click="false"
    :show-close="false"
    class="notification-name-modal"
    name="notification-name-modal"
  >
    <c-title
      data-cy="modal-title"
    >
      Nome para controle interno
    </c-title>
    <div class="modal-content">
      <div class="notification-input-container">
        <c-input
          data-cy="notification-name-input"
          class="notification-name"
          v-model="name"
          label="Insira um nome para controle interno"
          placeholder="Nome para controle interno"
          name="name"
          maxlength="100"
          required
          data-vv-delay="10"
          v-validate="'required'"
          :feedback-show="errors.has('name') || !nameValidation"
          :feedback-message="nameValidationMessage"
          @click.native="touched = true"
        />
        <c-length-input-text
          :show="!errors.has('name') && nameValidation"
          length="50"
        />
      </div>
    </div>
    <div class="modal-actions">
      <c-button
        data-cy="cancel-notification-button"
        class="button cancel-button"
        @click="$router.push({ name: 'comunicacao-notificacoes' })"
      >
        Cancelar
      </c-button>
      <c-button
        data-cy="confirm-notification-button"
        class="button confirm-button"
        icon="chevron-right"
        primary
        :disabled="disableSaveButton"
        @click="handleConfirm"
      >
        Continuar
      </c-button>
    </div>
  </c-modal>
</template>

<script>
import CModal from '@/components/CComponents/CModal'
import CLengthInputText from '@/components/CComponents/CLengthInputText'
import { minLengthValidation, maxLengthValidation } from '@/modules/validate/validators.js'
import { minLengthValidationMessage, maxLengthValidationMessage } from '@/modules/validate/helpers.js'

export default {
  components: { CModal, CLengthInputText },
  data () {
    return {
      name: '',
      touched: false
    }
  },
  computed: {
    nameValidation () {
      return (maxLengthValidation(this.name, 100) && minLengthValidation(this.name, 5)) || !this.touched
    },
    nameValidationMessage () {
      if (!maxLengthValidation(this.name, 100)) return maxLengthValidationMessage('nome', 100)
      else if (!minLengthValidation(this.name, 5)) return minLengthValidationMessage('nome', 5)
      return 'Campo obrigatório'
    },
    disableSaveButton () {
      return !this.nameValidation || !this.name
    }
  },
  methods: {
    handleConfirm () {
      this.$emit('close')
      this.$emit('confirm', this.name)
    }
  }
}
</script>

<style lang="scss">
@import '~@/styles/reference';

.notification-name-modal {
  z-index: 30 !important;
  height: 100%;
  width: 99%;
  max-height: 285px !important;
  max-width: 430px !important;

  > .content {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: space-between;
    padding-bottom: 10px;

    @include mobile { justify-content: center; }

    .modal-content {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;

      .notification-input-container {
        width: 100%;
      }
    }
    .modal-actions {
      width: 100%;
      display: flex;
      gap: 16px;

      .button {
        height: 40px;
        width: 200px;
        border-radius: 20px;
      }
      .cancel-button {
        border: 1px solid rgba(94, 102, 132, 1);
        border: 1px solid var(--color-secondary)
      }
    }
  }
}
</style>
