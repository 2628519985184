<template>
  <component
    :is="item.external ? 'a' : 'router-link'"
    :href="item.external ? item.path : null"
    :to="item.external ? null : item.path"
    :target="item.external ? '_blank' : null"
    class="layout-sidebar-item"
  >
    <c-icon
      class="icon"
      size="15"
      :icon="item.icon"
    />
    <span>{{ item.name }}</span>
    <c-icon
      v-if="arrow"
      class="arrow"
      size="10"
      icon="chevron-right"
    />
  </component>
</template>

<script>

export default {
  props: {
    item: {
      type: Object,
      required: true
    },
    arrow: Boolean
  }
}
</script>

<style lang="scss">

.layout-sidebar-item {
  display: block;
  text-align: left;
  width: 200px;
  margin: auto;
  position: relative;
  text-decoration: none;
  color: rgba(17.85, 30.6, 71.4, 1);
  opacity: 0.5;
  font-size: 12px;
  line-height: 16px;
  text-transform: uppercase;
  font-weight: 500;
  font-family: 'Ubuntu', sans-serif;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  padding-top: 18px;
  padding-bottom: 18px;
  cursor: pointer;

  &:hover { opacity: 0.5; }

  & > .icon,
  & > .arrow {
    fill: rgba(17.85, 30.6, 71.4, .5);
  }

  & > .icon { margin-right: 17px; }

  & > .arrow {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }
}
</style>
