<template>
  <activate-account
    @submit="submit"
    @clear-error="clearError"
    :is-loading="isLoading"
    :error="errorCode"
  />
</template>

<script>
import * as types from '@/store/types'
import { mapActions, mapGetters } from 'vuex'

import ActivateAccount from '@/components/Login/ActivateAccount'

const errorCodes =
{ 0: { type: '', data: {} }
, 1: { type: 'invalidCredentials', data: {} }
, 2: { type: 'invalidCredentials', data: {} }
, 3: { type: 'notEnabled', data: {} }
, 4: { type: 'notEnabled', data: {} }
, 6: { type: 'notEnabled', data: {} }
, 9: { type: 'invalidCredentials', data: {} }
, 12: { type: 'userExpired', data: {} }
}

export default {
  components: { ActivateAccount },
  data () {
    return {
      isLoading: false,
      errorCode: errorCodes[0]
    }
  },
  computed: {
    ...mapGetters({
      companiesEnabled: types.CONFIG_COMPANIES_ENABLED,
      companiesActive: types.CONFIG_COMPANIES_ACTIVE
    })
  },
  methods: {
    ...mapActions({
      activate: types.AUTH_ACTIVATE
    }),
    async submit (document) {
      this.isLoading = true
      const { error, success, ...data } = await this.activate({ document })

      if (error) {
        this.errorCode = errorCodes[error]
        this.errorCode.data = data
      } else if (this.companiesActive.length) {
        this.$router.push({ name: 'ativacao-verificacao' })
      } else if (this.companiesEnabled.length > 1) {
        this.$router.push({ name: 'ativacao-clubes' })
      } else if (this.companiesEnabled.length) {
        const company = this.companiesEnabled[0]['id']
        this.$router.push({ name: 'ativacao-company', params: { company } })
      }

      this.isLoading = false
    },
    clearError () {
      this.errorCode = errorCodes[0]
    }
  }
}
</script>
