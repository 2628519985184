<template>
  <div
    data-cy="account-creation-navbar"
    class="account-creation-navbar"
  >
    <c-logo
      data-cy="logo"
      class="logo"
      kaledo-width="115px"
      height="25px"
      kaledo
    />
    <call-to-login
      v-if="isDesktop && !hideLoginAction"
      data-cy="call-to-login"
    />
  </div>
</template>

<script>
import CLogo from '@/components/CComponents/CLogo'
import CallToLogin from '@/components/AccountCreation/CallToLogin.vue'

export default {
  name: 'AccountCreationNavbar',
  components: {
    CLogo,
    CallToLogin
  },
  props: {
    hideLoginAction: Boolean
  }
}
</script>

<style lang='scss' scoped>
@import '~@/styles/reference.scss';

.account-creation-navbar {
  max-width: 1200px;
  margin: 0 auto;
  width: 100%;
  height: 80px;
  padding: 10px 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  backdrop-filter: blur(19px);

  @include tablet {
    height: 70px;
    padding: 40px 24px;
    max-width: 640px;
  }
  @include mobile {
    padding: 40px 24px;
    height: 70px;
    max-width: 508px;
  }
  .logo {
    max-width: 115px;
    height: 25px;
  }
}
</style>
