<template>
  <section class="partners">
    <c-header
      :show-button="isExclusives"
      :nav-tabs="navTabs"
      :button="button"
      @click="handleAnalytics('botao-novo-parceiro', { company_name: company.name})"
    >
      <c-borded-info-button
        data-cy="show-onboarding-button"
        class="info-button"
        @btn-click="showOnboarding = true"
      />
    </c-header>
    <c-spinner
      v-if="isLoading"
      size="md"
    />

    <partner-list
      v-if="!isLoading"
      :partners="partners.list"
      :pagination="partners"
      :pagination-loading="loadingPagination"
      :is-exclusives="isExclusives"
      :search-term="filters.name"
      :partner-count="partnerCount"
      :filters="filters"
      :insearch="insearch"
      :lock-partner="partnerStatusConfirmation.slug"
      @open-filters="isFiltersOpened = true"
      @toggle-partner-status="toggleStatus"
      @search-partners="searchPartners"
      @update-filters="updateFilters"
      @paginate-list="paginateList"
      @clear-search="filters.name = ''"
      @apply-filters="load"
      @clear-filters="clearFilters"
    />

    <c-confirm-modal
      confirm-text="Inativar"
      character="embarrassed"
      title="Tem certeza que deseja inativar este parceiro?"
      message="Se inativá-lo, não será possível visualizar ofertas e mídias referente à ele no seu clube"
      :is-opened="partnerStatusConfirmation.show"
      @close="closeStatusConfirmation"
      @confirm="confirmStatus"
    />

    <filter-menu
      :filters="filters"
      :is-opened="isFiltersOpened"
      @apply-filters="load"
      @update-filters="updateFilters"
      @close="isFiltersOpened = false"
    />

    <onboarding
      title="Guia de Parceiros"
      :is-opened="showOnboarding"
      :show-modal="showOnboarding"
      :topics="topics"
      @close="showOnboarding = false"
    />
  </section>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Loadable from '@/modules/loadable'
import * as types from '@/store/types'
import debounce from 'debounce'

import CConfirmModal from '@/components/CComponents/CConfirmModal'
import CBordedInfoButton from '@/components/CComponents/CBordedInfoButton.vue'
import CHeader from '@/components/CComponents/CHeader.vue'
import Onboarding from '@/components/General/OnboardingModal'
import PartnerList from '@/components/Partners/Listing/PartnerList'
import FilterMenu from '@/components/Filter/FilterMenu'

const search = debounce((action, filters) => {
  action(filters)
}, 200)

export default {
  components: { CConfirmModal, CHeader, PartnerList, FilterMenu, Onboarding, CBordedInfoButton },
  mixins: [Loadable],
  props: {
    isExclusives: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      partnerStatusConfirmation: {
        slug: '',
        show: false,
        isExclusive: null
      },
      displayStatusWarning: false,
      loadingPagination: false,
      isFiltersOpened: false,
      insearch: false,
      showOnboarding: false,
      filters: {
        asc: null,
        desc: 'created_at',
        name: '',
        status: '',
        categories: [],
        exclusives: this.isExclusives
      },
      button: {
        name: 'Novo Parceiro',
        routeName: 'parceiros-criar'
      },
      navTabs: [
        {
          name: 'Exclusivos',
          value: 'parceiros'
        },
        {
          name: 'Geral',
          value: 'parceiros-geral'
        }
      ],
      topics: [
        {
          title: 'O que são Parceiros Gerais?',
          contents: ['Parceiros Gerais são as marcas com ofertas disponíveis no seu clube. Para visualizar as ofertas que cada marca possui, basta acessar a aba "Geral" e clicar na marca desejada.']
        },
        {
          title: 'Como retirar uma marca do catálogo?',
          contents: ['Para retirar as ofertas de uma marca do seu clube, acesse a aba Geral e clique na opção com o status "Ativo" de uma marca. Para reativar, realize o mesmo processo clicando no status "Inativo".']
        },
        {
          title: 'O que são Parceiros Exclusivos?',
          contents: ['São as marcas cadastradas por você com ofertas também criadas por você, chamadas de "ofertas exclusivas".']
        },
        {
          title: 'Como adicionar um Parceiro Exclusivo?',
          contents: ['Na aba "Exclusivos", clique no botão "Novo Parceiro" para adicionar uma nova marca. Para retirar, também na aba "Exclusivos", clique na marca desejada e logo após clique no status do parceiro escrito "Ativo". Caso queira excluir permamentemente.']
        },
        {
          title: 'Como retirar um Parceiro Exclusivo?',
          contents: ['Para retirar, também na aba "Exclusivos", clique na marca desejada e logo após clique no status do parceiro escrito "Ativo". Caso queira excluir permanentemente, clique no botão "Excluir Parceiro".']
        },
        {
          title: 'Como cadastrar ofertas exclusivas?',
          contents: ['Para cadastrar uma oferta exclusiva, acesse a aba "Exclusivos" e clique em um parceiro, logo após clique no botão "Nova Oferta" e siga os passos.']
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      partners: types.PARTNER_LIST_PAGINATION,
      partnerCount: types.PARTNER_COUNT,
      categories: types.CATEGORIES,
      company: types.COMPANY
    })
  },
  beforeDestroy () {
    this.partnerClear()
  },
  methods: {
    ...mapActions({
      togglePartnerStatus: types.PARTNER_TOGGLE_STATUS,
      getPartners: types.PARTNER_LIST_PAGINATION,
      filterPartners: types.PARTNER_LIST_FILTER,
      partnerClear: types.PARTNER_CLEAR,
      getPartnerCount: types.PARTNER_COUNT,
      createFeedback: types.FEEDBACK,
      getCategories: types.CATEGORIES
    }),
    updateFilters (property) {
      this.filters = { ...this.filters, ...property }
      if (!this.filters.asc && this.filters.desc !== 'name') this.filters.desc = 'created_at'
      // Changing the name property starts a search operation
      const propertyHasProperty = Object.prototype.hasOwnProperty.call(property, 'name')
      if (propertyHasProperty) {
        search(this.filterPartners, this.filters)
        this.insearch = true
      } else {
        this.insearch = false
      }
    },
    clearFilters (type) {
      if (type === 'order') {
        this.filters = { ...this.filters, asc: null, desc: 'created_at' }
      } else {
        this.filters = { ...this.filters, categories: [], name: '', status: '', exclusives: this.isExclusives }
      }
    },
    closeStatusConfirmation () {
      this.partnerStatusConfirmation = { show: false, slug: '', isExclusive: null }
    },
    async confirmStatus () {
      const { slug, isExclusive, name } = this.partnerStatusConfirmation
      await this.togglePartnerStatus({ slug, isExclusive })
      this.closeStatusConfirmation()
      const event = this.$route.name === 'parceiros-geral' ? 'bloquear-parceiro-geral' : 'toggle-desativar-parceiro'
      this.handleAnalytics(event, {
        company_name: this.company.name,
        partner_name: name,
        partner_slug: slug
      })
    },
    async toggleStatus ({ slug, name, status, isExclusive }) {
      if (status) {
        this.partnerStatusConfirmation = { slug, show: true, isExclusive, name }
      } else {
        this.partnerStatusConfirmation.slug = slug
        await this.togglePartnerStatus({ slug, isExclusive })
        this.closeStatusConfirmation()
        const event = this.$route.name === 'parceiros-geral' ? 'desbloquear-parceiro-geral' : 'toggle-ativar-parceiro'
        this.handleAnalytics(event, {
          company_name: this.company.name,
          partner_name: name,
          partner_slug: slug
        })
      }
    },
    async searchPartners () {
      if (this.filters.name.length > 0) {
        await this.filterPartners({ ...this.filters })
        this.insearch = true
      } else {
        this.insearch = false
      }
    },
    async paginateList () {
      const page = this.partners.current_page + 1
      this.loadingPagination = true

      await this.getPartners({ ...this.filters, page })
      this.loadingPagination = false
    },
    async load () {
      await Promise.all([
        this.filterPartners(this.filters, this.partners.id),
        this.getPartnerCount()
      ])
    },

    handleAnalytics (event, data) {
      window.dataLayer.push(
        {
          event,
          ...data
        }
      )
    }
  }
}
</script>
