<template>
  <c-spinner v-if="loading" />
  <div v-else class="container">
    <c-title
      class="title"
    >
      Atalhos
    </c-title>

    <div class="shortcut-container">
      <div
        class="shortcut-cards"
        v-for="card in shortcutCard"
        :key="card.title"
      >
        <h2>{{ card.title }}</h2>
        <h3>{{ card.content }}</h3>
        <span @click="$router.push({ path: card.path })">{{ card.title }}</span>
      </div>
    </div>
    <div class="cards">
      <div
        v-for="card in cards"
        :class="'card ' + card.type"
        :key="card.title"
      >
        <c-title
          :class="card.type !== 'card' ? 'title' : ''"
        >
          {{ card.title }}
        </c-title>
        <div class="card-container">
          <h3 v-if="card.type !== 'wallet'">
            {{ card.content }}
          </h3>
          <div
            class="wallet"
            v-else
          >
            <h2>Seu saldo</h2>
            <h3>{{ formatedBalance }}</h3>
          </div>
          <c-button
            primary
            :class="card.type !== 'card' ? 'button-wallet' : 'button'"
            @click="resolveLink(card)"
          >
            {{ card.buttonText }}
          </c-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { USER, COMPANY, COMPANY_BALANCE } from '@/store/types'
import { mapGetters, mapActions } from 'vuex'
import CSpinner from '@/components/CComponents/CSpinner.vue'
export default {
  components: { CSpinner },
  data () {
    return {
      loading: true,
      cards: [
        {
          title: 'Carteira',
          type: 'wallet',
          buttonText: 'acessar carteira',
          path: '/carteira',
          event: 'carteira-acessar'
        },
        {
          title: 'Apoio para comunicações',
          type: 'card',
          content: ' Ofertas em destaque e materiais de apoio para divulgação do seu clube',
          buttonText: 'acessar',
          path: 'https://sites.google.com/convenia.com.br/especial/p%C3%A1gina-inicial'
        }
      ],
      shortcutCard: [
        {
          title: 'Convide usuários',
          icon: '',
          content: 'Convide usuários para participarem do seu clube de vantagens',
          path: '/usuarios'
        },
        {
          title: 'Cadastre um parceiro',
          icon: '',
          content: 'Cadastre parceiros e ofertas exclusivas para a sua base.',
          path: '/parceiros'
        },
        {
          title: 'Personalize seu clube',
          icon: '',
          content: 'Insira seu logo e as cores da sua marca no seu clube.',
          path: '/personalizacao'
        }
      ]
    }
  },

  computed: {
    ...mapGetters({
      user: USER,
      company: COMPANY
    }),
    formatedBalance () {
      if (!this.company.balance) return 'R$ 0,00'
      return (this.company.balance/100).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
    }
  },
  async mounted () {
    await this.getBalance()
    this.loading = false
  },

  methods: {
    ...mapActions({
      getBalance: COMPANY_BALANCE
    }),
    resolveLink (card) {
      if (card.event) this.handleAnalytics(card.event)
      if (card.path.includes('google')) window.open(card.path, '_blank')
      else this.$router.push({ path: '/carteira' })
    },
    handleAnalytics(event) {
      window.dataLayer.push({
        event,
        company_name: this.company.slug
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/styles/reference';

.container {
  max-width: 1020px;
  margin-top: 35px;
  padding: 0 10px;

  @include desktop { @include container; }

  & > .shortcut-container {
    display: flex;
    justify-content: space-between;
    margin-top: 24px;
    margin-bottom: 48px;
    & > .shortcut-cards {
      display: flex;
      width: 324.667px;
      padding: 24px;
      flex-direction: column;
      align-items: flex-start;
      gap: 16px;
      border-radius: 8px;
      border: 1px solid var(--gray-400, #BEC0D3);
      background: var(--background-white, #FDFDFD);
      & > h2 {
        color: var(--brand-dark-blue, #202950);
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: Nunito Sans;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 24px;
      }
      & > h3 {
        color: var(--gray-600, #5E6684);
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: Nunito Sans;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
      }
      & > span {
        color: var(--accent-secondary-100, #4457AB);
        text-align: center;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: Nunito Sans;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 16.8px;
        text-decoration-line: underline;
        &:hover {
          cursor: pointer;
        }
      }
    }
  }
  & > .cards {
    display: flex;
    justify-content: space-between;
    & > .wallet {
      border: 2px solid var(--brand-dark-blue, #202950) !important;
      background: var(--brand-dark-blue, #202950) !important;
    }
    &> .card {
      display: flex;
      width: 494px;
      padding: 24px;
      flex-direction: column;
      align-items: flex-start;
      gap: 32px;
      border-radius: 8px;
      border: 1px solid var(--gray-400, #BEC0D3);
      background: var(--background-white, #FDFDFD);
      & > .title :deep .c-title .text {
        color: var(--background-white, #FDFDFD);
      }
      & > .card-container {
        display: flex;
        justify-content: space-between;
        width: 100%;
        align-items: center;

        & > .button-wallet  {
          background: var(--background-white, #FDFDFD) !important;
          height: 40px;
          border-radius: 32px;
          :deep .content .text {
            color: var(--gray-600, #5E6684) !important;
          }
        }
        & > .button  {
          height: 40px;
          border-radius: 32px;
        }
        & > h3 {
          color: var(--gray-600, #5E6684);
          font-feature-settings: 'clig' off, 'liga' off;
          font-family: Nunito Sans;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: 24px; /* 150% */
        }
        & > .wallet {
          & > h2 {
            color: var(--background-white, #FDFDFD);
            font-feature-settings: 'clig' off, 'liga' off;
            font-family: Nunito Sans;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 16px;
          }
          & > h3 {
            color: var(--background-white, #FDFDFD);
            font-feature-settings: 'clig' off, 'liga' off;
            font-family: Nunito Sans;
            font-size: 22px;
            font-style: normal;
            font-weight: 700;
            line-height: 24px;
          }
        }
      }
    }
  }
}
</style>
