<template>
  <c-modal
    v-bind="$attrs"
    v-on="$listeners"
    class="info-users-modal"
    name="info-users-modal"
  >
    <c-title
      secondary-icon
      icon="award-star"
    >
      Seleção de Usuários
    </c-title>
    <div class="modal-content">
      <h5 class="subtitle">
        O que é?
      </h5>
      <p>
        Os usuários precisam ter um registro na Kaledo para realizar o resgate do prêmio.
      </p>
      <h5 class="subtitle">
        Como funciona?
      </h5>
      <p>
        Você precisará do email e CPF de cada usuário e poderá escolher entre dos tipos de inserção: Via planilha ou Manual.
      </p>
      <p>
        Para inserir <b>manualmente</b>, basta selecionar a opção na tela e inserir o email e CPF de cada usuário nos campos.
      </p>
      <p>
        Para inserir via planilha, você encontra mais informações <button
          class="button-link"
          @click="$emit('close'), $emit('open-example-modal')"
        >
          clicando aqui
        </button>
      </p>
    </div>
  </c-modal>
</template>

<script>
import CModal from '@/components/CComponents/CModal'

export default {
  components: { CModal }
}
</script>

<style lang="scss" scoped>
@import '~@/styles/reference';

.info-users-modal {
  background-color: #FFF !important;
  z-index: 30 !important;

  > .content {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    padding-bottom: 20px;

    @include mobile { justify-content: center; }

    .c-title {
      margin-bottom: 30px;

      .icon {
        font-size: 20px;
        margin-right: 10px;
      }
    }

    .modal-content {
      display: flex;
      flex-direction: column;

      > .subtitle {
        font-weight: 400;
        font-size: 14px;
        color: #5E6684;
        text-transform: uppercase;
        margin-bottom: 8px;
      }

      > p {
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        color: #202950;
        .button-link {
          border: none;
          background: none;
          color:#202950;
          font-weight: bold;
          font-size: 16px;
          text-decoration: underline;
          cursor: pointer;
        }

        &:not(:last-child) {
          margin-bottom: 40px;
        }
      }
    }
  }
}
</style>
