<template>
  <section class="container">
    <wootric v-if="isLogged" />
    <!-- Google Tag Manager (noscript) -->
    <noscript>
      <iframe src="https://www.googletagmanager.com/ns.html?id=<%= process.env.GTM_ID %>"
      height="0" width="0" style="display:none;visibility:hidden">
      </iframe>
    </noscript>
  </section>
</template>

<script>
import Wootric from '~/components/Wootric.vue'
import { mapState, mapGetters } from 'vuex'
import { COMPANY, USER } from '@/store/types'

export default {
  components: { Wootric },

  computed: {
    ...mapState({
      expireDate: state => state.auth.expiresAt
    }),
    ...mapGetters({
      company: COMPANY,
      user: USER
    }),

    isLogged () {
      return this.expireDate > Date.now()
    }
  },

  created () {
    this.$router.push('atalhos')
  }

}
</script>
