<template>
  <div class="media-edit-container">
    <div class="header">
      <c-title
        class="title"
        icon="plus-circle"
        size="2"
      >
        Editar Mídia
      </c-title>
      <c-toggle
        sync
        :height="30"
        :width="90"
        :disabled="!canCreateBanners && (mediaData || {}).status === 0"
        :labels="{ checked: 'Ativo', unchecked: 'Inativo' }"
        :value="!!(mediaData || {}).status"
        @input="mediaData.status = $event"
      />
    </div>
    <c-spinner
      v-if="isLoading"
      size="lg"
    />
    <media-form
      v-else
      :media="mediaData"
      :status="mediaData.status"
    />
  </div>
</template>

<script>
import MediaForm from '@/containers/Communication/Media/Components/Form.vue'
import { MEDIA, CAN_CREATE_BANNERS } from '@/store/types'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'MediaEditContainer',
  components: {
    MediaForm
  },
  props: {
    media: Object
  },
  data () {
    return {
      isLoading: true,
      mediaData: {}
    }
  },
  computed: {
    ...mapGetters({
      canCreateBanners: CAN_CREATE_BANNERS
    })
  },
  mounted () {
    this.calculateActiveBanners()
    const bannerId = this.$route.params.id
    this.getBanner({
      banner_id: bannerId
    }).then(res => {
      this.mediaData = res.data.data
      this.isLoading = false
    })
  },
  methods: {
    ...mapActions({
      getBanner: MEDIA,
      calculateActiveBanners: CAN_CREATE_BANNERS
    })
  }
}
</script>

<style lang='scss' scoped>
@import '~@/styles/reference';

.media-edit-container {
   max-width: 1020px;
  margin: 30px auto;

  @include mobile {
    margin: 12px 10px;
  }

  > .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 835px;
  }

  > .form {
    margin: 30px auto;
    max-width: 80%;

    > .c-input > .c-input-container {
      display: flex;
    }
  }
}
</style>
