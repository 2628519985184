<template>
  <div class="bulkSelectContainer">
    <slot />
    <div
      v-if="bulkSelectionArray.length > 0"
      class="actionMenuContainer"
    >
      <div
        class="actionMenu"
        data-cy="c-bulkSelect-menu"
       >
        <c-button
          v-if="config.buttonSelectAll"
          class="buttonIconColor"
          :class="setConfirmIcon.class"
          :icon="setConfirmIcon.icon"
          @click="selectAllAvailableItens()"
          data-cy="c-bulkSelect-select-all-available"
        />
        <p class="text">
          <b> {{ checkIfAllDataIsSelected ? allDataCount : bulkSelectionArray.length }} </b>

          <span v-if="bulkSelectionArray.length > 1 || checkIfAllDataIsSelected">selecionados.</span>
          <span v-else>selecionado.</span>
        </p>
        <p
          v-if="unlockSelectAllDataButton"
          @click="selectAllData()"
          class="textPaddingRight"
          data-cy="c-bulkSelect-select-all-data"
        >
          <c-button
            size="sm"
            class="buttonFontColor"
          >
            Selecionar todos os {{ allDataCount }} {{ customAllDataText }}
          </c-button>
        </p>
        <div
          class="buttonsContainer"
          v-for="button in buttons"
          :key="button.key"
        >
          <c-button
            :style="button.customStyle"
            class="buttonIconColor"
            :icon-only="button.onlyIcon"
            :icon="button.icon"
            :noborder="true"
            size="md"
            :disabled="button.disabled"
            @click="$emit(button.event)"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    allDataCount: undefined,
    availableItens: undefined,
    customAllDataText: {
      type: String,
      default: function () {
        return 'itens'
      }
    },
    customProperty: {
      type: String,
      default: function () {
        return 'id'
      }
    },
    config: {
      type: Object,
      default: function () {
        return {
          buttonSelectAll: true,
          customButtons: []
        }
      }
    },
    bulkSelectionArray: {
      type: Array,
      default: function () {
        return []
      }
    }
  },
  computed: {
    buttons () {
      let buttons = this.config.customButtons.map(button => {
        return { key: this.config.customButtons.indexOf(button), ...button }
      })
      return buttons
    },
    unlockSelectAllDataButton () {
      if (this.bulkSelectionArray.length === this.availableItens.length) return true
      return false
    },
    checkIfAllDataIsSelected () {
      if (this.bulkSelectionArray.includes('allDataSelected')) return true
      return false
    },
    setConfirmIcon(){
      if(this.bulkSelectionArray.length > 0 || this.checkIfAllDataIsSelected) return {class: 'confirmIcon selected', icon: 'check'}
      return {class: 'confirmIcon selected', icon: ''}
    }
  },
  methods: {
    selectAllAvailableItens () {
      let newbulkSelectionArray = this.availableItens.map(item => {
        return item[this.customProperty]
      })
      this.$emit('selectAllAvailableItens', newbulkSelectionArray)
    },
    selectAllData () {
      if (this.bulkSelectionArray.includes('allDataSelected')) {
        this.$emit('unselectAllData')
      } else {
        this.$emit('selectAllData')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/styles/reference';
.bulkSelectContainer{
  display: contents;
}
.actionMenuContainer{
  display: flex;
  width: 100vw;
  justify-content: center;
}
.actionMenu{
  position: fixed;
  background-color: var(--color-primary);
  padding: 0.5rem 1rem;
  align-items: center;
  border-radius: 2rem;
  justify-content: center;
  display: flex;
  z-index: 9;
  top: 85vh;
  @media screen and (min-height: 730px) {
    top: 80vh;
  }
  @media screen and (min-height: 1000px) {
    top: 82vh;
  }
}
.confirmIcon{
  padding-left: 0px !important;
  padding-right: 0px !important;
  height: 1.25rem;
  width: 1.25rem;
  min-width: 0px !important;
  border-color: rgba(255, 255, 255, 0.9);

}
.text{
  padding: 0rem 0.8rem 0rem 0.5rem;
  color: rgba(255, 255, 255, 0.5);
}
.buttonsContainer{
  border-left: 1px solid rgba(255, 255, 255, 0.9);
  padding-left: 13px ;
  min-width: 2.5rem;
  display: flex;
  justify-content: center;
}
.textPaddingRight{
  padding-right: 0.5rem;
}
.selected{
    background-color: var(--color-secondary);
}
</style>
<style lang="scss">
.buttonIconColor .content .c-icon{
  fill: rgba(255, 255, 255, 0.9);
}
.buttonFontColor {
  border-color: rgba(255, 255, 255, 0.9);
 & .content .text  {
  color: rgba(255, 255, 255, 0.9);
}
}
</style>
