<template>
  <section class="campaign-unit-list">
    <div class="unit-list-header">
      <div class="header-info">
        <span class="info highlight">{{ activeAddresses.length }}</span>
        <span class="info">/ {{ pagination.total }}</span>
        unidades selecionadas
      </div>

      <c-checkbox
        :value="allSelected"
        round
        class="header-action"
        @input="$emit('select-all')"
      >
        Selecionar Tudo
      </c-checkbox>

      <search-field
        placeholder="Buscar unidades por cidade ou estado"
        class="header-action"
        :term="searchTerm"
        @reset="resetSearch()"
        @input="$emit('search', $event)"
        @submit="$emit('search', $event)"
      />
    </div>

    <c-card class="unit-list-cards">
      <div
        class="unit-list-card"
        v-for="address in addresses"
        :key="address.id"
      >
        <div class="card-actions">
          <c-checkbox
            round
            :value="activeAddresses.includes(address.id)"
            @input="$emit('toggle-address', address.id)"
          />
        </div>
        <div class="card-info">
          <span class="info lead">{{ address.street }}, {{ address.number }}</span>
          <span class="info">{{ address.city }} - {{ address.state }}</span>
        </div>
      </div>
    </c-card>

    <div
      class="unit-list-pagination"
      v-if="showPagination"
    >
      <c-button
        size="lg"
        class="btn-pagination"
        @click="$emit('paginate-list')"
        :disabled="isPaginating"
      >
        Ver Mais
      </c-button>
    </div>
  </section>
</template>

<script>
import SearchField from '@/components/CComponents/SearchField'
import CCheckbox from '@/components/CComponents/CCheckbox'
import CCard from '@/components/CComponents/CCard'

export default {
  components: { SearchField, CCheckbox, CCard },
  props: {
    addresses: {
      type: Array,
      required: true
    },
    pagination: {
      type: Object,
      required: true
    },
    activeAddresses: {
      type: Array,
      required: true
    },
    searchTerm: {
      type: String,
      default: ''
    },
    allSelected: {
      type: Boolean,
      required: true
    },
    isPaginating: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    showPagination () {
      return this.addresses.length < this.pagination.total
    }
  },
  methods: {
    resetSearch () {
      this.$emit('search', '')
    }
  }
}
</script>

<style lang="scss">
@import '~@/styles/reference';

$card-text-color: #989CAE;

.campaign-unit-list {
  display: flex;
  flex-direction: column;
  margin-top: 5px;

  & > .unit-list-header {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;

    margin-bottom: 15px;

    .header-info {
      font-size: 14px;
      color: $title-color;

      margin-right: auto;
      flex-shrink: 0;

      & > .info { color: $text-color; }

      & > .info.highlight {
        font-weight: 600;
        color: $primary-color-placeholder;
        color: var(--color-primary);

        margin-right: 4px;
      }
    }

    & > .header-action {
      flex-shrink: 0;

      &:not(:last-child) { margin-right: 10px; }
      &.search-field { width: 100%; max-width: 305px; }
      &.search-field > .field { margin-bottom: 0; }
      &.search-field > .field > .c-input { margin-bottom: 0; }
    }

    @media screen and (max-width: 729px) {
      flex-wrap: wrap;
      align-items: center;

      & > .header-action { margin-right: 0 !important; }
      & > .search-field {
        max-width: initial !important;
        margin: 15px 0;
      }
    }
  }

  & > .unit-list-cards {
    padding: 0 20px;
    &:last-child { margin-bottom: 30px; }

    & > .unit-list-card {
      display: flex;
      padding: 20px 0;

      &:not(:last-child) { border-bottom: 1px solid $border-color; }

      & > .card-actions {
        display: flex;
        align-items: center;
        margin-right: 15px;
      }

      & > .card-info {
        display: flex;
        flex-grow: 1;
        flex-direction: column;

        & > .info {
          font-size: 14px;
          font-weight: 200;
          color: $card-text-color;

          &.lead {
            font-weight: 600;
            color: $primary-color-placeholder;
            color: var(--color-primary);
          }
        }
      }
    }
  }

  & > .unit-list-pagination {
    display: flex;
    justify-content: center;

    margin: 30px 0 35px 0;

    & > .btn-pagination {
      width: 100%;
      max-width: 190px;
    }
  }
}
</style>
