import { render, staticRenderFns } from "./nova-senha.vue?vue&type=template&id=2717c7db"
import script from "./nova-senha.vue?vue&type=script&lang=js"
export * from "./nova-senha.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports