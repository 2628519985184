<template>
  <c-modal
    v-bind="$attrs"
    v-on="$listeners"
    name="invoice-email-modal"
    class="invoice-email-modal"
    hide-cancel
  >
    <h3 class="title">
      Boleto bancário
    </h3>
    <div class="content">
      <div
        class="send-option"
      >
        <input
          class="campaign-checkbox"
          type="checkbox"
          checked="checked"
          v-model="sendUserEmail"
        />
        <span>Enviar para o meu email (fulanodetal@gmail.com)</span>
      </div>
      <div
        class="send-option"
      >
        <input
          class="campaign-checkbox"
          type="checkbox"
          checked="checked"
          v-model="sendOtherEmail"
        />
        <span>Enviar para outro email</span>
      </div>
      <c-input
        :disabled="!sendOtherEmail"
        v-model="email"
        class="email-input"
        placeholder="Insira um email"
      />
    </div>
    <div class="actions">
      <c-button
        :disabled="disableButton"
        class="send-button"
        :primary="true"
        size="lg"
      >
        Enviar boleto
      </c-button>
    </div>
  </c-modal>
</template>

<script>
import CModal from '@/components/CComponents/CModal'

export default {
  components: { CModal },
  data () {
    return {
      email: '',
      sendUserEmail: false,
      sendOtherEmail: false
    }
  },
  computed: {
    disableButton () {
      const isEmailValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(this.email.toLowerCase())
      return (!this.sendUserEmail && !this.sendOtherEmail) || !isEmailValid
    }
  }
}
</script>

<style lang="scss">
@import '~@/styles/reference';

.invoice-email-modal {
  > .c-modal > .content {
    display: flex;
    align-items: center;
    flex-direction: column;

    > .title {
      font-size: 16px;
      margin-top: 30px;
      margin-bottom: 20px;
      color: #202950;
    }
    > .content {
      .send-option {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        font-size: 14px;
        color: #5E6684;
        margin: 16px 0;

        .campaign-checkbox {
          height: 20px;
          width: 20px;
          margin-right: 8px;
          background-color: #f3f3f3;
          border-radius: 2px;
          outline: 1px solid white;
          cursor: pointer;
          transition: 0.3s all ease;

          &:hover {
            background: #d3d3d3;
          }
        }
      }
      .email-input {
        width: 100%;
        border: 1px solid #5E6684;
        border-radius: 4px;
        margin-bottom: 24px;

        & .input:focus, .input:hover {
          border: 1px solid #5E6684;
        }
      }
    }
    > .actions {
      width: 100%;
      display: flex;
      justify-content: center;

      .send-button {
        background: #121E48;
        background: var(--color-primary);
        transition: 0.1s all ease;

        &:hover {
          opacity: 0.8;
        }
      }
    }
  }
}
</style>
