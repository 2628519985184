<template>
  <donut-card
    :loading="loading"
    :graph-data="usersPerGenderData"
    :error="error"
  />
</template>

<script>
import DonutCard from '@/components/Dashboard/base/GraphCard.vue'
import { mapGetters, mapActions } from 'vuex'
import { DASH_USERS_PER_GENDER } from '@/store/types'

export default {
  name: 'UsersPerGender',
  components: {
    DonutCard
  },
  props: {
    reloadDataQuery: Boolean
  },
  data () {
    return {
      loading: true,
      error: false
    }
  },
  computed: {
    ...mapGetters({
      usersPerGenderData: DASH_USERS_PER_GENDER
    })
  },
  watch: {
    reloadDataQuery () {
      if (this.reloadDataQuery) { this.getUsersPerGender() }
    }
  },
  mounted () {
    this.getUsersPerGender()
  },
  methods: {
    ...mapActions({
      getUsersPerGenderData: DASH_USERS_PER_GENDER
    }),
    getUsersPerGender () {
      this.loading = true
      this.getUsersPerGenderData().then((res) => {
        this.error = !res
        this.loading = false
      })
    }
  }
}
</script>
