<template>
  <c-card
    data-cy="catalog-card"
    :class="['catalog-card', { '-inactive': !localStatus }]"
  >
    <div class="top-section">
      <c-image
        class="catalog-logo"
        :src="catalog.logo || imagePlaceholder"
        :alt="catalog.title"
        :placeholder="imagePlaceholder"
        is-background
      />
    </div>

    <div class="bottom-section">
      <div class="description">
        <p class="catalog-title">
          {{ catalog.title }}
        </p>
        <p class="catalog-description">
          {{ description }}
        </p>
      </div>
      <div class="catalog-actions">
        <c-toggle
          :height="30"
          :width="100"
          :labels="{ checked: 'Ativo', unchecked: 'Inativo' }"
          :value="localStatus"
          data-cy="catalog-toggle"
          @input="toggleStatus"
        />
      </div>
    </div>
  </c-card>
</template>

<script>
import CCard from '@/components/CComponents/CCard'
import CImage from '@/components/CComponents/CImage'

export default {
  components: { CCard, CImage },
  props: {
    catalog: Object
  },
  data () {
    return {
      localStatus: this.catalog.is_blocked !== 0,
      imagePlaceholder: require('@/assets/img/campaign-placeholder.svg')
    }
  },
  computed: {
    description () {
      if (!this.catalog.description) return ''
      const maxSize = 46
      if (this.catalog.description.length < maxSize) return this.catalog.description
      return `${this.catalog.description.substr(0, maxSize)}...`
    }
  },
  mounted () {
    this.localStatus = this.catalog.is_blocked !== 0
  },
  methods: {
    toggleStatus (ev) {
      this.localStatus = !this.localStatus
      window.dataLayer.push({
        event: 'pontos-personalizacao-catalogo-toggle'
      })
      this.$emit('toggle-catalog-status', {
        slug: this.catalog.slug,
        name: this.catalog.name,
        isExclusive: this.catalog.is_exclusive !== 0,
        is_blocked: this.localStatus ? 1 : 0
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/styles/reference';

.catalog-card {
  position: relative;
  cursor: pointer;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  @include desktop {
    width: 155px;
    max-height: 268px;
  }
  @include mobile {
    width: 100%;
  }
  @include tablet {
    width: 100%;
  }

  &.-inactive { background-color: #FBFCFE; }

  .top-section {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;

    .catalog-logo {
      flex: 50%;
      max-width: 100px;
      max-height: 90px;
      margin: 16px 28px;
      background-size: contain;
      background-clip: border-box;
      background-repeat: no-repeat;
      background-position: center;
    }
  }

  .bottom-section {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    flex-grow: 0;
    margin: auto 10px 0 10px;

    .description {
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: center;
      padding-bottom: 10px;
      border-bottom: 1px solid #F3F3F4;
      flex-grow: 1;
      width: 100%;

      .catalog-title {
        color: $title-color;
        font-weight: bold;
        font-size: 14px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: calc(100% - 40px);
      }
      .catalog-description {
        font-size: 14px;
        max-height: 60px;
        overflow: hidden;
      }
    }
    .catalog-actions {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-shrink: 0;
      height: 50px;
    }
  }
}
</style>
