<template>
  <coin-distribution/>
</template>

<script>
import CoinDistribution from '@/containers/Engagement/pages/CoinDistribution.vue'
export default {
  name: 'index',
  components: {
    CoinDistribution
  }
}
</script>

<style lang='scss' scoped>
</style>
