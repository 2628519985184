<template>
  <partner-list is-exclusives />
</template>

<script>
import PartnerList from '@/containers/Partners/PartnerList'

export default {
  components: { PartnerList }
}
</script>
