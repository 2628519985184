<template>
  <div class="step-three">
    <div class="step-three-title">
      <c-title
        data-cy="step-title"
        class="title"
        size="3"
      >
        Comunicação
      </c-title>
    </div>
    <h3 class="subtitle">
      Personalização
    </h3>
    <instruction
      text="Escreva um título e uma mensagem personalizada que os usuários selecionados irão receber quando as moedas forem entregues."
    />
    <div class="input">
      <c-input
        data-cy="order-title-input"
        :value="stepValue.communication_title"
        name="title"
        label="Título"
        required
        maxlength="50"
        v-validate="'required'"
        placeholder="Ex: Você ganhou moedas!"
        @input="update('communication_title', $event )"
      />
      <span class="subtext">Máx. 38 caracteres</span>
    </div>
    <div class="input">
      <c-input
        data-cy="order-message-input"
        :value="stepValue.communication_description"
        class="message-input"
        name="message"
        label="Mensagem"
        required
        textarea
        rows="5"
        maxlength="118"
        v-validate="'required'"
        placeholder="Ex: Parabéns! Você ganhou 5 moedas para trocar por recompensas da sua preferência."
        @input="update('communication_description', $event )"
      />
      <span class="subtext">Máx. 118 caracteres</span>
    </div>
    <h3 class="subtitle">
      Pré-visualização
    </h3>
    <order-communication-preview-card
      data-cy="communication-preview-card"
      :title="stepValue.communication_title"
      :message="stepValue.communication_description"
    />
  </div>
</template>

<script>
import { KIcon } from 'kaledo-components'
import CInfoButton from '@/components/CComponents/CInfoButton'
import Instruction from '@/components/General/Instruction.vue'
import OrderCommunicationPreviewCard from '@/components/General/OrderCommunicationPreviewCard.vue'

export default {
  components: {
    KIcon,
    CInfoButton,
    Instruction,
    OrderCommunicationPreviewCard
  },
  props: {
    stepValue: Object
  },
  data () {
    return {
      title: '',
      message: ''
    }
  },
  methods: {
    update (key, value) {
      this.$emit('input', { ...this.stepValue, [key]: value })
    },
    handleShowMore (campaign) {
      this.campaignInfo = campaign
      this.showModal = true
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/styles/reference';

.step-three {
  max-width: 1020px;
  margin-top: 40px;
  padding: 0;

  .step-three-title {
    display: flex;
    height: 35px;
    margin-bottom: 20px;

    .title {
      max-width: 240px;
      margin-bottom: 30px;
    }
  }

  .subtitle {
    font-size: 16px;
    color: #565F7D;
    margin-bottom: 8px;

    &:last-of-type {
      margin-top: 40px;
    }
  }

  .input {
    width: 100%;
    max-width: 423px;
    margin-top: 24px;

    :deep .required {
      display: none;
    }

    .message-input {
      :deep textarea {
        resize: none;
      }
    }
    .subtext {
      margin-bottom: 8px;
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      display: flex;
      align-items: center;
      color: #5E6684;
      padding-top: 6px;
    }
  }
}
</style>
