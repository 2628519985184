<template>
  <c-card :class="['card-user', { '-inactive': status === false }]">
    <div class="left-side">
      <div class="profile">
        <c-image
          class="user-image"
          :src="user.avatar"
          :placeholder="imagePlaceholder"
        />
        <c-icon
          v-if="isManager"
          class="medal"
          size="30"
          icon="medal-empty"
        />
      </div>

    </div>

    <div class="right-side">
      <div
        class="status"
        :style="'border: 1px solid ' + formatUserStatus(user.status).color"
      >
        <div
        class="dot"
        :style="'background-color: ' + formatUserStatus(user.status).color"
        />{{formatUserStatus(user.status).label}}
      </div>
      <div class="text">
        <p
          class="name"
          v-if="user.name"
        >
          {{ user.name }}
        </p>
        <p
          class="email"
          v-if="user.email"
        >
          {{ user.email }}
        </p>
        <p class="cpf">
          {{ user.document }}
        </p>
      </div>
      <c-button
        class="action"
        useKIcon
        :icon="setActionButtonIcon(user.status)"
        size="md"
        :popoverLabel="user.status === 2 ? 'Convidar' : 'Suspender'"
        @click.stop="$emit('confirmation', { id: user.id, status: user.status === 2 ? 0 : 2 })"
      />
      <c-button
        class="delete"
        icon="trash"
        size="md"
        popoverLabel="Excluir"
        @click.stop="$emit('confirmation', { id: user.id, roles: user.roles, isDelete: true })"
      />
    </div>
  </c-card>
</template>

<script>
import CCard from '@/components/CComponents/CCard'
import CImage from '@/components/CComponents/CImage'

export default {
  components: { CCard, CImage },
  props: {
    user: {
      type: Object,
      required: true
    },

    enable: {
      type: Boolean
    }
  },
  data () {
    return {
      imagePlaceholder: require('@/assets/img/userPlaceholder.svg')
    }
  },
  computed: {
    isManager () {
      return this.user.roles ? this.user.roles.includes('panel_manager') : false
    },

    status () {
      if (this.user.status === 2) return false

      if (this.user.status === 1) return true
    }
  },
  methods: {
    formatUserStatus (status) {
      const statusObj = {
        0 : {label: 'convidado', color:'#F5A623'},
        1 : {label: 'ativo', color: '#4CAD50'},
        2 : {label: 'suspenso', color: '#D8D8D8'}
      }
      return statusObj[status]
    },
    setActionButtonIcon (status) {
      return status === 2 ? 'plus-add3' : 'block-delete-stop'
    }
  }
}
</script>

<style lang="scss">
@import '~@/styles/reference';

.card-user {
  display: flex;
  padding: 10px 10px;
  height: 135px;
  width: 330px;
  margin: 0 5px 10px 5px;

  &.-inactive { background-color: #FBFCFE; }


  & >.left-side {
    display: flex;
    flex-direction: column;
    padding: 10px 5px 0 5px;

    & >.profile {
      position: relative;

      & >.user-image {
        width: 70px;
        height: 70px;
        border-radius: 50%;
      }
      & >.medal {
        position: absolute;
        padding: 3px;
        background-color: $primary-color-placeholder;
        background-color: var(--color-primary);
        border: 2px solid #fff;
        border-radius: 15px;
        fill: $secondary-color-placeholder;
        fill: var(--color-secondary) !important;
        transform: translate(-80%, 115%);
      }
    }

    & >.enable-user {
      font-size: 11px;
      height: 25px;
      border-radius: 3px;
      text-align: center;
      color: $primary-color-placeholder;
      color: var(--color-primary);
      padding: 5px 10px;
      background-color: rgba(18,30,72,0.1);
      text-transform: uppercase;
      font-weight: 600;
    }
  }

  & >.right-side {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    margin-left: 20px;
    padding-top: 10px;

    & > .status {
        box-sizing: border-box;
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 4px 8px;
        gap: 4px;
        min-width: 62px;
        height: 25px;
        border-radius: 2px;
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        text-align: center;
        color: #5E6684;
        text-transform: capitalize;
        & > .dot {
          height: 8px;
          width: 8px;
          border-radius: 50%;
          display: inline-block;
        }
      }

    & >.text {
      max-width: 100%;
      & >.name {
        font-size: 14px;
        font-weight: bold;
        color: rgba(#121e48, 0.8);
        padding: 6px 0 3px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      & >.email {
        font-size: 12px;
        padding: 3px 0;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      & >.cpf {
        font-size: 12px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        &:first-child { margin-top: 20px; }
      }
    }
    & > .action {
      position: absolute;
      bottom: 0;
      right: 40px;
      color: #5E6684;
    }
    & >.delete {
      position: absolute;
      bottom: 0;
      right: 0px;
    }
  }
}
</style>
