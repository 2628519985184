<template>
  <div
    class="login-left-side"
  >
    <img
      src="/img/kaledo-pattern.png"
      alt="background"
      style="display: none;"
    />
    <c-title
      white
      class="title"
    >
      Painel do Gestor
    </c-title>
    <p class="text">
      Gerencie e fique por dentro de tudo que acontece no seu clube.
    </p>
    <c-logo
      class="logo"
      kaledo-k
      is-white
      width="36px"
    />
  </div>
</template>

<script>
import CLogo from '@/components/CComponents/CLogo'

export default {
  components: { CLogo }
}
</script>

<style lang="scss">
  @import '~@/styles/reference';

  .login-left-side {
    background: #121E48;
    background-image: url('/img/kaledo-pattern.png');
    height: 500px;
    min-width: 275px;
    padding: 50px;
    position: relative;

    @include desktop {
      width: 351px;
      height: 500px;
    }

    @include mobile {
      background-size: 140%;
      max-width: 768px;
      max-height: 172px;
      background-position: left 30%;
      padding: 30px 26px 40px;
    }

    & > .title {
      margin-bottom: 25px;
      span{
        color: #fff;
        font-size: 28px;
        font-weight: 500;
        @include mobile { font-size: 20px; }
      }
    }
    & > .text {
      color: #fff;
      font-size: 16px;
      font-family: $base-font-family;
      font-weight: 100;
    }
    & > .logo {
      display: none;

      @include desktop {
        display: block;
        position: absolute;
        left: 51px;
        bottom: 28px;
      }
    }
  }
</style>
