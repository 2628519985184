<template>
  <div class="header-container">
    <div class="header">
      <k-icon
        icon="left1"
        size="20"
        class="icon"
        @click.native="handleReturn"
      />
    </div>
    <c-confirm-modal
      :btn-props="{ primary: true }"
      confirm-text="Sair sem salvar"
      character="embarrassed"
      title="Tem certeza que deseja sair sem salvar?"
      message="Se sair, todas as informações já inseridas não serão salvas."
      :is-opened="openConfirmationModal"
      @close="openConfirmationModal = false"
      @confirm="$emit('return'); openConfirmationModal = false"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { COMPANY } from '@/store/types'
import CConfirmModal from '@/components/CComponents/CConfirmModal'

import { KIcon } from 'kaledo-components'
export default {
  components: {
    KIcon,
    CConfirmModal
  },
  data () {
    return {
      openConfirmationModal: false
    }
  },
  computed: {
    ...mapGetters({
      company: COMPANY
    }),
    routesByPlan () {
      return {
        trial: [
          '/personalizacao/ambientes-internos',
          '/personalizacao/pagina-de-acesso'
        ],
        basic: [
          '/configuracao/bloqueios',
          '/personalizacao/ambientes-internos',
          '/personalizacao/pagina-de-acesso'
        ],
        intermediary: [
          '/configuracao/acesso',
          '/configuracao/bloqueios',
          '/personalizacao/ambientes-internos',
          '/personalizacao/pagina-de-acesso'
        ],
        advanced: [
          '/configuracao/acesso',
          '/configuracao/bloqueios',
          '/configuracao/logout',
          '/personalizacao/ambientes-internos',
          '/personalizacao/pagina-de-acesso'
        ]
      }
    }
  },
  methods: {
    handleReturn () {
      const plan = this.company.plan_type
      const routesByPlan = this.routesByPlan[plan]
      if (routesByPlan.includes(this.$route.path)) {
        this.openConfirmationModal = true
        return
      }
      this.$emit('return')
    }
  }
}
</script>

<style lang='scss' scoped>
.header-container {
  box-shadow: 0px 4px 1px -1px rgba(213, 214, 226, 0.4);
  background-color: #FFF;
  height: 59px;
  align-items: center;
  display: flex;
  & > .header {
    max-width: 1020px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    justify-content: flex-start;

    & > .icon {
      border-radius: 99px;
      border: 1px solid var(--primrias-e-secundrias-secondary-ii, #8C92B1);
      background: var(--bsicas-base-light, #FDFDFD);
      cursor: pointer;
      padding: 10px;
      &:hover {
        border: 1px solid rgba(94, 102, 132, 1);
      }
    }
  }
}
</style>
