<template>
  <c-card :class="classes">
    <campaign-tag
      class="tag"
      v-if="isExclusive"
      :is-exclusive="true"
    />
    <campaign-tag
      v-if="isHighlighted"
      class="tag -highlight"
      :is-highlight="isHighlighted"
      rotate
    />

    <div class="content">
      <div class="section-left">
        <campaign-stamp
          :symbol="symbol"
          :value="value"
          v-bind="$attrs"
        />
      </div>

      <div class="section-right">
        <div class="description">
          <h4 class="partner-name">
            {{ partner }}
          </h4>
          <p class="title">
            {{ title }}
          </p>
        </div>

        <p
          class="expiry"
          v-if="expirationDate"
        >
          <c-icon
            size="14"
            icon="clock"
          />
          {{ expirationDate }}
        </p>

        <div class="card-controls">
          <c-toggle
            sync
            await
            v-if="isExclusive"
            :height="30"
            :width="isFuture ? 112 : 100"
            :is-future="isFuture"
            :labels="{
              checked: isFuture ? 'Agendado' : 'Ativo',
              unchecked: 'Inativo'
            }"
            :value="!!status"
            @input="toggleStatus"
          />
          <c-button
            v-if="isDesktop"
            class="id-control"
            @click.stop="notify"
            no-padding
            data-cy="c-button-id"
          >
            ID
          </c-button>
        </div>
      </div>
    </div>
  </c-card>
</template>

<script>
import CCard from '@/components/CComponents/CCard'

import CampaignTag from '@/components/Campaign/Listing/CampaignTag'
import CampaignStamp from '@/components/Campaign/Listing/CampaignStamp'

import { getDiff, DAY } from '@/modules/time'
import { copyText } from '@/modules/copyText'

export default {
  components: { CampaignStamp, CampaignTag, CCard },
  props: {
    square: Boolean,
    id: String,
    image: String,
    title: String,
    partner: String,
    value: Number,
    symbol: String,
    status: Number,
    isHighlighted: Boolean,
    expiryDate: [ String, Number ],
    isFuture: Boolean,
    isExclusive: Boolean,
    slug: String
  },
  data () {
    return {
      expiry: null,
      localStatus: this.status || false
    }
  },
  computed: {
    classes () {
      const classes = [
        'campaign-card', {
          '-square': !!this.square,
          '-exclusive': this.isExclusive,
          '-inactive': !this.status
        }]
      return classes
    },
    expirationDate () {
      if (!this.expiryDate || !this.expiry) return undefined

      if (this.expiry <= 3 && this.expiry >= 0) {
        return `Expira em ${this.expiry} dias`
      } else {
        const date = new Date(this.expiryDate * 1000)
          .toLocaleDateString('pt-BR', { year: '2-digit', month: '2-digit', day: '2-digit', timeZone: 'America/Sao_Paulo' })

        return `${this.isDesktop ? 'Válido a' : 'A'}té ${date}`
      }
    }
  },
  mounted () {
    this.getExpiry()
    this.localStatus = this.status
  },
  methods: {
    getExpiry () {
      if (!this.expiryDate) return
      const days = getDiff(this.expiryDate * 1000, new Date(), DAY)
      this.expiry = days
    },
    notify (ev) {
      const feedback = {
        title: 'Sucesso',
        text: 'O ID da oferta foi copiado',
        type: 'success'
      }

      copyText(this.id)
      this.$emit('copy', feedback)
    },
    toggleStatus () {
      this.$emit('toggle-campaign-status', {
        slug: this.slug,
        title: this.partner,
        status: this.status
          ? 0
          : this.isFuture
            ? 2 : 1
      })
    }
  }
}
</script>

<style lang="scss">
@import '~@/styles/reference';

$partner-name-color: rgba(#121E48, 0.5);
$campaign-title-color: rgba(#121E48, 0.8);

.campaign-card {
  position: relative;
  display: flex;
  justify-content: flex-start;
  cursor: pointer;
  height: 170px;

  overflow: hidden;

  &.-inactive { background-color: #FBFCFE; }

  & > .tag {
    position: absolute;
    top: 0;
    left: 75px;
    transform: translateX(-50%);

    &.-highlight {
      bottom: 0px;
      left: 35px;
      top: initial;
    }
  }

  & > .tag-highlight {
    position: absolute;
    background-color: #F5A623;
    color: white;
    bottom: 0px;

    border-top-right-radius: 20px;
    border-bottom-left-radius: 3px;

    width: 70px;
    padding: 2px 5px;
    padding-top: 3px;

    font-size: 9.5px;
    text-transform: uppercase;
  }

  & > .content {
    display: flex;
    width: 100%;
  }

  .section-left {
    display: flex;
    align-items: center;
    padding: 0 30px 0 30px;
  }

  .section-right {
    display: flex;
    flex-direction: column;
    justify-content: center;

    padding: 25px 10px 10px 4px;
    flex-grow: 1;

    max-width: calc(100% - 160px);

    .description {
      margin-top: auto;
      margin-bottom: auto;

      .partner-name {
        overflow: hidden;
        white-space: nowrap;
        max-width: calc(100%);
        text-overflow: ellipsis ;

        font-size: 14px;
        color: $partner-name-color;
        font-weight: 400;

        margin-bottom: 3px;
      }

      .title {
        display: -webkit-box;
        overflow: hidden;

        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;

        color: $campaign-title-color;
      }
    }

    .expiry {
      display: flex;
      align-items: center;
      font-size: 12px;

      line-height: 1.5;
      margin: auto 0 0 0;

      .c-icon { margin-right: 5px; }
    }

    .card-controls {
      display: flex;
      justify-content: flex-end;
      margin-top: 10px;

      & > .id-control { margin-left: 10px; }
    }
  }

  &.-exclusive > .content > .section-left { margin-top: 19px; }
}
</style>
