<template lang="html">
  <div class="campaign-header">
    <div class="content">
      <section
        class="header-actions"
        v-if="isExclusive"
      >
        <c-toggle
          sync
          await
          :disabled="isLoading"
          :height="30"
          :width="!!campaign.status ? 112 : 100"
          :is-future="isFuture"
          :labels="{
            checked: isFuture ? 'Agendada' : 'Ativa',
            unchecked: 'Inativa'
          }"
          :value="!!campaign.status"
          @input="$emit('toggle-campaign-status', { slug: campaign.slug })"
        />

        <div class="wrapper-campaign-toggle">
          <c-button
            default
            size="lg"
            class="btn"
            icon="trash"
            :icon-only="!isDesktop"
            @click="$emit('delete-campaign')"
          >
            {{ isDesktop ? 'Excluir oferta' : null }}
          </c-button>
        </div>
      </section>

      <section class="partner">
        <c-image
          data-cy="partner-logo"
          class="partner-logo"
          :src="partner.logo || imagePlaceholder"
          :alt="partner.name"
        />
        <h3 data-cy="partner-name" class="name">
          {{ partner.name }}
        </h3>
        <p data-cy="partner-category" class="category">
          {{ categoryName }}
        </p>
      </section>

      <c-toggler
        class="toggler"
        :tabs="navTabs"
        :active-tab="activeTab"
        @change-tab="$emit('change-active-tab', $event)"
      />
    </div>
  </div>
</template>

<script>
import CToggler from '@/components/CComponents/CToggler'
import CImage from '@/components/CComponents/CImage'

export default {
  components: {
    CToggler,
    CImage
  },
  props: {
    partner: {
      type: Object,
      required: true
    },
    campaign: {
      type: Object,
      required: true
    },
    isExclusive: {
      type: Boolean,
      required: true
    },
    navTabs: {
      type: Array,
      required: true
    },
    activeTab: {
      type: String,
      required: true
    },
    isFuture: Boolean,
    isLoading: Boolean
  },
  data () {
    return {
      localStatus: !!(this.campaign || {}).status || false,
      displayDeleteWarning: false,
      imagePlaceholder: require('@/assets/img/campaign-placeholder.svg')
    }
  },
  computed: {
    categoryName () {
      const { categories } = this.partner || {}
      return categories && categories.length
        ? categories[0].name
        : ''
    }
  }
}
</script>

<style lang="scss">
@import '~@/styles/reference';

$partner-logo-width: 160px;
$partner-logo-height: 100px;

.campaign-header {
  background-color: white !important;
  background-image: url('~/assets/img/pattern-oferta.svg');
  background-repeat: repeat-x;

  & > .content {
    display: flex;
    align-items: center;
    flex-direction: column;
    @include desktop { @include container };
  }

  & > .content > .header-actions {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: flex-end;

    width: 100%;
    height: 66px;
    padding: 10px;
    padding-bottom: 16px;

    & > *:not(:first-child) { margin-left: 10px; }
  }

  & > .content > .partner {
    &:first-child { margin-top: 65px; }
    text-align: center;
    padding: 0 10px;

    & > .partner-logo {
      max-width: $partner-logo-width;
      max-height: $partner-logo-height;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      margin: 20px 0;
    }

    & > .name {
      color: $primary-color-placeholder;
      color: var(--color-primary);
    }

    & > .category {
      font-size: 14px;
      line-height: 19px;
      color: $text-color;
      margin-top: 4px;
    }
  }

  & > .content > .toggler {
    width: 100%;
    max-width: 560px;
  }
}
</style>
