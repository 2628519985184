<template>
  <div class="container">
    <login-form
      @talk-with-sales="talkWithSales"
      @clear-error="clearError"
      @submit="submit"
      :error="errorCode"
      :is-loading="isLoading"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import * as types from '@/store/types'
import LoginForm from '@/components/Login/LoginForm'

const errorCodes =
{
  0: { type: '', data: {} },
  1: { type: 'invalidCredentials', data: {} },
  2: { type: 'invalidCredentials', data: {} },
  4: { type: 'notActive', data: {} },
  6: { type: 'notEnabled', data: {} },
  7: { type: 'finishedTrial', data: {} }
}

export default {
  components: { LoginForm },
  data () {
    return {
      errorCode: errorCodes[0],
      isLoading: false
    }
  },
  computed: {
    ...mapGetters({
      companies: types.CONFIG_COMPANY_LIST
    })
  },
  methods: {
    ...mapActions({
      login: types.AUTH_LOGIN
    }),
    async submit (userData) {
      this.isLoading = true
      const { error: data } = await this.login(userData)
      const error = (data && data.error) || data
      this.isLoading = false

      if (error) {
        this.errorCode = errorCodes[error]
      } else if (this.companies.length) {
        this.$router.push({ name: 'selecao-de-conta' })
      } else {
        this.$router.push({ name: 'index' })
      }
    },
    clearError () {
      this.errorCode = errorCodes[0]
    },
    talkWithSales (isButtonAction) {
      if (isButtonAction) {
        const landingPageHostname = process.env.NODE_ENV === 'production'
          ? 'https://kaledo.com.br/'
          : 'https://qa.kaledo.com.br/'

        const kaledoSalesLink = `${landingPageHostname}?sales_modal=true`

        window.open(kaledoSalesLink, '_blank')
      } else {
        this.clearError()
      }
    }
  }
}
</script>
