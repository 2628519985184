<template>
  <select-club-confirmation />
</template>

<script>
import SelectClubConfirmation from '@/containers/Login/SelectClubConfirmation'

export default {
  components: { SelectClubConfirmation },
  layout: 'unauthenticated',
  middleware: 'checkauth'
}
</script>
