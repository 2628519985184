<template>
  <c-card class="partner-info-card">
    <div class="content">
      <div class="info-section">
        <div class="section-title">
          <p class="title">
            Sobre o parceiro
          </p>
        </div>
        <div class="section-content">
          <p class="content">
            {{ description }}
          </p>
        </div>
      </div>
      <div class="info-section">
        <div class="section-title">
          <p class="title">
            Telefone
          </p>
        </div>
        <div class="section-content">
          <p class="content">
            {{ phone ? phone : '–' }}
          </p>
        </div>
      </div>
      <div class="info-section">
        <div class="section-title">
          <p class="title">
            Responsável
          </p>
        </div>
        <div class="section-content">
          <p class="content">
            {{ owner ? owner : '–' }}
          </p>
        </div>
      </div>
      <div class="info-section">
        <div class="section-title">
          <p class="title">
            E-mail
          </p>
        </div>
        <div class="section-content">
          <p class="content">
            {{ email ? email : '–' }}
          </p>
        </div>
      </div>
    </div>
  </c-card>
</template>

<script>
import CCard from '@/components/CComponents/CCard'

export default {
  components: { CCard },
  props: {
    description: {
      type: String,
      required: true
    },
    phone: {
      type: String,
      required: true
    },
    owner: {
      type: String,
      required: true
    },
    email: {
      type: String,
      required: true
    }
  }
}
</script>

<style lang="scss">
@import '~@/styles/reference';

.partner-info-card {
  width: 100%;

  & > .content {
    display: flex;
    flex-direction: column;

    height: 100%;
    width:  100%;

    & > .info-section {
      display: flex;
      flex-grow: 1;

      margin: 0 10px;
      padding: 10px 0;
      min-height: 66px;

      &:not(:last-child) { border-bottom: 1px solid $border-color; }

      & > .section-title {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        flex-grow: 1;
        flex-shrink: 0;
        max-width: 120px;
        margin-right: 20px;
        width: 100%;

        .title {
          font-size: 12px;
          font-weight: 400;
          color: $title-color;
          text-transform: uppercase;
        }
      }

      & > .section-content {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        flex-grow: 1;

        @include mobile {
          max-width: calc(100% - 140px);

          & > .content {
            max-width: calc(100% - 5px);
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
          }
        }

        .content {
          font-size: 14px;
          font-weight: 400;
          color: $text-color;
          padding: { top: 15px; bottom: 15px; }
        }
      }
    }
  }
}
</style>
