<template>
  <div class="campaign-info-card">
    <img
      class="campaign-image"
      :src="campaignImage"
      alt="Imagem do tipo de campanha"
    />
    <div class="campaign-info">
      <h3>{{ title }}</h3>
      <span>Objetivo:</span>
      <p>{{ reason }}</p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    campaignInfo: Object
  },
  computed: {
    campaignImage () {
      if (!this.campaignInfo || !this.campaignInfo.image) return require('~/assets/img/campaign-placeholder.svg')
      return this.campaignInfo.image
    },
    title () {
      if (!this.campaignInfo || !this.campaignInfo.title) return 'Carregando...'
      return this.campaignInfo.title
    },
    reason () {
      if (!this.campaignInfo || !this.campaignInfo.reason) return 'Carregando...'
      return this.campaignInfo.reason
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/styles/reference';

.campaign-info-card {
  display: grid;
  grid-template-columns: 1fr 2fr;
  column-gap: 20px;
  height: 125px;
  width: 100%;
  max-width: 613px;
  background: #FDFDFD;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.16);
  border-radius: 4px;
  padding: 12px;

  @include mobile { max-width: 100%; }

  .campaign-image {
    border-radius: 2px;
    width: 100%;
    max-width: 230px;
    max-height: 103px;
    object-fit: fill;
  }

  .campaign-info {
    > h3 {
      margin-bottom: 15px;
      font-size: 16px;
      color: #202950;
    }

    > span {
      font-size: 12px;
      color: #5E6684;
    }

    > p {
      color: #202950;
      font-size: 14px;
    }
  }
}
</style>
