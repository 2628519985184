<template>
  <div
    class="painel-desktop"
    :style="{background: data.primary || ''}"
  >
    <div class="categories">
      <div
        class="category"
        v-for="category in categories"
        :key="category.name"
      >
        <k-icon
          :icon="category.icon"
          class="icon"
        />
        <span class="text">
          {{ category.name }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { KIcon } from 'kaledo-components'

export default {
  name: 'PainelDesktop',
  components: { KIcon },
  props: {
    data: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      categories: [
        {
          name: 'Produtos e serviços',
          icon: 'bag-shopping-circle'
        },
        {
          name: 'Lazer',
          icon: 'album'
        },
        {
          name: 'alimentos e bebidas',
          icon: 'fork-spoon'
        },
        {
          name: 'Educação',
          icon: 'graduation'
        },
        {
          name: 'bem-estar e saúde',
          icon: 'heart-pulse'
        },
        {
          name: 'Mercados',
          icon: 'bag-circle-essential-interface-shopping-ui'
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/styles/reference';
.painel-desktop {
  width: 100%;
  height: 50px;
  background: #D8D8D8;
  color: white;
  .categories {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    .category {
      padding: 13px 0px;
      text-transform: uppercase;
      font-size: 10px;
      display: flex;
      align-items: center;
      .icon {
        font-size: 16px;
        margin-right: 14px;
      }
    }
    .category + .category {
      margin-left: 30px;
    }
  }
}
</style>
