<template>
  <section v-if="userDetails.id">
    <user-detail
      :user="user"
      :status="status"
      @confirmation="confirmation"
    />

    <user-modal-status
      :show-modal-status="showModalStatus"
      :status="payload.status"
      @close="showModalStatus = false"
      @cancel="showModalStatus = false"
      @change-status="__changeStatus"
    />

    <user-modal-delete
      :id="userDetails.id"
      :is-manager="!!isManager"
      :status="userDetails.status"
      :is-self="userDetails.id === currentUser.id"
      :show-modal-delete="showModalDelete"
      @close="showModalDelete = false"
      @cancel="showModalDelete = false"
      @delete="__deleteUser"
    />

    <user-modal-manager
      :show-modal-manager="showModalManager"
      :is-manager="!!payload.status"
      @close="showModalManager = false"
      @cancel="showModalManager = false"
      @change-status-manager="__changeStatusManager"
    />
  </section>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Loadable from '@/modules/loadable'
import * as types from '@/store/types'

import UserDetail from '@/components/User/Details/UserDetail'
import UserModalStatus from '@/components/User/UserModalStatus'
import UserModalManager from '@/components/User/UserModalManager'
import UserModalDelete from '@/components/User/UserModalDelete'

export default {

  components: {
    UserDetail,
    UserModalDelete,
    UserModalStatus,
    UserModalManager
  },
  mixins: [Loadable],

  data () {
    return {
      payload: {},
      status: null,
      isManager: null,
      showModalStatus: false,
      showModalDelete: false,
      showModalManager: false,
      user: {
        avatar: null,
        city: null,
        document: '',
        email: '',
        gender: null,
        id: '',
        last_name: '',
        name: '',
        phone: '',
        roles: [],
        state: null,
        status: 2
      }
    }
  },

  computed: {
    ...mapGetters({
      userDetails: types.USER,
      currentUser: types.CONFIG_USER,
      company: types.COMPANY
    })
  },

  beforeDestroy () {
    this.clearUser()
  },

  methods: {
    ...mapActions({
      getUser: types.USER,
      changeStatus: types.USER_TOGGLE_STATUS,
      changeStatusManager: types.USER_MANAGER_TOGGLE_STATUS,
      deleteUser: types.USER_DELETE,
      clearUser: types.USERS_CLEAR
    }),

    async load () {
      const id = this.$route.params.userId
      await this.getUser({ id })
      this.user = { ...this.userDetails }
      this.status = !(this.userDetails && this.userDetails.status === 2)
      this.isManager = ((this.userDetails || {}).roles || []).includes('panel_manager')
    },

    confirmation (params) {
      const conditions = {
        manager: () => { this.showModalManager = true },
        status: () => { this.showModalStatus = true },
        delete: () => { this.showModalDelete = true }
      }
      conditions[params.action]()
      this.payload = params
    },

    async __changeStatus () {
      const id = await this.changeStatus(this.payload)
      if (id) {
        this.status = !this.status
        this.user.status = this.user.status === 2 ? 0 : 2
      }
      const event = this.status ? 'toggle-ativar-usuario-detalhe' : 'toggle-desativar-usuario-detalhe'
      this.handleAnalytics(event, { company_name: this.company.name })
      this.showModalStatus = false
    },

    async __changeStatusManager () {
      const id = await this.changeStatusManager(this.payload)

      if (id) {
        this.isManager = !this.isManager
      }
      this.handleAnalytics('promover-usuario-detalhe', { company_name: this.company.name })
      this.showModalManager = false
    },

    __deleteUser () {
      this.deleteUser(this.payload)
      this.showModalDelete = false
      this.handleAnalytics('remover-usuario-detalhe', { company_name: this.company.name })
      this.$router.push({ name: 'usuarios' })
    },
    handleAnalytics (event, data) {
      window.dataLayer.push(
        {
          event,
          ...data
        }
      )
    }
  }

}
</script>
