<template>
  <div class="awards-card">
    <div class="awards-tabs">
      <campaigns-bar-card
        v-for="(tab, index) in tabs"
        :key="index"
        class="card"
        :class="selectedTab === index ? 'selected-tab' : ''"
        :data="tab"
        :loading="loading"
        icon="info-information-circle"
        @click.native="changeTab(index)"
      />
    </div>
    <not-available
      v-if="!loading && donutData.columns.length === 0"
      :type="companyPermission"
    />
    <div
      v-else
      class="awards-content"
    >
      <awards-filters
        class="awards-filters-card"
        :title="'Ofertas de premiação'"
        @filters="filters => searchResults(filters)"
      />
      <dash-table
        class="awards-table-card"
        :title="'Principais Ofertas de Premiação'"
        :filters="filters"
        :loading="loading"
      />
      <graph-card
        class="award-categories-card"
        horizontal
        :loading="loading"
        :tabs="donutTabs"
        :graph-data="donutData"
      />
    </div>
  </div>
</template>

<script>
import DashTable from '@/components/Dashboard/base/DashTable.vue'
import CampaignsBarCard from '@/components/Dashboard/cards/CampaignsBarCard.vue'
import GraphCard from '@/components/Dashboard/base/GraphCard.vue'
import AwardsFilters from '@/components/Dashboard/base/AwardsFilters.vue'
import notAvailable from '@/components/Dashboard/base/notAvailable.vue'

export default {
  name: 'AwardsCard',
  components: {
    DashTable,
    CampaignsBarCard,
    GraphCard,
    AwardsFilters,
    notAvailable
  },
  props: {
    loading: Boolean,
    tableData: Object,
    donutData: Object
  },
  data () {
    return {
      companyPermission: 'nopermission',
      donutTabs: [
        'Mais Acessos',
        'Mais Resgates'
      ],
      filters: {},
      partner: {
        logo: 'https://clube-newcore.s3.us-west-2.amazonaws.com/perks-logo/hIntox2ZXYarDn2n.png',
        name: 'Steam',
        description: 'Compre Gift Card da Steam e receba Cashback'
      },
      tabs: [
        [
          {
            total: 1000,
            label: 'Prêmios concedidos no período',
            comparisonValue: -10,
            info: {
              title: 'Prêmios concedidos no período',
              text: 'Prêmios concedidos no período'
            }
          },
          {
            total: 900,
            label: 'Ofertas resgatadas no período',
            comparisonValue: 10,
            info: {
              title: 'Ofertas resgatadas no período',
              text: 'Ofertas resgatadas no período'
            }
          }
        ],
        [
          {
            total: 990,
            label: 'Usuários premiados no período',
            comparisonValue: 10,
            info: {
              title: 'Usuários premiados no período',
              text: 'Usuários premiados no período'
            }
          },
          {
            total: 718,
            label: 'Resgates em Destaques no período',
            comparisonValue: 10,
            info: {
              title: 'Resgates em Destaques no período',
              text: 'Resgates em Destaques no período'
            }
          }
        ]
      ],
      selectedTab: 0,
      categorias: ['x', '2013-02-01', '2013-02-02', '2013-02-03', '2013-02-04', '2013-02-05'],
      tableLabels: [ 'Nome Oferta', 'Acessos', 'Resgates' ]
    }
  },
  methods: {
    changeTab (index) {
      this.selectedTab = index
    },
    searchResults (filters) {
      console.log('filters', filters)
    }
  }
}
</script>

<style lang='scss' scoped>
.awards-card {
  > .awards-tabs {
    display: flex;
    z-index: -1;

    > .card {
      cursor: pointer;
      background: #F8F9FD;
      padding: 15px 20px;
      border-radius: 6px 6px 0px 0px;
      box-shadow: 1px -1px 4px 0 rgba(0,0,0,0.1);
      width: 100%;
    }

     > .card:last-child {
      margin-left: 10px;
    }

    & .no-partner {
        background: #F8F9FD;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;

        p {
          max-width: 180px;
        }
    }

    > .selected-tab {
      background: #FDFDFD;
    }
  }

  > .not-available {
    background: white;
    padding: 10px;
    border-radius: 0px 0px 6px 6px;
  }

  > .awards-content {
    margin-top: -1px;
    padding-top: 15px;
    background: #FDFDFD;
    z-index: 3;
    display: grid;
    grid-template-areas:
      "filters filters filters"
      "table chart chart";
    width: 100%;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.06);
    border-radius: 0px 0px 6px 6px;

    > .awards-filters-card {
      grid-area: filters;
      width: 100%;
      padding: 5px 20px;
    }

    > .awards-table-card {
      grid-area: table;
    }

    > .graph-card {
      width: 100%;
      min-width: 600px;
      box-shadow: none;

      & :deep .main-card {
        box-shadow: none;
      }

      & :deep .inner {
        .loading-card {
          padding-bottom: 40px;
          height: 100% !important;
        }

        .circle {
          left: 85px;
        }
      }
    }
  }
}
</style>
