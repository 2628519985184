<template>
  <div class="error-container">
    <c-character type="sad" class="character" />
    <h1 class="message" v-if="error.statusCode === 404">{{ error.statusCode }} Pagina não encontrada</h1>
    <h1 class="message" v-else>Ocorreu um erro.</h1>

    <c-button
      icon="chevron-left"
      icon-size="sm"
      class="btn"
      size="lg"
      primary
      to="/"
    >
      Página Inicial
    </c-button>
  </div>
</template>

<script>
import CCharacter from '@/components/CComponents/CCharacter'
import CButton from '@/components/CComponents/CButton'

export default {
  components: { CCharacter, CButton },
  props: ['error']
}
</script>

<style lang="scss">

.error-container {
  height: 50vh;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  & > .message {
    color: #636A86;
    margin: 15px 0;
  }
}
</style>
