<template>
  <div
    class="container"
    @mouseenter="showPopover = true"
    @mouseleave="showPopover = false"
  >
    <k-icon
      class="info-button"
      icon="question"
      ref="button"
      @click.native="$emit('btn-click'), showPopover = true"
    />
    <span class="popover-reloader">{{ showPopover }}</span>
    <c-popover
      :key="showPopover"
      v-if="$refs.button && popoverLabel && showPopover"
      class="popover"
      :position="['top', 'center']"
      :arrow="false"
      :target="$refs.button"
    >
      <span v-html="popoverLabel" />
    </c-popover>
  </div>
</template>

<script>
import { KIcon } from 'kaledo-components'
import CPopover from './CPopover'
export default {
  components: {
    KIcon,
    CPopover
  },
  props: {
    popoverLabel: String
  },
  data () {
    return {
      showPopover: false
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/styles/reference';
.container {
  display: flex;
  .info-button {
    font-size: 20px;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all .1s ease;

    &:hover {
      border: 3px solid rgba(94, 102, 132, 1);
      border-radius: 50px;
    }
  }
  .popover-reloader {
    color: transparent;
    font-size: 1px;
  }
  .popover {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 4px 8px;
    gap: 8px;
    isolation: isolate;
    min-width: 232px;
    max-width: 270px;
    min-height: 44px;
    max-height: 65px;
    background:  var(--color-primary);
    border-radius: 4px;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    display: flex;
    align-items: center;
    letter-spacing: -0.121905px;
    color: #FDFDFD;
  }
}
</style>
