<template>
  <div class="footer">
    <div class="fake-carrousel">
      <k-icon icon="left1" />
      <div class="partner-container">
        <div
          v-for="(div, index) in 3"
          :key="index"
          class="partner"
        />
      </div>
      <k-icon icon="arrow-right" />
    </div>
    <div
      class="kaledo-logo"
      v-if="developedByConvenia"
    >
      <span class="text">Desenvolvido por </span> <kaledo-logo />
    </div>
  </div>
</template>

<script>
import { swiper, swiperSlide } from 'vue-awesome-swiper'
import { KIcon } from 'kaledo-components'
import KaledoLogo from './KaledoLogo.vue'

export default {
  components: {
    swiper,
    swiperSlide,
    KaledoLogo,
    KIcon
  },

  props: {
    developedByConvenia: Boolean
  }
}
</script>

<style lang="scss" scoped>
.footer {
  height: 80px;
  width: 1020px;
  display: flex;
  align-items: center;
  background: #FFF;

  & > .fake-carrousel {
    width: 675px;
    display: flex;
    justify-content: center;
    align-items: center;

    & > .partner-container{
      display: flex;
      margin: 0 24px;
      & > .partner {
        width: 130px;
        height: 56px;
        border: 1px solid var(--neutral-gray-150, #8C92B1);
      }

      & > .partner:nth-child(2) {
        margin: 0 16px;
      }
    }
  }

  & > .kaledo-logo {
    width: 345px;
    display: flex;
    justify-content: center;
    align-items: self-end;
    & > .text {
      margin-right: 8px;
      color: var(--neutral-gray-150, #8C92B1);
      text-align: center;
      font-feature-settings: 'clig' off, 'liga' off;
      font-family: Nunito Sans;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%;
      letter-spacing: -0.122px;
    }
  }
}
</style>
