<template>
  <div>
    <awards-new-orders :campaigns="campaigns" />
  </div>
</template>

<script>
import AwardsNewOrders from '@/components/Awards/New/AwardsNewOrders'
import { mapGetters } from 'vuex'
import { AWARDS_CAMPAIGNS } from '@/store/types'

export default {
  components: {
    AwardsNewOrders
  },
  computed: {
    ...mapGetters({
      campaigns: AWARDS_CAMPAIGNS
    })
  }
}
</script>

<style>

</style>
