<template>
  <c-modal
    :is-opened="changedUsers !== undefined"
    class="modals"
    name=""
    @close="$emit('close')"
  >
    <div
      class="containerDiv"
      v-if="changedUsers !== undefined"
    >
      <div class="image">
        <c-character :type="setCharacterType" />
      </div>

      <div class="container">
        <h3 class="title">
          {{ setTitle }}
        </h3>
        <p class="sub-title">
          {{ setSubTitle }}
        </p>
      </div>

      <div class="actions">
        <c-button
          primary
          size="lg"
          class="action-delete"
          @click="$emit('close')"
        >
          Ok!
        </c-button>
      </div>
    </div>
  </c-modal>
</template>

<script>
import CCharacter from '@/components/CComponents/CCharacter'
import CModal from '@/components/CComponents/CModal.vue'

export default {
  components: {
    CCharacter,
    CModal
  },
  props: {
    changedUsers: {
      type: undefined,
      required: true
    }
  },
  computed: {
    setTitle () {
      if (this.changedUsers.changed > 0) return `${this.changedUsers.changed} usuário(s) foram removidos com sucesso!`
      return 'Não conseguimos remover esses usuário(s).'
    },
    setSubTitle () {
      if (this.changedUsers.notChanged !== '') return `Não foi possível remover esses usuários.`
      return ''
    },
    setCharacterType () {
      if (this.changedUsers.changed > 0) return 'happy'
      return 'embarrassed'
    }
  }
}
</script>

<style lang="scss">
@import '~@/styles/reference';

.containerDiv {

    & > .container {
      margin-top: 20px;

      & > .title {
        margin-top: 20px;
        text-align: center;
      }

      & > .sub-title {
        margin-top: 20px;
        text-align: center;
      }
    }

    & > .actions {
      margin-top: 20px;
      display: flex;
      justify-content: center;

      & >.action-delete {
        margin-left: 10px;
      }
    }
}
.image{
  display: flex;
  justify-content: center;
}
  .modals .c-modal-mask{
    z-index: 10;
  }
  .modals .c-modal{
    z-index: 10;
  }
</style>
