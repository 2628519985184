<template>
  <div class="account-creation-form">
    <progress-bar
      class="progress-bar"
      :step="step"
      :steps="2"
      @step-click="stepClicked => step = stepClicked"
    />
    <h1
      data-cy="title"
      class="title"
    >
      Crie sua conta
    </h1>
    <h2
      data-cy="subtitle"
      class="subtitle"
    >
      Preencha suas informações para começar a utilizar a Kaledo
    </h2>
    <step-one
      v-model="stepsData.first"
      :step-value="stepsData.first"
      v-if="step === 1"
    />
    <!-- <step-two
      v-model="stepsData.second"
      :step-value="stepsData.second"
      v-else-if="step === 2"
    /> -->
    <step-three
      v-model="stepsData.second"
      :step-value="stepsData.second"
      v-else-if="step === 2"
    />
    <k-button
      data-cy="continue-activation-button"
      class="submit-button"
      no-icon
      :disabled="!enableNextStep || loading"
      :loading="loading"
      :outlined="step < 3"
      @click.native="updateStep()"
    >
      <span class="btn-content">
        {{ submitButtonText }}
        <k-icon
          v-if="step < 2 && !loading"
          class="btn-icon"
          icon="right-arrow"
          size="18"
        />
      </span>
    </k-button>
    <call-to-login v-if="!isDesktop && step === 1" />
    <a
      data-cy="cancel-button"
      class="cancel-button"
      href="/login"
    >Cancelar</a>
  </div>
</template>

<script>
import ProgressBar from '@/components/General/ProgressBar.vue'
import StepOne from './Steps/StepOne.vue'
// import StepTwo from './Steps/StepTwo.vue'
import StepThree from './Steps/StepThree.vue'
import { KButton, KIcon } from 'kaledo-components'
import { isCPF, isCNPJ, isPhone, isProfessionalEmail, isPasswordValid, maxLengthValidation } from '@/modules/validate/validators.js'
import CallToLogin from '@/components/AccountCreation/CallToLogin.vue'

export default {
  name: 'AccountCreationForm',
  components: {
    ProgressBar,
    StepOne,
    StepThree,
    KButton,
    KIcon,
    CallToLogin
  },
  props: {
    loading: Boolean
  },
  data () {
    return {
      step: 1,
      stepsData: {
        first: {
          name: '',
          email: '',
          phone: '',
          document: '',
          cnpj: ''
        },
        // second: {
        //   birthday: '',
        //   phone: '',
        //   state: '',
        //   city: '',
        //   gender: ''
        // },
        second: {
          password: '',
          password_confirmation: '',
          terms: false
        }
      }
    }
  },
  computed: {
    submitButtonText () {
      return this.step < 2 ? 'Próximo passo' : 'Criar conta'
    },
    enableNextStep () {
      switch (this.step) {
      case 1:
        return this.validFirstStepData
      default:
        return this.validSecondStepData
      }
    },
    validFirstStepData () {
      const isNameValid = this.stepsData.first.name && maxLengthValidation(this.stepsData.first.name, 255)
      const isPhoneValid = this.stepsData.first.phone && isPhone(this.stepsData.first.phone)
      const isDocumentValid = this.stepsData.first.document && isCPF(this.stepsData.first.document)
      const isCNPJValid = this.stepsData.first.cnpj && isCNPJ(this.stepsData.first.cnpj)
      const isEmailValid = this.stepsData.first.email &&
        isProfessionalEmail(this.stepsData.first.email) &&
        maxLengthValidation(this.stepsData.first.email, 255)

      return isNameValid && isEmailValid && isPhoneValid && isDocumentValid && isCNPJValid
    },
    // validSecondStepData () {
    //   const stepData = this.stepsData.second
    //   const isBirthdayValid = stepData.birthday && isValidDate(stepData.birthday)
    //   const isPhoneValid = stepData.phone && isPhone(stepData.phone)

    //   return isBirthdayValid && isPhoneValid && stepData.state && stepData.city && stepData.gender
    // },
    validSecondStepData () {
      const stepData = this.stepsData.second
      const passwordValidate = isPasswordValid(stepData.password) &&
        (stepData.password === stepData.password_confirmation) &&
        maxLengthValidation(this.stepsData.second.password, 255)

      return passwordValidate && stepData.terms
    }
  },
  methods: {
    handleAnalytics (event, data) {
      window.dataLayer.push(
        {
          event,
          ...data
        }
      )
    },
    updateStep () {
      if (this.step < 2) {
        this.step++
        this.handleAnalytics(`cadastro_cliente_passo_${this.step}`)
      } else {
        const formatedData = {
          ...this.stepsData.first,
          ...this.stepsData.second
        }
        this.$emit('submit-form', formatedData)
        this.handleAnalytics('cadastro_cliente_concluir')
      }
    }
  }
}
</script>

<style lang='scss' scoped>
@import '~@/styles/reference';
.account-creation-form {
  margin: 0 auto;
  text-align: center;
  padding: 0px 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;

  .progress-bar {
    width: 100% !important;
    max-width: 576px;
    margin: 0 auto;

    @include tablet {
      max-width: 316px;
    }
  }
  .title {
    margin-top: 56px;
    margin-bottom: 16px;
    font-size: 56px;
    font-weight: 800;
    color: #202950;

    @include mobile {
      font-size: 40px;
    }
  }
  .subtitle {
    margin-bottom: 40px;
    color: #202950;
    text-align: center;
    font-size: 20px;
    font-weight: 400;

    @include mobile {
      font-size: 16px;
    }
  }
  .submit-button {
    margin-top: 40px;
    margin-bottom: 40px;
    width: 100%;
    max-width: 508px;
    box-shadow: 2px 4px 8px 0px rgba(32, 41, 80, 0.16);

    @include tablet {
      max-width: 640px;
    }

    .btn-content {
     display: flex;
     align-items: center;
     justify-content: center;

     .btn-icon {
      margin-left: 8px;
     }
    }
  }
  .call-to-login {
    margin-bottom: 40px;
  }
  .cancel-button {
    color: #5E6684;
    font-size: 18px;
    text-decoration: none;
    font-weight: 600;
  }
}
</style>
