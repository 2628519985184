<template>
  <div
    data-cy="popup-example-card"
    class="popup-example"
  >
    <img
      class="img"
      src="@/assets/img/AwardStarsPopup.png"
    />
    <div class="card-container">
      <div class="card">
        <img src="@/assets/img/AwardStarsCard.png" />
        <div class="text">
          <span><k-icon
            icon="award-star"
            size="20"
            class="icon"
          /><b>Prêmio</b></span> <br />
          <span><b>[valor]</b></span>
        </div>
      </div>
    </div>
    <div class="text">
      <h1 class="title">
        Parabéns! Você recebeu um prêmio
      </h1>
      <h4 class="text">
        Um mimo de boas vindas para você utilizar em ofertas exclusivas na Kaledo.
      </h4>
      <h3 class="resume">
        Concedido por:<span>[Nome da empresa]</span>
      </h3>
      <h3 class="resume">
        Entregue em:<span>[dd/mm/aaaa]</span>
      </h3>
    </div>
    <div class="footer-button">
      <k-button
        type="default"
        class="button"
        icon-size="12"
      >
        Saiba mais
      </k-button>
    </div>
  </div>
</template>

<script>
import { KIcon, KButton } from 'kaledo-components'

export default {
  components: {
    KIcon,
    KButton
  }
}
</script>

<style lang="scss" scoped>
.popup-example {
  margin: 16px 0;
  width: 617px;
  height: 369px;
  left: 0px;
  top: 0px;
  background: #FFFFFF;
  box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  & > .img {
    position: absolute;
    width: 617px;
  }
  & > .card-container {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
    top: 25%;
    & > .card {
      display: flex;
      padding: 0px 16px 0px 0px;
      gap: 20px;
      width: 179px;
      height: 72px;
      background: #121E48;
      box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.25);
      border-radius: 4px;
      font-style: normal;
      font-weight: 700;
      font-size: 18px;
      line-height: 23px;
      display: flex;
      align-items: center;
      text-align: center;
      color: #FFFFFF;
      & > .text {
        & > span {
          & > .icon {
            padding-right: 5px;
          }
        }
      }
    }
  }
  & > .text {
    padding-top: 90px;
    text-align: center;
    & > .title {
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 18px;
      align-items: center;
      text-align: center;
      color: #121E48;
      mix-blend-mode: normal;
      opacity: 0.8;
      padding: 16px 0;
    }
    & > .resume {
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      align-items: center;
      letter-spacing: -0.121905px;
      color: #565F7D;
      mix-blend-mode: normal;
      display: flex;
      justify-content: center;
      padding-bottom: 4px;
      & > span {
        font-style: normal;
        font-weight: 400;
        font-size: 13px;
        line-height: 18px;
        text-align: center;
        color: #121E48;
        mix-blend-mode: normal;
        opacity: 0.5;
        padding-left: 5px;
      }
    }
    & > .text {
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 18px;
      text-align: center;
      color: #121E48;
      mix-blend-mode: normal;
      opacity: 0.5;
      padding-bottom: 16px;
    }
  }
  & > .footer-button {
    display: flex;
    justify-content: center;
    padding-top: 12px;
    & > .button {
      padding: 8px 16px;
      gap: 8px;
      width: 133px;
      height: 40px;
      background: #121E48;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 30px;
      font-weight: 400;
      font-size: 12px;
      &:hover {
        cursor: default;
      }
      :deep .icon {
        bottom: auto;
      }
    }
  }
}
</style>
