<template>
  <div class="user-new-import-page">
    <user-import-create />
  </div>
</template>

<script>
export default {
  components: {
    UserImportCreate: () => import('@/containers/User/UserImportCreate')
  }
}
</script>

<style lang="scss">
@import '~@/styles/reference';

.user-new-import-page {
  @include container;
}
</style>
