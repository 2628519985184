<template>
  <div class="contact-form">
    <div class="section-header">
      <c-title
        icon="telephone"
        icon-size="18"
      >
        Contato
      </c-title>
    </div>

    <div class="section-controls">
      <div class="form-inline">
        <c-input
          label="Telefone"
          class="form-control -inline"
          placeholder="(00) 00000-0000"
          :mask="['(##) ####-####', '(##) ####-#####']"

          :value="formData.phone"
          @input="phone => $emit('update-form', { contact: { phone } })"
        />

        <c-input
          label="Responsável"
          placeholder="Nome do responsável"
          class="form-control -inline"

          :value="formData.name"
          @input="name => $emit('update-form', { contact: { name } })"
        />
      </div>

      <c-input
        label="E-mail"
        placeholder="exemplo@empresa.com.br"
        class="form-control"

        :value="formData.email"
        @input="email => $emit('update-form', { contact: { email } })"
      />
    </div>
  </div>
</template>

<script>

export default {
  props: {
    formData: {
      type: Object,
      required: true
    }
  }
}
</script>

<style lang="scss">
</style>
