<template>
  <shortcuts />
</template>

<script>
import Shortcuts from '@/containers/Shortcuts/Shortcuts.vue'
export default {
  components: { Shortcuts }

}
</script>

<style>
</style>
