<template>
  <c-modal
    class="modal"
    :is-opened="showModal"
    :is-fullscreen="false"
    name="name-modal"
    hide-cancel
    @close="$emit('close')"
  >
    <c-title
      data-cy="modal-title"
      class="title"
    >
      Detalhes da campanha
    </c-title>
    <div class="campaign-info">
      <h2>{{ campaign.title }}</h2>
      <div class="info">
        <h3>Descrição</h3>
        <span class="info-text description">
          {{ campaign.description }}
        </span>
      </div>
      <div class="dates">
        <div class="info">
          <h3>Data início</h3>
          <span class="info-text">
            {{ formatDate(campaign.start_date) }}
          </span>
        </div>
        <div class="info">
          <h3>Data final</h3>
          <span class="info-text">
            {{ campaign.end_date ? formatDate(campaign.end_date) : '-' }}
          </span>
        </div>
      </div>
      <div class="info">
        <h3>Recompensa</h3>
        <span class="info-text">
          {{ campaign.value }} moedas
        </span>
      </div>
    </div>
    <div class="campaign-preview">
      <h2>Card da campanha</h2>
      <campaign-preview-card
        :image="campaign.image || ''"
        :name="campaign.title || ''"
        :description="campaign.description || ''"
        :value="campaign.value || ''"
        :end_date="campaign.end_date ? formatDate(campaign.end_date) : ''"
      />
    </div>
    <span class="info-text description">
      Criada em {{ formatDate(campaign.created_at) }}
    </span>
  </c-modal>
</template>

<script>
import { timestampToOnlyDate } from '@/modules/validate/helpers.js'
import CModal from '@/components/CComponents/CModal'
import CampaignPreviewCard from '../components/CampaignPreviewCard'

export default {
  components: {
    CModal,
    CampaignPreviewCard
  },
  props: {
    showModal: {
      type: Boolean,
      required: true
    },
    coin: Object,
    campaign: {
      type: Object,
      required: true
    }
  },
  methods: {
    formatDate (date) {
      return timestampToOnlyDate(date)
    }
  }
}
</script>

<style lang="scss" scoped>
 .modal :deep .name-modal {
    margin: auto 0;
    height: 100%;
    width: 99%;
    max-height: 832px !important;
    max-width: 434px !important;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);

    &::-webkit-scrollbar {
      width: 10px
    }

    &::-webkit-scrollbar-track {
      background: #BEC0D3;
      border-radius: 16px;
    }

    &::-webkit-scrollbar-thumb {
      background: #5E6684;
      border-radius: 16px;
    }
  }
  .info-text {
    font-size: 14px;
    line-height: 17px;
    color: #5E6684;

    &:not(.description) {
      text-transform: uppercase;
    }
  }
  .campaign-info {
    display: flex;
    flex-direction: column;
    margin-top: 32px;

    h2 {
      color: #202950;
      font-size: 18px;
      margin-bottom: 24px;
    }

    h3 {
      color: #202950;
      font-size: 14px;
      text-transform: uppercase;
    }

    .info {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 8px;
      margin-bottom: 32px;
    }

    .dates {
      display: flex;
      justify-content: space-between;

      & .info:last-of-type {
        align-items: flex-end;
      }
    }
  }
  .campaign-preview {
    display: flex;
    flex-direction: column;
    margin-bottom: 32px;

    h2 {
      font-size: 18px;
      color: #202950;
      margin-bottom: 16px;
    }
  }

</style>
