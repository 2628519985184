<template>
  <div class="activate-account-content">
    <login-modal
      :opened="error.type === 'userExpired'"
      maintext="Tempo expirado para ativação de gesto."
      text="Ops! Seu tempo para ativar sua conta de gestor terminou"
      type="sad"
      button="Ok"
      @close="$emit('clear-error')"
    />

    <login-modal
      :opened="error.type === 'notEnabled' && !errorCompanies.length"
      maintext="CPF não habilitado para ser gestor"
      text="Ops! Você não está habilitado como gestor desta conta."
      type="sad"
      button="Ok"
      @close="$emit('clear-error')"
    />

    <login-modal
      :opened="error.type === 'notEnabled' && !!errorCompanies.length"
      type="happy"
      @close="$emit('clear-error')"
    >
      <div class="modal-carrousel">
        <div class="modal-text">
          <p class="text">
            Você já se cadastrou no(s) clube(s) indicados abaixo. Agora é só fazer login
          </p>
        </div>
        <swiper
          :class="['carousel', { '-center': errorCompanies.length === 2 }]"
          :options="swiperOptions"
        >
          <swiper-slide
            v-for="(company, index) in errorCompanies"
            :key="index"
          >
            <div class="item">
              <c-image
                is-background
                class="logo"
                :src="company.logo || ''"
                :placeholder="require('@/assets/img/logoPlaceholder.png')"
                width="85px"
                height="70px"
              />
            </div>
            <p class="text">
              {{ company.name }}
            </p>
          </swiper-slide>

          <div
            class="swiper-button -prev"
            slot="button-prev"
          >
            <c-icon
              class="icon"
              icon="chevron-left"
              size="22"
            />
          </div>
          <div
            class="swiper-button -next"
            slot="button-next"
          >
            <c-icon
              class="icon"
              icon="chevron-right"
              size="22"
            />
          </div>
        </swiper>
        <div class="modal-actions">
          <c-button
            primary
            size="lg"
            @click="$emit('clear-error')"
          >
            Entendi
          </c-button>
        </div>
      </div>
    </login-modal>
    <div class="activate-account-box">
      <login-leftside />

      <div class="activate-account-rightside">
        <div class="logo">
          <c-logo
            kaledo
          />
        </div>

        <c-form
          @submit="$emit('submit', document)"
          class="form"
        >
          <span class="title">Ativar conta</span>
          <p>Para ativar sua conta, insira seu CPF no campo abaixo para que possamos verificar em qual clube você está habilitado</p>

          <c-input
            data-cy="activate-document-input"
            required
            icon="contacts"
            placeholder="Insira seu CPF"
            class="input-data"
            name="CPF"
            v-validate="'required'"
            data-vv-delay="10"
            :disabled="isLoading"
            :feedback-show="errors.has('CPF') || isDocumentInvalid"
            :feedback-message="errors.first('CPF') || 'CPF inválido'"
            :mask="'###.###.###-##'"
            :value="document"
            @input="value => document = value"
          />

          <div class="btngroup">
            <c-button
              data-cy="cancel-activate-button"
              default
              size="lg"
              class="btn"
              type="button"
              @click="$router.push({ name: 'login' })"
            >
              Cancelar
            </c-button>

            <c-button
              data-cy="activate-account-button"
              primary
              size="lg"
              class="btn"
              :disabled="disableActivateButton"
              @click="validate"
            >
              Ativar conta
            </c-button>
          </div>
        </c-form>
      </div>
    </div>
  </div>
</template>

<script>
import CForm from '@/components/CComponents/CForm'
import CLogo from '@/components/CComponents/CLogo'
import CImage from '@/components/CComponents/CImage'
import { isCPF } from '@/modules/validate/validators.js'
import LoginLeftside from '@/components/Login/LoginLeftside'
import LoginModal from '@/components/Login/LoginModal'
import { swiper, swiperSlide } from 'vue-awesome-swiper'

const swiperOptions = {
  freeMode: true,
  spaceBetween: 20,
  slidesPerView: 3,
  navigation: {
    nextEl: '.swiper-button.-next',
    prevEl: '.swiper-button.-prev',
    disabledClass: '-disabled',
    hiddenClass: '-hidden'
  },
  breakpoints: {
    350: { slidesPerView: 2, spaceBetween: 10 },
    640: { slidesPerView: 2 },
    840: { slidesPerView: 3 }
  }
}

export default {
  components: {
    swiper,
    swiperSlide,
    CForm,
    CImage,
    CLogo,
    LoginLeftside,
    LoginModal
  },
  props: {
    error: {
      type: Object,
      required: true
    },
    isLoading: {
      type: Boolean,
      required: true
    },

    // Old stuff:
    failedModal: {
      type: Boolean,
      default: false
    },
    sucessModal: {
      type: Boolean,
      default: false
    },
    email: {
      type: String
    }
  },
  data () {
    return {
      swiperOptions,
      touched: false,
      document: ''
    }
  },
  computed: {
    isDirty () {
      if (this.touched) return true
      this.touched = !!this.document
      return this.touched
    },
    disableActivateButton () {
      return !isCPF(this.document) || this.isLoading
    },
    isFormInvalid () {
      return this.isDirty && !isCPF(this.document)
    },
    isDocumentInvalid () {
      return this.isFormInvalid || this.error.type === 'invalidCredentials'
    },
    errorCompanies () {
      return (this.error.data && this.error.data.companies) || []
    }
  },
  methods: {
    validate (ev) {
      if (this.touched) return true
      else ev.preventDefault()
      this.touched = true
      this.$validator.validate()
    }
  }
}
</script>

<style lang="scss">
@import '~@/styles/reference';

.activate-account-content {
  @include LoginContent;

  & > .activate-account-box {
    @include LoginBox;

    @include desktop {
      width: 780px;
      min-width: 600px;
    }

    & > .activate-account-rightside {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      padding: 0 40px;

      @include desktop {
        width: 55%;
      }
      & > .logo {
        margin-top: 50px;
        margin-bottom: 40px;
      }
      & > .form {
        margin-bottom: 50px;
        max-width: 320px;

        & > .title {
          font-weight: $title-font-weight;
          color: $title-color;
          font-family: $title-font-family;
        }

        & > .input-data {
          margin-top: 20px;
          margin-bottom: 35px;

          input {
            &:hover,
            &:active,
            &:focus {
              border-color: $color-primary-login;
              box-shadow: 0 0 0 1px $color-primary-login;
              outline: $color-primary-login;
            }
          }
        }
        & > .btngroup {
          display: flex;
          justify-content: space-between;
          margin-top: 30px;

          button {
            width: 45%;
            &:hover,
            &:active,
            &:focus {
              border-color: $color-primary-login;
              box-shadow: 0 0 0 1px $color-primary-login;
              outline: $color-primary-login;
            }
          }
        }
      }
    }
  }

  .modal-carrousel {
    & > .modal-text {
      display: flex;
      justify-content: center;

      margin-top: 20px;

      & > .text {
        font-size: 16px;
        max-width: 405px;
        text-align: center;
      }
    }
    & >.text {
      text-align: center;
      margin: 0 15%;
      color: rgba(18,30,72,0.8);
    }
      & > .carousel {
        margin: 0 auto;
        //margin-top: 25px;

        // GAMBETA WARNING
        &.-center > .swiper-wrapper {
          transform: translate3d(49px, 0px, 0px) !important;
        }

        .swiper-slide {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          height: 130px;
          min-width: 150px;

          @include mobile {
            min-width: 100px;
          }

          & > .item {
            min-width: 150px;
            height: 75px;
            display: flex;
            justify-content: center;
            align-items: center;

            @include mobile {
              min-width: 100px;
            }

            img {
              width: 60px;
              height: 40px;
              padding: 20px 30px;
              background-position: center;
              background-size: contain;
              background-repeat: no-repeat;

            }
          }

          & >.text {
            margin-top: 5px;
            font-size: 14px;
            font-weight: 600;
            color: rgba(18,30,72,0.8);

            max-width: calc(100% - 30px);
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
          }
        }

        & > .swiper-button {
          z-index: 20;
          background-color: rgba(234,237,239, 0.7);
          display: block;
          position: absolute;
          cursor: pointer;
          top: 50%;
          border-radius: 50%;
          width: 60px;
          height: 60px;
          transition: opacity .3s ease;

          & > .c-icon.icon {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
          }

          &.-disabled,
          &.-hidden { opacity: 0; }

          &.-prev {
            left: 0;
            transform: translate(-50%, -50%);

            & > .c-icon.icon { right: 8px; }
          }

          &.-next {
            right: 0;
            transform: translate(50%, -50%);

            & > .c-icon.icon { left: 8px; }
          }
        }
      }

    & > .modal-actions {
      display: flex;
      margin: 20px 0 30px 0;
      justify-content: center;

      & > .c-button {
        width: 100%;
        max-width: 150px;
      }
    }
  }
}

</style>
