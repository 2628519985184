<template>
  <div>
    <div
      v-for="(option, index) in options"
      :key="`radio-buttons-${option.value}-${index}`"
    >
      <input
        :id="option.value"
        type="radio"
        :value="option.value"
        :name="name"
        v-model="value"
        @input="updateValue"
      />

      <label :for="option.value">{{ option.label }}</label>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RadioButtons',
  props: {
    name: String,
    options: Array,
    selectedValue: [String, Boolean]
  },
  data () {
    return {
      value: ''
    }
  },
  mounted () {
    this.value = this.selectedValue
  },
  methods: {
    updateValue (e) {
      this.value = e.target.value
      e.target.checked = true
      this.$emit('update:selectedValue', e.target.value)
    }
  }
}
</script>

<style lang="scss" scoped>
[type="radio"]:checked,
[type="radio"]:not(:checked) {
    position: absolute;
    left: -9999px;
}
[type="radio"]:checked + label,
[type="radio"]:not(:checked) + label
{
    position: relative;
    padding-left: 24px;
    padding-bottom: 8px;
    cursor: pointer;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    display: inline-block;
    color: #5E6684;
}
[type="radio"]:checked + label:before,
[type="radio"]:not(:checked) + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 16px;
    height: 16px;
    border: 1px solid #8C92B1;
    border-radius: 100%;
    background: #FDFDFD;
}
[type="radio"]:checked + label:after,
[type="radio"]:not(:checked) + label:after {
    content: '';
    width: 12px;
    height: 12px;
    background: #4457AB;
    position: absolute;
    top: 2px;
    left: 2px;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}
[type="radio"]:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
}
[type="radio"]:checked + label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
}
</style>
