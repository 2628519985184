<template>
  <section class="container">
    <c-title size="3">
      <span class="text">
        <k-icon
          class="icon"
          icon="pen-edit5"
          size="20"
        />
        Personalização
      </span>
    </c-title>
    <div class="customization-list">
      <config-card
        v-for="(customization, index) in customizationList"
        :customization="customization"
        :card-index="index"
        :key="index"
      />
    </div>
    <trial-banner
      v-if="company.trial"
      @call-sales="callSales()"
    />
  </section>
</template>

<script>
import { KIcon } from 'kaledo-components'
import ConfigCard from '@/components/Customization/ConfigCard'
import TrialBanner from '@/components/Settings/TrialBanner'
import { mapGetters, mapActions } from 'vuex'
import { COMPANY, COMPANY_CALL_SALES } from '@/store/types'
export default {
  components: {
    KIcon,
    ConfigCard,
    TrialBanner
  },
  data () {
    return {
      customizationList: [
        {
          title: 'Página de acesso',
          route: 'pagina-de-acesso',
          itens: [
            'Página onde seus usuários realizam o login.'
          ]
        },
        {
          title: 'Ambientes internos',
          route: 'ambientes-internos',
          itens: [
            'Personalização do painel de gestão e do seu clube.'
          ]
        }
      ]
    }
  },

  computed: {
    ...mapGetters({
      company: COMPANY
    })
  },

  methods: {
    ...mapActions({
      callSales: COMPANY_CALL_SALES
    })
  }
}
</script>

<style lang='scss' scoped>
@import '~@/styles/reference';
.text {
  color: var(--bsicas-secondary-base, #5E6684);
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Ubuntu;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 19.2px;
  display: flex;
  align-content: center;
}
.icon {
  margin-right: 10px;
}
.container {
  max-width: 1020px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 48px;

  & > .title {
    color: var(--bsicas-secondary-base, #5E6684);
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Ubuntu;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 19.2px;
  }
  & > .customization-list {
    display: flex;
    flex-wrap: wrap;
    padding-top: 24px;
    gap: 24px;
  }
}
</style>
