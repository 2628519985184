import { render, staticRenderFns } from "./MyCoin.vue?vue&type=template&id=13d674ba&scoped=true"
import script from "./MyCoin.vue?vue&type=script&lang=js"
export * from "./MyCoin.vue?vue&type=script&lang=js"
import style0 from "./MyCoin.vue?vue&type=style&index=0&id=13d674ba&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "13d674ba",
  null
  
)

export default component.exports