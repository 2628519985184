<template>
  <div class="media-container">
    <media-header
      :search-term="searchTerm"
      :number-partners="activeBanners"
      @apply-filters="applyFilters"
      @change-search="searchTerm = $event"
      @clear-search="
        searchTerm = '';
        search('');
      "
      @search="search"
    />

    <div class="container">
      <c-spinner
        v-if="isLoading"
        size="lg"
      />
      <c-empty
        v-if="filteredMedias.length === 0 && !isLoading"
        class="empty"
        character="searching"
        btn-icon="plus"
        button-message="Nova Mídia"
        to="comunicacao-criar"
        :title="emptyText"
        :message="emptyMessage"
        :btn-active="searchTerm.length === 0 ? true : false"
      />

      <div
        v-else
        class="cards-container"
      >
        <media-card
          v-for="media in filteredMedias"
          :key="media.id"
          :media="media"
          @delete-media="confirmDelete($event, media)"
          @change-toggle-status="updateToggleStatus"
          @editMediaAnalytics="handleAnalytics('botao-editar-midia', {company_name: company.name, media_name: media.name})"
        />
      </div>
      <c-feedback
        :type="feedback.type"
        :title="feedback.title"
        :text="feedback.text"
        :icon="feedback.icon"
        @close="feedback.type = null"
      />
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import MediaCard from './MediaCard.vue'
import MediaHeader from './MediaHeader'
import CFeedback from '@/components/CComponents/CFeedback'
import { MEDIAS, DELETE_MEDIA, UPDATE_MEDIA, COMPANY } from '@/store/types'
import { mapGetters, mapActions } from 'vuex'
require('@/analytics.js')

export default {
  name: 'MediaContainer',

  components: {
    MediaHeader,
    MediaCard,
    CFeedback
  },

  data () {
    return {
      searchTerm: '',
      showOrder: false,
      isLoading: true,
      filteredMedias: [],
      activeBanners: 0,
      maxActiveBanners: 3,
      feedback: {
        type: null,
        title: '',
        text: '',
        icon: ''
      }
    }
  },

  computed: {
    ...mapGetters({
      medias: MEDIAS,
      company: COMPANY
    }),

    willHaveAnActiveBanner () {
      return this.medias.some(media => {
        const startDate = moment(media.start_date, 'DD/MM/YYYY')
        const today = moment(Date.now())
        if (startDate.isBetween(today, today.add(7, 'days'))) {
          return true
        }
        return false
      })
    },

    letUserCreateBanner () {
      if (this.activeBanners <= this.maxActiveBanners && !this.willHaveAnActiveBanner) return true
      return false
    },

    emptyText () {
      if (this.searchTerm === '') { return 'Você ainda não cadastrou nenhuma mídia!' }
      return `Não encontramos nenhum resultado correspondente a ${this.searchTerm}`
    },

    emptyMessage () {
      if (this.searchTerm === '') { return 'O que acha de adicionar sua primeira mídia do clube?' }
      return ''
    }
  },

  watch: {
    searchTerm (newVal) {
      if (newVal === '') this.filteredMedias = [ ...this.medias ]
    },
    medias: {
      handler (val) {
        this.filteredMedias = [ ...this.medias ]
        this.calculateActiveBanners()
      },
      deep: true
    }
  },

  mounted () {
    this.isLoading = true
    this.getMedias().then(() => {
      this.filteredMedias = [ ...this.medias ]
      this.isLoading = false
      this.calculateActiveBanners()
    }).catch(() => {
      this.isLoading = false
    })
    window.dataLayer.push(
      {
        event: 'mídias',
        company_name: this.company.name
      }
    )
  },

  methods: {
    ...mapActions({
      getMedias: MEDIAS,
      updateMedia: UPDATE_MEDIA,
      deleteMedia: DELETE_MEDIA
    }),

    updateToggleStatus (object) {
      if (this.activeBanners === this.maxActiveBanners && object.status === 1) {
        this.feedback = {
          type: 'error',
          title: 'Número máximo de banners ativos atingido',
          text: 'Para ativar esse banner você precisa inativar um banner ativo antes'
        }
      } else {
        this.updateMedia(object).then(() => {
          this.calculateActiveBanners()
        }).catch(() => {
          this.feedback = {
            type: 'error',
            title: 'Houve um erro ao atualizar o status do banner',
            text: 'Por favor, tente novamente mais tarde'
          }
        })
      }
      this.setHandleAnalytics(object)
    },

    search () {
      if (this.searchTerm === '') {
        this.filteredMedias = [ ...this.medias ]
        return
      }
      const medias = this.medias.filter(media => {
        return media.name.toLowerCase().includes(this.searchTerm)
      })
      this.filteredMedias = [ ...medias ]
      window.dataLayer.push(
        {
          event: 'buscar-midia',
          search_term: this.searchTerm
        }
      )
    },

    confirmDelete (media_id, media) {
      this.deleteMedia({
        banner_id: media_id
      }).then(res => {
        const medias = this.medias.filter(
          media => media.id !== media_id
        )
        this.filteredMedias = [ ...medias ]
      })
      this.handleAnalytics('botao-excluir-midia', {company_name: this.company.name, media_name: media.name})
    },

    calculateInactiveBanners () {
      return this.medias.filter(object => {
        return object.status === 0
      })
    },

    calculateActiveBanners () {
      const activeBanners = this.medias.filter(object => {
        const isFutureBanner = moment.unix(object.start_date).isAfter(Date.now())
        return object.status === 1 || isFutureBanner
      })
      this.activeBanners = activeBanners.length
      return activeBanners
    },

    applyFilters (params) {
      if (params[0]) {
        const medias = this.calculateInactiveBanners()
        this.filteredMedias = [ ...medias ]
      } else if (params[1]) {
        const medias = this.calculateActiveBanners()
        this.filteredMedias = [ ...medias ]
      } else if (params.asc) {
        this.filteredMedias = this.filteredMedias.sort((a, b) => (a.name > b.name) ? 1 : -1)
      } else if (params.desc) {
        this.filteredMedias = this.filteredMedias.sort((a, b) => (b.name > a.name) ? 1 : -1)
      } else {
        this.filteredMedias = [ ...this.medias ]
      }
      window.dataLayer.push(
        {
          event: 'filtrar-midia',
          company_name: this.company.name
        }
      )
    },

    setHandleAnalytics (object) {
      if (object.status === 1) {
        this.handleAnalytics('toggle-ativar-midia', {
          company_name: this.company.name,
          media_name: object.media.name
        })
      } else {
        this.handleAnalytics('toggle-desativar-midia', {
          company_name: this.company.name,
          media_name: object.media.name
        })
      }
    },

    handleAnalytics (event, data) {
      window.dataLayer.push(
        {
          event: event,
          ...data
        }
      )
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/styles/reference';

.media-container {
  max-width: 1020px;
  margin: 30px auto;

  > .container {
    margin-top: 30px;

    > .cards-container {
      display: grid;
      grid-gap: 20px;
      grid-template-columns: 1fr 1fr;
      @include mobile {
        padding: 0 15px;
        grid-template-columns: 1fr;
      }
    }
  }
}
</style>
