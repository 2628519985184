<template>
  <div class="campaign-information-header">
    <div class="campaign-info">
      <div class="info">
        <span class="text bold">Tipo de resgate:</span>
        <span class="text">{{ eventName }}</span>
      </div>
      <div
        class="info"
        v-if="showVouchers"
      >
        <span class="text bold">Códigos disponíveis:</span>
        <span class="text">{{ vouchersCount }}</span>
      </div>
    </div>
    <div
      class="campaign-actions"
      v-if="isExclusive"
    >
      <c-button
        v-if="isExclusive"
        data-cy="edit-campaign-button"
        class="btn-action"
        icon="pencil"
        size="lg"
        @click="$router.push({
          name: 'parceiros-slug-campanhas-campaign-editar',
          params: $route.params
        })"
      >
        {{ isDesktop ? 'Editar' : '' }}
      </c-button>
    </div>
  </div>
</template>

<script>
const eventTypes =
{ 'uniqueCode': 'Código único',
  'voucher': 'Voucher',
  'payment': 'Compra direta',
  'letter': 'Carta vínculo',
  'online': 'Online',
  'info': 'Info'
}

export default {
  props: {
    eventType: {
      type: String,
      required: true
    },
    vouchersCount: {
      type: Number,
      default: 0
    },
    isExclusive: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    eventName () {
      return eventTypes[this.eventType]
    },
    showVouchers () {
      return this.eventType === 'uniqueCode' || this.eventType === 'payment'
    }
  }
}
</script>

<style lang="scss">
@import '~@/styles/reference';

.campaign-information-header {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;

  padding-bottom: 20px;
  margin: 40px 10px 20px 10px;

  border-bottom: 2px solid $border-color;

  & > .campaign-info {
    display: flex;
    margin-right: auto;

    & > .info {
      &:not(:last-child) { margin-right: 60px; }

      & > .text {
        font-size: 16px;
        color: $text-color;

        &.bold {
          font-weight: 600;
          color: $title-color;
          margin-right: 5px;
        }
      }
    }
  }

  & > .campaign-actions {
    & >.btn-action {
      @include mobile {
        margin: 0 auto;
        padding: 0 0 0 5px;
      }
    }
  }
}
</style>
