<template>
  <c-modal
    v-bind="$attrs"
    v-on="$listeners"
    name="confirm modal"
  >
    <div class="modal">
      <div class="modal-container">
        <div class="title">
          <k-icon
            class="icon"
            icon="warning2"
            size="20"
          />Atenção!
        </div>
        <span class="sub">As informações inseridas não poderão ser alteradas</span>
        <div class="check">
          <input
            data-cy="warn-checkbox"
            v-model="confirmUpdate"
            class="checkbox"
            type="checkbox"
            checked="checked"
          />Estou ciente e tenho certeza das informações inseridas
        </div>
      </div>
      <div class="actions">
        <c-button
          size="lg"
          class="btn-action -cancel"
          @click="$emit('close')"
        >
          Cancelar
        </c-button>
        <c-button
          size="lg"
          primary
          :disabled="!confirmUpdate"
          class="btn-action -confirm"
          @click="$emit('confirm')"
        >
          Salvar
        </c-button>
      </div>
    </div>
  </c-modal>
</template>

<script>
import CModal from '@/components/CComponents/CModal'
import { KIcon } from 'kaledo-components'

export default {
  components: { CModal, KIcon },
  data () {
    return {
      confirmUpdate: false
    }
  }
}
</script>

<style lang="scss">
@import '~@/styles/reference';
.modal {
  padding: 40px;
}
.modal-container {
  background-color: #FFF !important;
  z-index: 30 !important;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  & > .title {
    display: flex;
    color: $neutral-dark-blue;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Nunito Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
    margin-bottom: 8px;

    & > .icon {
      color: $feedback-warning-100;
      padding-right: 8px;
    }
  }
  & > .sub {
    color: $neutral-dark-blue;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Nunito Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 140%;
  }
  & > .check {
    display: flex;
    align-items: center;
    margin: 40px 0;
    color: $neutral-gray-200;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Nunito Sans;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%; /* 14.4px */
    letter-spacing: -0.122px;
    & > .checkbox {
      margin-right: 8px;
    }
  }
}
.actions {
    display: flex;
    justify-content: center;
    & > .btn-action {
      margin: 0 12px;
    }
  }
</style>
