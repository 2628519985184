<template>
  <k-icon
    class="borded-info-button"
    icon="question"
    @click.native="$emit('btn-click')"
  />
</template>

<script>
import { KIcon } from 'kaledo-components'

export default {
  components: {
    KIcon
  }
}
</script>

<style lang="scss" scoped>
.borded-info-button {
  font-size: 20px;
  width: 40px;
  height: 40px;
  border: 1px solid rgba(94, 102, 132, 1);
  border-radius: 50px;
  margin-left: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all .1s ease;

  &:hover {
    border: 3px solid rgba(94, 102, 132, 1);
    border-radius: 50px;
  }
}
</style>
