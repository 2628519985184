<template>
  <nav class="c-menu">
    <c-menu-item
      class="item"
      :item="homeItem"
      v-bind="$attrs"
      @click.native="handleClickItem(homeItem)"
    />
    <dropdown-item
      v-for="(item, index) in dropdownItems"
      :id="`menu-item-${index}`"
      :key="item.path.name"
      :item="item"
      :dropdown-options="dropdownMenu"
    />
    <c-menu-item
      class="item"
      v-for="(item, index) in itemsFiltered"
      :id="`menu-item-${index+dropdownItems.length}`"
      :key="`c-menu-${item.path.name}`"
      :item="item"
      v-bind="$attrs"
      @click.native="handleClickItem(item)"
    />
  </nav>
</template>

<script>
import CMenuItem from './CMenuItem'
import DropdownItem from '@/components/CComponents/CDropdownItem.vue'
import { dropdownMenu } from '@/content/menuItems.js'
import { mapGetters } from 'vuex'
import { COMPANY } from '@/store/types'

export default {
  components: { CMenuItem, DropdownItem },
  props: {
    groupOptions: {
      type: Object,
      default: () => ({})
    },
    items: {
      type: Array,
      default: () => []
    },
    permissions: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    ...mapGetters({
      company: COMPANY
    }),
    homeItem () {
      return {
        name: 'Início',
        icon: 'home',
        path: { name: 'atalhos' },
        dropdown: false
      }
    },
    itemsFiltered () {
      return this.items.filter(item => !item.dropdown)
    },
    dropdownItems () {
      return this.items.filter(item => item.dropdown)
    },
    dropdownMenu () {
      return dropdownMenu(this.permissions)
    }
  },
  methods: {
    handleClickItem (item) {
      if (item.analyticsEvent) {
        window.dataLayer.push({
          event: item.analyticsEvent,
          company_name: this.company.slug
        })
      }
      this.$emit('click-item', item)
    }
  }
}
</script>

<style lang="scss">
@import '~@/styles/reference';

$c-menu-background-color: transparent !default;
$c-menu-padding: outer-base(1) !default;

.c-menu {
  display: flex;
  flex: 0 100%;
  width: 100%;
  align-items: flex-end;
  background-color: $c-menu-background-color;
  padding: $c-menu-padding;
  box-sizing: border-box;

  & > .group {
    @extend %c-menu-group;

    & + .group { border-top: 1px solid #e8e9f0; }

    & > .title {
      text-transform: uppercase;
      margin: 0 0 inner-base() 0;
    }
  }
}

%c-menu-group {
  position: relative;
  padding-top: inner-base();
  padding-bottom: inner-base();

  & > .items { @extend %c-menu-items; }
}

%c-menu-items {
  display: flex;
    display: block;
}
</style>
