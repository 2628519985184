<template>
  <div :class="classes">
    <partner-tag-curve
      class="curve -left"
      reverse
    />
    <partner-tag-curve
      class="curve -right"
      :is-exclusive="isExclusive"
    />

    <section
      class="exclusive"
      v-if="isExclusive"
    >
      <partner-tag-curve
        class="curve -center"
        is-exclusive
        reverse
      />

      <figure class="-exclusive tag">
        <figcaption>Exclusivo</figcaption>
      </figure>
    </section>
  </div>
</template>

<script>
import PartnerTagCurve from '@/components/Partners/PartnerTagCurve'

export default {
  components: { PartnerTagCurve },
  props: {
    isExclusive: Boolean
  },
  computed: {
    classes () {
      return [
        'partner-tag', {
          '-only-discount': !this.isExclusive
        }]
    }
  }
}
</script>

<style lang="scss">
@import '~@/styles/reference';

$partner-tag-height: 26px;

$partner-tag-size: auto $partner-tag-height;

.partner-tag {
  @include size($partner-tag-size);
  position: relative;
  font-family: $base-font-family;
  font-weight: 900;
  display: flex;
  align-items: stretch;
  justify-content: center;

  & > .exclusive {
    display: flex;
    align-items: stretch;
    justify-content: center;
    position: relative;
    right: 38px;
  }

  & > .tag,
  & > .exclusive > .tag {
    text-align: center;
    color: #fff;
    line-height: 26px;
    white-space: nowrap;

    display: flex;
    align-items: center;
    justify-content: center;

    &.-discount {
      background-color: $primary-color-placeholder;
      background-color: var(--color-primary);
    }

    &.-exclusive {
      background-color: $base-color;
      font-size: 8.5px;
      z-index: 1;
      width: 76px;
      text-transform: uppercase;
      letter-spacing: 0.5px;
    }
  }

  & > .curve,
  & > .exclusive > .curve {
    height: $partner-tag-height;

    &:not(.-center) {
      width: 48px;
      position: absolute;
      top: 0;
      z-index: -1;
    }

    &.-left { left: -37px; }

    &.-right { right: -37px; }

    &.-center {
      position: relative;
      left: 5px;
    }
  }

  &:not(.-only-discount) {
    left: 94px;

    & > .tag.-discount {
      padding-right: 20px;
    }

    & > .curve.-right { right: 1px; }
  }
}
</style>
