<template>
  <div class="sheets-mode">
    <instruction
      :step="2"
      text="Baixe a planilha e insira o arquivo .csv"
    />
    <div class="upload-options">
      <c-input
        data-cy="users-import-input"
        type="file"
        class="input-file"
        :class="uploadedFileData ? '-change' : ''"
        placeholder="Selecionar arquivo .CSV"
        :exts="['csv']"
        :text="fileButtonName"
        v-on="$listeners"
        @change="onFileChange"
      />

      <c-button
        data-cy="users-import-button"
        v-if="uploadedFileData"
        primary
        class="send-btn"
        size="lg"
        @click="formatData(uploadedFileData)"
        :disabled="disableSendButton"
      >
        Enviar
      </c-button>

      <c-button
        data-cy="users-import-model-download-button"
        external
        class="download-btn"
        icon="arrow-down-circle"
        size="lg"
        to="https://kaledo-public.s3.amazonaws.com/files/modelo-importacao-engajamento.csv"
        target="_blank"
      >
        Baixar planilha modelo
      </c-button>
    </div>
    <div class="content">
      <transition name="fade">
        <c-spinner v-if="loading" />
        <div v-else>
          <c-chip
            data-cy="users-count-chip"
            text="Usuários inseridos:"
            :value="rows.length"
          />
          <users-table
            data-cy="sheets-mode-users-table"
            :paginate="paginate"
            :rows="modifiedRows"
            :selected-rows="allSelectedRows"
            :use-cpf-mask="true"
            @select="selectRow"
            @select-all-rows="selectAllRows"
            @search="searchTable"
            @remove-items="openDeleteModal = true"
            @update-paginate="updatePaginate"
          />
        </div>
      </transition>
      <delete-users-modal
        :is-opened="openDeleteModal"
        :users="allSelectedRows"
        @close="openDeleteModal = false"
        @delete-users="removeUsers"
      />
      <sheets-instruction-modal
        :is-opened="openSheetsInstructionModal"
        @close="openSheetsInstructionModal = false, $emit('change-first-access')"
      />
    </div>
  </div>
</template>

<script>
import { isCPF } from '@/modules/validate/validators.js'
import Instruction from '@/components/General/Instruction.vue'
import CChip from '@/components/CComponents/CChip.vue'
import UsersTable from '@/components/General/tables/UsersTable'
import UsersTableMixin from './users-table-mixin.js'
import DeleteUsersModal from '@/components/General/modals/DeleteUsersModal.vue'
import SheetsInstructionModal from '@/components/General/modals/SheetsInstructionModal.vue'

export default {
  name: 'SheetsMode',
  components: {
    Instruction,
    CChip,
    UsersTable,
    DeleteUsersModal,
    SheetsInstructionModal
  },
  mixins: [UsersTableMixin],
  props: {
    openModal: Boolean,
    mode: String,
    dataRows: {
      type: Array,
      default () { return [] }
    }
  },
  data () {
    return {
      openDeleteModal: false,
      openSheetsInstructionModal: false,
      uploadedFileData: null,
      sentFileData: null,
      fileName: '',
      loading: false,
      deleteRows: [],
      selectedRows: [],
      filteredRows: [],
      modifiedRows: [],
      rows: []
    }
  },
  computed: {
    fileButtonName () {
      if (this.loading) return 'Carregando'
      return this.uploadedFileData ? 'Trocar Arquivo' : 'Selecionar Arquivo'
    },
    disableSendButton () {
      const equalsFiles = this.sentFileData === this.uploadedFileData
      return (this.sentFileData !== null) && (this.uploadedFileData !== null) && equalsFiles
    }
  },
  watch: {
    openModal () {
      this.openSheetsInstructionModal = true
    }
  },
  created () {
    this.modifiedRows = this.emptyrows
  },
  mounted () {
    if (this.dataRows.length > 0) {
      this.openSheetsInstructionModal = false
      this.rows = this.dataRows
      this.modifiedRows = this.dataRows
      this.totalUsers = this.dataRows.length
      const to = this.totalUsers > 5 ? 5 : this.totalUsers
      this.paginate = {
        ...this.paginate,
        per_page: 5,
        to,
        total: this.totalUsers
      }
    }
  },
  methods: {
    async onFileChange (event) {
      if (!event || !event[0] || !event[0].file) return
      this.loading = true
      this.fileData = event[0].file
      const reader = new FileReader()
      reader.onload = (e) => {
        this.uploadedFileData = e.target.result
      }
      await reader.readAsText(event[0].file)
      this.fileName = event[0].name
      this.loading = false
    },
    formatData (data) {
      const initialArray = data.split('\n')
      const jsonObj = []
      let invalid
      const headers = initialArray[0].split(',')
      if (headers.length < 2) {
        this.feedback({
          type: 'error',
          title: 'Número incorreto de headers',
          text: 'verifique planilha modelo'
        })
        return
      }

      const regexList = [/\bcpf\b/i, /\bemail\b/i]
      headers.forEach(header => {
        const isMatch = regexList.some(rx => rx.test(header))
        if (!isMatch) invalid = true
      })
      if (invalid) {
        this.feedback({
          type: 'error',
          title: 'Dados incorretos, verifique a planilha modelo',
          text: ''
        })
        this.loading = false
        return
      }
      for (let i = 1; i < initialArray.length; i++) {
        if (initialArray[i] === '') { break }
        const arrayData = initialArray[i].split(',')
        const obj = {}
        for (let j = 0; j < arrayData.length; j++) {
          let header = headers[j]
          let data = arrayData[j].trim()

          if (headers[j].toLowerCase() === 'cpf') {
            header = 'document'
            const noMaskDocument = arrayData[j].trim().replaceAll('.', '').replaceAll('-', '')
            data = this.formatCpf(noMaskDocument)
          }
          obj[header.trim()] = data
        }
        jsonObj.push(obj)
      }
      const finalArray = jsonObj.map(obj => {
        return {
          ...obj,
          checked: false,
          id: obj.document
        }
      })
      const finalArrayFinal = this.removeDuplicates(finalArray)
      this.generateData(finalArrayFinal)
      this.sentFileData = this.uploadedFileData
    },
    formatCpf (cpf) {
      if (cpf.length === 11) return cpf
      let finalCpf = cpf
      const totalZeros = 11 - cpf.length
      for (let i = 0; i < totalZeros; i++) {
        finalCpf = '0' + finalCpf
      }
      return finalCpf
    },
    userAlreadyThere (user, list) {
      if (this.modifiedRows.length === 0) return false
      const result = list.find(object => {
        return object.document === user.document || object.email === user.email
      })

      if (result !== undefined) {
        return true
      }
      return false
    },
    isEmailValid (email) {
      return email.length > 0 && !!(email.match(/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/))
    },
    removeDuplicates (list) {
      const final_list = []
      list.forEach(obj => {
        if (isCPF(obj.document) && this.isEmailValid(obj.email) && !this.userAlreadyThere(obj, final_list)) {
          final_list.push(obj)
        }
      })
      return final_list
    }
  }
}
</script>

<style lang='scss' scoped>
@import '~@/styles/reference';

.sheets-mode {
  .upload-options {
    margin-top: 24px;
    margin-bottom: 48px;
    display: flex;
    flex-wrap: wrap;
    .input-file {
      max-width: 528px;
      width: 100%;
      margin-right: 15px;

      :deep .label-file {
        .placeholder, .button-file{
          height: 40px;
        }

        &:hover {
          .button-file {
            transition: background-color .3s ease;
            background: rgba($primary-color-placeholder, .8);
            background: var(--color-primary-8);
          }
        }
      }

      &.-change {
        :deep .placeholder {
          color: #0A0D1A;
        }
        :deep .label-file {
          .button-file {
            background: #FFFFFF;
            border: 1px solid #5E6684;

            .text { color: #5E6684 !important; }
          }
          &:hover .button-file {
            transition: all .1s ease;
            box-shadow: 0 0 0 2px var(--color-secondary);
          }
        }
      }

      @include mobile {
        max-width: 100%;
      }
    }
    .send-btn {
      width: 100%;
      max-width: 168px;
    }
    .download-btn {
      margin-left: 40px;
      max-width: 236px;
      width: 100%;
      background: #FFFFFF;
      border: 1px solid #5E6684;
      border: 1px solid var(--color-secondary);

      @include mobile {
        margin-left: 0px;
        margin-top: 10px;
        max-width: 100%;
      }
    }
  }
}
</style>
