<template>
  <div
    class="campaign-list-wrapper"
    data-cy="campaign-list-wrapper"
  >
    <div class="campaign-controls">
      <c-title data-cy="campaigns-list-title" icon="crown">
        Ofertas
      </c-title>
      <c-button
        v-if="isExclusive"
        data-cy="new-campaign-button"
        primary
        size="lg"
        icon-size="sm"
        class="btn"
        icon="plus"
        @click="createCampaign()"
      >
        Nova oferta
      </c-button>
    </div>

    <div v-if="campaigns.length !== 0">
      <transition-group
        tag="div"
        class="campaign-list"
        name="campaign-list"
        appear
      >
        <campaign-card
          class="item"
          v-for="campaign in campaigns"
          :id="campaign.id"
          :key="campaign.slug"
          :slug="campaign.slug"
          :image="campaign.partner && campaign.partner.logo"
          :title="campaign.title"
          :status="campaign.status"
          :partner="campaign.partner && campaign.partner.name"

          :is-cashback="campaign.eventData.is_cashback"
          :cashback-value="campaign.eventData.cashback_value | toNumber"
          :cashback-symbol="campaign.eventData.cashback_symbol"

          :value="campaign.value | toNumber"
          :symbol="campaign.symbol"
          :expiry-date="campaign.end_date"
          :is-exclusive="!!(campaign.partner && campaign.partner.company_id)"
          :is-highlighted="!!campaign.is_highlighted"
          :is-future="isFuture(campaign.start_date * 1000)"
          @toggle-campaign-status="$emit('toggle-campaign-status', $event)"
          @click.native="goToCampaign(campaign)"
          @copy="$emit('copy', $event)"
        />
      </transition-group>

      <div class="campaign-pagination">
        <c-spinner
          v-if="paginationLoading"
          size="lg"
        />
        <c-button
          @click="$emit('paginate-list')"
          v-else-if="showPagination"
          size="lg"
        >
          Ver Mais
        </c-button>
      </div>
    </div>

    <c-empty
      v-else
      btn-active
      class="empty"
      title="Você ainda não cadastrou nenhuma oferta exclusiva!"
      character="searching"
      message="O que acha de adicionar a primeira oferta exclusiva do clube?"
      to="parceiros-slug-campanhas-criar"
      button-message="Nova oferta"
      btn-icon="plus"
      btn-icon-size="sm"
    />
  </div>
</template>

<script>

import CampaignCard from '@/components/Campaign/Listing/CampaignCard'

import isFuture from '@/modules/isFuture'

import { mapGetters } from 'vuex'
import * as types from '@/store/types'

export default {
  components: { CampaignCard },
  filters: {
    toNumber (value) {
      if (typeof value === 'number') return value
      if (typeof value === 'string') return +value.replace(/\D/g, '')

      return value
    }
  },
  props: {
    campaigns: {
      type: Array,
      required: true
    },
    pagination: {
      type: Object,
      required: true
    },
    paginationLoading: {
      type: Boolean,
      default: false
    },
    isExclusive: Boolean
  },
  data () {
    return {
      displayStatusConfirmation: false
    }
  },
  computed: {
    ...mapGetters({ company: types.COMPANY, partner: types.PARTNER }),
    showPagination () {
      return this.campaigns.length < this.pagination.total
    }
  },
  methods: {
    isFuture,
    goToCampaign (campaign) {
      this.handleAnalytics('visualizar-oferta', {
        company_name: this.company.name,
        partner_name: this.partner.name,
        partner_slug: this.partner.slug,
        campaign_name: campaign.name,
        campaign_slug: campaign.slug
      })
      this.$router.push({
        name: 'parceiros-slug-campanhas-campaign',
        params: { ...this.$route.params, campaign: campaign.slug }
      })
    },
    createCampaign () {
      this.handleAnalytics('botao-nova-oferta', {
        company_name: this.company.name,
        partner_name: this.partner.name,
        partner_slug: this.partner.slug
      })
      this.$router.push({ name: 'parceiros-slug-campanhas-criar' })
    },
    handleAnalytics (event, data) {
      window.dataLayer.push(
        {
          event: event,
          ...data
        }
      )
    }
  }
}
</script>

<style lang="scss">
@import '~@/styles/reference';

.campaign-controls {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
  margin: 30px 0 0 0;

  @include desktop { @include container; }
}

.campaign-list {
  padding-top: 5px;
  @include list;

  @media screen and (min-width: 769px) {
    flex-wrap: wrap;
    flex-direction: row;
    align-items: flex-start;
    padding: 20px 10px 10px;

    & > .item {
      flex: { basis: calc(50% - 5px); grow: 0; shrink: 0; }
      margin: { top: 0; right: 0; left: 0; }
    }

    & > .item:nth-child(even) { margin-left: 10px; }
  }

  @media screen and (min-width: 993px) {
    & > .item { flex: calc(100% / 3 - 20px / 3) 0 0; }

    & > .item:nth-child(even) { margin-left: 0; } // Overwrite

    & > .item:nth-child(3n + 2),
    & > .item:nth-child(3n + 3) { margin-left: 10px; }
  }
}

.campaign-pagination {
  display: flex;
  justify-content: center;
  margin-bottom: 30px;

  @include desktop { @include container; }
}

// List Transition
// ---------------

.campaign-list > .item {
  transition: transform .2s ease-out,
              opacity .3s ease-in;
}

.campaign-list-enter,
.campaign-list-leave-to {
  opacity: 0;
  transform: translateY(100%);
}

.campaign-list-leave-active {
  position: absolute;
}
</style>
