<template>
  <club-activation />
</template>

<script>
import ClubActivation from '@/containers/Login/ClubActivation'

export default {
  components: {
    ClubActivation
  },

  layout: 'unauthenticated',

  middleware: 'checkauth'
}
</script>

