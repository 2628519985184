<template>
  <c-modal
    v-bind="$attrs"
    v-on="$listeners"
    name="invoice-email-modal"
    class="invoice-email-modal"
    hide-cancel
  >
    <c-title
      data-cy="invoice-info-modal-title"
      class="title"
      size="3"
    >
      Boleto bancário
    </c-title>
    <div class="content">
      <p class="info">
        <strong>Data de Vencimento:</strong> Dia posterior à conclusão do pedido.
      </p>
      <p class="info">
        Caso não seja pago até a data informada, o pedido será automaticamente cancelado.
      </p>
      <div
        class="warning-info"
      >
        <p>
          As recompensas serão enviadas na data agendada do pedido.
        </p>
      </div>
      <div class="actions">
        <c-button
          data-cy="invoice-modal-action-button"
          class="button"
          size="lg"
          primary
          @click.native="$emit('close')"
        >
          OK
        </c-button>
      </div>
    </div>
  </c-modal>
</template>

<script>
import CModal from '@/components/CComponents/CModal'

export default {
  components: { CModal },
  data () {
    return {
      email: '',
      sendUserEmail: false,
      sendOtherEmail: false
    }
  },
  computed: {
    disableButton () {
      const isEmailValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(this.email.toLowerCase())
      return (!this.sendUserEmail && !this.sendOtherEmail) || !isEmailValid
    }
  }
}
</script>

<style lang="scss">
@import '~@/styles/reference';

.invoice-email-modal {
  > .c-modal > .content {
    display: flex;
    align-items: center;
    flex-direction: column;

    > .title {
      margin-top: 0px !important;
      width: 100%;
      text-align: left;
      font-size: 16px;
      margin-top: 30px;
      margin-bottom: 20px;
      color: #202950;
    }
    > .content {
      justify-content: flex-start;
      align-items: center;
      flex-direction: column;

      .info {
        width: 100%;
        text-align: left;
        font-size: 16px;
        color: #202950;
        margin: 15px 0;
      }

      .warning-info {
        width: 100%;
        padding: 4px 8px;
        background: rgba(255, 195, 48, 0.3);
        font-size: 12px;
        color: #202950;
      }

      > .actions {
        margin-top: 36px;
        width: 100%;
        display: flex;
        justify-content: center;

        .button {
          width: 84px;
        }
      }
    }
  }
}
</style>
