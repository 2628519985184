<template>
  <div class="new-order">
    <step-count
      class="step-count"
      :current-step="step"
      :steps="steps"
      @step-click="handleUpdateStep('prev', $event)"
    />
    <div class="step-div">
      <step-one
        v-if="step === 1"
        v-model="stepsData.first"
        :first-access="firstStepsAccess"
      />
      <step-two
        v-if="step === 2"
        :table-data="tableData"
        v-model="stepsData.second"
      />
      <step-three
        v-if="step === 3"
        :step-value="stepsData.third"
        v-model="stepsData.third"
      />

      <step-four
        v-if="step === 4"
        :title="title"
        :users="stepsData.second.users"
        :total="stepsData.second.value"
        :loading="loading"
        :step-value="stepsData.fourth"
        v-model="stepsData.fourth"
        @create-order="createOrder"
        @cancel="openCancelModal = true"
      />
    </div>
    <div
      class="footer"
      v-if="step !== 4"
    >
      <c-button
        data-cy="cancel-order-button"
        class="button cancel-button"
        @click="openCancelModal = true"
      >
        Cancelar
      </c-button>
      <c-button
        data-cy="next-step-button"
        primary
        class="button"
        icon="chevron-right"
        :disabled="!enableNextStep"
        @click="handleUpdateStep('next')"
      >
        Continuar
      </c-button>
    </div>
    <order-name-modal
      :is-opened="openOrderNameModal"
      @close="openOrderNameModal = false"
      @confirm="orderName => title = orderName"
    />
    <c-confirm-modal
      :btn-props="{ primary: true }"
      confirm-text="Cancelar pedido"
      character="embarrassed"
      title="Tem certeza que deseja cancelar a criação do pedido?"
      message="Se cancelar, todas as informações já inseridas não serão processadas."
      :is-opened="openCancelModal"
      @close="openCancelModal = false"
      @confirm="confirmCancel"
    />
  </div>
</template>

<script>
import { COMPANY, FEEDBACK, DISTRIBUTION_STEP, COMPANY_BALANCE } from '@/store/types'
import { mapGetters, mapMutations, mapActions } from 'vuex'
import StepCount from '@/components/General/StepCount'
import StepOne from './NewSteps/StepOne'
import StepTwo from './NewSteps/StepTwo'
import StepThree from './NewSteps/StepThree'
import StepFour from './NewSteps/StepFour'
import OrderNameModal from '@/components/Engagement/modals/OrderNameModal'
import CConfirmModal from '@/components/CComponents/CConfirmModal'

export default {
  components: {
    StepCount,
    StepOne,
    StepTwo,
    StepThree,
    StepFour,
    OrderNameModal,
    CConfirmModal
  },
  props: {
    loading: Boolean
  },
  data () {
    return {
      loadingOrderCreation: false,
      openOrderNameModal: true,
      openCancelModal: false,
      currentStepIsComplited: false,
      firstStepsAccess: true,
      steps: [
        {
          number: 1,
          text: 'Usuários'
        },
        {
          number: 2,
          text: 'Moedas'
        },
        {
          number: 3,
          text: 'Comunicação'
        },
        {
          number: 4,
          text: 'Conclusão'
        }
      ],
      title: '',
      stepsData: {
        first: {
          uploaded_users: []
        },
        second: {
          value: null,
          users: []
        },
        third: {
          communication_title: '',
          communication_description: ''
        },
        fourth: {
          release_date: null,
          use_balance: false
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      company: COMPANY,
      step: DISTRIBUTION_STEP
    }),
    enableNextStep () {
      let enable = true
      Object.keys(this.stepsData).forEach((key, index) => {
        if (this.step === (index + 1)) { enable = this.insideObject(key) }
      })
      return enable
    },
    cancelButtonText () {
      if (this.step === 1) return 'cancelar'
      else return 'voltar'
    },
    tableData () {
      return {
        cols: [
          {
            align: 'center',
            label: 'cpf'
          },
          {
            align: 'center',
            label: 'email'
          },
          {
            align: 'center',
            label: 'quantidade de moedas'
          }
        ],
        rows: this.stepsData.first.uploaded_users
      }
    },
    formatedSecondStepData () {
      const formatedUsers = !this.stepsData.second.users
        ? []
        : this.stepsData.second.users.map(function (user) {
          return {
            document: user.document,
            email: user.email,
            value: user.value * 100
          }
        })
      return {
        value: this.stepsData.second.value * 100,
        users: formatedUsers
      }
    }
  },
  watch: {
    'stepsData.first' () {
      if (this.stepsData.second && this.stepsData.second.users && this.stepsData.second.users.length > 0) {
        const updatedUsers = []
        this.stepsData.second.users.forEach(secondStepUser => {
          const findedUser = this.stepsData.first.uploaded_users.find(firstStepUser => firstStepUser.id === secondStepUser.id)
          if (findedUser) { updatedUsers.push(findedUser) }
        })
        this.stepsData.first.uploaded_users.forEach(firstStepUser => {
          const findedUser = this.stepsData.second.users.find(secondStepUser => secondStepUser.id === firstStepUser.id)
          if (typeof findedUser === 'undefined') { updatedUsers.push(firstStepUser) }
        })

        this.stepsData.second.users = updatedUsers
        this.stepsData.second.value = this.sumAllKeyValues('value', updatedUsers)
      }
    },
    'stepsData.second' () {
      this.stepsData.first.uploaded_users =
        this.stepsData.second.users
          ? this.stepsData.second.users.map(user => {
            return {
              ...user,
              value: user.value ? user.value : 0
            }
          })
          : this.stepsData.first.uploaded_users
    }
  },
  async mounted () {
    await this.getBalance()
    this.updateStep(1)
  },
  methods: {
    ...mapMutations({
      updateStep: DISTRIBUTION_STEP
    }),
    ...mapActions({
      feedback: FEEDBACK,
      getBalance: COMPANY_BALANCE
    }),
    handleAnalytics (event, data) {
      window.dataLayer.push(
        {
          event,
          ...data
        }
      )
    },
    sumAllKeyValues (key, objArray) {
      if (typeof objArray === 'undefined') return null

      let sum = 0
      objArray.forEach(obj => {
        if (obj.hasOwnProperty(key)) {
          sum += parseInt(obj.value)
        }
      })
      return sum
    },
    confirmCancel () {
      window.dataLayer.push({
        event: 'pontos-pedido-abandonar',
        company_name: this.company.slug
      })
      this.feedback({
        type: 'warning',
        title: 'Pedido cancelado'
      })
      this.$router.push({ name: 'engajamento-distribuicao' })
    },
    insideObject (obj) {
      const { [obj]: stepsDataObj } = this.stepsData

      const hasValidValue = value => !(value === 0 || value === '' || value === null)

      for (const key in stepsDataObj) {
        const value = stepsDataObj[key]

        if (!hasValidValue(value)) {
          return false
        }

        if (Array.isArray(value)) {
          if (value.length === 0) {
            return false
          }
          if (obj === 'second' && value.some(object => !hasValidValue(object.value) || !object.hasOwnProperty('value'))) {
            return false
          }
        }
      }

      return true
    },
    handleUpdateStep (func, stepNumber) {
      if (func === 'next') {
        if (this.step !== 4) {
          this.handleAnalytics(`pontos-pedido-passo${this.step}`, {
            company_name: this.company.slug
          })
          this.updateStep(this.step + 1)
        } else {
          this.createOrder()
        }
      } else {
        if (this.step !== 1) {
          this.firstStepsAccess = false
          this.updateStep(stepNumber)
        }
      }
    },
    async createOrder () {
      const finalOrder = {
        title: this.title,
        ...this.formatedSecondStepData,
        ...this.stepsData.third,
        ...this.stepsData.fourth
      }
      if (!finalOrder.use_balance) delete finalOrder.use_balance
      this.$emit('create-order', finalOrder)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/styles/reference';

.new-order {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 1020px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;

  > .step-count {
    margin-top: 30px;
  }

  > .step-div {
    width: 100%;
    height: 100%;
    display: block;
  }

  & >.footer {
    width: 100%;
    justify-content: flex-end;
    display: flex;
    padding-top: 25px;

    & >.button {
      margin: 0 5px;
      width: 180px;
      height: 40px;
      border-radius: 30px;

      &.cancel-button {
        background: #FFFFFF;
        border: 1px solid var(--color-secondary);
      }
    }
  }
}
</style>
