<template>
  <c-modal
    v-bind="$attrs"
    v-on="$listeners"
    class="new-coin-modal"
    name="new-coin-modal"
    :show-close="false"
  >
    <div
      class="new-coin-modal-body"
      data-cy="new-coin-modal-body"
    >
      <div class="header">
        <c-title class="title">
          Minha moeda
        </c-title>
        <c-button
          size="lg"
          icon="cross"
          class="close"
          @click="closeModal"
          data-cy="close-c-modal"
        />
      </div>

      <div class="content">
        <instruction
          :step="1"
          text="Dê um nome para a sua moeda"
        />
        <div class="new-coin-input-container">
          <c-input
            data-cy="new-coin-name"
            class="new-coin-name"
            v-model="coin.program_name"
            placeholder="Nome da moeda"
            name="new-coin-name"
            maxlength="18"
            required
            data-vv-delay="10"
            v-validate="'required'"
            :feedback-show="errors.has('new-coin-name')"
            :feedback-message="errors.first('new-coin-name')"
          />
          <transition name="fade">
            <span
              v-if="coin.program_name.length !== 0"
              class="subtext"
              data-cy="new-coin-max-characters"
            >
              Max 18 caracteres
            </span>
          </transition>
        </div>
        <instruction
          :step="2"
          text="Carregue uma imagem que represente a sua moeda"
        />
        <div class="new-coin-input-container">
          <upload-image
            :is-edit="isEdit"
            @change="handleImage"
          />
          <span class="sub">
            Formato: <b>png</b><br />
            Tamanho: <b>24x24px</b>
          </span>
        </div>
      </div>
    </div>

    <div class="new-coin-modal-actions">
      <c-button
        class="new-coin-button cancel-button"
        @click="closeModal"
      >
        Cancelar
      </c-button>
      <c-button
        data-cy="new-coin-confirm-button"
        class="new-coin-button confirm-button"
        primary
        :loading="loading"
        :disabled="isDisabled"
        @click="handleConfirm"
      >
        Confirmar
      </c-button>
    </div>
  </c-modal>
</template>

<script>
import CModal from '@/components/CComponents/CModal'
import Instruction from '@/components/General/Instruction.vue'
import UploadImage from '@/components/General/UploadImage.vue'

export default {
  name: 'NewCoinModal',
  components: {
    CModal,
    Instruction,
    UploadImage
  },
  props: {
    coinData: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      isEdit: false,
      modifiedData: false,
      loading: false,
      coin: {
        program_name: '',
        program_image: []
      }
    }
  },
  computed: {
    isDisabled () {
      if (this.loading) return true

      const hasNoName = this.coin.program_name.length === 0
      const hasNoFile = (this.coin.program_image.length === 0 && !this.isEdit)
      const hasEditImage = this.isEdit && this.modifiedData && this.coin.program_image.length === 0 // false
      const hasNotEditName = this.isEdit && (this.coinData && this.coinData.program_name) && this.coinData.program_name === this.coin.program_name && !this.modifiedData

      if (hasNoName || hasNoFile || hasEditImage || hasNotEditName) return true
      return false
    }
  },
  mounted () {
    this.checkData()
  },
  methods: {
    closeModal () {
      this.modifiedData = false
      this.loading = false
      this.setData()
      this.$emit('close')
    },
    handleImage (files) {
      this.modifiedData = true
      this.coin.program_image = files
    },
    setData () {
      this.$validator.pause()
      this.$nextTick(() => {
        this.coin = {
          ...this.coinData,
          program_image: []
        }
        this.$validator.errors.clear()
        this.$validator.fields.items.forEach(field => field.reset())
        this.$validator.fields.items.forEach(field => this.errors.remove(field))
        this.$validator.resume()
      })
    },
    checkData () {
      const hasProgramImage = this.coinData.program_image && this.coinData.program_image.length > 0
      const hasProgramName = this.coinData.program_name && this.coinData.program_name.length > 0
      if (this.coinData !== undefined && hasProgramImage && hasProgramName) {
        this.isEdit = true
        this.coin = {
          ...this.coinData,
          program_image: []
        }
      }
    },
    handleConfirm () {
      this.loading = true
      if (this.isEdit) {
        this.$emit('update-coin', this.coin)
      }
      else {
        this.$emit('create-coin', this.coin)
      }
      this.closeModal()
    }
  }
}
</script>

<style lang='scss' scoped>
@import '~@/styles/reference';
.new-coin-modal {

  .new-coin-modal-body {
    .header {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .content {
      margin-top: 40px;
      .new-coin-input-container {
        margin-top: 24px;
        margin-bottom: 40px;
        position: relative;
        .subtext {
          position: absolute;
          bottom: -20px;
          left: 0;
          right: 0;
          font-size: 12px;
          color: #5E6684;
        }
      }
      .new-coin-name {
        max-width: 280px;
      }
    }
  }
  .new-coin-modal-actions {
    display: flex;
    // justify-content: center;
    .new-coin-button {
      width: 200px;
      height: 40px;
      border-radius: 20px;
    }
    .cancel-button {
      background: none;
      border: 1px solid #5E6684;
      border: 1px solid var(--color-primary);
      color: #5E6684;
      color: var(--color-primary);
      margin-right: 8px;
    }
  }
}
</style>

<style lang="scss">
@import '~@/styles/reference';

.c-modal.new-coin-modal {
  max-width: 482px;
  padding-left: 40px;
  padding-right: 40px;
  @include mobile {
    padding-left: 20px;
    padding-right: 20px;
  }
}
</style>
