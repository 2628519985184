<template>
  <div class="container">
    <c-title
      class="title"
      size="3"
      icon="magic-button"
    >
      Valor do Prêmio
    </c-title>
    <div class="header">
      Escolha o valor do prêmio digitando nos campos abaixo. A escolha pode ser feita de forma individual, ou em massa na barra de ações da tabela.
    </div>
    <div
      data-cy="award-total-card"
      class="total"
    >
      Total: <b data-cy="total-value">{{ formatCurrency(totalValue) }}</b>
    </div>
    <users-table
      use-cpf-mask
      :table-data="filteredUsers"
      :paginate="paginate"
      @input="search = $event"
      @update-values="updateUserValues"
      @update-paginate="updatePaginate"
    />
  </div>
</template>

<script>
import UsersTable from './Components/StepThree/UsersTable.vue'
export default {
  components: {
    UsersTable
  },
  props: {
    tableData: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      search: '',
      tableDataCopy: [],
      paginate: {
        current_page: 1,
        from: 1,
        per_page: 5,
        to: 5,
        total: 5
      }
    }
  },
  computed: {
    totalValue () {
      if (typeof this.tableDataCopy.rows === 'undefined') { return 0 }
      let totalValue = 0
      this.tableDataCopy.rows.forEach(user => {
        if (user.hasOwnProperty('value')) {
          totalValue = totalValue + user.value
        }
      })
      return totalValue
    },
    atLeastOneValueComplited () {
      const valuesFilled = this.tableDataCopy.rows.filter(user => typeof user.value !== 'undefined' && user.value > 0)
      return valuesFilled.length > 0
    },
    filteredUsers () {
      const tdc = JSON.parse(JSON.stringify(this.tableDataCopy))
      const formatedCpfValue = this.formatCpfSearch(this.search)
      const filteredRows = (!this.search)
        ? tdc.rows
        : tdc.rows.filter((user) => {
          const { document, email } = user
          const formattedCpfSearch = this.formatCpfSearch(document)
          return formattedCpfSearch.includes(formatedCpfValue) || email.includes(this.search)
        })

      this.paginate = {
        current_page: 1,
        from: filteredRows.length ? 1 : 0,
        per_page: 5,
        to: filteredRows.length >= 5 ? 5 : filteredRows.length,
        total: filteredRows.length
      }

      return {
        ...this.tableDataCopy,
        rows: filteredRows
      }
    }
  },
  created () {
    this.tableDataCopy = this.tableData
  },
  methods: {
    updatePaginate (newPaginate) {
      this.paginate = newPaginate
    },
    formatCpfSearch (value) {
      return value.replace(/[.-]/gi, '')
    },
    formatCurrency (value) {
      if (!value) return 'R$ 0,00'
      return value.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
    },
    updateUserValues (data) {
      if (!this.search) {
        this.tableDataCopy = data
      } else {
        data.rows.forEach((user) => {
          const matchedUserIndex = this.tableDataCopy.rows.findIndex(row => row.id === user.id)
          this.tableDataCopy.rows[matchedUserIndex].value = user.value
        })
      }

      if (this.atLeastOneValueComplited) {
        this.$emit('input', { users: this.tableDataCopy.rows, value: this.totalValue })
      } else {
        this.$emit('input', { users: null })
      }
    }
  }
}
</script>

<style lang='scss' scoped>
.container {
  max-width: 1020px;
  margin-top: 40px;
  & > .title {
    padding-bottom: 24px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #202950;

    :deep .c-title > .c-icon {
      color: #202950;
      color: var(--color-secondary);
    }
  }
  & > .header {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #202950;
    display: flex;
    padding: 24px 0;
    & > .dot {
      height: 24px;
      width: 24px;
      background-color: #202950;
      border-radius: 50%;
      justify-content: center;
      display: flex;
      align-items: center;
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 20px;
      color: #FDFDFD;
      margin-right: 8px;
    }
  }
  & > .total {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 8px;
    gap: 6px;
    width: -moz-fit-content;
    width: fit-content;
    min-width: 145px;
    height: 38px;
    background: #F8F9FD;
    border: 1px solid #5E6684;
    border: 1px solid var(--color-primary);
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.16);
    border-radius: 4px;
    margin-bottom: 16px;
  }
}
</style>
