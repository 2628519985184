<template>
  <spine-card
    :loading="loading"
    :error="error"
    :graph-data="accessData"
    :format-tab-label="true"
  />
</template>

<script>
import SpineCard from '@/components/Dashboard/cards/SpineCard.vue'
import { mapGetters, mapActions } from 'vuex'
import { DASH_ACESSES_IN_THE_PERIOD } from '@/store/types'
export default {
  name: 'AccessData',
  components: {
    SpineCard
  },
  props: {
    reloadDataQuery: Boolean
  },
  data () {
    return {
      error: false,
      loading: false
    }
  },
  computed: {
    ...mapGetters({
      accessData: DASH_ACESSES_IN_THE_PERIOD
    })
  },
  watch: {
    reloadDataQuery () {
      if (this.reloadDataQuery) { this.getAccessData() }
    }
  },
  mounted () {
    this.getAccessData()
  },
  methods: {
    ...mapActions({
      getAccessInPeriod: DASH_ACESSES_IN_THE_PERIOD
    }),
    getAccessData () {
      this.loading = true
      this.getAccessInPeriod().then((success) => {
        this.error = !success
        this.loading = false
      })
    }
  }
}
</script>

<style lang='scss' scoped>
</style>
