<template>
  <div
    v-if="rescue === 'uniqueCode' || rescue === 'payment'"
    class="upload-codes-component"
  >
    <c-title
      icon="ticket"
      size="3"
    >
      Upload de códigos
    </c-title>

    <div class="container">
      <c-input
        type="file"
        class="input-file"
        placeholder="Selecionar arquivo .CSV"
        :exts="['csv']"
        v-on="$listeners"
      />

      <c-button
        external
        class="download-btn"
        icon="arrow-down-circle"
        size="lg"
        to="https://s3-us-west-2.amazonaws.com/clube-newcore/public-content/modelo-cupons.csv"
        target="_blank"
      >
        Baixar modelo
      </c-button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    rescue: String
  }
}
</script>

<style lang="scss">
@import '~@/styles/reference';

.upload-codes-component {
  margin: { top: 30px; }
  border-bottom: 1px solid $border-color;

  & > .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 30px;
    margin-bottom: 70px;

    @include desktop {
      justify-content: center;
      flex-direction: row;
    }

    & > .input-file {
      min-width: 100%;
      margin-right: 10px;

      @include desktop {
        max-width: 500px;
        min-width: 500px;
      }
      @include mobile { margin-bottom: 10px; }
    }
    & > .download-btn {
      width: 100%;

      @include desktop {
        width: 240px;
      }
    }
  }
}
</style>
