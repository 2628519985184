<template>
  <section class="communication">
    <general-header
      class="header"
      hide-info-button
      :nav-tabs="navTabs"
      :show-tabs="showTabs"
      @show-onboarding="showOnboarding = true"
      @return-click="handleReturnClick()"
    >
      <template
        v-if="showActionButton"
        #action-button
      >
        <c-button
          icon="plus"
          primary
          data-cy="action-button"
          class="action-button"
          @click.native="handleActionClick()"
        >
          {{ actionButtonText }}
        </c-button>
      </template>
    </general-header>
    <nuxt-child :media="$route.params.media" />
    <c-confirm-modal
      :btn-props="{ primary: true }"
      cancel-text="Voltar"
      :confirm-text="`Cancelar ${modalActionText}`"
      character="embarrassed"
      :title="`Tem certeza que deseja cancelar a ${modalActionText} da notificação?`"
      message="Se cancelar, todas as informações já inseridas não serão processadas."
      :is-opened="openCancelModal"
      @close="openCancelModal = false"
      @confirm="confirmCancel"
    />
    <onboarding
      title="Guia de Comunicação"
      :is-opened="showOnboarding"
      :show-modal="showOnboarding"
      :topics="topics"
      @close="showOnboarding = false"
    />
  </section>
</template>

<script>
import GeneralHeader from '@/components/General/GeneralHeader'
import CConfirmModal from '@/components/CComponents/CConfirmModal'
import Onboarding from '@/components/General/OnboardingModal'
import { CAN_CREATE_BANNERS, COMPANY, NOTIFICATION_CREATION_STEP, FEEDBACK } from '@/store/types'
import { mapGetters, mapMutations, mapActions } from 'vuex'

export default {
  components: { GeneralHeader, CConfirmModal, Onboarding },

  data () {
    return {
      showOnboarding: false,
      openCancelModal: false,
      navTabs: [
        {
          name: 'Destaques',
          value: 'comunicacao'
        },
        {
          name: 'Mídias',
          value: 'comunicacao-midias'
        },
        {
          name: 'Notificações',
          value: 'comunicacao-notificacoes'
        }
      ],
      topics: [
        {
          title: 'O que são Ofertas em Destaque?',
          contents: ['São uma seleção de ofertas exclusivas que ficam em destaque em um carrossel na página do seu clube. Portanto, para criar essa seleção, primeiro é necessário cadastrar ofertas exclusivas na opção do menu "Parceiros".']
        },
        {
          title: 'Como selecionar os Destaques?',
          contents: ['Dentro das opções na aba "Destaques", escolha e arraste para o lado direito da tela no máximo 10 ofertas e clique no botão "Salvar Destaques".']
        },
        {
          title: 'O que são Mídias?',
          contents: ['São os banners cadastrados por você. Banners são uma ótima maneira de se comunicar com a sua base a promover campanhas.']
        },
        {
          title: 'Como criar uma Mídia?',
          contents: ['Nã aba "Mídias" clique no botão "Nova Mídia" e siga os passos. É possível cadastrar até 3 banners.']
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      canCreateBanners: CAN_CREATE_BANNERS,
      company: COMPANY
    }),
    feedbackActionText () {
      return String(this.$route.name).includes('notificationId') ? 'Edição' : 'Criação'
    },
    modalActionText () {
      return String(this.$route.name).includes('notificationId') ? 'edição' : 'criação'
    },
    showTabs () {
      const creationRoutes = [
        'criar',
        'detalhes'
      ]

      return !creationRoutes.some(substring => String(this.$route.name).includes(substring))
    },
    showActionButton () {
      const enabledRoutes = [
        'midias',
        'notificacoes'
      ]
      const IsCurrentRouteEnabled = enabledRoutes.some(substring => String(this.$route.name).includes(substring))
      return IsCurrentRouteEnabled && !String(this.$route.name).includes('criar')
    },
    actionButtonText () {
      if (String(this.$route.name).includes('midias')) {
        return 'Nova mídia'
      } else if (String(this.$route.name).includes('notificacoes')) {
        return 'Nova notificação'
      }
      return ''
    }
  },
  mounted () {
    this.calculateActiveBanners()
  },
  methods: {
    ...mapMutations({
      updateNotificationCreationStep: NOTIFICATION_CREATION_STEP,
      feedback: FEEDBACK
    }),
    ...mapActions({
      calculateActiveBanners: CAN_CREATE_BANNERS
    }),
    handleAnalytics (event, data) {
      window.dataLayer.push(
        {
          event,
          ...data
        }
      )
    },
    handleNewNotification () {
      this.updateNotificationCreationStep(1)
      this.handleAnalytics('notificacao-criar', { company_name: this.company.slug })
      this.$router.push('/comunicacao/notificacoes/criar')
    },
    handleActionClick () {
      if (String(this.$route.name).includes('midias')) {
        this.handleAnalytics('botao-nova-midia', { company_name: this.company.name })
        this.$router.push({ name: 'comunicacao-criar' })
      } else {
        this.handleNewNotification()
      }
    },
    handleReturnClick () {
      if (String(this.$route.name).includes('notificacoes')) {
        if (String(this.$route.name).includes('criar')) {
          this.openCancelModal = true
        } else {
          this.$router.push({ name: 'comunicacao-notificacoes' })
        }
      } else {
        this.$router.push({ name: 'comunicacao-midias' })
      }
    },
    confirmCancel () {
      this.openCancelModal = false
      this.$router.push({ name: 'comunicacao-notificacoes' })
      this.feedback({
        type: 'warning',
        title: `${this.feedbackActionText} de notificação cancelada`
      })
    }
  }
}
</script>

<style lang='scss' scoped>
.communication {
  .action-button {
    height: 40px;
    border-radius: 30px;
    font-size: 12px;
    font-weight: 400;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
  }
}
</style>
