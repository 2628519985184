<template>
  <div>
    <component-header @return="$router.push('/parceiros')"/>
    <settings-container />
  </div>
</template>

<script>
import InfoButton from '@/components/Settings/general/InfoButton'
import SettingsContainer from '@/containers/Settings/SettingsContainer'
import ComponentHeader from '@/components/General/ComponentHeader'
export default {
  components: {
    SettingsContainer,
    InfoButton,
    ComponentHeader
  }
}
</script>


