<template>
  <div class="change-account">
    <div class="search-header">
      <span class="text-header">Selecione o clube desejado: </span>

      <search-field
        class="search-field"
        placeholder="Encontre um clube"
        :term="searchTerm"
        @input="term => updateSearch(term)"
        @reset="updateSearch('')"
      />
    </div>

    <div class="accounts-wrapper">
      <div
        class="accounts"
        v-for="(company) in filteredCompanies"
        :key="company.id"
        :title="company.name"
      >
        <c-button
          class="company-logo"
          @click="$emit('change-account', company.id)"
        >
          <c-image
            :src="company.logo"
            :placeholder="imagePlaceholder"
          />
        </c-button>

        <p class="company-name">
          {{ company.name }}
        </p>
      </div>
    </div>

    <c-empty
      v-if="filteredCompanies.length === 0"
      class="empty-search"
      title="Ops!"
      character="searching"
      message="Não encontramos nenhum clube relacionado à busca"
    />
  </div>
</template>

<script>
import CImage from '@/components/CComponents/CImage'
import CButton from '@/components/CComponents/CButton'
import CEmpty from '@/components/CComponents/CEmpty'
import SearchField from '@/components/CComponents/SearchField'

export default {
  components: {
    CImage,
    CButton,
    CEmpty,
    SearchField
  },

  props: {
    companies: {
      type: Array,
      required: true
    }
  },

  data () {
    return {
      imagePlaceholder: require('@/assets/img/campaign-placeholder.svg'),
      searchTerm: '',
      filteredCompanies: ''
    }
  },

  mounted () {
    this.filteredCompanies = this.companies
  },

  methods: {
    updateSearch (term) {
      this.searchTerm = term

      if (term === '') {
        this.filteredCompanies = this.companies
        return
      }

      let regex = new RegExp(term, 'i')

      let companies = this.companies.filter(company => {
        if (company.name.match(regex) !== null) { return company }
      })

      this.filteredCompanies = companies
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/styles/reference';

.change-account {
  .search-header {
    margin: 0 12px;
    display: flex;
    flex-wrap: wrap;
    padding-top: 15px;
    align-items: center;
    justify-content: space-between;

    & > .text-header {
      font-size: 15px;
      opacity: 0.5;
      color: #121E48;
    }

    & > .search-field {
      padding-top: 10px;
      width: 100%;
    }
  }

  .accounts-wrapper {
    display: flex;
    flex-wrap: wrap;
    overflow-y: auto;
    justify-content: space-between;
    height: 40vh;
    margin-top: 20px;
    margin-left: 15px;

    @media screen and (max-height: 600px) {
      height: 30vh;
    }

    @media screen and (min-height: 670px) {
      height: 45vh;
    }

    & > .accounts {
      width: 70px;
      margin: 20px 15px 0 0;

      &:last-child {
        margin-bottom: 100px;
      }

      & > .company-logo {
        width: 70px;
        height: 70px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        border: 1px solid rgba(18, 30, 72, 0.1);
        border-radius: 50%;
        box-shadow: 1px 1px 4px 0 rgba(0,0,0,0.1);

        &:hover {
          border: 3px solid var(--color-secondary);
        }

        img {
          width: 56px;
          display: block;
        }
      }

      & > .company-name {
        text-align: center;
        font-size: 12px;
        font-weight: bold;
        opacity: 0.8;
        color: #121E48;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-top: 10px;
      }
    }
  }

  .empty-search {
    position: absolute;
    top: 180px;
    margin-left: auto;
    margin-right: auto;
  }
}
</style>
