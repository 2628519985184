<template>
  <svg
    :class="classes"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
	  :viewBox="`0 0 ${width} ${height}`"
    xml:space="preserve">
    <g>
      <path
        class="path"
        :style="styles"
        d="M39,0L0,0v24l4.5,0c4.8,0.1,9.5-3.8,13.9-11.8C24.9,0.1,31,0,39,0z"/>
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: [Number, String],
      default: 39
    },
    height: {
      type: [Number, String],
      default: 24
    },
    isExclusive: Boolean,
    reverse: Boolean
  },
  computed: {
    styles () {
      const fill = this.isExclusive
        ? '#121E48'
        : 'var(--color-primary)'
      const styles = { fill }
      return styles
    },
    classes () {
      const classes = [
        'campaign-tag-curve',
        {
          '-reverse': this.reverse
        }
      ]
      return classes
    }
  }
}
</script>

<style lang="scss">
@import '~@/styles/reference';

.campaign-tag-curve {
  fill: $primary-color-placeholder;

  &.-reverse {
    transform: scaleX(-1);
  }

  & > g > .path { fill: $primary-color-placeholder; }
}
</style>
