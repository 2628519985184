<template>
  <div
    class="step-one"
    data-cy="new-order-step-one"
  >
    <div class="header">
      <c-title
        data-cy="step-title"
        class="title"
        size="3"
      >
        Seleção de Usuários
      </c-title>
    </div>
    <div class="intro">
      <instruction
        :step="1"
        text="Selecione uma maneira de inserir os usuários"
      />
      <div class="cards">
        <user-insertion-mode-card
          data-cy="step-one-mode-card"
          v-for="option in options"
          :key="option.component"
          :title="option.title"
          :text="option.text"
          :selected="option.component === mode"
          :show-modal="option.showModal"
          @open-modal="openExampleModal = !openExampleModal"
          @click="mode = option.component"
        />
      </div>
    </div>
    <div class="content">
      <transition
        name="fade"
        mode="out-in"
      >
        <component
          :is="mode"
          :open-modal="openExampleModal"
          :data-rows="value.uploaded_users"
          :mode="mode"
          :first-access="firstAccessCopy"
          @change-first-access="firstAccessCopy = false"
          @change="rows = $event, $emit('input', {uploaded_users: $event})"
        />
      </transition>
      <info-users-modal
        :is-opened="openInfoUsersModal"
        @close="openInfoUsersModal = false"
        @open-example-modal="openExampleModal = !openExampleModal"
      />
      <register-users-modal
        :is-opened="openRegisterUsersModal"
        @close="openRegisterUsersModal = false"
        :users="[]"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { COMPANY } from '@/store/types'
import Instruction from '@/components/General/Instruction.vue'
import UserInsertionModeCard from '@/components/General/cards/UserInsertionModeCard.vue'
import SheetsMode from './Components/StepOne/SheetsMode.vue'
import ManualMode from './Components/StepOne/ManualMode.vue'
import RegisterUsersModal from '@/components/General/modals/RegisterUsersModal.vue'
import InfoUsersModal from '@/components/General/modals/InfoUsersModal.vue'

export default {
  components: {
    Instruction,
    UserInsertionModeCard,
    SheetsMode,
    RegisterUsersModal,
    ManualMode,
    InfoUsersModal
  },
  props: ['value', 'first-access'],
  data () {
    return {
      mode: 'sheets-mode',
      openExampleModal: false,
      openInfoUsersModal: false,
      openRegisterUsersModal: false,
      firstAccessCopy: true,
      rows: []
    }
  },
  computed: {
    ...mapGetters({
      company: COMPANY
    }),
    options () {
      return [
        {
          title: 'Inserir via planilha',
          text: 'Para inserir vários usuários em uma única importação.',
          component: 'sheets-mode',
          showModal: true
        },
        {
          title: 'Inserir manualmente',
          text: 'Para inserir um usuário por vez sem a necessidade de importar um arquivo.',
          component: 'manual-mode',
          showModal: false
        }
      ]
    }
  },
  created () {
    this.rows = this.value.uploaded_users
    this.firstAccessCopy = this.firstAccess
  }
}
</script>

<style lang='scss' scoped>
.step-one {
  margin-top: 40px;

  .header {
    display: flex;
  }
  .intro {
    margin-top: 48px;
    .cards {
      margin-top: 24px;
      display: flex;
    }
  }
  .content {
    margin-top: 48px;
    .buttons {
      margin-top: 54px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      .send {
        margin-left: 8px;
      }
    }
  }
}
</style>
