<template>
  <div class="address-form">
    <div class="section-header">
      <c-title
        icon="map-marker"
        icon-size="18"
      >
        Unidades
      </c-title>

      <search-field
        v-if="existingAddresses.length > 0"
        placeholder="Busque por CEP, rua, cidade..."
        :term="searchTerm"
        @reset="searchTerm = ''"
        @input="$emit('search-addresses', $event)"
        @submit="$emit('search-addresses', $event)"
      />
    </div>

    <div class="section-controls">
      <div
        class="addresses"
        v-if="existingAddresses.length"
      >
        <div v-if="addresses.length">
          <address-card
            v-on="$listeners"
            v-for="(address, index) in addresses"
            v-if="addresses && Object.keys(address).includes('id')"
            :is-active="activeAddress === address.id"
            :number="address.number"
            :street="address.street"
            :state="address.state"
            :code="address.code"
            :city="address.city"
            :key="address.id"
            :id="address.id"
            :index="index"
            @toggle-details="activeAddress = activeAddress === $event ? '' : $event"
          />

          <div
            class="addresses-pagination"
            v-if="showPagination"
          >
            <c-button
              class="btn-paginate"
              size="lg"
              :disabled="isPaginating"
              @click="$emit('paginate-addresses')"
            >
              Ver Mais
            </c-button>
          </div>
        </div>
        <c-empty
          v-else
          class="empty"
          title="Unidades não encontradas"
          character="searching"
          message="Pesquise por cidade ou estado desejado"
        />
      </div>

      <div
        class="new-addresses"
        v-if="newAddresses.length"
      >
        <address-unit-form
          v-on="$listeners"
          v-for="(address, index) in formData.address"
          v-if="!Object.keys(address).includes('id')"
          :disable-delete="!paginationTotal && formData.address.length === 1"
          :code="address.code"
          :number="address.number"
          :street="address.street"
          :city="address.city"
          :state="address.state"
          :key="index"
          :index="index"
          :address-length="formData.address.length"
        />
      </div>
    </div>

    <div class="section-actions">
      <c-button
        icon="plus-circle"
        class="btn-add"
        icon-size="lg"
        size="lg"
        :disabled="!hasValidAddresses"
        @click="addAddress"
      >
        Adicionar mais endereços
      </c-button>
    </div>
  </div>
</template>

<script>
import SearchField from '@/components/CComponents/SearchField'

import AddressCard from '@/components/Partners/Editing/AddressCard'
import AddressUnitForm from '@/components/Partners/Editing/AddressUnitForm'

const ADDRESS_DEFAULT = {
  street: '',
  number: '',
  code: '',
  city: '',
  state: ''
}

export default {
  components: { AddressCard, AddressUnitForm, SearchField },
  props: {
    addresses: {
      type: Array,
      required: true
    },
    paginationTotal: {
      type: Number,
      required: true
    },
    isPaginating: {
      type: Boolean,
      required: true
    },
    formData: {
      type: Object,
      required: true
    },
    searchTerm: {
      type: String,
      required: false
    }
  },
  data () {
    return {
      showAddressForm: false,
      activeAddress: ''
    }
  },
  computed: {
    showPagination () {
      return this.addresses.length < this.paginationTotal
    },
    existingAddresses () {
      return this.formData.address.filter(a => a.hasOwnProperty('id'))
    },
    newAddresses () {
      return this.formData.address.filter(a => !a.hasOwnProperty('id'))
    },
    hasValidAddresses () {
      return this.newAddresses.every((address) => {
        return Object.keys(address).every(key => !!address[key])
      })
    }
  },
  watch: {
    'formData.address' (newValue) {
      if (!this.formData.address.length) this.addAddress()
    },
    addresses (newValue) {
      if (!this.addresses.length && !!this.paginationTotal) { this.$emit('paginate-addresses') }
    }
  },
  methods: {
    addAddress () {
      this.$emit('add-address', { ...ADDRESS_DEFAULT })
    }
  }
}
</script>

<style lang="scss">
@import '~@/styles/reference';

.address-form {

  & > .section-header {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    @include mobile {
      flex-direction: column;
      align-items: flex-start;
    }

    & > .search-field {
      width: 100%;
      max-width: 300px;

      @include mobile { max-width: 100%; margin-top: 20px; }

      & > .field {
        margin: 0;

        & > .c-input { margin: 0; }
        & > .c-input > .input { width: 100%; }
      }
    }
  }

  & > .section-controls {
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;

    & > .addresses {
      width: 100%;

      & > .addresses-pagination {
        display: flex;
        justify-content: center;

        margin: 20px 0;

        & > .btn-paginate {
          width: 100%;
          max-width: 190px;
        }
      }

      & > .empty { padding: 0; }
    }

    & > .new-addresses {
      width: 100%;

      &:not(:first-child) { margin-top: 50px; }
      & > .address-unit-form:not(:last-child) { margin-bottom: 20px; }
    }
  }

  & > .section-actions {
    display: flex;
    justify-content: center;

    margin: 30px 0 25px 0;

    & > .btn-add {
      width: 100%;
      max-width: 440px;

      border-style: dashed;
      border-color: #E9EAEE;
    }
  }
}
</style>
