<template>
  <div class="notifications-container">
    <notifications
      :loading="loading"
      :notifications="notifications.data"
      :paginate="customPaginate"
      @new-notification="handleNewNotification"
      @notification-details="notification => handleNotificationDetails(notification)"
      @edit-notification="notificationId => handleEditNotification(notificationId)"
      @submit-search="handleGetNotifications"
    />
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
import Notifications from '~/components/Communication/Notifications/Notifications'
import {
  FEEDBACK,
  COMPANY,
  NOTIFICATION,
  NOTIFICATIONS,
  GET_NOTIFICATIONS,
  UPDATE_NOTIFICATION,
  NOTIFICATION_CREATION_STEP,
  CAMPAIGN,
  PARTNER
} from '@/store/types'

export default {
  name: 'NotificationsContainer',

  components: {
    Notifications
  },

  data () {
    return {
      lastFilters: null,
      loading: true,
      hasSomeSearchSubmitted: false,
      showNotificationDetailsModal: false
    }
  },

  computed: {
    ...mapGetters({
      notification: NOTIFICATION,
      notifications: NOTIFICATIONS,
      company: COMPANY
    }),
    customPaginate () {
      return {
        links: this.notifications.links,
        ...this.notifications.meta
      }
    }
  },

  async mounted () {
    await this.handleGetNotifications()
  },

  methods: {
    ...mapMutations({
      updateDistributionStep: NOTIFICATION_CREATION_STEP,
      setCampaign: CAMPAIGN,
      setPartner: PARTNER
    }),
    ...mapActions({
      getNotifications: GET_NOTIFICATIONS,
      updateNotification: UPDATE_NOTIFICATION,
      feedback: FEEDBACK
    }),

    handleAnalytics (event, data) {
      window.dataLayer.push(
        {
          event,
          ...data
        }
      )
    },
    handleNotificationDetails (notification) {
      this.handleAnalytics('notificacao-detalhes', { company_name: this.company.slug })
      if (notification.campaign) { this.setCampaign(notification.campaign) }
      if (notification.partner) { this.setPartner(notification.partner) }
      this.$router.push({ path: `notificacoes/detalhes/${notification.id}` })
    },
    handleNewNotification () {
      this.updateDistributionStep(1)
      this.handleAnalytics('notificacao-criar', { company_name: this.company.slug })
      this.$router.push({ name: 'comunicacao-notificacoes-criar' })
    },
    handleEditNotification (notificationId) {
      this.updateDistributionStep(1)
      this.handleAnalytics('notificacao-editar', { company_name: this.company.slug })
      this.$router.push(`/comunicacao/notificacoes/criar/${notificationId}`)
    },
    async handleGetNotifications (filters) {
      this.loading = true
      this.lastFilters = filters
      if (filters) { this.hasSomeSearchSubmitted = true }
      await this.getNotifications(filters)
        .catch(() => {
          this.loading = false
          this.feedback({
            type: 'error',
            title: 'Houve um erro ao carregar as notificações',
            text: 'Tente novamente mais tarde'
          })
        })
      this.loading = false
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/styles/reference';

.media-container {
  max-width: 1020px;
  margin: 30px auto;

  > .container {
    margin-top: 30px;

    > .cards-container {
      display: grid;
      grid-gap: 20px;
      grid-template-columns: 1fr 1fr;
      @include mobile {
        padding: 0 15px;
        grid-template-columns: 1fr;
      }
    }
  }
}
</style>
