<template>
  <c-table-container
    class="listing-table"
    :class="isRowsEmpty ? '-inactive' : ''"
    v-bind="$attrs"
    v-on="$listeners"
    :paginate="!isRowsEmpty ? paginate : {}"
  >
    <data-table
      :sort="false"
      :cols="cols"
      :rows="formatedRows"
      class="-table"
    >
      <template
        slot="row"
        slot-scope="{ row }"
      >
        <td :class="tdClass">
          <span
            class="td-link"
            @click="$emit('link-click', row)"
          >
            {{ formatString (row.title) }}
          </span>
        </td>
        <td :class="tdClass">
          <span class="td-span">
            {{ formatDate(row.start_date) }}
          </span>
        </td>
        <td :class="tdClass">
          <span class="td-span">
            {{ row.end_date ? formatDate(row.end_date) : '-' }}
          </span>
        </td>
        <td :class="tdClass">
          <c-label
            v-if="row.id"
            class="table-status"
            :color="statusColor(row)"
          >
            {{ statusLabel(row) }}
          </c-label>
        </td>
        <td
          :class="tdClass"
          class="table-actions"
        >
          <c-toggle
            v-if="row.id"
            is-engagement
            :height="30"
            :width="100"
            :labels="{ checked: 'Ativo', unchecked: 'Ativo' }"
            :value="row.status === 1 || row.status === 2"
            @change="$emit('action-click', row)"
          />
        </td>
      </template>
    </data-table>
    <div
      class="empty-content"
      v-if="isRowsEmpty && hasEmptyBanner"
    >
      <empty-banner
        title="Você ainda não possui uma campanha de engajamento"
        button="Nova campanha"
        @button-click="handleEmptyButton"
      />
    </div>
  </c-table-container>
</template>

<script>
import { mapGetters } from 'vuex'
import { timestampToOnlyDate } from '@/modules/validate/helpers.js'
import { COMPANY } from '@/store/types'
import EmptyBanner from '@/components/General/EmptyBanner'

export default {
  components: {
    EmptyBanner,
    DataTable: () => import('vue-data-tablee'),
    CTableContainer: () => import('@/components/CComponents/CTableContainer'),
    CLabel: () => import('@/components/CComponents/CLabel')
  },
  props: {
    rows: Array,
    cols: Array,
    hasEmptyBanner: Boolean,
    paginate: Object
  },
  data () {
    return {
      statuses: {
        0: {
          label: 'Inativa',
          color: '#EE5250'
        },
        1: {
          label: 'Ativa',
          color: '#4cad50'
        },
        2: {
          label: 'Agendado',
          color: '#003396'
        }
      },
      tdClass: 'data-tablee-cell -content -center'
    }
  },
  computed: {
    ...mapGetters({
      company: COMPANY
    }),
    formatedRows () {
      if (this.rows.length && this.rows.length < 5) {
        let array = []
        const diff = 5 - this.rows.length
        for (let i = 0; i < diff; i++) {
          array.push({})
        }
        array = this.rows.concat(array)
        return array
      }
      return this.rows
    },
    isRowsEmpty () {
      return this.rows.length === 0
    }
  },
  methods: {
    formatString (string) {
      if (!string || string.length < 43) return string
      return string.substring(0, 43) + '...'
    },
    formatDate (date) {
      return timestampToOnlyDate(date)
    },
    statusColor (row) {
      if (!row || !this.statuses[row.status].color) return '#000'
      return this.statuses[row.status].color
    },
    statusLabel (row) {
      if (!row || !this.statuses[row.status].label) return 'Carregando...'
      return this.statuses[row.status].label
    },
    handleEmptyButton () {
      window.dataLayer.push({
        event: 'pontos-campanha-criacao',
        company_name: this.company.slug
      })
      this.$router.push('/engajamento/campanhas/nova-campanha')
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/styles/reference';

.result { border: 1px solid rgba(18, 30, 72, 0.05); }
.listing-table-preview-button { width: 135px; }
.listing-table {
  &.-inactive {
    :deep > .table .-header .data-tablee-text {
      color: #D8D8D8;
    }
  }
  :deep > .table {
    border: none;
    .-header {
      background: #FDFDFD;
      padding: 20px;
    }
  }
  .-table {
    background: #FDFDFD;
    box-shadow: 0px 4px 8px rgba(94, 102, 132, 0.08);
    border-radius: 4px;
    border: none;
  }
  .td-span {
    color: #5E6684;
    font-size: 14px;
  }
  .td-link {
    color: #608CEA;
    font-size: 14px;
    cursor: pointer;
    text-decoration: underline;

    :visited {
      color: #608CEA;
    }
  }
  td {
    height: 80px;
  }
  .table-status {
    width: 135px;
    height: 30px;

    > .text{
      text-transform: none;
      font-size: 12px;
    }
  }

  .empty-content {
    padding: 20px;
    background: white;
  }
}
</style>
