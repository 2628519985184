<template>
  <club-activation
    @clear-error="clearError"
    :companies="companiesEnabled"
    :is-loading="isLoading"
    :error="errorCode"
  />
</template>

<script>
import * as types from '@/store/types'
import { mapGetters } from 'vuex'

import ClubActivation from '@/components/Login/ClubActivation'

const errorCodes =
{ 0: { type: '', data: {} },
  1: { type: 'invalidCredentials', data: {} },
  2: { type: 'userNotFound', data: {} },
  3: { type: 'isActive', data: {} },
  4: { type: 'notEnabled', data: {} }
}

export default {
  components: { ClubActivation },
  data () {
    return {
      isLoading: false,
      errorCode: errorCodes[0]
    }
  },
  computed: {
    ...mapGetters({
      companiesEnabled: types.CONFIG_COMPANIES_ENABLED
    })
  },
  methods: {
    clearError () {
      this.errorCode = errorCodes[0]
    }
  }
}
</script>
