<template>
  <div class="container">
    <div class="title">
      <h2>Configurações de Bloqueios</h2><premium-tag v-if="company.trial" />
    </div>
    <div class="content">
      <h2>Novos parceiros</h2>
      <h3>Você tem a possibilidade de bloquear as ofertas de novos parceiros até que as mesmas passem pela sua aprovação antes de estarem disponíveis no seu clube. </h3>
      <div class="form">
        <div class="label">
          bloquear novos parceiros? <info-button :info="infos[0]" />
        </div>
        <div class="options">
          <div class="check">
            <c-checkbox
              :value="data.blocked_type === 'active'"
              :disabled="company.trial"
              round
              @input="handleCheckbox('blocked_type', 'active')"
              class="checkbox"
            />  Sim
          </div>
          <div class="check">
            <c-checkbox
              :value="data.blocked_type === 'deactive'"
              :disabled="company.trial"
              round
              @input="handleCheckbox('blocked_type', 'deactive')"
              class="checkbox"
            />  Não
          </div>
        </div>
      </div>
      <h2>Pagamentos</h2>
      <h3>Selecione as bandeiras que serão permitidas em pagamentos.</h3>
      <div class="form">
        <div class="label">
          bandeiras permitidas <info-button :info="infos[1]" />
        </div>
        <c-select
          class="select"
          required
          label="name"
          name="bandeiras"
          track-by="name"
          :multiple="true"
          :options="availableBrands"
          :disabled="company.trial"
          icon="chevron-down"
          :value="data.brands"
          :feedback-show="errors.has('bandeiras')"
          :feedback-message="errors.first('bandeiras')"
          @input="setBrands($event)"
        />
        <c-button
          class="button"
          :disabled="company.trial"
          @click="data.brands = availableBrands"
        >
          Todas
        </c-button>

        <c-button
          class="button"
          icon="cross"
          :disabled="company.trial"
          @click="data.brands = []"
        >
          Limpar
        </c-button>
      </div>
    </div>
    <footer-buttons
      v-if="!company.trial"
      :is-processing="loading || isValid"
      @save="save()"
      @back="openConfirmationModal = true"
    />
    <trial-banner
      v-else
      @call-sales="callSales()"
    />
    <c-confirm-modal
      :btn-props="{ primary: true }"
      confirm-text="Sair sem salvar"
      character="embarrassed"
      title="Tem certeza que deseja sair sem salvar?"
      message="Se sair, todas as informações já inseridas não serão salvas."
      :is-opened="openConfirmationModal"
      @close="openConfirmationModal = false"
      @confirm="$router.push('/configuracao')"
    />
  </div>
</template>

<script>
import InfoButton from '@/components/Settings/general/InfoButton'
import PremiumTag from '@/components/Settings/general/PremiumTag.vue'
import CCheckbox from '@/components/CComponents/CCheckbox.vue'
import FooterButtons from '@/components/Settings/general/FooterButtons.vue'
import TrialBanner from '@/components/Settings/TrialBanner.vue'
import { COMPANY, COMPANY_SETTINGS, COMPANY_CALL_SALES } from '@/store/types'
import { mapGetters, mapActions } from 'vuex'
import CSelect from '@/components/Settings/CSelect'
import CConfirmModal from '@/components/CComponents/CConfirmModal.vue'
export default {
  components: { PremiumTag, CCheckbox, InfoButton, FooterButtons, TrialBanner, CSelect, CConfirmModal },

  data () {
    return {
      openConfirmationModal: false,
      infos: [
        {
          title: 'Bloquear novos parceiros?',
          text: 'Bloquear as novas marcas parceiras até que você as aprove para estarem no seu clube'
        },
        {
          title: 'Bandeiras permitidas',
          text: 'Bandeiras de cartão que serão permitidas em compras no seu clube'
        }
      ],
      availableBrands: [
        { name: 'MASTERCARD' },
        { name: 'VISA' },
        { name: 'AMEX' },
        { name: 'ELO' },
        { name: 'DISCOVER' },
        { name: 'JCB' },
        { name: 'HIPERCARD' }
      ],
      loading: false,
      data: {
        blocked_type: 'deactive',
        brands: [
          { name: 'MASTERCARD' },
          { name: 'VISA' },
          { name: 'AMEX' },
          { name: 'ELO' },
          { name: 'DISCOVER' },
          { name: 'JCB' },
          { name: 'HIPERCARD' }
        ]
      }
    }
  },

  computed: {
    ...mapGetters({
      company: COMPANY
    }),

    isValid () {
      return !this.data.brands.length > 0
    }
  },

  mounted () {
    this.data.blocked_type = this.company.blocked_type
    if (this.company.config.brands.length > 0) {
      this.data.brands = this.company.config.brands.map(brand => {
        return { name: brand }
      })
    }
  },

  methods: {
    ...mapActions({
      companySettings: COMPANY_SETTINGS,
      callSales: COMPANY_CALL_SALES
    }),

    handleCheckbox (field, value) {
      if (this.company.trial) return
      this.data[field] = value
    },

    setBrands (value) {
      this.data.brands = []
      value.forEach(element => {
        this.data.brands.push(element)
      })
    },

    allBrands () {
      this.data.brands = this.availableBrands
    },

    async save () {
      this.loading = true
      const brands = this.data.brands.map(brand => {
        return brand.name
      })
      const response = await this.companySettings({ ...this.data, brands })
      if (response === 'success') {
        this.$router.push('/configuracao')
      }
      this.loading = false
    }
  }
}
</script>

<style lang='scss' scoped>
@import '~@/styles/reference.scss';
.container {
  max-width: 1020px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 48px;

  & > .title {
    align-items: center;
    display: flex;
    color: $neutral-dark-blue;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Nunito Sans;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;

    & > h2 {
      margin-right: 8px;
    }
  }

  & > .content {
    & > h2 {
      color: $neutral-gray-200;
      font-feature-settings: 'clig' off, 'liga' off;
      font-family: Nunito Sans;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 140%;
      margin-bottom: 8px;
      margin-top: 48px;
    }

    & > h3 {
      color: $neutral-dark-blue;
      font-feature-settings: 'clig' off, 'liga' off;
      font-family: Nunito Sans;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%;
    }

    & > .form {
      margin-top: 24px;

      & > .select {
        margin: 8px 0;
      }

      & > .label {
        display: flex;
        align-items: center;
        height: 20px;
        color: $neutral-gray-200;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: Nunito Sans;
        font-size: 11px;
        font-style: normal;
        font-weight: 400;
        letter-spacing: 0.5px;
        text-transform: uppercase;
      }

      & > .options{
        margin-top: 16px;
        display: flex;
        width: 122px;
        justify-content: space-between;

        & > .check {
          display: flex;
          color: $neutral-gray-200;
          font-feature-settings: 'clig' off, 'liga' off;
          font-family: Nunito Sans;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 140%;
          align-items: center;

          & > .checkbox {
            margin-right: 8px;
          }
        }
      }
    }
  }
}
</style>
