<template>
  <account-creation-container />
</template>

<script>
import AccountCreationContainer from '@/containers/AccountCreation/AccountCreationContainer.vue'

export default {
  components: { AccountCreationContainer },
  layout: 'unauthenticated',
  middleware: 'checkauth'
}
</script>
