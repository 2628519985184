<template>
  <engagement/>
</template>

<script>
import Engagement from '@/containers/Engagement/Engagement.vue'

export default {
  name: 'index',
  components: {
    Engagement
  }
}
</script>

<style lang='scss' scoped>
</style>
