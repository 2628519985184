<template>
  <div :class="[ 'highlight-featured', {'-hidden': isHidden} ]">
    <div class="featured-header">
      <c-title
        class="title"
        :icon="isDesktop ? '' : 'thumbs-up'"
      >
        {{ title }}
      </c-title>
      <c-button
        v-if="isDesktop"
        size="lg"
        icon="cross"
        class="close"
        primary
        @click="$emit('close')"
      />
    </div>

    <div
      class="action-button-wrapper"
      v-if="!isDesktop"
    >
      <c-button
        @click="$emit('open-list')"
        class="button"
        icon-size="sm"
        icon="plus"
        size="lg"
        primary
      >
        Adicionar oferta em destaque
      </c-button>
    </div>

    <div class="featured-list">
      <draggable
        element="div"
        class="featured-list"
        v-model="featuredModel"
        v-bind="options"

        @start="onStart"
        @end="onEnd"
        @add="$emit('add-item', $event)"
        @remove="$emit('remove-item', $event)"
        @update="$emit('sort', $event)"
      >
        <transition-group
          tag="div"
          type="transition"
          class="content"
        >
          <div
            class="item-wrapper feature-wrap"
            v-for="(campaign, index) in featuredModel"
            :key="campaign.id"
            @mouseup="mouseUp"
          >
            <placeholder-card
              :index="index"
              is-active
            />

            <highlight-card
              class="item"
              remove
              :slug="campaign.slug"
              :image="campaign.partner && campaign.partner.logo"
              :title="campaign.title"
              :partner="campaign.partner && campaign.partner.name"
              :expiry-date="campaign.end_date"
              :value="campaign.value | toNumber"
              :symbol="campaign.symbol"
              :status="!!campaign.status"
              :is-exclusive="!!(campaign.partner && campaign.partner.company_id)"
              @remove-item="removeAction(campaign.slug)"
            />
          </div>

          <placeholder-card
            v-for="(item, index) in placeholderSlots"
            :key="item"
            :index="index + (featured || []).length"
            :class="{ 'item-wrapper': listDragging }"
          />
        </transition-group>
      </draggable>

      <c-empty
        v-if="!(featuredModel || []).length && !isDesktop"
        class="small-empty"
        character="searching"
        title="Não existe nenhuma oferta em destaque"
        message='Clique em "adicionar oferta em destaque" para adicionar novas ofertas.'
      />

      <c-button
        v-if="isDesktop"
        @click="validateHighlighteds(campaigns)"
        :disabled="disableButton"
        class="featured-save"
        icon="chevron-right"
        size="sm"
        primary
        block
      >
        Salvar Destaques
      </c-button>
    </div>
  </div>
</template>

<script>
import PlaceholderCard from './PlaceholderCard'
import HighlightCard from '@/components/Communication/Highlight/HighlightCard'
import { mapActions, mapGetters } from 'vuex'
import { COMPANY, FEEDBACK } from '@/store/types'

import Draggable from 'vuedraggable'

export default {
  components: {
    PlaceholderCard,
    HighlightCard,
    Draggable
  },
  filters: {
    toNumber (value) {
      if (typeof value === 'number') {
        return value
      }

      if (typeof value === 'string') {
        return +value.replace(/\D/g, '')
      }
    }
  },

  props: {
    featured: {
      type: Array,
      required: true
    },
    dragOptions: {
      type: Object,
      required: true
    },
    isHidden: Boolean,
    listDragging: Boolean,
    loading: Boolean
  },

  data () {
    return {
      isDragging: false,
      placeholderLimit: 10,
      featuredModel: [ ...this.featured ],
      disableButton: false
    }
  },

  computed: {
    ...mapGetters({ company: COMPANY }),
    placeholderSlots () {
      return this.placeholderLimit - this.featured.length
    },

    campaigns () {
      return this.featured.map((c, i) => ({ slug: c.slug, order: i }))
    },

    options () {
      return {
        ...this.dragOptions,
        draggable: '.item-wrapper',
        disabled: !this.isDesktop || (!this.featuredModel.length && !this.listDragging),
        sort: true
      }
    },

    title () {
      return this.isDesktop ? 'Ofertas em destaque' : 'Destaques'
    }
  },

  watch: {
    featured (newVal) {
      this.featuredModel = [ ...newVal ]
    },
    loading (newval) {
      this.disableButton = newval
    }
  },

  methods: {
    ...mapActions({
      feedback: FEEDBACK
    }),

    onStart (ev) {
      this.$emit('is-dragging', true)
      ev.item.childNodes[0].setAttribute('style', 'display: none')
    },

    onEnd (ev) {
      this.$emit('is-dragging', false)
      ev.item.childNodes[0].setAttribute('style', '')
    },

    mouseUp (ev) {
      if (ev && ev.path) {
        ev.path
          .find((el) => (el.classList && el.classList.contains('feature-wrap')))
          .classList.remove('sortable-drag')
      }
    },

    removeAction (slug) {
      this.$emit('remove-item', { slug: slug })
    },

    validateHighlighteds (campaigns) {
      if (this.loading) return
      this.handleAnalytics('salvar-destaque', {
        company_name: this.company.name
      })
      if (campaigns.length > 10) {
        this.feedback({
          type: 'error',
          title: 'Oops',
          text: 'Só é permitido o cadastro de 10 ofertas em destaque'
        })

        return
      }

      this.$emit('save-highlighted', { campaigns })
    },
    handleAnalytics (event, data) {
      window.dataLayer.push(
        {
          event: event,
          ...data
        }
      )
    }
  }
}
</script>

<style lang="scss">
@import '~@/styles/reference';

.sortable-drag {
  &.sortable-fallback {
    opacity: 1 !important;
    & > .c-card {
      width: 315px;
      height: 160px;
      transform: rotate(2deg) scale(1);
    }
  }

  .placeholder-card { display: none; }
  .c-card { margin: 0; }
}

.highlight-featured {
  user-select: none;
  padding: 0 0 60px 30px;

  &.-hidden { display: none; }

  & >.small-empty {
    @media only screen and (min-width: 361px) { display: none !important; }
  }

  @include mobile {
    padding: 0 10px 60px 10px;

    & > .action-button-wrapper {
      display: flex;
      align-items: center;
      justify-content: center;

      margin-top: 5px;

      & > .button { width: 100%; }
    }

    & > .featured-list > .featured-list {
      & > .content {
        & > .feature-wrap {
          margin-bottom: 10px;

          & > .placeholder-card {
            > svg { max-height: 100%; }

            @media only screen and (max-width: 360px) { display: none; }
          }

          & > .highlight-card {
            min-height: 155px;

            max-width: 310px;
            max-height: 170px;

            width: 100%;
            height: 40vw;

            margin: 10px 10px 12px 60px;

            @media only screen and (max-width: 360px) { margin: 10px; }
          }
        }

        & > .placeholder-card {
          max-width: 375px;
          max-height: 210px;
          margin: 0 auto 9px auto;

          & > svg { max-height: 100%; }

          @media only screen and (max-width: 360px) { display: none; }
        }
      }
    }
  }

  & > .featured-header {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    padding: 20px 15px 15px 0;

    & > .title { padding-top: 0 !important; }
  }

  & > .featured-list {
    margin-top: 15px;

    @include desktop { padding-right: 15px; }

    & > .featured-save {
      z-index: 10;

      &:hover {
        background-color: $primary-color-placeholder;
        background-color: var(--color-primary);
      }
    }

    & .content > .item-wrapper:not(.feature-wrap):not(.placeholder-card) {
      position: relative;
      padding: 10px 0;
      margin-top: -10px;

      & > .highlight-card {
        max-width: 319px;
        max-height: 155px;
        margin: 12px 0 10px 60px;
      }

      &::before {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        background-image: url('~/static/img/contorno_card.svg');
        background-repeat: no-repeat;
      }
    }

    & .content > .feature-wrap {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 25px;

      &.ghost, &.sortable-drag, &.sortable-chosen {
        margin-bottom: 12px;
        & > .highlight-card { margin: 12px 2px 13px 50px !important; }

        &::before {
          content: "";
          position: absolute;
          width: 100%;
          height: 100%;
          background-image: url('~/static/img/contorno_card.svg');
          background-repeat: no-repeat;
          background-size: cover;
        }

        &::after {
          content: "";
          position: absolute;
          left: 21px;
          top: 46%;
          transform: translateY(-50%);

          font-family: 'Ubuntu';
          font-weight: 400;
          color: #FFF;
        }

        // Could use a for loop here
        &:nth-child(2)::after { content: "1"; }
        &:nth-child(3)::after { content: "2"; }
        &:nth-child(4)::after { content: "3"; }
        &:nth-child(5)::after { content: "4"; }
        &:nth-child(6)::after { content: "5"; }
        &:nth-child(7)::after { content: "6"; }
        &:nth-child(8)::after { content: "7"; }
        &:nth-child(9)::after { content: "8"; }
        &:nth-child(10)::after { content: "9"; }
        &:nth-child(11)::after { content: "10"; }
      }

      & > .placeholder-card {
        position: absolute;
        width: 100%;
        height: 100%;
      }

      & > .highlight-card {
        max-height: 155px;
        margin-top: 8px;
        margin-left: 50px;

        @include desktop { width: 315px; }
      }
    }

    & .content > .placeholder-card { margin-bottom: 6px; }
  }
}
</style>
