<template>
  <div class="LoginContent">
    <login-modal
      v-if="error.type === 'notActive'"
      :opened="error.type === 'notActive'"
      maintext="Você não ativou sua conta ainda!"
      text="Para ativar a sua conta, clique no botão abaixo e insira o seu CPF"
      type="sad"
      button="Ativar conta"
      redirect="ativacao-ativar-conta"
      @close="$emit('clear-error')"
    />

    <login-modal
      v-if="error.type === 'notEnabled'"
      :opened="error.type === 'notEnabled'"
      maintext="CPF não habilitado para ser gestor"
      text="Ops! Você não está habilitado como gestor desta conta."
      type="sad"
      button="Ok"
      @close="$emit('clear-error')"
    />

    <login-modal
      v-if="error.type === 'finishedTrial'"
      :opened="error.type === 'finishedTrial'"
      maintext="O seu período de teste grátis acabou"
      text="Para continuar utilizando as nossas funcionalidades, fale com o time de vendas"
      hide-character
      button="Falar com vendas"
      @close="isButtonClick => $emit('talk-with-sales', isButtonClick)"
    />

    <div class="LoginBox">
      <link
        rel="preload"
        fetchpriority="low"
        as="image"
        href="/img/kaledo-pattern.png"
        type="image/png"
      />
      <login-leftside />
      <div class="right-side">
        <div class="logo">
          <c-logo
            kaledo
          />
        </div>
        <c-form
          @submit="$emit('submit', userData)"
          class="form"
        >
          <c-input
            required
            data-cy="login-document-input"
            data-vv-delay="10"
            icon="contacts"
            placeholder="Insira seu CPF"
            class="input-data"
            name="CPF"
            validation-icon="warning-circle"
            icon-size="20"
            v-validate="'required'"
            :disabled="isLoading"
            :mask="'###.###.###-##'"
            :value="userData.document"
            :feedback-show="errors.has('CPF') || !isDocumentValid"
            :feedback-message="!isDocumentValid ? 'CPF inválido' : errors.first('CPF')"
            @click.native="touched = true"
            @input="(cpf) => userData.document = cpf.replace(/\D/g, '')"
          />
          <c-input
            data-cy="login-password-input"
            required
            v-validate="'required'"
            data-vv-delay="10"
            icon="lock2"
            placeholder="Insira sua senha"
            type="password"
            class="input-data"
            name="Senha"
            :value="userData.password"
            validation-icon="warning-circle"
            icon-size="20"
            :disabled="isLoading"
            :feedback-show="errors.has('Senha') || hasInvalidCredentialsError"
            :feedback-message="hasInvalidCredentialsError ? 'CPF ou senha incorretos.' : errors.first('Senha')"
            @input="(pwd) => userData.password = pwd"
          />
          <div class="under-input">
            <router-link
              data-cy="forget-pwd-link"
              :to="{ name: 'esqueci-minha-senha' }"
              class="forget-pwd"
            >
              Esqueci a senha
            </router-link>
          </div>

          <div class="btngroup">
            <c-button
              data-cy="login-button"
              class="btn"
              size="lg"
              primary
              :disabled="!isFormValid || isLoading"
            >
              Entrar
            </c-button>
            <span class="lines">OU</span>
            <c-button
              data-cy="activate-account-button"
              default-border
              size="lg"
              class="btn pink-border"
              :disabled="isLoading"
              @click.prevent="$router.push({ name: 'ativacao-ativar-conta' })"
            >
              Ativar conta
            </c-button>
          </div>
        </c-form>
      </div>
    </div>
  </div>
</template>

<script>
// import CForm from '@/components/CComponents/CForm'
// import CLogo from '@/components/CComponents/CLogo'
import { isCPF } from '@/modules/validate/validators.js'
// import LoginLeftside from '@/components/Login/LoginLeftside'
// import LoginModal from '@/components/Login/LoginModal'

export default {
  components: {
    CForm: () => import('@/components/CComponents/CForm'),
    LoginLeftside: () => import('@/components/Login/LoginLeftside'),
    LoginModal: () => import('@/components/Login/LoginModal'),
    CLogo: () => import('@/components/CComponents/CLogo')
  },
  props: {
    error: {
      type: Object,
      required: true
    },
    isLoading: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      userData: {
        document: '',
        password: ''
      }
    }
  },
  computed: {
    isDocumentValid () {
      return isCPF(this.userData.document) || !this.touched || !this.userData.document
    },
    isFormValid () {
      return (this.userData.document.length === 11 && isCPF(this.userData.document)) &&
        this.userData.password.length > 0
    },
    hasInvalidCredentialsError () {
      return this.error && this.error.type === 'invalidCredentials' && this.userData.password !== ''
    }
  },
  watch: {
    userData: {
      handler: function (value) {
        if (this.error.type === 'invalidCredentials') { this.$emit('clear-error') }
      },
      deep: true
    }
  }
}
</script>

<style lang="scss">
  @import '~@/styles/reference';
  .LoginContent {
    @include LoginContent;

    .LoginBox {
      @include LoginBox;

      @include desktop {
        width: 780px;
        min-width: 600px;
      }

      & > .right-side {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        padding: 0 40px;

        @include desktop {
          width: 55%;
        }

        & > .logo {
          margin-bottom: 40px;
          margin-top: 50px
        }

        & > .form {
          min-width: 280px;
          max-width: 320px;

          & > .input-data {
            margin-bottom: 35px;
            input {
              &:hover,
              &:active,
              &:focus {
                border-color: $color-primary-login;
                box-shadow: 0 0 0 1px $color-primary-login;
                outline: $color-primary-login;
              }
            }
          }

          & > .under-input {
            margin-top: -24px;
            display: flex;
            position: relative;
            justify-content: space-between;

            & > .wrongdata {
              font-size: 12px;
              color: $failure-color;
              font-family: $base-font-family;
              font-weight: 100;
              padding-right: 100px;
              position: absolute;
              margin-top: 6px;
              width: 100%;

              & > .icon {
                fill: #ff7987;
                position: absolute;
                top: -2px;
              }
              & > .warning-text {
                padding-left: 20px;
                position: absolute;
                width: 100%;
                top: -3px;
              }
            }
            & > .forget-pwd {
              z-index: 4;
              position: absolute;
              font-size: 12px;
              right: 0;
              color: $base-color;
              font-family: $base-font-family;
              font-weight: 100;
              text-decoration: none;
              cursor: pointer;
            }
          }
          & > .btngroup {
            display: flex;
            align-items: center;
            flex-direction: column;
            margin-top: 40px;
            margin-bottom: 40px;

            & > .btn {
              width: 100%;
              margin: 10px 0px;
              font-family: $base-font-family;
              font-weight: 100;

              &.pink-border:hover,
              &.pink-border:active,
              &.pink-border:focus {
                border: 1px solid $color-primary-login;
                box-shadow: 0 0 0 1px $color-primary-login;
                outline: $color-primary-login;
              }
            }

            & > .lines{
              font-size: 12px;
              width: 100%;
              text-align: center;
              margin: 15px auto;
              overflow: hidden;
              color: $color-grey-text-primary;
              font-family: $base-font-family;
              font-weight: 100;

              &:before {
                margin-left: -100%;
              }
              &:after {
                margin-right: -100%;
              }
              &:before,
              &:after {
                display: inline-block;
                content: '';
                background-color: #F1F1F4;
                height: 1px;
                width: 100%;
                vertical-align: middle;
                box-sizing: border-box;
                border: solid #FFF;
                border-width: 0 10px;
              }
            }
          }
        }
      }
    }
  }
</style>
