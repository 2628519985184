<template>
  <div class="change-account-container">
    <c-title
      icon="home-user"
      class="title"
    >
      Clubes
    </c-title>

    <change-account
      :companies="companies"
      @change-account="changeAccount"
    />
  </div>
</template>

<script>
import ChangeAccount from '@/components/Accounts/ChangeAccount'
import { mapGetters, mapActions } from 'vuex'
import {
  CONFIG_COMPANY_LIST,
  CHANGE_ACCOUNT,
  AUTH,
  COMPANY,
  CHANGE_TO_COMPANY
} from '@/store/types'

export default {
  components: {
    ChangeAccount
  },

  computed: {
    ...mapGetters({
      companies: CONFIG_COMPANY_LIST,
      auth: AUTH,
      company: COMPANY,
      changeToCompany: CHANGE_TO_COMPANY
    })
  },

  methods: {
    ...mapActions({
      getToken: CHANGE_ACCOUNT
    }),

    async changeAccount (account) {
      await this.getToken(account)

      if (this.auth.token) {
        await window.dataLayer.push({
          event: 'troca-de-contas',
          'company_name': this.company.name,
          'changed_to_company': this.changeToCompany.name
        })

        document.location.href = process.env.reloadUrl
      }
    }
  }
}
</script>

<style lang="scss">
@import '~@/styles/reference';

.change-account-container {
  & > .title {
    margin: 12px 12px 0 12px;
  }
}
</style>
