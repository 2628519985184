<template>
  <media-container />
</template>

<script>
import MediaContainer from '@/containers/Communication/Media/MediaContainer.vue'

export default {
  components: { MediaContainer }
}
</script>
