<template>
  <c-card class="reports-card">
    <div class="top">
      <c-title class="title">
        {{ name }}
      </c-title>
      <c-button
        icon="download-circle"
        class="download-btn"
      />
    </div>
    <p class="text-card">
      {{ text }}
    </p>
  </c-card>
</template>

<script>
import CCard from '@/components/CComponents/CCard'

export default {
  components: {
    CCard
  },
  props: {
    name: {
      type: String
    },
    text: {
      type: String
    },
    period: {
      type: Boolean
    },
    url: {
      type: String
    }
  }
}
</script>

<style lang="scss">
  @import '~@/styles/reference';

  .reports-card {
    padding: 20px;
    width: 100%;
    margin: 0 5px 10px 5px;
    cursor: pointer;

    @include hoverCard;

    @media screen and (min-width: 540px) and (max-width: 967px){
      width: calc(50% - 10px);
    }

    @media screen and (min-width: 968px){
      width: calc((100%/3) - 10px);
    }

    & >.top {
      display: flex;
      justify-content: space-between;

      & >.download-btn {
        transform: translate(30%, -30%);
      }
    }
    & > .text-card {
      margin-top: 10px;
    }
  }
</style>
