<template>
  <div :class="['campaign-information-step-form', stepClass]">
    <div class="number">
      <p class="text">
        {{ number }}
      </p>
    </div>

    <c-input
      v-if="isTextArea"
      class="input"
      textarea
      rows="5"
      placeholder="Insira o passo"
      maxlength="100"
      :value="step"
      @input="v => emitter(number, v)"
    />
    <p
      v-else
      class="description"
    >
      {{ step }}
    </p>

    <div
      v-if="!last"
      class="arrows"
    >
      <c-icon
        v-for="_ in 3"
        :key="_"
        icon="chevron-down"
        size="11"
        :style="{
          opacity: `calc(1 - ((3 - ${_}) * .25))`,
          bottom: `calc((${_} - 1) * 6px)`
        }"
      />
    </div>

    <c-input
      v-if="number === 2 && event === 'online'"
      class="voucher"
      placeholder="Insira o cupom"
      :value="voucher"
      @input="v => $emit('voucher', { voucher: v })"
    />
  </div>
</template>

<script>

export default {
  props: {
    step: {
      type: [String, Number, Array],
      default: () => ''
    },
    isTextArea: Boolean,
    number: {
      type: Number,
      required: true
    },
    last: Boolean,
    voucher: String,
    event: String
  },

  computed: {
    stepClass () {
      const stepClass = {
        '-last': this.last,
        '-after-voucher': this.number === 3 && this.event === 'online',
        '-has-voucher': this.voucher,
        '-subline': this.event === 'online' && this.number === 2
      }
      return stepClass
    }
  },

  methods: {
    emitter (property, value) {
      this.$emit('update', { index: property - 1, value })
    }
  }
}
</script>

<style lang="scss">
@import '~@/styles/reference';

.campaign-information-step-form {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  position: relative;

  &:not(.-last) > .input:after {
    content: '';
    position: absolute;
    left: 0; bottom: -18px;
    height: 1px;
    background-color: rgba(#565F7D, .1);;
    width: 100%;
  }

  & > .input {
    width: 100%;
    margin: { left: 30px; top: 40px;}
  }

  &.-subline {
    & > .input {
      &:not(:last-child){
        &:nth-child(2) {
          &:after {
            bottom: -78px;
          }
        }
      }
    }
    & > .arrows {
      transform: translate(-200%, calc(285% + 6px));
    }
  }

  & > .description {
    flex: 1 1 0;
    padding: 20px;
    margin-left: 10px;
  }

  &.-after-voucher {
    margin-top: 60px;
  }

  &:not(.-last) {
    & > .description { border-bottom: 1px solid $border-color; }
  }

  & > .number {
    flex: 50px 0 0;
    margin-top: 10px;
    display: flex;
    justify-content: flex-start;

    & > .text {
      font-size: 16px;
      height: 34px;
      width: 34px;
      border-radius: 100%;
      padding-top: 3px;
      background-color: $primary-color-placeholder;
      background-color: var(--color-primary);
      display: flex;
      align-items: center;
      justify-content: center;
      color: white;
      font-weight: 700;

      &:after { content: 'º' }
    }
  }

  & > .arrows {
    display: flex;
    flex-flow: column nowrap;
    position: absolute;
    left: 33px;
    bottom: 0;
    transform: translate(-200%, calc(100% + 6px));

    & > .c-icon {
      position: relative;
      fill: $primary-color-placeholder;
      fill: var(--color-primary);
    }
  }

  &.-has-voucher > .description { padding-bottom: 60px; }

  & > .voucher {
    position: absolute;
    left: 80px;
    bottom: -56px;
  }
}
</style>
