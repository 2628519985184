<template>
  <div>
    <nuxt />
    <c-feedback
      :type="feedback.type"
      :title="feedback.title"
      :text="feedback.text"
      :icon="feedback.icon"
      @close="closeFeedback"
    />
  </div>
</template>
<script>
import CFeedback from '@/components/CComponents/CFeedback'


import * as types from '@/store/types'
import { mapGetters, mapActions } from 'vuex'

export default {
  components: {
    CFeedback
  },
  computed: {
    ...mapGetters({
      feedback: types.FEEDBACK
    })
  },
  methods: {
    ...mapActions({
      closeFeedback: types.FEEDBACK_CLOSE
    })
  }
}
</script>

<style lang="scss" src="@/styles/style.scss" />
