<template />

<script>
import { mapState, mapActions } from 'vuex'
import * as types from '@/store/types'

export default {
  name: 'Wootric',

  computed: {
    ...mapState({
      userId: state => state.auth.userId,
      user: state => state.user.user
    })
  },
  async created () {
    await this.getUser(this.userId)
    window.dataLayer.push({
      event: 'set-wootric-settings',
      user_email: this.user.email,
      user_created_at: this.user.created_at
    })
    window.dataLayer.push({
      event: 'wootric-start'
    })
  },
  methods: {
    ...mapActions({
      getUser: types.USER
    })
  }
}
</script>

<style>
</style>
