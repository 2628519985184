<template>
  <div
    class="campaign-details"
    data-cy="campaign-details"
  >
    <div
      class="content"
      v-if="activeTab === 'campaign'"
    >
      <campaign-information
        v-if="campaign && (Object.keys(campaign).length > 0)"
        :campaign="campaign"
        :is-exclusive="isExclusive"
        v-on="$listeners"
      />
    </div>

    <div
      class="content"
      v-if="activeTab === 'partner'"
    >
      <partner-information
        v-if="partner && (Object.keys(partner).length > 0)"
        :partner="partner"
        :campaign-slug="$route.params.campaign"
      />
    </div>
  </div>
</template>

<script>
import PartnerInformation from '@/components/Partners/PartnerInformation'
import CampaignInformation from '@/components/Campaign/Information/CampaignInformation'

export default {
  components: { PartnerInformation, CampaignInformation },
  props: {
    campaign: {
      type: Object,
      required: true
    },
    partner: {
      type: Object,
      required: true
    },
    activeTab: {
      type: String,
      required: true
    },
    isExclusive: {
      type: Boolean,
      required: true
    }
  }
}
</script>

<style lang="scss">
@import '~@/styles/reference';

.campaign-details {

  & > .content {
    @include desktop { @include container; }
  }
}
</style>
