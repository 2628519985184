<template>
  <form
    class="search-field"
    @submit.prevent="submit"
  >
    <c-input
      watch
      icon="search"
      v-bind="$attrs"
      class="field"
      round
      background
      type="text"
      :value="term"
      @input="input"
      :disabled="isLoading"
      @reset="resetSearch()"
      @focusout="$emit('focusout')"
      :placeholder="placeholder"
    />
    <c-button
      v-if="term.length"
      type="button"
      size="sm"
      class="reset"
      icon="cross"
      @click.stop="$emit('reset')"
    />
  </form>
</template>

<script>
export default {
  props: {
    isLoading: Boolean,

    term: {
      type: String,
      default: ''
    },

    placeholder: {
      type: String,
      default: 'Procurar...'
    }
  },
  mounted () {
    const focus = () => {
      const element = this.$refs.field
      if (!element) { return setTimeout(focus, 200) }
      element.focus()
    }
    focus()
  },
  methods: {
    input (event) {
      const value = !this.isDesktop && event.target
        ? event.target.value
        : event
      this.$emit('input', value)
    },
    submit (event) {
      this.$emit('submit', event)
    }
  }
}
</script>

<style lang="scss">
@import '~@/styles/reference';

.search-field {
  position: relative;
  display: flex;
  align-items: center;

  & > .field {
    flex: 1;
    height: 100%;
    padding: 0;
    border: 0;
    font-size: 16px;
    font-weight: 500;
    font-family: $title-font-family !important;
    background-color: transparent;

    &:focus {
      outline: none;
      box-shadow: none;
    }

    &::-ms-clear { display: none; }

    &::-webkit-search-cancel-button { display: none; }

    &::-webkit-search-results-button { display: none; }
  }

  & > .reset {
    position: absolute;
    right: 5px;

    svg {
      fill: #33579B !important;
    }
    & > .c-icon {
      width: 12px !important;
      height: 12px !important;
    }
    &:hover {
      border: none !important;
      outline: none;
      box-shadow: none;
    }
  }

  & > .icon { margin-right: 10px }

  @include desktop {
    & > .c-input-container.field {
      margin: {
        top: 10px;
        bottom: 10px;
      }

      & > .input {
        width: 400px;
        outline: none;
      }
    }
  }
}
</style>
