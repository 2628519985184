<template>
  <div
    class="layout-menu"
    data-cy="layout-menu"
  >
    <div class="header">
      <h3 class="name">
        {{ user.name }}
      </h3>
      <p class="cpf">
        {{ user.document }}
      </p>
      <p class="club">
        {{ clubName }}
      </p>
      <c-spinner v-if="loading" />
      <div
        v-else
        class="wallet-balance"
        @click="goToWallet()"
      >
        <div class="balance">
          <c-icon
            class="icon"
            icon="wallet-circle"
            size="14"
          />
          <span>{{ formatedBalance }}</span>
        </div>
        <k-icon
          class="button-icon"
          icon="arrow-right"
          size="20"
        />
      </div>
      <div
        v-if="company.trial"
        class="trial-days-left"
        data-cy="trial-days-left"
      >
        <p>
          Seu teste grátis acaba em {{ trialDays }} dia{{ trialDays > 1 ? 's' : '' }}
        </p>
      </div>
    </div>
    <div class="config">
      <div
        class="button"
        @click="handleConfigButton"
        data-cy="config-button"
      >
        <k-icon
          class="icon"
          icon="settings-gear"
          size="20"
        />
        <span class="button-title">Configurações</span>
      </div>
      <div
        class="button"
        data-cy="personalization-button"
        @click="handlePersonalizationButton"
      >
        <k-icon
          class="icon"
          icon="pen-edit5"
          size="20"
        />
        <span class="button-title">Personalização</span>
      </div>
    </div>
    <div
      v-if="clubUrl && company.url && company.name"
      class="access-company"
    >
      <c-button
        data-cy="access-company-button"
        class="access-company-button"
        icon="chevron-right"
        icon-size="sm"
        size="lg"
        external
        is-bold
        :to="clubUrl"
        target="_blank"
      >
        Acessar clube
      </c-button>
    </div>

    <div
      v-if="companies.length && companies.length > 1"
      :class="['select-account', { '-add-padding': sixCompanies.length <= 2 }]"
      data-cy="select-account"
    >
      <div
        :class="['accounts', { '-align-center' : sixCompanies.length <= 2 }]"
        v-for="(company) in sixCompanies"
        :key="company.id"
        :title="company.name"
      >
        <c-button
          class="company-logo"
          data-cy="button-select-account"
          @click="$emit('change-account', company.id)"
        >
          <c-image
            :src="company.logo"
            :placeholder="imagePlaceholder"
          />
        </c-button>

        <p class="company-name">
          {{ company.name }}
        </p>
      </div>
    </div>

    <div
      v-if="companies.length > 6"
      class="footer"
      @click="openModal = true"
    >
      <span
        class="footer-title"
        data-cy="open-all-clubs-button"
      >{{ `Ver todos os clubes (${companies.length})` }}</span>
    </div>

    <div
      class="footer"
      :is="'a'"
      :target="'_blank'"
      :href="supportPath"
      @click="$emit('close')"
    >
      <c-icon
        icon="question-circle"
        size="15"
      />
      <span class="footer-title">Suporte</span>
    </div>

    <div
      class="footer"
      @click="$emit('logout')"
    >
      <c-icon
        icon="exit"
        size="15"
      />
      <span
        class="footer-title"
        data-cy="logout-button"
      >Sair</span>
    </div>

    <change-account-modal
      :companies="companies"
      :is-opened="openModal"
      @change-account="account => $emit('change-account', account)"
      @close="openModal = false"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import CImage from '@/components/CComponents/CImage'
import { KIcon } from 'kaledo-components'
import { COMPANY, COMPANY_BALANCE } from '@/store/types'
import moment from 'moment'
import CSpinner from '../CComponents/CSpinner.vue'

export default {
  components: {
    CImage,
    KIcon,
    ChangeAccountModal: () => import('@/components/Accounts/ChangeAccountModal'),
    CSpinner
  },

  props: {
    user: Object,
    clubName: String,
    companies: Array,
    supportPath: String,
    clubUrl: String
  },

  data () {
    return {
      imagePlaceholder: require('@/assets/img/campaign-placeholder.svg'),
      openModal: false,
      loading: false
    }
  },

  computed: {
    ...mapGetters({
      company: COMPANY
    }),
    formatedBalance () {
      if (!this.company.balance) return 'R$ 0,00'
      return (this.company.balance/100).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
    },
    sixCompanies () {
      return this.companies.length > 6
        ? this.companies.slice(0, 6)
        : this.companies
    },

    trialDays () {
      const start = moment.unix(this.company.trial_start_date)
      const today = moment(new Date())
      const daysLeft = today.diff(start, 'days')
      return process.env.TRIAL_TIME - daysLeft
    }
  },

  async mounted () {
    this.loading = true
    await this.getBalance()
    this.loading = false
  },

  methods: {
    ...mapActions({
      getBalance: COMPANY_BALANCE
    }),
    goToWallet () {
      window.dataLayer.push({
        event: 'carteira-acessar',
        company_name: this.company.slug
      })
      this.$router.push({ name: 'carteira' })
    },
    handleConfigButton () {
      this.$router.push({ name: 'configuracao' })
      this.$emit('close')
      window.dataLayer.push({
        event: 'configuracoes-visualizar',
        company_name: this.company.slug
      })
    },
    handlePersonalizationButton () {
      this.$router.push({ name: 'personalizacao' })
      this.$emit('close')
      window.dataLayer.push({
        event: 'pontos-personalizacao-visualizar',
        company_name: this.company.slug
      })
    }
  }
}
</script>

<style lang="scss">
@import '~@/styles/reference';

$items-group-spacing: 12px;

.layout-menu {
  width: 100%;
  max-width: 300px;
  box-shadow: 0 2px 6px 0 rgba(0,0,0,0.2);

  & > .header {
    display: flex;
    flex-flow: column;
    padding: 20px 10px 20px 10px;
    border-bottom: 1px solid $border-color;
    align-items: center;
    background-image: url('~/assets/img/pattern-oferta.svg');
    background-repeat: repeat-x;

    & > .name {
      font-size: 14px;
      font-family: $title-font-family;
      font-weight: 500;
      color: $title-color;
      padding-bottom: 5px;
    }

    & > .cpf {
      color: $text-color;
      font-family: $title-font-family;
      font-size: 12px;
      font-weight: bold;
    }

    & > .club {
      padding-top: 15px;
      color: $text-color;
      font-family: $title-font-family;
      font-size: 12px;
      text-align: center;
      font-weight: 500;
    }

    & > .wallet-balance {
      display: flex;
      width: 168px;
      padding: 8px;
      justify-content: space-between;
      align-items: center;
      border-radius: 8px;
      border: 1px solid var(--gray-400, #BEC0D3);
      background: var(--background-white, #FDFDFD);
      margin-top: 16px;
      &:hover {
        cursor: pointer;
        background: $hover-background-color;
      }
      > .balance {
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 4px;

        > .icon {
          color: #5E6684;
          width: 20px !important;
          height: 20px !important;
          margin-top: 2px;
        }

        > span {
          color: var(--brand-dark-blue, #202950);
          text-align: center;
          font-family: Nunito Sans;
          font-size: 12px;
          font-style: normal;
          font-weight: 700;
          line-height: 16px; /* 133.333% */
          letter-spacing: -0.122px;
          text-transform: uppercase;
        }
      }
      & > .button-icon {
        &:hover {
          cursor: pointer;
        }
      }
    }

    & > .trial-days-left {
      display: flex;
      width: 168px;
      padding: 8px;
      justify-content: center;
      align-items: center;
      gap: 10px;
      border-radius: 8px;
      border: 1px solid $accent-tertiary-100;
      margin-top: 16px;

      & > p {
        color: $brand-light-blue;
        text-align: center;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: Nunito Sans;
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: 140%; /* 16.8px */
        letter-spacing: -0.122px;
      }
    }
  }

  & > .config {
      display: flex;
      align-items: center;
      justify-content: center;
      border-top: 1px solid $border-color;
      border-bottom: 1px solid $border-color;
      flex-direction: column;

      & > .button {
        height: 45px;
        width: 100%;
        justify-content: center;
        align-items: center;
        display: flex;

        & > .icon {
          color: #121E48;
        }

        & > .button-title {
          cursor: pointer;
          opacity: 0.5;
          color: #121E48;
          font-family: Ubuntu;
          font-size: 12px;
          font-weight: 500;
          line-height: 20px;
          padding-left: 10px;
          text-transform: uppercase;
        }

        &:hover { background-color: $hover-background-color; }
      }
    }

  .access-company {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 16px 0;

    .access-company-button {
      width: 140px;
    }
  }

  & > .select-account {
    display: flex;
    padding: 0 0 30px 32px;
    flex-wrap: wrap;

    &.-add-padding {
      padding-right: 32px;
    }

    & > .accounts {
      width: 56px;
      margin: 0px 32px 0 0;

      &.-align-center {
        &:last-child {
          margin-right: 0;
        }
      }

      & > .company-logo {
        width: 58px;
        height: 58px;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        border: 1px solid $border-color;
        border-radius: 50%;
        box-shadow: 1px 1px 4px 0 rgba(0,0,0,0.1);

        &:hover {
          border: 3px solid var(--color-secondary);
        }

        img {
          width: 38px;
          display: block;
        }
      }

      & > .company-name {
        text-align: center;
        font-size: 12px;
        font-weight: bold;
        color: rgba(18,30,72,0.8);;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-top: 3px;
      }
    }
  }

  & > .more-accounts {
    display: flex;
    justify-content: center;
    align-self: center;
    font-weight: bold;
    padding-bottom: 25px;
    border-bottom: 1px solid $border-color;

    .c-button > .content > .text {
      font-weight: bold;
      opacity:  0.8;
    }
  }

  & > .footer {
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border-top: 1px solid $border-color;

    &:hover { background-color: $hover-background-color; }

    & > .footer-title {
      opacity: 0.5;
      color: #121E48;
      font-family: Ubuntu;
      font-size: 12px;
      font-weight: 500;
      line-height: 20px;
      padding-left: 10px;
      text-transform: uppercase;
    }
  }

  & > a.footer {
    text-decoration: none;
  }
}
</style>
