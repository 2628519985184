<template>
  <div
    data-cy="campaigns-resume"
    class="campaigns-resume"
  >
    <div class="campaigns-resume-title">
      <c-title
        class="title"
      >
        Campanhas de premiação
      </c-title>
      <c-info-button
        data-cy="campaigns-info-button"
        class="info-btn"
        @btn-click="$emit('info-click')"
      />
    </div>
    <div class="campaigns-list">
      <transition name="fade">
        <c-spinner v-if="loading" />
        <swiper-container
          data-cy="campaigns-resume-list"
          class="carousel"
          :options="options"
        >
          <swiper-item
            v-for="(campaign, index) in campaigns"
            :key="index"
            class="swiper-slide"
          >
            <award-campaign-card
              :campaign="campaign"
              text="Ver mais"
              @click.native="handleAwardClick('card', campaign)"
              @buttonEvent="handleAwardClick('button', campaign)"
            />
          </swiper-item>
          <div
            slot="button-prev"
            class="swiper-button -prev"
          >
            <k-icon
              class="icon"
              icon="left1"
              size="22"
            />
          </div>

          <div
            slot="button-next"
            class="swiper-button -next"
          >
            <k-icon
              class="icon"
              icon="arrow-right"
              size="22"
            />
          </div>
        </swiper-container>
      </transition>
    </div>
    <campaign-modal
      v-if="campaignInfo"
      :show-modal="showModal"
      :campaign="campaignInfo"
      @close="handleModalClose"
    />
  </div>
</template>

<script>

import { KIcon } from 'kaledo-components'
import CInfoButton from '@/components/CComponents/CInfoButton.vue'
import { swiper as SwiperContainer, swiperSlide as SwiperItem } from 'vue-awesome-swiper'
import AwardCampaignCard from '@/components/Awards/base/AwardCampaignCard'
import CampaignModal from '@/components/Awards/base/CampaignDetails/CampaignModal'

export default {
  components: {
    KIcon,
    CInfoButton,
    SwiperContainer,
    SwiperItem,
    AwardCampaignCard,
    CampaignModal
  },
  props: {
    campaigns: Array
  },
  data () {
    return {
      showModal: false,
      campaignInfo: null,
      loading: false,
      options: {
        slidesPerView: 'auto',
        spaceBetween: 10,
        slidesPerGroup: 3,
        navigation: {
          nextEl: '.swiper-button.-next',
          prevEl: '.swiper-button.-prev',
          disabledClass: '-disabled',
          hiddenClass: '-hidden'
        }
      }
    }
  },
  methods: {
    handleAwardClick (clickType, campaign) {
      if (clickType === 'button') {
        this.campaignInfo = campaign
        this.showModal = true
      } else {
        this.$emit('new-order', campaign.id)
      }
    },
    handleModalClose () {
      this.showModal = false
      this.campaignInfo = null
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/styles/reference';

.campaigns-resume {
  max-width: 1020px;
  margin-top: 40px;
  padding: 0 10px;

  > .campaigns-resume-title {
    display: flex;
    height: 35px;
    margin-bottom: 20px;

    > .title {
      max-width: 240px;
      margin-bottom: 30px;
    }
    > .info-btn {
      margin-top: -5px;
      margin-left: 5px;
    }
  }

  @include desktop { @include container; }
  .campaigns-list {

    .carousel {
      padding-bottom: 5px;
      display: flex;

      .swiper-button { display: none; }

      :deep .swiper-wrapper {
        display: flex;

        @include mobile {
          padding: 0 10px;
        }
      }

      @include desktop {
        position: relative;
        overflow: hidden;

        & > .swiper-wrapper {
          padding: 10px 0;
          display: flex;

          & > .swiper-slide { display: block; }
        }

        & > .swiper-button {
          display: block;
          position: absolute;
          cursor: pointer;
          top: 50%;
          background-color: $shadow-color;
          border-radius: 50%;
          width: 60px;
          height: 60px;
          transition: opacity .3s ease;

          & > .icon {
            fill: rgba(white, .8);
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
          }

          &.-disabled,
          &.-hidden { opacity: 0; }

          &.-prev {
            left: 0;
            transform: translate(-50%, -50%);

            & > .icon {
              right: 8px;
              position: absolute;
            }
          }

          &.-next {
            right: 0;
            transform: translate(50%, -50%);

            & > .icon {
              left: 8px;
              position: absolute;
            }
          }
        }
      }
    }
  }
}
.swiper-slide {
  display: inline-block;
}

.swiper-wrapper { white-space: nowrap; }
</style>
