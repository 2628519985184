<template>
  <media-edit-container :media="media" />
</template>

<script>
import MediaEditContainer from '@/containers/Communication/Media/Edit/MediaEditContainer.vue'

export default {
  name: 'Editar',

  components: { MediaEditContainer },

  props: {
    media: Object
  }
}
</script>

<style lang='scss' scoped>
</style>
