<template>
  <donut-card
    class="mobile-card"
    :loading="loading"
    :graph-data="usersPerMobileBrandingData"
    :error="error"
  />
</template>

<script>
import DonutCard from '@/components/Dashboard/base/GraphCard.vue'
import { mapGetters, mapActions } from 'vuex'
import { DASH_USERS_PER_MOBILE_BRANDING } from '@/store/types'

export default {
  name: 'UsersPerMobileBranding',
  components: {
    DonutCard
  },
  props: {
    reloadDataQuery: Boolean
  },
  data () {
    return {
      error: false,
      loading: true
    }
  },
  computed: {
    ...mapGetters({
      usersPerMobileBrandingData: DASH_USERS_PER_MOBILE_BRANDING
    })
  },
  watch: {
    reloadDataQuery () {
      if (this.reloadDataQuery) { this.getUsersPerGender() }
    }
  },
  mounted () {
    this.getUsersPerGender()
  },
  methods: {
    ...mapActions({
      getUsersPerMobileBrandingData: DASH_USERS_PER_MOBILE_BRANDING
    }),
    getUsersPerGender () {
      this.loading = true
      this.getUsersPerMobileBrandingData().then((success) => {
        this.error = !success
        this.loading = false
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.graph-card {
  height: 100% !important;

  :deep .inner {
    height: 100%;
  }
}
</style>
