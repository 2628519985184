<template>
  <spine-card
    :loading="loading"
    :error="error"
    :graph-data="userActivationData"
    :format-tab-label="true"
  />
</template>

<script>
import SpineCard from '@/components/Dashboard/cards/SpineCard.vue'
import { mapGetters, mapActions } from 'vuex'
import { DASH_ACCOUNTS_ACTIVATED_IN_THE_PERIOD } from '@/store/types'
export default {
  name: 'UserActivation',
  components: {
    SpineCard
  },
  props: {
    reloadDataQuery: Boolean
  },
  data () {
    return {
      error: false,
      loading: false
    }
  },
  computed: {
    ...mapGetters({
      userActivationData: DASH_ACCOUNTS_ACTIVATED_IN_THE_PERIOD
    })
  },
  watch: {
    reloadDataQuery () {
      if (this.reloadDataQuery) { this.getUserActivation() }
    }
  },
  mounted () {
    this.getUserActivation()
  },
  methods: {
    ...mapActions({
      getUsersActivationData: DASH_ACCOUNTS_ACTIVATED_IN_THE_PERIOD
    }),
    getUserActivation () {
      this.loading = true
      this.getUsersActivationData().then((success) => {
        this.error = !success
        this.loading = false
      })
    }
  }
}
</script>

<style lang='scss' scoped>
@import '~@/styles/reference.scss';
</style>
