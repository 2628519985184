<template>
  <div 
    class="media-card" 
    data-cy="media-card"
  >
    <div class="header">
      <h2 class="name">
        {{ media.name }}
      </h2>

      <div class="expire-date">
        <c-icon
          icon="clock"
          size="11"
        />

        <p class="expire-text">
          Até {{ endDate }}
        </p>
      </div>
    </div>

    <img
      class="image"
      :src="media.image"
      alt="imagem teste"
    />

    <div class="footer">
      <div
        class="future-banner-tag"
        v-if="isFutureBanner"
      >
        Agendado
      </div>
      <div />
      <div>
        <c-toggle
          sync
          await
          class="toggle"
          :height="30"
          :width="90"
          :labels="{ checked: 'Ativo', unchecked: 'Inativo' }"
          :value="localStatus"
          @input="changeToggle"
        />

        <c-button
          class="options-button"
          icon="options"
          data-cy="media-options"
          size="lg"
          @click.stop="showOptions = !showOptions"
        />
      </div>
    </div>

    <transition name="fade">
      <ul
        v-if="showOptions"
        class="options"
      >
        <li
          class="option"
        >
          <span
            class="link"
            @click="goToEditMedia()"
            data-cy="media-edit"
          >
            Editar
          </span>
        </li>

        <li class="option">
          <button
            class="button"
            @click="showConfirmDeleteModal = true"
            data-cy="media-delete"
          >
            Excluir
          </button>
        </li>
      </ul>
    </transition>

    <c-confirm-modal
      :btn-props="{ danger: true }"
      confirm-text="Excluir"
      character="embarrassed"
      title="Tem certeza que deseja excluir essa mídia?"
      message="Se excluí-la, não será possível visualizá-la no seu clube e nem recuperar os dados armazenados"
      :is-opened="showConfirmDeleteModal"
      @close="showConfirmDeleteModal = false"
      @confirm="confirmDelete"
    />
  </div>
</template>

<script>
import moment from 'moment'
import CConfirmModal from '@/components/CComponents/CConfirmModal'

export default {
  name: 'MediaCard',

  components: {
    CConfirmModal
  },

  props: {
    media: Object
  },

  data () {
    return {
      localStatus: false,
      showOptions: false,
      showConfirmDeleteModal: false
    }
  },

  computed: {
    endDate () {
      if (typeof this.media.end_date === 'string') {
        const date = new Date(this.media.end_date)
        return moment(date).format('L')
      }
      moment.locale('pt-br')
      return moment.unix(this.media.end_date).format('L')
    },
    isFutureBanner () {
      return moment.unix(this.media.start_date).isAfter(Date.now())
    }
  },

  created () {
    this.localStatus = !!this.media.status
  },

  methods: {
    changeToggle (value) {
      this.localStatus = !this.localStatus
      let toggleValue = value ? 0 : 1
      this.$emit('change-toggle-status', {
        media: this.media,
        status: toggleValue
      })
    },
    goToEditMedia () {
      this.$emit('editMediaAnalytics')
      this.$router.push({ path: `/comunicacao/${this.media.id}/editar/`, params: this.media })
    },
    confirmDelete () {
      this.$emit('delete-media', this.media.id)
      this.showConfirmDeleteModal = false
    }
  }
}
</script>

<style lang='scss' scoped>
@import '~@/styles/reference';

.media-card {
  width: 100%;
  max-width: 518px;
  position: relative;
  >.header, >.header >.expire-date {
    display: flex;
    align-items: center;
  }
  >.header {
  background: white;
    justify-content: space-between;
    padding: 18px 8px 18px 20px;
    >.name {
      font-size: 14px;
      color: var(--color-primary)
    }
    >.expire-date >.expire-text {
      font-size: 12px;
      margin-left: 5px;
    }
  }
  >.image {
    width: 100%;
    max-width: 100%;
    height: 107px;
    object-fit: cover;
  }
  >.footer {
    background: white;
    padding: 10px 8px 10px 0;
    text-align: right;
    display: flex;
    align-items: center;
    justify-content: space-between;
    >.future-banner-tag {
      margin-left: 16px;
    padding: 5px 10px;
    background: $success-color;
    font-size: 10px;
    color: white;
    text-transform: uppercase;
    border-radius: 5px;
    border: none;
    }
    .toggle {
      margin-right: 16px;
    }
  }
  .options {
    list-style: none;
    text-align: center;
    max-width: 130px;
    margin-top: 10px;
    margin-left: auto;
    background: white;
    position: absolute;
    right: 0;
    z-index: 1;
    box-shadow: 1px 1px 4px 0px rgba(0,0,0,0.1);
    >.option {
    transition: all 0.3s ease;
    &:hover {
      background: #CACACA;
      >.link, .button {
        color: white;
      }
    }
      >.link, .button {
        cursor: pointer;
        font-size: 10px;
        font-weight: bold;
        text-decoration: none;
        text-transform: uppercase;
        color: #CACACA;
        margin: 0 2px;
      }
      >.link {
        padding: 13px 40px;
        display: block;
      }
      >.button {
        background: none;
        border: none;
        padding: 13px 40px;
        border: none;
      }
    }
    >.option + .option {
      border-top: 1px solid #E0E2EA;
    }
  }
}
</style>
