<template>
  <div class="campaign-form-component">
    <div class="discounts">
      <c-select
        form-label="Desconto"
        class="discounts-item"
        track-by="value"
        display-by="label"
        :items="options"
        :value="campaign.symbol"
        @input="v => emitter('symbol', v)"
      />

      <c-input
        v-if="HideDiscountField"
        required
        class="value"
        maxlength="7"
        validation-icon="warning-circle"
        data-vv-delay="10"
        name="valor"
        :value="campaignValue"
        @input="v => emitter('value', v)"
        v-validate="'required'"
        placeholder="Insira o valor"
        v-money="symbolMoneyValidate"
        :type="campaign.symbol === '%' ? 'number' : undefined"
        step="0.1"
        min="0"
        :max="campaign.symbol === '%' ? 100 : undefined"
        :feedback-show="errors.has('valor')"
        :feedback-message="errors.first('valor')"
      />
    </div>

    <c-input
      required
      class="title"
      validation-icon="warning-circle"
      label="TÍTULO"
      textarea
      rows="5"
      maxlength="80"
      :value="campaign.title"
      @input="v => emitter('title', v)"
      placeholder="Insira um título"
      name="titulo"
      data-vv-validate-on="blur"
      v-validate="'required'"
      :feedback-show="errors.has('titulo')"
      :feedback-message="errors.first('titulo')"
    />
    <div class="characteres">
      <span
        v-if="campaign.title"
        class="input-length"
      >{{ campaign.title.length + ' CARACTERES' }}</span>
    </div>

    <c-input
      class="input"
      icon="calendar"
      validation-icon="warning-circle"
      placeholder="Insira a data inicial"
      auto
      required
      label="Data inicial"
      name="start_date"
      autocomplete="off"
      ref="startDateRef"
      watch
      v-model="startDate"
      data-cy="start-date-button"
      @click.native="showStartDate = true"
    />

    <c-popover
      v-if="showStartDate"
      :position="['top', 'center']"
      :target="$refs.startDateRef"
      @blur="showStartDate = false"
    >
      <c-date
        inline
        monday-first
        class="datepicker input"
        data-cy="startDate"
        :calendar-class="'calendar'"
        :language="ptBR"
        :value="startDateUS"
        :disabled-dates="disabledStartDates"
        @input="startDate = $event"
      />
    </c-popover>

    <c-input
      class="date"
      icon="calendar"
      validation-icon="warning-circle"
      placeholder="Insira a data final"
      auto
      required
      label="Data final"
      name="end_date"
      v-model="endDate"
      data-cy="end-date-button"
      @click.native="showEndDate = !showEndDate"
      autocomplete="off"
      ref="endDateRef"
      watch
    />

    <c-popover
      v-if="showEndDate"
      :position="['top', 'center']"
      :target="$refs.endDateRef"
      @blur="showEndDate = false"
    >
      <c-date
        inline
        monday-first
        class="datepicker input"
        :calendar-class="'calendar'"
        :language="ptBR"
        :value="endDateUS"
        :disabled-dates="disabledEndDates"
        @input="endDate = $event"
      />
    </c-popover>
  </div>
</template>

<script>
import CPopover from '@/components/CComponents/CPopover'

import CDate from 'vuejs-datepicker'
import { ptBR } from 'vuejs-datepicker/dist/locale'

import moment from 'moment'
import { VMoney } from 'v-money'

export default {
  components: {
    CDate,
    CPopover
  },

  directives: { money: VMoney },

  props: {
    campaign: {
      type: Object
    }
  },

  data () {
    return {
      options: [
        { name: 'percent', value: '%', label: '%' },
        { name: 'money', value: 'R$', label: 'R$' },
        { name: '2x1', value: '2x1', label: '2x1' },
        { name: 'free', value: 'GRÁTIS', label: 'Grátis' },
        { name: 'money', value: 'U$', label: 'U$' },
        { name: 'money', value: '€', label: '€' }
      ],
      money: {
        decimal: ',',
        thousands: '.',
        prefix: '',
        precision: 2,
        masked: false /* doesn't work with directive */
      },
      showStartDate: false,
      showEndDate: false,
      ptBR
    }
  },

  computed: {
    symbolMoneyValidate () {
      const moneySymbols = ['R$', 'U$', '€']
      const isMoney = moneySymbols.indexOf(this.campaign.symbol) !== -1
      return isMoney ? this.money : null
    },

    campaignValue () {
      return this.campaign.symbol === '%'
        ? this.campaign.value
        : parseFloat(this.campaign.value).toFixed(2)
    },

    HideDiscountField () {
      return this.campaign.symbol !== 'GRÁTIS' && this.campaign.symbol !== '2x1'
    },

    startDate: {
      get () {
        return typeof (this.campaign || {}).start_date === 'string'
          ? moment((this.campaign || {}).start_date, 'YYYY-MM-DD').format('DD/MM/YYYY')
          : (this.campaign || {}).start_date
      },

      set (value) {
        this.showStartDate = false

        const date = typeof value === 'object'
          ? value.toLocaleDateString('pt-BR')
          : value

        this.emitter('start_date', date)
      }
    },

    endDate: {
      get () {
        return typeof (this.campaign || {}).end_date === 'string'
          ? moment((this.campaign || {}).end_date, 'YYYY-MM-DD').format('DD/MM/YYYY')
          : (this.campaign || {}).end_date
      },

      set (value) {
        this.showEndDate = false

        const date = typeof value === 'object'
          ? value.toLocaleDateString('pt-BR')
          : value

        this.emitter('end_date', date)
      }
    },

    startDateUS () {
      return this.startDate ? moment(this.startDate, 'DD/MM/YYYY').format('MM/DD/YYYY') : ''
    },

    endDateUS () {
      return this.endDate ? moment(this.endDate, 'DD/MM/YYYY').format('MM/DD/YYYY') : ''
    },

    disabledStartDates () {
      return {
        from: new Date(moment(this.endDate, 'DD/MM/YYYY').subtract(1, 'days'))
      }
    },

    disabledEndDates () {
      return {
        to: new Date(moment(this.startDate, 'DD/MM/YYYY').add(1, 'days'))
      }
    }
  },

  methods: {
    emitter (key, value) {
      setTimeout(() => {
        key === 'end_date' || key === 'start_date'
          ? this.$emit('update', { [key]: moment(value, 'DD/MM/YYYY').format('YYYY-MM-DD'), error: this.errors.all() })
          : this.$emit('update', { [key]: value, error: this.errors.all() })
      }, 50)
    }
  }
}
</script>

<style lang="scss">
@import '~@/styles/reference';

.campaign-form-component {
  display: flex;
  flex-direction: column;

  & > .discounts {
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    flex-basis: 100%;
    @include mobile { padding-top: 15px; }

    & > .discounts-item { flex-basis: 30%; }

    & > .value {
      flex-basis: 70%;
      margin-top: 25px; // fix height because label select
      padding-left: 15px;

      & > .feedback { padding-left: 15px; padding-bottom: 2px; }
    }
  }

  & > .title {
   margin-top: 10px;

   & > .feedback { padding-bottom: 6px; position: unset; }
  }

  & > .characteres {
    display: flex;
    justify-content: flex-end;

    & > .input-length {
      color: $title-color;
      opacity: 0.6;
      font-size: 12px;
    }
  }

  & .date {
    margin-top: 20px;

    & > .c-input > .input {
      &::before {
        width: 100%;
        color: $placeholder-color;
        content: attr(placeholder);
      }

      &:focus::before,
      &:valid::before { display: none }
    }
  }
}

.calendar {
  &.vdp-datepicker__calendar {
    color: #121E48 !important;

    & > header {
      & > .prev,
      & > .next {
        opacity: 0.5;
      }

      & > span {
        color: var(--color-primary) !important;
        font-weight: bold;
        opacity: 1;
      }
    }

    .cell {
      &.day-header {
        opacity: 0.3
      }

      &.day,
      &.month,
      &.year {
        opacity: 0.5;
        font-size: 14px;
      }

      &:not(.blank):not(.disabled) {
        &.day:hover,
        &.month:hover,
        &.year:hover {
          border: 1px solid var(--color-primary) !important;
          opacity: 1;
        }
      }

      &.selected {
        background-color: var(--color-primary) !important;
        color: #fff;
        font-weight: bold;
        opacity: 1;
      }
    }
  }
}
</style>
