<template>
  <div class="campaign-form-actions">
    <c-button
      class="btn cancel"
      size="lg"
      @click="$emit('back')"
    >
      Cancelar
    </c-button>

    <c-button
      icon="chevron-right"
      icon-size="sm"
      class="btn"
      size="lg"
      primary
      :disabled="isValid || isProcessing"
      @click="$emit('save')"
      data-cy="footer-buttons-save"
    >
      {{ saveButtonText }}
    </c-button>
  </div>
</template>

<script>

export default {
  props: {
    isProcessing: Boolean,
    isValid: Boolean,
    saveButtonText: {
      type: String,
      default: 'Salvar'
    }
  }
}
</script>

<style lang="scss">
@import '~@/styles/reference';

.campaign-form-actions {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;

  & > .btn {
    width: 50%;

    @include desktop {
      width: 190px;
    }
  }
  & > .cancel {
    margin-right: 10px;
  }
}
</style>
