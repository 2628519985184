<template>
  <section class="dashboard-container">
    <users-enabled-and-activated
      @submit="reloadQueries"
      :reload-data-query="loading"
      :disable-functions="isTabUnderConstruction"
    />
    <div class="buttons">
      <dash-button
        class="dash-button"
        v-for="(button, index) in buttons"
        :key="index"
        :label="button.label"
        :icon="button.icon"
        :is-selected="currentTab === button.tab"
        @click.native="changeTab(button.tab, button.event)"
      />
    </div>
    <transition
      name="fade"
      mode="out-in"
    >
      <component
        :is="currentTab"
        :loading="loading"
        :under-construction="isTabUnderConstruction"
        :tab-name="tabName"
      />
    </transition>
  </section>
</template>

<script>
import UsersEnabledAndActivated from '@/components/Dashboard/graphics/UsersEnabledAndActivated.vue'
import DashButton from '@/components/Dashboard/base/DashButton.vue'
import DashboardUsersTab from '@/containers/Dashboard/Tabs/DashboardUsersTab.vue'
import DashboardPartnersTab from '@/containers/Dashboard/Tabs/DashboardPartnersTab.vue'
import DashboardCommunicationTab from '@/containers/Dashboard/Tabs/DashboardCommunicationTab.vue'
import DashboardProductsTab from '@/containers/Dashboard/Tabs/DashboardProductsTab.vue'
import { mapGetters, mapMutations } from 'vuex'
import { COMPANY, DASH_DATES } from '@/store/types'
import moment from 'moment'

export default {
  name: 'DashboardContainer',
  components: {
    UsersEnabledAndActivated,
    DashButton,
    DashboardUsersTab,
    DashboardPartnersTab,
    DashboardCommunicationTab,
    DashboardProductsTab
  },
  data () {
    return {
      loading: false,
      currentTab: 'dashboard-users-tab',
      infoCardTexts: [
        'O período de comparação exibido se refere a mesma quantidade de dias selecionados no filtro, porém para uma data que antecede a data inicial selecionada.',
        'Ex: Utilizando o filtro "Últimos 30 dias", o período de comparação será os 30 dias anteriores a esses 30 dias selecionados.'
      ],
      buttons: [
        {
          label: 'Usuários e acessos',
          icon: 'users',
          tab: 'dashboard-users-tab',
          event: 'tab_users',
          under_construction: false
        },
        {
          label: 'Parcerias e ofertas',
          icon: 'dollar-payments-conversion',
          tab: 'dashboard-partners-tab',
          event: 'tab_perks',
          under_construction: true
        },
        {
          label: 'Comunicação',
          icon: 'media-library-folder',
          tab: 'dashboard-communication-tab',
          event: 'tab_communications',
          under_construction: true
        },
        {
          label: 'Produtos Kaledo',
          icon: 'kaledo',
          tab: 'dashboard-products-tab',
          event: 'tab_products',
          under_construction: true
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      company: COMPANY
    }),
    isTabUnderConstruction () {
      return this.buttons.find(button => button.tab === this.currentTab).under_construction
    },
    tabName () {
      return this.buttons.find(button => button.tab === this.currentTab).label
    }
  },
  created () {
    this.changeDates({
      start_date: moment().subtract(1, 'days').format('YYYY-MM-DD'),
      end_date: moment().subtract(1, 'day').format('YYYY-MM-DD')
    })
  },
  methods: {
    ...mapMutations({
      changeDates: DASH_DATES
    }),
    changeTab (tabName, event) {
      this.currentTab = tabName
      window.dataLayer.push({
        event: event,
        'company_name': this.company.name
      })
    },
    reloadQueries (dates) {
      this.loading = true
      this.changeDates(dates)
      setTimeout(() => { this.loading = false }, 1000)
    }
  }
}
</script>

<style lang='scss' scoped>
@import '~@/styles/reference';
.dashboard-container {
  max-width: 1020px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  .users-cards, .offers-cards, .buttons {
    display: flex;
    margin-top: 20px;
  }
  > .buttons {
    margin-bottom: 20px;
    > .dash-button:first-of-type {
      margin-left: 0px;
    }

    > .dash-button {
      margin: 0 5px;
      @include mobile {
        margin: 0 5px 5px 0;
      }
    }
    @include mobile {
      flex-wrap: wrap;
      margin-bottom: 15px;
    }
  }
}
</style>
