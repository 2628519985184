<template>
  <img
    v-bind="$attrs"
    :src="source"
    :style="style"
  />
</template>

<script>
export default {
  props: {
    src: String,
    placeholder: String,
    isBackground: Boolean
  },
  data () {
    return {
      virtual: null,
      isLoaded: false
    }
  },
  computed: {
    source () {
      const isPlaceholder = this.placeholder && !this.isLoaded
      const source = isPlaceholder ? this.placeholder : this.src
      return source
    },
    style () {
      const isEmpty = !this.isBackground || !this.source
      const style = isEmpty ? {} : { 'background-image': `url(${this.source})` }
      return style
    }
  },
  watch: {
    src () {
      this.isLoaded = false
      this.createVirtualImage()
    }
  },
  beforeDestroy () {
    if (!this.placeholder) return

    this.destroyVirtualImage()
  },

  mounted () {
    if (!this.placeholder) return

    this.createVirtualImage()
  },

  methods: {
    load () {
      this.isLoaded = true
    },

    createVirtualImage () {
      if (this.virtual) this.destroyVirtualImage()

      this.virtual = new Image()

      this.virtual.addEventListener('load', this.load)

      this.virtual.src = this.src
    },

    destroyVirtualImage () {
      if (!this.virtual) return

      this.virtual.removeEventListener('load', this.load)

      this.virtual = null
    }
  }
}
</script>
