<template>
  <activate-form />
</template>

<script>
import ActivateForm from '@/containers/Login/ActivateForm'

export default {
  components: {
    ActivateForm
  },

  layout: 'unauthenticated',

  middleware: 'checkauth'
}
</script>

