<template>
  <section
    class="partner-edit-container"
    v-if="!isLoading"
  >
    <partner-form
      :form-data="formData"
      :categories="categories"
      @update-form="updateForm"
      @upload-image="logoUpload = $event"
      ref="PartnerForm"
    />

    <contact-form
      :form-data="formData.contact"
      @update-form="updateForm"
      ref="ContactForm"
    />

    <address-form
      :search-term="addressFilter.search"
      :addresses="availableAddresses"
      :is-paginating="isPaginating"
      :pagination-total="addresses.total - deletedAddresses.length"
      :form-data="formData"
      @paginate-addresses="paginateAddresses"
      @search-addresses="searchAddress"
      @delete-address="deleteAddress"
      @add-address="addAddress"
      @update="updateAddress"
      ref="AddressForm"
    />

    <div class="form-actions">
      <div class="form-import">
        <span class="import-heading">OU</span>
        <c-input
          size="md"
          type="file"
          placeholder="Selecionar arquivo CSV"
          :text="isDesktop ? 'Selecionar arquivo' : 'Selecionar arquivo CSV'"
          :exts="['xls', 'csv']"
          @change="addressUpload = $event[0]['file']"
        />

        <span class="import-notice">
          <a
            class="link"
            href="https://s3-us-west-2.amazonaws.com/clube-newcore/public-content/model-enderecos.csv"
          >
            Clique aqui
          </a> para baixar um modelo do arquivo csv
        </span>
      </div>
    </div>

    <unified-registration
      v-if="showUnifiedRegistration"
      :companies="companies"
      :current-company="currentCompany"
      @selected-companies="ev => selectedCompanies = ev"
    />

    <div class="form-actions">
      <div class="form-submit">
        <c-button
          class="btn-action"
          size="lg"
          @click="$router.push({ name: 'parceiros' })"
        >
          Cancelar
        </c-button>

        <c-button
          primary
          :disabled="!isFormValid || isProcessing"
          icon="chevron-right"
          class="btn-action"
          icon-size="sm"
          size="lg"
          @click="saveAction"
        >
          Salvar
        </c-button>
      </div>
    </div>

    <confirm-unified-registration
      :is-opened="openConfirmModal"
      @accept-confirm-unified="submitAndClose()"
      @close-confirm-unified="openConfirmModal = false"
    />
  </section>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Loadable from '@/modules/loadable'
import * as types from '@/store/types'
import debounce from 'debounce'

import PartnerForm from '@/components/Partners/Editing/PartnerForm'
import ContactForm from '@/components/Partners/Editing/ContactForm'
import AddressForm from '@/components/Partners/Editing/AddressForm'
import UnifiedRegistration from '@/components/UnifiedRegistration/UnifiedRegistration'
import ConfirmUnifiedRegistration from '@/components/UnifiedRegistration/ConfirmUnifiedRegistration'

const search = debounce(function (filters) {
  this.filterAddresses({ ...filters })
}, 200)

const ADDRESS_DEFAULT = {
  street: '',
  number: '',
  code: '',
  city: '',
  state: ''
}

const FORM_DEFAULT = {
  name: '',
  logo: '',
  categories: [],
  description: '',

  address: [{ ...ADDRESS_DEFAULT }],
  contact: {
    name: '',
    email: '',
    phone: ''
  }
}

export default {

  components: {
    PartnerForm,
    ContactForm,
    AddressForm,
    UnifiedRegistration,
    ConfirmUnifiedRegistration
  },
  mixins: [ Loadable ],

  data () {
    return {
      formData: JSON.parse(JSON.stringify(FORM_DEFAULT)),
      logoUpload: undefined,
      addressUpload: undefined,
      isPaginating: false,
      deletedAddresses: [],
      isProcessing: false,
      addressFilter: {
        perk: this.$route.params['slug'] || undefined,
        search: undefined,
        limit: 3,
        page: 1
      },
      selectedCompanies: [],
      openConfirmModal: false,
      accept: false
    }
  },

  computed: {
    ...mapGetters({
      addresses: types.ADDRESS_PAGINATE,
      categories: types.CATEGORIES,
      partner: types.PARTNER,
      companies: types.CONFIG_COMPANY_LIST,
      currentCompany: types.CONFIG_COMPANY_ID,
      unifiedPermission: types.CONFIG_UNIFIED_REGISTRATION
    }),

    availableAddresses () {
      return this.addresses.list.filter(a => !this.deletedAddresses.includes(a.id))
    },

    isFormValid () {
      return this.formData.name.length > 0 &&
        this.formData.description.length > 0 &&
        this.formData.categories.length > 0
    },

    thisRefs () {
      return this.$refs
    },

    showUnifiedRegistration () {
      return !!this.unifiedPermission &&
        this.companies.length > 1 &&
        this.$route.name === 'parceiros-criar'
    }
  },

  methods: {
    ...mapActions({
      filterAddresses: types.ADDRESS_FILTER,
      getAddresses: types.ADDRESS_PAGINATE,
      getCategories: types.CATEGORIES,
      getPartner: types.PARTNER,
      partnerClear: types.PARTNER_CLEAR,

      uploadAddress: types.PARTNER_ADDRESS_UPLOAD,
      uploadLogo: types.PARTNER_LOGO_UPLOAD,
      updatePartner: types.PARTNER_UPDATE,
      savePartner: types.PARTNER_CREATE
    }),

    async processing (fun) {
      this.isProcessing = true
      await fun
      this.isProcessing = false
    },

    async load () {
      if (this.$route.params['slug']) {
        await Promise.all([
          this.getPartner({ slug: this.$route.params['slug'] }),
          this.filterAddresses({ ...this.addressFilter }),
          this.getCategories()
        ])

        const partner = JSON.parse(JSON.stringify(this.partner))

        Object.keys(FORM_DEFAULT).forEach((k) => {
          this.formData[k] = partner[k] || this.formData[k]
        })

        this.formData.address = this.formData.address.length === 0
          ? [{ ...ADDRESS_DEFAULT }]
          : this.formData.address
      } else {
        await this.getCategories()
      }
    },

    async paginateAddresses () {
      this.isPaginating = true
      this.addressFilter.page = this.addresses.current_page + 1
      await this.getAddresses({ ...this.addressFilter })

      this.isPaginating = false
    },

    async saveAction () {
      this.showUnifiedRegistration && !!this.selectedCompanies.length
        ? this.openConfirmModal = true
        : await this.processing(this.submitForm())
    },

    submitAndClose () {
      this.openConfirmModal = false
      this.processing(this.submitForm())
    },

    async submitForm () {
      let { ...partner } = this.formData

      partner.categories = partner.categories.map(c => c.id)
      partner.address = partner.address.filter((address) => {
        if (address.hasOwnProperty('id')) return true
        return Object.keys(address).every((key) => address[key] && address[key].length > 0)
      })

      if (this.logoUpload) {
        partner.logo = this.logoUpload
      }

      if (this.$route.params['slug']) {
        await this.updatePartner({ partner, id: this.partner.id })
      } else {
        if (this.selectedCompanies.length > 0) {
          partner = { ...partner, companies: this.selectedCompanies }
        }

        await this.savePartner({ partner })
      }

      if (this.addressUpload) {
        await this.uploadAddress({ partner: this.partner.id, file: this.addressUpload })
      }

      if (this.$route.params['slug']) {
        this.$router.push({ name: 'parceiros-slug', params: { slug: this.$route.params['slug'] } })
      } else {
        this.$router.push({ name: 'parceiros' })
      }
    },

    searchAddress (term) {
      if (typeof term === 'object') return false

      this.addressFilter.search = term || undefined
      this.addressFilter.page = term ? 1 : this.addressFilter.page

      if (term.length > 0) { search.call(this, { ...this.addressFilter }) } else { this.filterAddresses({ ...this.addressFilter }) }
    },

    updateForm (property) {
      if (property['contact']) { this.formData.contact = { ...this.formData.contact, ...property.contact } } else { this.formData = { ...this.formData, ...property } }
    },

    updateAddress ({ id, index: currIndex, property }) {
      const index = currIndex ||
        this.formData.address.findIndex(a => a.id === id)

      Object.keys(property).forEach((key) => {
        this.formData.address[index][key] = property[key]
      })
    },

    addAddress (address) {
      this.formData.address = [ ...this.formData.address, address ]
    },

    deleteAddress ({ id, index: currIndex }) {
      const index = currIndex ||
        this.formData.address.findIndex(ads => ads.id === id)

      this.formData.address.splice(index, 1)
      if (id) this.deletedAddresses.push(id)
    }
  },

  beforeDestroy () {
    this.partnerClear()
  }
}
</script>

<style lang="scss">
@import '~@/styles/reference';

// This should style all the c-inputs and c-selects in this form

.partner-edit-container {
  margin-top: 30px;
  @include desktop { @include container; }

  @include mobile { padding: 0 20px; }

  & > .form-actions {
    display: flex;
    flex-direction: column;
    width: 100%;

    & > .form-import {
      display: flex;
      align-items: center;
      flex-direction: column;

      // TODO: Maybe remove this on mobile, check with designer
      margin-bottom: 90px;

      & > .import-heading {
        display: flex;
        align-items: center;
        width: 100%;

        color: $title-color;
        font-size: 12px;
        text-transform: uppercase;

        &::before,
        &::after {
          content: "";
          display: block;

          height: 2px;
          width: 100%;
          flex-grow: 1;
          background-color: $border-color;
        }

        &::before { margin-right: 10px; }
        &::after { margin-left: 10px; }
      }

      & > .wrapper-c-input {
        width: 100%;
        max-width: 440px;
        margin: 20px 0;
      }

      & > .import-notice {
        font-size: 12px;
        color: $text-color;

        // TODO: Move color into a variable
        & > .link {
          color: #608CEA;
          text-decoration: none;
          &:hover { text-decoration: underline; }
        }
      }
    }

    & > .form-submit {

      display: flex;
      justify-content: flex-end;

      & > .btn-action {
        width: 100%;
        max-width: 190px;

        &:not(:last-child) { margin-right: 10px; }
      }
    }
  }

  .section-header {
    margin-bottom: 20px;
  }

  .section-controls {
    display: flex;
    align-items: center;
    flex-direction: column;

    & > .wrapper-c-input,
    & > .form-inline {
      margin-bottom: 30px;
    }

    .form-control {
      & > .c-input-container {
        position: relative;
        display: flex;
        align-items: center;

        & > .label {
          position: absolute;
          right: 100%;

          white-space: nowrap;
          padding-right: 10px;

          flex: initial;
          width: auto;
        }

        & > .input {
          flex-shrink: 0;
          &.-textarea { resize: none; }
        }

        & > .validation {
          position: absolute;
          top: 95%;

          font-size: 12px;
        }
      }

      @include mobile {
        & > .c-input-container {
          flex-direction: column;
          align-items: flex-start;

          & > .label { position: static; }
        }
      }
    }

    .form-control:not(.-inline) {
      width: 100%;
      max-width: 657px;

      & > .c-input-container > .input {
        @include desktop {
          width: 100%;
          max-width: 657px !important;
        }
      }
    }

    & > .form-inline {
      display: flex;
      @include desktop { width: 657px; }

      @include mobile {
        flex-direction: column;
        width: 100%;
      }

      & > .form-control {
        flex-grow: 1;

        &:not(:last-child) { margin-right: 130px; }
        &.-no-margin {
          margin-right: 0px;
        }

        & > .c-input-container {
          position: relative;
          display: flex;
          align-items: center;

          & > .label {
            position: absolute;
            right: 100%;

            white-space: nowrap;
            padding-right: 10px;

            flex: initial;
            width: auto;
          }

          & > .input { flex-shrink: 0; }
        }

        @include mobile {
          margin-right: 0 !important;
          &:not(:last-child) { margin-bottom: 30px; }

          & > .c-input-container {
            flex-direction: column;
            align-items: flex-start;

            & > .label { position: static; }
          }
        }
      }
    }

    .form-control-select {
      width: 100%;
      max-width: 657px;
      display: flex;
      align-items: center;

      margin-bottom: 30px;

      & > .label {
        position: absolute;
        right: 100%;
        padding-right: 10px;
        flex: initial;
        width: auto;
        white-space: nowrap;
      }

      & > .feedback { bottom: -19px; font-size: 12px; }

      & > .inner { width: 100%; }
      & > .inner > .multiselect {
        @include desktop { width: 657px; }
      }

      @include mobile {
        flex-direction: column;
        align-items: flex-start;

        & > .label { position: static; }
      }
    }
  }
}
</style>
