<template>
  <div class="personalization" data-cy="personalization-page">
    <my-coin/>
    <catalog/>
  </div>
</template>

<script>

import MyCoin from '@/components/Engagement/Personalization/MyCoin.vue'
import Catalog from '@/components/Engagement/Personalization/Catalog.vue'

export default {
  name: 'Personalization',
  components: {
    MyCoin,
    Catalog
  }
}
</script>

<style lang='scss' scoped>
@import '~@/styles/reference';

.personalization {
  margin-top: 35px;
  padding: 0 10px;
    @include desktop {
      margin-top: 48px;
      @include container;
    }
}
</style>
