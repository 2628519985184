<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="97" height="22" viewBox="0 0 97 22" fill="none">
    <path d="M38.1698 7.26314H34.113L30.6018 11.7592V2.78723H27.2422V18.8985H30.6018V14.2464L34.3404 18.8985H38.3063L33.4714 13.0128L38.1698 7.26314Z" fill="#8C92B1"/>
    <path d="M46.7378 8.21466C45.8638 7.37385 44.717 6.95093 43.3125 6.95093C41.7565 6.95093 40.4076 7.55006 39.2961 8.73324C38.1897 9.91137 37.6289 11.3765 37.6289 13.0833C37.6289 14.7901 38.1897 16.2552 39.2961 17.4333C40.4076 18.6165 41.7565 19.2156 43.3125 19.2156C44.717 19.2156 45.8638 18.7927 46.7378 17.9519V18.9035H50.0924V7.26308H46.7378V8.21466ZM45.9194 10.9737C46.4701 11.5225 46.7378 12.2123 46.7378 13.0782C46.7378 13.9493 46.4701 14.634 45.9194 15.1828C45.3687 15.7316 44.6917 15.9984 43.848 15.9984C43.0043 15.9984 42.3324 15.7316 41.7918 15.1828C41.2462 14.634 40.9835 13.9442 40.9835 13.0732C40.9835 12.2022 41.2462 11.5124 41.7918 10.9636C42.3374 10.4148 43.0094 10.148 43.848 10.148C44.6917 10.1581 45.3687 10.4249 45.9194 10.9737Z" fill="#8C92B1"/>
    <path d="M55.6143 2.11755H52.2598V18.8984H55.6143V2.11755Z" fill="#8C92B1"/>
    <path d="M63.3741 6.94592C61.5807 6.94592 60.0953 7.53499 58.9485 8.70306C57.8017 9.86609 57.2207 11.3413 57.2207 13.0783C57.2207 14.8304 57.8118 16.3106 58.9687 17.4686C60.1307 18.6266 61.6867 19.2106 63.5964 19.2106C65.8194 19.2106 67.532 18.3698 68.6788 16.7184L68.8203 16.512L66.0922 14.9462L65.9709 15.1073C65.4455 15.797 64.6826 16.1344 63.6419 16.1344C62.1515 16.1344 61.2169 15.5957 60.7976 14.493H69.1335L69.1689 14.3017C69.2447 13.8738 69.2851 13.4609 69.2851 13.0783C69.2851 11.3564 68.7243 9.89126 67.6179 8.71816C66.5014 7.54506 65.0767 6.94592 63.3741 6.94592ZM61.5857 10.5458C62.0505 10.1833 62.6517 10.002 63.3741 10.002C63.9804 10.002 64.526 10.1732 65.0009 10.5156C65.4051 10.8076 65.693 11.2355 65.8598 11.7994H60.737C60.9037 11.2758 61.1866 10.8529 61.5857 10.5458Z" fill="#8C92B1"/>
    <path d="M79.7124 8.20965C78.8536 7.37388 77.7118 6.95095 76.3073 6.95095C74.7361 6.95095 73.3771 7.55009 72.2707 8.73326C71.1643 9.9114 70.6035 11.3765 70.6035 13.0833C70.6035 14.7901 71.1643 16.2552 72.2707 17.4333C73.3822 18.6165 74.7412 19.2157 76.3073 19.2157C77.7067 19.2157 78.8485 18.7927 79.7124 17.957V18.9035H83.067V2.79224H79.7124V8.20965ZM76.8479 16.0035C76.0042 16.0035 75.3272 15.7366 74.7765 15.1878C74.2259 14.639 73.9581 13.9493 73.9581 13.0833C73.9581 12.2123 74.2259 11.5276 74.7765 10.9788C75.3272 10.43 76.0042 10.1631 76.8479 10.1631C77.6865 10.1631 78.3635 10.43 78.9041 10.9788C79.4497 11.5276 79.7124 12.2173 79.7124 13.0883C79.7124 13.9594 79.4497 14.6491 78.9041 15.1979C78.3585 15.7366 77.6865 16.0035 76.8479 16.0035Z" fill="#8C92B1"/>
    <path d="M95.2074 8.7232C94.0151 7.54506 92.5399 6.94592 90.8273 6.94592C89.1096 6.94592 87.6394 7.54506 86.4572 8.7232C85.275 9.90133 84.6738 11.3665 84.6738 13.0783C84.6738 14.7901 85.275 16.2552 86.4572 17.4333C87.6394 18.6115 89.1096 19.2106 90.8273 19.2106C92.545 19.2106 94.0151 18.6115 95.2074 17.4333C96.3997 16.2552 97.0009 14.7901 97.0009 13.0783C97.0009 11.3715 96.3947 9.90637 95.2074 8.7232ZM93.6463 13.0833C93.6463 13.9241 93.3836 14.5937 92.838 15.1325C92.2924 15.6762 91.6356 15.938 90.8273 15.938C90.0139 15.938 89.3622 15.6762 88.8266 15.1325C88.2911 14.5887 88.0284 13.9191 88.0284 13.0783C88.0284 12.2375 88.2911 11.5678 88.8266 11.0241C89.3622 10.4854 90.0139 10.2185 90.8273 10.2185C91.6407 10.2185 92.2974 10.4803 92.838 11.0241C93.3786 11.5729 93.6463 12.2425 93.6463 13.0833Z" fill="#8C92B1"/>
    <path d="M5.24911 0.204346C2.34922 0.204346 0 2.54551 0 5.43547V15.8977C0 18.7877 2.34922 21.1288 5.24911 21.1288C8.14901 21.1288 10.4982 18.7877 10.4982 15.8977V5.43547C10.4982 2.54551 8.14901 0.204346 5.24911 0.204346ZM7.42151 15.8977C7.42151 17.096 6.44646 18.0627 5.24911 18.0627C4.04672 18.0627 3.07672 17.091 3.07672 15.8977V5.43547C3.07672 4.2372 4.05177 3.27052 5.24911 3.27052C6.45151 3.27052 7.42151 4.24223 7.42151 5.43547V15.8977Z" fill="#8C92B1"/>
    <path d="M19.4561 1.73487C17.405 -0.309247 14.0807 -0.309247 12.0346 1.73487L1.53641 12.1971C-0.51474 14.2412 -0.51474 17.5541 1.53641 19.5932C3.58755 21.6323 6.91182 21.6373 8.95792 19.5932L19.4561 9.13095C21.5073 7.09186 21.5073 3.77899 19.4561 1.73487ZM6.78552 17.4282C5.93677 18.2741 4.55755 18.2741 3.7088 17.4282C2.86005 16.5824 2.86005 15.2079 3.7088 14.3621L14.207 3.90486C15.0558 3.05901 16.435 3.05901 17.2838 3.90486C18.1325 4.7507 18.1325 6.12519 17.2838 6.97103L6.78552 17.4282Z" fill="#8C92B1"/>
    <path d="M15.7472 10.6665C12.8473 10.6665 10.498 13.0077 10.498 15.8976C10.498 18.7876 12.8473 21.1288 15.7472 21.1288C18.6471 21.1288 20.9963 18.7876 20.9963 15.8976C20.9963 13.0077 18.6471 10.6665 15.7472 10.6665ZM15.7472 18.0626C14.5448 18.0626 13.5748 17.0909 13.5748 15.8976C13.5748 14.6994 14.5498 13.7327 15.7472 13.7327C16.9496 13.7327 17.9196 14.7044 17.9196 15.8976C17.9196 17.0959 16.9496 18.0626 15.7472 18.0626Z" fill="#8C92B1"/>
  </svg>
</template>

<script>
export default {

}
</script>

<style>
</style>
