<template>
  <div class="dashboard-users-tab">
    <user-activation :reload-data-query="loading" />
    <access-data :reload-data-query="loading" />
    <div class="donuts-cards">
      <users-per-age :reload-data-query="loading" />
      <main-interests-card :reload-data-query="loading" />
      <users-per-gender :reload-data-query="loading" />
      <users-per-platform :reload-data-query="loading" />
      <registration-info-card :reload-data-query="loading" />
      <users-per-mobile-branding :reload-data-query="loading" />
    </div>
  </div>
</template>

<script>
import UserActivation from '@/components/Dashboard/graphics/UserActivation.vue'
import AccessData from '@/components/Dashboard/graphics/AccessData.vue'
import UsersPerAge from '@/components/Dashboard/graphics/UsersPerAge.vue'
import UsersPerGender from '@/components/Dashboard/graphics/UsersPerGender.vue'
import UsersPerPlatform from '@/components/Dashboard/graphics/UsersPerPlatform.vue'
import RegistrationInfoCard from '@/components/Dashboard/cards/RegistrationInfoCard.vue'
import MainInterestsCard from '@/components/Dashboard/cards/MainInterestsCard.vue'
import UsersPerMobileBranding from '@/components/Dashboard/graphics/UsersPerMobileBranding.vue'

export default {
  name: 'DashboardUsersTab',
  components: {
    UserActivation,
    AccessData,
    UsersPerAge,
    MainInterestsCard,
    UsersPerGender,
    UsersPerPlatform,
    RegistrationInfoCard,
    UsersPerMobileBranding
  },
  props: {
    loading: Boolean
  }
}
</script>

<style lang="scss" scoped>
@import '~@/styles/reference.scss';

.dashboard-users-tab {
  width: 100%;
  .donuts-cards {
    display: grid;
    max-width: 100%;
    margin-top: 20px;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
    justify-content: center;
     @include desktop { @include container; }

    > .registration-info-card {
      grid-column: 2 / -1;
    }
  }

  @media screen and (max-width: $desktop-break) {
    .donuts-cards {
      grid-template-columns: repeat(2, 1fr);
      justify-items: center;

      > .graph-card {
        max-width: 100%;
        width: 100%;
      }

      > .registration-info-card {
        max-width: 100%;
        margin: 0 10px;
        grid-column: 1 / -1;
      }
    }
  }
  @include mobile {
    .donuts-cards {
      grid-template-columns: repeat(1, 1fr);
    }
  }
}
</style>
