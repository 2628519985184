<template>
  <div
    class="c-avatar"
    :class="'-' + size"
    :style="{ backgroundImage: `url(${image})` }"
  >
    <img
      v-if="!image"
      src="~/assets/img/userPlaceholder.svg"
      :alt="abreviation"
      class="placeholder"
    />
    <c-button
      class="change"
      v-if="changeImage"
      @click="$emit('change-image')"
      icon="camera3"
      size="lg"
    />
  </div>
</template>

<script>
export default {
  props: {
    image: String,
    changeImage: Boolean,
    size: {
      default: 'md',
      validator (value) {
        const sizes = ['sm', 'md', 'lg']
        const isValid = sizes.includes(value)
        return isValid
      }
    }
  },
  computed: {
    abreviation () {
      const abreviation = this.name && this.name.split(' ')
        .filter(name => name.length > 3)
        .reduce((abreviation, name) => abreviation + name[0], '')
        .toUpperCase()
      return abreviation
    }
  }
}
</script>

<style lang="scss">
@import '~@/styles/reference';

$c-avatar-small-size: 30px !default;
$c-avatar-medium-size: 50px !default;
$c-avatar-large-size: 85px !default;

@mixin c-avatar($c-avatar-size) {
  width: $c-avatar-size;
  height: $c-avatar-size;

  & > .placeholder {
    width: $c-avatar-size;
    height: $c-avatar-size;
  }
}

.c-avatar {
  position: relative;
  box-sizing: border-box;
  display: inline-flex;
  margin: 0;
  border-radius: 50%;
  background-size: cover;
  background-position: center;
  text-align: center;
  position: relative;

  & > .placeholder {
    box-sizing: border-box;
    border-radius: 50%;
  }

  & > .change {
    position: absolute;
    right: 0;
    bottom: 0;
    background-color: white;

    & > .c-icon {
      fill: $icon-color;
    }
  }

  &.-lg {
    @include c-avatar( $c-avatar-large-size );
  }

  &.-md {
    @include c-avatar( $c-avatar-medium-size );
  }

  &.-sm {
    @include c-avatar( $c-avatar-small-size );
  }
}
</style>
