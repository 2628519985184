<template>
  <div
    class="my-coin"
    data-cy="my-coin"
  >
    <c-title class="coin-title">
      Minha moeda
    </c-title>
    <transition name="fade">
      <c-spinner v-if="loading" />
      <empty-banner
        v-else-if="isCoinEmpty"
        title="Você ainda não possui uma moeda personalizada"
        button="Nova moeda"
        @button-click="handleEmptyButton"
      />
      <coin
        v-else
        :coin="coinData"
        @edit="openCoinModal = true"
      />
    </transition>
    <new-coin-modal
      v-if="openCoinModal"
      :is-opened="openCoinModal"
      :coin-data="coinData"
      @close="openCoinModal = false"
      @update-coin="handleUpdateCoin"
      @create-coin="handleCreateCoin"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { COMPANY, COIN, GET_COIN, CREATE_COIN, UPDATE_COIN } from '@/store/types'
import EmptyBanner from '@/components/General/EmptyBanner.vue'
import NewCoinModal from '@/components/Engagement/modals/NewCoinModal.vue'
import Coin from '@/components/Engagement/Personalization/Coin.vue'

export default {
  name: 'MyCoin',
  components: {
    EmptyBanner,
    Coin,
    NewCoinModal
  },
  data () {
    return {
      openCoinModal: false,
      loading: true
    }
  },
  computed: {
    ...mapGetters({
      coinData: COIN,
      company: COMPANY
    }),
    isCoinEmpty () {
      if (this.loading) return true
      const isEmptyObject = Object.keys(this.coinData).length === 0
      if (isEmptyObject) return true
      const hasNoProgramImage = 'program_image' in this.coinData && (!this.coinData.program_image || this.coinData.program_image.length === 0)
      const hasNoProgramName = 'program_name' in this.coinData && (!this.coinData.program_name || this.coinData.program_name.length === 0)
      return hasNoProgramImage || hasNoProgramName
    }
  },
  mounted () {
    this.initCoin()
  },
  methods: {
    ...mapActions({
      getCoin: GET_COIN,
      createCoin: CREATE_COIN,
      updateCoin: UPDATE_COIN
    }),
    handleAnalytics (event, data) {
      window.dataLayer.push(
        {
          event,
          ...data
        }
      )
    },
    handleEmptyButton () {
      this.openCoinModal = true
    },
    async initCoin () {
      this.loading = true
      await this.getCoin()
      this.loading = false
    },
    async handleCreateCoin (coin) {
      this.loading = true
      await this.createCoin(coin)
      this.handleAnalytics('pontos-personalizacao-moeda-criar', { company_name: this.company.slug })
      this.initCoin()
    },
    async handleUpdateCoin (coin) {
      this.loading = true
      await this.updateCoin(coin)
      this.handleAnalytics('pontos-personalizacao-moeda-editar', { company_name: this.company.slug })
      this.initCoin()
    }
  }
}
</script>

<style lang='scss' scoped>
.my-coin {
  .coin-title {
    margin-bottom: 24px;
  }
}
</style>
