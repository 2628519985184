<template>
  <section class="partner-details" v-if="!isLoading">
    <partner-details
      :partner="partner"
      @delete-partner="confirmDelete"
      @toggle-partner-status="togglePartnerStatus"
    />

    <unit-list
      title="Unidades"
      :filters="{ perk: $route.params['slug'] }"
    />
  </section>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Loadable from '@/modules/loadable'
import * as types from '@/store/types'

import PartnerDetails from '@/components/Partners/Details/PartnerDetails'
import UnitList from '@/containers/Unit/UnitList'

export default {
  mixins: [ Loadable ],
  components: { PartnerDetails, UnitList },
  computed: {
    ...mapGetters({
      partner: types.PARTNER
    })
  },
  methods: {
    ...mapActions({
      togglePartnerStatus: types.PARTNER_TOGGLE_STATUS,
      deletePartner: types.PARTNER_DELETE,
      partnerClear: types.PARTNER_CLEAR,
      getPartner: types.PARTNER
    }),
    async load() {
      const slug = this.$route.params['slug']
      const partner = await this.getPartner({ slug })

      // Redirect to error page if partner is not exclusive
      if (!partner.is_exclusive)
        this.$router.push('/error')
    },
    async confirmDelete(id) {
      await this.deletePartner({ id })
      this.$router.push({ name: 'parceiros' })
    }
  },
  beforeDestroy () {
    this.partnerClear()
  }
}
</script>

<style lang="scss">
@import '~@/styles/reference';

.partner-details {
  margin: 0 10px;
  @include desktop { @include container; }
}
</style>
