<template>
  <div
    class="container"
  >
    <c-button
      class="buttonIconColor"
      :class="selected ?
        'confirmIcon selected' :
        'confirmIcon'"
      @click.stop.native="$emit('selectItem')"
      :icon="selected ? 'check': ''"
      data-cy="c-bulkSelectItem-button"
    />
    <div
      v-if="bulkSelectionIsActive"
      class="blockItem"
    />
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    bulkSelectionIsActive: {
      type: Boolean,
      required: true
    },
    selected: {
      type: Boolean,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/styles/reference';
.container{
  position: relative;
}
.confirmIcon{
  position: absolute;
  right: 10px;
  top: 5px;
  padding-left: 0px !important;
  padding-right: 0px !important;
  height: 1.25rem;
  width: 1.25rem;
  min-width: 0px !important;
  z-index: 8;
}
.selected{
  background-color: var(--color-secondary);
}
.blockItem{
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: transparent;
  z-index: 7;
}
</style>
<style lang="scss">
.buttonIconColor > .content > .c-icon{
  fill: rgba(255, 255, 255, 0.9);
}
</style>
