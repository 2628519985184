<template>
  <wallet />
</template>

<script>
import Wallet from '@/containers/User/Wallet/Wallet.vue'
export default {
  components: { Wallet }
}
</script>

<style>
</style>
