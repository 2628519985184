<template>
  <div :class="classes">
    <highlight-tag-curve
      class="curve -left"
      reverse
      :invert="invert"
    />
    <highlight-tag-curve
      class="curve -right"
      :invert="invert"
      :is-exclusive="isExclusive"
    />

    <figure class="-discount tag">
      <figcaption class="caption">
        {{ discount }}
      </figcaption>
    </figure>

    <section
      class="exclusive"
      v-if="isExclusive"
    >
      <highlight-tag-curve
        class="curve -center"
        is-exclusive
        reverse
        :invert="invert"
      />
      <figure class="-exclusive tag">
        <figcaption>Exclusivo</figcaption>
      </figure>
    </section>
  </div>
</template>

<script>
import HighlightTagCurve from '@/components/Communication/Highlight/HighlightTagCurve'

export default {
  components: { HighlightTagCurve },

  props: {
    discountType: {
      type: String,
      default: 'percentage'
    },

    discountValue: {
      type: Number,
      default: 0
    },

    discountSymbol: {
      type: String,
      default: '%'
    },

    isExclusive: {
      type: Boolean,
      default: false
    },

    invert: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    discount () {
      if (this.discountSymbol === 'GRÁTIS' || this.discountSymbol === '2x1') { return this.discountSymbol }

      const toMoney = (value) => parseFloat(value).toFixed(2).replace('.', ',')

      const discount = this.discountType === 'percentage'
        ? `${this.discountValue}%`
        : `${this.discountSymbol} ${toMoney(this.discountValue)}`

      return discount
    },

    classes () {
      const classes = [
        'highlight-tag', {
          '-only-discount': !this.isExclusive,
          '-invert': this.invert,
          '-money-free': this.discountSymbol === 'R$' || this.discountSymbol === 'GRÁTIS'
        }]

      return classes
    }
  }
}
</script>

<style lang="scss">
@import '~@/styles/reference';

$highlight-tag-height: 26px;

$highlight-tag-size: auto $highlight-tag-height;

.highlight-tag {
  @include size($highlight-tag-size);
  position: relative;
  font-family: $base-font-family;
  font-weight: 900;
  display: flex;
  align-items: stretch;
  justify-content: center;

  & > .exclusive {
    display: flex;
    align-items: stretch;
    justify-content: center;
    position: relative;
    right: 38px;
  }

  & > .tag,
  & > .exclusive > .tag {
    text-align: center;
    color: #fff;
    line-height: 26px;
    white-space: nowrap;

    display: flex;
    align-items: center;
    justify-content: center;

    &.-discount {
      background-color: $primary-color-placeholder;
      background-color: var(--color-primary);
      font-size: 12px;

      .caption { padding-right: 8px;}
    }

    &.-exclusive {
      background-color: $base-color;
      font-size: 8.5px;
      z-index: 1;
      width: 45px;
      text-transform: uppercase;
      letter-spacing: 0.5px;
    }
  }

  & > .curve,
  & > .exclusive > .curve {
    height: $highlight-tag-height;

    &:not(.-center) {
      width: 48px;
      position: absolute;
      top: 0;
      z-index: -1;
    }

    &.-left { left: -37px; }

    &.-right {
      right: -37px;
      &.-invert {
        & > g {
          & > path {
            fill: $base-color !important;
          }
        }
      }
    }

    &.-center {
      position: relative;
      left: 3px;

      &.-invert {
        & > g {
          & > path {
            fill: $base-color !important;
          }
        }
      }
    }
  }

  &:not(.-only-discount) {
    left: 105px;

    & > .tag.-discount {
      padding-right: 15px;
    }

    & > .curve.-right { right: 1px; }
  }

  &.-invert {
    .-exclusive {
      color: white !important;
    }

    .tag.-discount {
      background-color: white !important;
      color: $primary-color-placeholder;
      color: var(--color-primary);
    }
  }

  &.-money-free {
    left: 115px;
  }
}
</style>
