<template>
  <li :class="`quest-item ${quest.isCompleted ? '-is-completed' : ''}`">
    <div class="quest-item-content">
      <c-checkbox
        :value="quest.isCompleted"
        mode="check-icon"
        :round="true"
      />
      <p class="title">
        {{ quest.title }}
      </p>
      <c-button
        size="lg"
        class="quest-button"
        @click="handleClick"
        :disabled="quest.isCompleted"
      >
        <span class="quest-text">
          Realizar missão
        </span>

        <k-icon
          icon="arrow-right"
          class="icon-arrow-right"
        />
      </c-button>
    </div>

    <p class="description">
      {{ quest.description }}
    </p>
  </li>
</template>

<script>
import { COMPANY } from '@/store/types'
import { mapGetters } from 'vuex'
import { KIcon } from 'kaledo-components'
import CCheckbox from '@/components/CComponents/CCheckbox'

export default {
  name: 'QuestItem',
  components: {
    CCheckbox,
    KIcon
  },
  props: {
    quest: Object
  },
  methods: {
    ...mapGetters({
      company: COMPANY
    }),
    handleClick () {
      window.dataLayer.push({
        event: `realizar-missao-${this.quest.id}`,
        company_name: this.company.slug
      })
      this.$router.push(this.quest.path)
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss" scoped>
@import "~@/styles/reference";

.quest-item {
  margin-bottom: 72px;
  position: relative;
  .quest-item-content {
    display: flex;
    align-items: center;
    position: relative;
    z-index: 1;
  }
  &::after {
    content: '';
    display: block;
    width: 6px;
    height: 56px;
    border-radius: 360px;
    background: #E0F8FF;
    position: absolute;
    top: 65px;
    left: 9px;
  }
  &.-is-completed {
    &::after {
      background: #14CCFF;
    }
  }
  &:last-child {
    margin-bottom: 0;
    &::after {
      display: none;
    }
  }
  .title {
    font-size: 16px;
    color: $base-color;
    margin-left: 8px;
  }
  .quest-button {
    width: 174px;
    position: relative;
    margin-left: auto;
    &.-disabled {
      opacity: 1;
      color: $base-color;
      background: rgba(216, 216, 216, 1);
    }
    .quest-text {
      display: block;
      margin-right: 8px;
    }
    .icon-arrow-right {
      font-size: 20px;
      position: absolute;
      right: 10px;
      top: 9px;
    }
  }
  .description {
    font-size: 14px;
    color: $base-color;
  }
}
.flex-item + .flex-item {
  margin-top: 72px;
}
</style>
