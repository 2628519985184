<template>
  <campaign-container />
</template>

<script>
import CampaignContainer from '@/containers/Campaign/Details/CampaignContainer'

export default {
  components: { CampaignContainer }
}
</script>
