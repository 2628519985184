<template>
  <div class="graph-card">
    <div class="cards">
      <campaigns-bar-card
        class="card"
        :class="selectedTab === 0 ? 'selected-tab' : ''"
        :title="tableData.tabs[0][0].title"
        :data="tableData.tabs[0]"
        :loading="loading"
        :info="tableData.tabs[0][0].info"
        icon="info-information-circle"
        @click.native="changeTab(0)"
      />
      <campaigns-bar-card
        class="card"
        :class="selectedTab === 1 ? 'selected-tab' : ''"
        :title="tableData.tabs[1][0].title"
        :data="tableData.tabs[1]"
        :loading="loading"
        :info="tableData.tabs[1][0].info"
        icon="info-information-circle"
        @click.native="changeTab(1)"
      />
    </div>
    <slot v-if="tableData.slot === true && selectedTab === tableData.slotTable" />
    <div
      v-else
      class="container"
    >
      <banners-filters
        class="campaign-filters-card"
        :title="tableData.tabs[selectedTab][0].filterTitle"
        :filters="selectedfilters"
        @filters="filters => searchResults(filters)"
      />
      <div class="main-table">
        <transition
          name="fade"
          mode="out-in"
        >
          <loading-table
            v-if="loading"
            :labels="tableData.labels[selectedTab]"
          />
          <table
            v-else
            class="table"
          >
            <thead class="head">
              <th
                class="head-item"
                v-for="(label) in tableData.labels[selectedTab]"
                :key="label.column"
                @click="changeSortKey(label.column)"
              >
                <k-icon
                  v-if="sortBy === label.column"
                  icon="arrow-down1"
                  class="icon"
                />
                {{ label.name }}
              </th>
            </thead>
            <tbody
              class="body"
            >
              <tr
                class="row"
                v-for="(result, index) in sortedResults"
                :key="`dash-table-${index}`"
              >
                <td
                  v-for="(label) in tableData.labels[selectedTab]"
                  :key="`${label.column}-${index}`"
                  class="column"
                >
                  <img
                    v-if="label.isImage"
                    :src="result[label.column]"
                    class="image"
                  />
                  <span v-else>{{ result[label.column] }}</span>
                </td>
              </tr>
            </tbody>
          </table>
        </transition>
        <div class="fake-pagination">
          <fake-pagination
            :current-page="currentPage"
            :last-page="numberPages"
            @next="nextPage"
            @previous="previousPage"
            @last-page="lastPage"
            @first-page="firstPage"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CampaignsBarCard from '@/components/Dashboard/cards/CampaignsBarCard.vue'
import BannersFilters from '@/components/Dashboard/base/BannersFilters.vue'
import FakePagination from '@/components/Dashboard/base/FakePagination.vue'
import LoadingTable from '@/components/Dashboard/base/LoadingTable.vue'
export default {
  name: 'BannersTable',
  components: {
    CampaignsBarCard,
    BannersFilters,
    LoadingTable,
    FakePagination
  },
  props: {
    tableData: Object,
    selectedfilters: Array,
    table: {
      type: Boolean,
      default: true
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      selectedTab: 0,
      filters: {},
      sortBy: 'redeem',
      resultsCopy: [],
      sortedResults: [],
      selectedRow: null,
      results: [],
      numberPages: 1,
      currentPage: 1
    }
  },
  created () {
    this.results = JSON.parse(JSON.stringify(this.tableData.data[this.selectedTab]))
    this.createPagination()
  },
  methods: {
    changeTab (index) {
      this.selectedTab = index
      this.results = this.tableData.data[this.selectedTab] || []
    },
    searchResults (filters) {
      console.log('filters', filters)
    },
    sortResults (key) {
      const results = [...this.results]
      const sorted = results.sort((a, b) => (a[key] < b[key]) ? 1 : -1)
      this.resultsCopy = sorted
      this.firstPage()
    },
    changeSortKey (key) {
      this.sortBy = key
      this.sortResults(key)
    },
    createPagination () {
      if (this.results.length > 5) {
        this.numberPages = Math.ceil((this.results.length / 5))
      }
      this.resultsCopy = [...this.results]
      this.sortedResults = this.results.slice(0, 5)
    },
    nextPage () {
      const init = this.currentPage * 5
      const endPage = (init + 5)
      const end = endPage > this.resultsCopy.length ? this.resultsCopy.length : endPage
      this.sortedResults = this.resultsCopy.slice(init, end)
      this.currentPage = this.currentPage + 1
    },
    previousPage () {
      this.currentPage = this.currentPage - 1
      const init = (this.currentPage * 5) - 5
      const end = (init + 5)
      this.sortedResults = this.resultsCopy.slice(init, end)
    },
    lastPage () {
      this.currentPage = this.numberPages
      const end = this.resultsCopy.length
      const init = (this.currentPage * 5) - 5
      this.currentPage = this.numberPages
      this.sortedResults = this.resultsCopy.slice(init, end)
    },
    firstPage () {
      this.currentPage = 1
      this.sortedResults = this.resultsCopy.slice(0, 5)
    }
  }
}
</script>

<style lang='scss' scoped>
@import '~@/styles/reference.scss';
.graph-card {
  .cards {
    display: flex;
    .card {
      position: relative;
      z-index: 1;
      padding: 16px;
      width: 100%;
      border-radius: 6px 6px 0px 0px;
      margin-right: 10px;
      cursor: pointer;
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.16);
      background: none;

      &.selected-tab {
        background: #FDFDFD !important;
      }
    }
  }
  .cards div:last-child {
    margin-right: 0;
    display: flex;
    justify-content: flex-start;
  }
  .container {
    position: relative;
    z-index: 3;
    background: #FDFDFD;
    padding: 16px;
    box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.16);
    border-radius: 0px 0px 6px 6px;
    .fake-pagination {
      position: absolute;
      width: 97%;
      display: flex;
      justify-content: center;
    }
    .title, .table > .head > .head-item {
    color: #5E6684;
    text-transform: uppercase;
    font-family: $base-font-family;
  }
  .title {
    font-size: 14px;
    text-align: center;
    font-weight: 700;
    margin-bottom: 40px;
    color: $text-color;
  }
    .table {
      margin-bottom: 48px;
      .head {
        border-bottom: 1px solid $another-color;
      }
      .head th:nth-of-type(2){
        font-weight: bolder;
        text-align: inherit;
      }
      .loading-page {
        max-width: 100%;
      }
      .head-item {
        font-size: 12px;
        font-weight: 400;
        padding-bottom: 10px;
        padding-right: 8px;
        text-align: center;
        vertical-align: bottom;
        cursor: pointer;
        &:first-child {
          text-align: left;
        }
        &:last-child {
          padding-right: 0px;
        }
        .icon {
          color: #00B2E3;
          font-size: 20px;
          display: block;
          margin: 0 auto;
        }
      }
      .row td:nth-of-type(2) {
        width: 100px;
      }
      .row td:nth-of-type(4) {
        width: 35%;
      }
       .row td:last-child {
        width: 116px;
      }
      .row {
        font-size: 12px;
        color: $title-color;
        transition: all ease 0.3s;
        &:hover {
          background: #D8E0F6;
        }
        &:focus {
          background: #202950;
          color: white;
        }
        .column {
          padding: 14px 8px 10px 8px;
          text-align: center;
          &:first-child {
            text-align: left;
          }
          .image {
            height: 56px;
            object-fit: fill;
          }
        }
        .first, .last {
          padding: 16px 0;
        }
        .last {
          text-align: right;
          font-weight: 600;
        }
      }
      .row + .row {
        border-top: 1px solid $another-color;
      }
    }
  }
}
</style>
