<template>
  <div class="navbar-preview">
    <div v-if="loading">Carregando...</div>
    <navbar-preview-desktop
      v-else-if="isDesktopMode"
      :logo="logoSrc"
    />
    <navbar-preview-mobile
      v-else
      :logo="logoSrc"
    />
  </div>
</template>

<script>
import NavbarPreviewDesktop from './NavbarPreviewDesktop.vue'
import NavbarPreviewMobile from './NavbarPreviewMobile.vue'

export default {
  name: 'NavbarPreview',
  components: { NavbarPreviewDesktop, NavbarPreviewMobile },
  props: {
    loading: Boolean,
    isDesktopMode: {
      type: Boolean,
      default: true
    },
    logo: {
      type: [File, Array, String],
      default: () => []
    }
  },
  data () {
    return {
      logoSrc: null
    }
  },
  watch: {
    logo () {
      if (typeof this.logo === 'string') {
        this.logoSrc = this.logo
      } else if (this.logo && typeof this.logo.name === 'string') {
        this.logoSrc = URL.createObjectURL(this.logo)
      } else {
        this.logoSrc = null
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/styles/reference';
.navbar-preview {
  display: flex;
  justify-content: center;
}
</style>
