<template>
  <div
    class="c-label"
    :style="labelStyle"
    v-if="$slots.default"
  >
    <p
      class="text"
      :style="textColor ? `color: ${textColor}` : ''"
    >
      <slot />
    </p>
  </div>
</template>

<script>
export default {
  props: {
    color: String,
    textColor: {
      type: String,
      default: ''
    }
  },
  computed: {
    labelStyle () {
      const labelStyle = {
        backgroundColor: this.color,
        color: this.textColor
      }
      return labelStyle
    }
  }
}
</script>

<style lang="scss">
.c-label {
  display: inline-flex;
  height: 25px;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  padding: {
    left: 5px;
    right: 5px;
  }

  & > .text {
    text-transform: uppercase;
    font-weight: bold;
    font-size: 10px;
    color: inherit;
    line-height: 1;
  }
}
</style>
