<template>
  <div
    class="user-import-list"
    v-if="!isLoading"
  >
    <user-import-table
      v-if="imports.length"
      :rows="imports"
      :paginate="pagination"
      @update-paginate="getImportsList"
      @get-status="getStatus"
      @cancel-import="cancelImport"
      @handleAnalytics="handleAnalytics($event, {company_name: company.name})"
    />

    <div
      v-else
      class="empty-list"
    >
      <c-character
        type="searching"
        class="character"
      />
      <p class="empty-text">
        Não há histórico de importação
      </p>
    </div>

    <user-import-confirmation
      is-confirmation
      v-if="!!status"
      class="confirmation"
      :is-opened="!!status"
      :status="status"
      @close="clearStatus"
      @confirm="procceedImport"
      @cancel-import="cancelImport(openedStatus)"
    />
  </div>

  <c-spinner
    size="md"
    v-else
  />
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import * as types from '@/store/types'
import Loadable from '@/modules/loadable'
import CCharacter from '@/components/CComponents/CCharacter'

export default {
  components: {
    CCharacter,
    UserImportTable: () => import('@/components/User/Import/UserImportTable'),
    UserImportConfirmation: () => import('@/components/User/Import/UserImportConfirmation')
  },
  mixins: [ Loadable ],
  data () {
    return {
      openedStatus: undefined,
      rows: []
    }
  },
  computed: {
    status () {
      if (!this.openedStatus) { return }

      const userImport = this.imports.find(userImport => userImport.id === this.openedStatus) || {}

      return userImport.details
    },
    ...mapGetters({
      imports: types.USER_IMPORT_LIST,
      pagination: types.USER_IMPORT_PAGINATION,
      company: types.COMPANY
    })
  },
  methods: {
    closeConfirmation () {
      this.confirmationOpened = false
    },
    getStatus (id) {
      this.openedStatus = id
    },
    clearStatus () {
      this.openedStatus = undefined
    },
    async procceedImport () {
      await this.confirmImport(this.openedStatus)
      this.clearStatus()
    },
    async cancelImport (id) {
      const success = await this.cancelImportAction(id)

      if (success) {
        this.clearStatus()
        setTimeout(async () => await this.load(), 1500)
      }
    },
    async confirmImport (id) {
      const success = await this.confirmImportAction(id)

      if (success) { setTimeout(async () => await this.load(), 1500) }
    },
    async load () {
      this.isLoading = true

      await Promise.all([
        this.getImportsList(),
        this.getProcessingStatus()
      ])

      this.isLoading = false
    },
    ...mapActions({
      getImportsList: types.USER_IMPORT_LIST,
      paginateImportList: types.USER_IMPORT_PAGINATION,
      confirmImportAction: types.USER_IMPORT_CONFIRMATION,
      cancelImportAction: types.USER_IMPORT_CANCEL,
      getProcessingStatus: types.USER_IMPORT_PROCESSING
    }),

    handleAnalytics (event, data) {
      window.dataLayer.push(
        {
          event: event,
          ...data
        }
      )
    }
  }
}
</script>
<style lang="scss">
.user-import-list {

  & >.empty-list {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    background-color: #fff;
    padding: 60px 30px;
    margin-top: 20px;

    & >.empty-text {
      font-size: 14px;
      margin-top: 20px;
    }
  }

  .table {
    overflow-x: auto;
    border-radius: 3px;
  }
}
</style>
