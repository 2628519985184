<template>
  <div class="carrousel-mobile">
    <c-title
      use-k-icon
      :icon="icon"
      class="carrousel-title"
      preview-mode
      :border-color="data.secondary"
    >
      {{ title }}
    </c-title>
    <div class="cards-container">
      <card-item
        v-for="index in cardItems"
        :key="`card-item-${index}`"
        :data="data"
        mobile
      />
    </div>
  </div>
</template>

<script>
import CardItem from './CardItem.vue'

export default {
  name: 'CarrouselMobile',
  components: {
    CardItem
  },
  props: {
    data: {
      type: Object,
      default: () => {}
    },
    title: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      cardItems: 2
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/styles/reference';
.carrousel-mobile {
  margin-bottom: 32px;
  .carrousel-title {
    margin-bottom: 24px;
  }
  .cards-container {
    display: flex;
    width: 100%;
    overflow: hidden;
  }
}
</style>
