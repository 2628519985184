// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../../assets/img/trial/gift/bg-gift.png");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".fade-enter-active[data-v-31089130],.fade-leave-active[data-v-31089130]{transition:opacity .3s}.fade-enter[data-v-31089130],.fade-enter-from[data-v-31089130],.fade-leave-to[data-v-31089130]{opacity:0}.gift-container[data-v-31089130]{align-items:center;background:rgba(224,248,255,.16);display:flex;height:172px;justify-content:center;margin-top:42px;width:100%}.gift-container .text-container[data-v-31089130]{margin-left:24px;padding-bottom:24px;padding-left:24px;padding-right:24px;text-align:center}.gift-container .text-container.-can-rescue[data-v-31089130]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-position-x:center;background-position-y:bottom;background-size:contain}.gift-container .text-container .title[data-v-31089130]{color:#00b2e3;font-size:18px;font-weight:700;margin-bottom:16px}.gift-container .text-container .button-container .gift-button[data-v-31089130]{width:160px}.gift-container .text-container .button-container .gift-button.-disabled[data-v-31089130]{background:#d8d8d8;color:#121e48;opacity:1}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
module.exports = ___CSS_LOADER_EXPORT___;
