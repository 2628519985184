<template>
  <router-link class="call-to-login" to="/login">Já tem uma conta? <b>Faça login</b></router-link>
</template>

<script>
export default {
  name: 'CallToLogin'
}
</script>

<style lang='scss' scoped>
@import '~@/styles/reference';
.call-to-login {
  color: #5E6684;
  font-size: 18px;
  text-decoration: none;
  @include mobile {
    font-size: 16px;
  }
}
</style>
