<template>
  <div>
    <div class="container">
      <div class="sales-text">
        <h2>Tenha acesso a todas as funcionalidades</h2>
        <h3>Faça upgrade de plano para utilizar todos os recursos e maximizar seus resultados</h3>
      </div>
      <div class="sales-button" />

      <k-button
        class="button"
        icon="pen-edit5"
        no-icon
        @click.native="openConfirmationModal = true, $emit('call-sales')"
      >
        falar com vendas
      </k-button>
    </div>
    <c-confirm-modal
      no-buttons
      title="Nosso time entrará em contato em breve"
      message="Nosso time de vendas recebeu o seu chamado e em breve você será contatado via email."
      :is-opened="openConfirmationModal"
      @close="openConfirmationModal = false"
    />
  </div>
</template>

<script>
import { KButton } from 'kaledo-components'
import CConfirmModal from '../CComponents/CConfirmModal.vue'
export default {

  components: {
    KButton,
    CConfirmModal
  },
  data () {
    return {
      openConfirmationModal: false
    }
  }
}
</script>

<style lang='scss' scoped>
@import '~@/styles/reference.scss';

.container {
  width: 1022px;
  height: 174px;
  margin: 48px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  border-radius: 8px;
  border: 1px solid $neutral-gray-50;
  background: #FFF;
  box-shadow: 0px 4px 8px 0px rgba(94, 102, 132, 0.12);
  & > .sales-text {
    padding: 40px;
    & > h2 {
      color: $neutral-dark-blue;
      font-feature-settings: 'clig' off, 'liga' off;
      font-family: Nunito Sans;
      font-size: 28px;
      font-style: normal;
      font-weight: 700;
      margin-bottom: 16px;
    }
    & > h3 {
      color: $neutral-gray-200;
      font-feature-settings: 'clig' off, 'liga' off;
      font-family: Nunito Sans;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 120%;
    }
  }
  & > .sales-button {
    height: 100%;
    width: 345px;
    background-image: url('~/assets/img/blue-pattern.png');
    opacity: 0.08;
    background-size: 1753px 963px;
    background-position: left
  }
  & > .button {
    position: absolute;
    right: 40px;
    width: 196px;
  }
}
</style>
