<template>
  <donut-card
    :loading="loading"
    :error="error"
    :graph-data="usersPerAgeData"
  />
</template>

<script>
import DonutCard from '@/components/Dashboard/base/GraphCard.vue'
import { mapGetters, mapActions } from 'vuex'
import { DASH_USERS_PER_AGE } from '@/store/types'

export default {
  name: 'UsersPerAge',
  components: {
    DonutCard
  },
  props: {
    reloadDataQuery: Boolean
  },
  data () {
    return {
      error: false,
      loading: false
    }
  },
  computed: {
    ...mapGetters({
      usersPerAgeData: DASH_USERS_PER_AGE
    })
  },
  watch: {
    reloadDataQuery () {
      if (this.reloadDataQuery) { this.getUsersPerAge() }
    }
  },
  mounted () {
    this.getUsersPerAge()
  },
  methods: {
    ...mapActions({
      getUsersPerAgeData: DASH_USERS_PER_AGE
    }),
    getUsersPerAge () {
      this.loading = true
      this.getUsersPerAgeData().then((success) => {
        this.error = !success
        this.loading = false
      })
    }
  }
}
</script>

<style lang='scss' scoped>
</style>
