<template>
  <c-card
    :class="classes"
    @click.native="handleClick"
    :data-cy="`user-import-type-input-${ type }`"
  >
    <div
      class="icon"
      v-if="icon"
    >
      <c-icon
        class="image"
        :icon="icon"
        :size="'' + iconSize"
      />
    </div>

    <div class="content">
      <h3 class="title">
        {{ title }}
      </h3>
      <p class="description">
        {{ description }}
      </p>
    </div>
  </c-card>
</template>

<script>
import CCard from '@/components/CComponents/CCard'

export default {
  components: { CCard },
  props: {
    icon: String,
    iconSize: {
      type: [String, Number],
      default: 30
    },
    title: String,
    description: String,
    type: {
      type: String,
      required: true
    },
    selected: Boolean,
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    classes () {
      return ['user-import-type-input', {
        '-selected': this.selected,
        '-disabled': this.disabled
      }]
    }
  },
  methods: {
    handleClick () {
      if (this.disabled) return
      this.$emit('change-type', this.type)
    }
  }
}
</script>

<style lang="scss">
@import '~@/styles/reference';

.c-card.user-import-type-input {
  padding: 19.5px 17.5px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  transition: background-color .3s ease;

  & > .icon {
    margin-right: 18.5px;
    border-radius: 50%;
    height: 61px;
    width: 61px;
    flex: 61px 0 0;
    border: $border;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: border-color .3s ease;

    & > .image {
      transition: fill .3s ease;
    }
  }

  & > .content {
    & > .title { margin-bottom: 10px; }

    & > .title, & > .description {
      transition: color .3s ease;
    }
  }

  &.-selected {
    background-color: $primary-color-placeholder;
    background-color: var(--color-primary);

    & > .icon {
      border-color: $secondary-color-placeholder;
      border-color: var(--color-secondary);

      & > .image {
        fill: $secondary-color-placeholder;
        fill: var(--color-secondary);
      }
    }

    & > .content {
      & > .title, & > .description {
        color: $secondary-color-placeholder;
        color: var(--color-secondary);
      }
    }
  }
  &.-disabled {
    background-color: #5E66841F;
    cursor: not-allowed;
    & > .content {
      & > .title, & > .description {
        color: #5E6684;
      }
    }
  }
}
</style>
