<template>
  <div>
    <k-icon
      class="info-button"
      icon="question"
      ref="questionButton"
      @click.native="show = !show"
    />
    <c-popover
      v-if="show"
      :position="['top', 'center']"
      :target="$refs.questionButton"
      class="popover"
      @blur="show = false"
    >
      <div class="content">
        <p class="text">
          {{ info.text }}
        </p>
      </div>
    </c-popover>
  </div>
</template>

<script>
import { KIcon } from 'kaledo-components'
import CPopover from '@/components/CComponents/CPopover.vue'

export default {
  components: {
    KIcon,
    CPopover
  },

  props: {
    info: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      show: false
    }
  }
}
</script>

<style lang="scss" scoped>
.info-button {
  font-size: 20px;
  margin-left: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: all .1s ease;

  &:hover {
    border: 1px solid rgba(94, 102, 132, 1);
    border-radius: 50%;
  }
}
.popover {
    border-radius: 4px;
    display: flex;
    padding: 15px;
    align-items: flex-start;
    gap: 8px;
    width: 250px;
    z-index: 1000;

  & > .content {
    & > .content {

      & > .text {
        font-size: 12px;
        font-weight: 400;
        text-transform: none;
      }
    }
  }
  }
</style>
