<template>
  <div :class="classes">
    <campaign-tag-curve
      v-if="!isHighlight"
      class="curve -left"
      :is-exclusive="isExclusive"
      reverse
    />
    <campaign-tag-curve
      class="curve -right"
      :rotate="rotate"
      :is-highlight="isHighlight"
      :is-exclusive="isExclusive"
    />
    <figure
      class="-highlight tag"
      v-if="isHighlight"
    >
      <figcaption>Destaque</figcaption>
    </figure>

    <section
      class="exclusive"
      v-if="isExclusive"
    >
      <campaign-tag-curve
        class="curve -center"
        is-exclusive
        reverse
      />
      <figure class="-exclusive tag">
        <figcaption>Exclusivo</figcaption>
      </figure>
    </section>
  </div>
</template>

<script>
import CampaignTagCurve from '@/components/Campaign/Listing/CampaignTagCurve'

export default {
  components: { CampaignTagCurve },
  props: {
    discountType: {
      type: String,
      default: 'percentage'
    },
    discountValue: {
      type: Number,
      default: 0
    },
    discountSymbol: {
      type: String,
      default: '%'
    },
    isExclusive: Boolean,
    isHighlight: Boolean,
    rotate: Boolean
  },
  computed: {
    discount () {
      const toMoney = (value) => parseFloat(value).toFixed(2).replace('.', ',')
      const discount = this.discountType === 'percentage'
        ? `${this.discountValue}%`
        : `${this.discountSymbol} ${toMoney(this.discountValue)}`
      return discount
    },
    classes () {
      const classes = [
        'campaign-tag', {
          '-only-discount': !this.isExclusive,
          '-highlight': this.isHighlight
        }]
      return classes
    }
  }
}
</script>

<style lang="scss">
@import '~@/styles/reference';

$campaign-tag-height: 26px;
$campaign-highlight-tag-height: 16px;

$campaign-tag-size: auto $campaign-tag-height;

.campaign-tag {
  @include size($campaign-tag-size);
  position: relative;
  font-family: $base-font-family;
  font-weight: 900;
  display: flex;
  align-items: stretch;
  justify-content: center;

  &.-highlight {
    height: $campaign-highlight-tag-height;
    font-size: 9px;
    font-weight: 400;

    & > .campaign-tag-curve {
      height: $campaign-highlight-tag-height;
      right: -32px !important;
    }
  }

  & > .exclusive, .highlight {
    display: flex;
    align-items: stretch;
    justify-content: center;
    position: relative;
    right: 38px;
  }

  & > .tag,
  & > .highlight > .tag,
  & > .exclusive > .tag {
    text-align: center;
    color: #fff;
    line-height: 26px;
    white-space: nowrap;

    display: flex;
    align-items: center;
    justify-content: center;

    &.-discount {
      background-color: $primary-color-placeholder;
      background-color: var(--color-primary);
    }

    &.-exclusive {
      background-color: $base-color;
      font-size: 8.5px;
      z-index: 1;
      width: 76px;
      text-transform: uppercase;
      letter-spacing: 0.5px;
    }

    &.-highlight {
      background-color: var(--color-terciary);
      font-size: 8.5px;
      z-index: 1;
      width: 70px;
      text-transform: uppercase;
      letter-spacing: 0.5px;
    }
  }

  & > .curve,
  & > .highlight > .curve,
  & > .exclusive > .curve {
    height: $campaign-tag-height;

    &:not(.-center) {
      width: 48px;
      position: absolute;
      top: 0;
      z-index: 5;
    }

    &.-left { left: -37px; }

    &.-right { right: -37px; }

    &.-center {
      position: relative;
      left: 5px;
    }
  }

  &:not(.-only-discount) {
    left: 100px;

    & > .tag.-discount { padding-right: 20px; }
    & > .curve.-right { right: 1px; }
  }
}
</style>
