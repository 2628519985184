<template>
  <div class="dropdown-item">
    <button
      class="dropdown-button"
      data-cy="dropdown-button"
      @click="showDropdownMenu = !showDropdownMenu"
    >
      {{ item.name }}
      <k-icon
        class="dropdown-icon"
        icon="arrow-down1"
        size="20px"
      />
    </button>
    <div
      class="dropdown-mask"
      v-if="showDropdownMenu"
      @click="showDropdownMenu = false"
    />
    <transition
      name="fade"
      mode="out-in"
    >
      <c-dropdown-menu
        class="dropdown-menu-item"
        v-if="showDropdownMenu"
        :options="dropdownOptions"
        @close="showDropdownMenu = false"
      />
    </transition>
  </div>
</template>

<script>
import { KIcon } from 'kaledo-components'
import CDropdownMenu from './CDropdownMenu.vue'
export default {
  name: 'CDropdownItem',
  components: {
    KIcon,
    CDropdownMenu
  },
  props: {
    item: Object,
    dropdownOptions: Array
  },
  data () {
    return {
      showDropdownMenu: false
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/styles/reference';
.dropdown-item {
  margin-right: 32px;
  position: relative;
  .dropdown-button {
    position: relative;
    z-index: 3;
    border: none;
    background: none;
    font-size: 16px;
    color: #5E6684;
    font-weight: 600;
    cursor: pointer;

  }
  .dropdown-menu-item {
    position: absolute;
    z-index: 2;
    top: 40px;
    left: -20px;
  }
  .dropdown-mask {
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
  }
}
</style>
