<template>
  <campaigns/>
</template>

<script>
import Campaigns from '@/containers/Engagement/pages/Campaigns.vue'
export default {
  name: 'index',
  components: {
    Campaigns
  }
}
</script>

<style lang='scss' scoped>
</style>
