<template>
  <partner-details />
</template>

<script>
import PartnerDetails from '@/containers/Partners/PartnerDetails'

export default {
  components: { PartnerDetails }
}
</script>
