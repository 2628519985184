<template>
  <div class="general-campaigns-card">
    <div class="general-campaigns-tabs">
      <campaigns-bar-card
        class="card"
        :class="selectedTab === 0 ? 'selected-tab' : ''"
        title="Ofertas Exclusivas"
        :data="tabs"
        :info="infoExclusiveOffers"
        :loading="loading"
        icon="info-information-circle"
        @click.native="changeTab(0)"
      />
      <campaigns-bar-card
        class="card"
        :class="selectedTab === 1 ? 'selected-tab' : ''"
        title="Ofertas Gerais"
        :data="tabs"
        :info="infoGeralOffers"
        :loading="loading"
        icon="info-information-circle"
        @click.native="changeTab(1)"
      />
    </div>
    <div
      v-if="!loading && columns.length === 0"
      class="not-available"
    >
      <not-available />
    </div>
    <div
      v-else
      class="general-campaigns-content"
    >
      <campaign-filters
        class="campaign-filters-card"
        :title="'Relatório de Ofertas Exclusivas'"
        :radio-group-name="'campaigns'"
        :options="filterOptions"
        :radio-options="generalFilterOptions"
        :disable-submit="disableSubmit"
        @filters="filters => searchResults(filters)"
      />
      <dash-table
        class="campaigns-table-card"
        :title="'Principais Ofertas'"
        :filters="filters"
        :loading="loading"
      />
      <graph-card
        :loading="loading"
        class="general-campaign-graph-card"
      >
        <spine-chart
          class="general-campaigng-spine-chart"
          :columns="selectedColumns[0]"
          title="Acessos/Resgates"
          :labels="labels"
        />
      </graph-card>
    </div>
  </div>
</template>

<script>
import DashTable from '@/components/Dashboard/base/DashTable.vue'
import CampaignsBarCard from '@/components/Dashboard/cards/CampaignsBarCard.vue'
import GraphCard from '@/components/Dashboard/cards/GraphCard.vue'
import CampaignFilters from '@/components/Dashboard/base/CampaignFilters.vue'
import notAvailable from '@/components/Dashboard/base/notAvailable.vue'
import SpineChart from '@/components/Dashboard/base/SpineChart.vue'

export default {
  name: 'CampaignsCard',
  components: {
    DashTable,
    CampaignsBarCard,
    GraphCard,
    CampaignFilters,
    notAvailable,
    SpineChart
  },
  props: {
    loading: Boolean
  },
  data () {
    return {
      filters: {},
      generalFilterOptions: [
        {
          label: 'Nome de Oferta',
          value: 'campaign1'
        },
        {
          label: 'Nome de Parceria',
          value: 'partner1'
        }
      ],
      generalOptions: [
        {
          label: 'Todos os tipos de oferta',
          value: ''
        },
        {
          label: 'Carta vínculo',
          value: 'carta'
        },
        {
          label: 'Cashback',
          value: 'cashback'
        },
        {
          label: 'Código Único',
          value: 'unique'
        },
        {
          label: 'Info',
          value: 'info'
        },
        {
          label: 'Online',
          value: 'online'
        },
        {
          label: 'Voucher',
          value: 'voucher'
        }
      ],
      exclusiveOptions: [
        {
          label: 'Todos os tipos de oferta',
          value: ''
        },
        {
          label: 'Código Único',
          value: 'unique'
        },
        {
          label: 'Info',
          value: 'info'
        },
        {
          label: 'Online',
          value: 'online'
        },
        {
          label: 'Voucher',
          value: 'voucher'
        }
      ],
      partner: {
        logo: 'https://clube-newcore.s3.us-west-2.amazonaws.com/perks-logo/hIntox2ZXYarDn2n.png',
        name: 'Steam',
        description: 'Compre Gift Card da Steam e receba Cashback'
      },
      infoExclusiveOffers: {
        title: 'Ofertas Exclusivas',
        text: 'Ofertas exclusivas'
      },
      infoGeralOffers: {
        title: 'Ofertas gerais',
        text: 'ofertas gerais'
      },
      tabs: [
        {
          total: 4000,
          label: 'Acessos em Destaques no período',
          comparisonValue: 10
        },
        {
          total: 4000,
          label: 'Resgates em Destaques no período',
          comparisonValue: -10
        }
      ],
      selectedTab: 0,
      categorias: ['x', '2013-02-01', '2013-02-02', '2013-02-03', '2013-02-04', '2013-02-05'],
      columns: [
        [
          [
            ['x', '2013-01-01', '2013-01-02', '2013-01-03', '2013-01-04', '2013-01-05'],
            ['data1', 125, 75, 75, 125, 150],
            ['data2', 220, 180, 225, 200, 175]
          ],
          [
            ['x', '2013-01-01', '2013-01-02', '2013-01-03', '2013-01-04', '2013-01-05'],
            ['data1', 100, 100, 175, 155, 160],
            ['data2', 150, 150, 200, 200, 175]
          ]], [ [
          ['x', '2013-01-01', '2013-01-02', '2013-01-03', '2013-01-04', '2013-01-05'],
          ['data1', 125, 75, 75, 125, 150],
          ['data2', 220, 180, 225, 200, 175]
        ],
        [
          ['x', '2013-01-01', '2013-01-02', '2013-01-03', '2013-01-04', '2013-01-05'],
          ['data1', 100, 100, 175, 155, 160],
          ['data2', 150, 150, 200, 200, 175]
        ]]
      ],
      labels: [
        {
          id: 'data1',
          label: 'Acessos',
          color: '#202950',
          style: 'solid'
        },
        {
          id: 'data2',
          label: 'Resgates',
          color: '#00B2E3',
          style: 'dashed'
        }
      ],
      tableLabels: [ 'Nome Oferta', 'Acessos', 'Resgates' ]
    }
  },
  computed: {
    disableSubmit () {
      if (Object.keys(this.filters).length === 0) return true
      return !this.generalFilterOptions.some(e => e.value === this.filters.search_type)
    },
    selectedColumns () {
      return this.columns[this.selectedTab]
    },
    barClasses () {
      let array = []

      for (let i = 1; i < this.selectedColumns[1][0].length; i++) {
        array.push({
          datas: this.selectedColumns[1][0][i],
          acessos: this.selectedColumns[1][1][i],
          resgates: this.selectedColumns[1][2][i]
        })
      }
      return {
        Acessos: 'general-hits-bars',
        Resgates: 'general-rescues-bars',
        ComparacaoAnterior: array
      }
    },
    filterOptions () {
      return this.selectedTab === 0 ? this.exclusiveOptions : this.generalOptions
    }
  },
  methods: {
    changeTab (index) {
      this.selectedTab = index
    },
    searchResults (filters) {
      this.filters = filters
    }
  }
}
</script>

<style lang='scss' scoped>
@import '~@/styles/reference';
.general-campaigns-card {
  > .general-campaigns-tabs {
    display: flex;

    @include mobile {
      overflow-x: scroll;
    }

    > .card {
      cursor: pointer;
      background: #F8F9FD;
      padding: 25px 30px;
      border-radius: 6px 6px 0px 0px;
      box-shadow: 1px -1px 4px 0 rgba(0,0,0,0.1);
      width: 100%;
      height: 200px !important;
    }

     > .card:last-child {
      margin-left: 10px;
    }

    & .no-partner {
      background: #F8F9FD;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;

      p {
        max-width: 180px;
      }
    }

    > .selected-tab {
      background: #FDFDFD;
    }
  }

  > .not-available {
    background: white;
    padding: 10px;
    border-radius: 0px 0px 6px 6px;
  }

  > .general-campaigns-content {
    margin-top: -1px;
    padding-top: 15px;
    background: #FDFDFD;
    z-index: 3;
    display: grid;
    grid-template-areas:
      "filters filters filters"
      "table chart chart";
    width: 100%;
    max-width: 100%;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.06);
    border-radius: 0px 0px 6px 6px;
    @include mobile {
      grid-template-areas:
      "filters"
      "table"
      "chart";
    }

    > .campaign-filters-card {
      grid-area: filters;
      width: 100%;
      padding: 8px 20px;
    }

    > .campaigns-table-card {
      grid-area: table;
    }

    > .graph-card {
      width: 100%;
      min-width: 600px;
      box-shadow: none;

      @include tablet {
        min-width: 100%;
      }
      @include mobile {
        min-width: 100%;
      }

      & :deep .main-card {
        box-shadow: none;
        @include tablet {
          max-width: 100%;
          width: 100%;
        }
        @include mobile {
          max-width: 100%;
          width: 100%;
        }
      }

      & .general-campaigns-chart-card {
        grid-area: chart;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: -1px;
        padding-top: 15px;
      }
    }
    .general-campaigng-spine-chart {
      @include tablet {
        max-width: 100%;
      }
      @include mobile {
        max-width: 100%;
      }
    }
  }
}
</style>
