<template>
  <div class="periodContainer">
    <div class="period">
      <c-select
        form-label="Período"
        class="discounts-item"
        track-by="value"
        display-by="label"
        :items="datesOptions"
        :value="selectValue"
        @input="selectTimePeriod"
      />
    </div>
    <div class="date">
      <c-input
        class="form-control -inline -no-margin input"
        icon="calendar"
        ref="datePeriod"
        validation-icon="warning-circle"
        :placeholder="datePlaceholder"
        auto
        required
        label=""
        name="period"
        autocomplete="off"
        mask="##/##/#### - ##/##/####"
        watch
        v-validate="'required'"
        :feedback-show="errors.has('period')"
        :feedback-message="errors.first('period')"
        :flex="true"
        :value="period"
        @input="period = $event"
        @click.native="openPopover"
      />
      <div class="buttons">
        <c-button
          :disabled="disableAction"
          preview-alternative
          class="open-filter-button"
          size="lg"
          @click="$emit('submitFilter')"
        >
          Aplicar
        </c-button>
      </div>
    </div>
    <c-popover
      v-if="showDatePicker"
      :position="popoverPosition"
      :target="$refs.datePeriod"
      @blur="closePopover"
    >
      <div
        class="datepickers"
        @click.stop
      >
        <functional-calendar
          ref="fcalendar"
          class="initial-calendar"
          @input="handleCalendar"
          :limits="limits"
          :day-names="calendarOptions.dayNames"
          :month-names="calendarOptions.monthNames"
          :short-month-names="calendarOptions.shortMonthNames"
          :disabled-dates="disabledDates"
          is-date-range
          is-multiple
          :calendars-count="2"
        />
        <div class="buttons">
          <c-button
            class="datepicker-button"
            size="lg"
            @click="closePopover"
          >
            Cancelar
          </c-button>
          <c-button
            class="datepicker-button"
            primary
            size="lg"
            @click="applyData"
          >
            Aplicar
          </c-button>
        </div>
      </div>
    </c-popover>
  </div>
</template>

<script>
import { ptBR } from 'vuejs-datepicker/dist/locale'
import predefinedDates from '@/content/walletPredefinedDates'
import moment from 'moment'
import CPopover from '@/components/CComponents/CPopover'
import dashDatesOptions from '@/content/dashDatesOptions'
import { FunctionalCalendar } from 'vue-functional-calendar'

export default {
  name: 'InitialCard',
  components: {
    CPopover,
    FunctionalCalendar
  },
  props: {
    disableAction: Boolean
  },
  data () {
    return {
      disableFunctions: false,
      loading: false,
      initialDate: {},
      ptBR,
      period: '',
      auxData: {},
      start_date: null,
      end_date: null,
      showDatePicker: false,
      errorMessage: '',
      selectValue: ''
    }
  },
  computed: {
    calendarOptions () {
      return {
        dayNames: ['Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sab', 'Dom'],
        monthNames: ['Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho', 'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'],
        shortMonthNames: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez']
      }
    },
    limits () {
      let beginDate = new Date()
      beginDate.setFullYear(beginDate.getFullYear() - 1)
      beginDate = moment(beginDate, 'DD-MM-YYYY')
      const diffDays = moment().diff(beginDate, 'days')
      const min = diffDays > 365
        ? moment().subtract(12, 'months').format('DD/MM/YYYY')
        : moment().subtract(parseInt(diffDays / 2), 'days').format('DD/MM/YYYY')
      const max = moment().format('DD/MM/YYYY')
      return { min, max }
    },
    disabledDates () {
      const today = moment(new Date()).format('DD/MM/YYYY')
      return [today, 'afterToday']
    },
    popoverPosition () {
      if (this.isLgScreen) return ['top', 'right']
      return ['top', 'center']
    },
    disableApplyButton () {
      if (!this.start_date || !this.end_date) return true
      return !(this.start_date.length > 0 && this.end_date.length > 0)
    },
    datePlaceholder () {
      if (!this.selectValue) return 'DD-MM-AAAA - DD-MM-AAAA'
      const periodValue = predefinedDates(Date.now(), this.selectValue)
      return `${periodValue[0]} - ${periodValue[1]}`
    },
    datesOptions () {
      return dashDatesOptions(moment().subtract(12, 'months').format('DD/MM/YYYY'))
    }
  },
  watch: {
    selectValue () {
      const dateValues = predefinedDates(new Date(), this.selectValue)
      this.start_date = dateValues[0]
      this.end_date = dateValues[1]
      this.period = `${this.start_date}${this.end_date}`
      this.updateFilterData()
    }
  },
  methods: {
    updateFilterData () {
      this.$emit('updateFilter', [
        { start_date: moment(this.formatDate(this.start_date)).format('YYYY-MM-DD') },
        { end_date: moment(this.formatDate(this.end_date)).format('YYYY-MM-DD') }
      ])
    },
    formatDate (date) {
      return `${date.split('/')[1]}/${date.split('/')[0]}/${date.split('/')[2]}`
    },
    handleCalendar (event) {
      this.auxData = event
    },
    applyData () {
      this.closePopover()
      this.start_date = this.auxData.dateRange.start
      this.end_date = this.auxData.dateRange.end
      this.formatPeriod(this.auxData.dateRange.start, this.auxData.dateRange.end)
      this.updateFilterData()
    },
    formatDateNumber (number) {
      if (parseInt(number) < 10) return `0${number}`
      return number
    },
    formatPeriod (start, end) {
      const splittedStart = start.split('/')
      const splittedEnd = end.split('/')
      const formatedStart = `${this.formatDateNumber(splittedStart[0])}/${this.formatDateNumber(splittedStart[1])}/${splittedStart[2]}`
      const formatedEnd = `${this.formatDateNumber(splittedEnd[0])}/${this.formatDateNumber(splittedEnd[1])}/${splittedEnd[2]}`
      this.period = `${formatedStart}${formatedEnd}`
    },
    openPopover () {
      this.showDatePicker = true
    },
    closePopover () {
      this.showDatePicker = false
    },
    selectTimePeriod (value) {
      this.selectValue = value
    },
    setHighlightedDates (date) {
      const dateFormated = moment(date).format('YYYY-MM-DD') + 'T00:00:00.000Z'
      if (!this.highlighted.to) {
        this.highlighted.to = dateFormated
      } else {
        this.highlighted.from = dateFormated
      }
    }
  }
}
</script>

<style lang='scss' scoped>
@import '~@/styles/reference.scss';
.periodContainer {
  display: flex;
    .period {
      width: 205px;
      margin-right: 10px;
      z-index: 4;
    }
    .date {
      width: 320px;
      margin-top: 25px;
      display: flex;
      align-items: center;
      .input {
        width: 250px;
        margin-right: 10px;
      }
    }
    @include mobile {
      margin-left: 0;
      flex-wrap: wrap;
      width: 100%;
      margin-top: 20px;
      .period {
        width: 100%;
        margin-right: 0px;
      }
      .date {
        width: 100%;
        margin-top: 15px;
      }
    }
  .open-filter-button {
    background: $base-color;
    background: var(--color-primary);
    border-color: $base-color;
    border-color: var(--color-primary);
    color: white;
    transition: all ease 0.3s;
    &:hover {
      background: color-mix(in srgb,var($base-color),#FFF 35%);
      background: color-mix(in srgb,var(--color-primary),#FFF 35%);
      border-color: color-mix(in srgb,var($base-color),#FFF 35%);
      border-color: color-mix(in srgb,var(--color-primary),#FFF 35%);
    }
    &:disabled {
      background: $feedback-inactive-50;
      border-color: $feedback-inactive-50;
      color: #565F7D;
    }
  }
}

</style>

<style lang="scss">
@import '~@/styles/reference.scss';
.datepicker {
  .vdp-datepicker__calendar {
    border: none;
    padding: 0 20px;
  }
  &.start-date {
    border-right: 0.5px solid #BEC0D3;
  }
  .vdp-datepicker__calendar .cell.selected {
    background: $base-color;
    color: white;
    border-radius: 50%;
  }
}
.initial-calendar {
  .vfc-main-container {
    box-shadow: none;
  }
  .vfc-week .vfc-day span.vfc-span-day.vfc-today {
    background-color: $hover-background-color;
    color: $c-color;
  }
  .vfc-week .vfc-day span.vfc-span-day.vfc-hover:hover, .vfc-week .vfc-day span.vfc-span-day.vfc-hovered {
    background-color: #00B2E3;
  }
  .vfc-week .vfc-day span.vfc-span-day.vfc-marked:before, .vfc-week .vfc-day .vfc-base-start, .vfc-week .vfc-day .vfc-base-end {
    background-color: #D8E0F6;
  }
  .vfc-week .vfc-day span.vfc-span-day.vfc-hover:hover, .initial-calendar .vfc-week .vfc-day span.vfc-span-day.vfc-hovered {
    background-color: #00B2E3;
  }
  .vfc-week .vfc-day span.vfc-span-day.vfc-marked.vfc-borderd:before, .vfc-week .vfc-day span.vfc-span-day.vfc-marked.vfc-start-marked:before, .vfc-week .vfc-day span.vfc-span-day.vfc-marked.vfc-end-marked:before {
    background-color: $base-color;
    border-radius: 50%;
  }
  .vfc-week .vfc-day span.vfc-span-day,
  .vfc-top-date span,
  .vfc-dayNames span {
    color: #5E6684;
  }
  .vfc-navigation-buttons div .vfc-arrow-left, .vfc-separately-navigation-buttons div .vfc-arrow-left,
  .vfc-navigation-buttons div .vfc-arrow-right, .vfc-separately-navigation-buttons div .vfc-arrow-right {
    border-color: #5E6684;
  }

}
</style>
