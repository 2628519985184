<template>
  <div class="instruction">
    <span
      v-if="step"
      class="number"
    >
      {{ step }}
    </span>
    <p class="text">
      {{ text }}
      <slot />
    </p>
  </div>
</template>

<script>
export default {
  name: 'Instruction',
  props: {
    step: Number,
    text: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang='scss' scoped>
@import '~@/styles/reference';

.instruction {
  display: flex;
  align-content: center;
  .number {
    width: 24px;
    height: 24px;
    background: $base-color;
    background:  var(--color-primary);
    padding: 0 10px;
    margin-right: 8px;
    color: white;
    border-radius: 50%;
    font-size: 16px;
    display: flex;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
  }
  .text {
    color: $base-color;
    font-size: 16px;
    .text-container {
      display: flex;
      align-items: center;
      .link {
        padding: 0 4px;
        color: $base-color;
      }
      .icon {
        color: $base-color;
      }
    }
  }
}

.instruction + .instruction {
  margin-top: 24px;
}
</style>
