<template>
  <div
    class="spine-chart"
  >
    <vue-c3
      id="spine-chart"
      :handler="handler"
    />
    <div class="legend">
      <div
        v-for="item in labelsFiltered"
        :key="item.id"
        class="item"
        :id="item.id"
        @mouseover="handleMouseOver(item.id)"
        @mouseleave="handleMouseDown()"
        @click="handleClick(item.id)"
      >
        <span
          class="border"
          :style="{'border-style': item.style, 'border-color': item.color}"
        />
        <span class="text">{{ item.label }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import VueC3 from 'vue-c3'

export default {
  name: 'SpineChart',
  components: {
    VueC3
  },
  props: {
    columns: Array,
    title: String,
    labels: {
      type: Array,
      default: () => [
        {
          id: 'data1',
          label: 'Período',
          color: '#00B2E3',
          style: 'solid'
        },
        {
          id: 'data2',
          label: 'Período de comparação',
          color: '#202950',
          style: 'solid'
        }
      ]
    }
  },

  data () {
    return {
      handler: new Vue()
    }
  },
  computed: {
    count () {
      return this.columns[0][0].length - 1
    },
    labelsFiltered () {
      if (this.columns.length < 3) {
        return [
          {
            id: 'data1',
            label: 'Período',
            color: '#00B2E3',
            style: 'solid'
          }
        ]
      }
      return this.labels
    },
    axisTick () {
      function getReferenceNumber (highestValue) {
        if (highestValue <= 10) {
          return 1
        } else if (highestValue <= 100) {
          return 50
        } else if (highestValue <= 250) {
          return 100
        } else if (highestValue <= 1000) {
          return 250
        } else if (highestValue <= 10000) {
          return 1000
        } else if (highestValue <= 20000) {
          return 2000
        } else {
          return 5000
        }
      }

      const highestArray01Value = Math.max(...this.columns[1].slice(1))
      let highestValue = highestArray01Value

      if (this.columns.length >= 3) {
        const highestArray02Value = Math.max(...this.columns[2].slice(1))
        highestValue = Math.max(highestArray01Value, highestArray02Value)
      }

      const referenceNumber = getReferenceNumber(highestValue)
      const maxValue = Math.ceil(highestValue / referenceNumber) * referenceNumber
      let ticks = []
      for (let i = 0; i <= maxValue; i += referenceNumber) {
        ticks.push(i)
      }
      return ticks
    },
    options () {
      let generateOptions = { names: {}, colors: {} }
      for (let index = 1; index < this.columns.length; index++) {
        generateOptions.names[`data${index}`] = this.labels[index - 1].label
        generateOptions.colors[`data${index}`] = this.labels[index - 1].color
      }
      return {
        data: {
          x: 'x',
          columns: this.columns,
          type: 'area-spline',
          ...generateOptions,
          classes: {
            title: this.title
          }
        },
        padding: {
          top: 10
        },
        legend: {
          show: false
        },
        tooltip: {
          format: {
            title: () => this.label
          },
          horizontal: false,
          contents: function (d) {
            const meta = this.config.data_classes
            const minor = '<span class="tooltip-comparison -error">↓</span>'
            const major = '<span class="tooltip-comparison -success">↑</span>'
            let tooltipObject = ''
            let percentHtml = ''

            d.forEach((object) => {
              tooltipObject += `
                <h6 class="tooltip-label">${object.name}</h6>
                <p class="tooltip-description">${object.value}</p>
              `
            })

            if (d.length > 1) {
              let percentChange = 0
              const value1 = parseFloat(d[0].value)
              const value2 = parseFloat(d[1].value)
              if (value1 !== 0 && value2 !== 0) percentChange = ((value1 - value2) / value1) * 100
              const percentFiltered = percentChange.toFixed(2)
              percentHtml = `<p class="tooltip-label">${percentChange >= 0 ? major : minor} ${percentFiltered} %</p>`
            }
            return `<div class="custom-tooltip">
            <h6 class="tooltip-title">${meta.title}</h6>
              ${tooltipObject}
              ${percentHtml}
            </div>`
          }
        },
        grid: {
          y: {
            show: true
          }
        },
        axis: {
          x: {
            type: 'timeseries',
            tick: {
              count: this.count,
              format: '%d %b.'
            }
          },
          y: {
            tick: {
              values: this.axisTick,
              format: function (x) {
                return x
              }
            }
          }
        }
      }
    }
  },
  watch: {
    columns () {
      this.handler.$emit('init', this.options)
      this.handler.$emit('dispatch', (chart) => chart.resize())
    }
  },
  mounted () {
    this.handler.$emit('init', this.options)
  },
  methods: {
    handleMouseOver (id) {
      this.handler.$emit('dispatch', (chart) => chart.focus(id))
    },
    handleMouseDown () {
      this.handler.$emit('dispatch', (chart) => chart.revert())
    },
    handleClick (id) {
      this.handler.$emit('dispatch', (chart) => chart.toggle(id))
    }
  }
}
</script>

<style lang='scss'>
@import '~@/styles/c3.min.css';
@import '~@/styles/reference.scss';

.spine-chart {
  max-width: 1024px;
  width: 100%;
  margin: 0 auto;
  .c3 svg {
    transform: translate(0,10px);
  }
  #spine-chart .c3-line-data1 {
    stroke-width: 4px;
    stroke: #202950;
  }
  #spine-chart .c3-line-data2 {
    stroke-width: 2px;
    stroke-dasharray: 3;
    stroke: #202950;
  }
  #spine-chart .c3-axis {
    fill: #BEC0D3;
  }
  #spine-chart .c3 line, .c3 path {
    stroke: #BEC0D3;
  }
  .c3-tooltip-container {
    box-shadow: 0px 0px 5px 0 hsla(0, 0%, 0%, .3);
  }
  .c3 line {
    stroke: none;
  }
  .c3-xgrids {
    line:not([stroke-width="10"]) {
      stroke: rgba(190, 192, 211, 1) !important;
      stroke-dasharray: 0;
    }
  }
  c3-axis-x .tick line, .c3-axis-x .tick text {
    transform: translate(-20px,0);
    word-spacing: 1px;
  }
  .c3-axis-x .tick:last-of-type text {
    transform: translate(-35px,0);
  }
  c3-axis-y .tick line, .c3-axis-y .tick text{
    transform: translate(-25px,0);
  }
  .c3-axis-y text,  .c3-axis-x text{
    fill: rgba(94, 102, 132, 1);
    font-size: 14px;
    font-family: $base-font-family;
  }
  .c3-axis-y text {
    transform: translate(0, 0) !important;
    text-anchor: end !important;
  }
  .c3-axis-x text{
    text-anchor: start !important;
  }
  .domain {
    stroke: #BEC0D3;
  }
  .c3-chart-arc text {
    font-size: small;
  }
  .c3-legend-background {
    stroke-width: 0;
  }
  .c3-grid line {
    stroke: #eaeaea;
    stroke-dasharray: 0;
  }
  .c3-grid line:last-of-type{
    transform: translate(0,2px);
  }
  .c3-axis-y .tick:last-of-type text {
    transform: translate(-25px,8px);
  }
  .custom-tooltip {
    background: white;
    padding: 8px;
    border-radius: 4px;
    width: 170px;

    .tooltip-title {
      font-size: 12px;
      text-transform: uppercase;
      margin-bottom: 5px;
      color: #202950;
    }
    .tooltip-label {
      font-size: 10px;
      color: #5E6684;
      font-weight: 400;
    }
    .tooltip-description {
      color: #5E6684;
      font-size: 12px;
      font-weight: 700;
    }
    .tooltip-comparison {
      &.-error {
        color: $like-color;
      }
      &.-success {
        color: $success-color;
      }
    }
  }

  .legend {
    margin-top: 30px;
    display: flex;
    align-items: center;
    .item {
      display: flex;
      align-items: center;
      cursor: pointer;
      .border {
        display: block;
        box-sizing: border-box;
        border-width: 2px;
        width: 24px;
      }
      .text {
        padding-left: 8px;
        font-size: 12px;
        color: #5E6684;
      }
    }
    .item + .item {
      margin-left: 16px;
    }
  }
}
</style>
