<template>
  <campaign-units />
</template>

<script>
import CampaignUnits from '@/containers/Campaign/Editing/CampaignUnits'

export default {
  components: { CampaignUnits }
}
</script>
