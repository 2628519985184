<template>
  <div class="award-details">
    <div class="title-header">
      <c-title
        class="title"
      >
        Detalhes do pedido
      </c-title>
    </div>
    <transition name="fade">
      <c-spinner v-if="loading" />
      <div
        class="content"
        v-else
      >
        <main>
          <rewarded-users
            title="Usuários premiados"
            use-int-value
            is-awards
            :total="award.value"
            :table-cols="cols"
            :users="award.awards"
            :release-date="award.release_date"
          />
          <campaign-info-card
            class="campaign-info"
            :campaign-info="award.award_campaign"
          />
        </main>

        <aside>
          <order-card
            :order-details="award"
            :disable-cancel-action="disableCancelFunction"
            @order-cancel="openCancelModal = true"
          />
          <payment-card
            :total="award.total"
            :bill-link="award.bill_link"
          />
          <historic-card
            is-awards
            :status="award.status"
          />
        </aside>
      </div>
    </transition>

    <c-confirm-modal
      :btn-props="{ primary: true }"
      confirm-text="Cancelar pedido"
      character="embarrassed"
      title="Tem certeza que deseja cancelar seu pedido?"
      message="Esse pedido de premiação não será processado"
      :is-opened="openCancelModal"
      @close="openCancelModal = false"
      @confirm="$emit('cancel-order'); openCancelModal = false"
    />
  </div>
</template>

<script>
import RewardedUsers from '@/components/General/RewardedUsers'
import CampaignInfoCard from '@/components/Awards/Details/components/CampaignInfoCard'
import OrderCard from '@/components/General/cards/OrderCard'
import PaymentCard from '@/components/General/cards/PaymentCard'
import HistoricCard from '@/components/General/cards/HistoricCard'
import CConfirmModal from '@/components/CComponents/CConfirmModal'

export default {
  components: {
    OrderCard,
    CampaignInfoCard,
    PaymentCard,
    HistoricCard,
    RewardedUsers,
    CConfirmModal
  },
  props: {
    loading: Boolean,
    award: {
      type: Object,
      default () { return {} }
    }
  },
  data () {
    return {
      openCancelModal: false,
      orderId: null,
      cols: ['email', 'cpf', 'valor']
    }
  },
  computed: {
    disableCancelFunction () {
      return this.award.status === 0 || this.award.status === 1 || this.award.status === 4
    }
  },
  methods: {
    handleLinkClick () {
      this.openAwardOrderModalInfo = false
    }
  }
}
</script>

<style lang="scss">
@import '~@/styles/reference';

.award-details {
  max-width: 1020px;
  margin-top: 35px;
  padding: 0 10px;
  display: flex;
  flex-direction: column;

  @include desktop { @include container; }

  > .title-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    > .title {
      max-width: 200px;

    }

    > .new-award-button {
      width: 170px;
      height: 40px;
      border-radius: 30px;
      font-size: 12px;
      margin-top: 16px;
      font-weight: 400;
      display: flex;
      align-items: center;
      justify-content: center;

      .icon {
        position: relative;
        font-size: 20px;
        bottom: 0;
        left: 0;
        margin-right: 5px;
      }
      .text-icon {
        margin-left: 5px;
        font-size: 30px;
        font-weight: bold;
      }
    }
  }
  .content {
    display: flex;
    justify-content: space-between;

    @include tablet { flex-direction: column; }

    @include mobile { flex-direction: column; }

    main {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      width: 100%;
      margin-right: 50px;
      margin-bottom: 20px;

      .campaign-info {
        margin-top: 20px;
      }
    }
    aside {
      display: flex;
      flex-direction: column;
      width: 100%;
      min-width: 300px;

      > :not(:first-child) {
        margin-top: 20px;
      }
    }
  }
}
</style>
