// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/img/blue-pattern.png");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".fade-enter-active[data-v-2ab7d5e8],.fade-leave-active[data-v-2ab7d5e8]{transition:opacity .3s}.fade-enter[data-v-2ab7d5e8],.fade-enter-from[data-v-2ab7d5e8],.fade-leave-to[data-v-2ab7d5e8]{opacity:0}.container[data-v-2ab7d5e8]{align-items:center;background:#fff;border:1px solid #f7f8fc;border-radius:8px;box-shadow:0 4px 8px 0 rgba(94,102,132,.12);display:flex;height:174px;justify-content:space-between;margin:48px 0;position:relative;width:1022px}.container>.sales-text[data-v-2ab7d5e8]{padding:40px}.container>.sales-text>h2[data-v-2ab7d5e8]{color:#202950;font-family:Nunito Sans;font-feature-settings:\"clig\" off,\"liga\" off;font-size:28px;font-style:normal;font-weight:700;margin-bottom:16px}.container>.sales-text>h3[data-v-2ab7d5e8]{color:#5e6684;font-family:Nunito Sans;font-feature-settings:\"clig\" off,\"liga\" off;font-size:18px;font-style:normal;font-weight:600;line-height:120%}.container>.sales-button[data-v-2ab7d5e8]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-position:0;background-size:1753px 963px;height:100%;opacity:.08;width:345px}.container>.button[data-v-2ab7d5e8]{position:absolute;right:40px;width:196px}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
module.exports = ___CSS_LOADER_EXPORT___;
