<template>
  <div class="homepage-preview">
    <visualizations
      v-if="isDesktop"
      :mode="isDesktopMode"
      v-model="isDesktopPreview"
    />
    <transition
      name="fade"
      mode="out-in"
    >
      <div
        class="preview"
        :class="!isDesktopPreview ? '-mobile' : ''"
      >
        <navbar-preview
          :loading="loading"
          :is-desktop-mode="isDesktopPreview"
          :logo="data.logo"
        />
        <painel-preview
          :is-desktop-mode="isDesktopPreview"
          :data="data"
        />
        <home-preview
          :is-desktop-mode="isDesktopPreview"
          :data="data"
        />
      </div>
    </transition>
  </div>
</template>

<script>
import Visualizations from '@/components/Customization/Preview/Visualizations.vue'
import NavbarPreview from '@/components/Customization/Preview/NavbarPreview/NavbarPreview.vue'
import PainelPreview from '@/components/Customization/Preview/Painel/PainelPreview.vue'
import HomePreview from '@/components/Customization/Preview/Home/HomePreview.vue'

export default {
  name: 'HomePage',
  components: {
    Visualizations,
    NavbarPreview,
    PainelPreview,
    HomePreview
  },
  props: {
    data: Object,
    loading: Boolean
  },
  data () {
    return {
      isDesktopMode: 'desktop',
      isDesktopPreview: true
    }
  },
  mounted () {
    if (!this.isDesktop) {
      this.isDesktopMode = 'mobile'
      this.isDesktopPreview = false
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/styles/reference';
.homepage-preview {
  margin-bottom: 48px;
}
.preview {
  width: 100%;
  max-width: 1022px;
  box-shadow: 0px 4px 8px 0px #5E66841F;
  transition: all ease-in-out 0.5s;

  &.-mobile {
    max-width: 360px;
    margin: 0 auto;
  }
}
</style>
