<template>
  <div class="campaign-filters">
    <h4 class="title">
      {{ title }}
    </h4>
    <div class="filters-content">
      <div class="search-bar">
        <k-input
          class="kinput"
          icon="search1"
          v-model="search"
          placeholder="Buscar por nome de oferta ou parceria"
          @input="search = $event"
        />
        <k-button
          no-icon
          class="kbutton"
          :loading="loading"
          :disabled="!validFiltersForm"
          @click.native="emitFilters"
        >
          <span v-if="!loading">Buscar</span>
        </k-button>
      </div>
    </div>
  </div>
</template>

<script>
import { KInput, KButton } from 'kaledo-components'

export default {
  name: 'AwardsFilters',
  components: {
    KInput,
    KButton
  },
  props: {
    loading: Boolean,
    title: String,
    campaignTypes: Array
  },
  data () {
    return {
      search: ''
    }
  },
  computed: {
    validFiltersForm () {
      return this.search || this.type
    }
  },
  methods: {
    emitFilters () {
      const filters = {
        ...(this.search) && {search: ''}
      }
      this.$emit('filters', filters)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/styles/reference.scss';

.campaign-filters {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 20px 0;

  > .title {
    width: 100%;
    text-align: left;
    margin-top: 10px;
    margin-bottom: 20px;
    font-size: 14px;
    text-transform: uppercase;
    color: #5E6684;
  }

  > .filters-content {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    .filter-label {
      color: #5E6684;
      font-size: 11px;
      font-weight: 400;
      text-transform: uppercase;
      margin-bottom: 8px;
    }

    > .radio-buttons {
      width: 100%;
      max-width: 150px;
      height: 65px;
      font-family: $base-font-family;

      label {
        color: #5E6684;
        font-size: 11px;
        font-weight: 400;
      }

      input {
        margin-right: 5px;
      }
    }
    .kdropdown {
      border-radius: 4px;
      max-width: 230px;
      height: 40px;
      margin-top: 13px;
      border: 1px solid #bec0d3;
      padding: 0;

      & :deep  .c-select-container {
        height: 40px;

        > .options {
          text-transform: none;
          font-weight: 400;
        }
      }

      & :deep  .c-select {
        flex-direction: row-reverse;
        height: 40px;
      }

      & :deep  .dropdown-button {
        padding-left: 0;
        text-transform: none;
        font-weight: 400;
      }

      & :deep  .false-placeholder {
        left: 0;
        top: 0;
        display: none !important;
      }

      & :deep  .label {
        display: none;
      }
    }
    .search-bar {
      display: flex;
      justify-content: space-between;
      width: 100%;
      max-width: 510px;

      .kinput {
        border-radius: 30px;
        max-width: 400px;
        height: 40px;
        padding: 10px 16px;
        border: 1px solid #5e6684;

        & :deep  .input-container {
          padding-top: 0px;

          .icon {
            color: #BEC0D3;
          }

          ::placeholder {
            color: #BEC0D3;
            font-size: 13px;
            font-weight: 400;
          }
        }
      }
      .kbutton {
        max-width: 100px;
        height: 40px;
        border-radius: 30px;
        font-weight: 400;
        font-size: 12px;

        & :deep .loading {
          width: 100%;
          left: auto;
          bottom: auto;
          position: relative;
        }
      }
    }
  }
}
</style>
