<template>
  <div class="container">
    <new-pwd
      :is-processing="isProcessing"
      :show-success="showSuccess"
      @submit-form="submitForm"
      @close-success="showSuccess = false"
    />
  </div>
</template>

<script>
import NewPwd from '@/components/Login/NewPwd'
import { mapActions } from 'vuex'
import * as types from '@/store/types'

export default {
  components: { NewPwd },
  data () {
    return {
      isProcessing: false,
      showSuccess: false
    }
  },
  methods: {
    ...mapActions({
      resetPassword: types.AUTH_RESET_PASSWORD
    }),
    async submitForm (formData) {
      this.isProcessing = true
      const token = this.$route.query.token
      const status = await this.resetPassword({ ...formData, token })

      if (status) this.showSuccess = true
      this.isProcessing = false
    }
  }
}
</script>
