<template>
  <section>
    <c-spinner
      v-if="isLoading"
      size="md"
    />

    <campaign-list
      v-else
      :is-exclusive="partner.is_exclusive"
      :campaigns="campaigns.list"
      :pagination="campaigns"
      :pagination-loading="this.loadingPagination"
      @paginate-list="paginateList"
      @toggle-campaign-status="toggleStatus"
      @copy="showFeedback"
    />

    <c-confirm-modal
      show-close
      hide-cancel
      :btn-props="{ primary: true, icon: 'chevron-right', iconSize: 'sm' }"
      confirm-text="Entendi"
      character="disappointed"
      message="Qualquer ação feita aqui, não será possível visualizar no seu clube!"
      title="Este parceiro está inativo!"
      :is-opened="showPartnerWarning"
      @confirm="hidePartnerWarning = true"
      @close="hidePartnerWarning = true"
    />

    <c-confirm-modal
      confirm-text="Inativar"
      character="embarrassed"
      title="Tem certeza que deseja inativar essa oferta?"
      message="Se inativá-la, não será possível visualizar qualquer conteúdo referente à ela no seu clube"
      :is-opened="campaignStatusConfirmation.show"
      @close="closeStatusConfirmation"
      @confirm="confirmStatus"
    />
  </section>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Loadable from '@/modules/loadable'
import * as types from '@/store/types'

import CampaignList from '@/components/Campaign/Listing/CampaignList'
import CConfirmModal from '@/components/CComponents/CConfirmModal'

export default {
  components: { CampaignList, CConfirmModal },
  mixins: [ Loadable ],
  data () {
    return {
      loadingPagination: false,
      hidePartnerWarning: false,
      campaignStatusConfirmation: { show: false, campaign: '', status: null }
    }
  },
  computed: {
    ...mapGetters({
      campaigns: types.CAMPAIGN_LIST_PAGINATION,
      partner: types.PARTNER
    }),
    showPartnerWarning () {
      if (this.partner && this.partner.is_exclusive) {
        return this.partner.status === 0 && !this.hidePartnerWarning
      }
    }
  },
  methods: {
    ...mapActions({
      toggleCampaignStatus: types.CAMPAIGN_TOGGLE_STATUS,
      paginateCampaigns: types.CAMPAIGN_LIST_PAGINATION,
      clearCampaigns: types.CAMPAIGN_LIST_CLEAR,
      getCampaigns: types.CAMPAIGN_LIST_FILTER,
      showFeedback: types.FEEDBACK
    }),
    async paginateList () {
      const page = this.campaigns.current_page + 1
      const partner = this.$route.params['slug']
      this.loadingPagination = true
      await this.paginateCampaigns({ partner, page })
      this.loadingPagination = false
    },
    async load () {
      const partner = this.$route.params['slug']
      await this.getCampaigns({ partner })
    },
    async confirmStatus () {
      const { campaign: slug, status } = this.campaignStatusConfirmation
      await this.toggleCampaignStatus({ slug, status })
      this.closeStatusConfirmation()
    },
    toggleStatus ({ slug, status }) {
      if (!status) this.campaignStatusConfirmation = { show: true, campaign: slug, status }
      else this.toggleCampaignStatus({ slug, status })
    },
    closeStatusConfirmation () {
      this.campaignStatusConfirmation = { show: false, campaign: '', status: null }
    }
  },
  async destroyed () {
    await this.clearCampaigns()
  }
}
</script>
