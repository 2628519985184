<template>
  <div
    class="c-upload-image"
    :class="generatedClass"
  >
    <label
      v-if="label"
      :for="id"
      class="label"
    >
      <span>
        {{ label }}
        <span
          v-if="required"
          class="required"
        >*</span>
      </span>
    </label>

    <transition name="c-input-feedback">
      <span
        v-if="feedbackShow && feedbackMessage"
        class="feedback"
      >
        {{ feedbackMessage }}
      </span>
    </transition>
    <div>
      <c-button
        class="preview-button"
        type="button"
        size="lg"
        :disabled="!imgSrc"
        :popover-label="`<img style='height: 100%; width: 100px; object-fit: contain' src='${imgSrc}' />`"
        :left-pixels="'30%'"
      >
        Preview
      </c-button>

      <div class="inner">
        <div class="input">
          <input
            autofill="new-password"
            type="file"
            :id="id"
            :disabled="disabled"
            :accept="fileAccept"
            :class="['input-field', { '-disabled': disabled }]"
            @change="handleFile"
          />
          <label
            :for="id"
            class="input-label"
          >
            <span v-if="fileName">
              <c-icon
                icon="files-compare"
                width="16"
              />
              <span>{{ fileName }}</span>
            </span>
            <span
              v-else
              class="default"
            >
              <c-icon
                icon="upload-circle"
                width="16"
              />
              <span>{{ fileLabel }}</span>
            </span>
          </label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mask } from 'vue-the-mask'

export default {
  directives: {
    mask (el, binding) {
      if (!binding.value) {
        return
      }
      return mask(el, binding)
    }
  },

  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    required: {
      type: Boolean,
      default: false
    },
    placeholder: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'text',
      validator: value => 'text|number|email|password|search|url|tel|file|color'.split('|').indexOf(value) > -1
    },
    fileLabel: {
      type: String,
      default: 'Adicionar arquivo'
    },
    fileAccept: {
      type: String,
      default: '*'
    },
    label: {
      type: String,
      default: ''
    },
    mask: {
      type: [Array, String],
      default: ''
    },
    icon: {
      type: String,
      default: ''
    },
    feedbackType: {
      type: String,
      default: 'error'
    },
    feedbackMessage: {
      type: String,
      default: ''
    },
    feedbackShow: {
      type: Boolean,
      default: false
    },
    name: {
      type: String,
      default: ''
    },
    value: {
      default () { return [] }
    }
  },

  data () {
    return {
      id: -1,
      active: false,
      inputed: false,
      internalValue: null,
      openPreviewModal: false,
      fileName: false,
      imgSrc: null,
      hexTokens: {
        F: {
          pattern: /[0-9a-fA-F]/,
          transform: v => v.toLocaleUpperCase()
        }
      }
    }
  },

  computed: {
    generatedClass () {
      return {
        '-file': this.type === 'file',
        '-color': this.type === 'color',
        '-disabled': this.disabled,
        '-active': this.active,
        '-inputed': this.inputed,
        '-hasIcon': this.icon,
        '-success': this.feedbackType === 'success',
        '-error': this.feedbackType === 'error',
        '-showFeedback': this.feedbackShow
      }
    }
  },

  watch: {
    internalValue: function (val) {
      this.inputed = val.length > 0
    },

    value: {
      handler (v) {
        if (v) {
          this.internalValue = v.toString()
          this.imgSrc = v
        } else { this.internalValue = '' }
      },

      immediate: true,
      deep: true
    },

    imgSrc () {
      this.$emit('changed-image', { name: this.name, image: this.imgSrc })
    }
  },

  created () {
    this.inputed = !!this.internalValue
    this.id = `${this._uid}`
  },

  methods: {
    handleFile ({ target }) {
      if (target.files.length) {
        this.$emit('input', target.files[0])

        const reader = new FileReader()
        reader.onload = (e) => {
          this.imgSrc = e.target.result
        }

        reader.readAsDataURL(target.files[0])

        this.fileName = target.files[0].name
      } else {
        this.fileName = false
      }
    }
  }
}
</script>

<style lang="scss">
@import '~@/styles/reference';

$c-input-placeholder-color: #cfd2e0 !default;

.c-upload-image {
  display: flex;
  position: relative;
  align-items: center;

  @include mobile {
    display: flex;
    flex-direction: column;
  }

  & >.label {
    text-transform: uppercase;
    position: absolute;
    right: 100%;
    margin-right: 10px;
    flex: none;
    color: $title-color;
    font-size: 12px;
    line-height: 16px;
    max-width: 108px;
    width: 100%;
    text-align: right;
    display: block;

    @include mobile {
      max-width: 100%;
      text-align: left;
      margin-bottom: 9px;
      padding-left: 10px;
      position: static;
    }
  }

  &.-file {
    .preview-button {
      background: none;
      text-transform: uppercase;
      color: $title-color;
      margin-bottom: 5px;
      font-size: 13px;
    }

    .inner, .preview-button {
      border: 1px solid $another-color;
      border-radius: 30px;
      padding: 12px 0;
      width: 265px;
    }

    .input-field {
      display: none;
    }

    .input-label {
      display: block;
      width: 100%;
      height: 100%;
      text-align: center;
      font-size: 13px;
      text-transform: uppercase;
      color: $title-color;
      fill: darken($c-input-placeholder-color, 10);
      pointer-events: all;
      transition: background 0.3s ease;
      will-change: background;
      cursor: pointer;
      .c-icon {
        position: relative;
        top: 2px;
        margin-right: 5px;
      }

      &:hover {
        background: darken(#fdfdfd, 2);

        .default span {
          text-decoration: underline;
        }
      }
    }
  }
}

.c-input-feedback-enter-active,
.c-input-feedback-leave-active {
  transition: all 0.3s ease-out;
}

.c-input-feedback-enter,
.c-input-feedback-leave-to {
  opacity: 0;
  transform: translateY(-10px);
}

</style>
