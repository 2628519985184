<template>
  <div class="user-import-file-selection">
    <c-input
      class="file btn-action"
      type="file"
      file-size="100000"
      :text="isDesktop ? 'Selecionar arquivo' : 'Selec. arquivo'"
      :placeholder="isDesktop ? 'Selecione um arquivo .csv' : 'Arquivo .csv'"
      :exts="['csv']"
      @click.native="handleAnalytics('botao-importar-selecionar-arquivo', {company_name: company.name})"
      @change="file => $emit('input', file)"
    />

    <c-button
      external
      class="model btn-action"
      size="lg"
      icon="download-circle"
      icon-size="md"
      to="https://s3-us-west-2.amazonaws.com/clube-newcore/public-content/model-importacao.csv"
      target="_blank"
      :full-size="!isDesktop"
      @click="handleAnalytics('botao-importar-baixar-modelo', {company_name: company.name})"
    >
      Baixar modelo
    </c-button>

    <c-button
      class="documents btn-action"
      size="lg"
      icon="file-spreadsheet"
      icon-size="md"
      :full-size="!isDesktop"
      @click="downloadCpfs()"
    >
      BAIXAR CPF'S HABILITADOS
    </c-button>
  </div>
</template>

<script>
import * as types from '@/store/types'
import { mapGetters } from 'vuex'
export default {
  computed: {
    ...mapGetters({ company: types.COMPANY })
  },
  methods: {
    downloadCpfs () {
      this.$emit('download-cpfs-enabled')
      this.handleAnalytics('botao-importar-baixar-cpfs', {
        company_name: this.company.name
      })
    },
    handleAnalytics (event, data) {
      window.dataLayer.push(
        {
          event: event,
          ...data
        }
      )
    }
  }
}
</script>

<style lang="scss">
@import '~@/styles/reference';

.user-import-file-selection {
  display: flex;
  flex-flow: row wrap;
  margin: 0 5px;

  @include mobile {
    padding-bottom: 60px;
    justify-content: center;

    & > .btn-action:not(:last-child) { margin-bottom: 10px; }
  }

  & > * {
    margin: 0 5px 5px;
    flex: auto 0 0;
  }

  & > .file {
    flex-grow: 1;

    @include mobile { width: 100%; }

    @include desktop {
      min-width: 400px;
      max-width: 550px;
    }
  }
}
</style>
