<template>
  <div class="color-picker">
    <label
      class="label"
      for=""
    >{{ label }}</label>

    <div class="color-picker-input-container">
      <input
        class="color-picker-input"
        type="color"
        maxlength="7"
        :value="color"
        @input="handleColorChange($event.target.value)"
      />
      <input
        type="text"
        class="text-input"
        maxlength="7"
        placeholder="#"
        :value="color"
        @input="handleColorChange($event.target.value)"
      />
    </div>
    <span
      v-if="!validation"
      data-cy="cinput-feedback"
      class="error"
    >
      <c-icon
        icon="warning-circle"
        size="15"
        class="icon"
      />
      Cor Inválida
    </span>
  </div>
</template>

<script>
import { isHexColor } from '@/modules/validate/validators.js'
export default {
  name: 'ColorPicker',
  props: {
    label: {
      type: String,
      default: ''
    },
    color: {
      type: String,
      default: '#FFFFFF'
    }
  },
  computed: {
    validation () {
      return isHexColor(this.color)
    }
  },
  methods: {
    handleColorChange (value) {
      const formatedValue = String(value).startsWith('#') ? value : `#${value}`
      this.$emit('input', formatedValue)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/styles/reference';
.color-picker {
  .label {
    font-size: 10px;
    text-transform: uppercase;
    color: $secondary-color-placeholder;
    margin-bottom: 8px;
  }
  .color-picker-input-container {
    max-width: 321px;
    width: 100%;
    min-width: 321px;
    background: #FDFDFD;
    border-radius: 4px;
    display: flex;
    align-items: center;
    border: 1px solid #BEC0D3;
    .color-picker-input {
      width: 29px;
      height: 25px;
      border: none;
      background: transparent;
      padding: 0px;
      margin-left: 16px;
      margin-right: 8px;
      &:focus {
        outline: none;
      }
    }
    .text-input {
      width: 100%;
      height: 32px;
      border: none;
      background: transparent;
      font-size: 12px;
      font-weight: 400;
      color: #5E6684;
      padding: 8px 0px;
      text-transform: uppercase;
      &:focus {
        outline: none;
      }
    }
  }
  .error {
    display: flex;
    align-items: center;
    color: #ff7986;
    font-size: 12px;
    margin-top: 5px;

    .icon {
      margin-right: 5px;
      fill: #ff7986;
    }
  }
}
</style>
