<template>
  <div
    data-cy="orders-resume"
    class="orders-resume"
  >
    <c-title
      class="title"
    >
      Sua Carteira
    </c-title>
    <wallet-balance
      :loading="loading"
      :balance="company.balance"
      @new-order="handleNewOrder()"
    />
    <div class="title-header">
      <c-title
        class="title"
      >
        Extrato
      </c-title>
      <div class="filters">
        <period-filter
          :disable-action="!hasFilters"
          @updateFilter="handlePeriodFilter($event)"
          @submitFilter="filterOrders()"
        />
        <c-button
          class="open-filter-button"
          size="lg"
          ref="filter"
          @click="showFilter = !showFilter"
        >
          Status
          <k-icon
            class="icon"
            icon="arrow-down1"
          />
        </c-button>
      </div>
      <c-popover
        v-if="showFilter"
        :position="['top', 'center']"
        :target="$refs.filter"
        @blur="showFilter = false"
      >
        <filters
          :active-filter="statusFilter"
          @clearFilters="clearStatusFilter()"
          @apply-filters="handleStatusFilter($event)"
        />
      </c-popover>
    </div>
    <transition name="fade">
      <c-spinner v-if="loading" />
      <c-empty
        v-else-if="showEmpty"
        class="empty-search"
        title="Ops!"
        character="searching"
        message="Não encontramos nenhuma operaçao relacionada à busca"
      />
      <listing-table
        v-else
        data-cy="orders-resume-table"
        empty-banner-text="Você ainda não realizou nenhuma operação com a sua carteira"
        has-empty-banner
        :rows="ordersResume"
        :cols="tableCols"
        :paginate="links"
        @details-click="handleDetailsClick($event)"
        @new-order="handleNewOrder()"
        @update-paginate="handlePaginate"
      />
    </transition>
  </div>
</template>

<script>
import { COMPANY, USER, USER_WALLET_ORDERS, COMPANY_BALANCE } from '@/store/types'
import { mapGetters, mapActions } from 'vuex'
import ListingTable from './components/ListingTable.vue'
import WalletBalance from './components/WalletBalance.vue'
import Filters from './components/Filters.vue'
import CPopover from '@/components/CComponents/CPopover'
import PeriodFilter from './components/PeriodFilter.vue'
import { KIcon } from 'kaledo-components'

export default {
  components: {
    ListingTable,
    WalletBalance,
    Filters,
    CPopover,
    PeriodFilter,
    KIcon
  },
  data () {
    return {
      showFilter: false,
      tableCols: [
        { label: 'Nº', align: 'center' },
        { label: 'Valor', align: 'center' },
        { label: 'Origem', align: 'center' },
        { label: 'Responsável', align: 'center' },
        { label: 'Data', align: 'center' },
        { label: 'Status', align: 'center' }
      ],
      statusStringFilter: '',
      statusFilter: [],
      periodFilter: '',
      openCancelModal: false,
      orderId: null,
      loading: true,
      paginateFilter: '',
      paramsChanged: false,
      params: {
        limit: 10,
        page: 1
      }
    }
  },
  computed: {
    ...mapGetters({
      user: USER,
      company: COMPANY,
      orders: USER_WALLET_ORDERS
    }),
    hasFilters () {
      return this.statusStringFilter || this.periodFilter
    },
    showEmpty () {
      const rowsEmpty = this.ordersResume.length === 0
      return !this.loading && this.hasFilters && rowsEmpty
    },
    ordersResume () {
      if (!this.orders || !this.orders.data) return []
      return this.orders.data
    },
    links () {
      if (!this.orders || !this.orders.meta) return {}
      return this.orders.meta
    }
  },
  async mounted () {
    await this.getBalance()
    await this.filterOrders()
    this.loading = false
  },
  methods: {
    ...mapActions({
      getOrders: USER_WALLET_ORDERS,
      getBalance: COMPANY_BALANCE
    }),

    async handlePaginate (paginate) {
      this.paginateFilter = this.stringifyFilters([paginate])
      this.paramsChanged = true
      await this.filterOrders()
    },
    handleAnalytics (event, data) {
      window.dataLayer.push(
        {
          event,
          ...data
        }
      )
    },
    handleNewOrder () {
      window.dataLayer.push({
        event: 'carteira-adicionar-saldo',
        company_name: this.company.slug
      })
      this.$router.push({ path: 'carteira/adicionar' })
    },

    async clearStatusFilter () {
      this.statusFilter = []
      this.statusStringFilter = ''
      await this.filterOrders()
    },
    stringifyFilters (filters) {
      if (!filters) return ''
      let string = ''
      filters.forEach(filter => {
        if (filter.status) string += `${string}status=${filter.status}&`
        else if (filter.start_date) string += `${string}start_date=${filter.start_date}&`
        else if (filter.end_date) string += `end_date=${filter.end_date}&`
        else if (filter.page) string += `page=${filter.page}&limit=${filter.limit}&`
      })
      return string
    },
    handleStatusFilter (filter) {
      this.statusFilter = filter
      this.statusStringFilter = this.stringifyFilters(filter)
      this.filterOrders()
    },
    handlePeriodFilter (filter) {
      this.periodFilter = this.stringifyFilters(filter)
    },
    async filterOrders () {
      this.loading = true
      await this.getOrders(`${this.statusStringFilter}${this.periodFilter}${this.paginateFilter}`)
      this.paramsChanged = false
      this.loading = false
    },
    handleDetailsClick (orderId) {
      this.handleAnalytics('carteira-visualizar-detalhes', {
        company_name: this.company.slug
      })
      this.$router.push({ path: `carteira/detalhes/${orderId}` })
    }
  }
}
</script>

<style lang="scss">
@import '~@/styles/reference';

.orders-resume {
  max-width: 1020px;
  margin-top: 35px;
  padding: 0 10px;

  @include desktop { @include container; }

  > .title-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    > .filters {
      display: flex;
      align-items: flex-end;
      > .button {
        margin-left: 5px;
      }
    }
    > .title {
      max-width: 200px;
    }
  }
  .open-filter-button {
    margin-left: 24px;
    background: #FFFFFF;
    border: 1px solid $neutral-gray-200;
    transition: all ease 0.3s;
    .icon {
      margin-left: 8px;
    }
    &:hover {
      border-color: $base-color;
      border-color: var(--color-primary)
    }
  }
}
</style>
