<template>
  <div
    data-cy="notifications-listing"
    class="notifications-listing"
  >
    <div class="title-header">
      <c-title
        class="title"
      >
        Notificações
      </c-title>
      <div
        v-if="(notifications.length || showEmpty) && !loading"
        class="actions"
      >
        <filters-popover
          :filters-by-status="filtersByStatus"
          :show-filter="showFilter"
          :has-filter="hasFilters"
          @select-filter="filterNotifications"
          @blur="showFilter = false"
          @show-filter="showFilter = !showFilter"
          @clear-filter="clearFilter"
          @apply-filter="submitSearch"
          @close="showFilter = false"
        />
      </div>
    </div>
    <transition
      name="fade"
    >
      <c-spinner v-if="loading" />
      <c-empty
        v-else-if="showEmpty"
        class="empty-search"
        title="Ops!"
        character="searching"
        message="Não encontramos nenhuma notificação relacionada à busca"
      />
      <listing-table
        v-else
        data-cy="notifications-table"
        empty-banner-text="Você ainda não possui uma notificação criada"
        :rows="notifications"
        :cols="tableCols"
        :paginate="paginate"
        :has-empty-banner="!search"
        @update-paginate="handlePaginate"
        @empty-banner-action="$emit('new-notification')"
        @action-click="notification => $emit('edit-notification', notification)"
        @details-click="notification => $emit('notification-details', notification)"
      />
    </transition>
  </div>
</template>

<script>
import { COMPANY } from '@/store/types'
import { mapGetters } from 'vuex'
import FiltersPopover from '@/components/General/FiltersPopover'
import ListingTable from './components/ListingTable'
const notificationsStatuses = ['inactive', 'active', 'schedulled']

export default {
  components: {
    FiltersPopover,
    ListingTable
  },
  props: {
    loading: Boolean,
    notifications: {
      type: Array,
      default () {
        return []
      }
    },
    paginate: [Object, Array]
  },
  data () {
    return {
      tableCols: [
        { label: 'Nome', align: 'center' },
        { label: 'Data Inicial', align: 'center' },
        { label: 'Data Final', align: 'center' },
        { label: 'Cliques', align: 'center' },
        { label: 'Status', align: 'center' },
        { label: 'Ação', align: 'center' }
      ],
      search: '',
      someSearchHasSubmitted: false,
      showFilter: false,
      filters: {},
      paramsChanged: false,
      params: {
        limit: 10,
        page: 1
      },
      filtersByStatus: [
        { name: 'Notificação agendada', icon: 'clipboard-check', status: 'schedulled', selected: false },
        { name: 'Notificação ativa', icon: 'award-star', status: 'active', selected: false },
        { name: 'Notificação inativa', icon: 'cross-circle2', status: 'inactive', selected: false }
      ]
    }
  },
  computed: {
    ...mapGetters({
      company: COMPANY
    }),
    hasFilters () {
      return this.filters && this.filters.type && this.filters.type.length > 0
    },
    showEmpty () {
      const hasSearch = this.search && this.search.length > 0
      const rowsEmpty = this.rows && this.rows.length === 0
      return !this.loading && (this.someSearchHasSubmitted || hasSearch || this.hasFilters) && rowsEmpty
    },
    rows () {
      return this.notifications || []
    }
  },
  methods: {
    handleAnalytics (event, data) {
      window.dataLayer.push(
        {
          event,
          ...data
        }
      )
    },
    async handlePaginate (paginate) {
      this.params = paginate
      this.paramsChanged = true
      await this.submitSearch()
    },
    filterNotifications (property) {
      const hasType = notificationsStatuses.some(type => type.includes(this.filters.type))

      hasType && this.filters.type === property.type
        ? this.filters = {}
        : this.filters = { ...this.filters, ...property }

      const changeStatus = filter => {
        filter.status === property.type
          ? filter.selected = !filter.selected
          : filter.selected = false
        return filter
      }
      this.filtersByStatus.map(changeStatus)
    },
    async clearFilter () {
      this.filters = {}
      this.filtersByStatus = this.filtersByStatus.map(filter => ({ ...filter, selected: false }))
      await this.submitSearch()
    },
    handleConfirmCancel () {
      this.$emit('edit-notification')
    },
    async submitSearch () {
      this.someSearchHasSubmitted = true
      const type = Object.values(this.filters)[0] // mal time
      const hasType = notificationsStatuses.some(v => v === type)
      if (this.search || this.filters.manager || hasType || this.paramsChanged) {
        this.$emit('submit-search', { title: this.search, status: type, ...this.params })
        this.paramsChanged = false
      } else {
        this.params.page = 1
        this.$emit('submit-search')
      }
      this.showFilter = false
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/styles/reference';
.notifications-listing {
  max-width: 1020px;
  margin-top: 35px;
  padding: 0 10px;

  @include desktop { @include container; }

  .title-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 1020px;
    margin: 48px auto 28px auto;

    > .title {
      max-width: 200px;
    }

    .actions {
      display: flex;
      justify-content: flex-end;

      .notification-search {
        margin-right: 20px;

        .input {
          width: 350px;
          background: #FDFDFD;
          border: 1px solid rgba(86, 95, 125, 1);
          border: 1px solid var(--color-secondary);
          transition: all .1s ease;
        }
      }
    }
  }
}
</style>
