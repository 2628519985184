<template>
  <select-club-confirmation
    @submit="submit"
    @clear-error="clearError"
    :companies="companiesActive"
    :is-loading="isLoading"
    :error="errorCode"
  />
</template>

<script>
import * as types from '@/store/types'
import { mapActions, mapGetters } from 'vuex'

import SelectClubConfirmation from '@/components/Login/SelectClubConfirmation'

const errorCodes =
{ 0: { type: '', data: {} }
, 1: { type: 'invalidCredentials', data: {} }
, 2: { type: 'userNotFound', data: {} }
, 3: { type: 'isActive', data: {} }
, 4: { type: 'notEnabled', data: {} }
}

export default {
  components: { SelectClubConfirmation },
  data () {
    return {
      isLoading: false,
      errorCode: errorCodes[0]
    }
  },
  computed: {
    ...mapGetters({
      companiesActive: types.CONFIG_COMPANIES_ACTIVE,
      companiesEnabled: types.CONFIG_COMPANIES_ENABLED,
    })
  },
  methods: {
    ...mapActions({
      confirm: types.AUTH_ACTIVATE_CONFIRM
    }),
    async submit (password) {
      this.isLoading = true
      const { error, ...data } = await this.confirm({ password })

      if (error) {
        this.errorCode = errorCodes[error]
        this.errorCode.data = data
      } else if (this.companiesEnabled.length > 1) {
        this.$router.push({ name: 'ativacao-clubes' })
      } else if (this.companiesEnabled.length) {
        const company = this.companiesEnabled[0]['id']
        this.$router.push({ name: 'ativacao-company', params: { company } })
      }

      this.isLoading = false
    },
    clearError () {
      this.errorCode = errorCodes[0]
    }
  }
}
</script>
