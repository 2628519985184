<template>
  <div class="container">
    <forget-pwd
      @forget-password="submit"
      @toggle-failed-modal="failedModal = !failedModal"
      @toggle-sucess-modal="sucessModal = !sucessModal"
      :sucess-modal="sucessModal"
      :failed-modal="failedModal"
      :email="email"
    />
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import * as types from '@/store/types'
import ForgetPwd from '@/components/Login/ForgetPwd'

export default {
  components: { ForgetPwd },
  data () {
    return {
      sucessModal: false,
      failedModal: false,
      email: ''
    }
  },
  methods: {
    ...mapActions({
      remember: types.AUTH_REMEMBER
    }),

    async submit (document) {
      const { status, email } = await this.remember({ document })

      if (status) {
        this.sucessModal = true
        this.email = email
      } else {
        this.failedModal = true
      }
    }
  }
}
</script>
