<template>
  <c-table-container
    class="rewarded-users-table"
    v-bind="$attrs"
    v-on="$listeners"
    :paginate="paginate"
  >
    <table class="table">
      <tr class="table-header">
        <th
          v-for="col in cols"
          :key="col"
        >
          <div class="margin">
            {{ col }}
          </div>
        </th>
      </tr>
      <tr
        v-for="(row, index) in rows"
        :key="index"
        class="table-row"
      >
        <td :class="tdClass">
          <div class="margin">
            <span v-if="row">{{ row.email || row.user.email }}</span>
          </div>
        </td>
        <td :class="tdClass">
          <div class="margin">
            <span v-if="row"> {{ formatDocument(row) }}</span>
          </div>
        </td>
        <td :class="tdClass">
          <div class="margin">
            <span v-if="row">{{ isAwards ? formatCurrency(row.value) : formatValue(row.value) }}</span>
          </div>
        </td>
      </tr>
    </table>
    <div
      class="empty-content"
      v-if="rows.length === 0 && hasEmptyBanner"
    >
      <empty-banner />
    </div>
  </c-table-container>
</template>

<script>
import EmptyBanner from '@/components/General/EmptyBanner.vue'

const statuses = {
  0: {
    label: 'Cancelado',
    color: '#EE5250'
  },
  1: {
    label: 'Entregue',
    color: '#4CAD50'
  },
  3: {
    label: 'Aguardando Pagamento',
    color: '#f5a623'
  },
  4: {
    label: 'Entrega Agendada',
    color: '#f5a623'
  }
}

export default {
  components: {
    EmptyBanner,
    CTableContainer: () => import('@/components/CComponents/CTableContainer')
  },
  filters: {
    toDate (date) {
      return new Date(date * 1000).toLocaleDateString('pt-BR', { timeZone: 'America/Sao_Paulo' })
    }
  },
  props: {
    rows: Array,
    cols: Array,
    hasEmptyBanner: Boolean,
    paginate: Object,
    isAwards: Boolean,
    isFormatted: {
      type: Boolean,
      default: false
    },
    useIntValue: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      statuses,
      tdClass: 'data-tablee-cell -content -center'
    }
  },
  methods: {
    formatValue (value) {
      return this.useIntValue ? (value / 100) : value
    },
    formatCurrency (value) {
      const formatedValue = this.formatValue(value)
      return formatedValue.toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL'
      })
    },
    formatDocument (row) {
      if (this.isFormatted) {
        return row.document
      }
      if (row.user && row.user.document) {
        return row.user.document
      } else {
        const document = row.document
        if (!document) return ''
        return `${document.substring(0, 3)}.${document.substring(3, 6)}.${document.substring(6, 9)}-${document.substring(9, 11)}`
      }
    },
    formatString (string) {
      if (!string || string.length < 43) return string
      return string.substring(0, 43) + '...'
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/styles/reference';
.rewarded-users-table {
  font-size: 12px;
  color: #202950;

  :deep .table {
    height: 100%;
    max-height: 522px;
    display: block;
    overflow: auto;
    width: 100%;
    border: none !important;
    overflow-x: hidden;

    &::-webkit-scrollbar {
      width: 7px
    }

    &::-webkit-scrollbar-track {
      background: #BEC0D3;
      border-radius: 16px;
    }

    &::-webkit-scrollbar-thumb {
      background: #5E6684;
      border-radius: 16px;
    }

    tr {
      height: 33px;
      width: 100%;

      &.table-header {
        background: #F8F9FD;
        min-width: 190px;
        text-transform: uppercase;
        font-size: 12px;

        @include mobile { min-width: 120px }
      }

      th {
        font-weight: 700;
      }

      th, td {
        height: 33px;
        padding: 4px;
        min-width: 210px;

        &:last-of-type {
          min-width: 150px;
        }

        @include mobile { min-width: 120px }

        .margin {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 24px;
        }

        &:not(:last-of-type) > .margin {
          border-right: 1px solid #D8D8D8;
        }
      }

      &:not(:first-child) {
        border-top: 1px solid #D8D8D8;
      }

      &:nth-child(2n) {
        background: #FDFDFD;
      }

      &:nth-child(2n + 1) {
        background: #F8F9FD;
      }
    }
  }
  .empty-content {
    padding: 20px;
    background: white;
  }
}
</style>
