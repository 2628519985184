<template>
  <wallet-listing />
</template>

<script>
import WalletListing from '@/components/User/Wallet/WalletListing.vue'
export default {
  components: { WalletListing }
}
</script>

<style>
</style>
