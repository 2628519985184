<template>
  <div class="step-two">
    <div class="step-two-title">
      <c-title
        class="title"
      >
        Personalização da Campanha
      </c-title>
    </div>
    <div class="step-form">
      <h3>
        Imagem
      </h3>
      <instruction
        class="instruction"
        text="Insira uma imagem para a sua campanha que ficará disponível para os usuários em seu clube"
      />
      <upload-image
        :is-edit="false"
        @change="handleImage"
      />
      <span class="sub">
        Formato: <b>png</b><br />
        Tamanho: <b>300x130px</b>
      </span>
      <h3>
        Pré-visualização
      </h3>
      <instruction
        class="instruction"
        text="Pré visualização da sua campanha com as informações que foram preenchidas"
      />
      <campaign-preview-card
        :image="imageSrc"
        :name="previewData.title || ''"
        :description="previewData.description || ''"
        :value="previewData.value || ''"
        :coin="coin || {}"
        :end_date="previewData.end_date"
      />
    </div>
  </div>
</template>

<script>
import { KIcon } from 'kaledo-components'
import { swiper as SwiperContainer, swiperSlide as SwiperItem } from 'vue-awesome-swiper'
import Instruction from '@/components/General/Instruction'
import CampaignPreviewCard from '../../components/CampaignPreviewCard.vue'
import UploadImage from '@/components/General/UploadImage.vue'

export default {
  components: {
    KIcon,
    SwiperContainer,
    SwiperItem,
    Instruction,
    CampaignPreviewCard,
    UploadImage
  },
  props: {
    stepValue: Object,
    previewData: Object,
    coin: Object
  },
  data () {
    return {
      campaign: {},
      image: ''
    }
  },
  computed: {
    imageSrc () {
      if (!this.image || this.image.length === 0) return ''
      return URL.createObjectURL(this.image)
    }
  },
  methods: {
    update (key, value) {
      this.$emit('input', { ...this.stepValue, [key]: value })
    },
    handleImage (files) {
      this.image = files
      this.update('image', this.image)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/styles/reference';

.step-two {
  max-width: 1020px;
  margin-top: 40px;
  padding: 0;

  > .step-two-title {
    display: flex;
    height: 35px;
    margin-bottom: 20px;

    > .title {
      max-width: 240px;
      margin-bottom: 30px;
    }
  }

  .step-form {
    width: 100%;

    h3 {
      margin-top: 48px;
      margin-bottom: 16px;
      color: #565F7D;
    }

    .instruction {
      margin-bottom: 16px;
    }

    & > .title {
      padding-bottom: 24px;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #202950;
    }

    .images-list {
      .campaign-image {
        width: 300px;
        height: 130px;
        border-radius: 2px;
        cursor: pointer;

        &:hover {
          border: 1px solid #202950;
          border: 1px solid var(--color-secondary);
          border-radius: 2px;
        }
        &.-selected {
          border: 2px solid #202950;
          border: 2px solid var(--color-secondary);
        }
      }
      .carousel {
        padding-bottom: 5px;
        display: flex;

        .swiper-button { display: none; }

        :deep .swiper-wrapper {
          display: flex;

          @include mobile {
            padding: 0 10px;
          }
        }

        @include desktop {
          position: relative;
          overflow: hidden;

          & > .swiper-wrapper {
            padding: 10px 0;
            display: flex;

            & > .swiper-slide { display: block; }
          }

          & > .swiper-button {
            display: block;
            position: absolute;
            cursor: pointer;
            top: 50%;
            background-color: #FFFFFF;
            border-radius: 50%;
            width: 60px;
            height: 60px;
            transition: opacity .3s ease;

            &:hover {
              opacity: 0.8;
            }

            & > .icon {
              color: #202950;
              position: absolute;
              top: 50%;
              transform: translateY(-50%);
            }

            &.-disabled,
            &.-hidden { opacity: 0; }

            &.-prev {
              left: 0;
              transform: translate(-50%, -50%);

              & > .icon {
                right: 8px;
                position: absolute;
              }
            }

            &.-next {
              right: 0;
              transform: translate(50%, -50%);

              & > .icon {
                left: 8px;
                position: absolute;
              }
            }
          }
        }
      }
    }
  }
}
.swiper-slide {
  display: inline-block;
}

.swiper-wrapper { white-space: nowrap; }
</style>
