<template>
  <select-account-container />
</template>

<script>
import SelectAccountContainer from '@/containers/Login/SelectAccountContainer'

export default {
  components: { SelectAccountContainer },
  layout: 'unauthenticated',
  middleware: 'checkauth'
}
</script>
