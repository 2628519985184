<template>
  <div class="awards-page">
    <awards-header
      class="header"
      :nav-tabs="navTabs"
      @show-onboarding="showOnboarding = true"
    />
    <onboarding
      :title="onboardingTitle"
      :is-opened="showOnboarding"
      :showModal="showOnboarding"
      :topics="topics"
      @close="showOnboarding = false"
    />
    <nuxt-child />
  </div>
</template>

<script>
import AwardsHeader from '@/components/Awards/AwardsHeader'
import Onboarding from '@/components/General/OnboardingModal'
import { mapGetters } from 'vuex'
import { ORDERS_LIST } from '@/store/types'

export default {
  components: { AwardsHeader, Onboarding },
  data () {
    return {
      onboardingTitle: 'Guia de Premiações',
      showOnboarding: false,
      topics: [
        {
          icon: 'crown-circle',
          title: 'Ofertas de premiação',
          contents: ['Para ver as ofertas disponíveis para serem resgatadas como prêmio, basta acessar a aba Parceiros no menu e clicar em uma parceria. Dentro da página do parceiro selecionado estarão listadas todas as ofertas, incluindo as de premiação.']
        },
        {
          icon: 'task-list-document-text',
          title: 'Pedidos de premiação',
          contents: ['Um pedido de premiação é realizado em 4 passos que envolvem a escolha da campanha, seleção de usuários premiados, valor do prêmio e escolha da data de entrega do prêmio. O pagamento é feito por boleto bancário com validade de 1 dia.',
          'Para criar um novo pedido, basta clicar no botão "Novo Pedido” na aba Premiações.']
        },
        {
          icon: 'images-pictures-copy',
          title: 'Campanhas de premiação',
          contents: ['Campanhas servem para agrupar pedidos com a mesma finalidade. Todos os pedidos realizados utilizando uma mesma campanha terão comunicações iguais, ou seja, o texto e tema do email, pop-up e notificação que os premiados receberão serão os mesmos.',
          'Para visualizar as comunicações, basta clicar no botão "Ver Mais” de uma campanha.']
        },
        {
          icon: 'mail',
          title: 'Comunicação aos premiados',
          contents: ['As pessoas premiadas receberão um pop-up e notificação dentro do clube e um email na data agendada para a entrega do prêmio. O texto e visual dessas comunicações são personalizadas de acordo com a campanha escolhida no pedido. Você pode ver essas comunicações clicando no botão "Ver mais” de uma campanha.']
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      orders_list: ORDERS_LIST
    }),
    navTabs () {
      return [
        {
          name: 'Resumo',
          value: 'premiacoes'
        },
        {
          name: 'Pedidos',
          value: 'premiacoes-pedidos',
          disabled: !this.orders_list || !this.orders_list.data || this.orders_list.data.length === 0
        }
      ]
    }
  }
}
</script>

<style lang="scss">
.awards-page {
  & > .header { margin-bottom: 30px; }
}
</style>
