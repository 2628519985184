<template>
  <div class="spine-card">
    <graph-card
      :mini-cards="graphData.tabs"
      :format-tab-label="formatTabLabel"
      @change-tab="tab = $event"
      :loading="loading"
    >
      <not-available
        v-if="!loading && (error || showNotAvailable)"
        :type="error ? 'error' : 'empty'"
      />
      <component
        v-else-if="!loading"
        :is="spineComponent"
        :columns="correctGraph"
        :title="chartTitle"
      />
    </graph-card>
  </div>
</template>

<script>
import SpineChart from '@/components/Dashboard/base/SpineChart.vue'
import SpineTimeChart from '@/components/Dashboard/base/SpineTimeChart.vue'
import GraphCard from '@/components/Dashboard/cards/GraphCard.vue'
import notAvailable from '@/components/Dashboard/base/notAvailable.vue'

export default {
  name: 'SpineCard',
  components: {
    SpineChart,
    SpineTimeChart,
    GraphCard,
    notAvailable
  },
  props: {
    loading: Boolean,
    error: Boolean,
    graphData: {
      type: Object,
      default: () => ({
        tabs: []
      })
    },
    formatTabLabel: Boolean
  },
  data () {
    return {
      tab: 0,
      featureDisabled: false
    }
  },
  computed: {
    showNotAvailable () {
      if (this.loading || !this.graphData || !this.graphData.tabs) return true
      const isTabsEmpty = this.graphData.tabs.findIndex(tab => parseInt(tab.total) !== 0)
      if (isTabsEmpty === -1) return true
      if (!this.correctGraph || (this.correctGraph && this.correctGraph.columns && this.correctGraph.columns.length === 0)) return true
      return false
    },

    correctGraph () {
      if (!this.graphData) return []
      return this.graphData.columns[this.tab]
    },

    isTimeValue () {
      if (!this.graphData) return false
      return this.graphData.tabs[this.tab].isTimeValue
    },
    chartTitle () {
      if (!this.graphData) return ''
      return this.graphData.tabs[this.tab].chart_title
    },
    spineComponent () {
      if (this.isTimeValue) return 'spine-time-chart'
      return 'spine-chart'
    }
  }
}
</script>

<style lang='scss' scoped>
.spine-card {
  margin-top: 20px;
  .card {
    padding: 16px;
    background: #FDFDFD;
    width: 326px;
    border-radius: 6px 6px 0px 0px;
  }
  .main-card {
    background: #FDFDFD;
    padding: 16px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.16);
    border-radius: 0px 0px 6px 6px;
  }
}
</style>
