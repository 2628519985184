<template>
  <under-construction-card
    v-if="underConstruction"
    :tab-name="tabName"
  />
  <div
    class="dashboard-products-tab"
    v-else
  >
    <campaigns-bar-card
      class="card"
      :loading="loading"
      :title="kaledoMoneyData.title"
      :label="kaledoMoneyData.label"
      :data="kaledoMoneyData.tabs"
      icon="kaledo-money"
    />
    <vidalink-card
      :loading="loading"
      :graph-data="vidalinkData"
    />
    <awards-card
      :loading="loading"
      :donut-data="awardsCategoriesData"
    />
  </div>
</template>

<script>
import CampaignsBarCard from '@/components/Dashboard/cards/CampaignsBarCard.vue'
import VidalinkCard from '@/components/Dashboard/cards/VidalinkCard.vue'
import AwardsCard from '@/components/Dashboard/cards/AwardsCard.vue'
import UnderConstructionCard from '@/components/Dashboard/cards/UnderConstructionCard.vue'

export default {
  name: 'DashboardProductsTab',
  components: {
    CampaignsBarCard,
    VidalinkCard,
    AwardsCard,
    UnderConstructionCard
  },
  props: {
    underConstruction: Boolean,
    tabName: String
  },
  data () {
    return {
      loading: false,
      kaledoMoneyData: {
        title: 'Kaledo Money',
        label: 'Acessos no período',
        accesses: 3000,
        comparisonValue: 10,
        tabs: [
          {
            total: 3000,
            label: 'Acessos no período',
            comparisonValue: 10
          }
        ]
      },
      vidalinkData: {
        title: 'Vidalink',
        tabs: [
          {
            total: 10000,
            label: 'Acessos no período',
            icon: 'info-information-circle',
            comparisonValue: 10,
            chart_title: 'Contas ativadas'
          },
          {
            total: 10000,
            label: 'Ativações de cartão no período',
            icon: 'info-information-circle',
            comparisonValue: 10,
            chart_title: 'Usuários ativos'
          }
        ],
        columns: [
          [
            ['x', '2013-01-01', '2013-01-02', '2013-01-03', '2013-01-04', '2013-01-05'],
            ['data1', 30, 200, 100, 400, 150],
            ['data2', 130, 340, 200, 500, 250]
          ],
          [
            ['x', '2013-01-01', '2013-01-02', '2013-01-03', '2013-01-04', '2013-01-05', '2013-01-06'],
            ['data1', 300, 20, 10, 40, 150, 250],
            ['data2', 150, 360, 400, 200, 250, 350]
          ],
          [
            ['x', '2013-01-01', '2013-01-02', '2013-01-03', '2013-01-04', '2013-01-05', '2013-01-06'],
            ['data1', 30, 200, 100, 400, 150, 250],
            ['data2', 130, 340, 200, 500, 650, 350]
          ]
        ]
      },
      // awardsCampaignsData: ,
      awardsCategoriesData: {
        title: 'Categorias de premiação',
        columns: [
          [
            ['data1', 25, 20],
            ['data2', 24, 1.5],
            ['data3', 13, 15],
            ['data4', 14, 9],
            ['data5', 12, 8],
            ['data6', 12, 10]
          ],
          [
            ['data1', 30, 20],
            ['data2', 21, 1.5],
            ['data3', 12, 15],
            ['data4', 19, 9],
            ['data5', 10, 8],
            ['data6', 9, 10]
          ]
        ],
        names: {
          data1: 'Produtos e Serviços',
          data2: 'Item de leg c/ ícone',
          data3: 'Bem-estar e saúde',
          data4: 'Alimentos e bebidas',
          data5: 'Mercados',
          data6: 'Educação'
        },
        icons: [
          'monitor-computer-circle',
          'unwrap-mobile-iphone',
          'display-monitor-square',
          'monitor-computer-circle',
          'unwrap-mobile-iphone',
          'display-monitor-square'
        ],
        comparisonPeriod: {
          period_1: {
            start: 1666200980,
            end: 1666200989
          },
          period_2: {
            start: 1666201419,
            end: 1666201437
          }
        },
        comparisonValues: {
          data1: 25,
          data2: 1,
          data3: 10,
          data4: 8,
          data5: 11,
          data6: 10
        },
        comparisonResults: {
          data1: 0.1,
          data2: -0.3,
          data3: -10,
          data4: 0,
          data5: 6,
          data6: 2
        }
      }
    }
  },
  mounted () {
    this.loading = true
    setTimeout(() => {
      this.loading = false
    }, 5000)
  }
}
</script>

<style lang="scss" scoped>
.dashboard-products-tab {
  display: grid;
  grid-template-columns: 1fr 3fr;
  row-gap: 20px;

 .card {
    position: relative;
    background: white;
    z-index: 1;
    padding: 16px;
    width: 326px;
    height: 200px;
    border-radius: 6px 6px 0px 0px;
    margin-right: 10px;
    cursor: pointer;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.16);
    margin-bottom: 20px;
  }

  .awards-card {
    grid-column: 1 / -1;
  }
}
</style>
