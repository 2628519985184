<template>
  <div
    class="spine-chart"
  >
    <vue-c3
      id="spine-chart"
      :handler="handler"
    />
    <div class="legend">
      <div
        v-for="item in labelsFiltered"
        :key="item.id"
        class="item"
        :id="item.id"
        @mouseover="handleMouseOver(item.id)"
        @mouseleave="handleMouseDown()"
        @click="handleClick(item.id)"
      >
        <span
          class="border"
          :style="{'border-style': item.style, 'border-color': item.color}"
        />
        <span class="text">{{ item.label }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import VueC3 from 'vue-c3'

export default {
  name: 'SpineTimeChart',
  components: {
    VueC3
  },
  props: {
    columns: Array,
    title: String,
    labels: {
      type: Array,
      default: () => [
        {
          id: 'data1',
          label: 'Período',
          color: '#00B2E3',
          style: 'solid'
        },
        {
          id: 'data2',
          label: 'Período de comparação',
          color: '#202950',
          style: 'solid'
        }
      ]
    }
  },

  data () {
    return {
      handler: new Vue()
    }
  },
  computed: {
    count () {
      return this.columns[0][0].length - 1
    },
    labelsFiltered () {
      if (this.columns.length < 3) {
        return [
          {
            id: 'data1',
            label: 'Período',
            color: '#00B2E3',
            style: 'solid'
          }
        ]
      }
      return this.labels
    },
    axisTick () {
      const highestArray01Value = Math.max(...this.columns[1].slice(1))
      let highestValue = highestArray01Value

      if (this.columns.length >= 3) {
        const highestArray02Value = Math.max(...this.columns[2].slice(1))
        highestValue = Math.max(highestArray01Value, highestArray02Value)
      }

      const referenceNumber = 120
      const maxValue = Math.ceil(highestValue / referenceNumber) * referenceNumber
      let ticks = []
      for (let i = 0; i <= maxValue; i += referenceNumber) {
        ticks.push(i)
      }
      return ticks
    },
    options () {
      let generateOptions = { names: {}, colors: {} }
      for (let index = 1; index < this.columns.length; index++) {
        generateOptions.names[`data${index}`] = this.labels[index - 1].label
        generateOptions.colors[`data${index}`] = this.labels[index - 1].color
      }
      return {
        data: {
          x: 'x',
          columns: this.columns,
          type: 'area-spline',
          ...generateOptions,
          classes: {
            title: this.title
          }
        },
        padding: {
          top: 10
        },
        legend: {
          show: false
        },
        tooltip: {
          format: {
            title: () => this.label
          },
          horizontal: false,
          contents: function (d) {
            const meta = this.config.data_classes
            const isTimeValues = true
            const minor = '<span class="tooltip-comparison -error">↓</span>'
            const major = '<span class="tooltip-comparison -success">↑</span>'
            let tooltipObject = ''
            let percentHtml = ''

            function secondsToMinutes (seconds) {
              let minutes = Math.floor(seconds / 60)
              let extraSeconds = Math.floor(seconds % 60)
              minutes = minutes !== 0 ? `${minutes}min ` : ''
              extraSeconds = extraSeconds !== 0 ? `${extraSeconds}s` : ''

              return `${minutes}${extraSeconds}`
            }

            d.forEach((object) => {
              const value = secondsToMinutes(object.value)
              tooltipObject += `
                <h6 class="tooltip-label">${object.name}</h6>
                <p class="tooltip-description">${value}</p>
              `
            })

            if (d.length > 1) {
              let percentChange = 0
              const value1 = parseFloat(d[0].value)
              const value2 = parseFloat(d[1].value)
              if (value1 !== 0 && value2 !== 0) percentChange = ((value1 - value2) / value1) * 100
              const percentFiltered = percentChange.toFixed(2)
              percentHtml = `<p class="tooltip-label">${percentChange >= 0 ? major : minor} ${percentFiltered} %</p>`
            }
            return `<div class="custom-tooltip">
            <h6 class="tooltip-title">${meta.title}</h6>
              ${tooltipObject}
              ${percentHtml}
            </div>`
          }
        },
        grid: {
          y: {
            show: true
          }
        },
        axis: {
          x: {
            type: 'timeseries',
            tick: {
              count: this.count,
              format: '%d %b.'
            }
          },
          y: {
            tick: {
              values: this.axisTick,
              format: function (x) {
                let minutes = Math.floor(x / 60)
                let extraSeconds = Math.floor(x % 60)
                minutes = minutes !== 0 ? `${minutes}min ` : ''
                extraSeconds = extraSeconds !== 0 ? `${extraSeconds}s` : ''

                return `${minutes}${extraSeconds}`
              }
            }
          }
        }
      }
    }
  },
  watch: {
    columns () {
      this.handler.$emit('init', this.options)
      this.handler.$emit('dispatch', (chart) => chart.resize())
    }
  },
  mounted () {
    this.handler.$emit('init', this.options)
  },
  methods: {
    secondsToMinutes (seconds) {
      let minutes = Math.floor(seconds / 60)
      let extraSeconds = Math.floor(seconds % 60)
      minutes = minutes !== 0 ? `${minutes}min ` : ''
      extraSeconds = extraSeconds !== 0 ? `${extraSeconds}s` : ''

      return `${minutes}${extraSeconds}`
    },
    handleMouseOver (id) {
      this.handler.$emit('dispatch', (chart) => chart.focus(id))
    },
    handleMouseDown () {
      this.handler.$emit('dispatch', (chart) => chart.revert())
    },
    handleClick (id) {
      this.handler.$emit('dispatch', (chart) => chart.toggle(id))
    }
  }
}
</script>
