<template>
  <transition name="notification">
    <div
      data-cy="feedback-notification"
      v-if="type && type.length"
      :class="classes"
    >
      <c-icon
        v-if="icon || type"
        class="icon"
        :icon="icon || icons[type]"
        size="21"
      />
      <section class="content">
        <p class="title">
          {{ title }}
        </p>
        <p class="text">
          {{ text }}
        </p>
      </section>
      <c-button
        ghost
        size="sm"
        class="close"
        icon="cross"
        name="feedback-close"
        @click="$emit('close')"
      />
    </div>
  </transition>
</template>

<script>
const icons = {
  success: 'thumbs-up',
  error: 'warning',
  warning: 'check-circle',
  favorite: 'filled-star',
  like: 'filled-heart'
}

export default {
  props: {
    type: {
      validator: (value) =>
        ['', 'success', 'error', 'warning', 'favorite', 'like'].includes(value)
    },
    title: String,
    text: String,
    icon: String
  },
  data () {
    return {
      icons,
      timeout: null
    }
  },
  computed: {
    classes () {
      const classes = [
        'c-notification',
        {
          ['-' + this.type]: true
        }
      ]
      return classes
    }
  },
  mounted () {
    this.setCloseTimeout()
  },
  updated () {
    this.resetCloseTimeout()
  },
  methods: {
    setCloseTimeout () {
      this.timeout = setTimeout(() => {
        this.$emit('close')
      }, 5000)
    },
    resetCloseTimeout () {
      clearTimeout(this.timeout)
      setTimeout(() => {
        this.setCloseTimeout()
      }, 0)
    }
  }
}
</script>

<style lang='scss'>
@import '~@/styles/reference';

.c-notification {
  z-index: 100;
  position: fixed;
  left: 0;
  width: calc(100% - 20px);
  bottom: 0;
  height: 70px;
  margin: 10px;
  display: flex;
  align-items: center;
  flex-flow: row nowrap;
  border-radius: 3px;
  transform: translateY(0);
  transition: background-color 0.3s ease, transform 0.3s ease, opacity 0.3s ease;

  @include desktop {
    width: 450px;
  }

  & > .icon {
    margin: 20px;
    fill: white;
  }

  & > .content {
    margin-right: auto;

    & > .title,
    & > .text {
      color: white;
    }

    & > .title {
      font-weight: 600;
    }
  }

  & > .close {
    margin: 20px;
  }
  &.-success {
    background-color: $success-color;
  }
  &.-error {
    background-color: $failure-color;
  }
  &.-warning {
    background-color: $favorite-color;
  }
  &.-favorite {
    background-color: $favorite-color;
  }
  &.-like {
    background-color: $like-color;
  }
}

.notification-enter,
.notification-leave-to {
  opacity: 0;
  transform: translateY(100%);
}
</style>
