<template>
  <div class="step-one">
    <div class="step-one-title">
      <c-title
        class="title"
        icon="award-star"
      >
        Criação da Campanha
      </c-title>
    </div>
    <div class="step-form">
      <h3>
        Informações Gerais
      </h3>
      <instruction
        class="instruction"
        :step="1"
        text="Dê um nome para a sua campanha"
      />
      <div class="input">
        <c-input
          data-cy="campaign-title-input"
          v-model="title"
          name="title-input"
          required
          maxlength="62"
          v-validate="'required'"
          placeholder="Nome da campanha"
          @input="update('title', $event )"
        />
        <span class="subtext">Máx. 62 caracteres</span>
      </div>
      <instruction
        class="instruction"
        :step="2"
        text="Escreva uma descrição. Não se esqueça de citar informações importantes da sua campanha."
      />
      <div class="input">
        <c-input
          data-cy="campaign-description-input"
          v-model="description"
          class="description-input"
          name="description-input"
          required
          textarea
          rows="5"
          maxlength="118"
          v-validate="'required'"
          placeholder="Descrição da campanha"
          @input="update('description', $event )"
        />
        <span class="subtext">Máx. 118 caracteres</span>
      </div>
      <h3>
        Recompensa
      </h3>
      <instruction
        class="instruction"
        text="Defina a quantidade de moedas que os ganhadores receberão."
      />
      <div class="input">
        <c-input
          v-model="value"
          data-cy="campaign-value-input"
          class="value-input"
          name="value-input"
          data-vv-as="moedas"
          label="Moedas"
          type="number"
          required
          v-validate="{ required: true, min_value: 1 }"
          :feedback-show="errors.has('value-input')"
          :feedback-message="errors.first('value-input')"
          placeholder="Ex: 10"
          @input="update('value', $event )"
        />
      </div>
      <h3>
        Duração
      </h3>
      <instruction
        class="instruction"
        text="Defina a duração da sua campanha. Caso não preencha, a campanha será ativada no seu clube após a criação e poderá ser desativada manualmente aqui no Painel do Gestor. "
      />
      <div class="duration-inputs">
        <div class="input-div">
          <c-input
            data-cy="campaign-initial-date-input"
            class="input start-date-input"
            v-model="start_date"
            icon="calendar"
            ref="start_date"
            name="start_date"
            label="Início"
            mask="##/##/####"
            placeholder="DD/MM/AAAA"
            v-validate="{ date_format: 'dd/MM/yyyy', after: today }"
            validation-icon="warning-circle"
            :feedback-show="invalidInitialDateMessage.length > 0"
            :feedback-message="invalidInitialDateMessage"
            @input="update('start_date', $event )"
          />
          <span class="subtext">(Opcional)</span>
        </div>
        <div class="input-div">
          <c-input
            data-cy="campaign-end-date-input"
            class="input"
            v-model="end_date"
            icon="calendar"
            ref="end_date"
            name="end_date"
            label="Final"
            mask="##/##/####"
            placeholder="DD/MM/AAAA"
            v-validate="{ date_format: 'dd/MM/yyyy', after: today }"
            validation-icon="warning-circle"
            :feedback-show="!!invalidEndDateMessage"
            :feedback-message="invalidEndDateMessage"
            @input="update('end_date', $event )"
          />
          <span class="subtext">(Opcional)</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Instruction from '@/components/General/Instruction'
import moment from 'moment'

export default {
  components: {
    Instruction
  },
  props: {
    stepValue: Object
  },
  data () {
    return {
      title: '',
      description: '',
      value: '',
      start_date: '',
      end_date: ''
    }
  },

  computed: {
    today () {
      return moment().format('DD/MM/yyyy')
    },
    invalidInitialDateMessage () {
      const today = moment().subtract(1, 'days')
      const formated_start_date = moment(this.start_date, 'DD/MM/YYYY', true)
      const formated_end_date = moment(this.end_date, 'DD/MM/YYYY', true)
      const isOrderValid = !this.start_date || !this.end_date || formated_start_date.isSameOrBefore(formated_end_date)
      const isAfterToday = !this.start_date || formated_start_date.isAfter(today)
      const isEndDateFilled = this.end_date.length > 0 && this.start_date.length === 0

      if (!!this.start_date && !formated_start_date.isValid()) {
        return 'Data inválida'
      } else if (!isOrderValid) {
        return 'Data inicial deve ser anterior à data final'
      } else if (!isAfterToday) {
        return 'Data inicial deve ser posterior ou igual à data atual'
      } else if (isEndDateFilled) {
        return 'Data inicial é obrigatória quando existe uma data final'
      } else {
        return ''
      }
    },
    invalidEndDateMessage () {
      const today = moment()
      const formated_start_date = moment(this.start_date, 'DD/MM/YYYY', true)
      const formated_end_date = moment(this.end_date, 'DD/MM/YYYY', true)
      const isOrderValid = !this.start_date || !this.end_date || formated_end_date.isSameOrAfter(formated_start_date)
      const isAfterToday = !this.end_date || formated_end_date.isAfter(today)

      if (!!this.end_date && !formated_end_date.isValid()) {
        return 'Data inválida'
      } else if (!isOrderValid) {
        return 'Data final deve ser posterior à data inicial'
      } else if (!isAfterToday) {
        return 'Data final deve ser posterior à data atual'
      } else {
        return ''
      }
    }
  },
  created () {
    Object.keys(this.stepValue).forEach(key => {
      this[key] = this.stepValue[key]
    })
  },
  methods: {
    update (key, value) {
      this.$emit('input', { ...this.stepValue, [key]: value })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/styles/reference';

.step-one {
  max-width: 1020px;
  height: 100%;
  margin-top: 40px;
  padding: 0;

  > .step-one-title {
    display: flex;
    height: 35px;

    > .title {
      max-width: 240px;
      margin-bottom: 30px;
    }
  }

  .step-form {
    width: 100%;

    h3 {
      margin-top: 48px;
      margin-bottom: 16px;
      color: #565F7D;
    }

    .instruction {
      margin-bottom: 16px;
    }

    & > .title {
      padding-bottom: 24px;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #202950;
    }

    .input {
      max-width: 328px;
      gap: 8px;
      margin-bottom: 8px;

      .description-input {
        :deep textarea {
          resize: none;
        }
      }

      .value-input {
        width: 100px;
      }

      :deep .label {
        font-style: normal;
        font-weight: 400;
        font-size: 11px;
        line-height: 24px;
        color: #5E6684;

        .required {
          display: none;
        }
      }
      & > .subtext {
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        display: flex;
        align-items: center;
        color: #5E6684;
        padding-top: 6px;

        &:first-of-type {
          margin-bottom: 32px;
        }
      }
    }
    .duration-inputs {
      display: flex;

      .input-div {
        display: flex;
        align-items: flex-end;
        gap: 8px;

        &:first-of-type {
          margin-right: 40px;
        }
        .input {
          width: 250px;
        }
        .subtext {
          color: #5E6684;
          font-size: 14px;
          margin-bottom: 8px;
        }
      }
    }
  }
}
</style>

<style lang="scss">
.wrapper-c-input.value-input > .feedback.-error, .wrapper-c-input.start-date-input > .feedback.-error {
  min-width: 500px;
}
.wrapper-c-input.start-date-input > .feedback.-error {

}
</style>
