<template>
  <div class="user-content">
    <c-spinner
      v-if="isLoading"
      size="md"
    />

    <!-- user list -->
    <div
      v-else-if="users.length"
      class="user-list"
    >
      <c-bulk-select
        :config="buildBulkSelectConfig"
        :available-itens="users"
        :bulk-selection-array="bulkSelectionArray"
        :all-data-count="userCount.total_users"
        custom-all-data-text="usuários"
        @selectAllAvailableItens="bulkSelectionArray = $event"
        @selectAllData="bulkSelectionArray = ['allDataSelected']"
        @unselectAllItens="bulkSelectionArray = []"
        @openBulkUserStatusModal="bulkUserStatusModal = true"
        @openBulkUpdateUserProductsModal="userProductsModal = true"
        @openBulkDeleteModal="deleteUsersModal = true"
      >
        <c-bulk-select-item
          v-for="(bulkUser, index) in users"
          :key="index"
          :bulk-selection-is-active="bulkSelectionArray.length > 0"
          :selected="isSelectedBulkSelection(bulkUser.id)"
          @selectItem="buildbulkSelectionArray(bulkUser.id)"
        >
          <user-card
            class="card"
            :user="bulkUser"
            @click.native="$emit('open-details', { userId: bulkUser.id })"
            v-on="$listeners"
            v-bind="$attrs"
            data-cy="user-card"
          />
        </c-bulk-select-item>
      </c-bulk-select>
    </div>

    <!-- empty list -->
    <c-empty
      v-else-if="!users.length && search"
      class="empty"
      title="Ops!"
      character="searching"
      message="Não encontramos nenhum usuário relacionado à busca"
    />

    <!-- list and empty search -->
    <div
      v-else
      class="empty-list"
    >
      <c-character
        type="searching"
        class="character"
      />
      <p class="empty-text">
        <strong>Faça uma busca</strong> para encontrar usuários :)
      </p>
    </div>

    <!-- pagination -->
    <div class="user-pagination">
      <c-spinner
        v-if="paginating"
        size="md"
      />

      <c-button
        v-else-if="(users.length < pagination.total && !isLoading)"
        class="btn-see-more"
        @click="$emit('paginate')"
        size="lg"
      >
        Ver Mais
      </c-button>
    </div>
    <c-modal
      class="modals"
      name="Exclusão em massa"
      :is-opened="deleteUsersModal"
    >
      <bulk-delete-users
        @close="deleteUsersModal = false"
        @bulkDeleteUsers="$emit('bulkDeleteUsers', { users_id: bulkSelectionArray }, bulkSelectionArray = [] )"
      />
    </c-modal>
  </div>
</template>

<script>
import CBulkSelect from '@/components/CComponents/CBulkSelect'
import CBulkSelectItem from '@/components/CComponents/CBulkSelectItem'
import CEmpty from '@/components/CComponents/CEmpty'
import CCharacter from '@/components/CComponents/CCharacter'
import UserCard from '@/components/User/Listing/UserCard'
import CModal from '@/components/CComponents/CModal'
import BulkDeleteUsers from './bulkManageModals/BulkDeleteUsers'

export default {
  components: {
    CEmpty,
    CCharacter,
    UserCard,
    CBulkSelect,
    CBulkSelectItem,
    CModal,
    BulkDeleteUsers
  },

  props: {
    isLoading: Boolean,
    search: String,
    user: {
      type: Object,
      required: true
    },
    users: {
      type: Array,
      required: true
    },
    pagination: {
      type: Object,
      required: true
    },
    paginating: {
      type: Boolean,
      default: false
    },
    userCount: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      bulkUserStatusModal: false,
      userProductsModal: false,
      deleteUsersModal: false,
      bulkSelectionArray: []
    }
  },
  computed: {
    buildBulkSelectConfig () {
      const config = {
        buttonSelectAll: true,
        customButtons: [
          {
            onlyIcon: true,
            icon: 'trash',
            text: '',
            event: 'openBulkDeleteModal',
            disabled: false,
            customStyle: ''
          }
        ]
      }
      return config
    }
  },
  methods: {
    buildbulkSelectionArray (item) {
      if (this.bulkSelectionArray.includes('allDataSelected')) {
        const index = this.bulkSelectionArray.indexOf('allDataSelected')
        this.bulkSelectionArray.splice(index, 1)
      }
      if (this.bulkSelectionArray.includes(item)) {
        const index = this.bulkSelectionArray.indexOf(item)
        this.bulkSelectionArray.splice(index, 1)
      } else {
        this.bulkSelectionArray.push(item)
      }
    },
    isSelectedBulkSelection (item) {
      if (this.bulkSelectionArray.includes('allDataSelected')) return true
      if (this.bulkSelectionArray.includes(item)) return true
      return false
    }
  }
}
</script>

<style lang="scss">
  @import '~@/styles/reference';
  .user-content {
    @include container;

    padding: 0 5px;

    & >.user-controls {
      display: flex;
      justify-content: space-between;
      margin-top: 30px;

      @include mobile {
        flex-direction: column;
      }

      & >.list-heading {
        flex-grow: 1;
        width: 100%;
        max-width: 600px;

        .c-title {
          margin-bottom: 9px;

          .count-wrapper {
            font-size: 24px;

            .count.active {
              font-weight: bold;
              color: var(--color-primary);
            }
            .count {
              font-weight: 200;
            }
          }
        }
      }

      & >.header-actions {
        display: flex;
        align-content: center;
        order: 1;

        @include mobile {
          flex-direction: column;
        }

        & >.wrapper-search-field {
          margin-top: 10px;

          @include mobile {
            width: 100%;
          }
          .search-field {
            @include mobile {
              width: 100%;
            }
          }
          .wrapper-c-input {
            margin-bottom: 0;
          }
        }
        .c-button:not(:first-child) { margin-left: 10px; }
      }
    }

    & >.empty-list {
      background-color: #fff;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 60px 30px;
      margin-top: 20px;
      border-radius: 3px;

      & >.empty-text {
        font-size: 14px;
        margin-top: 20px;
      }
    }

    & > .user-pagination {
      margin-top: 30px;

      & > .btn-see-more {
        display: flex;
        margin: 0 auto;
      }
    }

    & >.user-list {
      position: relative;
      display: flex;
      flex-wrap: wrap;
      margin-left: -5px;
      margin-right: -5px;

      & >.card {
        min-height: 125px;
        cursor: pointer;

        @include hoverCard;
      }
    }
  }
  .modals .c-modal-mask{
    z-index: 10;
  }
  .modals .c-modal{
    z-index: 10;
  }
</style>
