<template>
  <img
    class="c-character"
    :src="image"
    :alt="description"
    :title="description"
    :style="{ 'background-image': `url(${image})` }"
  />
</template>

<script>
const isType = (value) => {
  const types = [ 'sad', 'embarrassed', 'happy', 'greedy', 'disappointed', 'searching' ]
  const isValid = types.includes(value)
  return isValid
}

export default {
  props: {
    description: String,
    type: {
      type: String,
      validator: isType
    }
  },
  data () {
    return {
      image: ''
    }
  },
  watch: {
    type () {
      this.loadImage()
    }
  },
  methods: {
    async loadImage () {
      this.image = require(`@/assets/img/character-${this.type}.svg`)
    }
  },
  mounted () {
    this.loadImage()
  }
}
</script>

<style lang="scss">
@import '~@/styles/reference';

$c-character-size: 130px;

.c-character {
  @include size($c-character-size);
  box-sizing: border-box;
  display: block;
  padding: calc($c-character-size / 2);
  background: {
    size: contain;
    repeat: no-repeat;
    position: center;
  }
}
</style>
