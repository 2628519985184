<template>
  <div
    class="navbar-preview-desktop"
  >
    <div class="logo-container">
      <img
        v-if="logo"
        :src="logo"
        alt="logo"
        class="logo"
      />
      <c-logo
        v-else
        class="logo"
        kaledo
      />
    </div>
    <div class="fake-search">
      <k-icon
        icon="search1"
        size="20px"
        class="icon"
      />
      <span class="placeholder">
        Qual oferta está procurando hoje
      </span>
    </div>
    <div class="navbar-actions">
      <c-button
        class="button profile preview-button"
        icon="star-favorite"
        use-k-icon
        size="lg"
      >
        Recompensas
      </c-button>
      <c-button
        class="button profile preview-button"
        icon="user"
        size="lg"
      />
      <c-button
        class="button profile preview-button"
        icon="bell-notification"
        size="lg"
        use-k-icon
      />
    </div>
  </div>
</template>

<script>
import CLogo from '@/components/CComponents/CLogo.vue'
import { KIcon } from 'kaledo-components'

export default {
  name: 'NavbarPreviewDesktop',
  components: { CLogo, KIcon },
  props: {
    logo: {
      type: String,
      default: null
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/styles/reference';
.navbar-preview-desktop {
    width: 100%;
    height: 70px;
    background: #FDFDFD;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 18px 80px;

    @include mobile {
      width: 1022px;
      overflow: scroll;
    }
    .fake-search {
      max-width: 350px;
      width: 100%;
      min-width: 350px;
      height: 40px;
      border-radius: 24px;
      background: #FDFDFD;
      border: 1px solid #BEC0D3;
      display: flex;
      align-items: center;
      padding: 10px 16px;
      .icon {
        color: #5E6684;
        margin-right: 8px;
      }
      .placeholder {
        font-size: 14px;
        color: #5E6684;
      }
    }
    .awards {
      width: 158px;
      height: 40px;
      border-radius: 24px;
    }
  }
  .logo {
    max-width: 150px;
    height: 33px;
    object-fit: cover;
  }
  .preview-button {
    cursor: not-allowed;
    transition: all ease 0.3s;
    border-color: #BEC0D3;
    color: rgba(18, 30, 72, 0.5);
    &:hover {
      border-color: #BEC0D3;
      box-shadow: none;
    }
  }
  .preview-button + .preview-button {
    margin-left: 16px;
  }
</style>
