<template>
  <div>
    <component-header @return="$router.push('/personalizacao')"/>
    <inside-enviroments />
  </div>
</template>

<script>
import InsideEnviroments from '@/containers/Customization/InsideEnviroments.vue'
import ComponentHeader from '@/components/General/ComponentHeader'
export default {
  name: 'InsideEnvironments',
  components: {
    InsideEnviroments,
    ComponentHeader
  }
}
</script>

<style lang="scss" scoped>
@import '~@/styles/reference';
</style>
