<template>
  <div class="unified-container">
    <div class="header">
      <c-title
        icon="files"
        icon-size="18"
      >
        Cadastro Unificado
      </c-title>
    </div>

    <div class="toggle">
      <p class="text">
        Deseja que esse cadastro seja realizado automaticamente em outros Clubes ligados à sua conta?
      </p>

      <c-toggle
        v-if="!uniqueCode"
        :class="[{'-align-left': !isDesktop}]"
        :labels="{ checked: 'Sim', unchecked: 'Não' }"
        :disabled="uniqueCode"
        :value="multipleRegister"
        @input="ev => multipleRegister = ev"
      />
    </div>

    <span
      v-if="uniqueCode"
      class="unique-code-warning"
    >
      O resgate de código único impossibilita o cadastro em múltiplos clubes.
      Altere o tipo de resgate para ativar o cadastro unificado.
    </span>

    <div
      v-if="multipleRegister && !uniqueCode"
      class="table-container"
    >
      <p class="text-before">
        Selecione os Clubes que receberão essa ação:
      </p>

      <div class="accounts-table">
        <vue-good-table
          :columns="cols"
          :rows="rows"
          :row-style-class="rowStyleClassFn"
          :select-options="{
            enabled: true,
            disableSelectInfo: true,
          }"
          @on-selected-rows-change="selectionChanged($event)"
        >
          <template
            slot="table-column"
            slot-scope="props"
          >
            <div class="column-header">
              <span
                v-if="props.column.label =='Nome'"
                class="text"
              >
                {{ props.column.label }}
              </span>
            </div>
          </template>
        </vue-good-table>
      </div>
    </div>
  </div>
</template>

<script>
import { VueGoodTable } from 'vue-good-table'

export default {
  components: {
    VueGoodTable
  },

  props: {
    companies: {
      type: Array,
      required: true
    },
    currentCompany: {
      type: String,
      required: true
    },
    uniqueCode: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      multipleRegister: true,
      cols: [
        {
          label: 'Nome',
          field: 'name',
          sortable: false
        }
      ],
      selectedCompanies: []
    }
  },

  computed: {
    rowStyleClassFn () {
      return '-rows'
    },

    rows () {
      return this.companies.filter(company => company.id !== this.currentCompany)
        .map(company => {
          return {
            'name': company.name,
            'id': company.id
          }
        })
    }
  },

  watch: {
    multipleRegister () {
      if (this.multipleRegister) return

      this.$emit('selected-companies', [])
    }
  },

  methods: {
    selectionChanged (ev) {
      if (this.uniqueCode) {
        return this.selectedCompanies = []
      }

      this.selectedCompanies = ev.selectedRows.map(selected => selected.id)

      this.$emit('selected-companies', this.selectedCompanies)
    }
  }
}
</script>

<style lang="scss">
@import '~@/styles/reference';

.unified-container {
  border-radius: 3px;
  background-color: #fff;
  box-shadow: 1px 1px 4px 0 rgba(0,0,0,0.1);
  margin-bottom: 25px;
  padding: 20px;

  & > .header {
    padding-bottom: 20px;
  }

  & > .toggle {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px 15px 0;

    & > .text {
      color: rgba(18, 30, 72, 0.8);
    }

    & > .-align-left {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      padding-top: 5px;
    }
  }

  & > .unique-code-warning {
    font-size: 12px;
    font-weight: bold;
    color: #FF5967;
  }

  & > .table-container {
    & > .text-before {
      padding: 17px 0;
      color: rgba(18, 30, 72, 0.8);
      border-top: 1px solid rgba(18, 30, 72, 0.05);
    }

    & > .accounts-table {
      flex: 0 100%;

      tr {
        height: 50px;

        &:first-child {
          background-color: rgba(18, 30, 72, 0.03);
        }
      }

      th {
        width: 60px;
      }

      .column-header {
        text-align: left;
      }

      .text {
        text-transform: uppercase;
        color: #121E48;
        font-weight: 700;
        align-self: flex-start;
      }

      .-rows {
        position: relative;
        padding: 0 18px;
        border-top: 1px solid #eaedef;
        line-height: 1.44;
        color: rgba(18, 30, 72, 0.5);
        font-size: 14px;

        &:first-child {
          background-color: transparent;
        }
      }
    }
  }
}

</style>
