<template>
  <c-modal
    class="modal"
    :is-opened="showModal"
    :is-fullscreen="false"
    name="name-modal"
    hide-cancel
    @close="$emit('close')"
  >
    <c-title
      data-cy="campaign-details-modal-title"
      icon="award-star"
      class="title"
    >
      Detalhes da campanha
    </c-title>
    <div class="desc-container">
      <campaign-details-card
        :title="campaign.title || ''"
        :reason="campaign.reason || ''"
        :description="campaign.description || ''"
      />
    </div>
    <div class="midias-title">
      Mídias de Comunicação
    </div>
    <div class="midias">
      <div class="email">
        <div class="title">
          Email
        </div>
        <email-example :config="campaign.config.email" />
      </div>
      <div class="popup">
        <div class="title">
          Pop up
        </div>
        <popup-example />
        <div class="notifications">
          <div class="title">
            Notificação
          </div>
          <notification-example
            :image="campaign.config.notification.image || ''"
            :title="campaign.config.notification.title || ''"
            :description="campaign.config.notification.description || ''"
          />
        </div>
      </div>
    </div>
  </c-modal>
</template>

<script>
import CModal from '@/components/CComponents/CModal'
import CampaignDetailsCard from './CampaignDetailsCard'
import EmailExample from './EmailExample'
import PopupExample from './PopupExample'
import NotificationExample from './NotificationExample'

export default {
  components: {
    CModal,
    CampaignDetailsCard,
    EmailExample,
    PopupExample,
    NotificationExample
  },
  props: {
    showModal: {
      type: Boolean,
      required: true
    },
    campaign: {
      type: Object,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
 .modal :deep .name-modal {
  margin: 0 auto;
  height: 100%;
  width: 99%;
  max-height: 706px !important;
  max-width: 1362px !important;
  top: 10%;
  left: 0;
  right: 0;

  /* Estilização do scroll */
  &::-webkit-scrollbar {
    width: 10px
  }

  &::-webkit-scrollbar-track {
    background: #BEC0D3;
    border-radius: 16px;
  }

  &::-webkit-scrollbar-thumb {
    background: #5E6684;
    border-radius: 16px;
  }
}
.midias-title {
  font-style: normal;
  font-weight: 600;
  font-size: 22px;
  line-height: 26px;
  color: #202950;
  padding-top: 40px;
}
  .midias {
    display: flex;
    padding-top: 24px;
    margin-bottom: 24px;
    & > .email {
      width: 100%;
      max-width: 600px;
      & > .title {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        color: #202950;
        padding-bottom: 16px;
      }
    }
    & > .popup {
      margin-left: 32px;

      & > .title {
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        color: #202950;
      }
      & > .notifications {
        & > .title {
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 20px;
          color: #202950;
        }
      }
    }
  }
 .desc-container {
    display: flex;
    justify-content: space-between;
    padding-top: 40px;

    .campaigns {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      padding: 16px;
      gap: 24px;
      isolation: isolate;
      width: 100%;
      max-width: 617px;
      margin-right: auto;
      height: 113px;
      margin-left: 32px;
      background: #FDFDFD;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.16);
      border-radius: 8px;

      & > .campaign-orders {
       display: flex;
       width: 100%;
       justify-content: space-between;

       .info {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 4px 8px;
        gap: 8px;
        background: #FDFDFD;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.16);
        border-radius: 4px;
        font-style: normal;
        font-weight: 400;
        font-size: 11px;
        line-height: 14px;
        display: flex;
        align-items: center;
        letter-spacing: 0.5px;
        text-transform: uppercase;
        color: #202950;
       }

       & > .qtd {
        height: 26px;
       }
       & > .total {
        height: 26px;
        left: 486px;
        top: 71px;
       }
      }
    }
  }

</style>
