<template>
  <div class="banner-preview">
    <div
      v-if="!img"
      class="banner-container"
    >
      <div class="no-image">
        <div class="icon-container">
          <c-icon
            size="30"
            icon="store"
          />
        </div>
        <div class="text-container">
          <h4 class="title">
            Home
          </h4>
          <p class="text">
            Banner em destaque
          </p>
          <p class="text">
            (tamanho 600x230 px)
          </p>
        </div>
      </div>
    </div>
    <div
      v-else
      class="image"
    >
      <img
        :src="img"
        alt="Preview do banner"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'BannerPreview',
  props: {
    img: [String, File, Array]
  }
}
</script>

<style lang='scss' scoped>
@import '~@/styles/reference';

.banner-preview {
  margin-bottom: 30px;

  > .banner-container, > .image {
    box-shadow: 1px 1px 4px 0 rgba(0,0,0,0.1);
    max-width: 500px;
    max-height: 100px;
  }

  > .banner-container {
    background: white;
    padding: 20px 18px;

    > .no-image {
      display: flex;
      align-items: center;

      > .icon-container {
        border: 1px solid $border-color;
        padding: 13px 15px;
        width: 61px;
        height: 61px;
        border-radius: 50%;
        margin-right: 18px;
      }

      > .text-container {
        > .title {
          color: $text-color;
          font-size: 16px;
          margin-bottom: 10px;
        }
        > .text {
          font-size: 13px;
          margin-bottom: 0;
        }
      }
    }
  }

  > .image {
    > img {
      display: block;
      width: 100%;
      height: 100px;
      object-fit: cover;
    }
  }
}
</style>
