<template>
  <div class="campaign-container">
    <campaign-header />
    <campaign-list />
  </div>
</template>

<script>
import CampaignHeader from '@/containers/Campaign/Listing/CampaignHeader'
import CampaignList from '@/containers/Campaign/Listing/CampaignList'

export default {
  components: { CampaignHeader, CampaignList }
}
</script>
