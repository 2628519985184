<template>
  <div class="step-four">
    <div class="title-header">
      <c-title
        data-cy="step-title"
        class="title"
        size="3"
      >
        Conclusão
      </c-title>
    </div>
    <div class="content">
      <main>
        <rewarded-users
          title="Usuários recompensados"
          :table-cols="cols"
          :file-name="title"
          :total="total"
          :users="users"
          :has-date="false"
        />
      </main>
      <aside>
        <conclude-order-card
          data-cy="conclude-order-card"
          enable-wallet
          :total="total"
          :loading="loading"
          :wallet-balance="company.balance"
          @use-balance="update('use_balance', $event)"
          @changed-date="update('release_date', $event)"
          @create-order="$emit('create-order')"
          @send-invoice="$emit('send-invoice')"
          @show-invoice-modal="showInvoiceModal = true"
        />
        <span
          data-cy="cancel-order-action"
          class="cancel"
          @click="$emit('cancel')"
        >Cancelar Pedido</span>
      </aside>
    </div>
    <invoice-info-modal
      v-bind="$attrs"
      :is-opened="showInvoiceModal"
      @close="showInvoiceModal = false"
    />
  </div>
</template>

<script>
import RewardedUsers from '@/components/General/RewardedUsers'
import ConcludeOrderCard from '@/components/General/cards/ConcludeOrderCard'
import InvoiceInfoModal from '@/components/General/modals/InvoiceInfoModal'
import { mapGetters } from 'vuex'
import { COMPANY } from '@/store/types'

export default {
  components: {
    ConcludeOrderCard,
    RewardedUsers,
    InvoiceInfoModal
  },
  props: {
    users: {
      type: Array,
      default: () => []
    },
    total: {
      type: Number,
      default: 0
    },
    title: {
      type: String,
      default: ''
    },
    stepValue: Object,
    loading: Boolean
  },
  data () {
    return {
      showInvoiceModal: false,
      cols: ['email', 'cpf', 'moedas']
    }
  },
  computed: {
    ...mapGetters({
      company: COMPANY
    }),
  },
  methods: {
    update (key, value) {
      this.$emit('input', { ...this.stepValue, [key]: value })
    },
    handleLinkClick () {
      this.openAwardOrderModalInfo = false
    }
  }
}
</script>

<style lang="scss">
@import '~@/styles/reference';

.step-four {
  max-width: 1020px;
  margin-top: 35px;
  padding: 0;
  display: flex;
  flex-direction: column;

  > .title-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    > .title {
      max-width: 200px;
    }
  }
  .content {
    display: flex;
    justify-content: space-between;

    @include tablet { flex-direction: column; }

    @include mobile { flex-direction: column; }

    main {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      width: 100%;
      margin-right: 50px;
      margin-bottom: 20px;

      .awarded-users {
        width: 100%;
        margin-bottom: 20px;
      }
    }
    aside {
      display: flex;
      flex-direction: column;
      width: 100%;
      min-width: 300px;

      > :not(:first-child) {
        margin-top: 20px;
      }
      .cancel {
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 14px;
        text-decoration-line: underline;
        color: #FF5967;
        &:hover {
          cursor: pointer;
        }
      }
    }
  }
}
</style>
