<template>
  <donut-card
    :loading="loading"
    :error="error"
    :graph-data="usersPerPlatformData"
  />
</template>

<script>
import DonutCard from '@/components/Dashboard/base/GraphCard.vue'
import { mapGetters, mapActions } from 'vuex'
import { DASH_USERS_PER_PLATFORM } from '@/store/types'

export default {
  name: 'UsersPerPlatform',
  components: {
    DonutCard
  },
  props: {
    reloadDataQuery: Boolean
  },
  data () {
    return {
      error: false,
      loading: true
    }
  },
  computed: {
    ...mapGetters({
      usersPerPlatformData: DASH_USERS_PER_PLATFORM
    })
  },
  watch: {
    reloadDataQuery () {
      if (this.reloadDataQuery) { this.getUsersPerPlatform() }
    }
  },
  mounted () {
    this.getUsersPerPlatform()
  },
  methods: {
    ...mapActions({
      getUsersPerPlatformData: DASH_USERS_PER_PLATFORM
    }),
    getUsersPerPlatform () {
      this.loading = true
      this.getUsersPerPlatformData().then((success) => {
        this.error = !success
        this.loading = false
      })
    }
  }
}
</script>
