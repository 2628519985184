<template>
  <div class="container">
    <step-count
      :current-step="1"
      :steps="steps"
    />
    <div class="title-header">
      <c-title
        class="title"
      >
        Adição de saldo
      </c-title>
    </div>
    <p>Adicione um valor na sua carteira para ser utilizado no pagamento de pedidos de prêmio e pontos </p>
    <div class="step">
      <instruction
        :step="1"
        text="Insira o valor"
      />
      <k-input
        class="price"
        :class="priceInputFocus ? '-focus' : ''"
        type="money"
        @click.native="priceInputFocus = true"
        v-model="value"
      />
    </div>
    <div class="step">
      <instruction
        :step="2"
        text="Escreva uma descrição (Opcional)"
      />
      <c-input
        label="descrição"
        class="text-input"
        textarea
        placeholder="Escreva uma descrição"
        v-model="description"
      />
    </div>
    <div class="step">
      <instruction
        :step="3"
        text="Método de pagamento"
      />
      <div
        data-cy="payment-type-card"
        class="payment-type"
      >
        <div class="type">
          <c-checkbox
            disabled
            round
            :value="true"
          />
          <k-icon
            icon="scaner-barcode-select"
            size="20"
            class="icon"
          />Boleto bancário
        </div>
        <div class="description">
          O boleto será gerado após a conclusão do pedido
          <k-icon
            data-cy="payment-type-info-button"
            class="icon"
            icon="question"
            @click.native="$emit('show-invoice-modal')"
          />
        </div>
      </div>
    </div>
    <div
      class="footer"
    >
      <c-button
        data-cy="cancel-order-button"
        class="button cancel-button"
        @click="openCancelModal = true"
      >
        Cancelar
      </c-button>
      <c-button
        data-cy="next-step-button"
        primary
        class="button"
        :disabled="disableOrderButton"
        @click="openReviewModal = true"
      >
        Adicionar saldo
      </c-button>
    </div>
    <c-confirm-modal
      :btn-props="{ primary: true }"
      confirm-text="Cancelar pedido"
      cancel-text="voltar"
      character="embarrassed"
      title="Tem certeza que deseja cancelar seu pedido?"
      message="Esse pedido de saldo não será processado"
      :is-opened="openCancelModal"
      @close="openCancelModal = false"
      @confirm="confirmCancel"
    />
    <review-modal
      :is-opened="openReviewModal"
      :value="value"
      :description="description"
      @close="openReviewModal = false"
      @confirm="checkoutOrder(), openCancelModal = false"
      :is-loading="isLoading"
    />
  </div>
</template>

<script>
import StepCount from '@/components/General/StepCount'
import CConfirmModal from '@/components/CComponents/CConfirmModal'
import Instruction from '@/components/General/Instruction.vue'
import { KIcon, KInput } from 'kaledo-components'
import CCheckbox from '@/components/CComponents/CCheckbox.vue'
import ReviewModal from '@/components/User/Wallet/ReviewModal.vue'
import { mapGetters, mapActions } from 'vuex'
import { COMPANY, USER_BUY_NEW_BALANCE } from '@/store/types'
export default {
  components: {
    CConfirmModal,
    StepCount,
    Instruction,
    KIcon,
    KInput,
    CCheckbox,
    ReviewModal
  },
  props: {
    loading: Boolean,
    award: {
      type: Object,
      default () { return {} }
    }
  },
  data () {
    return {
      value: 0,
      description: '',
      openCancelModal: false,
      priceInputFocus: false,
      openReviewModal: false,
      orderId: null,
      isLoading: false,
      steps: [
        {
          number: 1,
          text: 'Adição de Saldo'
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      company: COMPANY
    }),
    disableOrderButton () {
      return this.value < 1 || this.description.length === 0
    }
  },
  methods: {
    ...mapActions({
      checkoutUserOrder: USER_BUY_NEW_BALANCE
    }),

    handleLinkClick () {
      this.openAwardOrderModalInfo = false
    },
    handlePriceFocus () {
      this.priceInputFocus = false
    },

    goToWallet () {
      window.dataLayer.push({
        event: 'carteira-adicao-finalizar',
        company_name: this.company.slug
      })
      this.$router.push({ path: '/carteira' })
    },

    confirmCancel () {
      window.dataLayer.push({
        event: 'carteira-adicao-abandonar',
        company_name: this.company.slug
      })
      this.$router.push({ path: '/carteira' })
    },

    convertToInteger (value) {
      if (Number.isInteger(value)) {
        return value * 100
      } else if (value.toFixed(2).split('.')[1].length <= 2) {
        return Math.round(value * 100)
      }
    },

    async checkoutOrder () {
      this.isLoading = true
      const value = this.convertToInteger(this.value)
      const response = await this.checkoutUserOrder({
        value,
        description: this.description
      })
      if (response.success) this.goToWallet()
      else this.openReviewModal = false
      this.isLoading = false
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/styles/reference';

.container {
  max-width: 1020px;
  margin-top: 35px;
  padding: 0 10px;
  display: flex;
  flex-direction: column;

  @include desktop { @include container; }

  & > .title-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    margin-top: 48px;

    & > .title {
      max-width: 200px;

    }
  }
  & > p {
    color: var(--gray-600, #5E6684);
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Nunito Sans;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 24px;
    margin-bottom: 48px;
  }

   & >.footer {
    width: 100%;
    justify-content: flex-end;
    display: flex;
    padding-top: 25px;

    & >.button {
      margin: 0 5px;
      width: 180px;
      height: 40px;
      border-radius: 30px;

      &.cancel-button {
        background: #FFFFFF;
        border: 1px solid var(--color-secondary);
      }
    }
  }

  & > .step {
    &:not(:nth-last-child(2)) {
      margin-bottom: 48px;
    }

    :deep .k-input {
      margin-top: 24px;
      width: 200px;
      padding-top: 2px;
      border-radius: 4px;
      height: 40px;
      box-shadow: none;
      border: 1px solid #D8D9DE;
    }

    &.-focus {
      :deep .k-input {
        border: 2px solid #202950;
      }
    }
    & > .input {
      margin-top: 24px;
      margin-bottom: 24px;
      width: 200px;
    }

    & > .text-input {
      margin-top: 24px;
      margin-bottom: 0px;
      width: 346px;
      :deep .c-input input {
        height: 67px;
      }
    }

    & > .payment-type {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          padding: 8px;
          gap: 8px;
          width: 346px;
          height: 72px;
          background: #FFFFFF;
          border: 1px solid #BEC0D3;
          border-radius: 4px;
          margin: 16px 0;
          margin-bottom: 20px;

          > .type {
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            line-height: 16px;
            text-transform: uppercase;
            color: #202950;
            display: flex;
            align-items: center;
            .icon {
              padding: 0 8px;
            }
          }
          > .description {
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 14px;
            color: #5E6684;
            display: flex;
            justify-content: flex-start;
            align-items: center;

            .icon {
              font-size: 20px;
              margin-left: 5px;
              cursor: pointer;
              transition: all .1s ease;

              &:hover {
                font-weight: 700;
              }
            }
          }
        }
  }
}
</style>
