<template>
  <div class="visualizations">
    <h2 class="title">
      Pré-visualização
    </h2>
    <div class="options">
      <div class="check">
        <c-checkbox
          round
          class="checkbox"
          :value="isDesktopPreview"
          @input="isDesktopPreview = true, $emit('input', true)"
        />  Desktop
      </div>
      <div class="check">
        <c-checkbox
          round
          class="checkbox"
          :value="!isDesktopPreview"
          @input="isDesktopPreview = false, $emit('input', false)"
        />  Mobile
      </div>
    </div>
  </div>
</template>

<script>
import CCheckbox from '@/components/CComponents/CCheckbox.vue'

export default {
  name: 'Visualizations',
  components: {
    CCheckbox
  },
  props: {
    mode: {
      type: String,
      default: 'desktop'
    }
  },
  data () {
    return {
      isDesktopPreview: true
    }
  },
  watch: {
    mode (val) {
      this.isDesktopPreview = (val === 'desktop')
    }
  },
  mounted () {
    this.isDesktopPreview = (this.mode === 'desktop')
  }
}
</script>

<style lang="scss" scoped>
@import '~@/styles/reference';
.visualizations {
  display: flex;
  align-items: self-end;
  margin-bottom: 24px;
  .title {
    align-items: center;
    display: flex;
    color: $neutral-dark-blue;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Nunito Sans;
    font-size: 16px;
    font-weight: 700;
  }
  & > .options{
    margin-top: 16px;
    display: flex;
    width: 122px;
    justify-content: space-between;

    & > .check {
      display: flex;
      color: $neutral-gray-200;
      font-feature-settings: 'clig' off, 'liga' off;
      font-family: Nunito Sans;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%;
      align-items: center;
        margin-left: 40px;
      & > .checkbox {
        margin-right: 8px;
        margin-bottom: 0;
      }
    }
  }
}
</style>
