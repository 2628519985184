<template>
  <div class="horizontal-bar">
    <div class="legends">
      <div
        v-for="(label, index) in labels"
        :key="`${label}-${index}`"
        class="graphic-legend"
      >
        <div :class="`color -color-${index}`" />
        <span class="text">{{ label }}</span>
      </div>
    </div>
    <vue-c3 :handler="handler" />
  </div>
</template>

<script>
import Vue from 'vue'
import VueC3 from 'vue-c3'

export default {
  name: 'HorizontalBar',
  components: {
    VueC3
  },
  props: {
    graphData: Object,
    categories: Array,
    columns: Array,
    title: String
  },
  data () {
    return {
      handler: new Vue()
    }
  },
  computed: {
    labels () {
      const label = this.formatedGroupsColumns.filter(column => column[0] !== 'x').map(column => column[0])
      return label
    },
    groups () {
      const label = this.formatedGroupsColumns.map(column => column[0])
      return label
    },
    formatedGroupsColumns () {
      return [this.categories, this.columns[0], this.columns[1].map((element, index) => {
        return typeof element !== 'string' ? this.columns[1][index] - this.columns[0][index] : element
      })]
    },
    axisTick () {
      const splittedBiggestArray = this.columns[1].slice(1)
      const highestArrayValue = Math.max(...splittedBiggestArray)
      const maxValue = Math.ceil(highestArrayValue / 50) * 50
      const ticks = []
      for (let i = 0; i <= maxValue; i += 50) {
        ticks.push(i)
      }
      return ticks
    },
    options () {
      return {
        data: {
          x: 'x',
          columns: this.formatedGroupsColumns,
          type: 'bar',
          order: null,
          groups: [
            this.groups
          ],
          classes: {
            Ativas: 'active-bars',
            Disponíveis: 'available-bars',
            title: this.title
          }
        },
        size: {
          width: 676
        },
        legend: {
          hide: true
        },
        bar: {
          zerobased: true,
          width: 8
        },
        grid: {
          y: {
            show: true
          }
        },
        axis: {
          rotated: true,
          x: {
            type: 'category'
          },
          y: {
            tick: {
              values: this.axisTick
            }
          }
        },
        tooltip: {
          horizontal: false,
          contents: function (d) {
            const meta = this.config.data_classes
            return `<div class="custom-tooltip">
            <h5 class="tooltip-title">${meta.title}</>
            <h6 class="tooltip-label">${d[0].name}</h6>
            <p class="tooltip-description">${d[0].value}</p>
            <h6 class="tooltip-label">${d[1].name}</h6>
            <p class="tooltip-description">${d[1].value + d[0].value}</p>
            </div>`
          }
        },
        color: {
          pattern: ['#202950', '#D8E0F6', '#202950', '#ff7f0e', '#ffbb78']
        }
      }
    }
  },
  watch: {
    columns () {
      this.handler.$emit('init', this.options)
      this.addEvent()
    }
  },
  mounted () {
    this.handler.$emit('init', this.options)
    this.addEvent()
  },
  beforeDestroy () {
    this.removeEvent()
  },
  methods: {
    addEvent () {
      const activeBars = document.querySelectorAll('.c3-bars-Ativas > .c3-shape')
      const availableBars = document.querySelectorAll('.c3-bars-Disponíveis > .c3-shape')
      const tooltip = document.querySelectorAll('.horizontal-bar > .vuec3-chart > .c3-tooltip-container')[0]
      const bars = [activeBars, availableBars]

      function setBarStyle (index, activeColor, availableColor) {
        activeBars.forEach((targetElement, targetIndex) => {
          if (index !== targetIndex) {
            targetElement.style.fill = targetElement.style.stroke = activeColor
          }
        })
        availableBars.forEach((targetElement, targetIndex) => {
          if (index !== targetIndex) {
            targetElement.style.fill = availableColor
          }
        })

        return true
      }

      bars.forEach(barsGroup => {
        barsGroup.forEach((element, index) => {
          element.addEventListener('mouseover', (event) => {
            tooltip.style.display = 'block'
            setBarStyle(index, '#D8E0F6', '#D8D8D8')
          })
          element.addEventListener('mouseout', (event) => {
            tooltip.style.display = 'none'
            setBarStyle(index, '#202950', '#D8E0F6')
          })
        })
      })
    },
    removeEvent () {
      const activeBars = document.querySelectorAll('.c3-bars-Ativas > .c3-shape')
      const availableBars = document.querySelectorAll('.c3-bars-Disponíveis > .c3-shape')
      const tooltip = document.querySelectorAll('.horizontal-bar > .vuec3-chart > .c3-tooltip-container')[0]
      const bars = [activeBars, availableBars]

      function setBarStyle (index, activeColor, availableColor) {
        activeBars.forEach((targetElement, targetIndex) => {
          if (index !== targetIndex) {
            targetElement.style.fill = targetElement.style.stroke = activeColor
          }
        })
        availableBars.forEach((targetElement, targetIndex) => {
          if (index !== targetIndex) {
            targetElement.style.fill = availableColor
          }
        })

        return true
      }

      bars.forEach(barsGroup => {
        barsGroup.forEach((element, index) => {
          element.removeEventListener('mouseover', (event) => {
            tooltip.style.display = 'block'
            setBarStyle(index, '#D8E0F6', '#D8D8D8')
          })
          element.removeEventListener('mouseout', (event) => {
            tooltip.style.display = 'none'
            setBarStyle(index, '#202950', '#D8E0F6')
          })
        })
      })
    }
  }
}
</script>

<style lang='scss'>
@import '~@/styles/reference.scss';
$active-color: #202950;
$available-color: #D8E0F6;
$available-hover-color: #D8D8D8;

.horizontal-bar {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 13px;

  .c3 svg {
    width: 100%;
    transform: translate(-10px,0);
  }
  .c3-ygrids {
    line:not([stroke-width="10"]) {
      stroke: rgba(190, 192, 211, 1) !important;
      stroke-dasharray: 0;
    }
  }
  .c3 path.domain {
    display: none !important;
  }
  .c3-axis-y text,  .c3-axis-x text{
    fill: rgba(94, 102, 132, 1);
    font-size: 14px;
    font-family: $base-font-family;
    text-anchor: start !important;
  }
  c3-axis-x .tick line, .c3-axis-x .tick text {
    transform: translate(-80px,0);
    word-spacing: 1px;
  }
  c3-axis-y .tick line, .c3-axis-y .tick text{
    transform: translate(-10px,0);
  }
  .c3-axis.c3-axis-y > g:nth-child(2) > text{
    transform: translate(-5px,0);
  }
  .c3 line {
    stroke: none;
  }
  .c3-target {
    pointer-events: auto !important;
  }
  .c3-shape {
    transition: 0.3s;
  }

  .legends {
    width: 100%;
    padding: 10px 50px;
    display: flex;
    justify-content: flex-end;
    .graphic-legend {
      display: flex;
      align-items: center;
      margin-bottom: 3px;
      .text {
        font-size: 12px;
        color: $text-color;
      }
      .color {
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background: #D8E0F6;
        margin-right: 5px;
        &.-color-0 {
          background: #202950;
        }
        &.-color-1 {
          background: #D8E0F6;
        }
      }
    }
    .graphic-legend {
      margin-left: 50px;
    }
  }

  .c3-tooltip-container {
    transition: 0.3s;
    background: white;
    box-shadow: rgba(0,0,0,0.1);
    border-radius: 3px;
  }
  .custom-tooltip {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 170px;
    min-height: 110px;
    font-size: 11px;
    padding: 10px;
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.16);
    border-radius: 4px;
    .tooltip-title {
      color: #202950;
      font-weight: 400;
      font-size: 13px;
      margin-bottom: 10px;
      text-transform: uppercase;
    }
    .tooltip-label {
      color: $text-color;
      margin-bottom: 2px;
      font-size: 11px;
      font-weight: 400;
    }
    .tooltip-description {
      color: $title-color;
      font-size: 13px;
      font-weight: 600;
    }
    .tooltip-description:nth-of-type(1) {
      margin-bottom: 5px;
    }
    .tooltip-comparison {
      &.-error {
        color: $like-color;
      }
      &.-success {
        color: $success-color;
      }
    }
  }
}

</style>
