import { render, staticRenderFns } from "./ConcludeOrderCard.vue?vue&type=template&id=a752bcda&scoped=true"
import script from "./ConcludeOrderCard.vue?vue&type=script&lang=js"
export * from "./ConcludeOrderCard.vue?vue&type=script&lang=js"
import style0 from "./ConcludeOrderCard.vue?vue&type=style&index=0&id=a752bcda&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a752bcda",
  null
  
)

export default component.exports