<template>
  <c-table-container
    class="listing-table"
    :class="isRowsEmpty ? '-inactive' : ''"
    v-bind="$attrs"
    v-on="$listeners"
    :paginate="paginate"
  >
    <data-table
      :sort="false"
      :cols="cols"
      :rows="rows"
      class="-table"
    >
      <template
        slot="row"
        slot-scope="{ row }"
      >
        <td :class="tdClass">
          <span
            class="td-link"
            @click="$emit('details-click', row.id)"
          >
            {{ row.order_id }}
          </span>
        </td>
        <td :class="tdClass">
          <span
            class="td-link"
            @click="$emit('details-click', row.id)"
          >
            {{ formatString (row.title) }}
          </span>
        </td>
        <td :class="tdClass">
          <span lass="td-span">{{ formatCurrency(row.total) }}</span>
        </td>
        <td :class="tdClass">
          <span class="td-span">{{ formatDate(row.release_date) }}</span>
        </td>
        <td :class="tdClass">
          <c-label
            class="table-status"
            text-color="white"
            :color="statusColor(row)"
          >
            {{ statusLabel(row) }}
          </c-label>
        </td>
        <td
          :class="tdClass"
          class="table-actions"
        >
          <c-action-button
            :disabled="disableCancelFunction (row.status)"
            @click.native="disableCancelFunction (row.status) ? '' : $emit('cancel-click', row.id)"
            text="Cancelar pedido"
            icon="trash-delete-bin4"
          />
        </td>
      </template>
    </data-table>
    <div
      class="empty-content"
      v-if="rows.length === 0 && hasEmptyBanner"
    >
      <empty-banner
        button="Novo pedido"
        :title="emptyBannerText"
        @button-click="$emit('new-order')"
      />
    </div>
  </c-table-container>
</template>

<script>
import { timestampToOnlyDate } from '@/modules/validate/helpers.js'
import EmptyBanner from '@/components/General/EmptyBanner'
import CActionButton from '@/components/CComponents/CActionButton'

const statuses = {
  0: {
    label: 'Cancelado',
    color: '#EE5250'
  },
  1: {
    label: 'Entregue',
    color: '#4CAD50'
  },
  2: {
    label: 'Aguardando Liberação Interna',
    color: '#f5a623'
  },
  3: {
    label: 'Aguardando Pagamento',
    color: '#f5a623'
  },
  4: {
    label: 'Entrega Agendada',
    color: '#003396'
  }
}

export default {
  components: {
    EmptyBanner,
    CActionButton,
    DataTable: () => import('vue-data-tablee'),
    CTableContainer: () => import('@/components/CComponents/CTableContainer'),
    CLabel: () => import('@/components/CComponents/CLabel')
  },
  props: {
    rows: Array,
    cols: Array,
    emptyBannerText: String,
    hasEmptyBanner: Boolean,
    paginate: Object
  },
  data () {
    return {
      statuses,
      tdClass: 'data-tablee-cell -content -center'
    }
  },
  computed: {
    formatedRows () {
      if (this.rows.length && this.rows.length < 5) {
        let array = []
        const diff = 5 - this.rows.length
        for (let i = 0; i < diff; i++) {
          array.push({})
        }
        array = this.rows.concat(array)
        return array
      }
      return this.rows
    },
    isRowsEmpty () {
      return this.rows.length === 0
    }
  },
  methods: {
    disableCancelFunction (status) {
      return status === 0 || status === 1 || status === 4
    },
    formatCurrency (value) {
      return (value / 100).toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL'
      })
    },
    formatString (string) {
      if (!string || string.length < 43) return string
      return string.substring(0, 43) + '...'
    },
    formatDate (date) {
      const formatedDate = timestampToOnlyDate(date)
      return formatedDate
    },
    statusColor (row) {
      if (!row || !this.statuses[row.status].color) return '#000'
      return this.statuses[row.status].color
    },
    statusLabel (row) {
      if (!row || !this.statuses[row.status].label) return 'Carregando...'
      return this.statuses[row.status].label
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/styles/reference';

.result { border: 1px solid rgba(18, 30, 72, 0.05); }
.listing-table-preview-button { width: 135px; }
.listing-table {
  &.-inactive {
    :deep > .table .-header .data-tablee-text {
      color: #D8D8D8;
    }
  }
  :deep > .table {
    border: none;
    .-header {
      background: #FDFDFD;
      padding: 20px;
    }
  }
  .-table {
    background: #FDFDFD;
    box-shadow: 0px 4px 8px rgba(94, 102, 132, 0.08);
    border-radius: 4px;
    border: none;
  }
  td.span {
    color: #5E6684;
    font-size: 14px;
  }
  .td-link {
    color: #608CEA;
    font-size: 14px;
    cursor: pointer;
    text-decoration: underline;

    :visited {
      color: #608CEA;
    }
  }
  td {
    height: 80px;
  }
  .table-status {
    width: 135px;
    height: 30px;

    > .text{
      text-transform: none;
      font-size: 12px;
    }
  }
  .table-actions {
    display: flex;
    justify-content: center;

    .icon-action {
      font-size: 20px;
      margin: 3px;
      cursor: pointer;
      padding: 10px;
      border: 1px solid rgba(94, 102, 132, 1);
      border-radius: 50px;
      transition: all .1s ease;

      &:hover {
        border-width: 2px;
      }

      &.-disabled {
        background: rgba(216, 216, 216, 1);
        border: none;
      }
    }
  }

  .empty-content {
    padding: 20px;
    background: white;
  }
}
</style>
