<template>
  <div class="awards-resume-container">
    <orders-resume
      :is-loading="loading"
      @new-order="handleNewOrder"
      @cancel-order="handleCancelOrder"
    />
    <campaigns-resume
      :campaigns="campaigns"
      @new-order="handleNewOrder"
      @info-click="openAwardCampaignModalInfo = true"
    />
    <award-order-modal-info
      show-close
      hide-cancel
      @close="openAwardOrderModalInfo = false"
      @link-click="handleLinkClick"
      :is-opened="openAwardOrderModalInfo"
    />
    <award-campaign-modal-info
      show-close
      hide-cancel
      @close="openAwardCampaignModalInfo = false"
      :is-opened="openAwardCampaignModalInfo"
    />
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
import OrdersResume from '~/components/Awards/Resume/OrdersResume'
import CampaignsResume from '~/components/Awards/Resume/CampaignsResume'
import AwardOrderModalInfo from '@/components/Awards/base/AwardOrderModalInfo'
import AwardCampaignModalInfo from '@/components/Awards/base/AwardCampaignModalInfo'
import { CANCEL_ORDER, GET_AWARDS_CAMPAIGNS, AWARDS_CAMPAIGNS, COMPANY, AWARD_STEP, AWARDS_GET_ORDERS_LIST } from '@/store/types'

export default {
  components: { OrdersResume, CampaignsResume, AwardOrderModalInfo, AwardCampaignModalInfo },
  data () {
    return {
      loading: false,
      openAwardOrderModalInfo: false,
      openAwardCampaignModalInfo: false
    }
  },
  computed: {
    ...mapGetters({
      campaigns: AWARDS_CAMPAIGNS,
      company: COMPANY
    })
  },
  mounted () {
    this.handleGetCampaigns()
  },
  methods: {
    ...mapMutations({
      updateAwardStep: AWARD_STEP
    }),
    ...mapActions({
      cancelOrder: CANCEL_ORDER,
      getAwardCampaigns: GET_AWARDS_CAMPAIGNS,
      getOrders: AWARDS_GET_ORDERS_LIST,
      getCompany: COMPANY
    }),
    handleAnalytics (event, data) {
      window.dataLayer.push(
        {
          event,
          ...data
        }
      )
    },
    handleLinkClick () {
      this.openAwardOrderModalInfo = false
      this.openAwardCampaignModalInfo = true
    },
    handleNewOrder () {
      this.updateAwardStep(1)
      this.handleAnalytics('premios-pedido-criacao', {
        company_name: this.company.slug
      })
      this.$router.push('/premiacoes/novo-pedido')
    },
    async handleCancelOrder (id) {
      this.loading = true
      await this.cancelOrder({id})
      await this.handleGetCampaigns()
      await this.getCompany()
      this.loading = false
      this.getCompany()
    },
    async handleGetCampaigns () {
      this.loading = true
      await this.getAwardCampaigns()
      await this.getOrders()
        .catch(() => {
          this.feedback({
            type: 'error',
            title: 'Houve um erro ao carregar os pedidos',
            text: 'Tente novamente mais tarde'
          })
        })
        .finally(() => {
          this.loading = false
        })
    }
  }
}
</script>

<style>
</style>
