import { render, staticRenderFns } from "./NewOrderStepTwo.vue?vue&type=template&id=47773c04&scoped=true"
import script from "./NewOrderStepTwo.vue?vue&type=script&lang=js"
export * from "./NewOrderStepTwo.vue?vue&type=script&lang=js"
import style0 from "./NewOrderStepTwo.vue?vue&type=style&index=0&id=47773c04&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "47773c04",
  null
  
)

export default component.exports