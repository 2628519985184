<template>
  <partner-edit />
</template>

<script>
import PartnerEdit from '@/containers/Partners/PartnerEdit'

export default {
  components: { PartnerEdit }
}
</script>
