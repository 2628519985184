<template>
  <div
    data-cy="orders-resume"
    class="orders-resume"
  >
    <div class="title-header">
      <c-title
        class="title"
      >
        Resumo de pedidos
      </c-title>
      <c-button
        primary
        data-cy="new-award-button"
        class="new-award-button"
        icon="plus"
        @click="$emit('new-order')"
      >
        Novo pedido
      </c-button>
    </div>
    <transition name="fade">
      <c-spinner v-if="loading || isLoading" />
      <listing-table
        v-else
        data-cy="orders-resume-table"
        empty-banner-text="Você ainda não possui um pedido de premiação"
        has-empty-banner
        :rows="ordersResume"
        :cols="tableCols"
        @cancel-click="handleCancelClick"
        @details-click="handleDetailsClick"
        @new-order="$emit('new-order')"
      />
    </transition>
    <c-confirm-modal
      :btn-props="{ primary: true }"
      confirm-text="Cancelar pedido"
      character="embarrassed"
      title="Tem certeza que deseja cancelar seu pedido?"
      message="Esse pedido de premiação não será processado"
      :is-opened="openCancelModal"
      @close="abortCancel"
      @confirm="$emit('cancel-order', orderId); openCancelModal = false"
    />
  </div>
</template>

<script>
import { ORDERS_LIST } from '@/store/types'
import { mapGetters } from 'vuex'
import OrdersMixins from '@/components/Awards/Orders/orders-mixins.js'
import ListingTable from '@/components/General/ListingTable.vue'
import CConfirmModal from '@/components/CComponents/CConfirmModal'

export default {
  components: {
    ListingTable,
    CConfirmModal
  },
  mixins: [OrdersMixins],
  props: {
   isLoading: Boolean
  },
  data () {
    return {
      tableCols: [
        { label: 'Nº', align: 'center' },
        { label: 'Pedido', align: 'center' },
        { label: 'Valor', align: 'center' },
        { label: 'Agendado para', align: 'center' },
        { label: 'Status', align: 'center' },
        { label: 'Ação', align: 'center' }
      ],
      openCancelModal: false,
      orderId: null,
      loading: true
    }
  },
  computed: {
    ...mapGetters({
      orders_list: ORDERS_LIST
    }),
    ordersResume () {
      if (!this.orders_list || !this.orders_list.data) return []
      return this.orders_list.data.slice(0, 5)
    }
  },
  mounted () {
    this.getOrdersResume()
  },
  methods: {
    handleAnalytics (event, data) {
      window.dataLayer.push(
        {
          event,
          ...data
        }
      )
    },
    handleLinkClick () {
      this.openAwardOrderModalInfo = false
    },
    handleDetailsClick (orderId) {
      this.handleAnalytics('premios-pedido-visualizar', {
        company_name: this.company.slug
      })
      this.$router.push({ path: `detalhes/${orderId}` })
    },
    handleCancelClick (orderId) {
      this.openCancelModal = true
      this.orderId = orderId
    },
    abortCancel () {
      this.orderId = null
      this.openCancelModal = false
    }
  }
}
</script>

<style lang="scss">
@import '~@/styles/reference';

.orders-resume {
  max-width: 1020px;
  margin-top: 35px;
  padding: 0 10px;

  @include desktop { @include container; }

  > .title-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    > .title {
      max-width: 200px;
    }

    > .new-award-button {
      width: 170px;
      height: 40px;
      border-radius: 30px;
      font-size: 12px;
      margin-top: 16px;
      font-weight: 400;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
</style>
