<template>
  <div class="distribution-details-container">
    <notification-details
      :notification="notification"
      :campaign="campaign"
      :partner="partner"
      :loading="loading"
      :status-loading="statusLoading"
      @edit="handleEditNotification"
      @toggle-status="handleEditNotificationStatus"
    />
  </div>
</template>

<script>
import NotificationDetails from '~/components/Communication/Notifications/Details/NotificationDetails'
import Loadable from '@/modules/loadable'
import { NOTIFICATION, COMPANY, GET_NOTIFICATION_DETAILS, UPDATE_NOTIFICATION, CAMPAIGN, PARTNER } from '@/store/types'
import { mapGetters, mapActions } from 'vuex'
import moment from 'moment'

export default {
  components: { NotificationDetails },
  mixins: [Loadable],
  data () {
    return {
      loading: true,
      statusLoading: false,
      notificationId: null,
      openInvoicePerEmailModal: false
    }
  },
  computed: {
    ...mapGetters({
      notification: NOTIFICATION,
      company: COMPANY,
      campaign: CAMPAIGN,
      partner: PARTNER
    }),
    oneWeekAfterDate () {
      return moment().add(7, 'days').format('YYYY-MM-DD')
    },
    twoDaysBeforeDate () {
      return moment().subtract(2, 'days').format('YYYY-MM-DD')
    },
    yesterdayDate () {
      return moment().subtract(1, 'days').format('YYYY-MM-DD')
    }
  },
  methods: {
    ...mapActions({
      getNotification: GET_NOTIFICATION_DETAILS,
      updateNotification: UPDATE_NOTIFICATION,
      getCampaign: CAMPAIGN
    }),
    handleAnalytics (event, data) {
      window.dataLayer.push(
        {
          event,
          ...data
        }
      )
    },
    async load () {
      this.loading = true
      this.notificationId = this.$route.params.notificationId
      await this.getNotification(this.notificationId)
      this.loading = false
    },
    handleLinkClick () {
      this.openAwardOrderModalInfo = false
    },
    async handleEditNotificationStatus (status) {
      this.loading = true
      const formatedDates = status
        ? {
          start_date: `${this.yesterdayDate}`,
          end_date: `${this.oneWeekAfterDate}`
        }
        : {
          start_date: this.twoDaysBeforeDate,
          end_date: `${this.yesterdayDate}`
        }
      const editedNotification = {
        id: this.notificationId,
        ...this.notification,
        ...formatedDates
      }

      delete editedNotification.image
      await this.updateNotification(editedNotification)
      await this.getNotification(this.notificationId)
      this.loading = false
    },
    async handleEditNotification () {
      this.handleAnalytics('notificacao-editar', { company_name: this.company.slug })
      this.$router.push(`/comunicacao/notificacoes/criar/${this.notificationId}`)
    }
  }
}
</script>

<style>
</style>
