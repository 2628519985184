<template>
  <section class="user-import-step">
    <header class="header">
      <div class="step">
        <span class="number">{{ step }}</span>
      </div>
      <p class="title">{{ title }}</p>
    </header>

    <div class="content">
      <slot />
    </div>
  </section>
</template>

<script>
export default {
  props: {
    title: String,
    step: [String, Number]
  }
}
</script>

<style lang="scss">
@import '~@/styles/reference';

.user-import-step {
  margin-bottom: 30px;

  & > .header {
    display: flex;
    flex-flow: row;
    justify-content: flex-start;
    align-items: center;
    margin: 0 5px 20px 5px;

    & > .step {
      height: 30px;
      width: 30px;
      border-radius: 50%;
      background-color: $primary-color-placeholder;
      background-color: var(--color-primary);
      display: flex;
      flex-flow: row nowrap;
      justify-content: center;
      align-items: center;
      margin-right: 10px;

      & > .number {
        color: white;
        font-family: $title-font-family;
        line-height: 1;
        font-size: 18px;
        padding: 0 10px;
        font-weight: 400;
      }
    }

    & > .title {
      font-size: 14px;
      color: $title-color;
    }
  }
}
</style>
