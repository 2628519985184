<template>
  <div class="user-header">
    <div class="content">
      <div class="navigation">
        <c-toggler
          class="toggler"
          :tabs="navTabs"
          :active-tab="$route.name"
          @change-tab="(name) => $router.push({ name })"
        />
      </div>

      <div class="actions">
        <transition name="fade">
          <c-button
            class="button"
            primary
            size="lg"
            icon="plus"
            icon-size="sm"
            data-cy="import-users-button"
            v-if="$route.name === 'usuarios-importacao' && isDesktop"
            :disabled="isImporting === undefined || isUserQuotaExceeded"
            @click="redirectOrOpenModal"
          >
            Importar usuários
          </c-button>
        </transition>
      </div>
    </div>

    <user-import-processing-modal
      v-if="importingFeedback"
      :is-opened="importingFeedback"
      @close="importingFeedback = false"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import * as types from '@/store/types'

export default {
  components: {
    CToggler: () =>
      import('@/components/CComponents/CToggler'),
    UserImportProcessingModal: () =>
      import('@/components/User/Import/UserImportProcessingModal')
  },
  props: {
    filtersByStatus: Array,
    hasFilter: Boolean,
    showFilter: Boolean,
    managerSelected: Boolean
  },
  data () {
    return {
      navTabs: [
        {
          name: 'Usuários',
          value: 'usuarios'
        },
        {
          name: 'Importação',
          value: 'usuarios-importacao'
        }
      ],
      importingFeedback: false
    }
  },
  computed: {
    ...mapGetters({
      isImporting: types.USER_IMPORT_PROCESSING,
      userCount: types.USER_COUNT,
      company: types.COMPANY
    }),
    isUserQuotaExceeded () {
      const plansWith10Users = ['trial']
      if (this.company.trial || plansWith10Users.includes(this.company.plan_type)) {
        return parseInt(this.userCount.total_users) >= 11
      } else {
        return false
      }
    }
  },
  methods: {
    redirectOrOpenModal () {
      if (!this.isImporting || this.company.trial) {
        this.$router.push({ name: 'usuarios-importacao-nova' })
      } else {
        this.importingFeedback = true
      }
      this.handleAnalytics('botao-importar-usuarios', {
        company_name: this.company.name
      })
    },
    handleAnalytics (event, data) {
      window.dataLayer.push(
        {
          event,
          ...data
        }
      )
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/styles/reference';

.user-header {
  box-shadow: 0px 4px 1px -1px rgba(213, 214, 226, 0.4);
  background-color: #FFF;

  & > .content {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include desktop { @include container }
    @include mobile { flex-direction: column; }

    & > .navigation {
      @include mobile { width: 100%; }
    }

    & > .actions {
      display: flex;
      flex-flow: row wrap;
      // margin: 0 -5px;

      & > * { margin: 5px; }
    }
  }

  .header-popover > .c-popover > .content > .filters { height: 513px; }

  .popover-filter-group { @include popoverFilterGroup; }

  .popover-filter-apply.c-button.-block{ position: absolute; }
}

.fade-enter-active, .fade-leave-active { transition: opacity .3s; }
.fade-enter, .fade-leave-to { opacity: 0; }
</style>
