<template>
  <user-form />
</template>

<script>
import UserForm from '~/containers/User/Form/UserForm'

export default {
  components: { UserForm }
}
</script>
