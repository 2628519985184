<template>
  <div class="payment-card">
    <details-card title="Pagamento">
      <template #content>
        <div class="main">
          <div class="main-left">
            <span class="info-label">Forma de pagamento</span><br />
            Boleto bancário
          </div>
          <div class="main-right">
            <span class="info-label">Valor</span> <br />
            {{ totalFormated }}
          </div>
        </div>
        <a
          v-if="billLink"
          :href="billLink"
          target="_blank"
          class="action"
        >
          Ver boleto bancário
        </a>
      </template>
    </details-card>
  </div>
</template>

<script>
import DetailsCard from './DetailsCard'

export default {
  components: {
    DetailsCard
  },
  props: {
    total: Number,
    billLink: String
  },
  computed: {
    totalFormated () {
      return (this.total / 100).toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL'
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.payment-card {
  height: 185px;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.16);

  .details-card {
    :deep .details-content {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      color: #5E6684;

      .main {
        width: 100%;
        margin: auto 0;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        font-weight: 600;
        font-size: 13px;

        .info-label {
          color: #202950;
          text-transform: uppercase;
        }

        .main-left {
          justify-self: flex-start;
          text-align: flex-start;
        }
        .main-right {
          justify-self: end;
          text-align: end;
        }
      }

      .action {
        width: 100%;
        font-size: 14px;
        cursor: pointer;
        color: #608CEA;
        text-decoration-line: underline;
      }
    }
  }
}
</style>
