<template>
  <div
    class="account-creation-container"
    :class="accountWasCreated ? '-email-confirmation' : ''"
  >
    <account-creation-navbar :hide-login-action="accountWasCreated" />
    <div
      class="container"
      v-if="!accountWasCreated"
    >
      <account-creation-form
        :loading="loading"
        @submit-form="userData => submitForm(userData)"
      />
    </div>
    <email-confirmation-message v-else />
    <account-creation-footer />
    <creation-error-modal
      :is-opened="openErrorModal"
      :error="error"
      @action-click="handleErrorModalAction"
      @close="openErrorModal = false"
    />
  </div>
</template>

<script>
import AccountCreationNavbar from '@/components/AccountCreation/AccountCreationNavbar.vue'
import AccountCreationFooter from '@/components/AccountCreation/AccountCreationFooter.vue'
import AccountCreationForm from '@/components/AccountCreation/AccountCreationForm.vue'
import EmailConfirmationMessage from '@/components/AccountCreation/EmailConfirmationMessage.vue'
import CreationErrorModal from '@/components/AccountCreation/CreationErrorModal.vue'
import { mapActions } from 'vuex'
import { ACCOUNT_CREATE } from '@/store/types'
require('@/analytics.js')

export default {
  name: 'AccountCreationContainer',
  components: {
    AccountCreationNavbar,
    AccountCreationFooter,
    AccountCreationForm,
    EmailConfirmationMessage,
    CreationErrorModal
  },
  data () {
    return {
      loading: false,
      openErrorModal: false,
      error: '',
      userData: null,
      accountWasCreated: false
    }
  },
  methods: {
    ...mapActions({
      createAccount: ACCOUNT_CREATE
    }),
    async submitForm (userData) {
      this.loading = true
      const res = await this.createAccount(userData)
      if (res && res.success) {
        this.userData = null
        this.accountWasCreated = true
      } else if (res && res.data) {
        this.error = Object.keys(res.data)[0]
        this.userData = userData
        this.openErrorModal = true
      }
      this.loading = false
    },
    handleErrorModalAction () {
      const phoneFormaterRegex = /[^0-9]+/g
      const landingPageHostname = process.env.NODE_ENV === 'production'
        ? 'https://kaledo.com.br/'
        : 'https://qa.kaledo.com.br/'

      const kaledoSalesLink = `${landingPageHostname}
        ?sales_modal=true&name=${this.userData.name}
        &email=${this.userData.email}
        &phone=${this.userData.phone.replace(phoneFormaterRegex, '')}`

      const actionLink = (this.error === 'company_exists')
        ? kaledoSalesLink
        : 'https://kaledo.zendesk.com/hc/pt-br/categories/15574766394253-Sou-Empresa'

      if (this.error === 'company_exists' || this.error === 'user_exists') {
        window.open(actionLink, '_blank')
      } else {
        this.openErrorModal = false
      }
    }
  }
}
</script>

<style lang='scss' scoped>
@import '~@/styles/reference.scss';

.account-creation-container {
  background-image: url('@/assets/img/account-creation-bg.jpeg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: center;

  &.-email-confirmation {
    height: 100vh;
  }

  .container {
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
    margin-bottom: 46px;
  }
  @include mobile {
    background-image: url('@/assets/img/account-creation-bg-mobile.jpeg');
    background-repeat: repeat;
  }
  @media screen and (min-width: 768px) and (max-width: 1023px) {
    background-image: url('@/assets/img/account-creation-bg-tablet.jpeg');
  }
  @include tablet {
    background-image: url('@/assets/img/account-creation-bg-tablet.jpeg');
  }
}
</style>
