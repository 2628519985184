<template>
  <div
    data-cy="general-header"
    class="general-header"
  >
    <div class="content">
      <div class="navigation">
        <c-toggler
          v-if="showTabs"
          class="toggler"
          :tabs="navTabs"
          :active-tab="$route.name"
          @change-tab="(name) => { if ($route.push !== name) { $router.push({ name }) } }"
        />

        <k-icon
          data-cy="back-button"
          v-else
          icon="left"
          class="back-button"
          @click.native="$emit('return-click')"
        />
      </div>

      <div class="actions">
        <slot name="action-button" />
        <c-info-button
          v-if="!hideInfoButton"
          data-cy="show-onboarding-button"
          class="onboarding-button"
          @btn-click="$emit('show-onboarding')"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { KButton, KIcon } from 'kaledo-components'
import CToggler from '@/components/CComponents/CToggler'
import CInfoButton from '@/components/CComponents/CInfoButton'

export default {
  components: {
    KButton,
    CToggler,
    KIcon,
    CInfoButton
  },
  props: {
    navTabs: Array,
    hideInfoButton: Boolean,
    showTabs: Boolean
  }
}
</script>

<style lang="scss" scoped>
@import '~@/styles/reference';

.general-header {
  box-shadow: 0px 4px 1px -1px rgba(213, 214, 226, 0.4);
  background-color: #FFF;
  height: 59px;

  & > .content {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include desktop { @include container }
    @include mobile { flex-direction: column; }

    & > .navigation {
      & > .back-button {
        margin-top: -5px;
        font-size: 20px;
        width: 30px;
        height: 30px;
        margin-left: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        transition: all .1s ease;
        border: 1px solid #5e6684;
        border-radius: 50px;

        &:hover {
          border: 3px solid rgba(94, 102, 132, 1);
          border-radius: 50px;
        }
      }
      @include mobile { width: 100%; }

    }

    & > .actions {
      display: flex;
      align-items: flex-end;
      padding-bottom: 5px;
      flex-flow: row wrap;

      :deep .info-button {
        margin-top: 5px;
        margin-left: 20px;
        width: 40px;
        height: 40px;
        border: 1px solid rgba(94, 102, 132, 1);
        border-radius: 50px;

        &:hover {
          border: 3px solid rgba(94, 102, 132, 1);
          border-radius: 50px;
        }
      }
    }
  }
}

</style>
