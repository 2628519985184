<template>
  <div class="order-details">
    <div class="title-header">
      <c-title
        class="title"
      >
        Detalhes do pedido
      </c-title>
    </div>
    <transition name="fade">
      <c-spinner v-if="loading" />
      <div
        class="content"
        v-else
      >
        <main>
          <rewarded-users
            title="Usuários recompensados"
            :users="order.users"
            :table-cols="cols"
            :release-date="order.release_date"
            :total="formatedOrderValue"
            is-formatted
            use-int-value
          />
        </main>

        <aside>
          <order-card
            :order-details="order"
            :disable-cancel-action="disableCancelFunction"
            @order-cancel="openCancelModal = true"
          />
          <payment-card
            :total="order.value"
            :bill-link="order.bill_link"
          />
          <historic-card :status="order.status" />
        </aside>
      </div>
    </transition>

    <c-confirm-modal
      :btn-props="{ primary: true }"
      confirm-text="Cancelar pedido"
      character="embarrassed"
      title="Tem certeza que deseja cancelar seu pedido?"
      message="Esse pedido não será processado"
      :is-opened="openCancelModal"
      @close="openCancelModal = false"
      @confirm="$emit('cancel-order'); openCancelModal = false"
    />
  </div>
</template>

<script>
import RewardedUsers from '@/components/General/RewardedUsers'
import OrderCard from '@/components/General/cards/OrderCard'
import PaymentCard from '@/components/General/cards/PaymentCard'
import HistoricCard from '@/components/General/cards/HistoricCard'
import CConfirmModal from '@/components/CComponents/CConfirmModal'

export default {
  components: {
    OrderCard,
    PaymentCard,
    HistoricCard,
    RewardedUsers,
    CConfirmModal
  },
  props: {
    loading: Boolean,
    order: {
      type: Object,
      default () { return {} }
    }
  },
  data () {
    return {
      openCancelModal: false,
      orderId: null,
      cols: ['email', 'cpf', 'moedas']
    }
  },
  computed: {
    formatedOrderValue () {
      return this.order.value / 100
    },
    disableCancelFunction () {
      return this.order.status === 0 || this.order.status === 1 || this.order.status === 4
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/styles/reference';

.order-details {
  max-width: 1020px;
  margin-top: 35px;
  padding: 0 10px;
  display: flex;
  flex-direction: column;

  @include desktop { @include container; }

  > .title-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    > .title {
      max-width: 200px;
    }
  }
  .content {
    display: flex;
    justify-content: space-between;

    @include tablet { flex-direction: column; }

    @include mobile { flex-direction: column; }

    main {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      width: 100%;
      margin-right: 50px;
      margin-bottom: 20px;

      .rewarded-users {
        width: 100%;
        margin-bottom: 20px;
      }
    }
    aside {
      display: flex;
      flex-direction: column;
      width: 100%;
      min-width: 300px;

      > :not(:first-child) {
        margin-top: 20px;
      }
    }
  }
}
</style>
