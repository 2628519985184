<template>
  <div class="step-one">
    <campaign-modal
      v-if="campaignInfo"
      :show-modal="showModal"
      :campaign="campaignInfo"
      @close="showModal = false"
    />
    <div class="step-one-title">
      <c-title
        secondary-icon
        class="title"
        icon="award-star"
      >
        Pedido
      </c-title>
    </div>
    <div class="name">
      <div class="input">
        <c-input
          data-cy="award-order-name-input"
          v-model="title"
          name="name"
          label="Dê um nome para o seu pedido"
          required
          maxlength="50"
          v-validate="'required'"
          placeholder="Nome do pedido"
          @input="update('title', $event )"
        />
        <span class="subtext">Max 50 caracteres</span>
      </div>
    </div>
    <div class="campaigns-list">
      <div class="title">
        <c-title
          secondary-icon
          class="text-title"
          icon="award-star"
        >
          Campanha
        </c-title>
        <c-info-button
          data-cy="campaigns-info-button"
          class="info-btn"
          @btn-click="openAwardCampaignModalInfo = true"
        />
      </div>
      <swiper-container
        class="carousel"
        :options="options"
      >
        <swiper-item
          v-for="(campaign, index) in campaigns"
          :key="index"
          class="swiper-slide"
        >
          <award-campaign-card
            :campaign="campaign"
            :selected="campaign.id === selectedCampaign.id"
            :has-checkbox="true"
            text="Ver mais"
            @buttonEvent="handleShowMore(campaign)"
            @select="selectCampaign(campaign)"
          />
        </swiper-item>
        <div
          slot="button-prev"
          class="swiper-button -prev"
        >
          <k-icon
            class="icon"
            icon="left1"
            size="22"
          />
        </div>

        <div
          slot="button-next"
          class="swiper-button -next"
        >
          <k-icon
            class="icon"
            icon="arrow-right"
            size="22"
          />
        </div>
      </swiper-container>
    </div>
    <award-campaign-modal-info
      show-close
      hide-cancel
      @close="openAwardCampaignModalInfo = false"
      :is-opened="openAwardCampaignModalInfo"
    />
  </div>
</template>

<script>
import { KIcon } from 'kaledo-components'
import CInfoButton from '@/components/CComponents/CInfoButton.vue'
import { swiper as SwiperContainer, swiperSlide as SwiperItem } from 'vue-awesome-swiper'
import AwardCampaignCard from '@/components/Awards/base/AwardCampaignCard'
import AwardCampaignModalInfo from '@/components/Awards/base/AwardCampaignModalInfo'
import CampaignModal from '@/components/Awards/base/CampaignDetails/CampaignModal'

export default {
  components: {
    KIcon,
    CInfoButton,
    SwiperContainer,
    SwiperItem,
    AwardCampaignCard,
    CampaignModal,
    AwardCampaignModalInfo
  },
  props: {
    campaigns: Array,
    stepValue: Object
  },
  data () {
    return {
      title: '',
      showModal: false,
      campaignInfo: null,
      selectedCampaign: {},
      openAwardCampaignModalInfo: false,
      options: {
        slidesPerView: 'auto',
        spaceBetween: 10,
        slidesPerGroup: 3,
        navigation: {
          nextEl: '.swiper-button.-next',
          prevEl: '.swiper-button.-prev',
          disabledClass: '-disabled',
          hiddenClass: '-hidden'
        }
      }
    }
  },
  created () {
    this.title = this.stepValue.title
    this.selectedCampaign.id = this.stepValue.award_campaign_id
  },
  methods: {
    selectCampaign (campaign) {
      if (this.selectedCampaign.id === campaign.id) {
        this.update('award_campaign_id', null)
        this.selectedCampaign = {}
      } else {
        this.update('award_campaign_id', campaign.id)
        this.selectedCampaign = campaign
      }
    },
    update (key, value) {
      this.$emit('input', { ...this.stepValue, [key]: value })
    },
    handleShowMore (campaign) {
      this.campaignInfo = campaign
      this.showModal = true
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/styles/reference';

.step-one {
  max-width: 1020px;
  margin-top: 40px;
  padding: 0 10px;

  > .step-one-title {
    display: flex;
    height: 35px;
    margin-bottom: 20px;

    > .title {
      max-width: 240px;
      margin-bottom: 30px;
    }
  }

  @include desktop { @include container; }
  .name {
    max-width: 328px;
    height: 125px;
    & > .title {
      padding-bottom: 24px;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #202950;
    }
    & > .input :deep .label {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #202950;
      text-transform: none;
    }
    & > .input {
      & > .subtext {
          font-weight: 400;
          font-size: 12px;
          line-height: 18px;
          display: flex;
          align-items: center;
          letter-spacing: -0.121905px;
          color: #5E6684;
          padding-top: 6px;
        }
    }
  }
  .campaigns-list {
    .title {
      display: flex;
      height: 35px;
      margin-bottom: 20px;

      .info-btn {
        margin-top: -3px;
        margin-left: 5px;
      }
    }
    .carousel {
      padding-bottom: 5px;
      display: flex;

      .swiper-button { display: none; }

      :deep .swiper-wrapper {
        display: flex;

        @include mobile {
          padding: 0 10px;
        }
      }

      @include desktop {
        position: relative;
        overflow: hidden;

        & > .swiper-wrapper {
          padding: 10px 0;
          display: flex;

          & > .swiper-slide { display: block; }
        }

        & > .swiper-button {
          display: block;
          position: absolute;
          cursor: pointer;
          top: 50%;
          background-color: $shadow-color;
          border-radius: 50%;
          width: 60px;
          height: 60px;
          transition: opacity .3s ease;

          & > .icon {
            fill: rgba(white, .8);
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
          }

          &.-disabled,
          &.-hidden { opacity: 0; }

          &.-prev {
            left: 0;
            transform: translate(-50%, -50%);

            & > .icon {
              right: 8px;
              position: absolute;
            }
          }

          &.-next {
            right: 0;
            transform: translate(50%, -50%);

            & > .icon {
              left: 8px;
              position: absolute;
            }
          }
        }
      }
    }
  }
}
.swiper-slide {
  display: inline-block;
}

.swiper-wrapper { white-space: nowrap; }
</style>
