<template>
  <div
    data-cy="steps"
    class="container"
  >
    <div
      class="step"
      v-for="step in steps"
      :key="step.number"
    >
      <k-icon
        v-if="step.number != 1"
        icon="arrow-right"
        size="20"
        class="icon"
      />
      <div
        data-cy="step-card"
        :class="'card ' + setBackground(step.number)"
        @click="handleStepClick(step.number)"
      >
        <p :class="'title -' + setBackground(step.number)">
          {{ step.number }}º Passo <k-icon
            v-if="(step.number < currentStep)"
            icon="checkmark-circle-1"
            size="16"
            class="icon"
          />
        </p>
        <p :class="'text -' + setBackground(step.number)">
          {{ step.text }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { KIcon } from 'kaledo-components'
export default {
  components: {
    KIcon
  },
  props: {
    currentStep: {
      type: Number,
      required: true
    },
    steps: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      defaultSteps: []
    }
  },
  methods: {
    setBackground (step) {
      if (step > this.currentStep) return 'next'
      else if (step < this.currentStep) return 'prev'
      else return 'current'
    },
    handleStepClick (step) {
      if (step < this.currentStep) { this.$emit('step-click', step) }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/styles/reference';

.container {
  display: flex;
  padding: 0 !important;
  .step {
    display: flex;
    align-items: center;
    .icon {
      padding: 0 8px;
    }
    .card {
      width: 128px;
      height: 58px;
      background: #FDFDFD;
      border: 2px solid #202950;
      border: 2px solid var(--color-secondary);
      border-radius: 4px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      padding: 8px 15px 8px 8px;
      gap: 4px;
      .title {
        font-weight: 400;
        font-size: 10px;
        line-height: 15px;
        text-align: center;
        letter-spacing: -0.121905px;
        text-transform: uppercase;
        color: #202950;
      }
      .text {
        font-weight: 700;
        font-size: 12px;
        line-height: 18px;
        text-align: center;
        letter-spacing: -0.121905px;
        color: #202950;
      }

      .-prev {
        color:  #FDFDFD !important;
      }

      .-next {
        color: #8C92B1 !important;
      }
    }
    .next {
      background: #D8D8D8 !important;
      color: #8C92B1 !important;
      border: none;
    }
    .prev {
      background: var(--color-primary);
      color:  #FDFDFD !important;
      transition: all .3s ease;
      &:hover {
        background: rgba($primary-color-placeholder, .8)  !important;
        cursor: pointer;
      }
    }
  }
}
</style>
