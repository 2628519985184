<template>
  <div>
    <not-available
      type="construction"
    >
      <p class="description">
        Em breve será possível consultar gráficos e <br />
        demais informações sobre {{ tabName }}
      </p>
    </not-available>
  </div>
</template>

<script>
import NotAvailable from '@/components/Dashboard/base/notAvailable.vue'

export default {
  name: 'UnderConstructionCard',
  components: {
    NotAvailable
  },
  props: {
    tabName: String
  }
}
</script>

<style lang='scss' scoped>
.empty {
  width: 100%;
  height: 200px;
  padding: 16px;
  border-radius: 6px 6px 0px 0px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.16);
  background: white !important;

  :deep .content {
    max-width: 320px !important;

    > .description {
      font-size: 16px;
      margin-top: 10px;
    }
    > .icon {
      color: #5E6684;
    }
    > .text {
      font-size: 16px;
      text-transform: uppercase;
      color: #5E6684;
    }
  }
}
</style>
