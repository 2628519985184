<template>
  <div class="user-profile">
    <c-avatar
      size="lg"
      :image="user.avatar"
    />
    <h3 class="name">
      {{ user.name || user.document || '' }}
    </h3>
    <!-- <c-button isBold>
      Minhas Informações
    </c-button> -->
  </div>
</template>

<script>
import * as types from '@/store/types'
import { mapGetters } from 'vuex'

import CAvatar from '@/components/CComponents/CAvatar'

export default {
  components: { CAvatar },
  computed: mapGetters({ user: types.CONFIG_USER })
}
</script>

<style lang="scss">
@import '~@/styles/reference';

.user-profile {
  padding-top: 46px;
  padding-bottom: 18px;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    top: -20px;
    left: 0;
    height: 130px;
    width: 100%;
    background-image: url('~/assets/img/pattern-oferta.svg');
    background-repeat: repeat-y;
  }

  & > .name {
    color: $title-color;
    text-transform: uppercase;
    font-size: 14px;
    line-height: 19px;
    margin-top: 14px;
    margin-bottom: 3px;
  }
  & > .withdraw {
    color: $text-color;
    font-size: 12px;
    line-height: 13px;
    margin-bottom: 12px;

    & > span { font-weight: 700; }
  }
}
</style>
