<template>
  <div class="award-details">
    <div class="title-header">
      <c-title
        class="title"
      >
        Detalhes do pedido
      </c-title>
    </div>
    <transition name="fade">
      <c-spinner v-if="loading" />
      <div
        class="content"
        v-else
      >
        <main>
          <order-card
            class="order-card"
            :order-details="order"
            :disable-cancel-action="disableCancelFunction"
            @order-cancel="openCancelModal = true"
          />
        </main>

        <aside>
          <payment-card
            class="payment-card"
            :total="order.value"
            :bill-link="order.bill_link"
          />
          <historic-card
            is-wallet
            :status="order.status"
          />
        </aside>
      </div>
    </transition>

    <c-confirm-modal
      :btn-props="{ primary: true }"
      confirm-text="Cancelar pedido"
      character="embarrassed"
      title="Tem certeza que deseja cancelar seu pedido?"
      message="Esse pedido de premiação não será processado"
      :is-opened="openCancelModal"
      @close="openCancelModal = false"
      @confirm="cancelOrder(); openCancelModal = false"
    />
  </div>
</template>

<script>
import PaymentCard from '@/components/General/cards/PaymentCard'
import HistoricCard from '@/components/General/cards/HistoricCard'
import CConfirmModal from '@/components/CComponents/CConfirmModal'
import OrderCard from '@/components/User/Wallet/components/OrderCard.vue'
import { USER_WALLET_ORDER_DETAIL, USER_CANCEL_WALLET_ORDER } from '@/store/types'
import { mapActions } from 'vuex'
export default {
  components: {
    OrderCard,
    PaymentCard,
    HistoricCard,
    CConfirmModal
  },
  data () {
    return {
      order: {},
      openCancelModal: false,
      loading: true,
      cols: ['email', 'cpf', 'valor']
    }
  },

  computed: {
    disableCancelFunction () {
      return this.order.status === 0 || this.order.status === 1 || this.order.status === 4
    }
  },

  async mounted () {
    this.order = await this.getOrders(this.$route.params.orderId)
    this.loading = false
  },
  methods: {
    ...mapActions({
      getOrders: USER_WALLET_ORDER_DETAIL,
      cancelUserOrder: USER_CANCEL_WALLET_ORDER
    }),
    handleLinkClick () {
      this.openAwardOrderModalInfo = false
    },
    async cancelOrder () {
      this.loading = true
      const response = await this.cancelUserOrder(this.$route.params.orderId)
      if (response.success) this.order.status = 0
      this.loading = false
    }
  }
}
</script>

<style lang="scss">
@import '~@/styles/reference';

.award-details {
  max-width: 1020px;
  margin-top: 35px;
  padding: 0 10px;
  display: flex;
  flex-direction: column;

  @include desktop { @include container; }

  > .title-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    > .title {
      max-width: 200px;

    }

    > .new-award-button {
      width: 170px;
      height: 40px;
      border-radius: 30px;
      font-size: 12px;
      margin-top: 16px;
      font-weight: 400;
      display: flex;
      align-items: center;
      justify-content: center;

      .icon {
        position: relative;
        font-size: 20px;
        bottom: 0;
        left: 0;
        margin-right: 5px;
      }
      .text-icon {
        margin-left: 5px;
        font-size: 30px;
        font-weight: bold;
      }
    }
  }
  .content {
    display: flex;
    justify-content: space-between;

    @include tablet { flex-direction: column; }

    @include mobile { flex-direction: column; }

    main {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      width: 100%;
      margin-right: 50px;
      margin-bottom: 20px;

      .campaign-info {
        margin-top: 20px;
      }

      .order-card {
        display: flex;
        width: 602px;
        height: 316px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 24px;
        flex-shrink: 0;
        border-radius: 4px;
        background: var(--bsicas-base-light, #FDFDFD);
        box-shadow: 0px 2px 4px 0px rgba(94, 102, 132, 0.12);
      }
    }
    aside {
      display: flex;
      flex-direction: column;
      width: 100%;
      min-width: 300px;

      > :not(:first-child) {
        margin-top: 20px;
      }

      .payment-card {
        height: 177px;
      }

    }
  }
}
</style>
