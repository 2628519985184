<template>
  <c-popover
    class="popover"
    v-if="showPopover"
    :position="['bottom', 'left']"
    :target="target"
    v-on="$listeners"
  >
    <c-card class="markdown">
      <div class="header">
        <c-title
          class="title"
          icon="map-marker-edit"
          size="3"
        >
          Markdown
        </c-title>

        <c-button
          class="close"
          size="lg"
          flat
          icon="cross"
          @click="$emit('blur')"
        />
      </div>

      <div class="column">
        <p class="item">
          O Clube usa a ferramenta Markdown para formatação. Aqui estão as noções básicas. Consulte a sintaxe completa.
        </p>
      </div>

      <div class="column">
        <p class="title">
          Negrito
        </p>
        <p class="item">
          Quebre os ovos **cuidadosamente**.
        </p>
      </div>

      <div class="column">
        <p class="title">
          Listas
        </p>
        <p class="item br">
          Ingredientes:
        </p>

        <p class="item">
          - Ovos
        </p>
        <p class="item">
          - Óleo
        </p>
        <p class="item">
          - *Opcional*: leite
        </p>
      </div>

      <div class="column last">
        <p class="title">
          Link
        </p>
        <p class="item">
          [Clique aqui](www.teste.com)
        </p>

        <p class="link">
          Clique aqui
        </p>
      </div>
    </c-card>
  </c-popover>
</template>

<script>
import CPopover from '@/components/CComponents/CPopover'
import CCard from '@/components/CComponents/CCard'

export default {
  components: { CPopover, CCard },

  props: {
    showPopover: Boolean,
    target: [String, HTMLSpanElement, HTMLElement, Object]
  }
}
</script>
