<template>
  <c-modal
    class="user-import-confirmation"
    name="user-import-confirmation"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <div class="heading">
      <c-title
        class="title"
        :icon="!status.type ? 'cw' : 'import'"
      >
        Confirmação de {{ !isNaN(status.type) && !status.type ? 'atualização' : 'importação' }}
      </c-title>

      <p class="text">
        Seu arquivo {{ isManual ? 'foi' : 'será' }} importado.
        Confira o que {{ isManual ? 'aconteceu' : 'vai acontecer' }}:
      </p>
    </div>

    <user-import-confirmation-information
      class="information"
      v-for="(quantity, property) in status"
      v-if="!status.type || additionProperties.includes(property)"
      :key="property"
      :quantity="quantity"
      :property="property"
      v-bind="$attrs"
    />

    <user-import-confirmation-total
      v-if="status.file"
      :total="status.file"
    />

    <div class="actions">
      <template v-if="isConfirmation">
        <c-button
          size="lg"
          class="button -cancel"
          @click="$emit('cancel-import')"
        >
          Cancelar
        </c-button>
        <c-button
          primary
          size="lg"
          icon-size="sm"
          icon="chevron-right"
          class="button -confirm"
          @click="$emit('confirm')"
        >
          OK
        </c-button>
      </template>

      <c-button
        v-else
        primary
        size="lg"
        icon-size="sm"
        icon="chevron-right"
        class="button -procceed"
        @click="$emit('procceed')"
      >
        OK
      </c-button>
    </div>
  </c-modal>
</template>

<script>
const additionProperties = ['kept', 'invalid', 'created']

export default {
  components: {
    CModal: () => import('@/components/CComponents/CModal'),
    UserImportConfirmationInformation: () => import('@/components/User/Import/UserImportConfirmationInformation'),
    UserImportConfirmationTotal: () => import('@/components/User/Import/UserImportConfirmationTotal')
  },
  props: {
    isManual: Boolean,
    isConfirmation: Boolean,
    status: {
      type: Object,
      required: true
    }
  },
  data () { return { additionProperties } }
}
</script>

<style lang="scss">
@import '~@/styles/reference';

.user-import-confirmation {
  background-color: white;
  @include desktop { &, &.-fullscreen { max-width: 445px !important; } }

  & > .content {
    display: flex;
    flex-flow: column nowrap;
    margin-bottom: 10px;
    justify-content: stretch;

    & > .heading {
      margin-bottom: 45px;

      & > .title { margin-bottom: 20px; }
      & > .text { color: rgba($base-color, .8); }
    }

    & > .information {
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: center;
      padding: 10px 0;

      &:not(:nth-child(2)) { border-top: $border; }
    }

    & > .actions {
      display: flex;
      flex-flow: row wrap;
      justify-content: center;
      margin-top: 20px;

      & > .button:not(:only-child) { flex-grow: 1; }
      & > .button:only-child { min-width: 100px; }

      & > * + * { margin-left: 10px; }
    }
  }
}
</style>
