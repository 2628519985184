<template>
  <NewCampaignContainer />
</template>

<script>
import NewCampaignContainer from '~/containers/Engagement/pages/NewCampaignContainer'
export default {
  components: { NewCampaignContainer }
}
</script>

<style>
</style>
