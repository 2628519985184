<template>
  <div :class="containerHeight">
    <details-card title="Pagamento do pedido">
      <template #content>
        <span class="info">
          <instruction
            :step="1"
            text="Escolha o método de pagamento"
          />
          <div
            v-if="enableWallet"
            data-cy="payment-wallet-card"
            class="payment-wallet"
          >
            <div
              disabledWallet
              :class="walletBalance === 0 ? 'disabledWallet type' : 'type'"
            >
              <span>
                <k-icon
                  icon="wallet-circle"
                  size="20"
                  class="icon"
                />
                Carteira
              </span>
              <c-toggle
                round
                class="toggle"
                :disabled="loading || walletBalance === 0"
                :value="walletToggle"
                :height="32"
                :width="60"
                @change="walletToggle = !walletToggle, $emit('use-balance', walletToggle)"
              />

            </div>
            <div :class="walletBalance === 0 ? 'disabledWallet type' : ''">
              Saldo: <b>{{ formatedBalance }}</b>
            </div>
          </div>
          <div
            data-cy="payment-type-card"
            class="payment-type"
          >
            <div class="type">
              <c-checkbox
                round
                :value="useTicket"
              />
              <k-icon
                icon="scaner-barcode-select"
                size="20"
                class="icon"
              />Boleto bancário
            </div>
            <div class="description">
              O boleto será gerado após a conclusão do pedido
              <k-icon
                data-cy="payment-type-info-button"
                class="icon"
                icon="question"
                @click.native="$emit('show-invoice-modal')"
              />
            </div>
          </div>
          <instruction
            :step="2"
            :text="stepTwoText"
          />
        </span>
        <div class="actions">
          <c-input
            data-cy="order-date-input"
            v-model="startDate"
            class="input"
            icon="calendar"
            validation-icon="warning-circle"
            placeholder="Escolher data"
            auto
            required
            name="start_date"
            autocomplete="off"
            ref="startDateRef"
            watch
            mask="##/##/####"
            :feedback-show="!isDateValid"
            feedback-message="Data inválida!"
            @click.native="showStartDate = true"
          />

          <c-popover
            v-if="showStartDate"
            :position="['top', 'center']"
            :target="$refs.startDateRef"
            @blur="showStartDate = false"
          >
            <c-date
              inline
              monday-first
              class="datepicker input"
              data-cy="startDate"
              :calendar-class="'calendar'"
              :language="ptBR"
              :value="startDate"
              :disabled-dates="disabledDates"
              @input="handleSelectNewDate($event)"
            />
          </c-popover>
        </div>
        <div class="list">
          <span>Valor do pedido</span>
          <h3>{{ formatCurrency(total) }}</h3>
        </div>
        <div
          class="list"
          v-if="walletToggle"
        >
          <span>
            Desconto da carteira
          </span>
          <span>
            -{{ totalDiscount }}
          </span>
        </div>
        <div class="list">
          <span>
            Taxa de serviço
          </span>
          <span>
            {{ formatedServiceCharge }}
          </span>
        </div>
        <div
          data-cy="order-total-info"
          class="resume"
        >
          <span>Total a pagar</span>
          <span
            data-cy="total-value"
            class="price"
          >{{ formatCurrency(mathTotal) }}</span>
        </div>
        <c-button
          data-cy="conclude-order-button"
          class="button"
          primary
          :disabled="!startDate || loading"
          @click="$emit('create-order')"
        >
          Concluir pedido
        </c-button>
      </template>
    </details-card>
  </div>
</template>

<script>
import { ptBR } from 'vuejs-datepicker/dist/locale'
import { KIcon } from 'kaledo-components'
import DetailsCard from './DetailsCard'
import Instruction from '@/components/General/Instruction.vue'
import CDate from 'vuejs-datepicker'
import CCheckbox from '@/components/CComponents/CCheckbox'
import CPopover from '@/components/CComponents/CPopover'
import moment from 'moment'
import CToggle from '@/components/CComponents/CToggle.vue'

export default {
  components: {
    KIcon,
    DetailsCard,
    Instruction,
    CDate,
    CCheckbox,
    CPopover,
    CToggle
  },
  props: {
    total: Number,
    loading: Boolean,
    isAwards: Boolean,
    enableWallet: Boolean,
    walletBalance: {
      type: Number,
      required: true
    }
  },
  data () {
    return {
      startDate: null,
      showStartDate: false,
      walletToggle: false,
      ptBR
    }
  },
  computed: {
    useTicket () {
      if (!this.walletToggle) return true
      return (this.walletBalance / 100) < this.total
    },
    tax () {
      return process.env.ORDER_CHARGE_PAYMENT_SLIP
    },
    formatedBalance () {
      if (!this.walletBalance) return 'R$ 0,00'
      return (this.walletBalance / 100).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
    },
    totalDiscount () {
      if (!this.walletBalance) return 'R$ 0,00'
      if (this.walletBalance / 100 >= this.total) return this.total.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
      return (this.walletBalance / 100).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
    },
    serviceCharge () {
      if (!this.walletToggle) return (this.total * process.env.ORDER_CHARGE_PAYMENT_SLIP)
      if (this.walletBalance / 100 >= this.total) return 0
      return (this.total - (this.walletBalance / 100)) * process.env.ORDER_CHARGE_PAYMENT_SLIP
    },
    formatedServiceCharge () {
      const formatDecimal = parseFloat(this.serviceCharge.toFixed(2))
      return this.formatCurrency(formatDecimal)
    },
    disabledDates () {
      const day = moment().get('date')
      const month = moment().get('month')
      const year = moment().get('year')

      return {
        to: new Date(year, month, day)
      }
    },
    containerHeight () {
      let classes = 'payment-card'
      if (this.walletToggle && this.enableWallet) classes = `activeWalletHeight ${classes}`
      else classes = `walletHeight ${classes}`
      return classes
    },
    mathTotal () {
      let newTotal = this.total + this.serviceCharge
      if (this.walletToggle) {
        newTotal = this.total - (this.walletBalance / 100) + this.serviceCharge
        if (Math.sign(newTotal) === -1) newTotal = 0
      }
      return newTotal
    },

    isDateValid () {
      return !this.startDate || moment(this.startDate, 'DD/MM/YYYY', true).isValid()
    },
    stepTwoText () {
      return this.isAwards ? 'Escolha o dia que o prêmio será entregue para as pessoas premiadas.' : 'Escolha o dia que as moedas serão entregues.'
    }
  },
  methods: {
    formatCurrency (value) {
      return value.toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL'
      })
    },
    handleSelectNewDate (date) {
      this.showStartDate = false
      this.startDate = moment(date).format('DD/MM/YYYY')
      const emitDate = new Date(moment(date).format('MM/DD/YYYY'))
      this.$emit('changed-date', emitDate)
    }
  }
}
</script>

<style lang="scss" scoped>

.activeWalletHeight {
  height: 700px !important;
}
.walletHeight {
  height: 660px !important;
}

.payment-card {
  height: 455px;
  .list {
    display: flex;
    margin: 8px 10px;
    justify-content: space-between;
      h3 {
      color:#5E6684;
      font-size: 16px;
      font-weight: 600;
    }
  }
  .details-card {
    width: 378px;
    height: 100%;

    :deep .details-content {
      display: flex;
      flex-direction: column;
      justify-content: flex-end;

      > .info {
        display: flex;
        flex-direction: column;
        padding-top: 24px;

        > .title {
          font-size: 16px;
          line-height: 24px;
          color: #5E6684;
          display: flex;
        }
        > .payment-wallet {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          padding: 8px;
          gap: 8px;
          width: 346px;
          height: 96px;
          background: #FFFFFF;
          border: 1px solid #BEC0D3;
          border-radius: 4px;
          margin-top: 16px;
          > .disabledWallet {
            color: rgba(32,41,80,0.15) !important;
          }
          > .type {
            display: flex;
            justify-content: space-between;
            width: 100%;
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            line-height: 16px;
            text-transform: uppercase;
            color: #202950;

            > .toggle :deep .core .button {
              margin-top: 0px !important;
            }
          }
        }
        > .payment-type {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          padding: 8px;
          gap: 8px;
          width: 346px;
          height: 72px;
          background: #FFFFFF;
          border: 1px solid #BEC0D3;
          border-radius: 4px;
          margin: 16px 0;
          margin-bottom: 20px;

          > .type {
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            line-height: 16px;
            text-transform: uppercase;
            color: #202950;
            display: flex;
            align-items: center;
            .icon {
              padding: 0 8px;
            }
          }
          > .description {
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 14px;
            color: #5E6684;
            display: flex;
            justify-content: flex-start;
            align-items: center;

            .icon {
              font-size: 20px;
              margin-left: 5px;
              cursor: pointer;
              transition: all .1s ease;

              &:hover {
                font-weight: 700;
              }
            }
          }
        }
      }

      .actions {
        margin-top: 22px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 32px;

        .action {
          font-size: 14px;
          cursor: pointer;
          color: #608CEA;
          text-decoration-line: underline;
        }
      }
      .resume {
        margin-top: 15px;
        padding: 8px 10px;
        display: flex;
        border-top: 1px solid #D8D8D8;
        border-bottom: 1px solid #D8D8D8;
        justify-content: space-between;
        align-items: center;
        background: #F8F9FD;
        font-weight: 600;
        font-size: 16px;
        text-transform: uppercase;
        color: #202950;
        margin-bottom: 20px;
        .price {
          font-weight: 700;
        }
      }
      .button {
        width: 346px;
        height: 40px;
        border-radius: 30px;
      }
    }
  }
}
</style>
