<template>
  <div
    data-cy="campaign-details-card"
    class="campaign-details-card"
  >
    <img
      v-if="image"
      ref="previewImage"
      class="campaign-image"
      :src="isImageLink ? image : require('@/assets/img/engagement/campaign-img.png')"
      alt="Imagem da campanha"
    />
    <div
      v-else
      class="campaign-image div"
    >
      <k-icon
        class="icon"
        icon="image-picture-add"
        size="20"
      />
      <span>Imagem</span>
    </div>
    <div class="content">
      <h3>{{ name }}</h3>
      <div class="description">
        <h4>Descrição: </h4>
        <span>{{ description }} </span>
      </div>
      <h4>Recompensa: </h4>
      <div class="footer">
        <span
          v-if="coin"
          class="reward-info"
        >
          <img
            class="coin-logo"
            :src="coin.program_image"
          />
          <strong>{{ value }}</strong> {{ coin.program_name }}
        </span>
        <span
          v-if="endDate"
          class="date-info"
        >
          Até <br />{{ endDate }}
        </span>
      </div>
    </div>
  </div>
</template>
<script>
import { KIcon } from 'kaledo-components'
import { mapGetters, mapActions } from 'vuex'
import { COIN, GET_COIN } from '@/store/types'

export default {
  components: {
    KIcon
  },
  props: {
    image: String,
    name: String,
    description: String,
    value: [String, Number],
    endDate: String
  },
  computed: {
    ...mapGetters({
      coin: COIN
    }),
    isImageLink () {
      if (this.image.includes('localhost:3333')) return true

      const blobUrlRegex = /^blob:([a-z\d+-.]+:)(\/\/[^\/]+\/)?([^\s]+)$/i
      const imageRegex = /^(blob:|)(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/g

      return imageRegex.test(this.image) || blobUrlRegex.test(this.image)
    }
  },
  async mounted () {
    if (!this.coin || this.coin.program_name === '' || this.coin.program_image === '' || window.Cypress) {
      await this.getCoin()
    }
  },
  methods: {
    ...mapActions({
      getCoin: GET_COIN
    })
  }
}
</script>

<style lang="scss" scoped>
.campaign-details-card {
  background: #FDFDFD;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0px;
  width: 300px;
  height: 362px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.10);
  border-radius: 4px;

  .campaign-image {
    width: 300px;
    height: 130px;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;

    &.div {
      display: flex;
      justify-content: center;
      align-items: center;
      background: #D8D8D8;
      color: #202950;
      font-weight: 600;
      font-size: 18px;

      .icon {
        margin-right: 8px;
      }
    }
  }
  .content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 16px;
    margin: auto 0;

    h3 {
      font-size: 16px;
      line-height: 20px;
      color: #121E48;
      margin-bottom: 24px;
    }
    h4 {
      font-size: 12px;
      line-height: 18px;
      color: #121E48;
      margin-bottom: 4px;
    }
    span {
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      color: #202950;
    }
    .description {
      margin-bottom: 24px;
    }
    .coin-logo {
      width: 24px;
      height: 24px;
      object-fit: cover;
    }
    .footer {
      width: 100%;
      height: 24px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .reward-info {
        display: flex;
        align-items: center;
        gap: 4px;
      }
      .date-info {
        display: flex;
        color: #5E6684;
        font-size: 12px;
        text-align: right;

        .icon {
          margin-right: 4px;
          color: #5E6684;
        }
      }
    }
  }
}
</style>
