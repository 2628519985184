<template>
  <div class="manual-mode">
    <instruction
      :step="2"
      text="Insira o CPF e o email do usuário"
    />
    <form
      ref="manualForm"
      class="form"
      @submit.prevent="sendUser"
    >
      <c-input
        data-cy="manual-mode-user-document-input"
        class="input input-cpf"
        name="CPF"
        placeholder="Insira um CPF"
        :mask="'###.###.###-##'"
        data-vv-as="CPF"
        type="text"
        watch
        :feedback-show="errors.has('CPF') || isDocumentInvalid"
        :feedback-message="errors.first('CPF') || 'CPF inválido'"
        v-model="document"
      />
      <c-input
        data-cy="manual-mode-user-email-input"
        placeholder="Insira um email"
        type="email"
        class="input input-email"
        name="email"
        watch
        data-vv-as="e-mail"
        v-validate="{ regex: /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/ }"
        data-vv-delay="10"
        :feedback-show="errors.has('email')"
        :feedback-message="errors.first('email')"
        v-model="email"
      />
      <c-button
        data-cy="manual-mode-user-insert-button"
        primary
        :disabled="disableSendButton"
        class="send-button"
        icon="chevron-right"
        type="submit"
      >
        Enviar
      </c-button>
    </form>
    <div class="content">
      <c-chip
        data-cy="users-count-chip"
        text="Usuários inseridos:"
        :value="rows.length"
      />
      <users-table
        data-cy="manual-mode-users-table"
        :paginate="paginate"
        :rows="totalUsers > 0 ? modifiedRows : emptyrows"
        :selected-rows="allSelectedRows"
        @select="selectRow"
        @select-all-rows="selectAllRows"
        @search="searchTable"
        @remove-items="openDeleteModal = true"
        @update-paginate="updatePaginate"
      />
    </div>
    <delete-users-modal
      :is-opened="openDeleteModal"
      :users="allSelectedRows"
      @close="openDeleteModal = false"
      @delete-users="removeUsers"
    />
  </div>
</template>

<script>
import { isCPF } from '@/modules/validate/validators.js'
import Instruction from '@/components/General/Instruction.vue'
import CChip from '@/components/CComponents/CChip.vue'
import UsersTable from '@/components/General/tables/UsersTable'
import UsersTableMixin from './users-table-mixin.js'
import DeleteUsersModal from '@/components/General/modals/DeleteUsersModal.vue'

export default {
  name: 'ManualMode',
  components: {
    Instruction,
    CChip,
    UsersTable,
    DeleteUsersModal
  },
  mixins: [UsersTableMixin],
  props: {
    dataRows: {
      type: Array,
      default () { return [] }
    },
    mode: String
  },
  data () {
    return {
      touched: false,
      document: '',
      email: '',
      totalUsers: 0,
      openDeleteModal: false,
      selectedRows: [],
      openModal: true,
      rows: [],
      modifiedRows: [],
      filteredRows: []
    }
  },
  computed: {
    isDirty () {
      if (this.touched) return true
      this.touched = !!this.document || !!this.email
      return this.touched
    },
    isFormInvalid () {
      return this.isDirty && this.document.length !== 14
    },
    isDocumentInvalid () {
      const isFieldInvalid = this.isDirty && this.document.length !== 14
      return isFieldInvalid
    },
    isEmailValid () {
      return this.email.length > 0 && !!(this.email.match(/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/))
    },
    disableSendButton () {
      return this.isDocumentInvalid || !this.isEmailValid
    }
  },
  mounted () {
    this.$emit('change-first-access', false)
    if (this.dataRows.length > 0) {
      this.rows = this.dataRows
      this.modifiedRows = this.dataRows
      this.totalUsers = this.dataRows.length
      const to = this.totalUsers > 5 ? 5 : this.totalUsers
      this.paginate = {
        ...this.paginate,
        per_page: 5,
        to,
        total: this.totalUsers
      }
    }
  },
  methods: {
    userAlreadyThere () {
      if (this.rows.length === 0) return false
      const result = this.rows.find(object => {
        const objDocument = object.document.trim().replaceAll('.', '').replaceAll('-', '')
        const document = this.document.trim().replaceAll('.', '').replaceAll('-', '')
        return objDocument === document || object.email === this.email
      })
      if (typeof result !== 'undefined') {
        this.feedback({
          type: 'error',
          title: 'Usuários duplicados',
          text: 'Já existe um usuário com o mesmo email ou cpf inserido'
        })
        return true
      }
      return false
    },
    cleanFields () {
      this.document = ''
      this.email = ''
      this.$refs.manualForm.reset()
      this.touched = false
    },
    sendUser () {
      if (this.userAlreadyThere()) return false
      const document = this.document.trim().replaceAll('.', '').replaceAll('-', '')
      if (!isCPF(document)) {
        this.feedback({
          type: 'error',
          title: 'CPF Inválido',
          text: 'Por favor, insira um cpf válido'
        })
        return false
      }

      const newRow = {
        id: document,
        document,
        email: this.email,
        checked: false
      }
      this.rows.push(newRow)
      this.totalUsers += 1
      this.paginate = {
        current_page: 1,
        from: 1,
        per_page: 5,
        to: this.totalUsers < 5 ? this.totalUsers : 5,
        total: this.totalUsers
      }
      this.cleanFields()
    }
  }
}
</script>

<style lang='scss' scoped>
@import '~@/styles/reference';

.manual-mode {
  .form {
    margin-top: 28px;
    display: flex;
    align-items: center;
    .input {
      max-width: 343px;
      width: 100%;
      margin-right: 16px;
      border-radius: 4px;
    }
    .send-button {
      max-width: 160px;
      width: 100%;
      height: 40px;
      border-radius: 30px;
    }
  }
  .content {
    margin-top: 52px;
  }
}
</style>
