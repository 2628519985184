<template>
  <div>
    <div class="user-import-form">
      <div class="documents">
        <c-input
          class="document"
          v-for="item in availableSpaces"
          :key="`update-import-form-${item}`"
          placeholder="Insira um CPF"
          mask="###.###.###-##"
          @input="value => $emit('update-document', {
            index: item - 1,
            value
          })"
        />
        <c-input
          class="document"
          v-for="item in (10 - availableSpaces)"
          :key="item"
          placeholder="Insira um CPF"
          mask="###.###.###-##"
          disabled
        />
      </div>
    </div>
    <p
      class="trial-warning"
      data-cy="trial-warning"
      v-if="company.trial"
    >
      <k-icon
        icon="lock3"
        size="20px"
        class="icon"
      />
      <span class="text">Usuários convidados restantes: <b>{{ availableSpaces }}</b></span>
    </p>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { KIcon } from 'kaledo-components'
import { COMPANY, USER_COUNT } from '@/store/types'

export default {
  components: {
    KIcon
  },
  computed: {
    ...mapGetters({
      company: COMPANY,
      userCount: USER_COUNT
    }),
    availableSpaces () {
      if (!this.company.trial) return 10
      return 11 - parseInt(this.userCount.total_users)
    }
  },
  beforeDestroy () {
    this.$emit('update-documents', [])
  }
}
</script>

<style lang="scss">
@import '~@/styles/reference';

.user-import-form {
  display: flex;
  justify-content: center;
  align-items: center;

  & > .documents {
    max-width: 710px;
    margin: {
      left: -5px;
      right: -5px;
    }
    display: flex;
    flex-flow: row wrap;
    @include mobile { margin-bottom: 60px; }

    & > .document {
      margin: 0 5px 5px;
      flex: 100% 1 0;

      @include desktop { flex-basis: calc(50% - 10px); }
    }
  }

}
.trial-warning {
  width: 710px;
  margin: 24px auto;
  color: #00B2E2;
  font-size: 16px;
  font-weight: 700;
}
</style>
