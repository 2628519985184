<template>
  <div class="notification-details">
    <div class="title-header">
      <c-title
        class="title"
      >
        Detalhes da notificação
      </c-title>
      <c-toggle
        v-if="!loading"
        data-cy="status-toggle"
        active-color="#202950"
        :disabled="statusLoading"
        :height="32"
        :width="100"
        :labels="{ checked: 'Ativa', unchecked: 'Inativa' }"
        :value="status"
        @input="toggleStatus"
      />
    </div>
    <transition name="fade">
      <c-spinner v-if="loading" />
      <div v-else>
        <div
          class="content"
        >
          <notification-details-card
            class="details"
            :notification="notification"
            :partner="partner"
            :campaign="campaign"
          />
          <notification-visualization-card
            class="visualization"
            :notification="notification"
          />
        </div>
        <div class="footer">
          <button
            data-cy="notification-edit-button"
            class="notification-edit-button flex"
            @click="$emit('edit')"
          >
            <k-icon
              data-cy="notification-edit-icon"
              class="notification-edit-icon"
              icon="pen-edit17"
            />
            Editar notificação
          </button>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import NotificationDetailsCard from './components/NotificationDetailsCard'
import NotificationVisualizationCard from './components/NotificationVisualizationCard'
import { KIcon } from 'kaledo-components'
import { timestampToOnlyDate } from '@/modules/validate/helpers.js'
import moment from 'moment'

export default {
  components: {
    NotificationDetailsCard,
    NotificationVisualizationCard,
    KIcon
  },
  props: {
    loading: Boolean,
    statusLoading: Boolean,
    error: Boolean,
    notification: {
      type: Object,
      default () { return {} }
    },
    campaign: {
      type: Object,
      default () { return {} }
    },
    partner: {
      type: Object,
      default () { return {} }
    }
  },
  data () {
    return {
      localStatus: false
    }
  },
  computed: {
    status () {
      if (!this.notification || !this.notification.end_date) return false
      const today = moment(new Date(), 'DD/MM/YYYY')
      const formatedEndDate = moment(timestampToOnlyDate(this.notification.end_date), 'DD/MM/YYYY')

      return formatedEndDate.isSameOrAfter(today)
    }
  },
  methods: {
    toggleStatus (ev) {
      this.$emit('toggle-status', !this.status)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/styles/reference';

.notification-details {
  max-width: 1020px;
  margin-top: 35px;
  padding: 0 10px;
  display: flex;
  flex-direction: column;

  @include desktop { @include container; }

  > .title-header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    > .title {
      max-width: 200px;
    }
  }
  .content {
    display: flex;
    justify-content: space-between;

    @include tablet { flex-direction: column; }

    @include mobile { flex-direction: column; }

    .details {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      width: 100%;
      margin-right: 40px;
      margin-bottom: 24px;
    }
    .visualization {
      display: flex;
      flex-direction: column;
      width: 100%;
      min-width: 300px;
      height: 100%;

      > :not(:first-child) {
        margin-top: 20px;
      }
    }
  }
  .footer {
    .notification-edit-button {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 193px;
      height: 40px;
      flex-shrink: 0;
      border: 1px solid #5E6684;
      border-radius: 32px;
      background: white;
      text-transform: uppercase;
      font-size: 12px;
      padding: 6px 16px;
      color: #5E6684;
      cursor: pointer;
      transition: all ease 0.3s;
      .notification-edit-icon {
        font-size: 20px;
        margin-right: 4px;
      }
      &:hover {
        background: #5E6684;
        color: white;
      }
    }
  }
}
</style>
