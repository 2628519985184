<template>
  <layout-container>
    <nuxt />
  </layout-container>
</template>

<script>
import * as types from '@/store/types'
import { mapActions, mapGetters } from 'vuex'
import LayoutContainer from '@/containers/Layout/LayoutContainer'

export default {
  name: 'Default',
  components: { LayoutContainer },

  middleware: 'auth',

  computed: mapGetters({
    personalization: types.CONFIG_PERSONALIZATION,
    company: types.COMPANY,
    user: types.USER
  }),
  created () {
    require('../analytics')
    /* this.$zendesk.show() */
  },
  async mounted () {
    await this.getCompany()
    await this.getPersonalization()
    await this.getAccess()
  },
  methods: mapActions({
    getCompany: types.COMPANY,
    getPersonalization: types.CONFIG_PERSONALIZATION,
    getAccess: types.AUTH_ACCESS_CLUB
  })
}
</script>

<style lang="scss" src="@/styles/style.scss" />
