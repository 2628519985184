<template>
  <div class="address-card">
    <c-card class="address-card-header">
      <div class="address-info">
        <span class="info-street">{{ `${street}, ${streetNumber}` }}</span>
        <span class="info-city">{{ city }}</span>
      </div>

      <div class="address-actions">
        <c-button
          :icon="isActive ? 'chevron-up' : 'chevron-down'"
          class="btn-action"
          icon-size="md"
          size="lg"
          @click="$emit('toggle-details', id)"
        />

        <c-button
          icon="trash"
          class="btn-action"
          icon-size="md"
          size="lg"
          @click="$emit('delete-address', { id })"
        />
      </div>
    </c-card>

    <c-card :class="[ 'address-card-content', { '-is-open': isActive } ]">
      <div class="section-controls">
        <div class="control-group">
          <c-input
            watch
            label="CEP"
            placeholder="00000-000"
            mask="#####-###"
            class="form-control -inline -short"
            :value="code"
            @input="$emit('update', { id, property: { code: $event } })"
          />

          <c-input
            watch
            required
            label="Endereço"
            placeholder="Digite o endereço"
            class="form-control"
            :value="street"
            :disabled="!isFormValid"
            @input="$emit('update', { id, property: { street: $event } })"

            name="address"
            data-vv-delay="10"
            v-validate="'required'"
            feedback-message="Campo obrigatório"
            :feedback-show="errors.has('address')"
          />
        </div>

        <div class="control-group">
          <c-input
            watch
            required
            label="Número"
            placeholder="00000"
            mask="#####"
            class="form-control -inline -short"
            :value="streetNumber"
            :disabled="!isFormValid"
            @input="$emit('update', { id, property: { number: $event } })"

            data-vv-delay="10"
            v-validate="'required'"
            name="number"

            feedback-message="Campo obrigatório"
            :feedback-show="errors.has('number')"
          />

          <div class="form-inline">
            <c-input
              watch
              required
              label="Cidade"
              class="form-control -inline -short"
              placeholder="Digite a cidade"
              :value="city"
              :disabled="!isFormValid"
              @input="$emit('update', { id, property: { city: $event } })"

              name="city"
              data-vv-delay="10"
              v-validate="'required'"
              feedback-message="Campo obrigatório"
              :feedback-show="errors.has('city')"
            />

            <c-input
              watch
              required
              label="Estado"
              placeholder="Digite o estado"
              class="form-control -inline -short"
              :value="state"
              :disabled="!isFormValid"
              @input="$emit('update', { id, property: { state: $event } })"

              name="state"
              data-vv-delay="10"
              v-validate="'required'"
              feedback-message="Campo obrigatório"
              :feedback-show="errors.has('state')"
            />
          </div>
        </div>
      </div>
    </c-card>
  </div>
</template>

<script>
import CCard from '@/components/CComponents/CCard'

export default {
  components: { CCard },
  props: {
    isActive: {
      type: Boolean,
      default: false
    },
    number: {
      type: String,
      required: false
    },
    index: Number,
    street: {
      type: String,
      required: false
    },
    state: {
      type: String,
      required: false
    },
    code: {
      type: String,
      required: false
    },
    city: {
      type: String,
      required: false
    },
    id: {
      type: String,
      required: false
    }
  },
  computed: {
    streetNumber () {
      return this.number ? this.number.replace(/\D+/g, '') : ''
    },
    isFormValid () {
      return this.code.length === 9
    }
  }
}
</script>

<style lang="scss">
@import '~@/styles/reference';

$address-card-content-bg: #FFF;

.address-card {
  width: 100%;
  &:not(:last-child) { margin-bottom: 10px; }

  & > .address-card-header {
    position: relative;
    z-index: 10;

    display: flex;
    align-items: center;
    justify-content: space-between;

    padding: 15px 20px;
    width: 100%;

    & > .address-info {
      display: flex;
      flex-direction: column;

      & > .info-street {
        font-size: 12px;
        font-weight: 600;
        color: rgba($primary-color-placeholder, 0.8);

        text-transform: uppercase;
      }

      & > .info-city {
        font-size: 14px;
        color: $text-color;
      }
    }

    & > .address-actions > .btn-action:not(:last-child) { margin-right: 15px; }
  }

  & > .address-card-content {
    position: relative;
    z-index: 5;
    top: -1px;

    max-height: 0px;
    overflow: hidden;
    @include desktop { margin: 0 25px; }
    @include mobile { margin: 0 10px; }

    background-color: $address-card-content-bg;
    transition: max-height 300ms, margin 300ms;

    &.-is-open {
      max-height: 280px;
      @include mobile { max-height: 416px; }
      @include desktop { &:not(:last-child) { margin: 0 25px 20px 25px; } }
    }

    & > .section-controls {
      justify-content: center;
      flex-direction: column;
      align-items: center;

      padding: 41px 20px 40px 20px;
      @include mobile { padding-top: 26px; }

      & > .control-group {
        display: flex;
        flex-direction: row;
        justify-content: center;
        width: 100%;

        flex-shrink: 0;
        &:not(:last-child) { margin-bottom: 20px; }

        & > .form-control {
          width: 100%;
          max-width: 430px;

          @include desktop {
            &.-short { max-width: 145px; }
            &:not(:last-child) { margin-right: 105px; }
          }
        }

        & > .form-inline { display: flex; }

        & > .form-inline > .form-control {
          flex-grow: 1;
          margin-bottom: 0;
          &:not(:last-child) { margin-right: 90px; }
        }
      }

      @include mobile {
        flex-direction: column;

        & > .control-group {
          flex-direction: column;
          width: 100%;

          & > .form-control {
            max-width: 100%;
            margin-right: 0px;
            &:not(:last-child) { margin-bottom: 20px; }
          }

          & > .form-inline > .form-control {
            margin-right: 0px;
            &:not(:last-child) { margin-right: 15px !important; }
          }

          & > .form-control.-inline { margin-bottom: 25px !important; }
        }
      }
    }
  }
}
</style>
