<template>
  <div
    data-cy="notification-details-card"
    class="notification-details-card"
  >
    <h2
      data-cy="notification-details-title"
      class="title"
    >
      {{ notification.name }}
    </h2>
    <div class="content-half">
      <div
        data-cy="status-info"
        class="content"
      >
        <h4 class="info-name">
          Status
        </h4>
        <c-label
          class="status"
          :color="color"
          text-color="#0A0D1A"
        >
          {{ status }}
        </c-label>
      </div>
      <div
        data-cy="clicks-info"
        class="content"
      >
        <h4 class="info-name">
          Cliques
        </h4>
        <span class="info-value">{{ notification.reads }}</span>
      </div>
    </div>
    <div
      data-cy="start-date-info"
      class="content-half"
    >
      <div class="content">
        <h4 class="info-name">
          Data inicial
        </h4>
        <span class="info-value">{{ formateDate(notification.start_date) }}</span>
      </div>
      <div
        data-cy="end-date-info"
        class="content"
      >
        <h4 class="info-name">
          Data final
        </h4>
        <span class="info-value">{{ formateDate(notification.end_date) }}</span>
      </div>
    </div>
    <div
      v-if="partner.id && campaign.id"
      class="campaign-content"
    >
      <h4 class="info-name">
        Oferta atrelada
      </h4>
      <div class="info-value">
        <span class="partner">{{ partner.name }}</span>
        -
        <span>{{ campaign.name }}</span>
      </div>
    </div>
    <span class="created-at">Criada em {{ formateDate(notification.created_at) }}</span>
  </div>
</template>

<script>
import { timestampToOnlyDate } from '@/modules/validate/helpers.js'
import CLabel from '@/components/CComponents/CLabel'
const statuses = [
  {
    label: 'Inativo',
    color: '#FF5967'
  },
  {
    label: 'Ativo',
    color: '#3CE797'
  },
  {
    label: 'Agendado',
    color: '#FFC330'
  }
]
export default {
  components: {
    CLabel
  },
  props: {
    loading: Boolean,
    notification: {
      type: Object,
      default () { return {} }
    },
    campaign: {
      type: Object,
      default () { return {} }
    },
    partner: {
      type: Object,
      default () { return {} }
    }
  },
  computed: {
    releaseDateFormated () {
      const formatedDate = timestampToOnlyDate(this.notification.release_date)
      return String(formatedDate)
    },
    createdDateFormated () {
      const formatedDate = timestampToOnlyDate(this.notification.created_at)
      return String(formatedDate)
    },
    color () {
      if (!this.notification) return false
      const filteredStatus = statuses.filter(status => status.label === this.notification.status)
      if (!filteredStatus.length || (!this.notification.status && this.notification.status !== 0)) return '#000'
      else return filteredStatus[0].color
    },
    status () {
      if (!this.notification) return false
      const filteredStatus = statuses.filter(status => status.label === this.notification.status)
      if (!filteredStatus.length || (!this.notification.status && this.notification.status !== 0)) return 'Carregando...'
      else return filteredStatus[0].label
    }
  },
  methods: {
    formateDate (date) {
      const formatedDate = timestampToOnlyDate(date)
      return String(formatedDate)
    }
  }
}
</script>

<style lang="scss" scoped>
.notification-details-card {
  display: flex;
  width: 100%;
  max-width: 600px;
  padding: 32px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 24px;
  border-radius: 4px;
  background: #FDFDFD;
  box-shadow: 0px 2px 4px 0px rgba(94, 102, 132, 0.12);

  .title {
    color: var(--bsicas-primary-base, #202950);
    font-size: 18px;
    font-weight: 500;
    line-height: 22px;
  }
  .info-name {
    color: var(--bsicas-primary-base, #202950);
    font-size: 14px;
    line-height: 16.8px; /* 120% */
    text-transform: uppercase;
  }

  .status {
    width: 70px;
  }
  .info-value {
    color: var(--bsicas-secondary-base, #5E6684);
    font-size: 14px;
    font-weight: 400;
    line-height: 140%;
    .partner {
      color: var(--neutral-gray-200, #5E6684);
      font-size: 14px;
      font-weight: 700;
      line-height: 140%;
    }
  }
  .content-half {
    display: flex;
    justify-content: space-between;
    width: 100%;

    .content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      gap: 8px;

      &:first-of-type {
        align-items: flex-start;
        text-align: left;
      }
      &:last-of-type {
        align-items: flex-end;
        text-align: right;
      }
    }
  }
  .campaign-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
    gap: 8px;
  }
  .created-at {
    color: var(--neutral-dark-blue, #202950);
    font-size: 12px;
    font-weight: 400;
    line-height: 120%; /* 14.4px */
    letter-spacing: -0.122px;
  }
}
</style>
