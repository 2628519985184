<template>
  <div
    class="user-import-confirmation-information"
    v-if="statuses[property]"
  >
    <div class="wrapper">
      <div
        class="icon"
        :style="{
          backgroundColor: statuses[property].color,
          color: statuses[property].symbol === 'x'
            ? '#121E48'
            : 'white'
        }"
      >
        {{ statuses[property].symbol }}
      </div>
      <p class="text">
        <span class="quantity">{{ quantity }}</span>
        {{ statuses[property].description }}
      </p>
    </div>

  </div>
</template>

<script>
const statuses = {
  duplicated: {
    color: '#E7E8EC',
    symbol: 'x',
    description: 'CPFs duplicados',
  },
  invalid: {
    color: '#E7E8EC',
    symbol: 'x',
    description: 'CPFs inválidos',
  },
  existing: {
    color: '#E7E8EC',
    symbol: 'x',
    description: 'CPFs duplicados',
  },
  created: {
    color: '#0CAD50',
    symbol: '+',
    description: 'CPFs importados',
  },
  removed: {
    color: '#EE5250',
    symbol: '-',
    description: 'CPFs removidos',
  },
  kept: {
    color: '#E7E8EC',
    symbol: 'x',
    description: 'CPFs mantidos',
  },
}

export default {
  props: {
    quantity: [Number, String],
    property: String
  },
  data () {
    return {
      statuses
    }
  }
}
</script>

<style lang="scss">
@import '~@/styles/reference';

.user-import-confirmation-information {
  display: flex;
  justify-content: center;

  & > .wrapper {
    display: flex;
    min-width: 270px;
    padding-left: 40px;

    & > .icon {
      display: flex;
      align-items: center;
      justify-content: center;

      width: 20px;
      height: 20px;
      font-size: 12px;
      border-radius: 50%;
      margin-right: 10px;
    }

    & > .text > .quantity {
      display: inline-block;

      font-weight: bold;
      min-width: 35px;
      margin-right: 10px;
      text-align: center;
    }
  }
}
</style>
