<template>
  <c-modal
    class="modal"
    :show-close="false"
    :is-opened="showModal"
    :is-fullscreen="false"
    name="name-modal"
    hide-cancel
    @close="$emit('close')"
  >
    <c-title
      data-cy="modal-title"
      class="title"
    >
      Revisão das Informações
    </c-title>
    <div class="campaign-info">
      <h2>{{ campaign.title }}</h2>
      <div class="info">
        <h3>Descrição</h3>
        <span class="info-text description">
          {{ campaign.description }}
        </span>
      </div>
      <div class="dates">
        <div class="info">
          <h3>Data início</h3>
          <span class="info-text">
            {{ campaign.start_date || todayDate }}
          </span>
        </div>
        <div class="info">
          <h3>Data final</h3>
          <span class="info-text">
            {{ campaign.end_date || '-' }}
          </span>
        </div>
      </div>
      <div class="info">
        <h3>Recompensa</h3>
        <span class="info-text">
          {{ campaign.value }} moedas
        </span>
      </div>
    </div>
    <div class="campaign-preview">
      <h2>Mídia utilizada</h2>
      <img
        class="image"
        :src="imageSrc"
        alt="Imagem da campanha"
      />
    </div>
    <div class="actions">
      <c-button
        data-cy="cancel-review-button"
        class="button cancel-button"
        @click="$emit('close')"
      >
        Voltar
      </c-button>
      <c-button
        data-cy="confirm-review-button"
        primary
        :disabled="loading"
        class="button"
        @click="$emit('confirm')"
      >
        Confirmar
      </c-button>
    </div>
  </c-modal>
</template>

<script>
import moment from 'moment'
import CModal from '@/components/CComponents/CModal'
import CampaignPreviewCard from '../Campaigns/components/CampaignPreviewCard'

export default {
  components: {
    CModal,
    CampaignPreviewCard
  },
  props: {
    loading: Boolean,
    showModal: {
      type: Boolean,
      required: true
    },
    campaign: {
      type: Object,
      required: true
    }
  },
  computed: {
    todayDate () {
      return moment().format('DD/MM/YYYY')
    },
    imageSrc () {
      if (!this.campaign.image || this.campaign.image.length === 0) return ''
      return URL.createObjectURL(this.campaign.image)
    }
  }
}
</script>

<style lang="scss" scoped>
 .modal :deep .name-modal {
    margin: auto 0;
    height: 100%;
    width: 99%;
    max-height: 600px !important;
    max-width: 434px !important;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);

    &::-webkit-scrollbar {
      width: 10px
    }

    &::-webkit-scrollbar-track {
      background: #BEC0D3;
      border-radius: 16px;
    }

    &::-webkit-scrollbar-thumb {
      background: #5E6684;
      border-radius: 16px;
    }
  }
  .info-text {
    font-size: 14px;
    line-height: 17px;
    color: #5E6684;

    &:not(.description) {
      text-transform: uppercase;
    }
  }
  .campaign-info {
    display: flex;
    flex-direction: column;
    margin-top: 32px;

    h2 {
      color: #202950;
      font-size: 18px;
      margin-bottom: 24px;
    }

    h3 {
      color: #202950;
      font-size: 14px;
      text-transform: uppercase;
    }

    .info {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 8px;
      margin-bottom: 32px;
    }

    .dates {
      display: flex;
      justify-content: space-between;

      & .info:last-of-type {
        align-items: flex-end;
      }
    }
  }
  .campaign-preview {
    display: flex;
    flex-direction: column;
    margin-bottom: 32px;

    h2 {
      font-size: 18px;
      color: #202950;
      margin-bottom: 16px;
    }
    .image {
      width: 300px;
      height: 130px;
    }
  }
  .actions {
    display: flex;
    justify-content: space-between;

    .button {
      width: 190px;
      height: 40px;
      border-radius: 30px;

      &.cancel-button {
        border: 1px solid rgba(94, 102, 132, 1);
        border: 1px solid var(--color-secondary);
      }
    }
  }
</style>
