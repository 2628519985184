<template>
  <div class="container">
    <div class="title">
      <h2>Configurações de Acesso</h2><premium-tag v-if="company.trial" />
    </div>
    <div class="content">
      <h2>Ativação de Conta</h2>
      <h3>Processo onde seu usuário preenche um formulário com informações pessoais no primeiro acesso.</h3>
      <div class="form">
        <div class="label">
          Pular ativação de conta? <info-button :info="infos[0]" />
        </div>
        <div class="options">
          <div class="check">
            <c-checkbox
              :value="data.redirect_to_activation"
              round
              :disabled="company.trial"
              class="checkbox"
              @input="handleCheckbox('redirect_to_activation', true)"
            />  Sim
          </div>
          <div class="check">
            <c-checkbox
              :value="!data.redirect_to_activation"
              round
              :disabled="company.trial"
              class="checkbox"
              @input="handleCheckbox('redirect_to_activation', false)"
            />  Não
          </div>
        </div>
      </div>
      <div class="form">
        <div class="label">
          Enviar email de boas vindas? <info-button :info="infos[1]" />
        </div>
        <div class="options">
          <div class="check">
            <c-checkbox
              :value="data.email_after_activation"
              round
              class="checkbox"
              :disabled="company.trial"
              @input="handleCheckbox('email_after_activation', true)"
            />  Sim
          </div>
          <div class="check">
            <c-checkbox
              :value="!data.email_after_activation"
              @input="handleCheckbox('email_after_activation', false)"
              round
              :disabled="company.trial"
              class="checkbox"
            />  Não
          </div>
        </div>
      </div>
      <div class="form">
        <div class="label">
          Cadastrar usuários à newsletter? <info-button :info="infos[2]" />
        </div>
        <div class="options">
          <div class="check">
            <c-checkbox
              :value="data.save_users_email"
              round
              :disabled="company.trial"
              @input="handleCheckbox('save_users_email', true)"
              class="checkbox"
            />  Sim
          </div>
          <div class="check">
            <c-checkbox
              :value="!data.save_users_email"
              round
              :disabled="company.trial"
              @input="handleCheckbox('save_users_email', false)"
              class="checkbox"
            />  Não
          </div>
        </div>
      </div>
      <h2>Página de Acesso</h2>
      <h3>Página personalizada por você onde seu usuário realiza o login.</h3>
      <div class="form">
        <div class="label">
          Exibir página de acesso? <info-button :info="infos[3]" />
        </div>
        <div class="options">
          <div class="check">
            <c-checkbox
              :value="data.has_initial_page"
              round
              :disabled="company.trial"
              @input="handleCheckbox('has_initial_page', true)"
              class="checkbox"
            />  Sim
          </div>
          <div class="check">
            <c-checkbox
              :value="!data.has_initial_page"
              round
              :disabled="company.trial"
              @input="handleCheckbox('has_initial_page', false)"
              class="checkbox"
            />  Não
          </div>
        </div>
      </div>
    </div>
    <footer-buttons
      v-if="!company.trial"
      :is-processing="loading"
      @save="save()"
      @back="openConfirmationModal = true"
    />
    <trial-banner
      v-else
      @call-sales="callSales()"
    />
    <c-confirm-modal
      :btn-props="{ primary: true }"
      confirm-text="Sair sem salvar"
      character="embarrassed"
      title="Tem certeza que deseja sair sem salvar?"
      message="Se sair, todas as informações já inseridas não serão salvas."
      :is-opened="openConfirmationModal"
      @close="openConfirmationModal = false"
      @confirm="$router.push('/configuracao')"
    />
  </div>
</template>

<script>
import InfoButton from '@/components/Settings/general/InfoButton'
import PremiumTag from '@/components/Settings/general/PremiumTag.vue'
import CCheckbox from '@/components/CComponents/CCheckbox.vue'
import FooterButtons from '@/components/Settings/general/FooterButtons.vue'
import TrialBanner from '@/components/Settings/TrialBanner.vue'
import { COMPANY, COMPANY_SETTINGS, COMPANY_CALL_SALES } from '@/store/types'
import { mapGetters, mapActions } from 'vuex'
import CConfirmModal from '@/components/CComponents/CConfirmModal.vue'
export default {
  components: { PremiumTag, CCheckbox, InfoButton, FooterButtons, TrialBanner, CConfirmModal },

  data () {
    return {
      infos: [
        {
          text: 'Pular o processo de preencher o formulário no primeiro acesso'
        },
        {
          text: 'Enviar um email de boas vindas após o primeiro acesso do usuário'
        },
        {
          text: 'Cadastrar seus usuários para receber nossos emails '
        },
        {
          text: 'Exibir a página personalizada por você onde seus usuários realizam o login'
        }
      ],
      openConfirmationModal: false,
      data: {
        redirect_to_activation: false,
        email_after_activation: true,
        save_users_email: true,
        has_initial_page: true
      },
      loading: false
    }
  },
  computed: {
    ...mapGetters({
      company: COMPANY
    })
  },
  mounted () {
    const keys = ['redirect_to_activation', 'email_after_activation', 'save_users_email', 'has_initial_page']
    keys.forEach(key => {
      if (
        this.company.config[key] !== null &&
        this.company.config[key] !== undefined
      ) this.data[key] = this.company.config[key]
    })
  },

  methods: {
    ...mapActions({
      companySettings: COMPANY_SETTINGS,
      callSales: COMPANY_CALL_SALES
    }),

    handleCheckbox (field, value) {
      if (this.company.trial) return
      this.data[field] = value
    },

    async save () {
      this.loading = true
      const response = await this.companySettings(this.data)
      if (response === 'success') {
        this.$router.push('/configuracao')
      }
      this.loading = false
    }
  }

}
</script>

<style lang='scss' scoped>
@import '~@/styles/reference.scss';
.container {
  max-width: 1020px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 48px;

  & > .title {
    align-items: center;
    display: flex;
    color: $neutral-dark-blue;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Nunito Sans;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;

    & > h2 {
      margin-right: 8px;
    }
  }

  & > .content {
    & > h2 {
      color: $neutral-gray-200;
      font-feature-settings: 'clig' off, 'liga' off;
      font-family: Nunito Sans;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 140%;
      margin-bottom: 8px;
      margin-top: 48px;
    }

    & > h3 {
      color: $neutral-dark-blue;
      font-feature-settings: 'clig' off, 'liga' off;
      font-family: Nunito Sans;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%;
    }

    & > .form {
      margin-top: 24px;
      & > .label {
        display: flex;
        align-items: center;
        height: 20px;
        color: $neutral-gray-200;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: Nunito Sans;
        font-size: 11px;
        font-style: normal;
        font-weight: 400;
        letter-spacing: 0.5px;
        text-transform: uppercase;
      }

      & > .options{
        margin-top: 16px;
        display: flex;
        width: 122px;
        justify-content: space-between;

        & > .check {
          display: flex;
          color: $neutral-gray-200;
          font-feature-settings: 'clig' off, 'liga' off;
          font-family: Nunito Sans;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 140%;
          align-items: center;

          & > .checkbox {
            margin-right: 8px;
          }
        }
      }
    }
  }
}
</style>
