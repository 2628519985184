<template>
  <button
    @click="$emit('click')"
    class="dash-button"
    :class="isSelected ? 'selected' : ''"
  >
    <k-icon
      :icon="icon"
      class="icon"
    />
    <span class="label">{{ label }}</span>
  </button>
</template>

<script>
import { KIcon } from 'kaledo-components'
export default {
  name: 'DashButton',
  components: {
    KIcon
  },
  props: {
    label: String,
    icon: String,
    isSelected: Boolean
  }
}
</script>

<style lang='scss' scoped>
@import '~@/styles/reference';
.dash-button {
  background: white;
  border: 1px solid #5E6684;
  border-radius: 20px;
  padding: 12px 24px;
  line-height: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: all ease 0.3s;
  outline: none;
  .icon {
    font-size: 18px;
  }
  .label {
    font-size: 12px;
    text-transform: uppercase;
    padding-left: 4px;
  }
  @include mobile {
    padding: 12px;
    margin-bottom: 5px;
    .icon {
      font-size: 16px;
    }
    .label {
      font-size: 10px;
    }
  }
  &:hover {
    border: 2px solid #5E6684;
  }
  &:focus {
    border: 2.5px solid #202950;
  }
}
.selected {
  border: 2.5px solid #202950;
}
</style>
