<template>
  <div
    class="home-preview"
    :class="isDesktopMode ? '' : '-mobile'"
  >
    <div
      class="home-preview-container"
      v-if="isDesktopMode"
    >
      <carrousel-desktop
        v-for="(item, index) in carrouselItems"
        :key="index"
        :data="data"
        :title="item.name"
        :icon="item.icon"
      />
    </div>
    <div
      class="home-preview-container-mobile"
      v-else
    >
      <carrousel-mobile
        v-for="(item, index) in carrouselItems"
        :key="index"
        :data="data"
        :title="item.name"
        :icon="item.icon"
      />
    </div>
  </div>
</template>

<script>
import CarrouselDesktop from './CarrouselDesktop.vue'
import CarrouselMobile from './CarrouselMobile.vue'

export default {
  name: 'HomePreview',
  components: {
    CarrouselDesktop,
    CarrouselMobile
  },
  props: {
    isDesktopMode: {
      type: Boolean,
      default: true
    },
    data: {
      type: Object,
      default: () => {}
    }
  },
  data () {
    return {
      carrouselItems: [
        {
          name: 'Produtos e Serviços',
          icon: 'bag-shopping-circle'
        },
        {
          name: 'Destaques',
          icon: 'crown-square'
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/styles/reference';
.home-preview {
  background: #F8F9FD;
  height: 574px;
  &.-mobile {
    height: 574px;
  }
  .home-preview-container {
    margin: 40px 80px;
  }
  .home-preview-container-mobile {
    margin: 32px 16px;
  }
}
</style>
