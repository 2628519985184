<template>
  <div :style="'width:'+ width">
    <div
      data-cy="step-count"
      class="title"
      :style="`justify-content: ${stepWidth}`"
    >
      <span>{{ step }}/{{ steps }}</span>
    </div>
    <div class="progressBar">
      <div :class="setFirstBarClass">
        <div
          class="actualStep"
          @click="handleStepClick(1)"
        />
        <div
          :class="lastStep"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: function () {
        return 'steps'
      }
    },
    step: {
      type: Number,
      default: function () {
        return 1
      }
    },
    steps: {
      type: Number,
      default: function () {
        return 1
      }
    },
    width: {
      type: String,
      default: function () {
        return '576px'
      }
    }
  },
  computed: {
    setFirstBarClass () {
      if (this.step >= 2) return 'activeBar'
      return 'bar'
    },
    setStep () {
      if (this.step >= 2) return 'actualStep'
      return 'step'
    },
    lastStep () {
      if (this.step >= 2) return 'lastActiveStep'
      return 'lastStep'
    },
    stepWidth () {
      switch (this.step) {
      case 1:
        return 'flex-start'
      case 2:
        return 'flex-end'
      default:
        return 'flex-start'
      }
    }
  },
  methods: {
    handleStepClick (step) {
      if (step < this.step) {
        this.$emit('step-click', step)
      }
    }
  }
}
</script>

<style lang='scss' scoped>
.title{
  display: flex;
  justify-content: center;
  padding: 0.5rem 0;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  color: #5E6684;
}
.progressBar {
  display: flex;
}
.bar{
  height: 8px;
  width: 100%;
  background-color: rgba(0, 178, 227, 0.08);
  position: relative;
}
.activeBar{
  height: 8px;
  width: 100%;
  background-color: #00B2E3;
  position: relative;

  .actualStep {
    cursor: pointer;
  }
}
.step {
    height: 18px;
    width: 18px;
    background-color: #e3f9ff;;
    border-radius: 100px;
    position: absolute;
    top: -5px;
}
.actualStep {
    height: 18px;
    width: 18px;
    background-color: #00B2E3;
    border-radius: 100px;
    position: absolute;
    top: -5px;
    left: -1px;
}
.lastStep{
    height: 18px;
    width: 18px;
    background-color: #e3f9ff;
    border-radius: 100px;
    position: absolute;
    top: -5px;
    left: 100%;
}
.lastActiveStep{
    height: 18px;
    width: 18px;
    background-color: #00B2E3;
    border-radius: 100px;
    position: absolute;
    top: -5px;
    left: 100%;
}
</style>
