<template>
  <div class="club-list LoginContent">
    <div class="LoginBox">
      <c-title>Olá</c-title>
      <p class="lead">
        Encontramos seu CPF habilitado no(s) seguinte(s) clube(s).<br />
        Em qual deseja ativar seu cadastro?
      </p>

      <swiper
        class="carousel"
        :options="swiperOptions"
      >
        <swiper-slide
          v-for="(company, index) in companies"
          :key="index"
        >
          <c-button
            pink-border
            class="club-logo"
            @click.stop="$router.push({
              name: 'ativacao-company',
              params: { company: company.id }
            })"
          >
            <c-image
              class="partner-logo"
              :src="company.logo"
              :placeholder="imagePlaceholder"
            />
          </c-button>
          <p class="club-name">
            {{ company.name }}
          </p>
        </swiper-slide>
        <div
          v-if="companies.length > 3"
          class="swiper-button -prev"
          slot="button-prev"
        >
          <c-icon
            class="icon"
            icon="chevron-left"
            size="22"
          />
        </div>

        <div
          v-if="companies.length > 3"
          class="swiper-button -next"
          slot="button-next"
        >
          <c-icon
            class="icon"
            icon="chevron-right"
            size="22"
          />
        </div>
      </swiper>
      <c-button
        size="lg"
        class="btn"
        @click="$router.push({ name: 'login' })"
      >
        Cancelar
      </c-button>
    </div>
  </div>
</template>

<script>
import CImage from '@/components/CComponents/CImage'

import { swiper, swiperSlide } from 'vue-awesome-swiper'

export default {
  components: {
    CImage,
    swiper,
    swiperSlide
  },
  props: {
    companies: {
      type: Array,
      required: true
    },
    isLoading: {
      type: Boolean,
      required: true
    },
    error: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      imagePlaceholder: require('@/assets/img/campaign-placeholder.svg'),
      swiperOptions: {
        freeMode: true,
        slidesPerView: 2.5,
        slidesPerGroup: 2.5,
        navigation: {
          nextEl: '.swiper-button.-next',
          prevEl: '.swiper-button.-prev',
          disabledClass: '-disabled',
          hiddenClass: '-hidden'
        }
      }
    }
  }
}
</script>

<style lang="scss">
@import '~@/styles/reference';

.club-list.LoginContent {
  @include LoginContent;

  .LoginBox {
    background-color: #fff;
    max-width: 500px;
    min-width: 500px;
    padding: 50px;
    display: flex;
    flex-direction: column;

    border-radius: 10px;
    box-shadow: 1px 1px 4px 0 rgba(0,0,0,0.1);

    & > .lead { margin-top: 20px; }

    & >.carousel {
      margin: 0 auto;
      margin-top: 45px;
      position: relative;
      overflow: hidden;
      width: 100%;

      .swiper-wrapper {
        display: flex;
        justify-content: space-around;
      }

      .swiper-slide {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 130px;
        min-width: 100px;

        padding-top: 5px;

        & >.club-logo {
          width: 85px;
          height: 85px;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          border: 1px solid rgba(18, 30, 72, 0.1);
          border-radius: 50%;

          img { width: 45px; display: block; }
        }

        & >.club-name{
          text-align: center;
          font-size: 14px;
          font-weight: bold;
          opacity: 0.8;
          color: #121E48;
          margin-left: 20px;

          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          width: calc(100% - 15px);

          @include desktop {
            margin-left: 0;
            margin-top: 25px;
          }
        }

        & >.item {
          width: 150px;
          height: 75px;
          display: flex;
          justify-content: center;
          align-items: center;

          img { max-width: 60px; max-height: 40px; }
        }

        & >.text {
          margin-top: 5px;
          text-transform: uppercase;
          font-size: 11px;
          color: rgba(18,30,72,0.8);
        }
      }

      & > .swiper-button {
        z-index: 20;
        display: block;
        position: absolute;
        cursor: pointer;
        top: calc(50% - 15px);
        border-radius: 50%;
        width: 60px;
        height: 60px;
        transition: opacity .3s ease;

        background-color: rgba(#FFF, 0.8);
        transition: background-color 200ms;

        &:hover { background-color: #FFF; }

        & > .c-icon.icon {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
        }

        &.-disabled,
        &.-hidden {
          opacity: 0;

          & > .icon { display: none; }
        }

        &.-prev {
          left: 0;
          transform: translate(-50%, -50%);

          & > .c-icon.icon { right: 8px; }
        }

        &.-next {
          right: 0;
          transform: translate(50%, -50%);

          & > .c-icon.icon { left: 8px; }
        }
      }
    }

    & >.btn { margin-top: 20px; }
  }
}
</style>
