<template>
  <div class="fake-pagination">
    <button
      class="page-button"
      :disabled="currentPage === 1"
      @click="$emit('first-page')"
    >
      <c-icon
        icon="first-page"
        size="12"
      />
    </button>
    <button
      class="page-button"
      :disabled="currentPage === 1"
      @click="$emit('previous')"
    >
      <c-icon
        icon="chevron-left"
        size="12"
        class="icon"
      />
    </button>
    <p class="pages">
      {{ initial }}-{{ final }} de {{ total }}
    </p>
    <button
      class="page-button"
      :disabled="currentPage === lastPage"
      @click="$emit('next')"
    >
      <c-icon
        icon="chevron-right"
        size="12"
        class="icon"
      />
    </button>
    <button
      class="page-button"
      @click="$emit('last-page')"
      :disabled="currentPage === lastPage"
    >
      <c-icon
        icon="last-page"
        size="12"
      />
    </button>
  </div>
</template>

<script>
export default {
  name: 'FakePagination',
  props: {
    currentPage: Number,
    lastPage: Number,
    total: Number
  },
  computed: {
    initial () {
      if (this.currentPage === 1) return 1
      return (this.currentPage * 5) - 5
    },
    final () {
      if (this.currentPage === 1) return 5
      if (this.currentPage === this.lastPage) return this.total
      return (this.currentPage * 5)
    }
  }
}
</script>

<style lang='scss' scoped>
.fake-pagination {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 24px;
  position: absolute;
  bottom: 20px;
  right: 20px;
  .page-button {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background: none;
    border: 1px solid #D9D9D9;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #5E6684;
    cursor: pointer;
    .icon {
      fill: #5E6684;
    }
    &:disabled {
      color: #D8D8D8;
      .icon {
        fill: #D8D8D8;
      }
    }
  }
  .page-button + .page-button {
    margin-left: 8px;
  }
  .pages {
    margin: 0 8px;
    font-size: 12px;
    color: #5E6684;
  }
}
</style>
