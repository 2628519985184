import { render, staticRenderFns } from "./BlockedContainer.vue?vue&type=template&id=248ead38&scoped=true"
import script from "./BlockedContainer.vue?vue&type=script&lang=js"
export * from "./BlockedContainer.vue?vue&type=script&lang=js"
import style0 from "./BlockedContainer.vue?vue&type=style&index=0&id=248ead38&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "248ead38",
  null
  
)

export default component.exports