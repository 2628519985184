<template>
  <div class="container">
    <div class="title">
      <h2>Configurações de Logout</h2><premium-tag v-if="company.trial" />
    </div>
    <div class="content">
      <h2>Logout</h2>
      <h3>Configurações relacionadas ao botão de ”sair” do ambiente do seu clube.</h3>
      <div class="form">
        <div class="label">
          Exibir o botão de sair? <info-button :info="infos[0]" />
        </div>
        <div class="options">
          <div class="check">
            <c-checkbox
              round
              class="checkbox"
              :disabled="company.trial"
              :value="data.logout"
              @input="handleCheckbox('logout', true)"
            />  Sim
          </div>
          <div class="check">
            <c-checkbox
              round
              class="checkbox"
              :disabled="company.trial"
              :value="!data.logout"
              @input="handleCheckbox('logout', false)"
            />  Não
          </div>
        </div>
      </div>
      <div class="form">
        <div class="label">
          Redirecionar o usuário após sair? <info-button :info="infos[1]" />
        </div>
        <div class="options">
          <div class="check">
            <c-checkbox
              round
              class="checkbox"
              :disabled="company.trial"
              :value="data.redirect_logout"
              @input="handleCheckbox('redirect_logout', true)"
            />  Sim
          </div>
          <div class="check">
            <c-checkbox
              round
              class="checkbox"
              :disabled="company.trial"
              :value="!data.redirect_logout"
              @input="handleCheckbox('redirect_logout', false)"
            />  Não
          </div>
        </div>
      </div>
      <div
        class="form"
        v-if="data.redirect_logout"
      >
        <div class="label">
          URL de redirecionamento
        </div>
        <c-input
          class="input"
          placeholder="Ex: site.com.br"
          v-model="data.logout_url"
        />
      </div>
    </div>
    <footer-buttons
      v-if="!company.trial"
      :is-processing="loading || !isValid"
      @save="save()"
      @back="openConfirmationModal = true"
    />
    <trial-banner
      v-else
      @call-sales="callSales()"
    />
    <c-confirm-modal
      :btn-props="{ primary: true }"
      confirm-text="Sair sem salvar"
      character="embarrassed"
      title="Tem certeza que deseja sair sem salvar?"
      message="Se sair, todas as informações já inseridas não serão salvas."
      :is-opened="openConfirmationModal"
      @close="openConfirmationModal = false"
      @confirm="$router.push('/configuracao')"
    />
  </div>
</template>

<script>
import InfoButton from '@/components/Settings/general/InfoButton'
import PremiumTag from '@/components/Settings/general/PremiumTag.vue'
import CCheckbox from '@/components/CComponents/CCheckbox.vue'
import FooterButtons from '@/components/Settings/general/FooterButtons.vue'
import TrialBanner from '@/components/Settings/TrialBanner.vue'
import { COMPANY, COMPANY_SETTINGS, COMPANY_CALL_SALES } from '@/store/types'
import { mapGetters, mapActions } from 'vuex'
import CConfirmModal from '@/components/CComponents/CConfirmModal.vue'
export default {
  components: { PremiumTag, CCheckbox, InfoButton, FooterButtons, TrialBanner, CConfirmModal },

  data () {
    return {
      data: {
        logout: false,
        redirect_logout: false,
        logout_url: '',
        openConfirmationModal: false
      },
      loading: false,
      infos: [
        {
          title: 'Exibir o botão de sair?',
          text: 'Exibir o botão de sair do seu clube para que seus usuários saiam do seu ambiente'
        },
        {
          title: 'Redirecionar o usuários após sair?',
          text: 'Redirecionar o seu usuário para algum link definido por você após sair do seu clube'
        }
      ]
    }
  },

  computed: {
    ...mapGetters({
      company: COMPANY
    }),

    isValid () {
      const conditions = [
        this.data.logout_url.length > 5,
        this.data.logout_url.includes('.com')
      ]
      if (this.data.redirect_logout) {
        return conditions[0] && conditions[1]
      } else { return true }
    }
  },

  mounted () {
    const keys = ['redirect_logout', 'logout', 'logout_url']
    keys.forEach(key => {
      if (this.company.config[key] !== null && this.company.config[key] !== undefined) this.data[key] = this.company.config[key]
    })
  },

  methods: {
    ...mapActions({
      companySettings: COMPANY_SETTINGS,
      callSales: COMPANY_CALL_SALES
    }),

    handleCheckbox (field, value) {
      if (this.company.trial) return
      this.data[field] = value
    },

    async save () {
      this.loading = true
      if (this.data.redirect_logout) {
        !this.data.logout_url.includes('https://') ? this.data.logout_url = 'https://' + this.data.logout_url : ''
      }
      const response = await this.companySettings(this.data)
      if (response === 'success') {
        this.$router.push('/configuracao')
      }
      this.loading = false
    }
  }
}
</script>

<style lang='scss' scoped>
@import '~@/styles/reference.scss';
.container {
  max-width: 1020px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 48px;

  & > .title {
    align-items: center;
    display: flex;
    color: $neutral-dark-blue;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Nunito Sans;
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;

    & > h2 {
      margin-right: 8px;
    }
  }

  & > .content {
    & > h2 {
      color: $neutral-gray-200;
      font-feature-settings: 'clig' off, 'liga' off;
      font-family: Nunito Sans;
      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: 140%;
      margin-bottom: 8px;
      margin-top: 48px;
    }

    & > h3 {
      color: $neutral-dark-blue;
      font-feature-settings: 'clig' off, 'liga' off;
      font-family: Nunito Sans;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: 140%;
    }

    & > .form {
      margin-top: 24px;
      & > .label {
        display: flex;
        align-items: center;
        height: 20px;
        color: $neutral-gray-200;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: Nunito Sans;
        font-size: 11px;
        font-style: normal;
        font-weight: 400;
        letter-spacing: 0.5px;
        text-transform: uppercase;
      }

      & > .input {
        width: 328px;
        margin-top: 8px;
      }

      & > .options{
        margin-top: 16px;
        display: flex;
        width: 122px;
        justify-content: space-between;

        & > .check {
          display: flex;
          color: $neutral-gray-200;
          font-feature-settings: 'clig' off, 'liga' off;
          font-family: Nunito Sans;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 140%;
          align-items: center;

          & > .checkbox {
            margin-right: 8px;
          }
        }
      }
    }
  }
}
</style>
