<template>
  <engagement-new-campaigns
    :loading="loading"
    @create-campaign="handleCreateCampaign"
  />
</template>

<script>
import moment from 'moment'
import EngagementNewCampaigns from '@/components/Engagement/Campaigns/New/EngagementNewCampaigns'
import { mapGetters, mapActions } from 'vuex'
import { COIN, ENGAGEMENT_CAMPAIGNS_IMAGES, GET_ENGAGEMENT_CAMPAIGNS_IMAGES, CREATE_ENGAGEMENT_CAMPAIGN } from '@/store/types'

export default {
  components: {
    EngagementNewCampaigns
  },
  data () {
    return {
      loading: false
    }
  },
  computed: {
    ...mapGetters({
      images: ENGAGEMENT_CAMPAIGNS_IMAGES
    })
  },
  methods: {
    ...mapActions({
      getImages: GET_ENGAGEMENT_CAMPAIGNS_IMAGES,
      coinData: COIN,
      createCampaign: CREATE_ENGAGEMENT_CAMPAIGN
    }),
    async handleGetImages () {
      await this.getImages()
    },
    async handleCreateCampaign (campaign) {
      window.dataLayer.push({
        event: 'pontos-campanha-criacao-finalizar'
      })
      this.loading = true
      const initialCampaign = campaign
      if (initialCampaign.start_date === '') {
        delete initialCampaign.start_date
      } else {
        initialCampaign.start_date = moment(campaign.start_date, 'DD/MM/YYYY').format('YYYY-MM-DD')
      }
      if (initialCampaign.end_date === '') {
        delete initialCampaign.end_date
      } else {
        initialCampaign.end_date = moment(campaign.end_date, 'DD/MM/YYYY').format('YYYY-MM-DD')
      }
      const success = await this.createCampaign(campaign)

      if (success) {
        this.$router.push({ name: 'engajamento-campanhas' })
      }
      this.loading = false
    }
  }
}
</script>

<style>
</style>
