<template>
  <activate-account />
</template>

<script>
import ActivateAccount from '@/containers/Login/ActivateAccount'

export default {
  components: {
    ActivateAccount
  },
  layout: 'unauthenticated',
  middleware: 'checkauth'
}
</script>
