<template>
  <svg
    width="115"
    height="26"
    viewBox="0 0 115 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.15143 14.5615C2.75549 1.74486 0 0 0 0H115C115 0 112.435 1.74486 107.411 14.5615C102.83 26.2471 98.0539 26.0935 94.3807 25.9753C94.0252 25.9639 93.68 25.9528 93.3463 25.9528H23.2577C22.8993 25.9528 22.5285 25.9639 22.1466 25.9753C18.2014 26.0935 13.0712 26.2471 8.15143 14.5615Z"
      :fill="fill"
    />
  </svg>
</template>

<script>
export default {
  name: 'TagPreview',
  props: {
    fill: {
      type: String,
      default: '#D8D8D8'
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/styles/reference';
</style>
