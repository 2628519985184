<template>
  <c-card class="campaign-information-how">
    <div class="header">
      <c-title
        class="title"
        icon="happy"
        size="3"
      >
        Como utilizar
      </c-title>
    </div>

    <div class="steps">
      <template v-for="(step, index) in steps">
        <campaign-information-step
          :key="index"
          :step="step"
          :number="index + 1"
          :last="index + 1 === steps.length"
          v-on="$listeners"
          v-bind="$attrs"
          :voucher="index === 1 ? voucher : ''"
        />
      </template>
    </div>
  </c-card>
</template>

<script>
import CCard from '@/components/CComponents/CCard'
import CampaignInformationStep from '@/components/Campaign/Information/CampaignInformationStep'

export default {
  components: { CCard, CampaignInformationStep },

  props: {
    steps: {
      type: Array,
      required: true
    },
    voucher: {
      type: String,
      required: false
    },
    isExclusive: {
      type: Boolean,
      required: true
    }
  }
}
</script>

<style lang="scss">
.campaign-information-how {
  margin-bottom: 20px;
  padding-top: 15px;

  & > .header {
    display: flex;
    justify-content: space-between;

    padding: 0 20px;
  }

  & > .steps {
    padding: 0 10px 10px 10px;
    & > .campaign-information-step {
      margin-bottom: 10px;
    }
  }
}
</style>
