<template>
  <c-modal
    v-on="$listeners"
    :is-opened="opened"
    name="modal-login"
    class="modal-login"
    :is-fullscreen="false"
  >
    <c-title v-if="title !== undefined">
      {{ title }}
    </c-title>
    <div class="modal-content">
      <c-character
        v-if="!hideCharacter"
        v-bind="$attrs"
      />
      <p
        class="maintext"
        v-if="maintext"
      >
        {{ maintext }}
      </p>
      <p
        class="text"
        v-if="text"
      >
        {{ text }}
      </p>
      <c-button
        @click="buttonAction"
        v-if="button !== undefined"
        class="button"
        size="lg"
        primary
      >
        {{ button }}
      </c-button>
    </div>
    <slot />
  </c-modal>
</template>

<script>
import CCharacter from '@/components/CComponents/CCharacter'
import CModal from '@/components/CComponents/CModal'

export default {
  components: { CCharacter, CModal },
  props: {
    opened: {
      type: Boolean,
      default: false
    },
    title: {
      type: String
    },
    redirect: {
      type: String
    },
    maintext: {
      type: String
    },
    text: {
      type: String
    },
    button: {
      type: String
    },
    hideCharacter: {
      type: Boolean
    }
  },
  methods: {
    buttonAction () {
      if (this.redirect) {
        this.$router.push({ name: this.redirect })
      } else {
        this.$emit('close', true)
      }
    }
  }
}
</script>

<style lang="scss">
@import '~@/styles/reference';

.modal-login {
  top: 20% !important;
  overflow: hidden !important;

  @include mobile {
    right: 10px !important;
    left: 10px !important;
  }

  .carousel {
    position: relative;
    overflow: hidden;

    .swiper-wrapper { display: flex; }
  }

  .modal-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 30px;

    & > .c-character {
      width: 80px;
      height: 80px;
      padding: 50px;
    }

    & >.maintext {
      margin-top: 20px;
      margin-bottom: 10px;
      text-align: center;
      opacity: 0.8;
      color: #121E48;
      font-size: 16px;
      font-weight: bold;
    }

    & >.text {
      text-align: center;
      opacity: 0.5;
      color: #121E48;
      font-size: 13px;
    }

    & >.button { margin: 30px 0; }
  }
}
</style>
