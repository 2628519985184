<template>
  <div class="navbar-preview-mobile">
    <div class="logo-container">
      <img
        v-if="logo"
        :src="logo"
        alt="logo"
        class="logo"
      />
      <c-logo
        v-else
        class="logo"
        kaledo
        :kaledo-width="'110px'"
        :kaledo-height="'24px'"
      />
    </div>
    <div class="actions">
      <c-button
        class="button profile preview-button"
        icon="search1"
        size="lg"
        use-k-icon
      />
      <c-button
        class="button profile preview-button"
        icon="bell-notification"
        size="lg"
        use-k-icon
      />
      <c-button
        class="button profile preview-button"
        icon="menu-burger4"
        size="lg"
        use-k-icon
      />
    </div>
  </div>
</template>

<script>
import CLogo from '@/components/CComponents/CLogo.vue'
import { KIcon } from 'kaledo-components'

export default {
  name: 'NavbarPreviewMobile',
  components: {
    CLogo,
    KIcon
  },
  props: {
    logo: {
      type: String,
      default: null
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/styles/reference';
.navbar-preview-mobile {
  max-width: 360px;
  width: 100%;
  height: 60px;
  background: #FDFDFD;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 12px;
  .logo-container {
    margin-left: 40px;
    .logo {
      max-width: 110px;
      height: 33px;
      object-fit: cover;
    }
  }
  .preview-button {
    cursor: not-allowed;
    transition: all ease 0.3s;
    border-color: #BEC0D3;
    &:hover {
      border-color: #BEC0D3;
      box-shadow: none;
    }
  }
}
</style>
