<template>
  <div class="onboarding-card">
    <c-title
      class="title"
    >
      Como funciona?
    </c-title>
    <div class="steps">
      <div
        v-for="(step, index) in steps"
        :key="index"
      >
        <div class="step">
          <div class="step-header">
            <k-icon
              class="icon"
              :icon="step.icon"
              :size="step.iconSize || '33'"
            />
            <h3>
              {{ step.title }}
            </h3>
          </div>
          <p class="step-content">
            {{ step.content }}
          </p>
        </div>
        <k-icon
          v-if="index !== (steps.length - 1)"
          class="icon"
          icon="arrow-right"
          size="33"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { KIcon } from 'kaledo-components'

export default {
  components: {
    KIcon
  },
  props: {
    steps: {
      type: Array,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
.onboarding-card {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 1000px;
  margin: 0 auto;
  margin-top: 48px;
  padding: 24px;
  gap: 32px;
  background: #FDFDFD;
  box-shadow: 0px 4px 8px rgba(94, 102, 132, 0.08);
  border-radius: 4px;

  .steps {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    > div {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .step {
      width: 100%;
      max-width: 280px;
      height: 265px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      text-align: center;
      gap: 16px;

      .step-header {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 16px;
        height: 90px;
      }
      .step-content {
        font-size: 16px;
        line-height: 24px;
        color: #5E6684;
      }
    }

    .icon {
      color: #5E6684;
      margin: 0 24px;
    }
  }
}
</style>
