<template>
  <div :class="[ 'link-component', { '-margin-top': twoSteps } ]">
    <c-input
      required
      class="input field-input"
      validation-icon="warning-circle"
      label="LINK"
      placeholder="http://www.site_do_parceiro.com.br"
      data-vv-delay="10"
      name="link"
      v-validate="'required'"
      :feedback-show="errors.has('link')"
      :feedback-message="errors.first('link')"
      :value="onlinelink"
      @input="v => emitter('eventData', v)"
    />
  </div>
</template>

<script>
export default {
  props: {
    onlinelink: String,
    twoSteps: {
      type: [Boolean]
    }
  },

  methods: {
    emitter (key, value) {
      setTimeout(() => {
        this.$emit('update', {
          [key]: {
            online_link: value
          },
          error: this.errors.all()
        })
      }, 50)
    }
  }
}
</script>

<style lang="scss">
@import '~@/styles/reference';

.link-component {
  margin-top: 20px;

  &.-margin-top { margin-top: 80px !important; }

  & > .field-input {
    width: 100%;
    padding-left: 80px;

    @include mobile { padding: 0; }

    & > .feedback {
      padding-top: 5px;
      width: 100%;
      padding-left: 80px;
      padding-right: 20px;

      @include mobile { padding: 0; }
    }
  }
}
</style>
