<template>
  <div class="order-card">
    <details-card :title="orderDetails.title">
      <template #content>
        <div class="order-main">
          <div class="main-left">
            <span class="info-label">Número</span><br />
            #{{ orderDetails.order_id }}
          </div>
          <div class="main-right">
            <span class="info-label">Status</span> <br />
            <c-label
              class="table-status"
              text-color="white"
              :color="color"
            >
              {{ status }}
            </c-label>
          </div>
          <div class="main-left">
            <span class="info-label">Realizado em</span><br />
            {{ createdDateFormated }}
          </div>
          <div class="main-right">
            <span class="info-label">Agendado para</span><br />
            {{ releaseDateFormated }}
          </div>
        </div>
        <div class="order-footer">
          <span
            v-if="!disableCancelAction"
            class="action"
            @click="$emit('order-cancel')"
          >Cancelar pedido</span>
        </div>
      </template>
    </details-card>
  </div>
</template>

<script>
import { timestampToOnlyDate } from '@/modules/validate/helpers.js'
import DetailsCard from './DetailsCard'
import CLabel from '@/components/CComponents/CLabel'

const statuses = {
  0: {
    label: 'Cancelado',
    color: '#EE5250'
  },
  1: {
    label: 'Entregue',
    color: '#4CAD50'
  },
  2: {
    label: 'Aguardando Liberação Interna',
    color: '#f5a623'
  },
  3: {
    label: 'Aguardando Pagamento',
    color: '#f5a623'
  },
  4: {
    label: 'Entrega Agendada',
    color: '#003396'
  }
}

export default {
  components: {
    DetailsCard,
    CLabel
  },
  props: {
    orderDetails: Object,
    disableCancelAction: Boolean
  },
  data () {
    return {
      statuses
    }
  },
  computed: {
    releaseDateFormated () {
      const formatedDate = timestampToOnlyDate(this.orderDetails.release_date)
      return String(formatedDate)
    },
    createdDateFormated () {
      const formatedDate = timestampToOnlyDate(this.orderDetails.created_at)
      return String(formatedDate)
    },
    color () {
      if (!this.orderDetails || (!this.orderDetails.status && this.orderDetails.status !== 0)) return '#000'
      else return statuses[this.orderDetails.status].color
    },
    status () {
      if (!this.orderDetails || (!this.orderDetails.status && this.orderDetails.status !== 0)) return 'Carregando...'
      else return statuses[this.orderDetails.status].label
    }
  }
}
</script>

<style lang="scss" scoped>
.order-card {
  height: 220px;

  .details-card {
    :deep .details-header {
      margin-top: 3px;
      align-items: flex-start;
    }
    :deep .details-content {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
      font-size: 13px;
      color: rgba(94, 102, 132, 1);

      > :not(:first-child) {
        margin-top: 10px;
      }

      .order-main {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        text-transform: uppercase;
        font-weight: 600;
        color: #5E6684;

        .info-label {
          color: #202950;
        }

        .main-left {
          justify-self: flex-start;
          text-align: flex-start;
          margin-bottom: 15px;
        }
        .main-right {
          justify-self: end;
          text-align: end;
          margin-bottom: 15px;
        }
      }

      .action {
        width: 100%;
        text-align: left;
        color: #EE5250;
        font-size: 14px;
        text-decoration-line: underline;
        cursor: pointer;
      }

      .order-footer {
        height: 20px;
      }
    }
  }
}
</style>
