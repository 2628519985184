<template>
  <div
    class="donut-graphic"
  >
    <vue-c3
      class="donut"
      :handler="handler"
    />
    <div class="circle" />
    <div
      class="legends"
      :class="legendClasses"
    >
      <div
        v-for="(label, index) in labels"
        :key="`${label}-${index}`"
        class="graphic-legend"
        :class="legendClasses"
        @mouseenter="mouseEnter(label)"
        @mouseleave="mouseLeave"
        @click="handleClick(label)"
      >
        <div
          :class="`color -color-${index} ${graphData.icons ? 'icon-color' : ''}`"
        >
          <k-icon
            v-if="graphData.icons"
            :icon="graphData.icons[index]"
            size="20"
            class="icon"
          />
        </div>
        <span class="number">{{ returnPercent(graphData.columns[label]) }}%</span>
        <span class="text"> {{ graphData.names[label] }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import VueC3 from 'vue-c3'
import moment from 'moment'
import { KIcon } from 'kaledo-components'

export default {
  name: 'DonutGraphic',
  components: {
    VueC3,
    KIcon
  },
  props: {
    graphData: Object
  },
  data () {
    return {
      handler: new Vue()
    }
  },
  computed: {
    formatedColumns () {
      const columns = this.labels.map(label => [
        label,
        parseFloat(this.graphData.columns[label])
      ])
      return columns
    },
    totalColumns () {
      const max = this.formatedColumns.length
      let total = 0
      for (let i = 0; i < max; i++) {
        total += parseFloat(this.formatedColumns[i][1])
      }
      return total.toFixed(2)
    },
    graphicSizeClass () {
      return this.labels.lenght > 8 ? '-large' : ''
    },
    legendClasses () {
      let classes = ''
      if (this.labels.length <= 3) classes += '-small'
      if (this.graphData.icons) classes += '-has-icons'
      return classes
    },
    labels () {
      return Object.keys(this.graphData.names)
    },
    periods () {
      const date = this.graphData.comparisonPeriod
      const final = []

      Object.values(date).forEach((period) => {
        final.push(`${this.formatDate(period.start)} - ${this.formatDate(period.end)}`)
      })

      return final
    },
    options () {
      return {
        data: {
          columns: this.formatedColumns,
          names: this.graphData.names,
          type: 'donut',
          classes: {
            periods: this.periods,
            comparisonValues: this.graphData.comparisonValues
          }
        },
        size: {
          width: 210,
          height: 200
        },
        legend: {
          hide: true
        },
        donut: {
          label: {
            show: false
          }
        },
        tooltip: {
          horizontal: false,
          contents: function (d) {
            const value = Math.ceil(d[0].value)
            const meta = this.config.data_classes
            let comparison = ''
            let periods = ''

            if (meta.periods.length >= 2) {
              const minor = '<span class="tooltip-comparison -error">↓</span>'
              const major = '<span class="tooltip-comparison -success">↑</span>'
              let percentChange = 0

              if (d[0].value !== 0 && meta.comparisonValues[d[0].id] !== 0) percentChange = ((d[0].value - meta.comparisonValues[d[0].id]) / d[0].value) * 100

              const percent = Math.ceil(percentChange)

              comparison += `<span class="comparison">${percentChange >= 0 ? major : minor} ${percent}%</span>`
              periods += `
                <p class="tooltip-period">${meta.periods[0]}</p>
                <p class="tooltip-value">${value}</p>
                <p class="tooltip-period">${meta.periods[1]}</p>
                <p class="tooltip-value">${meta.comparisonValues[d[0].id]}</p>
              `
            } else {
              periods += `
                <p class="tooltip-period">${meta.periods[0]}</p>
                <p class="tooltip-value">${value}</p>
              `
            }

            return `
            <div class="custom-tooltop">
              <h6 class="tooltip-title">${d[0].name}</h6>
              ${periods}
              ${comparison}
            </div>`
          }
        },
        color: {
          pattern: ['#202950', '#00B2E3', '#526096', '#3BD4EE', '#8192CA', '#62ECF6']
        }
      }
    }
  },
  mounted () {
    this.handler.$emit('init', this.options)
  },
  methods: {
    formatDate (date) {
      moment.locale('pt-br')
      const correctDate = date * 1000
      return moment(correctDate).format('D MMM YYYY')
    },
    mouseEnter (id) {
      this.handler.$emit('dispatch', (chart) => chart.focus(id))
    },
    mouseLeave () {
      this.handler.$emit('dispatch', (chart) => chart.revert())
    },
    handleClick (id) {
      this.handler.$emit('dispatch', (chart) => chart.toggle(id))
    },
    returnPercent (value) {
      const percent = (value * 100) / this.totalColumns
      return Math.ceil(percent.toFixed(2))
    }
  }
}
</script>

<style lang='scss' scoped>
@import '~@/styles/reference.scss';

$circle-size: 80px;
.donut-graphic {
  width: 100%;
  padding: 0 20px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .circle {
    position: absolute;
    width: $circle-size;
    height: $circle-size;
    border-radius: 50%;
    border: 1px solid #5E6684;
    top: 47px;
  }
  .legends {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
    text-align: left;
    width: 100%;

    &.-small {
      width: 120px;
    }

    .graphic-legend {
      width: 50%;
      display: flex;
      align-items: center;
      margin-bottom: 16px;
      cursor: pointer;

      &.-small {
        width: 100%;
      }

      &.-has-icons {
        display: grid;
        grid-template-columns: 1fr 3fr;
        grid-template-rows: repeat(2, 1fr);

        > .color {
          grid-row: 1 / -1;
        }
        > .text {
          font-size: 11px;
        }
      }

      .number {
        font-size: 12px;
        font-weight: 700;
        color: $base-color;
        padding-right: 8px;
      }
      .text {
        font-size: 12px;
        color: rgba(94, 102, 132, 1);
      }
      .color {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background: #202950;
        margin-right: 5px;
        &.-color-0 {
          background: #202950;
        }
        &.-color-1 {
          background: #00B2E3;
        }
        &.-color-2 {
          background: #526096;
        }
        &.-color-3 {
          background: #3BD4EE;
        }
        &.-color-4 {
          background: #8192CA;
        }
        &.-color-5 {
          background: #62ECF6;
        }
        &.icon-color {
          width: 32px;
          height: 32px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        >  .icon {
          color: white;
        }
      }
    }
  }
}

</style>
<style lang="scss" >
@import '~@/styles/reference.scss';
.donut {
.c3-tooltip-container {
  background: white;
  box-shadow: rgba(0,0,0,0.1);
  border-radius: 3px;
}
.custom-tooltop {
  width: 170px;
  min-height: 50px;
  font-size: 12px;
  padding: 8px;
  .tooltip-title {
    color: $title-color;
    font-weight: 600;
    margin-bottom: 5px;
    text-transform: uppercase;
  }
  .tooltip-period {
    color: #5E6684;
    font-size: 10px;
    line-height: 18px;
    font-weight: 400;
  }
  .tooltip-value {
    color: #5E6684;
    font-weight: 700;
    font-size: 12px;
    line-height: 18px;
  }
  .comparison {
    font-size: 12px;
    color: #5E6684;
    font-weight: 600;
    .tooltip-comparison {
      &.-error {
        color: $like-color;
      }
      &.-success {
        color: $success-color;
      }
    }
  }
}
}
</style>
