<template>
<div>
  <transition name="fade" mode="out-in">
    <c-spinner
      v-if="isLoading"
      size="lg"
    />
    <reports-list
      v-else
      :period="period"
      :options="options"
      :reports="reports"
      @submit-report="processReport"
      @update="v => period = v"
      @close="period = undefined"
  />
  </transition>
</div>

</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import * as types from '@/store/types'
import Loadable from '@/modules/loadable'

import ReportsList from '@/components/Reports/ReportsList'

const options = [
  {name: 'ESTE MÊS', value: 'tm'},
  {name: 'MÊS PASSADO', value: 'lm'},
  {name: 'ÚLTIMOS SEIS MESES', value: 'lsm'},
  {name: 'SELECIONAR PERÍODO', value: 'sp'},
]

export default {
  mixins: [ Loadable ],
  components: {
    ReportsList
  },
  data () {
    return {
      options,
      isLoading: true,
      period: undefined
    }
  },
  computed: {
    ...mapGetters({
      reports: types.REPORT_LIST
    })
  },
  methods: {
    ...mapActions({
      getReports: types.REPORT_LIST,
      submitReport: types.REPORT
    }),
    async load () {
      await this.getReports()
    },
    processReport (report) {
      this.submitReport(report)
      this.period = undefined
    }
  }
}
</script>
