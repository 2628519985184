<template>
  <section>
    <campaign-header
      v-if="!isLoading"
      :partner="partner"
      :is-loading="loadingStatus"
      @delete-partner="displayDeleteWarning = true"
      @toggle-partner-status="toggleStatus"
    />

    <c-confirm-modal
      :btn-props="{ danger: true }"
      confirm-text="Excluir"
      character="embarrassed"
      title="Tem certeza que deseja excluir esse parceiro?"
      message="Se excluí-lo, as mídias armazenadas hoje também serão deletadas permanentemente"
      :is-opened="displayDeleteWarning"
      @close="displayDeleteWarning = false"
      @confirm="confirmDelete"
    />

    <c-confirm-modal
      :btn-props="{ primary: true }"
      confirm-text="Inativar"
      character="embarrassed"
      title="Tem certeza que deseja inativar este parceiro?"
      message="Se inativá-lo, não será possível visualizar ofertas e mídias referente à ele no seu clube"
      :is-opened="displayStatusWarning"
      :is-loading="isLoading"
      @close="closeStatusConfirm"
      @confirm="confirmStatus"
    />
  </section>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Loadable from '@/modules/loadable'
import * as types from '@/store/types'

import CConfirmModal from '@/components/CComponents/CConfirmModal'
import CampaignHeader from '@/components/Campaign/Listing/CampaignHeader'

export default {
  components: { CConfirmModal, CampaignHeader },
  mixins: [ Loadable ],

  data () {
    return {
      displayStatusWarning: false,
      displayDeleteWarning: false,
      loadingStatus: false,
      isLoading: false
    }
  },

  computed: {
    ...mapGetters({
      company: types.COMPANY,
      partner: types.PARTNER
    })
  },

  destroyed () {
    this.clearPartner()
  },

  methods: {
    ...mapActions({
      togglePartnerStatus: types.PARTNER_TOGGLE_STATUS,
      setBreadcrumb: types.BREADCRUMB_NAMES,
      deletePartner: types.PARTNER_DELETE,
      clearPartner: types.PARTNER_CLEAR,
      getPartner: types.PARTNER
    }),
    async load () {
      const { slug } = this.$route.params
      await this.getPartner({ slug })
    },
    async confirmDelete () {
      await this.deletePartner({ id: this.partner.id })
      this.handleAnalytics('excluir-parceiro', {
        company_name: this.company.name,
        partner_name: this.partner.name,
        partner_slug: this.partner.slug
      })
      this.displayDeleteWarning = false
      this.$router.push({ name: 'parceiros' })
    },
    async confirmStatus (ev) {
      const isExclusive = this.partner.is_exclusive
      this.isLoading = true
      await this.togglePartnerStatus({ slug: this.partner.slug, isExclusive })
      this.isLoading = false
      this.closeStatusConfirm()
    },
    async toggleStatus () {
      const status = this.partner.is_exclusive
        ? this.partner.status
        : this.partner.is_blocked
      this.loadingStatus = true

      if (status) this.displayStatusWarning = true
      else await this.confirmStatus()
    },
    closeStatusConfirm () {
      this.displayStatusWarning = false
      this.loadingStatus = false
    },
    handleAnalytics (event, data) {
      window.dataLayer.push(
        {
          event: event,
          ...data
        }
      )
    }
  }
}
</script>
