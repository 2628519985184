<template>
  <div
    v-if="type === 1"
    class="warn-container"
  >
    <k-icon
      class="icon"
      icon="warning2"
      size="20"
    />As informações inseridas não poderão ser alteradas
  </div>
  <div
    v-else
    class="warn-container-support"
  >
    <k-icon
      class="icon"
      icon="warning2"
      size="20"
    />Para realizar alterações, <a
      class="link"
      href="https://kaledo.zendesk.com"
      target="_blank"
    >entre em contato com o nosso suporte</a>
  </div>
</template>

<script>
import { KIcon } from 'kaledo-components'
export default {

  components: {
    KIcon
  },
  props: {
    type: {
      type: Number,
      default: 1
    }
  }
}
</script>

<style lang='scss' scoped>
@import '~@/styles/reference.scss';
.warn-container {
  display: flex;
  width: 435px;
  height: 38px;
  padding: 8px;
  align-items: flex-start;
  gap: 8px;
  border-radius: 4px;
  border: 1px solid $feedback-warning-100;
  color: $neutral-dark-blue;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Nunito Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
  & > .icon {
    color: $feedback-warning-100;
  }
}
.warn-container-support {
  display: flex;
  width: 510px;
  height: 38px;
  padding: 8px;
  align-items: center;
  gap: 8px;
  border-radius: 4px;
  border: 1px solid $feedback-warning-100;
  color: $neutral-dark-blue;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Nunito Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
  & > .icon {
    color: $feedback-warning-100;
  }
  & > .link {
    color: $admin-alert-blue;
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Nunito Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 16.8px; /* 105% */
    text-decoration-line: underline;
    &:hover {
      cursor: pointer;
    }
  }
}
</style>
