<template>
  <div class="vidalink-card">
    <campaigns-bar-card
      class="card"
      :loading="loading"
      :title="graphData.title"
      :label="graphData.label"
      :data="graphData.tabs"
      :icon="'kaledo-vida'"
    />
    <loading-card v-if="loading" />
    <spine-chart
      v-else
      :columns="correctGraph"
      :title="chartTitle"
    />
  </div>
</template>

<script>
import LoadingCard from '@/components/Dashboard/cards/LoadingCard.vue'
import SpineChart from '@/components/Dashboard/base/SpineChart.vue'
import CampaignsBarCard from '@/components/Dashboard/cards/CampaignsBarCard.vue'

export default {
  name: 'VidalinkCard',
  components: {
    SpineChart,
    CampaignsBarCard,
    LoadingCard
  },
  props: {
    loading: Boolean,
    graphData: Object
  },
  computed: {
    correctGraph () {
      if (!this.graphData) return []
      return this.graphData.columns[0]
    },
    chartTitle () {
      if (!this.graphData) return ''
      return this.graphData.title
    }
  }
}
</script>

<style lang='scss' scoped>
.vidalink-card {
  > .card {
    cursor: pointer;
    background: #FDFDFD;
    height: 200px;
    box-shadow: 1px 1px 4px 0 rgba(0,0,0,0.1);
    border-radius: 6px 6px 0 0;

    & :deep h4 {
      max-width: 220px;
    }
  }

  > .spine-chart, .loading-card {
    z-index: 3;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: -1px;
    padding: 15px 5px;
    max-width: 700px;
    background: white;
    box-shadow: 1px 1px 4px 0 rgba(0,0,0,0.1);
    border-bottom: 1px solid rgba(18,30,72,0.05);
    border-radius: 0 6px 6px 6px
  }
}
</style>
