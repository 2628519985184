<template>
  <div class="c-chip">
    <span>{{ text }}</span>
    <span class="value">{{ value }}</span>
  </div>
</template>

<script>
export default {
  props: {
    text: String,
    value: [String, Number]
  }
}
</script>

<style lang="scss" scoped>
.c-chip {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 195px;
  height: 26px;
  padding: 4px 8px;
  background: #FDFDFD;
  box-shadow: 0px 2px 4px rgba(94, 102, 132, 0.08);
  border-radius: 4px;
  color: #202950;
  font-size: 11px;
  text-transform: uppercase;

  .value {
    font-weight: 700;
    margin-left: 8px;
    font-size: 12px;
  }
}
</style>
