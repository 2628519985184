<template>
  <div>
    <awards-orders
      :loading="loading"
      @confirm-cancel="handleCancelOrder"
      @submit-search="handleGetOrders"
      @cancel-order="handleCancelOrder"
    />
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { COMPANY, CANCEL_ORDER, AWARDS_GET_ORDERS_LIST } from '@/store/types'
import AwardsOrders from '@/components/Awards/Orders/AwardsOrders'

export default {
  components: {
    AwardsOrders
  },
  data () {
    return {
      loading: false
    }
  },
  mounted () {
    this.handleGetOrders()
  },
  methods: {
    ...mapActions({
      cancelOrder: CANCEL_ORDER,
      getOrders: AWARDS_GET_ORDERS_LIST,
      getCompany: COMPANY
    }),
    async handleCancelOrder (id) {
      this.loading = true
      await this.cancelOrder({id})
      await this.handleGetOrders()
      await this.getCompany()
      this.loading = false
      await this.getCompany()
    },
    async handleGetOrders (filters) {
      this.loading = true
      await this.getOrders(filters)
        .catch(() => {
          this.feedback({
            type: 'error',
            title: 'Houve um erro ao carregar os pedidos',
            text: 'Tente novamente mais tarde'
          })
        })
        .finally(() => {
          this.loading = false
        })
      this.loading = false
    }
  }
}
</script>

<style>
</style>
