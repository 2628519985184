<template>
  <transition name="c-modal-mask-fade">
    <div
      v-if="show"
      class="c-modal-mask"
      id="modal-mask"
      :class="{ '-transparent': transparent }"
      @click="$emit('close')"
    />
  </transition>
</template>

<script>
export default {
  props: {
    show: Boolean,
    transparent: Boolean
  }
}
</script>

<style lang="scss">
.c-modal-mask {
  height: 100%;
  width: 100%;
  position: fixed;
  left: 0;
  top: 0;

  &:not(.-transparent) {
    background-color: rgba(black, 0.5);
    z-index: 5;
  }
}

.c-modal-mask-fade {
  @at-root {
    #{&}-enter-active,
    #{&}-leave-active {
      transition: opacity .3s ease;
    }
    #{&}-enter,
    #{&}-leave-to {
      opacity: 0;
    }
  }
}
</style>
