<template>
  <div class="order-card">
    <details-card
      class="details-card"
      :title="orderDetails.title"
    >
      <template #content>
        <div class="order-main">
          <div class="main-left">
            <div class="title">
              Pedido #{{ orderDetails.order_id }}
            </div>

            <div class="date">
              <h2>Realizado em</h2>
              <h3>{{ createdDateFormated }}</h3>
            </div>
          </div>
          <div class="main-right">
            <div class="status">
              <h2 class="info-label">
                Status
              </h2>
              <c-label
                class="table-status"
                :color="color"
                :text-color="textColor ? textColor : 'inherit'"
              >
                {{ status }}
              </c-label>
            </div>
          </div>
        </div>
        <div class="description ">
          <h2>Observação</h2>
          <h3>{{ orderDetails.description }}</h3>
        </div>
        <div class="order-footer">
          <span
            v-if="!disableCancelAction"
            class="action"
            @click="$emit('order-cancel')"
          >Cancelar pedido</span>
        </div>
      </template>
    </details-card>
  </div>
</template>

<script>
import { timestampToOnlyDate } from '@/modules/validate/helpers.js'
import CLabel from '@/components/CComponents/CLabel'
import DetailsCard from '@/components/Awards/base/DetailsCard.vue'

const statuses = {
  0: {
    label: 'Cancelado',
    color: '#EE5250'
  },
  1: {
    label: 'Pago',
    color: '#4CAD50'
  },
  2: {
    label: 'Aguardando Pagamento',
    color: '#f5a623'
  },
  3: {
    label: 'Debitado',
    color: '#003396'
  }
}

export default {
  components: {
    DetailsCard,
    CLabel
  },
  props: {
    orderDetails: Object,
    disableCancelAction: Boolean
  },
  data () {
    return {
      statuses
    }
  },
  computed: {
    createdDateFormated () {
      const formatedDate = timestampToOnlyDate(this.orderDetails.created_at)
      return String(formatedDate)
    },
    color () {
      if (!this.orderDetails || (!this.orderDetails.status && this.orderDetails.status !== 0)) return '#000'
      else return statuses[this.orderDetails.status].color
    },
    status () {
      if (!this.orderDetails || (!this.orderDetails.status && this.orderDetails.status !== 0)) return 'Carregando...'
      else return statuses[this.orderDetails.status].label
    }
  }
}
</script>

<style lang="scss" scoped>
.order-card {
  height: 220px;

  .details-card {
    padding: 32px;
    max-width: none !important;
    :deep .details-header {
      display: none;
    }
    :deep .details-content {
      height: 100%;
      display: flex;
      flex-direction: column;
      font-size: 13px;
      color: rgba(94, 102, 132, 1);

      > :not(:first-child) {
        margin-top: 10px;
      }

      .order-main {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        text-transform: uppercase;
        font-weight: 600;
        color: #5E6684;

        .info-label {
          color: #202950;
        }

        .main-left {
          justify-self: flex-start;
          text-align: flex-start;
          margin-bottom: 15px;

          .title {
            font-size: 18px;
            font-weight: 700;
            margin-bottom: 24px;
            color:#202950;
          }

          .date{
            margin-bottom: 24px;
            h2 {
              font-size: 14px;
              font-weight: 600;
              color:#202950;
              margin-bottom: 8px;
            }
            h3 {
              font-size: 14px;
              font-weight: 600;
              color:#5E6684;
            }
          }
        }
        .main-right {
          display: flex;
          justify-content: center;
          flex-direction: column;
          text-align: end;
          .status {
            h2 {
              font-size: 14px;
              font-weight: 600;
              color:#202950;
              margin-bottom: 8px;
            }
          }
        }
      }

      .description {
          height: 89px;
          h2 {
              font-size: 14px;
              font-weight: 600;
              color:#202950;
              margin-bottom: 8px;
            }
            h3 {
              font-size: 14px;
              font-weight: 600;
              color:#5E6684;
            }
        }

      .action {
        width: 100%;
        text-align: left;
        color: #EE5250;
        font-size: 14px;
        text-decoration-line: underline;
        cursor: pointer;
      }

      .order-footer {
        height: 20px;
      }
    }
  }
}
</style>
