<template>
  <section
    class="engagement"
    data-cy="engagement"
  >
    <general-header
      class="header"
      :nav-tabs="navTabs"
      :show-tabs="showTabs"
      @return-click="handleReturnClick()"
      @show-onboarding="showOnboarding = true"
    >
      <template
        v-if="showActionButtonText"
        #action-button
      >
        <c-button
          icon="plus"
          primary
          data-cy="action-button"
          class="action-button"
          @click.native="handleActionClick()"
        >
          {{ actionButtonText }}
        </c-button>
      </template>
    </general-header>
    <onboarding
      :title="onboardingTitle"
      :is-opened="showOnboarding"
      :show-modal="showOnboarding"
      :topics="topics"
      @close="showOnboarding = false"
    />
    <nuxt-child />
    <c-confirm-modal
      :btn-props="{ primary: true }"
      cancel-text="Voltar"
      :confirm-text="cancelButtonText"
      character="embarrassed"
      :title="cancelMessage"
      message="Se cancelar, todas as informações já inseridas não serão processadas."
      :is-opened="openCancelModal"
      @close="openCancelModal = false"
      @confirm="confirmCancel"
    />
  </section>
</template>

<script>
import GeneralHeader from '@/components/General/GeneralHeader'
import Onboarding from '@/components/General/OnboardingModal'
import CConfirmModal from '@/components/CComponents/CConfirmModal'
import { mapGetters, mapMutations } from 'vuex'
import { COMPANY, ENGAGEMENT_CAMPAIGNS_STEP, DISTRIBUTION_STEP, FEEDBACK } from '@/store/types'

export default {
  name: 'Engagement',
  components: {
    GeneralHeader,
    Onboarding,
    CConfirmModal
  },
  data () {
    return {
      onboardingTitle: 'Guia do programa de engajamento',
      showOnboarding: false,
      openCancelModal: false,
      navTabs: [
        {
          name: 'Personalização',
          value: 'engajamento'
        },
        {
          name: 'Campanhas',
          value: 'engajamento-campanhas'
        },
        {
          name: 'Distribuição de Moedas',
          value: 'engajamento-distribuicao'
        }
      ],
      topics: [
        {
          icon: 'crown-circle',
          title: 'Moeda personalizada',
          contents: ['Você pode personalizar a moeda que será distribuida com um nome e uma imagem que represente a sua marca.',
            'Para criar a sua moeda, basta ir para a aba "Personalização” e clicar em sua moeda. ']
        },
        {
          icon: 'task-list-document-text',
          title: 'Catálogo de recompensas',
          contents: ['Personalize o catálogo de recompensas oferecidas aos usuários na aba "Personalização". Lá você tem o controle e é capaz de ativar e desativar os gift cards que estarão disponíveis no seu catálogo.']
        },
        {
          icon: 'user-profile-heart',
          title: 'Campanhas de engajamento',
          contents: ['A campanha é o seu meio de comunicação com o usuário final, é por meio dela que o seu usuário saberá a sua proposta, a recompensa em moedas e as ações necessárias para ganhar as moedas. Para criar a sua campanha, vá na aba "Campanhas” e clique no botão "Nova Campanha".']
        },
        {
          icon: 'crown-circle',
          title: 'Distribuição de moedas',
          contents: ['Na aba "Distribuição de Moedas” você poderá distribuir moedas para os usuários que cumpriram com a proposta da sua campanha. Para criar um pedido, basta clicar no botão "Novo pedido” e seguir as instruções, são apenas 4 etapas onde você terá que inserir informações como: email e CPF dos premiados, quantidade de moedas que serão distribuídas e uma mensagem personalizada.']
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      company: COMPANY
    }),
    showTabs () {
      const creationRoutes = [
        'engajamento-campanhas-nova-campanha',
        'engajamento-distribuicao-detalhes',
        'engajamento-distribuicao-novo-pedido'
      ]

      return !creationRoutes.some(substring => String(this.$route.name).includes(substring))
    },
    cancelFeedbackMessage () {
      return String(this.$route.name).includes('campanhas')
        ? 'Campanha cancelada'
        : 'Pedido cancelado'
    },
    cancelButtonText () {
      return String(this.$route.name).includes('campanhas')
        ? 'Cancelar campanha'
        : 'Cancelar pedido'
    },
    cancelMessage () {
      return String(this.$route.name).includes('campanhas')
        ? 'Tem certeza que deseja cancelar a criação da campanha?'
        : 'Tem certeza que deseja cancelar a criação do pedido?'
    },
    returnLink () {
      return String(this.$route.name).includes('campanhas') ? 'engajamento-campanhas' : 'engajamento-distribuicao'
    },
    showActionButtonText () {
      return String(this.$route.name).includes('campanhas') || String(this.$route.name).includes('distribuicao')
    },
    actionButtonText () {
      if (String(this.$route.name).includes('campanhas')) {
        return 'Nova campanha'
      } else if (String(this.$route.name).includes('distribuicao')) {
        return 'Novo pedido'
      }
      return ''
    }
  },
  methods: {
    ...mapMutations({
      updateCampaignsStep: ENGAGEMENT_CAMPAIGNS_STEP,
      updateDistributionStep: DISTRIBUTION_STEP,
      feedback: FEEDBACK
    }),
    handleAnalytics (event, data) {
      window.dataLayer.push(
        {
          event,
          ...data
        }
      )
    },
    handleNewCampaign () {
      this.updateCampaignsStep(1)
      this.handleAnalytics('pontos-campanha-criacao', { company_name: this.company.slug })
      this.$router.push('/engajamento/campanhas/nova-campanha')
    },
    handleNewOrder () {
      this.updateDistributionStep(1)
      this.handleAnalytics('pontos-pedido-criacao', { company_name: this.company.slug })
      this.$router.push('/engajamento/distribuicao/novo-pedido')
    },
    handleActionClick () {
      if (String(this.$route.name).includes('campanhas')) {
        this.handleNewCampaign()
      } else {
        this.handleNewOrder()
      }
    },
    handleReturnClick () {
      if (String(this.$route.name).includes('engajamento-distribuicao-detalhes')) {
        this.$router.push({ name: 'engajamento-distribuicao' })
      } else {
        this.openCancelModal = true
      }
    },
    confirmCancel () {
      const eventAreaName = String(this.$route.name).includes('campanhas') ? 'campanha-criacao' : 'pedido'
      this.$router.push({ name: this.returnLink })
      this.openCancelModal = false
      this.feedback({
        type: 'warning',
        title: this.cancelFeedbackMessage
      })
      this.handleAnalytics(`pontos-${eventAreaName}-abandonar`, { company_name: this.company.slug })
      this.updateCampaignsStep(1)
      this.updateDistributionStep(1)
    }
  }
}
</script>

<style lang='scss' scoped>
.engagement {
  .action-button {
    margin-right: 24px;
    height: 40px;
    border-radius: 30px;
    font-size: 12px;
    font-weight: 400;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
  }
}
</style>
