<template>
  <section class="container">
    <c-title
      class="title"
      size="3"
    >
      <span class="text">
        <k-icon
          class="icon"
          icon="settings-gear"
          size="20"
        />
        Configurações
      </span>
    </c-title>
    <div class="settings-list">
      <config-card
        v-for="(settings, index) in settingsList"
        :settings="settings"
        :card-index="index"
        :key="index"
      />
    </div>
    <trial-banner
      v-if="company.trial"
      @call-sales="callSales()"
    />
  </section>
</template>

<script>
import { KIcon } from 'kaledo-components'
import ConfigCard from '@/components/Settings/ConfigCard'
import TrialBanner from '@/components/Settings/TrialBanner'
import { COMPANY, COMPANY_CALL_SALES } from '@/store/types'
import { mapGetters, mapActions } from 'vuex'

export default {
  components: {
    KIcon,
    ConfigCard,
    TrialBanner
  },
  data () {
    return {
      settingsList: [
        {
          title: 'Seu ambiente',
          titleIcon: 'monitor-display-square',
          route: 'ambiente',
          itens: [
            'URL',
            'Nome do seu clube'
          ]
        },
        {
          title: 'Configurações de Acesso',
          titleIcon: 'user-profile',
          route: 'acesso',
          itens: [
            'Ativação de Conta',
            'Página de Acesso'
          ]
        },
        {
          title: 'Configurações de Bloqueios',
          titleIcon: 'block-delete-stop',
          route: 'bloqueios',
          itens: [
            'Novos Parceiros',
            'Saques e Pagamentos'
          ]
        }
        /* {
          title: 'Configurações de Logout',
          titleIcon: 'login-ogout',
          route: 'logout',
          itens: [
            'Botão de logout',
            'Redirecionamento'
          ]
        } */
      ]
    }
  },

  computed: {
    ...mapGetters({
      company: COMPANY
    })
  },

  methods: {
    ...mapActions({
      callSales: COMPANY_CALL_SALES
    })
  }

}
</script>

<style lang='scss' scoped>
@import '~@/styles/reference';
.text {
  color: var(--bsicas-secondary-base, #5E6684);
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Ubuntu;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 19.2px;
  display: flex;
  align-content: center;
}
.icon {
  margin-right: 10px;
}
.container {
  max-width: 1020px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 48px;

  & > .settings-list {
    display: flex;
    flex-wrap: wrap;
    padding-top: 24px;
    gap: 24px;
  }
}
</style>
