<template>
  <div data-cy="notification-example-card" class="notification-example">
    <img :src="image" />
    <div class="text">
      <h1>{{ title }}</h1>
      <h4>{{ description }}</h4>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    image: String,
    title: String,
    description: String
  }
}
</script>

<style lang="scss" scoped>
.notification-example {
  display: flex;
  width: 420px;
  height: 84px;
  align-items: center;
  padding: 0px;
  gap: 4px;
  background: #FFFFFF;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.16);
  margin: 16px 0;
  padding: 0 30px;
  & > img {
    max-width: 64px;
    margin-right: 16px;
  }
  & > .text {
    & > h1 {
      font-style: normal;
      font-weight: 700;
      font-size: 10px;
      line-height: 14px;
      text-transform: uppercase;
      color: #565F7D;
    }
    & > h4 {
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 15px;
      color: #565F7D;
    }
  }
}
</style>
