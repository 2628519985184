<template>
  <c-card class="address-unit-form">
    <div class="section-header">
      <c-title>
        Unidade {{ unitNumber }}
      </c-title>

      <c-button
        @click="$emit('delete-address', { index })"
        :disabled="disableDelete"
        class="btn-delete"
        icon-size="lg"
        size="lg"
        icon="trash"
      />
    </div>

    <div class="section-controls">
      <div class="control-group">
        <c-input
          watch
          label="CEP"
          placeholder="00000-000"
          mask="#####-###"
          class="form-control -inline -short"
          :value="code"
          @input="changeCode"
        />

        <c-input
          watch
          label="Endereço"
          name="address"
          class="form-control"
          placeholder="Digite o endereço"
          :value="street"
          :disabled="!isFormValid"
          @input="$emit('update', { index, property: { street: $event } })"

          data-vv-delay="10"
          data-vv-as='"endereço"'
          :required="isFormValid"
          v-validate="isFormValid ? 'required' : undefined"

          feedback-message="Campo obrigatório"
          :feedback-show="errors.has('address')"
        />
      </div>

      <div class="control-group">
        <c-input
          watch
          class="form-control -inline -short"
          placeholder="00000"
          label="Número"
          name="number"
          :value="number"
          :disabled="!isFormValid"
          @input="$emit('update', { index, property: { number: $event } })"

          data-vv-delay="10"
          data-vv-as='"número"'
          :required="isFormValid"
          v-validate="isFormValid ? 'required' : undefined"

          feedback-message="Campo obrigatório"
          :feedback-show="errors.has('number')"
        />

        <div class="form-inline">
          <c-input
            watch
            label="Cidade"
            name="city"
            class="form-control -inline -short"
            placeholder="Digite a cidade"
            :value="city"
            :disabled="!isFormValid"
            @input="$emit('update', { index, property: { city: $event } })"

            data-vv-delay="10"
            data-vv-as='"cidade"'
            :required="isFormValid"
            v-validate="isFormValid ? 'required' : undefined"

            feedback-message="Campo obrigatório"
            :feedback-show="errors.has('city')"
          />

          <c-input
            watch
            label="Estado"
            name="state"
            class="form-control -inline -short"
            placeholder="Digite o estado"
            :value="state"
            :disabled="!isFormValid"
            @input="$emit('update', { index, property: { state: $event } })"

            data-vv-as='"estado"'
            data-vv-delay="10"
            :required="isFormValid"
            v-validate="isFormValid ? 'required' : undefined"

            feedback-message="Campo obrigatório"
            :feedback-show="errors.has('state')"
          />
        </div>
      </div>
    </div>
  </c-card>
</template>

<script>
import CCard from '@/components/CComponents/CCard'

export default {
  components: { CCard },
  props: {
    code: {
      type: String,
      required: false
    },
    number: {
      type: String,
      required: false
    },
    street: {
      type: String,
      required: false
    },
    city: {
      type: String,
      required: false
    },
    state: {
      type: String,
      required: false
    },
    index: {
      type: Number,
      required: false
    },
    addressLength: {
      type: Number,
      required: false
    },
    disableDelete: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    isFormValid () {
      return this.code.length === 9
    },
    unitNumber () {
      const unitDiff = this.addressLength - this.index
      return (this.addressLength - unitDiff) + 1
    }
  },
  methods: {
    async changeCode (code) {
      this.$emit('update', { index: this.index, property: { code } })
      if (code.length !== 9) return

      fetch(`https://viacep.com.br/ws/${code}/json/`).then(res => {
        res.json().then(data => {
          const new_adress = {
            state: data.uf,
            city: data.localidade,
            street: data.logradouro
          }
          this.$emit('update', { index: this.index, property: new_adress })
        })
      })
    }
  }
}
</script>

<style lang="scss">
@import '~@/styles/reference';

.address-unit-form {
  width: 100%;
  padding: 20px;

  & > .section-header {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin-bottom: 0px !important;
  }

  & > .section-controls {
    flex-direction: column;
    justify-content: center;

    padding: 35px 0 65px 0;

    & > .control-group {
      display: flex;
      flex-direction: row;
      justify-content: center;
      width: 100%;

      flex-shrink: 0;
      &:not(:last-child) { margin-bottom: 20px; }

      & > .form-control {
        width: 100%;
        max-width: 430px;

        @include desktop {
          &.-short { max-width: 145px; }
          &:not(:last-child) { margin-right: 105px; }
        }
      }

      & > .form-inline { display: flex; }

      & > .form-inline > .form-control {
        flex-grow: 1;
        margin-bottom: 0;
        &:not(:last-child) { margin-right: 90px; }
      }
    }

    @include mobile {
      flex-direction: column;

      & > .control-group {
        flex-direction: column;
        width: 100%;

        & > .form-control {
          max-width: 100%;
          margin-right: 0px;
          &:not(:last-child) { margin-bottom: 20px; }
        }

        & > .form-inline > .form-control {
          margin-right: 0px;
          &:not(:last-child) { margin-right: 15px !important; }
        }

        & > .form-control.-inline { margin-bottom: 25px !important; }
      }
    }
  }
}
</style>
