<template>
  <div>
    <personalization/>
  </div>
</template>

<script>
import Personalization from '@/containers/Engagement/pages/Personalization.vue'

export default {
  name: 'Engagement',
  components: {
    Personalization
  }
}
</script>

<style lang='scss' scoped>
</style>
