<template>
  <div class="LoginContent">
    <div class="login-box">
      <c-title
        class="title"
        pink
      >
        Olá,
      </c-title>
      <p class="text">
        Você já se cadastrou no(s) seguinte(s) clube(s)
      </p>
      <swiper
        :class="carouselClasses"
        :options="swiperOptions"
      >
        <swiper-slide
          v-for="(company, index) in companies"
          :key="index"
        >
          <div class="club-logo">
            <c-image
              class="partner-logo"
              :src="company.logo"
              :placeholder="imagePlaceholder"
            />
          </div>
          <p class="club-name">
            {{ company.name }}
          </p>
        </swiper-slide>
        <div
          class="swiper-button -prev"
          slot="button-prev"
        >
          <c-icon
            class="icon"
            icon="chevron-left"
            size="22"
          />
        </div>

        <div
          class="swiper-button -next"
          slot="button-next"
        >
          <c-icon
            class="icon"
            icon="chevron-right"
            size="22"
          />
        </div>
      </swiper>
      <form
        class="form"
        @submit.prevent="$emit('submit', password)"
      >
        <p class="text">
          Digite sua senha para continuar:
        </p>
        <c-input
          required
          v-validate="'required'"
          data-vv-delay="10"
          icon="lock2"
          placeholder="Insira sua senha"
          type="password"
          class="input-data"
          name="senha"
          validation-icon="warning-circle"
          :disabled="isLoading"
          :feedback-show="errors.has('senha') || isPasswordInvalid"
          :feedback-message="passwordMessage"
          v-model="password"
        />
        <div class="under-input">
          <a
            @click.prevent="$router.push({ name: 'esqueci-minha-senha' })"
            class="forget-pwd"
          >
            Esqueci a senha
          </a>
        </div>
      </form>
      <c-button
        primary
        size="lg"
        class="btn"
        :disabled="!password || isLoading"
        @click="$emit('submit', password)"
      >
        Continuar
      </c-button>
    </div>
  </div>
</template>

<script>
import CImage from '@/components/CComponents/CImage'

import { swiper, swiperSlide } from 'vue-awesome-swiper'

export default {
  components: {
    CImage,
    swiper,
    swiperSlide
  },
  props: {
    companies: {
      type: Array,
      required: true
    },
    isLoading: {
      type: Boolean,
      required: true
    },
    error: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      password: '',
      touched: false,
      swiperOptions: {
        spaceBetween: 20,
        slidesPerView: 3,
        freeMode: true,
        navigation: {
          nextEl: '.swiper-button.-next',
          prevEl: '.swiper-button.-prev',
          disabledClass: '-disabled',
          hiddenClass: '-hidden'
        },
        breakpoints: {
          500: { slidesPerView: 2 },
          768: { slidesPerView: 3 }
        }
      },
      imagePlaceholder: require('@/assets/img/campaign-placeholder.svg')
    }
  },
  computed: {
    isDirty () {
      if (this.touched) return true
      this.touched = !!this.password
      return this.touched
    },
    isPasswordInvalid () {
      const isFieldInvalid = this.isDirty && !this.password
      return isFieldInvalid || this.error.type === 'invalidCredentials'
    },
    passwordMessage () {
      if (!this.isPasswordInvalid) return ''
      return this.errors.first('senha') || 'Senha incorreta'
    },
    carouselClasses () {
      return [
        'carousel',
        { '-center-2': this.companies.length === 2 }
      ]
    }
  }
}
</script>

<style lang="scss">
@import '~@/styles/reference';
.LoginContent {

  @include LoginContent;

  .login-box{
    background-color: #fff;
    max-width: 500px;
    padding: 50px;
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    box-shadow: 1px 1px 4px 0 rgba(0,0,0,0.1);

    @include desktop {
      min-width: 500px;
    }

    & > .title {
      margin-bottom: 20px;

      & > .c-title > .text { font-size: 20px }
    }

    & > .text { font-size: $base-font-size; }

    @include mobile {
      margin: 0 auto;
    }

    @include desktop {
      min-width: 500px;
    }

    & >.carousel {
      margin: 0 auto;
      margin-top: 25px;
      position: relative;
      overflow: hidden;
      width: 100%;

      &.-center-2 .swiper-wrapper { justify-content: space-around; }

      .swiper-wrapper {
        display: flex;
      }

      .swiper-slide {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 130px;
        min-width: 100px;

        & >.club-logo {
          width: 60px;
          height: 60px;
          display: flex;
          flex-direction: row;
          justify-content: center;
          align-items: center;
          border: 1px solid rgba(18, 30, 72, 0.1);
          border-radius: 50%;

          img {
            width: 45px;
            display: block;
          }
        }

        & >.club-name {
          text-align: center;
          font-size: 14px;
          font-weight: bold;
          opacity: 0.8;
          color: #121E48;

          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          width: calc(100% - 15px);

          @include desktop {
            margin-left: 0;
            margin-top: 10px;
          }
        }

        & >.item {
          width: 150px;
          height: 75px;
          display: flex;
          justify-content: center;
          align-items: center;

          img {
            max-width: 60px;
            max-height: 40px;
          }
        }
        & >.text {
          margin-top: 5px;
          text-transform: uppercase;
          font-size: 11px;
          color: rgba(18,30,72,0.8);
        }
      }

      & > .swiper-button {
        z-index: 20;
        display: block;
        position: absolute;
        cursor: pointer;
        top: calc(50% - 15px);
        border-radius: 50%;
        width: 60px;
        height: 60px;
        transition: opacity .3s ease;

        background-color: rgba(#FFF, 0.8);
        transition: background-color 200ms;

        &:hover { background-color: #FFF; }

        & > .c-icon.icon {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
        }

        &.-disabled,
        &.-hidden { opacity: 0; }

        &.-prev {
          left: 0;
          transform: translate(-50%, -50%);

          & > .c-icon.icon { right: 8px; }
        }

        &.-next {
          right: 0;
          transform: translate(50%, -50%);

          & > .c-icon.icon { left: 8px; }
        }
      }
    }

    & >.form {
      margin-top: 20px;

      & >.text {
        margin-bottom: 15px;
        font-size: $base-font-size;
      }

      input {
        &:hover,
        &:active,
        &:focus {
          border-color: $color-primary-login;
          box-shadow: 0 0 0 1px $color-primary-login;
          outline: $color-primary-login;
        }
      }

      & > .under-input {
        margin: 5px 0;
        display: flex;
        position: relative;
        justify-content: space-between;

        & > .forget-pwd {
          position: absolute;
          font-size: 12px;
          right: 0;
          color: #8FB2F4;
          font-family: $base-font-family;
          font-weight: 100;
          text-decoration: none;
          cursor: pointer;
        }
      }
    }

    & >.btn {
      margin-top: 30px;
    }
  }
}
</style>
