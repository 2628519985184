<template>
  <component
    :is="componentIs"
    :to="to"
    class="empty-banner-link"
    @click="$emit('button-click')"
  >
    <div
      data-cy="empty-banner"
      class="empty-banner"
    >
      <div
        class="banner-content"
      >
        <span class="description">
          {{ title }}
        </span>
        <k-button
          data-cy="new-empty-button"
          no-icon
          class="new-empty-button"
          @click.native="$emit('button-click')"
        >
          <span class="icon">+</span>
          {{ button }}
        </k-button>
      </div>
    </div>
  </component>
</template>

<script>
import { KButton } from 'kaledo-components'

export default {
  components: {
    KButton
  },
  props: {
    to: String,
    title: String,
    button: String
  },
  data () {
    return {
      onHover: false
    }
  },
  computed: {
    componentIs () {
      if (!this.to) return 'div'
      return 'router-link'
    }
  }
}
</script>

<style lang="scss">
@import '~@/styles/reference';

.empty-banner-link {
  text-decoration: none;
}
.empty-banner {
  width: 100%;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(248, 249, 253, 1);
  border: 1px solid rgba(94, 102, 132, 1);
  border-radius: 4px;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.16);
  transition: all .5s ease;
  cursor: pointer;

  > .banner-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .description {
      font-size: 18px;
      color: $base-color;
      @include mobile {
        font-size: 16px;
        text-align: center;
      }
    }

    .new-empty-button {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 200px;
      height: 40px;
      border-radius: 30px;
      font-size: 12px;
      margin-top: 16px;
      font-weight: 400;
      background: white;
      color: #5E6684;
      border: 1px solid #5E6684;
      transition: all ease 0.3s;

      .icon {
        position: relative;
        font-size: 20px;
        bottom: 2px;
        left: 0;
        margin-right: 5px;
      }
      &:hover {
        background: #5E6684;
        border: none;
        color: white
      }
    }
  }
}
</style>
