<template>
  <div class="campaign-filters">
    <h4
      v-if="!filters.includes('search') || filters.length === 0"
      class="title"
    >
      {{ title }}
    </h4>
    <div class="filters-content">
      <div
        v-if="filters.includes('radio-buttons') || filters.length === 0"
        class="radio-buttons"
      >
        <h5 class="filter-label">
          Filtrar por local de exposição:
        </h5>
        <radio-buttons
          class="radio-buttons"
          :options="radioButtons"
          :selected-value.sync="radioOption"
          @input="radioOption = $event"
        />
      </div>
      <div
        v-if="filters.includes('search') || filters.length === 0"
        class="search-bar"
      >
        <div class="input-container">
          <k-icon
            class="input-icon"
            icon="search1"
          />
          <input
            class="input-field"
            type="text"
            placeholder="Buscar por nome de oferta ou parceria"
            @input="search = $event"
          />
        </div>
        <k-button
          no-icon
          class="kbutton"
          :loading="loading"
          :disabled="!validFiltersForm"
          @click.native="emitFilters"
        >
          <span v-if="!loading">Buscar</span>
        </k-button>
      </div>
    </div>
  </div>
</template>

<script>
import { KButton, KIcon } from 'kaledo-components'
import RadioButtons from './RadioButtons.vue'

export default {
  name: 'BannersFilters',
  components: {
    KIcon,
    KButton,
    RadioButtons
  },
  props: {
    loading: Boolean,
    title: String,
    campaignTypes: Array,
    filters: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      search: '',
      radioOption: 'campaign',
      radioButtons: [
        {
          label: 'Incluir exposição em Vitrine de Ofertas',
          value: 'campaign'
        },
        {
          label: 'Somente exposição em páginas de ofertas',
          value: 'partner'
        }
      ],
      type: '',
      types: [
        {
          label: 'Todos os tipos de oferta',
          value: ''
        },
        {
          label: 'Gerais',
          value: 'gerais'
        },
        {
          label: 'Exclusivas',
          value: 'exclusivas'
        }
      ]
    }
  },
  computed: {
    validFiltersForm () {
      return (this.radioOption && this.search) || this.type
    }
  },
  methods: {
    emitFilters () {
      const filters = {
        ...(this.radioOption) && {search_type: this.radioOption.value},
        ...(this.type !== '') && {campaign_type: this.type},
        ...(this.search) && {search: ''}
      }
      this.$emit('filters', filters)
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/styles/reference.scss';

.campaign-filters {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0 0 20px 0;

  > .title {
    width: 100%;
    text-align: left;
    margin-top: 10px;
    margin-bottom: 20px;
    font-size: 14px;
    text-transform: uppercase;
    color: #5E6684;
  }
  .filter-container {
    max-width: 230px;
    width: 100%;
    margin-right: 64px;
    @include tablet {
      width: 100%;
      max-width: 230px;
      margin-right: 20px;
    }
    @include mobile {
      margin-right: 0;
      max-width: 100%;
      margin-bottom: 20px;
    }
  }

  > .filters-content {
    width: 90%;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    @include mobile {
      flex-wrap: wrap;
    }

    .filter-label {
      color: #5E6684;
      font-size: 11px;
      font-weight: 400;
      text-transform: uppercase;
      margin-bottom: 8px;
    }

    > .radio-buttons {
      width: 100%;
      max-width: 240px;
      height: 65px;
      font-family: $base-font-family;

      label {
        color: #5E6684;
        font-size: 11px;
        font-weight: 400;
      }

      input {
        margin-right: 5px;
      }
      @include mobile {
        margin-bottom: 20px;
      }
    }
    .kdropdown {
      border-radius: 4px;
      max-width: 230px;
      height: 40px;
      margin-top: 13px;
      border: 1px solid #bec0d3;
      padding: 0;
      @include mobile {
        max-width: 100%;
      }

      & :deep  .c-select-container {
        height: 40px;
      }

      & :deep  .c-select {
        flex-direction: row-reverse;
        height: 40px;
      }

      & :deep  .dropdown-button {
        padding-left: 0;
        text-transform: none;
        font-weight: 400;
      }

      & :deep  .false-placeholder {
        left: 0;
        top: 0;
        display: none !important;
      }

      & :deep  .label {
        display: none;
      }
    }
    .search-bar {
      display: flex;
      justify-content: space-between;
      width: 100%;
      max-width: 510px;
      .input-container {
        position: relative;
        max-width: 400px;
        width: 100%;
        margin-right: 8px;

        .input-icon {
          font-size: 20px;
          color: #BEC0D3;
          margin-right: 18px;
          position: absolute;
          left: 25px;
          top: 10px;
        }
        .input-field {
          width: 100%;
          border-radius: 30px;
          height: 40px;
          padding: 10px 16px 10px 50px;
          border: 1px solid #5e6684;
          font-size: 14px;
          color: $title-color;
          outline: $title-color;
          transition: all ease 0.2s;
          &:focus {
            border-width: 2px;
          }
          &::placeholder {
            color: #BEC0D3;
          }
        }
      }
      .kbutton {
        max-width: 100px;
        height: 40px;
        border-radius: 30px;
        font-weight: 400;
        font-size: 12px;

        & :deep .loading {
          width: 100%;
          left: auto;
          bottom: auto;
          position: relative;
        }
      }
    }
  }
}
</style>
