<template>
  <div class="question-button">
    <c-info-button
      data-cy="question-button"
      @btn-click="handleClick"
    />

    <c-modal
      v-if="info && !modalOutside"
      class="question-button-modal"
      name="question-button-modal"
      :is-opened="openModal"
      @close="openModal = false"
    >
      <c-title
        class="title"
        icon="magic-button"
        v-if="info && info.title"
      >
        {{ info.title }}
      </c-title>

      <div
        class="modal-body"
        data-cy="question-button-modal"
      >
        <template v-if="info.text">
          <h6 class="question">
            O que é?
          </h6>
          <p class="text">
            {{ info.text }}
          </p>
        </template>
        <slot v-else />
      </div>
    </c-modal>
  </div>
</template>

<script>
import { KIcon } from 'kaledo-components'
import CModal from '@/components/CComponents/CModal'
import CInfoButton from './CInfoButton.vue'

export default {
  name: 'cQuestionButton',
  components: {
    KIcon,
    CModal,
    CInfoButton
  },
  props: {
    info: Object,
    modalOutside: {
      type: Boolean,
      default: false
    },
    noSpace: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      openModal: false
    }
  },
  methods: {
    handleClick () {
      if (!this.modalOutside) this.openModal = true
      else this.$emit('open-modal')
    }
  }
}
</script>

<style lang='scss' scoped>
@import '~@/styles/reference';

.question-button {
  .button {
    border: none;
    background: none;
    &.-space {
      margin-left: 8px;
    }
    cursor: pointer;
    .icon {
      font-size: 20px;
      color: $base-color;
      cursor: pointer;
      transition: all .1s ease;

      &:hover {
        border: 3px solid rgba(94, 102, 132, 1);
        border-radius: 50px;
      }
    }
  }
  .question-button-modal {
    .modal-body {
      margin-top: 40px;
      margin-bottom: 4px;
      .question {
        text-transform: uppercase;
        color: #5E6684;
        font-size: 14px;
        font-weight: 400;
        margin-bottom: 8px;
      }
      .text {
        color: $base-color;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
      }
    }
  }
}
</style>
