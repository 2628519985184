<template>
  <distribution-details-container />
</template>

<script>
import DistributionDetailsContainer from '~/containers/Engagement/pages/DistributionDetailsContainer'
export default {
  components: { DistributionDetailsContainer }
}
</script>

<style>
</style>
