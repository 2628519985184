<template>
  <c-modal
    :is-opened="isOpened"
    @close="close"
    name="filter-menu"
    class="filter-menu"
  >
    <c-title
      class="title"
      size="3"
      icon="equalizer"
    >
      Filtro
    </c-title>

    <div class="action">
      <c-button
        size="lg"
        @click="clearSelection"
      >
        Limpar seleção
      </c-button>
    </div>

    <filter-group
      v-if="showCategories"
      title="Filtrar por categorias"
    >
      <filter-toggler
        class="filter"
        v-for="filter in categories"
        :key="filter.name"
        :name="filter.name"
        :icon="filter.icon"
        :selected="params[filter.action]"
        @click.native="handleCategory(filter.action)"
      />
    </filter-group>

    <filter-group
      class="popover-filter-group"
      title="Filtrar por status"
      v-if="showStatus"
    >
      <filter-toggler
        class="filter"
        v-for="filter in status"
        :key="filter.name"
        :name="filter.name"
        :icon="filter.icon"
        :selected="params[filter.action]"
        @click.native="handleStatus(filter.action)"
      />
    </filter-group>

    <filter-group title="Ordenar por">
      <!--
        <c-button slot="actions" @click="clean(ordinators)">
          Limpar seleção
        </c-button>
      -->

      <filter-toggler
        class="filter"
        v-for="ordinator in ordinators"
        :key="ordinator.name"
        :name="ordinator.name"
        :icon="ordinator.icon"
        :selected="params[ordinator.action]"
        @click.native="handleOrder(ordinator.action)"
      />
    </filter-group>

    <c-button
      class="apply"
      icon="chevron-right"
      size="sm"
      primary
      block
      @click.native="handleApply"
    >
      Aplicar
    </c-button>
  </c-modal>
</template>

<script>
import ordinators from '@/store/filters/ordinators.json'
import filters from '@/store/filters/filters.json'
import * as object from '@/modules/object'

import CModal from '@/components/CComponents/CModal'
import FilterGroup from '@/components/Filter/FilterGroup'
import FilterToggler from '@/components/Filter/FilterToggler'

export default {
  components: { CModal, FilterGroup, FilterToggler },
  props: {
    showStatus: {
      type: Boolean,
      default: true
    },
    showCategories: {
      type: Boolean,
      default: true
    },
    isOpened: {
      type: Boolean,
      required: true
    },
    filters: {
      type: Object,
      required: true
    }
  },
  data () {
    const getActions = (filters) => {
      const actions = filters.map((_) => _.action)
      return object.reduce(actions, (_, property) => ({ [property]: false }))
    }

    const params = {
      ...getActions(filters.categories),
      ...getActions(filters.status),
      ...getActions(ordinators)
    }

    if (filters.categories.lenght > 0) {
      Object.keys(params).forEach(key => {
        if (this.filters[key] || this.filters.categories.find(c => c === key)) { params[key] = true }
      })
    }

    return {
      categories: [ ...filters.categories ],
      status: [ ...filters.status ],
      ordinators: [ ...ordinators ],
      params
    }
  },
  mounted () {
    const toBoolean = (value) => typeof value === 'boolean' ? value : value === 'true'
    const actions = []
    if (Array.isArray(this.filters)) {
      actions.push(...this.filters.map((_) => _.action))
    }
    if (Array.isArray(this.ordinators)) {
      actions.push(...this.ordinators.map((_) => _.action))
    }
    const params = Object.keys({ ...this.params, ...this.$route.query }).reduce((obj, key) => {
      obj[key] = actions.includes(key) ? toBoolean(this.$route.query[key]) : this.$route.query[key]
      return obj
    }, {})
    this.params = params
  },
  methods: {
    close () {
      const styles = {
        overflow: '',
        position: '',
        height: ''
      }
      Object.assign(document.body.style, styles)
      Object.assign(document.documentElement.style, styles)
      this.$emit('close')
    },
    clean (filters) {
      const actions = filters.map((_) => _.action)
      const params = object.reduce(this.params, (action, value) => ({
        [action]: actions.includes(action) ? false : value
      }))

      this.params = params
      this.$emit('clear-filters')
    },
    clearSelection () {
      this.$emit('clean')
      this.clean(this.categories)
      this.clean(this.status)
      this.clean(this.ordinators)
    },
    handleOrder (action) {
      const value = !this.params[action]
      if (value) this.clean(this.ordinators)
      this.params[action] = value

      let ordinators = { asc: null, desc: null }
      ordinators[action] = value ? 'name' : null
      this.$emit('update-filters', ordinators)
    },
    handleCategory (action) {
      const value = !this.params[action]
      let categories = value
        ? [ ...this.filters.categories, action ]
        : [ ...this.filters.categories.filter(c => c !== action) ]

      this.$emit('update-filters', { categories })
      this.params[action] = value
    },
    handleStatus (action) {
      const value = !this.params[action]
      if (value) this.clean(this.status)

      this.params[action] = value
      this.$emit('update-filters', { status: value ? action : '' })
    },
    handleApply () {
      this.$emit('apply-filters')
      this.close()
    }
  }
}
</script>

<style lang="scss">
@import '~@/styles/reference';

.filter-menu {
  background-color: $background-color !important;
  z-index: 30 !important;
  padding: 10px;
  padding-bottom: 55px;

  & > .content > .title {
    margin-bottom: 20px;
  }

  & > .content > .filter-group:last-of-type {
    padding-bottom: 55px;
  }

  & .action {
    display: flex;
    justify-content: flex-end;
    padding-bottom: 10px;
  }

  & .ordenation {
    padding-bottom: 30px;
  }
}

.filter-menu-header {
  display: flex;
  height: 60px;
  justify-content: flex-start;
  align-items: center;
}
</style>
