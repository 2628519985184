<template>
  <div class="duration-inputs">
    <div class="input-div">
      <c-input
        data-cy="notification-start-date-input"
        class="input start-date-input"
        icon="calendar"
        ref="start_date"
        name="start_date"
        label="Data de Início"
        autocomplete="off"
        aria-autocomplete="none"
        mask="##/##/####"
        placeholder="DD/MM/AAAA"
        readonly="readonly"
        v-validate="{ date_format: 'dd/MM/yyyy', after: today }"
        validation-icon="warning-circle"
        watch
        :value="localStartDate"
        :feedback-show="!!invalidStartDateMessage"
        :feedback-message="invalidStartDateMessage"
        @click.native="showStartDate = true"
      />
      <c-popover
        v-if="showStartDate"
        :position="['top', 'center']"
        :target="$refs.start_date"
        @blur="showStartDate = false"
      >
        <c-date
          data-cy="notification-start-date-datepicker"
          class="datepicker input"
          inline
          monday-first
          :calendar-class="'calendar'"
          :language="ptBR"
          :value="datePickerStartDate"
          :disabled-dates="disabledStartDates"
          @input="updateStartDate($event)"
        />
      </c-popover>
    </div>
    <div class="input-div">
      <c-input
        data-cy="notification-end-date-input"
        class="input"
        icon="calendar"
        ref="end_date"
        name="end_date"
        label="Data Final"
        autocomplete="off"
        aria-autocomplete="none"
        mask="##/##/####"
        placeholder="DD/MM/AAAA"
        v-validate="{ date_format: 'dd/MM/yyyy', after: today }"
        validation-icon="warning-circle"
        readonly="readonly"
        watch
        :value="localEndDate"
        :feedback-show="!!invalidEndDateMessage"
        :feedback-message="invalidEndDateMessage"
        @click.native="showEndDate = true"
      />
      <c-popover
        v-if="showEndDate"
        :position="['top', 'center']"
        :target="$refs.end_date"
        @blur="showEndDate = false"
      >
        <c-date
          data-cy="notification-end-date-datepicker"
          class="datepicker input"
          inline
          monday-first
          :calendar-class="'calendar'"
          :language="ptBR"
          :value="datePickerEndDate"
          :disabled-dates="disabledEndDates"
          @input="updateEndDate($event)"
        />
      </c-popover>
    </div>
  </div>
</template>

<script>
import { ptBR } from 'vuejs-datepicker/dist/locale'
import CDate from 'vuejs-datepicker'
import CPopover from '@/components/CComponents/CPopover'
import moment from 'moment'
export default {
  components: {
    CDate,
    CPopover
  },
  props: {
    startDate: String,
    endDate: String,
    invalidStartDateMessage: String,
    invalidEndDateMessage: String,
    disabledStartDates: Object,
    disabledEndDates: Object
  },
  data () {
    return {
      ptBR,
      showStartDate: false,
      showEndDate: false
    }
  },
  computed: {
    today () {
      return moment().format('DD/MM/yyyy')
    },
    localStartDate: {
      get () {
        return this.startDate
      }
    },
    localEndDate: {
      get () {
        return this.endDate
      }
    },
    datePickerStartDate () {
      return this.startDate
        ? moment(this.startDate, 'DD/MM/YYYY').format('MM/DD/YYYY')
        : ''
    },
    datePickerEndDate () {
      return this.endDate
        ? moment(this.endDate, 'DD/MM/YYYY').format('MM/DD/YYYY')
        : ''
    }
  },
  methods: {
    updateStartDate (value) {
      this.showStartDate = false
      this.$emit('update:start-date', value)
    },
    updateEndDate (value) {
      this.showEndDate = false
      this.$emit('update:end-date', value)
    }
  }
}
</script>
<style lang="scss" scoped>
.duration-inputs {
  display: flex;
  .input-div {
    display: flex;
    align-items: flex-end;
    gap: 8px;
    &:first-of-type {
      margin-right: 24px;
    }
    .input {
      width: 300px;
    }
    .subtext {
      color: #5E6684;
      font-size: 14px;
      margin-bottom: 8px;
    }
  }
}
</style>
