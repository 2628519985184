<template>
  <c-card :class="classes">
    <div class="top-section">
      <partner-tag
        class="tag"
        v-if="isExclusive"
        :is-exclusive="isExclusive"
      />

      <c-image
        name="listing-card-image"
        :class="['partner-logo', { '-exclusive': isExclusive }]"
        :src="logo"
        :alt="name"
        :placeholder="imagePlaceholder"
        is-background
      />
    </div>

    <div class="bottom-section">
      <div class="description">
        <p class="partner-name">
          {{ name }}
        </p>
        <p class="partner-category">
          {{ category }}
        </p>
      </div>
      <div class="partners-campaign-count">
        <span class="icon">
          <c-icon
            icon="target"
            size="15"
          />
        </span>
        <span class="campaign-count">{{ campaignCount }}</span>
        <span class="text">ofertas</span>
      </div>
      <div class="partner-actions">
        <c-toggle
          sync
          await
          :height="30"
          :width="100"
          :disabled="isLoading"
          :labels="{ checked: 'Ativo', unchecked: 'Inativo' }"
          :value="status"
          @input="toggleStatus"
          class="toggle"
        />
        <c-button
          v-if="isExclusive"
          icon="loup-plus"
          class="details"
          size="md"
          data-cy="partner-card-edit-button"
          @click.stop="openPartner(slug)"
        />
      </div>
    </div>
  </c-card>
</template>

<script>
import CCard from '@/components/CComponents/CCard'
import CImage from '@/components/CComponents/CImage'
import PartnerTag from '@/components/Partners/PartnerTag'
import * as types from '@/store/types'
import { mapGetters } from 'vuex'
export default {
  components: { CCard, CImage, PartnerTag },
  props: {
    id: String,
    name: String,
    logo: String,
    slug: String,
    category: String,
    isLoading: Boolean,
    isExclusive: Boolean,
    campaignCount: Number,
    status: [Number, Boolean]
  },
  data () {
    return { imagePlaceholder: require('@/assets/img/campaign-placeholder.svg') }
  },
  computed: {
    ...mapGetters({ company: types.COMPANY }),
    classes () {
      return [
        'partner-card',
        {
          '-exclusive': this.isExclusive,
          '-inactive': !this.status
        }
      ]
    }
  },
  mounted () {
    this.localStatus = this.status
  },
  methods: {
    toggleStatus (ev) {
      this.$emit('toggle-partner-status', {
        slug: this.slug,
        name: this.name,
        status: this.status,
        isExclusive: this.isExclusive
      })
    },
    openPartner (slug) {
      this.$router.push({ name: 'parceiros-slug', params: { slug } })
      this.handleAnalytics('detalhes-parceiro', {
        company_name: this.company.name,
        partner_name: this.name,
        partner_slug: slug
      })
    },
    handleAnalytics (event, data) {
      window.dataLayer.push(
        {
          event,
          ...data
        }
      )
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/styles/reference';

.partner-card {
  position: relative;

  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;

  &.-inactive { background-color: #FBFCFE; }

  @include mobile {
    align-items: flex-end;
    min-height: 150px;
    min-width: 300px;

    &:not(.-exclusive) > .top-section > .partner-logo {
      margin: 0 -10px 0 20px;
    }
  }

  @media only screen and (min-width: 549px) and (max-width: 649px) {
    flex-grow: 1;
  }

  @include desktop {
    flex-direction: column;
    width: 160px;
    min-height: 225px;
  }

  .top-section {
    display: flex;
    align-self: center;
    align-items: center;
    flex-direction: column;

    @include mobile { max-width: 120px; }
    @include desktop { width: 100%; }

    & > .partner-tag {
      position: absolute;
      top: 0;
      transform: translateX(-50%);

      @include desktop { left: calc(50% + 18.5px); }
    }

    .partner-logo {
      flex: 50%;
      max-width: 90px;
      max-height: 70px;
      margin: 0 20px 0 50px;

      @include desktop { margin: 25px 25px 0 25px; }

      background-size: contain;
      background-clip: border-box;
      background-repeat: no-repeat;
      background-position: center;
      padding: 35px 45px;

      &.-exclusive {
        @include desktop { margin: 40px 20px 0 20px; }
      }
    }
  }

  .bottom-section {
    display: flex;
    flex-direction: column;

    width: 100%;
    max-width: calc(100% - 130px);

    padding: 10px 0 10px;

    @include desktop {
      flex-grow: 1;
      max-width: 140px;
      justify-content: flex-end;
    }

    @include mobile {
      margin: { right: 10px; left: 25px; }
      padding-bottom: 10px;
    }

    .description {
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 100%;

      @include desktop { overflow: hidden; text-align: center; }

      .partner-name {
        color: $icon-color;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      .partner-category {
        color: $base-color;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }

    .partners-campaign-count {
      display: flex;
      position: relative;
      align-items: center;
      color: $title-color;
      font-size: 14px;

      margin-top: 5px;
      margin-bottom: 5px;

      @include desktop {
        justify-content: center;
        border-top: 1px solid #F3F3F4;
        padding-top: 9px;
      }

      .icon {
        left: 0px;
        @include desktop { left: 30px; }
        display: flex;
        position: absolute;
      }

      .campaign-count { font-weight: 600; margin-left: 20px; }
      .text:not(:only-child) { margin-left: 5px; }
    }

    .partner-actions {
      display: flex;
      align-items: center;
      justify-content: flex-end;

      @include mobile { margin-top: 10px; }

      @include desktop {
        display: flex;
        justify-content: center;
        align-items: center;
      }

      & > .details { margin-left: 5px; }
    }
  }
}
</style>
