import { render, staticRenderFns } from "./DashButton.vue?vue&type=template&id=214fbe09&scoped=true"
import script from "./DashButton.vue?vue&type=script&lang=js"
export * from "./DashButton.vue?vue&type=script&lang=js"
import style0 from "./DashButton.vue?vue&type=style&index=0&id=214fbe09&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "214fbe09",
  null
  
)

export default component.exports