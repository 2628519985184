<template>
  <awards-orders-container />
</template>

<script>
import AwardsOrdersContainer from '~/containers/Awards/AwardsOrdersContainer'
export default {
  components: { AwardsOrdersContainer }
}
</script>

<style>

</style>
