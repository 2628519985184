<template>
  <component
    :is="`h${size}`"
    :class="classes"
  >
    <div
      :class="[ ...classes, 'content' ]"
    >
      <c-icon
        v-if="icon && !useKIcon"
        class="icon"
        :icon="icon"
        :size="iconSize"
      />
      <k-icon
        v-if="icon && useKIcon"
        class="icon"
        :icon="icon"
        :size="iconSize"
      />
      <span class="text">
        <slot />
      </span>
    </div>
  </component>
</template>

<script>
import { KIcon } from 'kaledo-components'

export default {
  components: { KIcon },
  props: {
    white: Boolean,
    pink: Boolean,
    textwhite: Boolean,
    size: {
      type: [String, Number],
      default: 1
    },
    icon: String,
    iconSize: {
      type: String,
      default: '20'
    },
    useKIcon: {
      type: Boolean,
      default: false
    },
    primaryIcon: Boolean,
    secondaryIcon: Boolean,
    showBorder: {
      type: Boolean,
      default: true
    },
    previewMode: {
      type: Boolean,
      default: false
    },
    borderColor: {
      type: String,
      default: null
    }
  },
  computed: {
    classes () {
      const classes = ['c-title', {
        '-white': this.white,
        '-pink': this.pink,
        '-textwhite': this.textwhite,
        '-with-border': this.showBorder,
        '-primary-icon': this.primaryIcon,
        '-secondary-icon': this.secondaryIcon,
        '-preview': this.previewMode
      }]
      return classes
    }
  }
}
</script>

<style lang="scss">
@import '~@/styles/reference';

// Bar settings
$c-title-bar-size: 90px 3px;
$c-title-bar-color: $secondary-color-placeholder;
$c-title-bar-color: var(--color-secondary);

// Icon settings
$c-title-icon-color: $icon-color;

// Text settings
$c-title-text-color: $title-color;
$c-title-text-font-size: 16px;
$c-title-text-font-weight: 500;
$c-title-text-font-family: $title-font-family;

.c-title {
  align-items: center;
  margin: 0;
  line-height: 1;
  font-size: 0;

  &:not(.-with-border) { display: flex; }

  &.content {
    display: flex;
    align-items: center;
    justify-content: center;

    margin-bottom: 0 !important;
    flex-grow: 1;
  }

  & > .text {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    font-size: $c-title-text-font-size;
    font-weight: $c-title-text-font-weight;
    font-family: $c-title-text-font-family;
    color: $c-title-text-color;
  }

  & > .icon {
    margin-right: 10px;
    vertical-align: bottom;
    fill: $c-title-icon-color;
    flex-shrink: 0;
  }

  &.-primary-icon {
    .icon > path {
      fill: var(--color-primary);
    }
  }

  &.-secondary-icon {
    .icon > path {
      fill: var(--color-secondary);
    }
  }

  &.-with-border:not(.content)::after {
    @include size($c-title-bar-size);
    display: block;
    margin-top: 8px;
    content: '';
  }

  &:not(.-white)::after {
    background-color: $c-title-bar-color;
  }
  &:not(.-white).-preview::after {
    background-color: v-bind(borderColor)
  }

  &.-white::after {
    background-color: #FFF;
  }
  &.-pink:after {
    background-color: $color-primary-login;
  }
}
</style>
