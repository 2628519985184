<template>
  <new-notification
    :loading="loading"
    :partners-loading="loading"
    :partners="partners.list"
    :notification-loading="notificationLoading"
    :notification="notification"
    :is-edit="isEdit"
    @submit-notification="notification => handleSubmitNotification(notification)"
  />
</template>

<script>
import NewNotification from '@/components/Communication/Notifications/New/NewNotification.vue'
import { mapGetters, mapActions } from 'vuex'
import {
  COMPANY,
  PARTNERS,
  NOTIFICATION,
  CREATE_NOTIFICATION,
  FEEDBACK,
  GET_PARTNER_CAMPAIGNS,
  GET_NOTIFICATION_DETAILS,
  UPDATE_NOTIFICATION
} from '@/store/types'

export default {
  components: {
    NewNotification
  },
  data () {
    return {
      loading: false,
      partnersLoading: true,
      notificationLoading: false,
      notificationId: null
    }
  },
  computed: {
    ...mapGetters({
      notification: NOTIFICATION,
      company: COMPANY,
      partners: PARTNERS
    }),
    isEdit () {
      return !!this.$route.params.notificationId
    }
  },
  async created () {
    if (this.$route.params.notificationId) { this.loadNotification() }
    this.getPartners({ exclusives: true, status: '1' })
    this.partnersLoading = false
  },
  methods: {
    ...mapActions({
      feedback: FEEDBACK,
      getPartners: GET_PARTNER_CAMPAIGNS,
      getNotification: GET_NOTIFICATION_DETAILS,
      createNotification: CREATE_NOTIFICATION,
      updateNotification: UPDATE_NOTIFICATION
    }),
    handleAnalytics (event, data) {
      window.dataLayer.push(
        {
          event,
          ...data
        }
      )
    },
    async loadNotification () {
      this.notificationLoading = true
      this.notificationId = this.$route.params.notificationId
      await this.getNotification(this.notificationId)
      this.notificationLoading = false
    },
    handleSubmitNotification (notification) {
      if (this.isEdit) {
        this.handleUpdateNotification(notification)
      } else {
        this.handleCreateNotification(notification)
      }
    },
    async handleCreateNotification (notification) {
      this.loading = true
      this.handleAnalytics('notificacao-finalizar', {
        company_name: this.company.slug
      })
      const success = await this.createNotification(notification)
      if (success) {
        this.$router.push({ name: 'comunicacao-notificacoes' })
      } else {
        this.feedback({
          type: 'error',
          title: 'Houve um erro a criar a sua notificação',
          text: 'Por favor, tente novamente mais tarde'
        })
      }
      this.loading = false
    },
    async handleUpdateNotification (notification) {
      this.loading = true
      const success = await this.updateNotification({
        ...notification,
        id: this.notificationId
      })
      if (success) {
        this.$router.push({ name: 'comunicacao-notificacoes' })
      } else {
        this.feedback({
          type: 'error',
          title: 'Houve um erro a editar a sua notificação',
          text: 'Por favor, tente novamente mais tarde'
        })
      }
      this.loading = false
    }
  }
}
</script>

<style>
</style>
