<template>
  <div class="inside-enviroments">
    <div class="header">
      <h3 class="title">
        Ambientes internos
      </h3>
      <p class="subtitle">
        Personalização do painel de gestão e do seu clube.
      </p>
    </div>
    <div class="customize-container">
      <h3 class="title">
        Logotipo da sua marca
      </h3>
      <label class="label">Logotipo</label>
      <upload-image
        class="upload-logo"
        :is-edit="isEdit"
        :max-size="300"
        :required-width="182"
        :required-height="66"
        @change="handleImage"
        @error="error = $event"
      />
      <span class="size">Tamanho: <span class="size-size">182 x 66 px</span></span>
    </div>
    <div class="customize-container">
      <h3 class="title">
        Cores da sua marca
      </h3>
      <div class="colors">
        <color-picker
          class="inside-enviroments-color-picker"
          label="Cor primária"
          :color="data.primary"
          v-model="data.primary"
        />
        <color-picker
          class="inside-enviroments-color-picker"
          label="Cor secundária"
          :color="data.secondary"
          v-model="data.secondary"
        />
        <color-picker
          class="inside-enviroments-color-picker"
          label="Cor terciária"
          :color="data.terciary"
          v-model="data.terciary"
        />
      </div>
    </div>
    <preview-home-page
      :loading="logoLoading"
      :data="data"
    />
    <footer-buttons
      :is-valid="!isValid"
      :is-processing="submitLoading"
      @save="handleSave"
      @back="openConfirmationModal = true"
    />
    <c-confirm-modal
      :btn-props="{ primary: true }"
      confirm-text="Sair sem salvar"
      character="embarrassed"
      title="Tem certeza que deseja sair sem salvar?"
      message="Se sair, todas as informações já inseridas não serão salvas."
      :is-opened="openConfirmationModal"
      @close="openConfirmationModal = false"
      @confirm="$router.push('/personalizacao')"
    />
  </div>
</template>

<script>
import { isHexColor } from '@/modules/validate/validators.js'
import { mapGetters, mapActions } from 'vuex'
import { COMPANY, COMPANY_STYLE } from '@/store/types'
import UploadImage from '@/components/General/UploadImage.vue'
import ColorPicker from '@/components/Customization/general/ColorPicker.vue'
import PreviewHomePage from '@/components/Customization/Preview/HomePage.vue'
import FooterButtons from '@/components/Settings/general/FooterButtons.vue'
import CConfirmModal from '@/components/CComponents/CConfirmModal.vue'

export default {
  name: 'InsideEnviroments',
  components: {
    UploadImage,
    ColorPicker,
    PreviewHomePage,
    FooterButtons,
    CConfirmModal
  },
  data () {
    return {
      submitLoading: false,
      modifiedData: false,
      isEdit: false,
      error: false,
      openConfirmationModal: false,
      logoValidation: false,
      logoLoading: false,
      initialData: {
        primary: '#d8d8d8',
        secondary: '#d8d8d8',
        terciary: '#d8d8d8'
      },
      data: {
        logo: [],
        primary: '#d8d8d8',
        secondary: '#d8d8d8',
        terciary: '#d8d8d8'
      }
    }
  },
  computed: {
    ...mapGetters({
      company: COMPANY
    }),
    colorsValidation () {
      const hasAllColors = isHexColor(this.data.primary) && isHexColor(this.data.secondary) && isHexColor(this.data.terciary)
      const atLeastOneColorHasChanged =
        (this.data.primary !== this.initialData.primary) ||
        (this.data.secondary !== this.initialData.secondary) ||
        (this.data.terciary !== this.initialData.terciary)

      return hasAllColors && atLeastOneColorHasChanged
    },
    isValid () {
      if (this.error) return false
      return this.logoValidation || this.colorsValidation
    }
  },
  mounted () {
    this.setInitialData()
  },
  methods: {
    ...mapActions({
      updateCompanyStyle: COMPANY_STYLE,
      getCompany: COMPANY
    }),
    getLogoValidation () {
      if (this.isLogoEmpty) { this.logoValidation === false }
      if (this.editLogoValidation) { this.logoValidation === false }
      const logoIsFile = !!this.data.logo && !!this.data.logo.name
      const hasModifiedData = this.modifiedData && !this.error

      this.logoValidation = (logoIsFile && hasModifiedData)
    },
    handleImage (files) {
      this.logoLoading = true
      if(files.length === 0) {
        this.modifiedData = false
        this.data.logo = null
        this.logoValidation = false
      }
       else {
        this.modifiedData = true
        this.data.logo = files
        this.getLogoValidation()
      }
      setTimeout(() => {
        this.logoLoading = false
      }, 50)
    },
    async handleSave () {
      this.submitLoading = true
      const payload = {
        logo: this.data.logo,
        colors: {
          primary: this.data.primary,
          secondary: this.data.secondary,
          tertiary: this.data.terciary
        }
      }
      if (!this.logoValidation || typeof this.data.logo === 'string') {
        delete payload.logo
      }
      const response = await this.updateCompanyStyle(payload)
      if (response && response === 'success') {
        this.$router.push('/personalizacao')
      }
      this.submitLoading = false
    },
    setInitialData () {
      if (this.company.config.colors) {
        const primary = this.company.config.colors.primary ? this.company.config.colors.primary : '#d8d8d8'
        const secondary = this.company.config.colors.secondary ? this.company.config.colors.secondary : '#d8d8d8'
        const terciary = this.company.config.colors.tertiary ? this.company.config.colors.tertiary : '#d8d8d8'
        this.data = {
          primary,
          secondary,
          terciary
        }
        this.initialData = {
          primary,
          secondary,
          terciary
        }
      }
      this.data.logo = this.company.logo ? this.company.logo : []
      if (typeof this.data.logo === 'string') {
        this.isEdit = true
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/styles/reference';
.inside-enviroments {
  @include desktop { @include container }
  @include mobile {
    max-width: 360px;
    margin: 48px auto;
  }
  margin-top: 48px;
  .header {
    margin-bottom: 56px;
    .title {
      font-size: 22px;
      font-weight: 700;
      color: $base-color;
      margin-bottom: 8px;
    }
    .subtitle {
      font-size: 16px;
      font-weight: 400;
      color: #5E6684;
    }
  }
  .customize-container {
    margin-bottom: 48px;
    .title {
      font-size: 16px;
      font-weight: 700;
      color: $base-color;
      margin-bottom: 24px;
    }
    .label {
      font-size: 10px;
      text-transform: uppercase;
      color: $secondary-color-placeholder;
      margin-bottom: 8px;
    }
    .upload-logo {
      margin-bottom: 8px;
    }
    .size {
      font-size: 14px;
      font-weight: 400;
      color: $base-color;
      display: block;
      padding-bottom: 8px;
      .size-size {
        font-weight: 600;
      }
    }
  }
  .colors {
    width: 100%;
    display: flex;
    height: 77px;
    align-items: baseline;
    justify-content: space-between;
    flex-wrap: wrap;
  }

}
</style>
