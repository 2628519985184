<template>
  <div
    class="c-toggler"
    data-cy="c-toggler"
  >
    <div
      v-for="tab in tabs"
      :class="[ 'tab', { '-active': tab.value === activeTab }, { '-disabled': tab.disabled }]"
      :key="tab.value"
      :ref="tab.value"
      :data-cy="tab.value"
      @click="handleClick(tab.disabled, tab.value)"
    >
      {{ tab.name }}
    </div>

    <div
      class="toggle"
      :style="toggleStyle"
    />
  </div>
</template>

<script>
export default {
  props: {
    tabs: {
      type: Array,
      required: true
    },
    activeTab: String
  },
  data () {
    return {
      toggleStyle: {}
    }
  },
  watch: {
    activeTab () {
      this.getToggleStyle()
    }
  },
  mounted () {
    this.ready = true
    this.getToggleStyle()
    window.addEventListener('resize', this.getToggleStyle)
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.getToggleStyle)
  },
  methods: {
    getToggleStyle () {
      const tabberPosition = this.$el.getBoundingClientRect().left || 0
      const { activeTab, $refs } = this
      const tab = $refs[activeTab]
      const toggleStyle = {
        left: tab ? (tab[0].getBoundingClientRect().left - tabberPosition) + 'px' : 0,
        width: tab ? tab[0].offsetWidth + 'px' : 0
      }
      this.toggleStyle = toggleStyle
    },
    handleClick (isDisabled, value) {
      if (!isDisabled) { this.$emit('change-tab', value) }
    }
  }
}
</script>

<style lang="scss">
@import '~@/styles/reference';

.c-toggler {
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  margin-bottom: 2px;

  & > .tab {
    text-transform: uppercase;
    color: $text-color;
    line-height: 1;
    text-align: center;
    font-weight: 400;
    font-size: 12px;
    transition: color .3s ease;
    cursor: pointer;
    flex-grow: 1;

    padding: {
      top: 30px;
      right: 20px;
      bottom: 15px;
      left: 20px;
    }

    &.-active {
      color: $primary-color-placeholder;
      color: var(--color-primary);
      font-weight: 600;
    }

    &.-disabled {
      color: #D8D8D8;
      cursor: default;
    }
  }

  & > .toggle {
    height: 4px;
    width: 0;
    left: 50%;
    background-color: $secondary-color-placeholder;
    background-color: var(--color-secondary);
    position: absolute;
    bottom: -4px;
    transition: left .3s ease, width .3s ease;
  }
}
</style>
