<template>
  <layout-header
    v-bind="$attrs"
    :user="user"
    :club-url="clubUrl"
    @toggle-sidebar="$emit('toggle-sidebar')"
  />
</template>

<script>
import { mapGetters } from 'vuex'
import { AUTH_ACCESS_CLUB, CONFIG_USER } from '@/store/types'
import LayoutHeader from '@/components/Layout/LayoutHeader'

export default {
  components: { LayoutHeader },

  computed: mapGetters({
    user: CONFIG_USER,
    clubUrl: AUTH_ACCESS_CLUB
  })
}
</script>
