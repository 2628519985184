<template>
  <c-modal
    class="delete-users-modal"
    name="delete-users-modal"
    :is-opened="isOpened"
    @close="$emit('close')"
  >
    <c-title
      data-cy="delete-users-modal-title"
      class="title"
      size="3"
      icon="user-cross"
    >
      Exclusão de Usuários
    </c-title>
    <div class="content">
      <transition name="fade">
        <div v-if="!users || users.length === 0">
          <c-spinner />
        </div>
        <div v-else>
          <div
            data-cy="selected-users-count-chip"
            class="selected-users"
          >
            {{ users.length }} {{ users.length > 1 ? 'usuários selecionados' : 'usuário selecionado' }}
          </div>
          <h4 class="text">
            Deseja excluir os seguintes usuários?
          </h4>
          <delete-users-table
            :use-cpf-mask="useCpfMask"
            :rows="users"
          />
          <div class="buttons">
            <c-button
              data-cy="cancel-remove-button"
              @click="$emit('close')"
            >
              Cancelar
            </c-button>
            <c-button
              data-cy="remove-confirm-button"
              class="send"
              icon="chevron-right"
              primary
              @click="handleClick"
            >
              Confirmar
            </c-button>
          </div>
        </div>
      </transition>
    </div>
  </c-modal>
</template>

<script>
import CModal from '@/components/CComponents/CModal'
import DeleteUsersTable from '../tables/DeleteUsersTable.vue'

export default {
  name: 'DeleteUsersModal',
  components: {
    CModal,
    DeleteUsersTable
  },
  props: {
    isOpened: {
      type: Boolean,
      required: true
    },
    useCpfMask: {
      type: Boolean,
      default: true
    },
    users: Array
  },
  methods: {
    handleClick () {
      this.$emit('delete-users')
      this.$emit('close')
    }
  }
}
</script>

<style lang='scss' scoped>
@import '~@/styles/reference';

.delete-users-modal {
  .content {
    margin-top: 24px;
    .selected-users {
      background: #BEC0D3;
      font-size: 12px;
      color: $base-color;
      max-width: 145px;
      width: 100%;
      text-align: center;
      padding: 0 8px;
      border-radius: 8px;
    }
    .text {
      margin: 16px 0;
      font-size: 16px;
      font-weight: 400;
      color: $base-color;
    }
    .buttons {
      margin: 24px 0px;
      text-align: right;
      .send {
        margin-left: 16px;
      }
    }
  }
}
</style>
