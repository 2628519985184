<template>
  <div
    class="c-select"
    :class="generatedClass"
  >
    <label
      v-if="formLabel"
      class="label"
    >
      <span>
        {{ formLabel }}<span
          v-if="required"
          class="required"
        >*</span>
      </span>
    </label>

    <transition name="c-select-feedback">
      <span
        v-if="feedbackShow && feedbackMessage"
        class="feedback"
      >
        {{ feedbackMessage }}
      </span>
    </transition>

    <div class="inner">
      <c-icon
        v-if="icon"
        :icon="icon"
        class="icon"
        width="20px"
        height="20px"
      />
      <multiselect
        v-on="$listeners"
        v-bind="$attrs"
        :placeholder="placeholder"
        :select-label="selectLabel"
        open-direction="bottom"
        :selected-label="selectedLabel"
        :deselect-label="deselectLabel"
      >
        <template slot="noResult">
          {{ noResultMessage }}
        </template>
      </multiselect>
    </div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import Scroll from '@/directives/Scroll'

export default {
  components: { Multiselect },
  directives: { Scroll },
  props: {
    feedbackType: {
      type: String,
      default: 'error'
    },
    feedbackMessage: {
      type: String,
      default: ''
    },
    feedbackShow: {
      type: Boolean,
      default: false
    },
    noResultMessage: {
      type: String,
      default: 'Não foram encontradas opções. Considere alterar o filtro.'
    },
    placeholder: {
      type: String,
      default: 'Selecione'
    },
    selectLabel: {
      type: String,
      default: 'Pressione enter para selecionar'
    },
    selectedLabel: {
      type: String,
      default: 'Selecionado'
    },
    deselectLabel: {
      type: String,
      default: 'Selecionado'
    },
    formLabel: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      default: ''
    },
    required: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    generatedClass () {
      return {
        '-success': this.feedbackType === 'success',
        '-error': this.feedbackType === 'error',
        '-show-feedback': this.feedbackShow,
        '-has-icon': this.icon,
        '-disabled': this.disabled
      }
    }
  }
}
</script>

<style lang="scss">
@import '~@/styles/convenia-components/reference.scss';
@import '~vue-multiselect/dist/vue-multiselect.min';
@import '~@/styles/reference.scss';

$c-select-font-size:          13px !default;
$c-select-feedback-font-size: 13px !default;
$c-select-label-font-size:    10px !default;
$c-select-font-family: $c-font-family-base !default;

$c-select-highlight-background: color(primary, light-25) !default;
$c-select-active-background: $c-color-primary !default;

$c-select-background-color:          #fdfdfd !default;
$c-select-border-color:              #bec1d4 !default;
$c-select-active-border-color:       #8fb2f4 !default;
$c-select-disabled-background-color: #eaedef !default;
$c-select-disabled-color:            #bdc0d1 !default;
$c-select-color:                     #5e6684 !default;
$c-select-active-font-color:         #ffffff !default;
$c-select-highlight-font-color:      #000000 !default;
$c-select-label-color:               #8c92b2 !default;
$c-select-success-border-color:      #59efb2 !default;
$c-select-success-color:             #59eeb2 !default;
$c-select-error-border-color:        #ff7987 !default;
$c-select-error-color:               #ff7986 !default;
.-disabled .inner .multiselect__tags
 {
  background-color: #D8D8D8;
  .multiselect__tags-wrap .multiselect__tag{
          border: 1px solid $neutral-gray-100;
          background-color: transparent;
          .multiselect__tag-icon::after {
            color: $neutral-gray-100 !important;
          }
    }
 }
.c-select {
  width: 379px;
  & {
    position: relative;
  }

  & > .label {
    color: $c-select-label-color;
    font-size: $c-select-label-font-size;
    text-transform: uppercase;
    margin-bottom: inner-base(0.5);
    display: block;
    line-height: 1;
  }

  & > .label .required {
    color: $c-select-error-color;
    display: inline-block;
    margin-left: 2px;
  }

  & > .feedback {
    font-size: $c-select-feedback-font-size;
    position: absolute;
    bottom: 3px;
    left: 0;
    right: 0;
    line-height: 1;
    z-index: 1;
  }

  &.-show-feedback {
    padding-bottom: outer-base();
  }

  &.-show-feedback.-success {
    & .multiselect .multiselect__tags {
      border-color: $c-select-success-border-color;
    }
    & > .feedback {
      color: $c-select-success-color;
    }
  }

  &.-show-feedback.-error {
    & .multiselect .multiselect__tags {
      border-color: $c-select-error-border-color;
    }
    & > .feedback {
      color: $c-select-error-color;
    }
  }

  &.-has-icon > .inner {
    position: relative;

    & > .icon {
      position: absolute;
      top: 50%;
      right: 15px;
      transform: translateY(-50%);
      z-index: 2;
    }

    .multiselect__single {
      text-indent: 30px;
      padding-top: 2px;
    }
  }

  .multiselect {
    z-index: 1;

    &,
    &__input,
    &__single {
      font-size: $c-select-font-size;
      font-family: $c-select-font-family;
      color: $c-select-color;
    }

    &__input,
    &__single {
      &::placeholder { color: gray; }
    }

    &__select {
      height: 2.5625rem;

      &::before {
        border-top-color: gray;
        color: gray;
      }
    }

    &__tags {
      border-color: $c-select-border-color;
    }

    &__tags,
    &__input {
      background-color: $c-select-background-color;
    }

    &__content-wrapper {
      @include elevation(2);
      margin-top: .3125rem;
      border-radius: 8px;
      max-height: 240px !important;
    }

    &__option {
      &--highlight,
      &--highlight::after {
        background-color: var(--color-primary);
        color: $c-select-active-font-color;
      }

      &--selected,
      &--selected::after {
        background-color: var(--color-primary);
        color: $c-select-active-font-color;
      }

      &--highlight.multiselect__option--selected,
      &--highlight.multiselect__option--selected::after {
        background-color: var(--color-primary);
        color: $c-select-active-font-color;
      }
    }

    & .multiselect__tags {
      transition: border 0.3s ease-in-out;
      will-change: border;;
    }

    &--active {
      z-index: 100;
      &::after { opacity: 1; }

      & .multiselect__tags {
        border-radius: 3px;
        transition: box-shadow 0.3s ease;
        box-shadow: 0 0 0 3px $c-select-active-border-color;
        border-color: transparent;
      }
    }

    &--disabled {
      opacity: 1 !important;
    }

    &--disabled .multiselect__tags,
    &--disabled .multiselect__input {
      color: $c-select-disabled-color;
      background-color: $c-select-disabled-background-color;
      border-color: $c-select-disabled-background-color;
      border-radius: 3px;
      pointer-events: all;
      &:hover { cursor: not-allowed; }
      &::placeholder { color: $c-select-disabled-color; }
    }

    &__tag {
      background-color: var(--color-primary);

      &-icon:hover { background-color: var(--color-primary); }

      &-icon::after { color: var(--color-secondary); }
    }
  }

  &.-show-feedback.-success .multiselect--active {
    & .multiselect__tags {
      box-shadow: 0 0 0 3px $c-select-success-border-color;
    }
  }

  &.-show-feedback.-error .multiselect--active {
    & .multiselect__tags {
      box-shadow: 0 0 0 3px $c-select-error-border-color;
    }
  }
}

.c-select-feedback-enter-active,
.c-select-feedback-leave-active {
  transition: all 0.3s ease-out;
}
.c-select-feedback-enter,
.c-select-feedback-leave-to {
  opacity: 0;
  transform: translateY(-10px);
}
</style>
