<template>
  <div :class="'url-container'">
    <c-input
      class="input"
      :value="value"
      :disabled="disabled"
      placeholder="Ex: meuclube"
      @input="$emit('input', $event)"
      onkeypress="return event.charCode != 32"
      ref="name"
    />
    <div :class="(disabled ? 'lock --disabled ' : 'lock ')">
      .kaledo.com.br
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: String,
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      internalValue: ''
    }
  }
}
</script>

<style lang='scss' scoped>
@import '~@/styles/reference';

.url-container {
    display: flex;
    color: $neutral-gray-200;
    font-family: Nunito Sans;
    font-size: 12px;
    font-style: normal;
    font-weight: 700;
    line-height: 18px;
    letter-spacing: -0.122px;
    width: min-content;

    & > .input {
      border: 1px solid $neutral-gray-100;
      background: $neutral-light-white;
      height: 40px;
      width: 228px;
      color: $neutral-gray-200;
      font-family: Nunito Sans;
      font-size: 12px;
      font-style: normal;
      font-weight: 700;
      line-height: 18px; /* 150% */
      letter-spacing: -0.122px;
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
      margin: 0;

    }

    & > .input:focus, input:focus{
        outline: none;
    }

    & > .input::placeholder{
      color: var(--cor-primary-neutral-1, #8C92B1);
      /* fonte/body/1 */
      font-family: Nunito Sans;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px; /* 150% */
      letter-spacing: -0.122px;
    }

    & > .lock {
      border: 1px solid $neutral-gray-100;
      border-left: none;
      background: $neutral-light-white;
      width: 112px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      height: 42px;
    }

    & > .--disabled {
      background-color: #eaedef;
      color: #bdc0d1;
    }
    :deep .c-input-container {
      border: 1px solid $neutral-gray-100;
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }
    :deep .wrapper-c-input {
      border: none;
    }
}
</style>
