<template>
  <c-modal
    name="partner-status-warning"
    class="partner-status-warning"
    v-bind="$attrs"
    v-on="$listeners"
    feedback
    :is-fullscreen="false"
  >
    <c-character type="embarrassed" />
    <div class="warning-text">
      <h3 class="lead">
        Parceiro Inativo
      </h3>
      <p class="text">
        A partir de agora não será mais possível visualizar ofertas e mídias
        deste parceiro no seu clube.
      </p>
    </div>
    <c-button
      primary
      size="lg"
      icon-size="sm"
      class="btn-dismiss"
      icon="chevron-right"
      @click="$emit('close')"
    >
      Entendi
    </c-button>
  </c-modal>
</template>

<script>
import CModal from '@/components/CComponents/CModal'
import CCharacter from '@/components/CComponents/CCharacter'

export default {
  components: { CModal, CCharacter }
}
</script>

<style lang="scss">
@import '~@/styles/reference';

.partner-status-warning {
  background-color: #FFF !important;

  & > .content {
    display: flex;
    align-items: center;
    flex-direction: column;

    & > .c-character {
      width: 70px;
      height: 70px;
      padding: 50px;
    }

    & > .warning-text {
      text-align: center;

      & > .lead { color: $title-color; margin-top: 20px; }
      & > .text {
        font-size: 14px;
        color: $text-color;

        max-width: 340px;
        margin: 17px 0 40px 0;
      }
    }

    & > .btn-dismiss { padding-left: 40px; padding-right: 40px; }
  }
}
</style>
