<template>
  <div :class="['campaign-list-header', { '-inactive': !partnerStatus }]">
    <div class="header-group">
      <div class="partners-info">
        <c-image
          data-cy="partner-logo"
          :class="['partner-logo', { '-placeholder': !partner.logo }]"
          :src="partner.logo"
          :alt="partner.name"
          :placeholder="imagePlaceholder"
        />
        <div class="partners-text">
          <p data-cy="partner-name" name="name-partners">
            {{ partner.name }}
          </p>
          <p data-cy="partner-category" class="text">
            {{ partnerCategory }}
          </p>
          <p class="text">
            <c-icon
              icon="target"
              size="12"
              class="icon"
            />
            <span>{{ partner.campaignsCount }}</span> Ofertas
          </p>
        </div>
      </div>

      <div class="campaign-buttons">
        <c-toggle
          data-cy="active-partner-toggle"
          sync
          await
          class="toggle"
          :disabled="isLoading"
          :height="30"
          :width="100"
          :labels="{ checked: 'Ativo', unchecked: 'Inativo' }"
          :value="partnerStatus"
          @input="$emit('toggle-partner-status', { slug: partner.slug })"
        />

        <div class="wrapper-partner-toggle">
          <c-button
            data-cy="delete-partner-button"
            default
            size="lg"
            class="btn"
            icon="trash"
            @click="$emit('delete-partner')"
            v-if="partner.is_exclusive"
          >
            {{ isDesktop ? 'Excluir parceiro' : null }}
          </c-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CImage from '@/components/CComponents/CImage'

export default {
  components: {
    CImage
  },
  props: {
    partner: {
      type: Object,
      required: true
    },
    isLoading: {
      type: Boolean,
      required: false
    }
  },
  data () {
    return {
      imagePlaceholder: require('@/assets/img/campaign-placeholder.svg'),
      displayDeleteWarning: false
    }
  },
  computed: {
    partnerCategory () {
      const { categories } = this.partner || {}
      return categories && categories.length
        ? categories[0].name
        : ''
    },
    partnerStatus () {
      return this.isExclusive
        ? !!this.partner.status
        : !!this.partner.is_blocked
    },
    isExclusive () {
      return !!this.partner.company_id
    }
  }
}
</script>

<style lang="scss">
@import '~@/styles/reference';

.campaign-list-header {
  background-color: #FFF;
  box-shadow: 0px 4px 5px -2px rgba(213, 214, 226, 0.24);

  &.-inactive { background-color: #FBFCFE; }

  & > .header-group {
    display: flex;
    justify-content: space-between;
    flex-direction: column-reverse;

    min-height: 120px;
    padding: 15px 0;

    @include desktop {
      flex-direction: row;
      @include container;
    }

    & > .partners-info {
      display: flex;
      justify-content: flex-start;
      min-width: 300px;
      flex-shrink: 0;

      padding: 0 30px;

      @include desktop {
        justify-content: center;
        padding: 0;
      }

      & > .partner-logo {
        max-height: 100px;
        max-width: 160px;
        min-width: 10px;
        margin: auto 0;

        &.-placeholder { min-width: 90px; }
      }

      & > .partners-text {
        font-family: $base-font-family;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-left: 50px;

        & > .name-partners {
          font-size: 14px;
        }

        & > .text {
          font-size: 14px;
          color: $title-color;
          width: 125px;
          white-space: nowrap;
        }

        span {
          font-weight: bold;
          font-size: 14px;
          color: #121E48;
          opacity: 80%;
          margin-left: 5px;
        }
      }
    }

    & > .campaign-buttons {
      display: flex;
      align-items: center;
      align-self: flex-start;
      justify-content: flex-end;
      margin-top: 10px;
      flex-shrink: 0;
      margin-top: 15px;

      @include mobile {
        padding-right: 20px;
        margin-bottom: 10px;
        margin-top: 0;

        align-self: flex-end;
      }

      & > .toggle { margin-right: 10px; }

      & > .wrapper-partner-toggle {
        & >.c-button {
          display: flex;
          justify-content: center;
          align-items: center;

          @include mobile {
            padding: 0;
            & > .content > .text { margin-left: 0 !important; }
          }
        }
      }
    }
  }
}
</style>
