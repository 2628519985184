<template>
  <div class="tag-container">
    <k-icon
        class="icon"
        icon="lock3"
        size="20"
      />Premium
  </div>
</template>

<script>
import { KIcon } from 'kaledo-components'
export default {
  components: {
    KIcon
  }
}
</script>

<style lang='scss' scoped>
.tag-container {
  display: flex;
  color: var(--accent-tertiary-200, #00B2E2);
  text-align: center;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Nunito Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 140%; /* 22.4px */
  & > icon {
    padding-right: 4px;
  }
}
</style>
