<template>
  <section class="c-table-container">
    <div class="actions">
      <div
        v-if="isSelectedRowsActionsVisible"
        class="selected-rows-actions"
      >
        <slot
          name="selected-rows-actions"
          :rows="selectedRows"
        />
      </div>
      <slot name="actions" />
    </div>

    <div class="table">
      <slot />
    </div>

    <c-paginate
      v-if="isPaginateVisible"
      class="paginate"
      :class="paginateBg ? '-bg' : ''"
      :is-fetching="isFetching"
      :paginate="paginate"
      @update-paginate="updatePaginate($event)"
    />
  </section>
</template>

<script>
export default {
  components: {
    CPaginate: () => import('@/components/CComponents/CPaginate')
  },
  props: {
    paginate: Object,
    isFetching: Boolean,
    selectedRows: {
      type: Array,
      default: () => []
    },
    paginateBg: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    isPaginateVisible () {
      const required = ['total', 'per_page', 'current_page', 'from', 'to']
      const isInPaginate = (property) => property in this.paginate
      const isVisible = this.paginate && required.every(isInPaginate)
      return isVisible
    },
    isSelectedRowsActionsVisible () {
      const hasSlot = !!this.$slots['selected-rows-actions']
      const hasSelectedRows = !!this.selectedRows.length
      return hasSlot && hasSelectedRows
    }
  },
  methods: {
    updatePaginate (paginate) {
      this.$emit('update-paginate', paginate)
    }
  }
}
</script>

<style lang="scss">
@import '~@/styles/reference.scss';

.c-table-container {
  display: flex;
  flex-flow: row wrap;

  & > .actions,
  & > .filters { flex: 1 auto; }

  & > .actions {
    @extend %c-table-container-actions;
    margin: outer-base() 0;
  }

  & > .table { flex: 0 100%; border: 1px solid #E9EAEE }

  & > .paginate {
    flex: 0 100%;
    margin-top: 10px;
    background: none;
    &.-bg {
      background-color: #F7F8FD;
    }
  }
}

%c-table-container-actions {
  display: flex;
  flex-flow: row wrap;
  align-items: flex-end;
  justify-content: flex-end;
  padding-bottom: 4px;
  position: relative;
  z-index: 2;

  & > .selected-actions {
    margin-right: 5px;
  }
}
</style>
