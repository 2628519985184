<template>
  <div class="layout-container">
    <layout-header
      @toggle-sidebar="showSidebar = !showSidebar"
      :club-name="personalization.name"
      :support-link="(personalization.config || {}).support_link"
      :logo="(personalization || {}).logo"
      :permissions="permissions"
    />
    <layout-sidebar
      :opened="showSidebar"
      @close="showSidebar = false"
    />

    <c-modal-mask
      :show="showSidebar"
      @close="showSidebar = false"
    />

    <c-feedback
      :type="feedback.type"
      :title="feedback.title"
      :text="feedback.text"
      :icon="feedback.icon"
      @close="closeFeedback"
    />

    <div class="layout-content">
      <slot />
    </div>

    <layout-footer
      :display-scroll-top="position < -200"
      :club-url="personalization.url || 'https://clube-alelo.convenia.com.br'"
      :personalization="personalization"
    />
    <trial-onboarding
      v-if="showTrialOnboarding"
      @open-menu="showSidebar = true"
    />
  </div>
</template>

<script>
import LayoutSidebar from '@/components/Layout/LayoutSidebar'
import LayoutHeader from '@/containers/Layout/LayoutHeader'
import LayoutFooter from '@/components/Layout/LayoutFooter'
import CModalMask from '@/components/CComponents/CModalMask'
import CFeedback from '@/components/CComponents/CFeedback'
import TrialOnboarding from '@/containers/Trial/TrialOnboarding.vue'
import {
  FEEDBACK,
  FEEDBACK_CLOSE,
  CONFIG_PERSONALIZATION,
  COMPANY_PERMISSIONS,
  COMPANY,
  USER
} from '@/store/types'
import { mapGetters, mapActions } from 'vuex'

export default {
  components: {
    CModalMask,
    LayoutSidebar,
    LayoutHeader,
    LayoutFooter,
    CFeedback,
    TrialOnboarding
  },

  data () {
    return {
      position: 0,
      lastPosition: 0,
      closeOnboarding: false,
      showSidebar: false
    }
  },

  computed: {
    ...mapGetters({
      feedback: FEEDBACK,
      personalization: CONFIG_PERSONALIZATION,
      permissions: COMPANY_PERMISSIONS,
      company: COMPANY,
      user: USER
    }),

    toTop () {
      const toTop = this.lastPosition <= this.position
      return toTop
    },
    showTrialOnboarding () {
      return this.company.trial && (this.user.panel_onboarding === false)
    }
  },
  created () {
    this.$bus.$on('close-sidebar-menu', () => {
      this.showSidebar = false
    })
  },
  mounted () {
    this.handleScroll()
    document.addEventListener('scroll', this.handleScroll)
  },
  beforeDestroy () {
    document.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    ...mapActions({
      closeFeedback: FEEDBACK_CLOSE
    }),

    handleScroll () {
      const boundingRect = document.body.getBoundingClientRect().top
      this.lastPosition = this.position
      this.position = boundingRect
    }
  }
}
</script>

<style lang="scss">
@import '~@/styles/reference';

.layout-container {
  position: relative;
  display: flex;
  flex-direction: column;

  min-height: 100%;
  background-color: $background-color;

  .layout-content { flex-grow: 1; }
}
</style>
