<template>
  <div class="campaign-information-tag">
    <campaign-tag
      v-if="isHighlighted"
      is-highlight
    />

    <div class="discount">
      <campaign-stamp
        large
        :symbol="symbol"
        :value="value"
        v-bind="$attrs"
      />
    </div>
    <div class="description">
      <div class="campaign-actions">
        <c-button
          data-cy="copy-id-button"
          size="lg"
          icon="files"
          icon-size="sm"
          class="btn-copy"
          @click="notify"
        >
          Copiar ID
        </c-button>
      </div>
      <div class="text-group">
        <p class="text">
          {{ title }}
        </p>
        <p class="until">
          <c-icon
            size="15px"
            icon="alarm"
          />
          Até {{ untilDate }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import CampaignStamp from '@/components/Campaign/Listing/CampaignStamp'

import CampaignTag from '@/components/Campaign/Listing/CampaignTag'

import { copyText } from '@/modules/copyText'

export default {
  components: { CampaignTag, CampaignStamp },
  props: {
    id: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    symbol: String,
    value: [Number, String],
    until: {
      type: Number,
      required: true
    },
    isHighlighted: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    untilDate () {
      const untilDate = new Date(this.until * 1000).toLocaleDateString('pt-BR', { timeZone: 'America/Sao_Paulo' })
      return untilDate
    }
  },
  methods: {
    notify (ev) {
      const feedback = {
        type: 'success',
        title: 'Sucesso',
        text: 'O ID da oferta foi copiado'
      }

      copyText(this.id)
      ev.stopPropagation()
      this.$emit('copy', feedback)
    }
  }
}
</script>

<style lang="scss">
@import '~@/styles/reference';

.campaign-information-tag {
  display: flex;
  flex-flow: row nowrap;

  min-height: 153px;

  & > .campaign-tag {
    position: absolute;
    top: 0px;
    left: 0px;
  }

  & > .discount {
    display: flex;
    align-items: center;
    justify-content: center;

    padding-bottom: 15px;
  }

  @include mobile {
    & > .discount > .campaign-stamp,
    & > .discount > .campaign-stamp > .stamp { width: 110px; height: 110px; }
  }

  & > .description {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    padding: 0 0 25px 15px;
    width: 100%;

    & > .campaign-actions {
      display: flex;
      justify-content: flex-end;

      & > .btn-copy {
        width: 100%;
        max-width: 170px;
      }
    }

    & > .text-group > .text {
      color: $primary-color-placeholder;
      color: var(--color-primary);
      font-size: 16px;
      font-weight: 600;
    }

    & > .text-group {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      margin-top: 20px;
    }

    & > .text-group > .until {
      font-size: 11px;
      font-weight: 600;
      color: $title-color;
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      margin-top: 20px;

      & > .c-icon {
        fill: $icon-color;
        margin-right: 11px;
      }
    }
  }
}
</style>
