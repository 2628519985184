<template>
  <highlight-container />
</template>

<script>
import HighlightContainer from '@/containers/Communication/Highlight/HighlightContainer'

export default {
  components: { HighlightContainer }
}
</script>
