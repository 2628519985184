<template>
  <div class="registration-info-card">
    <graph-card
      :graph-data="registerData"
    />
    <div class="container">
      <div class="main">
        <div class="info">
          <h4>opt-in newsletter</h4>
          <span class="value">{{ value1 }}</span>
          <span>Cadastraram o recebimento no período</span>
        </div>
        <div class="info">
          <h4>opt-out newsletter</h4>
          <span class="value">{{ value2 }}</span>
          <span>Descadastraram recebimento no período</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import GraphCard from '@/components/Dashboard/base/GraphCard.vue'
export default {
  components: {
    GraphCard
  },
  data () {
    return {
      value1: 800,
      value2: 1300,
      registerData: {
        title: 'Total do clube',
        columns: [
          ['data1', 10, 2],
          ['data2', 90, 88]
        ],
        names: {
          data1: 'Usuários optantes',
          data2: 'Usuários não-optantes'
        },
        comparisonPeriod: {
          period_1: {
            start: 1666200980,
            end: 1666200989
          },
          period_2: {
            start: 1666201419,
            end: 1666201437
          }
        },
        comparisonValues: {
          data1: 10,
          data2: 90
        },
        comparisonResults: {
          data1: 5,
          data2: 95
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/styles/reference.scss';
.registration-info-card {
      position: relative;
      z-index: 3;
      background: #FDFDFD;
      padding: 16px;
      box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.16);
      border-radius: 0px 0px 6px 6px;
      display: flex;
      > .graph-card {
        width: 100%;
        min-width: 290px;
        box-shadow: none;

        & :deep .legends {
          width: 170px;
        }
      }
      .container {
        display: flex;
        justify-content: center;
        width: 100%;
        align-items: center;
        .main {
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          height: 65%;
          .info {
            h4 {
              font-family: 'Nunito Sans';
              font-style: normal;
              font-weight: 400;
              font-size: 16px;
              line-height: 40px;
              text-transform: uppercase;
              color: #202950;
            }
            .value {
              font-family: 'Nunito Sans';
              font-style: normal;
              font-weight: 700;
              font-size: 18px;
              line-height: 22px;
              color: #202950;
            }
            span {
              font-family: 'Nunito Sans';
              font-style: normal;
              font-weight: 600;
              font-size: 16px;
              line-height: 30px;
              display: flex;
              align-items: center;
              color: #5E6684;
            }
          }
        }
      }
   }

</style>
