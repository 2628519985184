<template>
  <div class="length-message">
    <span
      v-if="show"
      data-cy="input-subtext"
      class="subtext"
    >
      Máx. {{ length }} caracteres
    </span>
  </div>
</template>

<script>
export default {
  props: {
    length: [Number, String],
    show: Boolean
  }
}
</script>

<style lang="scss">
.length-message {
  height: 15px;

  .subtext {
    margin-top: 6px;
    font-size: 12px;
    color: #5E6684;
  }
}
</style>
