<template>
  <select-account
    :companies="companies"
    :is-loading="isLoading"
    @submit="submit"
  />
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import * as types from '@/store/types'
import SelectAccount from '@/components/Login/SelectAccount'

export default {
  components: { SelectAccount },
  data () {
    return { isLoading: false }
  },
  computed: {
    ...mapGetters({ companies: types.CONFIG_COMPANY_LIST })
  },
  methods: {
    ...mapActions({ loginCompany: types.AUTH_LOGIN_COMPANY }),
    async submit(company) {
      this.isLoading = true
      const response = await this.loginCompany({ company })
      this.$router.push({ name: 'index' })
      this.isLoading = false
    }
  },
  created () {
    if (!(this.companies && this.companies.length))
      this.$router.push({ name: 'login' })
  }
}
</script> 