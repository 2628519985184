<template>
  <div
    data-cy="my-campaigns"
    class="my-campaigns"
  >
    <div class="title-header">
      <c-title
        class="title"
      >
        Minhas Campanhas
      </c-title>
      <div
        v-if="campaigns.length || showEmpty"
        class="actions"
      >
        <search-field
          data-cy="campaign-name-filter-input"
          class="campaign-search"
          placeholder="Bucar pelo nome da campanha"
          icon-size="20"
          :is-loading="false"
          :term="search"
          @input="search = $event"
          @submit="submitSearch"
          @reset="resetSearch"
          @focusout="$emit('focusout')"
        />
        <filters-popover
          :filters-by-status="filtersByStatus"
          :show-filter="showFilter"
          @select-filter="filterCampaigns"
          @blur="showFilter = false"
          @show-filter="showFilter = !showFilter"
          @clear-filter="clearFilter"
          @apply-filter="submitSearch"
          @close="showFilter = false"
        />
      </div>
    </div>
    <transition
      name="fade"
    >
      <c-spinner v-if="loading" />
      <c-empty
        v-else-if="showEmpty"
        class="empty-search"
        title="Ops!"
        character="searching"
        message="Não encontramos nenhuma campanha relacionado à busca"
      />
      <listing-table
        v-else
        data-cy="my-campaigns-table"
        empty-banner-text="Você ainda não possui uma campanha de engajamento"
        :rows="rows"
        :cols="tableCols"
        :paginate="paginate"
        :has-empty-banner="!search"
        @update-paginate="handlePaginate"
        @action-click="campaign => $emit('update-campaign', campaign)"
        @link-click="campaign => $emit('link-click', campaign)"
        @new-order="$emit('new-order')"
      />
    </transition>
  </div>
</template>

<script>
import FiltersPopover from '@/components/General/FiltersPopover'
import SearchField from '@/components/CComponents/SearchField'
import ListingTable from './ListingTable'

const engagementCampaignsStatuses = ['0', '1', '2']

export default {
  components: {
    FiltersPopover,
    SearchField,
    ListingTable
  },
  props: {
    loading: Boolean,
    coin: {
      type: Object,
      default () {
        return {}
      }
    },
    campaigns: {
      type: Array,
      default () {
        return []
      }
    },
    paginate: [Object, Array]
  },
  data () {
    return {
      orderId: null,
      tableCols: [
        { label: 'Nome', align: 'center' },
        { label: 'Data início', align: 'center' },
        { label: 'Data final', align: 'center' },
        { label: 'Status', align: 'center' },
        { label: 'Ação', align: 'center' }
      ],
      search: '',
      someSearchHasSubmitted: false,
      showFilter: false,
      filters: {},
      paramsChanged: false,
      params: {
        limit: 10,
        page: 1
      },
      filtersByStatus: [
        { name: 'Ativa', icon: 'lamp-bright', status: '1', selected: false },
        { name: 'Inativa', icon: 'lamp', status: '0', selected: false },
        { name: 'Agendado', icon: 'icon-clock-time-copy', status: '2', selected: false }
      ]
    }
  },
  computed: {
    hasFilters () {
      return this.filters && this.filters.type && this.filters.type.length > 0
    },
    showEmpty () {
      const hasSearch = this.search && this.search.length > 0
      const rowsEmpty = this.rows && this.rows.length === 0
      return !this.loading && (this.someSearchHasSubmitted || hasSearch || this.hasFilters) && rowsEmpty
    },
    rows () {
      return this.campaigns || []
    },
    links () {
      return this.paginate || {}
    }
  },
  methods: {
    handleAnalytics (event, data) {
      window.dataLayer.push(
        {
          event,
          ...data
        }
      )
    },
    async handlePaginate (paginate) {
      this.params = paginate
      this.paramsChanged = true
      await this.submitSearch()
    },
    filterCampaigns (property) {
      const hasType = engagementCampaignsStatuses.some(type => type.includes(this.filters.type))

      hasType && this.filters.type === property.type
        ? this.filters = {}
        : this.filters = { ...this.filters, ...property }

      const changeStatus = filter => {
        filter.status === property.type
          ? filter.selected = !filter.selected
          : filter.selected = false
        return filter
      }
      this.filtersByStatus.map(changeStatus)
    },
    async clearFilter () {
      this.filters = {}
      this.filtersByStatus = this.filtersByStatus.map(filter => ({ ...filter, selected: false }))
      await this.submitSearch()
    },
    async resetSearch () {
      this.search = ''
      await this.submitSearch()
    },
    setStatus (type) {
      switch (type) {
      case '0':
        return 'inactive'
      case '1':
        return 'active'
      case '2':
        return 'scheduled'
      default:
        return ''
      }
    },
    async submitSearch () {
      this.someSearchHasSubmitted = true
      const type = Object.values(this.filters)[0] // mal time
      const status = this.setStatus(type)

      const hasType = engagementCampaignsStatuses.some(v => v === type)
      if (this.search || this.filters.manager || hasType || this.paramsChanged) {
        this.$emit('submit-search', { title: this.search, status, ...this.params })
        this.paramsChanged = false
      } else {
        this.params.page = 1
        this.$emit('submit-search')
      }
      this.showFilter = false
    }
  }
}
</script>

<style lang="scss">
@import '~@/styles/reference';

.my-campaigns {
  max-width: 1020px;
  margin-top: 35px;
  padding: 0 10px;

  @include desktop { @include container; }

  .title-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 1020px;
    margin: 48px auto 28px auto;

    > .title {
      max-width: 200px;
    }

    .actions {
      width: 100%;
      max-width: 490px;
      display: flex;
      justify-content: flex-end;

      .campaign-search {
        margin-right: 20px;

        .input{
          width: 350px;
          background: #FDFDFD;
          border: 1px solid var(--color-secondary);
          transition: all .1s ease;
        }
      }
    }
  }
}
</style>
