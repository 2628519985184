<template>
  <c-modal
    name="user-status-confirmation"
    class="user-status-confirmation"
    :is-fullscreen="false"
    :is-opened="showModalStatus"
    @close="$emit('close')"
  >
    <c-character type="embarrassed" />

    <div class="container">
      <h3 class="title">
        Tem certeza que deseja {{ status === 2 ? 'desativar' : 'convidar' }} este usuário?
      </h3>

      <p class="sub-title">
        {{text}}
      </p>
    </div>

    <div class="actions">
      <c-button
        size="lg"
        @click="$emit('cancel')"
        class="action"
      >
        CANCELAR
      </c-button>
      <c-button
        primary
        size="lg"
        @click="$emit('change-status')"
        class="action"
      >
        {{ status === 2 ? 'DESATIVAR' : 'CONVIDAR' }}
      </c-button>
    </div>
  </c-modal>
</template>

<script>
import CModal from '@/components/CComponents/CModal'
import CCharacter from '@/components/CComponents/CCharacter'

export default {
  components: { CModal, CCharacter },

  props: {
    showModalStatus: Boolean,
    status: [Number, Boolean]
  },
  computed: {
    text () {
     return this.status === 1 ? 'Ao ser convidado, o usuário terá acesso ao Clube novamente.' : 'As informações deste usuário continuarão armazenadas aqui, porém, ele não poderá acessar o clube.'
    }
  }
}
</script>

<style lang="scss">
@import '~@/styles/reference';

.user-status-confirmation {
  display: flex;
  justify-content: center;

  & > .content {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 20px;

    > .c-character {
      width: 100px;
      height: 100px;
      padding: 50px;
    }

    & > .container {
      & > .title {
        margin-top: 10px;
        text-align: center;
        color: $title-color;
      }

      & > .sub-title {
        margin-top: 20px;
        text-align: center;
      }
    }

    & > .actions {
      margin-top: 20px;

      & > .action:not(:last-child) {
        margin-right: 10px;
      }
    }
  }
}
</style>
