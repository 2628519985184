<template>
  <div class="gift-container">
    <div class="image-container">
      <img
        v-if="!isCompleted"
        :src="require(`@/assets/img/trial/gift/gift-${numberSteps}.png`)"
        alt="gift"
      />
      <img
        v-else
        src="@/assets/img/trial/gift/gift-completed.png"
        alt="Caixa de presentes"
      />
    </div>
    <div
      v-if="!isCompleted"
      class="text-container"
      :class="{'-can-rescue': (numberSteps >= 3)}"
    >
      <p class="title">
        Recompensa
      </p>
      <div class="button-container">
        <c-button
          size="lg"
          class="gift-button"
          primary
          :disabled="numberSteps < 3"
          @click="handleRescue"
        >
          Resgatar
        </c-button>
      </div>
    </div>
  </div>
</template>

<script>
import { COMPANY } from '@/store/types'
import { mapGetters } from 'vuex'

export default {
  props: {
    numberSteps: {
      type: Number,
      required: true
    },
    isCompleted: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    ...mapGetters({
      company: COMPANY
    }),
    handleRescue () {
      this.$emit('complete-quest')
      window.dataLayer.push({
        event: 'finalizar-missao',
        company_name: this.company.name
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/styles/reference';
.gift-container {
  margin-top: 42px;
  width: 100%;
  height: 172px;
  background: rgba(224, 248, 255, 0.16);
  display: flex;
  align-items: center;
  justify-content: center;
  .text-container {
    margin-left: 24px;
    padding-left: 24px;
    padding-right: 24px;
    padding-bottom: 24px;
    text-align: center;
    &.-can-rescue {
      background-image: url('~/assets/img/trial/gift/bg-gift.png');
      background-size: contain;
      background-position-x: center;
      background-position-y: bottom
    }
    .title {
      font-size: 18px;
      font-weight: 700;
      color: rgba(0, 178, 227, 1);
      margin-bottom: 16px;
    }
    .button-container {
      .gift-button {
        width: 160px;
        &.-disabled {
          opacity: 1;
          color: $base-color;
          background: rgba(216, 216, 216, 1);
        }
      }
    }
  }
}
</style>
