<template>
  <awards-details-container />
</template>

<script>
import AwardsDetailsContainer from '~/containers/Awards/AwardsDetailsContainer'
export default {
  components: { AwardsDetailsContainer }
}
</script>
