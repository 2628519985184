<template>
  <login-template
    class="login-page"
    :background="data.background"
    :background-image="backgroundImage"
    :is-mobile="isMobile"
    :developed-by-convenia="data.developedByConvenia"
  >
    <c-image
      v-if="data.signInLogo"
      ref="signInLogo"
      class="logo-form"
      :src="data.signInLogo"
      :placeholder="data.signInLogo"
      width="125px"
    />
    <c-logo
      v-else
      class="logo-form"
      kaledo
    />

    <div class="form">
      <p
        class="text"
        :style="{
          color: data.mainText
        }"
      >
        {{ data.intro }}
      </p>
      <c-input
        required
        autocomplete="new-password"
        icon="contacts"
        placeholder="Insira seu CPF"
        class="input-data"
        :mask="'###.###.###-##'"
        validation-icon="warning-circle"
        validation="CPF inválido."
        :style="{
          color: data.negativeFeedback
        }"
      />
      <c-input
        required
        autocomplete="new-password"
        icon="lock2"
        placeholder="Insira sua senha"
        class="input-data"
        validation-icon="warning-circle"
        validation="Senha incorreta."
        :style="{
          color: data.negativeFeedback
        }"
      />

      <div class="under-input">
        <a
          class="forget-pwd"
          :style="{
            color: data.forgotPassword
          }"
          @click.prevent
        >
          Esqueci a senha
        </a>
      </div>

      <div class="btn-group">
        <c-button
          preview
          size="lg"
          class="btn main-button"
          :style="{
            backgroundColor: data.mainButton,
            color: data.mainButtonText
          }"
          @click.prevent
        >
          Entrar
        </c-button>
        <span
          class="lines"
          :style="{
            color: data.or
          }"
        >OU</span>
        <c-button
          preview-alternative
          size="lg"
          class="btn"
          :style="{
            borderColor: data.secondaryButton,
            color: data.secondaryButton
          }"
          @click.prevent
        >
          Ativar Conta
        </c-button>
      </div>
      <h3 class="redefine">
        Não consegue acessar sua conta? <span class="click-here">Clique aqui</span>
      </h3>
    </div>
  </login-template>
</template>

<script>
import LoginTemplate from './LoginTemplate'
import CImage from './CImage'
import CInput from './CInput'
import CLogo from '@/components/CComponents/CLogo.vue'

const swiperOptions = {
  slidesPerView: 3,
  spaceBetween: 20,
  navigation: {
    nextEl: '.swiper-button.-next',
    prevEl: '.swiper-button.-prev',
    disabledClass: '-disabled',
    hiddenClass: '-hidden'
  }
}

export default {
  components: {
    LoginTemplate,
    CImage,
    CInput,
    CLogo
  },
  props: {
    data: Object,
    isMobile: Boolean
  },
  data () {
    return {
      swiperOptions,
      backgroundImage: ''
    }
  },
  watch: {
    divisors (v) {
      this.setDivisorColor(v)
    },
    data: {
      handler () {
        this.backgroundImage = this.data.backgroundImage
      },
      deep: true
    }
  },
  mounted () {
    if (this.divisors) {
      this.setDivisorColor(this.divisors)
    }
  },
  methods: {
    setDivisorColor (v) {
      document.querySelector('.login-page').style.setProperty('--line-color', v)
    }
  }
}
</script>

<style lang="scss">
@import '~@/styles/reference';

.login-page {
  --line-color: #F1F1F4;

  & .logo-form {
      margin-bottom: 40px;
      margin-top: 50px !important;
  }
  & .form {
    min-width: 250px;
    max-width: 320px;

    & >.text {
      margin-bottom: 25px;
      color: #121E48;
      opacity: 0.5;
      font-family: "Nunito Sans";
      font-size: 14px;
      line-height: 19px;
    }
    & > .input-data {
      input:not(.-has-validation) {
        &:hover,
        &:active,
        &:focus {
          border-color: black;
          box-shadow: 0 0 0 1px black;
          outline: black;
        }
      }
    }

    & > .under-input {
      margin-top: -10px;
      display: flex;
      position: relative;
      justify-content: space-between;

      & > .wrongdata {
        font-size: 12px;
        color: blue;
        font-family: arial;
        font-weight: 100;
        padding-right: 100px;
        position: absolute;

        & > .icon {
          fill: blue;
        }
      }
      & > .forget-pwd {
        position: absolute;
        font-size: 12px;
        right: 0;
        color: $base-color;
        font-family: arial;
        font-weight: 100;
        text-decoration: none;
        cursor: pointer;
      }
    }
    & > .btn-group {
      display: flex;
      align-items: center;
      flex-direction: column;
      margin-top: 40px;
      margin-bottom: 40px;

      & > .btn {
        width: 100%;
        margin: 10px 0px;
        font-family: arial;
        font-weight: 100;
      }

      & > .lines{
        font-size: 12px;
        width: 100%;
        text-align: center;
        margin: 15px auto;
        overflow: hidden;
        color: grey     ;
        font-family: arial;
        font-weight: 100;

        &:before {
          margin-left: -100%;
        }
        &:after {
          margin-right: -100%;
        }
        &:before,
        &:after {
          display: inline-block;
          content: '';
          background-color: var(--line-color);
          height: 1px;
          width: 100%;
          vertical-align: middle;
          box-sizing: border-box;
          border: solid var(--line-color);
          border-width: 0 10px;
        }
      }
    }
    & > .redefine {
      color: var(--neutral-gray-200, #5E6684);
      text-align: center;
      font-feature-settings: 'clig' off, 'liga' off;
      font-family: Nunito Sans;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 120%;
      letter-spacing: -0.122px;

      & > .click-here {
        color: var(--neutral-gray-200, #5E6684);
        text-align: center;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: Nunito Sans;
        font-size: 12px;
        font-style: normal;
        font-weight: 700;
        line-height: 110%;
        letter-spacing: -0.122px;
        text-decoration-line: underline;

        &:hover {
          cursor: pointer;
        }
      }
    }
  }

  .main-button.c-button > .text,
  .secondary-button.c-button > .text,
  .c-input-container.input-data.c-input > .validation { color: unset; }
}
</style>
