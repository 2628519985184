<template>
  <div :class="['campaign-information-step', stepClass]">
    <div class="number">
      <p class="text">
        {{ number }}
      </p>
    </div>

    <c-input
      v-if="isTextArea"
      class="input"
      textarea
      rows="5"
      maxlength="100"
      :value="step"
      @input="v => emitter(number, v)"
    />

    <p
      v-else
      class="description"
    >
      {{ step }}
    </p>

    <div
      v-if="!last"
      class="arrows"
    >
      <c-icon
        v-for="_ in 3"
        :key="_"
        icon="chevron-down"
        size="11"
        :style="{
          opacity: `calc(1 - ((3 - ${_}) * .25))`,
          bottom: `calc((${_} - 1) * 6px)`
        }"
      />
    </div>

    <campaign-information-voucher
      v-if="voucher"
      class="voucher"
      v-on="$listeners"
      :voucher="voucher"
    />
  </div>
</template>

<script>
import CampaignInformationVoucher from '@/components/Campaign/Information/CampaignInformationVoucher'

export default {
  components: { CampaignInformationVoucher },
  props: {
    step: {
      type: [String, Number],
      default: () => ''
    },
    isTextArea: Boolean,
    number: {
      type: Number,
      required: true
    },
    last: Boolean,
    voucher: String,
    event: String
  },

  computed: {
    stepClass () {
      const stepClass = {
        '-last': this.last,
        '-has-voucher': this.voucher
      }
      return stepClass
    }
  },

  methods: {
    emitter (property, value) {
      this.$emit('update', { index: property - 1, value })
    }
  }
}
</script>

<style lang="scss">
@import '~@/styles/reference';

.campaign-information-step {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  position: relative;

  & > .input {
    width: 100%;
    margin: { left: 30px; top: 10px !important; right: 20px; }
  }

  & > .description {
    flex: 1 1 0;
    padding: 20px 20px 30px 20px;
    margin-left: 10px;
    word-break: break-word;
  }

  &:not(.-last) {
    & > .description { border-bottom: 1px solid $border-color; }
  }

  & > .number {
    flex: 50px 0 0;
    display: flex;
    justify-content: flex-end;

    & > .text {
      font-size: 16px;
      height: 34px;
      width: 34px;
      border-radius: 100%;
      background-color: $primary-color-placeholder;
      background-color: var(--color-primary);
      display: flex;
      align-items: center;
      justify-content: center;
      color: white;
      font-weight: 700;

      &:after { content: 'º' }
    }
  }

  & > .arrows {
    display: flex;
    flex-flow: column nowrap;
    position: absolute;
    left: 33px;
    bottom: 0;
    transform: translate(-50%, calc(50% + 6px));

    & > .c-icon {
      position: relative;
      fill: $primary-color-placeholder;
      fill: var(--color-primary);
    }
  }

  &.-has-voucher > .description { padding-bottom: 75px; }

  & > .voucher {
    position: absolute;
    left: 80px;
    bottom: 20px;
  }
}
</style>
