<template>
  <div class="placeholder-card">
    <svg v-if="isDesktop" version="1.1" id="Camada_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
    	 viewBox="0 0 390.9 176.5" style="enable-background:new 0 0 390.9 176.5;" xml:space="preserve" :class="['svg-desktop', { 'is-active': isActive } ]">
    <path class="st0" d="M390.2,151.1v-136c0-0.2,0-0.4-0.1-0.5c0-0.2-0.1-0.3-0.1-0.5V0.7H50v35.7v0c-0.5,3.8-1.8,7.4-3.9,10.5
    	c0,0,0,0,0,0c-0.4,0.6-0.8,1.2-1.3,1.8c0,0,0,0-0.1,0.1c-0.4,0.6-0.9,1.1-1.4,1.6c0,0-0.1,0.1-0.1,0.1c-0.5,0.5-1,1-1.5,1.5
    	c0,0,0,0-0.1,0.1c-1.1,1-2.3,1.8-3.5,2.6c0,0,0,0,0,0c-0.6,0.4-1.2,0.7-1.8,1c-0.1,0-0.1,0.1-0.2,0.1c-0.6,0.3-1.2,0.6-1.8,0.8
    	c-0.1,0-0.2,0.1-0.3,0.1c-0.6,0.2-1.2,0.4-1.9,0.6c-0.1,0-0.2,0.1-0.3,0.1c-0.6,0.2-1.3,0.3-1.9,0.5c-0.1,0-0.2,0-0.3,0.1
    	c-0.7,0.1-1.4,0.2-2,0.3c-0.1,0-0.1,0-0.2,0c-0.7,0.1-1.5,0.1-2.2,0.1c-13.8,0-24.9,11.2-24.9,24.9S11.5,108,25.3,108
    	c0.7,0,1.5,0,2.2,0.1c0.1,0,0.1,0,0.2,0c0.7,0.1,1.4,0.2,2,0.3c0.1,0,0.2,0,0.3,0.1c0.7,0.1,1.3,0.3,1.9,0.5c0.1,0,0.2,0.1,0.3,0.1
    	c0.6,0.2,1.3,0.4,1.9,0.6c0.1,0,0.2,0.1,0.3,0.1c0.6,0.2,1.2,0.5,1.8,0.8c0.1,0,0.1,0.1,0.2,0.1c0.6,0.3,1.2,0.6,1.8,1c0,0,0,0,0,0
    	c1.2,0.8,2.4,1.6,3.5,2.6c0,0,0,0,0.1,0c0.5,0.5,1,1,1.5,1.5c0,0,0.1,0.1,0.1,0.1c0.5,0.5,0.9,1,1.4,1.6c0,0,0,0,0.1,0.1
    	c0.4,0.6,0.9,1.1,1.3,1.8c0,0,0,0,0,0c2,3.1,3.4,6.7,3.9,10.5v0v45.9h340v-23.6v0c0.1-0.2,0.1-0.3,0.1-0.5
    	C390.2,151.4,390.2,151.3,390.2,151.1z"/>
    <rect x="121" y="68.3" class="st1" width="189.5" height="29.5"/>
    <text transform="matrix(1 0 0 1 130.561 80.6196)"><tspan x="0" y="0" class="st2 st3 st4">Arraste uma oferta para se </tspan><tspan x="5.7" y="16.8" class="st2 st3 st4">tornar destaque no clube</tspan></text>
    <circle class="st5" cx="25.4" cy="83" r="15"/>
    <text transform="matrix(1 0 0 1 24.856 88.1667)" text-anchor="middle" class="st6 st7 st8 number">{{ index + 1}}</text>
    </svg>

    <svg v-else version="1.1" id="Camada_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
    	 viewBox="0 0 350 178" style="enable-background:new 0 0 350 178;" xml:space="preserve" :class="['svg-mobile', { 'is-active': isActive } ]">
    <circle class="st0" cx="25.6" cy="89" r="15"/>
    <text transform="matrix(1 0 0 1 25.15 94.15)" text-anchor="middle" class="st1 st2 st3">{{ index + 1 }}</text>
    <rect x="120.2" y="74.2" class="st4" width="189.5" height="29.5"/>
    <text transform="matrix(1 0 0 1 130.561 80.6196)"><tspan x="0" y="0" class="st5 st6 st7">Adicione uma oferta para se </tspan><tspan x="5.7" y="16.8" class="st5 st6 st7">tornar destaque no clube</tspan></text>
    <g class="st8">
    	<path class="st1" d="M348.8,19V1.2H49.2v42.7C47.3,55.6,37,64.5,24.7,64.5c-13.8,0-24.9,11.1-24.9,24.6s11.1,24.6,24.9,24.6
    		c12.3,0,22.6,8.9,24.5,20.6v42.6h299.6v-17.7V19z"/>
    	<g>
    		<polyline class="st9" points="348.8,3.2 348.8,1.2 346.8,1.2 		"/>
    		<line class="st10" x1="344.8" y1="1.2" x2="52.2" y2="1.2"/>
    		<polyline class="st9" points="51.2,1.2 49.2,1.2 49.2,3.2 		"/>
    		<path class="st11" d="M49.2,5.1v38.7C47.3,55.6,37,64.5,24.7,64.5c-13.8,0-24.9,11.1-24.9,24.6s11.1,24.6,24.9,24.6
    			c12.3,0,22.6,8.9,24.5,20.6v39.6"/>
    		<polyline class="st9" points="49.2,174.9 49.2,176.9 51.2,176.9 		"/>
    		<line class="st10" x1="53.2" y1="176.9" x2="345.8" y2="176.9"/>
    		<polyline class="st9" points="346.8,176.9 348.8,176.9 348.8,174.9 		"/>
    		<polyline class="st12" points="348.8,172.9 348.8,159.2 348.8,19 348.8,4.2 		"/>
    	</g>
    </g>
    </svg>

    <div class="content">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    index: {
      type: Number
    },
    isActive: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/styles/reference';

.svg-desktop {

  .st0{opacity:0.1;fill:none;stroke:#091A44;stroke-miterlimit:10;stroke-dasharray:4,2;}
  .st1{fill:none;}
  .st2{opacity:0.5;fill:#121E48;enable-background:new    ;}
  .st3{font-family:'Nunito Sans';}
  .st4{font-size:14px;}
  .st5{fill:#B3B7C6;}
  .st6{fill:#F3F5F9;}
  .st7{font-family:'Ubuntu';}
  .st8{font-size:16px;}
  .number {
    font-weight: 600;
  }
  &.is-active .st5 {
    fill: $primary-color-placeholder;
    fill: var(--color-primary);
  }
}

.svg-mobile {

  .st0{fill:#B3B7C6;}
  .st1{fill:#F3F5F9;}
  .st2{font-family:'Nunito Sans';}
  .st3{font-size:16px;}
  .st4{fill:none;}
  .st5{opacity:0.5;fill:#121E48;enable-background:new    ;}
  .st6{font-family:'Nunito Sans';}
  .st7{font-size:14px;}
  .st8{opacity:0.1;}
  .st9{fill:none;stroke:#0D1D45;stroke-miterlimit:10;}
  .st10{fill:none;stroke:#0D1D45;stroke-miterlimit:10;stroke-dasharray:3.9945,1.9972;}
  .st11{fill:none;stroke:#0D1D45;stroke-miterlimit:10;stroke-dasharray:3.9772,1.9886;}
  .st12{fill:none;stroke:#0D1D45;stroke-miterlimit:10;stroke-dasharray:4.0412,2.0206;}
  .number { font-weight: 600;}
  &.is-active .st0 {
    fill: $primary-color-placeholder;
    fill: var(--color-primary);
  }
}

</style>
