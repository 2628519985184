import { render, staticRenderFns } from "./BannersTable.vue?vue&type=template&id=4fd43827&scoped=true"
import script from "./BannersTable.vue?vue&type=script&lang=js"
export * from "./BannersTable.vue?vue&type=script&lang=js"
import style0 from "./BannersTable.vue?vue&type=style&index=0&id=4fd43827&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4fd43827",
  null
  
)

export default component.exports