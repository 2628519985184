<template>
  <div class="user-import-type-selection">
    <user-import-type-input
      class="type"
      v-for="(input, type) in inputs"
      :key="type"
      :type="type"
      :icon="input.icon"
      :title="input.title"
      :description="input.description"
      :selected="type === internalValue"
      :disabled="isDisabled(type)"
      @change-type="type => internalValue = type"
      @click.native="setHandleAnalytics(type)"
    />
  </div>
</template>

<script>
import * as types from '@/store/types'
import { mapGetters } from 'vuex'

const inputs = {
  update: {
    icon: 'cw',
    title: 'Atualizar via planilha',
    description: 'Para adicionar e remover CPF’s de uma só vez; acontecerá a sobreposição de dados.'
  },
  add: {
    icon: 'user-plus',
    title: 'Adicionar via planilha',
    description: 'Para incluir vários usuários em uma única importação; acontecerá a adição de dados.'
  },
  manual: {
    icon: 'plus-circle',
    title: 'Adicionar manualmente',
    description: 'Para adicionar até dez CPF’s sem a necessidade de importar um arquivo.'
  }
}

export default {
  components: {
    UserImportTypeInput: () => import('@/components/User/Import/UserImportTypeInput')
  },
  props: {
    value: String
  },
  data () {
    return { inputs }
  },
  computed: {
    ...mapGetters({ company: types.COMPANY }),
    internalValue: {
      get () {
        const plansWith10Users = ['trial']
        if (this.company.trial || plansWith10Users.includes(this.company.plan_type)) {
          return 'manual'
        }
        return this.value
      },
      set (value) {
        this.$emit('input', value)
        this.$emit('update', value)
      }
    }
  },
  methods: {
    setHandleAnalytics (type) {
      let event = ''
      switch (type) {
      case 'update':
        event = 'botao-importar-atualizar-usuarios'
        break
      case 'add':
        event = 'botao-importar-adicionar-usuarios'
        break
      case 'manual':
        event = 'botao-importar-adicionar-manualmente'
        break
      }
      this.handleAnalytics(event, { company_name: this.company.name })
    },

    handleAnalytics (event, data) {
      window.dataLayer.push(
        {
          event,
          ...data
        }
      )
    },
    isDisabled (type) {
      if (type === 'manual') {
        return false
      }
      const plansWith10Users = ['trial']
      if (this.company.trial || plansWith10Users.includes(this.company.plan_type)) {
        return true
      }
      return false
    }
  }
}
</script>

<style lang="scss">
.user-import-type-selection {
  display: flex;
  flex-flow: row wrap;
  align-items: stretch;

  & > .type {
    margin: 0 5px 10px;
    flex: 1 1 0;
    min-width: 250px;
  }
}
</style>
