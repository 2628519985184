<template>
  <div class="account-creation-step-one">
    <k-input
      data-cy="name-input"
      class="kinput"
      required
      v-model="name"
      :has-error="!!name && !nameValidation"
      error-message="O campo nome deve possuir no máximo 255 caracteres"
      label="NOME COMPLETO"
      placeholder="Insira seu nome completo"
      @input="update('name', $event )"
    />
    <k-input
      data-cy="email-input"
      class="kinput"
      required
      v-model="email"
      :has-error="!!email && !emailValidation"
      :error-message="emailValidationMessage"
      label="EMAIL PROFISSIONAL"
      placeholder="nome@empresa.com.br"
      @input="update('email', $event )"
    />
    <k-input
      data-cy="phone-input"
      class="kinput"
      required
      :mask="['(##) ####-####', '(##) #####-####']"
      v-model="phone"
      :has-error="!!phone && !phoneValidation"
      error-message="Número de celular inválido"
      label="CELULAR"
      placeholder="(00) 00000-0000"
      @input="update('phone', $event )"
    />
    <k-input
      data-cy="document-input"
      class="kinput"
      required
      :mask="'###.###.###-##'"
      :has-error="!!document && !documentValidation"
      error-message="CPF inválido"
      v-model="document"
      label="CPF"
      placeholder="_ _ _ . _ _ _ . _ _ _  - _ _"
      @input="update('document', $event )"
    />
    <k-input
      data-cy="cnpj-input"
      class="kinput"
      required
      :mask="'##.###.###/####-##'"
      :has-error="!!cnpj && !cnpjValidation"
      error-message="CNPJ inválido"
      v-model="cnpj"
      label="CNPJ"
      placeholder="_ _ . _ _ _ . _ _ _ / _ _ _ _ - _ _"
      @input="update('cnpj', $event )"
    />
  </div>
</template>

<script>
import { KInput } from 'kaledo-components'
import { isCNPJ, isCPF, isPhone, isProfessionalEmail, maxLengthValidation } from '@/modules/validate/validators.js'
import { maxLengthValidationMessage } from '@/modules/validate/helpers.js'

export default {
  name: 'AccountCreationForm',
  components: {
    KInput
  },
  props: {
    stepValue: Object
  },
  data () {
    return {
      name: '',
      email: '',
      phone: '',
      document: '',
      cnpj: ''
    }
  },
  computed: {
    nameValidation () {
      return maxLengthValidation(this.name, 255)
    },
    emailValidation () {
      return isProfessionalEmail(this.email) && maxLengthValidation(this.email, 255)
    },
    emailValidationMessage () {
      return !maxLengthValidation(this.email, 255) ? maxLengthValidationMessage('email', 255) : 'E-mail inválido'
    },
    phoneValidation () {
      return isPhone(this.phone)
    },
    documentValidation () {
      return isCPF(this.document)
    },
    cnpjValidation () {
      return isCNPJ(this.cnpj)
    }
  },
  created () {
    this.name = this.stepValue.name
    this.email = this.stepValue.email
    this.phone = this.stepValue.phone
    this.document = this.stepValue.document
    this.cnpj = this.stepValue.cnpj
  },
  methods: {
    update (key, value) {
      this.$emit('input', { ...this.stepValue, [key]: value })
    }
  }
}
</script>

<style lang='scss' scoped>
@import '~@/styles/reference';
.account-creation-step-one {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  width: 100%;

  @include mobile {
    gap: 16px;
  }

  .kinput {
    width: 100%;
    max-width: 508px;

    @include tablet {
      max-width: 640px;

      :deep > .k-input {
        max-width: 640px;
      }
    }
  }
}
</style>
