<template>
  <section class="campaign-information">
    <campaign-information-header
      :event-type="campaign.event"
      :vouchers-count="campaign.vouchersCount"
      :is-exclusive="isExclusive"
    />

    <div class="campaign-information-cards">
      <div data-cy="important-informations-card" class="important-informations">
        <c-card class="information-card">
          <campaign-information-tag
            :id="campaign.id"
            :until="campaign.end_date"
            :symbol="campaign.symbol"
            :title="campaign.title"
            :value="campaign.value"
            :is-highlighted="!!campaign.is_highlighted"

            :cashback-value="campaign.eventData.cashback_value"
            :is-cashback="campaign.eventData.is_cashback"
            :cashback-symbol="campaign.eventData.cashback_symbol"

            v-on="$listeners"
          />

          <campaign-information-important
            v-if="campaign.important"
            :information="campaign.important"
          />
        </c-card>
      </div>

      <div class="how-to-use">
        <!-- v-if="campaign.howtouse && campaign.howtouse.length" -->
        <campaign-information-how
          :steps="campaign.howtouse"
          :voucher="campaignVoucher"
          :event="campaign.event"
          :is-exclusive="isExclusive"
          v-on="$listeners"
        />
      </div>
    </div>
  </section>
</template>

<script>
import CCard from '@/components/CComponents/CCard'

import CampaignInformationImportant from '@/components/Campaign/Information/CampaignInformationImportant'
import CampaignInformationHeader from '@/components/Campaign/Information/CampaignInformationHeader'
import CampaignInformationTag from '@/components/Campaign/Information/CampaignInformationTag'
import CampaignInformationHow from '@/components/Campaign/Information/CampaignInformationHow'

export default {
  components: {
    CampaignInformationImportant,
    CampaignInformationHeader,
    CampaignInformationTag,
    CampaignInformationHow,
    CCard
  },
  props: {
    campaign: {
      type: Object,
      required: true
    },
    isExclusive: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    campaignVoucher () {
      return this.campaign.event === 'online' ? this.campaign.voucher : ''
    }
  }
}
</script>

<style lang="scss">
@import '~@/styles/reference';

.campaign-information {
  display: flex;
  flex-direction: column;

  .campaign-information-cards {
    padding: 0 10px 10px 10px;

    .information-card {
      position: relative;
      padding: 20px;

      & > .campaign-information-tag:not(:last-child) {
        border-bottom: 1px solid $border-color;
      }
    }

    @include mobile {
      & > .important-informations:not(:last-child) { margin-bottom: 25px; }
    }

    @include desktop {
      display: flex;

      & > .important-informations {
        width: 50%;
        margin-right: 15px;

        & > .information-card {
          & > .campaign-information-tag { margin-bottom: 0; }
          & > .campaign-information-important { background: #fff; }
        }
      }

      & > .how-to-use { width: 50%; }
    }
  }
}
</style>
