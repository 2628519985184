<template>
  <div class="engagement-distribution-container">
    <my-orders
      :loading="loading"
      :orders="orders.data"
      :paginate="orders"
      @new-order="handleNewOrder"
      @cancel-order="orderId => handleCancelOrder(orderId)"
      @confirm-cancel="handleCancelOrder"
      @submit-search="handleGetOrders"
    />
    <onboarding-card
      v-if="showOnboarding"
      :steps="onboardingSteps"
    />
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
import MyOrders from '~/components/Engagement/Distribution/MyOrders'
import OnboardingCard from '~/components/General/OnboardingCard'
import {
  FEEDBACK,
  COMPANY,
  DISTRIBUTION_STEP,
  UPDATE_DISTRIBUTION_ORDER,
  CANCEL_DISTRIBUTION_ORDER,
  GET_DISTRIBUTION_ORDER_DETAILS,
  GET_DISTRIBUTION_ORDERS,
  DISTRIBUTION_ORDERS
} from '@/store/types'

export default {
  name: 'CoinDistribution',
  components: { MyOrders, OnboardingCard },
  data () {
    return {
      lastFilters: null,
      loading: true,
      hasSomeSearchSubmitted: false,
      showOrderDetailsModal: false,
      onboardingSteps: [
        {
          icon: 'pencil-square1',
          title: 'Criação',
          content: 'Primeiro, você deve criar o seu pedido clicando no botão "Novo Pedido". Você precisará preencher informações como: nome e CPF dos usuários, quantidade de moedas que serão distribuídas e personalizar uma mensagem para o usuário final.'
        },
        {
          icon: 'ph-coins-light',
          title: 'Entrega das moedas',
          content: 'No seu pedido você pode definir uma data para a entrega das moedas para o usuário final. A partir da data definida, as moedas estarão disponíveis em uma área atrelada ao seu clube para o resgate de gift cards.'
        },
        {
          icon: 'gift-card',
          iconSize: '26',
          title: 'Resgate de gift cards',
          content: 'Após a entrega das moedas, o usuário final poderá utilizá-las no resgate de gift cards de diversas marcas no seu clube.'
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      orders: DISTRIBUTION_ORDERS,
      company: COMPANY
    }),
    showOnboarding () {
      return !(this.loading || (this.orders.data && this.orders.data.length) || this.hasSomeSearchSubmitted)
    }
  },
  async mounted () {
    await this.handleGetOrders()
  },
  methods: {
    ...mapMutations({
      updateDistributionStep: DISTRIBUTION_STEP
    }),
    ...mapActions({
      feedback: FEEDBACK,
      getOrder: GET_DISTRIBUTION_ORDER_DETAILS,
      getOrders: GET_DISTRIBUTION_ORDERS,
      updateOrder: UPDATE_DISTRIBUTION_ORDER,
      cancelOrder: CANCEL_DISTRIBUTION_ORDER
    }),
    handleAnalytics (event, data) {
      window.dataLayer.push(
        {
          event,
          ...data
        }
      )
    },
    async handleCancelOrder (orderId) {
      this.handleAnalytics('pontos-pedido-cancelar', { company_name: this.company.slug })
      this.loading = true
      const response = await this.cancelOrder({
        id: orderId
      })
      this.loading = false
      if (response && response.data && response.data.success) {
        if (this.$route.name === 'engajamento-distribuicao') {
          this.handleGetOrders(this.lastFilters)
        } else {
          this.$router.push({ name: 'engajamento-distribuicao' })
        }
      }
    },
    handleNewOrder () {
      this.updateDistributionStep(1)
      this.handleAnalytics('pontos-pedido-criacao', { company_name: this.company.slug })
      this.$router.push('/engajamento/distribuicao/novo-pedido')
    },
    async handleUpdateOrder (order) {
      await this.updateOrder(order).then(async () => {
        await this.handleGetOrders(this.lastFilters)
      })
    },
    async handleGetOrders (filters) {
      this.loading = true
      this.lastFilters = filters
      if (filters) { this.hasSomeSearchSubmitted = true }
      await this.getOrders(filters)
        .catch(() => {
          this.loading = false
          this.feedback({
            type: 'error',
            title: 'Houve um erro ao carregar os pedidos',
            text: 'Tente novamente mais tarde'
          })
        })
      this.loading = false
    }
  }
}
</script>

<style lang='scss' scoped>

</style>
