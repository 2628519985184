<template>
  <c-card
    class="filter-toggler"
    :class="filterTogglerClass"
  >
    <span class="icon">
      <k-icon
        v-if="findIcon"
        :icon="formatIcon"
        size="20"
      />
      <c-icon
        v-else
        :icon="icon"
        size="20"
      />

    </span>
    <p class="name">
      {{ name }}
    </p>
  </c-card>
</template>

<script>
import CCard from '@/components/CComponents/CCard'
import { KIcon } from 'kaledo-components'
export default {
  components: { CCard, KIcon },
  props: {
    name: String,
    icon: String,
    selected: Boolean
  },
  computed: {
    filterTogglerClass () {
      return { '-selected': this.selected }
    },
    findIcon () {
     return this.icon.includes('icon-') ? true : false
    },
    formatIcon () {
      return this.icon.replace('icon-', '')
    }
  }
}
</script>

<style lang="scss">
@import '~@/styles/reference';

.filter-toggler {
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  background-color: white;
  height: 100px;
  cursor: pointer;

  & > .icon {
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid $border-color;
    border-radius: 100%;
    color: $icon-color;

    & > .c-icon { fill: $icon-color; }
  }

  & > .name {
    color: $title-color;
    margin-top: 3px;
  }

  &:hover { background-color: $hover-background-color; }

  &.-selected {
    background-color: $primary-color-placeholder;
    background-color: var(--color-primary);

    & > .name {
      color: $secondary-color-placeholder;
      color: var(--color-secondary);
    }
    & > .icon {
      border-color: $secondary-color-placeholder;
      border-color: var(--color-secondary);
      color: var(--color-secondary);

      & > .c-icon {
        fill: $secondary-color-placeholder;
        fill: var(--color-secondary);
      }
    }
  }
}
</style>
