<template>
  <div class="unit-card">
    <div class="info">
      <h3 class="name">
        {{ address.street }}, {{ address.number }}
      </h3>
      <p class="address">
        {{ address.city }}/{{ address.state }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    address: {
      type: Object,
      required: true
    }
  }
}
</script>

<style lang="scss">
@import '~@/styles/reference';

.unit-card {
  padding: 20px 0;
  margin: 0 20px;

  &:not(:last-child) {
    border-bottom: 1px solid $border-color;
  }

  & > .info {
    & > .name {
      color: $primary-color-placeholder;
      color: var(--color-primary);
      font-size: 14px;
      line-height: 19px;
      margin-bottom: 4px;
    }

    & > .address {
      font-size: 12px;
      line-height: 18px;
    }
  }
}
</style>
