<template>
  <div
    class="upload-image"
    data-cy="upload-image"
  >
    <div class="upload-image-button-container">
      <label
        :for="`upload-image-${id}`"
        class="upload-image-button"
        :class="error ? '-error' : ''"
      >
        <div class="icon-container circle center">
          <k-icon
            class="icon"
            icon="image-picture-edit-stars"
          />
        </div>
        <span
          class="text"
          data-cy="button-text"
        >
          {{ buttonText }}
        </span>
        <div v-if="loading">
          <c-spinner />
        </div>
        <div
          v-else-if="showConfirmAndRemove"
          data-cy="success-icon"
          class="success-icon-container circle center"
        >
          <k-icon
            class="success-icon"
            icon="done-check3"
          />
        </div>
      </label>
      <button
        v-show="showConfirmAndRemove"
        class="remove-icon-container circle center"
        data-cy="clear-file"
        @click="clear"
      >
        <k-icon
          class="remove-icon"
          icon="trash-delete-bin4"
        />
      </button>
    </div>
    <input
      :id="`upload-image-${id}`"
      class="hidden-input"
      type="file"
      ref="file"
      name="UploadImage"
      v-validate="{ required: true, ext: exts }"
      v-bind="$attrs"
      @change="change"
    />
    <span
      v-if="showErrorMessage"
      class="error"
      data-cy="cinput-feedback"
    >
      {{ errorMessage }}
    </span>
  </div>
</template>

<script>
import { KIcon } from 'kaledo-components'

export default {
  name: 'UploadImage',
  components: {
    KIcon
  },
  props: {
    id: {
      type: String,
      default: 'upload-image'
    },
    isEdit: {
      type: Boolean,
      default: false
    },
    maxSize: Number,
    placeholder: String,
    requiredWidth: Number,
    requiredHeight: Number
  },
  data () {
    return {
      loading: false,
      files: [],
      imageDimensions: {
        width: 0,
        height: 0
      },
      fileName: '',
      exts: ['png', 'jpg', 'jpeg'],
      error: false,
      editMode: false
    }
  },
  computed: {
    buttonText () {
      if (this.fileName.length > 0) {
        const maxSize = 18
        if (this.fileName.length < maxSize) return this.fileName
        return `${this.fileName.substr(0, maxSize)}...`
      }
      if (this.editMode) return this.placeholder ? `${this.placeholder}.png` : 'imagem.png'
      return 'Selecionar imagem'
    },
    hasFile () {
      return this.fileName.length > 0
    },
    showConfirmAndRemove () {
      return this.hasFile || this.editMode
    },
    showErrorMessage () {
      return this.error || !this.imageDimensionsValidation || !this.imageFileSizeValidation
    },
    errorMessage () {
      if (this.maxSize && !this.imageFileSizeValidation) return `Tamanho do arquivo deve ser menor que ${this.maxSize}kb`
      return !this.imageDimensionsValidation ? 'Dimensões de imagem inválidas' : 'Arquivo inválido'
    },
    imageFileSizeValidation () {
      if (Array.isArray(this.files) && this.files.length === 0) return true
      if (!this.maxSize) return true
      return this.files.size <= (this.maxSize * 1024)
    },
    imageDimensionsValidation () {
      if (Array.isArray(this.files) && this.files.length === 0) return true
      if (!this.requiredWidth && !this.requiredHeight) return true
      return this.imageDimensions.width === this.requiredWidth && this.imageDimensions.height === this.requiredHeight
    }
  },
  watch: {
    isEdit (newvalue) {
      this.editMode = newvalue
    },
    showErrorMessage () {
      this.$emit('error', this.showErrorMessage)
    }
  },
  mounted () {
    this.editMode = this.isEdit
  },
  methods: {
    clear () {
      this.editMode = false
      this.files = []
      this.fileName = ''
      this.$emit('change', this.files)
    },
    getImageDimensions (imageUrl, callback) {
      return new Promise((resolve, reject) => {
        const img = new Image()
        img.onload = function () {
          const dimensions = {
            width: this.width,
            height: this.height
          }
          return callback(null, dimensions)
        }
        img.onerror = function () {
          return callback(new Error('Failed to load image'))
        }
        img.src = imageUrl
      })
    },
    change (event) {
      this.loading = true
      const files = event.target.files[0]
      const reader = new FileReader()
      reader.onload = (e) => {
        this.loading = false
      }
      setTimeout(() => {
        if (this.errors.has('UploadImage')) {
          this.clear()
          this.loading = false
          this.error = true
          this.$emit('error', true)
          return
        }
        this.loading = false
        this.error = false
        this.$emit('error', false)
        this.files = files
        this.fileName = files.name
        const imageUrl = URL.createObjectURL(files)
        this.getImageDimensions(imageUrl, (error, dimensions) => {
          this.imageDimensions = {
            width: dimensions.width,
            height: dimensions.height
          }
          this.error = error
          return !error
        })
        this.$emit('change', this.files)
      }, 0)
    }
  }
}
</script>

<style lang='scss' scoped>
@import '~@/styles/reference';
.upload-image {
  margin-bottom: 24px;
  position: relative;
  .upload-image-button-container {
    display: flex;
    align-items: center;
      .upload-image-button {
      width: 280px;
      padding: 8px 16px;
      border: 1px dashed #BEC0D3;
      background-color: #FFF;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.16);
      border-radius: 4px;
      cursor: pointer;
      display: flex;
      align-items: center;
      &.-error {
        border-color:#ff7986;
      }
      .icon-container {
        width: 36px;
        height: 36px;
        border: 1px solid #BEC0D3;
        margin-right: 8px;
        .icon {
          font-size: 20px;
          color: #5E6684;
        }
      }
      .text {
        text-transform: uppercase;
        font-size: 12px;
        color: #608CEA;
        width: 100%;
        display: block;
      }
      .success-icon-container {
        width: 32px;
        height: 32px;
        background: #4CAD50;
        .success-icon {
          color: white;
          font-size: 20px;
        }
      }
    }
  }
  .remove-icon-container {
    width: 40px;
    height: 40px;
    border: 1px solid #5E6684;
    margin-left: 8px;
    background: none;
    cursor: pointer;
    .remove-icon {
      font-size: 20px;
      color: #5E6684;
    }
  }
  .error {
    display: flex;
    align-items: center;
    color: #ff7986;
    font-size: 12px;
    margin-top: 8px;
    left: 0px;
    right: 0px;
  }
  .hidden-input {
    display: none;
  }
}
.center {
  display: flex;
  align-items: center;
  justify-content: center;
}
.circle {
  border-radius: 50%;
  flex-shrink: 0;
}
</style>
