<template>
  <div
    class="loading-card"
    :style="{height: `${height}px`}"
  >
    <c-spinner
      class="loading"
      size="lg"
    />
    <p class="text">
      Carregando informações
    </p>
  </div>
</template>

<script>
export default {
  name: 'LoadingCard',
  props: {
    height: {
      type: Number,
      default: 353
    }
  }
}
</script>

<style lang='scss' scoped>
.loading-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .text {
    width: 171px;
    margin-top: 20px;
    margin-left: 10px;
    &:after {
      overflow: hidden;
      display: inline-block;
      vertical-align: bottom;
      -webkit-animation: ellipsis steps(4,end) 900ms infinite;
      animation: ellipsis steps(4,end) 900ms infinite;
      content: "\2026"; /* ascii code for the ellipsis character */
      width: 0px;
    }
  }
  @keyframes ellipsis {
  to {
    width: 1.25em;
  }
}

@-webkit-keyframes ellipsis {
  to {
    width: 1.25em;
  }
}
}
</style>
