<template>
  <div class="containerDiv">
    <div class="image">
      <c-character type="embarrassed" />
    </div>

    <div class="container">
      <h3 class="title">
        Tem certeza que deseja excluir os usuários selecionados?
      </h3>
      <p class="sub-title">
        Se excluí-los, eles não irão conseguir acessar o clube e todas as informações
        armazenadas hoje serão deletadas permanentemente.
      </p>
    </div>

    <div class="actions">
      <c-button
        size="lg"
        @click="$emit('close')"
      >
        Cancelar
      </c-button>
      <c-button
        primary
        size="lg"
        class="action-delete"
        @click="$emit('bulkDeleteUsers'), $emit('close')"
      >
        Excluir
      </c-button>
    </div>
  </div>
</template>

<script>
import CCharacter from '@/components/CComponents/CCharacter'
import CButton from '@/components/CComponents/CButton'

export default {
  components: {
    CCharacter,
    CButton
  }
}
</script>

<style lang="scss">
@import '~@/styles/reference';

.containerDiv {

    & > .container {
      margin-top: 20px;

      & > .title {
        margin-top: 20px;
        text-align: center;
      }

      & > .sub-title {
        margin-top: 20px;
        text-align: center;
      }
    }

    & > .actions {
      margin-top: 20px;
      display: flex;
      justify-content: center;

      & >.action-delete {
        margin-left: 10px;
      }
    }
}
.image{
  display: flex;
  justify-content: center;
}
</style>
