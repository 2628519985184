<template>
  <div
    data-cy="awards-header"
    class="awards-header"
  >
    <div class="content">
      <div class="navigation">
        <c-toggler
          v-if="$route.name !== 'premiacoes-novo-pedido'"
          class="toggler"
          :tabs="navTabs"
          :active-tab="$route.name"
          @change-tab="(name) => { if ($route.push !== name) { $router.push({ name }) } }"
        />

        <k-icon
          data-cy="back-button"
          v-else
          icon="left"
          class="back-button"
          @click.native="openCancelModal = true"
        />
      </div>

      <div class="actions">
        <c-button
          v-if="showNewOrderButton"
          data-cy="new-award-button"
          class="new-award-button"
          primary
          icon="plus"
          @click="gotoNewOrder()"
        >
          Novo pedido
        </c-button>
        <c-borded-info-button
          data-cy="show-onboarding-button"
          class="info-button"
          @btn-click="$emit('show-onboarding')"
        />
      </div>
    </div>
    <c-confirm-modal
      :btn-props="{ primary: true }"
      cancel-text="Voltar"
      confirm-text="Cancelar pedido"
      character="embarrassed"
      :title="cancelMessage"
      message="Se cancelar, todas as informações já inseridas não serão processadas."
      :is-opened="openCancelModal"
      @close="openCancelModal = false"
      @confirm="confirmCancel"
    />
  </div>
</template>

<script>
import CConfirmModal from '@/components/CComponents/CConfirmModal'
import { KIcon } from 'kaledo-components'
import CToggler from '@/components/CComponents/CToggler'
import CBordedInfoButton from '@/components/CComponents/CBordedInfoButton.vue'
import { mapMutations, mapGetters } from 'vuex'
import { AWARD_STEP, COMPANY } from '@/store/types'
export default {
  components: {
    CConfirmModal,
    CToggler,
    KIcon,
    CBordedInfoButton
  },
  props: {
    navTabs: Array
  },
  data () {
    return {
      openCancelModal: false,
      cancelMessage: 'Tem certeza que deseja cancelar a criação do pedido?'
    }
  },
  computed: {
    ...mapGetters({
      step: AWARD_STEP,
      company: COMPANY
    }),
    showNewOrderButton () {
      return this.$nuxt.$route.name === 'premiacoes-pedidos'
    }
  },
  methods: {
    ...mapMutations({
      updateAwardStep: AWARD_STEP
    }),
    handleAnalytics (event, data) {
      window.dataLayer.push(
        {
          event,
          ...data
        }
      )
    },
    gotoNewOrder () {
      this.updateAwardStep(1)
      this.handleAnalytics('premios-pedido-criacao', {
        company_name: this.company.slug
      })
      this.$router.push('novo-pedido')
    },
    confirmCancel() {
      this.handleAnalytics('premios-pedido-abandonar', {
        company_name: this.company.slug
      })
      this.openCancelModal = false
      this.$router.push({ name: 'premiacoes' })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/styles/reference';

.awards-header {
  box-shadow: 0px 4px 1px -1px rgba(213, 214, 226, 0.4);
  background-color: #FFF;
  height: 59px;

  & > .content {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include desktop { @include container }
    @include mobile { flex-direction: column; }

    & > .navigation {
      & > .back-button {
        margin-top: -5px;
        font-size: 20px;
        width: 30px;
        height: 30px;
        margin-left: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        transition: all .1s ease;
        border: 1px solid #5e6684;
        border-radius: 50px;

        &:hover {
          border: 3px solid rgba(94, 102, 132, 1);
          border-radius: 50px;
        }
      }
      @include mobile { width: 100%; }

    }

    & > .actions {
      height: 100%;
      padding-bottom: 5px;
      display: flex;
      align-items: flex-end;
      flex-flow: row wrap;

      .new-award-button {
        width: 170px;
        height: 40px;
        border-radius: 30px;
        font-size: 12px;
        margin-top: 16px;
        font-weight: 400;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}

</style>
