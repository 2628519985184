<template>
  <div class="types-rescue-component">
    <span class="label-rescue">TIPOS DE RESGATE</span>

    <c-select
      class="type-rescue"
      placeholder="Selecione um tipo de resgate"
      :items="rescues"
      v-on="$listeners"
      :value="rescue"
      track-by="name"
      display-by="label"
      data-cy="rescue-type-select"
    />
  </div>
</template>

<script>
export default {
  props: {
    newCampaign: Object,
    rescues: Array,
    rescue: String
  }
}
</script>

<style lang="scss">
@import '~@/styles/reference';

.types-rescue-component {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;

  @include mobile { flex-direction: column; }

  & > .label-rescue {
    color: rgba(18, 30, 72, 0.8);
    text-transform: uppercase;
    font-size: 12px;
    line-height: 16px;
    padding-right: 10px;
    @include mobile {
      margin-right: auto;
      padding-bottom: 10px;
    }
  }
  & > .type-rescue {
    width: 70%;
    @include mobile { width: 100%; }
  }
}
</style>
