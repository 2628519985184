<template>
  <section v-if="!isLoading">
    <user-form
      :user="user"
      :form="form"
      :places="places"
      :is-valid="isValid"
      @synchronize="synchronize"
      @reset-city="form.city = ''"
      @submit="handlePatch"
    />
  </section>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Loadable from '@/modules/loadable'
import * as types from '@/store/types'

import UserForm from '@/components/User/Form/UserForm'

import { estados } from '@/content/places'

export default {

  components: { UserForm },
  mixins: [Loadable],

  data () {
    return {
      form: {
        name: '',
        last_name: '',
        email: '',
        phone: '',
        birthday: '',
        gender: '',
        state: '',
        city: ''
      },
      places: [],
      hasErrors: false
    }
  },

  computed: {
    ...mapGetters({ user: types.USER }),

    isValid () {
      const requireds = ['email']

      const data = Object.entries(this.form).map(([key, value]) => ({ [key]: value }))

      const verifyValid = (requiredProps, data) => requiredProps.every(prop => data.some(obj => obj[prop]))

      return verifyValid(requireds, data) && !this.hasErrors
    }
  },

  beforeDestroy () {
    this.clearUser()
  },

  methods: {
    ...mapActions({
      getUser: types.USER,
      patchUser: types.USER_PATCH,
      clearUser: types.USERS_CLEAR
    }),

    async load () {
      const userId = this.$route.params.userId
      await this.getUser({ id: userId })

      const { avatar, document, id, roles, status, ...rest } = { ...this.user }
      this.form = rest

      this.places = estados
    },

    synchronize (v) {
      const { error, ...rest } = v

      this.hasErrors = error.length
      this.form = { ...this.form, ...rest }
    },

    async handlePatch () {
      const formatedUser = this.form
      delete formatedUser.panel_onboarding
      const id = await this.patchUser({ user: formatedUser, id: this.user.id })

      if (id) {
        this.$router.push({ name: 'usuarios-userId', params: { userId: id } })
      }
    }
  }
}
</script>
