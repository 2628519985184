<template>
  <div
    data-cy="awards-orders"
    class="orders-resume"
  >
    <div class="title-header">
      <c-title
        class="title"
      >
        Pedidos
      </c-title>
      <div class="actions">
        <search-field
          data-cy="order-name-filter-input"
          class="order-search"
          placeholder="Busque por nome do pedido"
          icon-size="20"
          :is-loading="false"
          :term="search"
          @input="search = $event"
          @submit="submitSearch"
          @reset="resetSearch"
          @focusout="$emit('focusout')"
        />
        <filters-popover
          :filters-by-status="filtersByStatus"
          :show-filter="showFilter"
          @blur="showFilter = false"
          @show-filter="showFilter = !showFilter"
          @clear-filter="clearFilter"
          @select-filter="filterOrders"
          @apply-filter="submitSearch"
          @close="showFilter = false"
        />
      </div>
    </div>
    <transition
      name="fade"
      mode="out-in"
    >
      <c-spinner v-if="loading" />
      <c-empty
        v-else-if="showEmpty"
        class="empty-search"
        title="Ops!"
        character="searching"
        message="Não encontramos nenhum pedido relacionado à busca"
      />
      <listing-table
        v-else
        data-cy="orders-table"
        empty-banner-text="Você ainda não possui um pedido de premiação"
        has-empty-banner
        :rows="rows"
        :cols="tableCols"
        :paginate="links"
        @cancel-click="handleCancelClick"
        @details-click="handleDetailsClick"
        @update-paginate="handlePaginate"
      />
    </transition>

    <c-confirm-modal
      :btn-props="{ primary: true }"
      confirm-text="Cancelar pedido"
      character="embarrassed"
      title="Tem certeza que deseja cancelar seu pedido?"
      message="Esse pedido de premiação não será processado"
      :is-opened="openCancelModal"
      @close="openCancelModal = false; orderId = null"
      @confirm="$emit('cancel-order', orderId); openCancelModal = false"
    />
  </div>
</template>

<script>
import { ORDERS_LIST } from '@/store/types'
import { mapGetters } from 'vuex'
import OrdersMixins from '@/components/Awards/Orders/orders-mixins.js'
import ListingTable from '@/components/General/ListingTable.vue'
import FiltersPopover from '@/components/General/FiltersPopover'
import SearchField from '@/components/CComponents/SearchField'
import CConfirmModal from '@/components/CComponents/CConfirmModal'

const awardsOrdersStatuses = ['canceled', 'confirmed', 'waiting_payment', 'schedulled']

export default {
  components: {
    FiltersPopover,
    ListingTable,
    SearchField,
    CConfirmModal
  },
  mixins: [OrdersMixins],
  props: {
    loading: Boolean
  },
  data () {
    return {
      orderId: null,
      openCancelModal: false,
      search: '',
      someSearchHasSubmitted: false,
      showFilter: false,
      managerSelected: false,
      filters: {},
      paramsChanged: false,
      params: {
        limit: 10,
        page: 1
      },
      tableCols: [
        { label: 'Nº', align: 'center' },
        { label: 'Pedido', align: 'center' },
        { label: 'Valor', align: 'center' },
        { label: 'Agendado para', align: 'center' },
        { label: 'Status', align: 'center' },
        { label: 'Ação', align: 'center' }
      ],
      filtersByStatus: [
        { name: 'Aguardando pagamento', icon: 'barcode', status: 'waiting_payment', selected: false },
        { name: 'Entrega agendada', icon: 'clipboard-check', status: 'schedulled', selected: false },
        { name: 'Pedido entregue', icon: 'award-star', status: 'confirmed', selected: false },
        { name: 'Pedido cancelado', icon: 'cross-circle2', status: 'canceled', selected: false }
      ]
    }
  },
  computed: {
    ...mapGetters({
      orders_list: ORDERS_LIST
    }),
    hasFilters () {
      return this.filters && this.filters.type && this.filters.type.length > 0
    },
    showEmpty () {
      const hasSearch = this.search && this.search.length > 0
      const rowsEmpty = this.rows && this.rows.length === 0
      const hasFilter = this.filters && this.filters.type && this.filters.type.length > 0
      return !this.loading && (this.someSearchHasSubmitted || hasSearch || hasFilter) && rowsEmpty
    },
    rows () {
      if (!this.orders_list || !this.orders_list.data) return []
      return this.orders_list.data
    },
    links () {
      if (!this.orders_list || !this.orders_list.meta) return {}
      return this.orders_list.meta
    }
  },
  methods: {
    async handlePaginate (paginate) {
      this.params = paginate
      this.paramsChanged = true
      await this.submitSearch()
    },
    handleDetailsClick (orderId) {
      this.$router.push({ path: `detalhes/${orderId}` })
    },
    handleCancelClick (orderId) {
      this.openCancelModal = true
      this.orderId = orderId
    },
    filterOrders (property) {
      const hasType = awardsOrdersStatuses.some(type => type.includes(this.filters.type))

      hasType && this.filters.type === property.type
        ? this.filters = {}
        : this.filters = { ...this.filters, ...property }

      const changeStatus = filter => {
        filter.status === property.type
          ? filter.selected = !filter.selected
          : filter.selected = false
        return filter
      }
      this.filtersByStatus.map(changeStatus)
    },
    async clearFilter () {
      this.filters = {}
      this.filtersByStatus = this.filtersByStatus.map(filter => ({ ...filter, selected: false }))
      await this.submitSearch()
    },
    isManager () {
      this.managerSelected = !this.managerSelected

      this.managerSelected
        ? this.filters = { ...this.filters, manager: this.managerSelected }
        : delete this.filters.manager
    },

    async resetSearch () {
      this.search = ''
      await this.submitSearch()
    },

    async submitSearch () {
      this.someSearchHasSubmitted = true
      const type = Object.values(this.filters)[0] // mal time
      const hasType = awardsOrdersStatuses.some(v => v === type)
      if (this.search || this.filters.manager || hasType || this.paramsChanged) {
        this.$emit('submit-search', { title: this.search, status: type, ...this.params })
        this.paramsChanged = false
      } else {
        this.params.page = 1
        this.$emit('submit-search')
      }
      this.showFilter = false
    }
  }
}
</script>

<style lang="scss">
@import '~@/styles/reference';

.orders-resume {
  max-width: 1020px;
  margin-top: 35px;
  padding: 0 10px;

  @include desktop { @include container; }

  > .title-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    > .title {
      max-width: 200px;

    }

    > .new-award-button {
      width: 170px;
      height: 40px;
      border-radius: 30px;
      font-size: 12px;
      margin-top: 16px;
      font-weight: 400;
      display: flex;
      align-items: center;
      justify-content: center;

      .icon {
        position: relative;
        font-size: 20px;
        bottom: 0;
        left: 0;
        margin-right: 5px;
      }
      .text-icon {
        margin-left: 5px;
        font-size: 30px;
        font-weight: bold;
      }
    }

    .actions {
      display: flex;

      .order-search {
        margin-right: 20px;

        .input {
          width: 350px;
          background: #FDFDFD;
          border: 1px solid rgba(86, 95, 125, 1);
          border: 1px solid var(--color-secondary);
          transition: all .1s ease;
        }
      }

      .order-filter {
        .filter-button {
          width: 130px;
          border: 1px solid rgba(86, 95, 125, 1);
          transition: all .1s ease;

          &:hover {
            border: 3px solid rgba(86, 95, 125, 1);
          }
        }
        .header .actions{
          flex: none;

          .c-button {
            border: 1px solid rgba(86, 95, 125, 1);
            transition: all .1s ease;

            &:hover {
              border: 3px solid rgba(86, 95, 125, 1);
            }
          }
        }
        .filter-toggler.-selected {
          background: #121E48;
        }
        .popover-filter-apply {
           background: #121E48;
        }
      }
    }
  }
}
</style>
