<template>
  <div class="general-users-table">
    <div class="header">
      <k-input
        data-cy="users-table-search-input"
        class="search"
        icon="search1"
        placeholder="Buscar CPF ou email"
        @input="$emit('search', $event)"
      />
      <div class="tag-container">
        <c-tag
          v-show="qtdRowsSelected"
          data-cy="selected-rows-total-tag"
          icon="trash-delete-bin4"
        >
          {{ qtdRowsSelected }} {{ selectedUsersText }}
        </c-tag>
      </div>
      <button
        data-cy="remove-users-button"
        class="remove-button"
        :class="!qtdRowsSelected ? '-disabled' : ''"
        @click="qtdRowsSelected ? $emit('remove-items') : ''"
      >
        <k-icon
          class="remove-icon"
          icon="trash-delete-bin4"
        />
      </button>
    </div>
    <c-table-container
      class="awards-user-table-import"
      :paginate-bg="false"
      v-bind="$attrs"
      v-on="$listeners"
    >
      <table class="data-table">
        <tr class="table-header">
          <th
            class="-cell"
          />

          <th
            v-for="(col, index) in cols"
            class="-cell"
            :key="index"
          >
            <span class="-text">{{ col.label }}</span>
          </th>
        </tr>
        <tr
          class="table-row"
          :class="row.checked ? '-selected' : ''"
          v-for="(row, index) in rows"
          :key="index"
        >
          <td
            class="-cell"
          >
            <input
              :disabled="row.document === ''"
              :checked="row.checked"
              :value="row.checked"
              @input="e => $emit('select', row, e.target.value)"
              type="checkbox"
            />
          </td>
          <td
            class="-cell"
          >
            <p class="text">
              {{ cpfFormatted(row.document) }}
            </p>
          </td>
          <td
            class="-cell"
          >
            <p class="text">
              {{ row.email }}
            </p>
          </td>
        </tr>
      </table>
    </c-table-container>
  </div>
</template>

<script>
import { KIcon, KInput } from 'kaledo-components'
import CTag from '@/components/CComponents/CTag'
import CTableContainer from '@/components/CComponents/CTableContainer'

export default {
  name: 'UsersTable',
  components: {
    KIcon,
    KInput,
    CTag,
    CTableContainer
  },
  props: {
    rows: Array,
    selectedRows: {
      type: Array,
      default () {
        return []
      }
    },
    hasSelectedRows: Boolean,
    useCpfMask: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    cols () {
      return [
        { label: 'CPF', align: 'center' },
        { label: 'E-Mail', align: 'center' }
      ]
    },
    isSomeRowUnselected () {
      const checkedRows = this.rows.filter(row => row.checked === false)
      return checkedRows.length > 0
    },
    qtdRowsSelected () {
      return this.selectedRows.length
    },
    selectedUsersText () {
      return this.qtdRowsSelected > 1 ? 'usuários selecionados' : 'usuário selecionado'
    }
  },
  methods: {
    cpfFormatted (value) {
      if (!value) return ''
      if (!this.useCpfMask || value.length === 0 || value.includes('.')) return value
      return `${value.substring(0, 3)}.${value.substring(3, 6)}.${value.substring(6, 9)}-${value.substring(9, 11)}`
    },
    handleSelectEmit () {
      this.$emit('select-all-rows', this.isSomeRowUnselected)
    }
  }
}
</script>

<style lang='scss' scoped>
@import '~@/styles/reference';
.general-users-table {
  margin-top: 16px;
  border-radius: 8px;
  .header {
    background: #FDFDFD;
    padding: 8px 24px;
    border-radius: 8px 8px 0px 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #eaedef;

    .search {
      width: 100%;
      max-width: 304px;
      display: flex;
      align-items: center;
      padding: 8px 16px;
      transition: all ease 0.3s;
      background: #FFFFFF;

      :deep .k-input {
        padding-top: 2px;
        border-radius: 16px;
        height: 40px;
        box-shadow: none;
        border: 1px solid #D8D9DE;
      }
    }
    .tag-container {
      width: 100%;
      height: 28px;
      margin-left: 25px;
    }
    .remove-button {
      border-radius: 50%;
      background: #202950;
      background: var(--color-primary);
      border: none;
      width: 65px;
      height: 40px;
      cursor: pointer;
      transition: all ease 0.3s;

      &:hover:not(.-disabled) {
        background: #364587;
        background-color: rgba($primary-color-placeholder, .8);
        background-color: var(--color-primary-8);
      }

      &.-disabled {
        cursor: default;
        background: #D8D8D8;

        .remove-icon {
          color: #5E6684;
        }
      }

      .remove-icon {
        font-size: 20px;
        color: #FDFDFD;
      }
    }
  }
  :deep .table {
    border-radius: 0px 0px 8px 8px;
  }
  .data-table {
    box-shadow: none;
    border: none;
    border-radius: 0px 0px 8px 8px;
    width: 100%;
    margin-top: -2px;

    .table-header {
      height: 60px;
      background: white;
      width: 100%;
      display: flex;
      border-bottom: 1px solid #eaedef;
      border-top: 1px solid #eaedef;
    }

    .table-row {
      display: flex;
      width: 100%;
      height: 60px;
      background: white;
      display: flex;
      border-bottom: 1px solid #eaedef;

      &.-selected {
        background: #F8F9FD;
      }
      &:last-of-type {
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;
      }
    }

    .-cell {
      display: flex;
      align-items: center;
      justify-content: center;
      position: relative;
      text-align: center;
      width: 100%;
      height: 100%;
      padding: 0 18px;

      span.-text {
        font-weight: 700;
        text-transform: uppercase;
        color: #5E6684;
      }

      p.text {
        color: #0A0D1A;
      }

      &:first-of-type {
        max-width: 120px;
      }
    }
  }
}
</style>
