<template>
  <forget-pwd-container />
</template>

<script>
import ForgetPwdContainer from '@/containers/Login/ForgetPwdContainer'

export default {
  components: { ForgetPwdContainer },
  layout: 'unauthenticated',
  middleware: 'checkauth'
}
</script>
