<template>
  <div class="c-input-container">
    <label
      class="label"
      v-if="label"
    >
      {{ label }}
      <span
        class="required"
        v-if="required"
      >
        *
      </span>
    </label>

    <slot />

    <p
      class="validation"
      v-if="validation"
    >
      {{ validation }}
    </p>
  </div>
</template>

<script>
export default {
  props: {
    label: String,
    validation: String,
    required: Boolean
  }
}
</script>

<style lang="scss" scoped>
.c-input-container {
  margin-bottom: 20px;

  & > .label,
  & > .validation {
    width: 100%;
    display: block;
  }

  & > .label {
    color: orange;
    text-transform: uppercase;
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 9px;
  }

  & > .validation {
    margin-top: 5px;
    color: lightgreen;
  }
}
</style>
