<template>
  <section
    class="partner-information"
    data-cy="partner-details"
  >
    <div class="partner-information-header">
      <c-title data-cy="partner-details-title" icon="store">
        Detalhes do parceiro
      </c-title>

      <div class="partner-actions">
        <c-button
          data-cy="edit-partner-button"
          icon="pencil"
          :icon-only="!isDesktop"
          size="lg"
          @click="editPartner()"
        >
          {{ isDesktop ? 'Editar' : null }}
        </c-button>

        <c-button
          data-cy="delete-partner-button"
          icon="trash"
          :icon-only="!isDesktop"
          size="lg"
          @click="displayDeleteWarning = true"
        >
          {{ isDesktop ? 'Excluir parceiro' : null }}
        </c-button>
      </div>
    </div>

    <div class="partner-information-content">
      <partner-card
        :id="partner.id"
        :name="partner.name"
        :logo="partner.logo"
        :slug="partner.slug"
        :is-exclusive="!!partner.company_id"
        :campaign-count="partner.campaignsCount"
        :status="partner.company_id ? !!partner.status : !!partner.is_blocked"
        :category="partnerCategory"
        @toggle-partner-status="toggleStatus"
      />

      <partner-info-card
        :description="partner.description || ''"
        :phone="partner.contact ? partner.contact.phone : ''"
        :owner="partner.contact ? partner.contact.name : ''"
        :email="partner.contact ? partner.contact.email : ''"
      />

      <partner-warning
        :is-opened="displayStatusWarning"
        @close="displayStatusWarning = false"
      />
    </div>

    <!-- TODO: Move this into separate component -->
    <c-modal
      name="delete-confirm"
      class="delete-confirm"
      :is-opened="displayDeleteWarning"
      @close="displayDeleteWarning = false"
    >
      <c-character type="embarrassed" />
      <div class="warning-text">
        <h3 class="lead">
          Tem certeza que deseja excluir esse parceiro?
        </h3>
        <p class="text">
          Se excluí-lo, as mídias e ofertas armazenadas hoje também serão
          deletadas permanentemente.
        </p>
      </div>

      <div class="modal-actions">
        <c-button
          data-cy="delete-partner-cancel-button"
          size="lg"
          class="btn-dismiss"
          @click="displayDeleteWarning = false"
        >
          Cancelar
        </c-button>
        <c-button
          data-cy="delete-partner-confirm-button"
          danger
          size="lg"
          class="btn-dismiss"
          @click="$emit('delete-partner', partner.id)"
        >
          Excluir
        </c-button>
      </div>
    </c-modal>
  </section>
</template>

<script>
import CCharacter from '@/components/CComponents/CCharacter'
import CModal from '@/components/CComponents/CModal'

import PartnerCard from '@/components/Partners/Details/PartnerCard'
import PartnerInfoCard from '@/components/Partners/Details/PartnerInfoCard'
import PartnerWarning from '@/components/Partners/PartnerWarning'

import { mapGetters } from 'vuex'
import * as types from '@/store/types'

export default {
  components: {
    CCharacter,
    CModal,
    PartnerCard,
    PartnerWarning,
    PartnerInfoCard
  },
  props: {
    partner: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      displayStatusWarning: false,
      displayDeleteWarning: false
    }
  },
  computed: {
    ...mapGetters({ company: types.COMPANY }),
    partnerCategory () {
      const { categories } = this.partner || { }
      return categories && categories.length ? categories[0].name : ''
    }
  },
  methods: {
    toggleStatus (ev) {
      if (!ev.status) { this.displayStatusWarning = true }

      this.$emit('toggle-partner-status', ev)
    },
    editPartner () {
      this.$router.push({ name: 'parceiros-slug-editar', params: this.$route.params })
      this.handleAnalytics('editar-parceiro', { company_name: this.company.name, partner_slug: this.partner.slug })
    },
    handleAnalytics (event, data) {
      window.dataLayer.push(
        {
          event: event,
          ...data
        }
      )
    }
  }
}
</script>

<style lang="scss">
@import '~@/styles/reference';

.partner-information {
  display: flex;
  flex-direction: column;

  padding-top: 30px;
  margin-bottom: 25px;

  & > .partner-information-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;

    // TODO: Replace .c-button class with something else
    .partner-actions > .c-button:not(:last-child) { margin-right: 10px; }
  }

  & > .partner-information-content {
    display: flex;

    & > .partner-card { flex-shrink: 0; }
    @include desktop { & > .partner-info-card { margin-left: 10px; } }

    @include mobile {
      flex-direction: column;
      & > .partner-card { margin-bottom: 10px; }
    }
  }

  .c-modal.delete-confirm {
    background-color: #FFF !important;

    & > .content {
      display: flex;
      align-items: center;
      flex-direction: column;

      & > .c-character {
        width: 70px;
        height: 70px;
        padding: 50px;
      }

      & > .warning-text {
        text-align: center;

        & > .lead { color: $title-color; margin-top: 20px; }
        & > .text {
          font-size: 14px;
          color: $text-color;

          max-width: 410px;
          margin: 17px 0 20px 0;
        }
      }

      & > .modal-actions {
        width: 100%;
        display: flex;
        justify-content: center;

        & > .c-button {
          width: 100%;
          @include desktop { max-width: 120px; }
          &:not(:last-child) { margin-right: 10px; }
        }
      }
    }
  }
}
</style>
