<template>
  <div
    v-if="isLoading"
    class="unit-list-loading"
  >
    <c-spinner
      class="spinner"
      size="md"
    />
  </div>

  <div
    class="unit-list"
    v-else
  >
    <div class="unit-header">
      <c-title
        class="title"
        icon="map-marker"
      >
        {{ title }}
      </c-title>

      <c-input
        icon="search"
        class="action search"
        placeholder="Procure unidades por cidade ou estado"
        v-model="search"
      />
      <slot name="action" />
    </div>

    <template v-if="hasUnits">
      <c-card class="unit-card-list">
        <transition-group
          tag="div"
          name="unit-list"
          class="list content"
          appear
        >
          <unit-card
            v-for="(address, index) in addresses.list"
            :key="`unit-card-${index}`"
            :address="address"
            v-on="$listeners"
            class="unit"
          />
        </transition-group>
      </c-card>

      <div class="actions">
        <c-button
          v-if="showPagination"
          class="btn-more"
          size="lg"
          :disabled="loading"
          @click="paginateList"
        >
          Ver mais
        </c-button>
      </div>
    </template>

    <p
      class="empty"
      v-else-if="isSearchEmpty"
    >
      Unidades não encontradas. Procure através da cidade ou estado desejados.
    </p>

    <c-empty
      v-else-if="hasUnits === 0"
      class="empty"
      title="Unidades não encontradas."
      character="searching"
      message='Clique em "editar" para ativar ou adicionar novas unidades.'
      to="parceiros-slug-campanhas-criar"
      button-message="Nova oferta"
    />
  </div>
</template>

<script>
import CCard from '@/components/CComponents/CCard'

import { mapGetters, mapActions } from 'vuex'
import * as types from '@/store/types'
import Loadable from '@/modules/loadable'
import debounce from 'debounce'

const searchAddresses = debounce(function (filter, search) {
  this.filterAddresses({ ...filter, search })
}, 200)

export default {
  components: {
    UnitCard: () => import('@/components/Unit/UnitCard'),
    CCard
  },
  mixins: [ Loadable ],
  props: {
    filters: {
      type: Object,
      required: true
    },
    title: {
      type: String,
      default: 'Unidades participantes'
    },
    partnerHasUnits: Boolean
  },
  data () {
    return {
      loading: false,
      query: ''
    }
  },
  computed: {
    ...mapGetters({
      addresses: types.ADDRESS_PAGINATE
    }),
    search: {
      set (value) {
        this.loading = true
        this.query = value
        this.searchAddresses({ ...this.filters }, value)
        this.loading = false
      },
      get () {
        return this.query
      }
    },
    hasUnits () {
      return this.addresses.list && this.addresses.list.length
    },
    isSearchEmpty () {
      return this.search && !this.addresses.list.length
    },
    showPagination () {
      return this.addresses.list.length < this.addresses.total
    }
  },
  methods: {
    ...mapActions({
      paginateAddresses: types.ADDRESS_PAGINATE,
      filterAddresses: types.ADDRESS_FILTER
    }),
    searchAddresses,
    async paginateList () {
      this.loading = true
      const page = this.addresses.current_page + 1
      await this.paginateAddresses({
        ...this.filters,
        search: this.search,
        page
      })

      this.loading = false
    },
    async load () {
      await this.filterAddresses({ ...this.filters })
    }
  }
}
</script>

<style lang="scss">
@import '~@/styles/reference';

.unit-list-loading { text-align: center; }

.unit-list {
  margin-bottom: 20px;

  & > .unit-header {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;

    & > .title { flex-shrink: 0; }

    & > .action {

      &:not(:last-child) { margin-right: 10px;}

      &.search {
        width: 100%;
        max-width: 300px;
        margin-left: auto;

        & > .c-input > .input { border-radius: 25px; }
      }
    }

    @media screen and (max-width: 690px) {
      flex-wrap: wrap;

      & > .title {
        margin-right: auto;
      }

      & > .search {
        max-width: 100% !important;
        margin-right: 0 !important;
        margin-top: 10px;
        order: 2;
      }
    }
  }

  & > .list {
    margin-bottom: 20px;

    & > .unit {
      transition: transform .2s ease,
                  opacity   .3s ease;
    }
  }

  & > .actions {
    text-align: center;
    margin-top: 30px;
    margin-bottom: 20px;

    & > .btn-more {
      width: 100%;
      max-width: 190px;
    }
  }

  & > .empty {
    text-align: center;
    color: $failure-color;
  }
}

.unit-list-enter,
.unit-list-leave-to {
  opacity: 0;
  transform: translateY(100%);
}

.unit-list-leave-active {
  position: absolute;
}
</style>
