<template>
  <div class="card-container">
    <div class="up-container">
      <span class="card-title">
        <span class="title">{{ customization.title }}</span>
      </span>
      <div class="options">
        <div
          class="option"
          v-for="(item, index) in customization.itens"
          :key="index"
        >
          {{ item }}
        </div>
      </div>
    </div>
    <div class="down-container">
      <k-button
        class="button"
        icon="pen-edit5"
        @click.native.stop="$router.push('personalizacao/' + customization.route )"
        no-icon
      >
        Personalizar
        <k-icon
          class="button-icon"
          icon="pen-edit5"
          size="20"
        />
      </k-button>
    </div>
  </div>
</template>

<script>
import { KButton, KIcon } from 'kaledo-components'
export default {
  components: {
    KButton,
    KIcon
  },
  props: {
    customization: Object,
    cardIndex: Number
  }
}
</script>

<style lang='scss' scoped>
@import '~@/styles/reference';
.card-container {
  display: flex;
  flex-direction: column;
  padding: 24px;
  align-items: flex-start;
  width: 491px;
  height: 164px;
  border-radius: 8px;
  border: 1px solid $neutral-gray-100;
  background: $neutral-light-white;
  margin-bottom: 22px;

  & > .up-container {
    width: 100%;

    & > .card-title {
      display: flex;
      & > .title {
        color: $neutral-dark-blue;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: Nunito Sans;
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 120%;
      }
    }

    & > .options {
      & > .option {
        padding-top: 16px;
        color: $primary-neutral-2;
        font-feature-settings: 'clig' off, 'liga' off;
        font-family: Nunito Sans;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 140%;
      }
    }
  }

  & > .down-container {
    width: 111px;

    & > .button {
      width: 159px;
      height: 40px;
      color: $primary-neutral-2;
      border-radius: 32px;
      border: 1px solid $primary-neutral-2;
      background: #FFF;
      font-family: Nunito Sans;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 16px;
      text-transform: uppercase;
      display: flex;
      align-items: center;
      margin-top: 24px;
      transition: 0.1s;
      &:hover {
        border: 2px solid rgba($primary-color-placeholder, .8);
        color: $primary-color-placeholder;
      }
      .button-icon {
        margin-left: 8px;
      }
    }
  }
}
</style>
