<template>
  <div class="media-create-container">
    <div class="header">
      <c-title
        class="title"
        icon="plus-circle"
        size="2"
      >
        Nova mídia
      </c-title>

      <c-toggle
        :height="30"
        :width="90"
        :labels="{ checked: 'Ativo', unchecked: 'Inativo' }"
        :value="!!(mediaData || {}).status"
        @input="mediaData.status = $event"
      />
    </div>

    <media-form
      :media="mediaData"
      :status="mediaData.status"
    />
    <cannot-create-modal v-if="!canCreateBanners" />
  </div>
</template>

<script>
import MediaForm from '@/containers/Communication/Media/Components/Form.vue'
import CannotCreateModal from '@/containers/Communication/Media/Components/CannotCreateModal.vue'
import { CAN_CREATE_BANNERS } from '@/store/types'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'MediaCreateContainer',

  components: {
    MediaForm,
    CannotCreateModal
  },

  props: {
    media: Object
  },

  data () {
    return {
      mediaData: {
        status: 0
      }
    }
  },

  computed: {
    ...mapGetters({
      canCreateBanners: CAN_CREATE_BANNERS
    })
  },

  mounted () {
    this.calculateActiveBanners()
  },

  methods: {
    ...mapActions({
      calculateActiveBanners: CAN_CREATE_BANNERS
    })
  }
}
</script>

<style lang='scss' scoped>
@import '~@/styles/reference';

.media-create-container {
  max-width: 1020px;
  margin: 30px auto;

  @include mobile {
    margin: 12px 10px;
  }

  > .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 835px;
  }

  > .form {
    margin: 30px auto;
    max-width: 80%;

    > .c-input > .c-input-container {
      display: flex;
    }
  }
}
</style>
