<template>
  <div data-cy="email-example-card" class="email">
    <img :src="config.header_image" />
    <div class="text">
      <h1>Parabéns, você foi premiado!</h1>
      <h4>{{ config.body_text }}</h4>
      <h4>
        Para utilizar seu prêmio, ative sua conta seguindo os passos abaixo ou caso já possua um cadastro, acesse em
        <a
          href="https://vantagens.kaledo.com.br"
          target="_blank"
        >https://vantagens.kaledo.com.br</a> com seu CPF e senha.
      </h4>
    </div>
    <div class="steps">
      <div class="card">
        <div class="info">
          <span>
            <k-icon
              class="icon"
              icon="award-star"
              size="20"
            /><b>Prêmio</b>
          </span> <br />
          <span>
            <b>[valor]</b>
          </span>
        </div>
        <k-button
          type="send"
          class="button"
          :style="{ background: config.button_color }"
          icon-size="12"
        >
          Resgatar prêmio
        </k-button>
      </div>
      <div class="container">
        <div class="title">
          <span>Passo a passo para ativar sua conta</span>
        </div>
        <div class="step">
          <div class="number">
            1
          </div>
          Clique em ativar conta e insira seu CPF
        </div>
        <div class="step">
          <div class="number">
            2
          </div>
          Configure sua conta
        </div>
        <div class="step">
          <div class="number">
            3
          </div>
          Acesse a área do usuário para visualizar sua carteira
        </div>
        <div class="step">
          <div class="number">
            4
          </div>
          Seu saldo estará disponível para utilização
        </div>
      </div>
      <div class="footer">
        <k-button
          type="send"
          class="button"
          :style="{ background: config.button_color }"
          icon-size="12"
        >
          Ativar minha conta
        </k-button>
      </div>
    </div>
    <div class="footer-info">
      <k-icon
        class="icon"
        icon="question"
      />
      <a>Acesse o suporte em caso de Dúvidas</a>
    </div>
  </div>
</template>

<script>
import { KIcon, KButton } from 'kaledo-components'
export default {
  components: {
    KIcon,
    KButton
  },
  props: {
    config: Object
  }
}
</script>

<style lang='scss' scoped>
.email {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;
  gap: 40px;
  width: 600px;
  min-height: 949px;
  height: auto;
  background: #FFFFFF;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.16);
  .text {
    padding: 0 20px;
    & > h1 {
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 22px;
      color: #0A0D1A;
      padding-bottom: 23px;
    }
    & > h4 {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 31px;
      color: #0A0D1A;
      padding-bottom: 24px;
      & > a {
        font-weight: bolder;
        color: black;
      }
    }
  }
  & > .steps {
    & > .card {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 8px 24px;
      gap: 56px;
      width: 370.04px;
      height: 68px;
      border: 1px solid #202950;
      border-radius: 8px;

      .icon {
        margin-right: 5px;
      }

      .button {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 8px 16px;
        gap: 8px;
        height: 40px;
        background: #121E48;
        border-radius: 30px;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        text-align: center;
        text-transform: uppercase;
        color: #FFFFFF;
        justify-content: center;
        &:hover {
          cursor: default;
        }
      }
    }
    & > .container {
      padding-top: 65px;
      & > .title {
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 26px;
        color: #0A0D1A;
        padding-bottom: 24px;
      }
      .step {
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        color: #0A0D1A;
        flex: none;
        display: flex;
        align-items: center;
        padding-bottom: 24px;
        .number {
          height: 24px;
          width: 24px;
          background-color: #202950;
          border-radius: 50%;
          justify-content: center;
          display: flex;
          align-items: center;
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          line-height: 20px;
          color: #FDFDFD;
          margin-right: 8px;
        }
      }
    }
    .footer {
      width: 100%;
      display: flex;
      justify-content: center;
      .button {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 8px 16px;
        gap: 8px;
        height: 40px;
        width: 210px;
        background: #121E48;
        border-radius: 30px;
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        text-align: center;
        text-transform: uppercase;
        color: #FFFFFF;
        justify-content: center;
        border: 1px solid #5E6684;
        &:hover {
          cursor: default;
        }
      }
    }
  }
  .footer-info {
    padding-left: 25px;
    width: 100%;
    text-align: left;
    color: #565F7D;
    font-size: 12px;
    margin-bottom: 24px;

    .icon {
      margin-right: 5px;
    }

    a {
      text-decoration: underline;
    }
  }
}
</style>
