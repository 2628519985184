<template>
  <div
    class="coin flex"
    data-cy="coin">
    <img
    class="coin-logo"
    data-cy="coin-logo"
    :src="coin.program_image"
    :alt="`imagem de ${coin.program_name}`"
    />
    <span class="coin-name" data-cy="coin-name">
      {{coin.program_name}}
    </span>
    <button
      data-cy="coin-edit-button"
      class="coin-edit-button flex"
      @click="$emit('edit')"
    >
    <k-icon
      data-cy="coin-edit-icon"
      class="coin-edit-icon"
      icon="pen-edit17"
    />
    Editar moeda
    </button>
  </div>
</template>

<script>
import { KIcon } from 'kaledo-components'

export default {
  name: 'Coin',
  props: {
    coin: Object
  },
  components: {
    KIcon
  }
}
</script>

<style lang='scss' scoped>
@import '~@/styles/reference';
.flex {
  display: flex;
  align-items: center;
}
.coin {
  width: 366px;
  height: 64px;
  background: white;
  padding: 20px 16px;
  box-shadow: 0px 4px 8px rgba(94, 102, 132, 0.08);
  border-radius: 4px;
  @include mobile {
    width: 100%;
  }
  .coin-logo {
    width: 24px;
    height: 24px;
    object-fit: contain;
    margin-right: 8px;
    flex-shrink: 0;
  }
  .coin-name {
    font-weight: 600px;
    font-size: 18px;
    color: #202950;
    width: 100%;
  }
  .coin-edit-button {
    width: 150px;
    height: 32px;
    flex-shrink: 0;
    border: 1px solid #5E6684;
    border-radius: 20px;
    background: white;
    text-transform: uppercase;
    font-size: 12px;
    padding: 6px 16px;
    color: #5E6684;
    cursor: pointer;
    transition: all ease 0.3s;
    .coin-edit-icon {
      font-size: 20px;
      margin-right: 4px;
    }
    &:hover {
      background: #5E6684;
      color: white;
    }
  }
}
</style>
