<template>
  <div
    class="card"
    :class="selected ? '-selected' : ''"
    @click="$emit('click')"
  >
    <div class="header">
      <c-title class="title">
        {{ title }}
      </c-title>
      <question-button
        v-if="showModal"
        :modal-outside="true"
        @open-modal="$emit('open-modal')"
      />
    </div>
    <p class="text">
      {{ text }}
    </p>
  </div>
</template>

<script>
import QuestionButton from '@/components/CComponents/CQuestionButton.vue'
export default {
  components: {
    QuestionButton
  },
  props: {
    title: String,
    text: String,
    selected: Boolean,
    showModal: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang='scss' scoped>
@import '~@/styles/reference';

.card {
  background: white;
  padding: 8px 16px;
  width: 348px;
  height: 120px;
  border-radius: 4px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.16);
  border: 2px solid white;
  transition: all ease 0.3s;
  cursor: pointer;

  @include mobile {
    height: auto;
  }
  &:hover:not(.-selected){
    background: rgba(245, 245, 245, 1);
  }
  &.-selected {
    border-color:#202950;
    border-color: var(--color-secondary);
  }
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .title {
      .text {
        color: #5E6684;
        font-size: 16px;
      }
    }
  }
  .text {
    margin-top: 24px;
    color: #5E6684;
  }
}
.card + .card {
  margin-left: 24px;
}
</style>
