<template>
  <div class="orders">
    <step-count
      :current-step="step"
      :steps="steps"
      @step-click="updateStep('prev', $event)"
    />
    <div>
      <new-order-step-one
        v-if="step === 1"
        :step-value="stepsData.first"
        :campaigns="campaigns"
        v-model="stepsData.first"
      />
      <new-order-step-two
        v-if="step === 2"
        v-model="stepsData.second"
        :first-access="firstStepsAccess"
      />
      <new-order-step-three
        v-if="step === 3"
        :table-data="tableData"
        v-model="stepsData.third"
      />
      <new-order-step-four
        v-if="step === 4"
        :title="stepsData.first.title"
        :users="stepsData.third.users"
        :total="stepsData.third.value"
        :loading="loadingOrderCreation"
        :step-value="stepsData.fourth"
        v-model="stepsData.fourth"
        @create-order="createOrder"
        @cancel="openCancelModal = true"
      />
    </div>
    <div
      class="footer"
      v-if="step !== 4"
    >
      <c-button
        data-cy="cancel-order-button"
        class="button cancel-button"
        @click="openCancelModal = true"
      >
        Cancelar
      </c-button>
      <c-button
        data-cy="next-step-button"
        primary
        class="button"
        icon="chevron-right"
        :disabled="!enableNextStep"
        @click="updateStep('next')"
      >
        continuar
      </c-button>
    </div>

    <c-confirm-modal
      :btn-props="{ primary: true }"
      confirm-text="Cancelar pedido"
      character="embarrassed"
      title="Tem certeza que deseja cancelar seu pedido?"
      message="Esse pedido de premiação não será processado"
      :is-opened="openCancelModal"
      @close="openCancelModal = false"
      @confirm="confirmCancel"
    />
  </div>
</template>

<script>
import { COMPANY, AWARDS_CREATE_ORDER, FEEDBACK, AWARD_STEP, COMPANY_BALANCE } from '@/store/types'
import { mapGetters, mapMutations, mapActions } from 'vuex'
import StepCount from '@/components/General/StepCount'
import NewOrderStepOne from './NewSteps/NewOrderStepOne'
import NewOrderStepTwo from './NewSteps/NewOrderStepTwo'
import NewOrderStepThree from './NewSteps/NewOrderStepThree'
import NewOrderStepFour from './NewSteps/NewOrderStepFour'
import CConfirmModal from '@/components/CComponents/CConfirmModal'

export default {
  components: {
    StepCount,
    NewOrderStepOne,
    NewOrderStepTwo,
    NewOrderStepThree,
    NewOrderStepFour,
    CConfirmModal
  },
  props: {
    campaigns: Array
  },
  data () {
    return {
      loadingOrderCreation: false,
      openCancelModal: false,
      currentStepIsComplited: false,
      firstStepsAccess: true,
      steps: [
        {
          number: 1,
          text: 'Campanha'
        },
        {
          number: 2,
          text: 'Usuários'
        },
        {
          number: 3,
          text: 'Valor do Prêmio'
        },
        {
          number: 4,
          text: 'Conclusão'
        }
      ],
      stepsData: {
        first: {
          title: '',
          award_campaign_id: null
        },
        second: {
          uploaded_users: []
        },
        third: {
          value: null,
          users: []
        },
        fourth: {
          release_date: null,
          use_balance: false
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      company: COMPANY,
      step: AWARD_STEP
    }),
    enableNextStep () {
      let enable = true
      Object.keys(this.stepsData).forEach((key, index) => {
        if (this.step === (index + 1)) { enable = this.insideObject(key) }
      })
      return enable
    },
    cancelButtonText () {
      if (this.step === 1) return 'cancelar'
      else return 'voltar'
    },
    tableData () {
      return {
        cols: [
          {
            align: 'center',
            label: 'cpf'
          },
          {
            align: 'center',
            label: 'email'
          },
          {
            align: 'center',
            label: 'valor do prêmio'
          }
        ],
        rows: this.stepsData.second.uploaded_users
      }
    },
    formatedThirdStepData () {
      const formatedUsers = !this.stepsData.third.users
        ? []
        : this.stepsData.third.users.map(function (user) {
          return {
            document: user.document,
            email: user.email,
            value: user.value * 100
          }
        })
      return {
        value: this.stepsData.third.value * 100,
        users: formatedUsers
      }
    }
  },
  watch: {
    'stepsData.second' () {
      if (this.stepsData.third && this.stepsData.third.users && this.stepsData.third.users.length > 0) {
        const updatedUsers = []
        this.stepsData.third.users.forEach(thirdStepUser => {
          const findedUser = this.stepsData.second.uploaded_users.find(secondStepUser => secondStepUser.id === thirdStepUser.id)
          if (findedUser) { updatedUsers.push(findedUser) }
        })
        this.stepsData.second.uploaded_users.forEach(secondStepUser => {
          const findedUser = this.stepsData.third.users.find(thirdStepUser => thirdStepUser.id === secondStepUser.id)
          if (typeof findedUser === 'undefined') { updatedUsers.push(secondStepUser) }
        })

        this.stepsData.third.users = updatedUsers
        this.stepsData.third.value = this.sumAllKeyValues('value', updatedUsers)
      }
    },
    'stepsData.third' () {
      this.stepsData.second.uploaded_users =
        this.stepsData.third.users
          ? this.stepsData.third.users.map(user => {
            return {
              ...user,
              value: user.value ? user.value : 0
            }
          })
          : this.stepsData.second.uploaded_users
    }
  },
  async created () {
    await this.getBalance()
    this.updateAwardStep(1)
  },
  methods: {
    ...mapMutations({
      updateAwardStep: AWARD_STEP
    }),
    ...mapActions({
      createAward: AWARDS_CREATE_ORDER,
      getCompany: COMPANY,
      feedback: FEEDBACK,
      getBalance: COMPANY_BALANCE
    }),
    handleAnalytics (event, data) {
      window.dataLayer.push(
        {
          event,
          ...data
        }
      )
    },
    sumAllKeyValues (key, objArray) {
      if (typeof objArray === 'undefined') return null

      let sum = 0
      objArray.forEach(obj => {
        if (obj.hasOwnProperty(key)) {
          sum += obj.value
        }
      })
      return sum
    },
    confirmCancel () {
      this.handleAnalytics('premios-pedido-abandonar', {
          company_name: this.company.slug
        })
      this.feedback({
        type: 'warning',
        title: 'Pedido cancelado'
      })
      this.$router.push({ name: 'premiacoes' })
    },
    insideObject (obj) {
      const { [obj]: stepsDataObj } = this.stepsData

      const hasValidValue = value => !(value === 0 || value === '' || value === null)

      for (const key in stepsDataObj) {
        const value = stepsDataObj[key]

        if (!hasValidValue(value)) {
          return false
        }

        if (Array.isArray(value)) {
          if (value.length === 0) {
            return false
          }
          if (obj === 'third' && value.some(object => object.value === 0 || !object.hasOwnProperty('value'))) {
            return false
          }
        }
      }

      return true
    },
    updateStep (func, stepNumber) {
      if (func === 'next') {
        if (this.step !== 4) {
          this.handleAnalytics(`premios-pedido-passo${this.step}`, {
            company_name: this.company.slug
          })
          this.updateAwardStep(this.step + 1)
        } else { this.createOrder() }
      } else {
        if (this.step !== 1) {
          this.firstStepsAccess = false
          this.updateAwardStep(stepNumber)
        }
      }
    },
    async createOrder () {
      this.loadingOrderCreation = true
      let finalOrder = {
        ...this.stepsData.first,
        ...this.formatedThirdStepData,
        ...this.stepsData.fourth
      }
      if (!finalOrder.use_balance) delete finalOrder.use_balance
      const success = await this.createAward(finalOrder)
      if (success) {
        this.handleAnalytics('premios-pedido-finalizar', { company_name: this.company.slug })
        this.$router.push({ name: 'premiacoes' })
        await this.getCompany()
      } else {
        this.feedback({
          type: 'error',
          title: 'Houve um erro ao processar o seu pedido',
          text: 'Por favor, tente novamente mais tarde'
        })
      }
      this.loadingOrderCreation = false
    }
  }
}
</script>

<style lang="scss">
@import '~@/styles/reference';

.orders {
  max-width: 1020px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;

  & >.footer {
    width: 100%;
    justify-content: flex-end;
    display: flex;
    padding-top: 25px;

    & >.button {
      margin: 0 5px;
      width: 180px;
      height: 40px;
      border-radius: 30px;

      &.cancel-button {
        background: #FFFFFF;
        border: 1px solid var(--color-secondary);
      }
    }
  }
}
</style>
