<template>
  <div class="email-confirmation-message">
    <div class="content">
      <h1 class="title">
        Confirme seu email
      </h1>
      <p class="message">
        Para finalizar seu cadastro, confirme seu email clicando no botão da mensagem que te enviamos.
      </p>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
@import '~@/styles/reference.scss';
.email-confirmation-message {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;
  height: 100%;
  gap: 24px;

  @include tablet {
    padding: 40px 24px;
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 536px;
    margin-top: 155px;
    gap: 16px;

    @include tablet {
      margin-top: 130px;
      max-width: 640px;
    }
    @include mobile {
      margin-top: 100px;
    }
  }

  .title {
    color: #202950;
    text-align: center;
    font-size: 56px;
    font-weight: 800;
    line-height: normal;
    letter-spacing: -0.328px;

    @include tablet {
      font-size: 40px;
    }
    @include mobile {
      font-size: 40px;
    }
  }
  .message {
    color: #202950;
    text-align: center;
    font-size: 20px;
    font-weight: 400;
    line-height: 160%;
    letter-spacing: -0.122px;
    opacity: 0.7;

    @include tablet {
      font-size: 16px;
    }
    @include mobile {
      font-size: 16px;
    }
  }
}
</style>
