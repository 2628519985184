<template>
  <div class="partners-header">
    <div class="content">
      <div class="header-nav">
        <c-toggler
          class="toggler"
          :tabs="navTabs"
          :active-tab="$route.name"
          @change-tab="changeTab"
        />
      </div>
      <div class="header-actions">
        <c-button
          v-if="isDesktop && showButton"
          @click="pushRoute()"
          icon-size="sm"
          icon="plus"
          size="lg"
          primary
        >
          {{ button.name }}
        </c-button>
        <slot />
      </div>
    </div>
  </div>
</template>

<script>

import CToggler from '@/components/CComponents/CToggler'

export default {
  components: { CToggler },
  props: {
    showButton: {
      type: Boolean,
      required: true
    },
    navTabs: Array,
    button: Object
  },
  methods: {
    changeTab (name) {
      if (this.$route.name === name) return
      this.$router.push({ name })
    },
    pushRoute () {
      this.$emit('click')
      this.$router.push({ name: this.button.routeName })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/styles/reference';

.partners-header {
  box-shadow: 0px 4px 1px -1px rgba(213, 214, 226, 0.4);
  background-color: #FFF;

  & > .content {
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include desktop { @include container }

    & > .header-nav {
      width: 100%;
      @include desktop { width: 20%; }
    }
    & > .header-actions {
      display: flex;
    }
  }
}
</style>
