<template>
  <notification-details-container />
</template>

<script>
import NotificationDetailsContainer from '~/containers/Communication/Notifications/NotificationDetailsContainer'
export default {
  components: { NotificationDetailsContainer }
}
</script>

<style>
</style>
