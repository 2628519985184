<template>
  <c-input-container
    v-bind="$attrs"
    :validation="validation"
    :class="['c-input', { '-has-icon': !!icon }]"
  >
    <c-icon
      v-if="icon"
      :icon="icon"
      height="20"
      width="20"
      class="icon"
    />

    <input
      v-bind="$attrs"
      :class="classes"
      :placeholder="placeholder"
      v-mask="mask"
      v-model="internalValue"
    />
  </c-input-container>
</template>

<script>
import { mask } from 'vue-the-mask'

export default {
  components: {
    CInputContainer: () => import('./CInputContainer')
  },
  directives: {
    mask (el, binding) {
      if (!binding.value || !binding.value.length) {
        return
      }
      return mask(el, binding)
    }
  },
  props: {
    icon: String,
    placeholder: String,
    mask: [String, Array],
    value: String,
    validation: String
  },
  data () {
    return {
      internalValue: this.value
    }
  },
  computed: {
    classes () {
      const classes = [
        'input', {
          '-has-validation': this.validation || this.success,
          '-error': this.disabled
        }
      ]
      return classes
    }
  },
  watch: {
    internalValue (v) {
      this.$emit('input', v)
    }
  }
}
</script>

<style lang="scss" scoped>
$c-input-disabled-background-color: #eaedef !default;
$c-input-disabled-color: #bdc0d1 !default;

.c-input {
  & > .input {
    @extend %c-input-input;
  }

  &.-has-icon {
    position: relative;
    padding: 0;
    padding-bottom: 20px;

    & > .icon {
      position: absolute;
      top: 10px;
      left: 10px;
      fill: rgba(18, 30, 72, 0.5);
    }

    & > .input {
      height: 20px;
      margin: 0;
      padding: 0;
      padding-left: 40px;
    }
  }
}

%c-input-input {
  background-color: white;
  color: rgba(18, 30, 72, 0.8);
  border-radius: 2px;
  font-size: 16px;
  display: inline-block;
  width: 100%;
  box-shadow: none;
  border: 1px solid rgba(18, 30, 72, 0.1);
  padding: 5px;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
  will-change: border-color, box-shadow;
  cursor: pointer;

  &::placeholder {
    font-size: 14px;
    color: rgba(18, 30, 72, 0.5);
    font-weight: 400;
  }

  &:not(.-textarea) {
    height: 40px;
  }

  &.-has-validation,
  .-error {
    box-shadow: none;
    outline: none;
    outline-offset: 0;
    border-color: rgba(18, 30, 72, 0.5);
  }

  &.-round {
    border-radius: 20px;
  }

  &.-disabled {
    background-color: $c-input-disabled-background-color;
    border-color: $c-input-disabled-background-color;

    color: $c-input-disabled-color;
    font-weight: 300;
    pointer-events: none;

    &:hover {
      cursor: not-allowed;
      pointer-events: all;
    }
  }

  outline: none;
}
</style>
