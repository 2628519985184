<template>
  <div
    class="card-item"
    :class="mobile ? '-mobile' : ''"
  >
    <div class="tag-container">
      <tag-preview :fill="data.primary" />
      <span class="tag-text">
        50% OFF
      </span>
    </div>

    <div class="image-container">
      <img
        class="image"
        src="@/assets/img/preview-image.png"
        alt="preview"
      />
    </div>
  </div>
</template>

<script>
import TagPreview from './TagPreview.vue'
export default {
  name: 'CardItem',
  components: { TagPreview },
  props: {
    data: {
      type: Object,
      default: () => {}
    },
    mobile: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/styles/reference';
.card-item {
  width: 157px;
  height: 157px;
  min-width: 157px;
  box-shadow: 0px 4px 8px 0px #5E668414;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #FDFDFD;
  border-radius: 4px;
  &.-mobile {
    width: 200px;
    height: 200px;
    min-width: 200px;
  }
  .tag-container {
    position: relative;
    .tag-text {
      position: absolute;
      left: 35px;
      top: 5px;
      color: white;
      font-size: 12px;
      font-weight: 700;
    }
  }
  .image-container {
    margin-top: 24px;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background: #F7F8FC;
    .image {
      width: 80px;
      height: 80px;
    }
  }

}
.card-item + .card-item {
  margin-left: 10px;
}
</style>
