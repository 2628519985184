<template>
  <div class="c-empty">
    <c-character
      :type="character"
      class="character"
    />

    <h4 class="title">
      {{ title }}
    </h4>

    <p
      class="message"
      v-html="message"
    />

    <c-button
      v-if="btnActive"
      class="button"
      size="lg"
      :icon-size="btnIconSize"
      primary
      :icon="btnIcon"
      :to="{ name: to, params: params }"
    >
      {{ buttonMessage }}
    </c-button>
  </div>
</template>

<script>
import CCharacter from '@/components/CComponents/CCharacter'
export default {
  components: {
    CCharacter
  },
  props: {
    title: {
      type: String,
      required: true
    },
    btnActive: {
      type: Boolean,
      default: false
    },
    to: {
      type: String,
      default: 'index'
    },
    buttonMessage: String,
    btnIcon: {
      type: String,
      default: 'chevron-right'
    },
    btnIconSize: {
      type: String,
      default: 'md'
    },
    message: {
      type: String,
      required: true
    },
    character: {
      type: String,
      required: true
    },
    params: {
      type: Object,
      default: () => ({})
    }
  }
}
</script>

<style lang="scss">
@import '~@/styles/reference';

.c-empty {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  padding: 50px 0;

  & > .character {
    width: 130px;
    height: 130px;
  }

  $empty-title-font-size: 14px;
  $empty-title-font-line: 18px;
  $empty-title-font-weight: 500;
  $empty-title-font-family: $title-font-family;
  $empty-title-font-color: $title-color;

  & > .title {
    margin-top: 30px;
    margin-left: 55px;
    margin-right: 55px;
    margin-bottom: 0;
    line-height: $empty-title-font-line;
    font-size: $empty-title-font-size;
    font-weight: $empty-title-font-weight;
    font-family: $empty-title-font-family;
  }

  & > .title,
  & > .message > strong {
    color: $empty-title-font-color;
  }

  & > .message {
    margin-top: 12px;
    margin-left: 30px;
    margin-right: 30px;
    margin-bottom: 0;
  }

  & > .button {
    margin-top: 45px;
    margin-left: auto;
    margin-right: auto;
  }
}
</style>
