<template>
  <personalization/>
</template>

<script>
import Personalization from '@/containers/Engagement/pages/Personalization.vue'

export default {
  name: 'index',
  components: {
    Personalization
  }
}
</script>

<style lang='scss' scoped>
</style>
