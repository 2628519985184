<template>
  <initial-card
    @submit="dates => handleSubmit(dates)"
    :loading="loading"
    :error="error"
    :initial-date="dashDates"
    :graph-data="activeAndEnabledUsersData"
    :format-tab-label="true"
  />
</template>

<script>
import InitialCard from '@/components/Dashboard/cards/InitialCard.vue'
import { mapGetters, mapActions } from 'vuex'
import { DASH_ACTIVE_ENABLED_USERS, DASH_DATES } from '@/store/types'
export default {
  name: 'UserActivation',
  components: {
    InitialCard
  },
  props: {
    reloadDataQuery: Boolean
  },
  data () {
    return {
      error: false,
      loading: false
    }
  },
  computed: {
    ...mapGetters({
      activeAndEnabledUsersData: DASH_ACTIVE_ENABLED_USERS,
      dashDates: DASH_DATES
    })
  },
  watch: {
    reloadDataQuery () {
      if (this.reloadDataQuery) { this.getUsersActivated() }
    }
  },
  mounted () {
    this.getUsersActivated()
  },
  methods: {
    ...mapActions({
      getActiveAndEnabledUsersData: DASH_ACTIVE_ENABLED_USERS
    }),
    handleSubmit (dates) {
      this.$emit('submit', dates)
    },
    getUsersActivated () {
      this.loading = true
      this.getActiveAndEnabledUsersData().then((success) => {
        this.error = !success
        this.loading = false
      })
    }
  }
}
</script>

<style lang='scss' scoped>
@import '~@/styles/reference.scss';
</style>
