<template>
  <div
    v-if="type === 1"
    class="warn-container"
  >
    <div class="title">
      <k-icon
        class="icon"
        icon="warning2"
        size="20"
      />Essa informação não poderá ser alterada
    </div>
    <div class="confirm">
      <input
        data-cy="warn-checkbox"
        v-model="check"
        class="checkbox"
        type="checkbox"
        checked="checked"
        @change=" $emit('input', check)"
      />Estou ciente e tenho certeza das informações inseridas
    </div>
  </div>
  <div
    v-else
    class="warn-container-support"
  >
    <k-icon
      class="icon"
      icon="warning2"
      size="20"
    />Para realizar alterações, <a
      class="link"
      href="https://kaledo.zendesk.com"
      target="_blank"
    >entre em contato com o nosso suporte</a>
  </div>
</template>

<script>
import { KIcon } from 'kaledo-components'

export default {

  components: {
    KIcon
  },

  props: {
    type: {
      type: [Number, Boolean],
      default: 1
    }
  },
  data () {
    return {
      check: false
    }
  }
}
</script>

<style lang='scss' scoped>
@import '~@/styles/reference';
.warn-container {
  display: flex;
  flex-direction: column;
  width: 335px;
  height: 68px;
  padding: 8px;
  align-items: flex-start;
  gap: 8px;
  border-radius: 4px;
  border: 1px solid $feedback-warning-100;
  color: $neutral-dark-blue;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Nunito Sans;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
  & > .title {
    display: flex;
    align-items: center;
      & > .icon {
        margin-right: 8px;
        color: $feedback-warning-100;
      }
  }
  & > .confirm {
    display: flex;
    align-items: center;
    color: $neutral-gray-200;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Nunito Sans;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    letter-spacing: -0.122px;

    & > .checkbox {
      margin-right: 8px;

      .check-icon {
        border-color: red;
      }
    }
  }
}
.warn-container-support {
  display: flex;
  width: 510px;
  height: 38px;
  padding: 8px;
  align-items: center;
  gap: 8px;
  border-radius: 4px;
  border: 1px solid $feedback-warning-100;
  color: $neutral-dark-blue;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: Nunito Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
  & > .icon {
    color: $feedback-warning-100;
  }
  & > .link {
    color: $admin-alert-blue;
    text-align: center;
    font-feature-settings: 'clig' off, 'liga' off;
    font-family: Nunito Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 16.8px; /* 105% */
    text-decoration-line: underline;
    &:hover {
      cursor: pointer;
    }
  }
}
</style>
