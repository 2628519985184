<template>
  <div class="carrousel-desktop">
    <c-title
      use-k-icon
      :icon="icon"
      class="carrousel-title"
      preview-mode
      :border-color="data.secondary"
    >
      {{ title }}
    </c-title>
    <div class="cards-container">
      <card-item
        v-for="index in cardItems"
        :key="`card-item-${index}`"
        :data="data"
      />
      <div class="next-button">
        <k-icon
          icon="arrow-right"
          size="24px"
          class="next-button-icon"
        />
      </div>
    </div>
  </div>
</template>

<script>
import CardItem from './CardItem.vue'
import { KIcon } from 'kaledo-components'

export default {
  name: 'CarrouselDesktop',
  components: {
    CardItem,
    KIcon
  },
  props: {
    data: {
      type: Object,
      default: () => {}
    },
    title: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      cardItems: 6
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/styles/reference';
.carrousel-desktop {
  margin-bottom: 55px;
  .carrousel-title {
    margin-bottom: 24px;
  }
  .cards-container {
    max-width: 872px;
    overflow: hidden;
    position: relative;
    display: flex;
    .next-button {
      width: 62px;
      height: 60px;
      background-color: white;
      position: absolute;
      right: -30px;
      top: 30%;
      box-shadow: 1px 1px 4px 0px #00000040;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      .next-button-icon {
        color: #5E6684;
        padding-right: 15px;
      }
    }
  }
}
</style>
