<template>
  <div class="reports-content">
    <!-- <c-title
      icon="file-text"
      class="title"
    >
      Relatórios
    </c-title> -->
    <div class="list-group">
      <reports-card
        v-for="report in reports"
        :key="report.name"
        :name="report.name"
        :text="report.text"
        :url="report.url"
        :period="report.period"
        @click.native="openReport(report)"
      />
    </div>

    <report-modal
      v-bind="$attrs"
      v-on="$listeners"
      v-if="!!openedReport"
      :is-opened="!!openedReport"
      :is-periodic="openedReport.period"
      @submit="submitReport"
      @close="openedReport = null && $emit('close')"
    />
  </div>
</template>

<script>
import ReportsCard from '@/components/Reports/ReportsCard'
import ReportModal from '@/components/Reports/ReportModal'

import { mapGetters } from 'vuex'
import * as types from '@/store/types'

export default {
  components: {
    ReportsCard,
    ReportModal
  },
  props: {
    reports: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      openedReport: null
    }
  },
  computed: {
    ...mapGetters({company: types.COMPANY})
  },
  methods: {
    submitReport (period) {
      this.$emit('submit-report', {
        ...this.openedReport,
        period
      })
      this.$emit('close')
      let data = {
        company_name: this.company.name,
        report_name: this.openedReport.name
      }
      if (this.openedReport.period) data.report_period = period
      this.handleAnalytics('botao-enviar-relatorio', data)
      this.openedReport = null
    },

    openReport (report) {
      this.openedReport = report
      this.handleAnalytics('visualizar-relatorio', {
        company_name: this.company.name,
        report_name: report.name
      })
    },

    handleAnalytics (event, data) {
      window.dataLayer.push(
        {
          event: event,
          ...data
        }
      )
    }
  }
}
</script>

<style lang="scss">
  @import '~@/styles/reference';

  .reports-content {
    margin-top: 35px;
    padding: 0 10px;

    @include desktop { @include container; }

    & > .title {
      margin-bottom: 20px;
    }

    & > .list-group {
      display: flex;
      flex-wrap: wrap;
      margin-left: -5px;
    }
  }
</style>
