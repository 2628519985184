<template>
  <change-account-container />
</template>

<script>
import ChangeAccountContainer from '@/containers/ChangeAccount/ChangeAccountContainer'

export default {
  components: { ChangeAccountContainer }
}
</script>
