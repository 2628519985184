<template>
  <c-input-container
    v-bind="$attrs"
    :class="classes"
    @click.native="$slots.default ? $emit(disabled ? '' : 'input', !value) : undefined"
  >
    <label
      class="checkbox"
      :class="{ '-active': value, 'check-icon': mode === 'check-icon', '-disabled': disabled }"
    >
      <input
        v-show="false"
        v-model="internalValue"
        type="checkbox"
      />
      <k-icon
        v-if="mode === 'check-icon' && value"
        icon="done-check3"
        class="icon-checkbox"
      />
    </label>

    <div
      class="text"
      v-if="$slots.default"
    >
      <slot />
    </div>
  </c-input-container>
</template>

<script>
import { KIcon } from 'kaledo-components'
import CInputContainer from '@/components/CComponents/CInputContainer'

export default {
  components: { CInputContainer, KIcon },
  props: {
    value: {
      type: Boolean,
      required: false
    },
    round: {
      type: Boolean,
      required: false
    },
    mode: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      internalValue: this.value
    }
  },
  computed: {
    classes () {
      return ['c-checkbox', {
        '-round': this.round,
        '-has-text': this.$slots.default
      }]
    }
  },
  watch: {
    internalValue (v) {
      this.$emit('input', v)
    }
  }
}
</script>

<style lang="scss">
@import '~@/styles/reference';

$c-checkbox-size: 20px;

$c-checkbox-border-color: #E9EAEE;
$c-checkbox-wrapper-bg: #FDFDFD;

.c-checkbox {
  display: flex;
  flex-flow: row wrap;

  &.-round > .checkbox {
    border-radius: 50%;
    width: 24px;
    height: 24px;
    flex: 24px 0 0;

    border-color: $c-checkbox-border-color;

    &:before { border-radius: 50%; }
    &.-active:before {
      width: 16px !important;
      height: 16px !important;
    }
    &.check-icon {
      position: relative;
      border-color: #8C92B1;
      &:before {
        background: white;
      }
      &.-active:before {
        width: 23px !important;
        height: 23px !important;
        background-color: #14CCFF;
      }
    }
    .icon-checkbox {
      color: white;
      font-size: 20px;
      left: 1px;
      position: absolute;
      z-index: 3;
    }
  }

  &.-has-text {
    border-radius: 30px;
    border: 1px solid $c-checkbox-border-color;
    background-color: $c-checkbox-wrapper-bg;
    padding: 8px;
    height: 40px;

    cursor: pointer;

    & > .text {
      display: flex;
      align-items: center;
      justify-content: center;

      font-size: 12px;
      color: $text-color;
      text-transform: uppercase;

      margin: 0 25px 0 15px;
      user-select: none;
    }
  }
  & > .-disabled:before {
        background-color: #D8D8D8 !important;
      }
  & > .checkbox {
    height: $c-checkbox-size;
    width: $c-checkbox-size;
    flex: $c-checkbox-size 0 0;
    border: 1px solid $border-color;
    border-radius: 2px;
    position: relative;
    background-color: white;
    display: inline-block;

    &:before {
      content: '';
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      background-color: $secondary-color-placeholder;
      background-color: var(--color-secondary);
      height: 0px;
      width: 0px;
      border-radius: 2px;
      transition: height .15s ease,
        width .15s ease;
    }

    &.-active:before {
      height: 12px;
      width: 12px;
    }
    &.check-icon {
      position: relative;
      border-color: #8C92B1;
      &:before {
        background: white;
      }
      &.-active:before {
        width: 23px !important;
        height: 23px !important;
        background-color: #14CCFF;
      }
    }
    .icon-checkbox {
      color: white;
      font-size: 20px;
      left: 0px;
      position: absolute;
      z-index: 3;
    }
    &.-check-icon::before {
      background-color: red;
    }
  }

  & > .text {
    font-size: 12px;
    margin-left: 20px;
    color: $text-color;
    flex: 1 1 0;
  }
}
</style>
