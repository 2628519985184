<template>
  <div>
    <c-header
      v-if="$can('dashboard')"
      :show-button="false"
      :nav-tabs="navTabs"
    />
    <reports-container />
  </div>
</template>

<script>
import ReportsContainer from '@/containers/Reports/ReportsContainer'
import CHeader from '@/components/CComponents/CHeader.vue'

export default {
  components: { CHeader, ReportsContainer },
  computed: {
    navTabs () {
      return [
        {
          name: 'Dashboard',
          value: 'relatorios'
        },
        {
          name: 'Relatorios',
          value: 'relatorios-relatorios'
        }
      ]
    }
  }
}
</script>
