<template>
  <div :class="[ 'c-input-container', { '-has-error': !!validation } ]">
    <label
      :class="['label', {'-is-dashed' : isDashedInput}]"
      v-if="label"
    >
      {{ label }}
      <span
        class="required"
        v-if="required"
      >
        *
      </span>
    </label>

    <slot />

    <p
      class="validation"
      v-if="validationMessage"
    >
      <c-icon
        size="15"
        icon="warning-circle"
        class="icon"
      />
      <span class="text">{{ validationMessage }}</span>
    </p>
  </div>
</template>

<script>

export default {
  props: {
    label: String,
    required: Boolean,
    validation: {
      type: [String, Boolean],
      required: false
    },
    isDashedInput: Boolean
  },

  computed: {
    validationMessage () {
      if (typeof this.validation === 'string') return this.validation
      else return false
    }
  }
}
</script>

<style lang="scss">
@import '~@/styles/reference';

$c-input-error-color: #ff7986 !default;

.c-input-container {
  &.-flex {
    display: flex;
    align-items: center;
    & > .label {
      flex: none;
      margin-right: 10px;
    }
  }
  & > .label,
  & > .validation {
    flex: 100%;
    display: block;
  }

  & > .label {
    color: $title-color;
    text-transform: uppercase;
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 9px;

    &.-is-dashed {
      margin-bottom: 0;
    }
  }

  & > .validation {
    display: flex;
    padding-top: 5px;
    align-items: center;
    color: $c-input-error-color;
    position: absolute;

    & > .icon { fill: #ff7987; }
    & > .text {
      font-size: 12px;
      padding-left: 5px;
      white-space: nowrap;
    }
  }
}
</style>
