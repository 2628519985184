<template>
  <div
    data-cy="campaign-details-card"
    class="data"
  >
    <h1>Nome: </h1>
    <h3>{{ title }}</h3>
    <h1>Objetivo: </h1>
    <h4>{{ reason }} </h4>
    <h1>Descrição: </h1>
    <h4>{{ description }} </h4>
  </div>
</template>
<script>
export default {
  props: {
    title: String,
    reason: String,
    description: String
  }
}
</script>

<style lang="scss" scoped>
.data {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 24px 16px;
  gap: 24px;
  width: 100%;
  min-height: 316px;
  height: 100%;
  background: #FDFDFD;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.16);
  border-radius: 8px;
  & > h1 {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-transform: uppercase;
    color: #5E6684;
  }
  & > h3 {
    font-family: 'Nunito Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 26px;
    color: #202950;
  }
  & > h4 {
    font-style: normal;
    font-size: 16px;
    line-height: 20px;
    color: #202950;
  }
}
</style>
