<template>
  <c-spinner
    v-if="isLoading"
    size="md"
  />
  <div
    v-else
    class="campaign-information-container"
    data-cy="campaign-information-container"
  >
    <c-title
      class="title"
      icon="pencil"
      size="3"
    >
      {{ hasCampaign ? 'Editar oferta' : 'Criar nova oferta' }}
    </c-title>

    <campaign-types-rescue
      :new-campaign="newCampaign"
      :rescues="rescues"
      :rescue="rescue"
      @input="v => rescue = v"
    />

    <div class="information-container">
      <c-card class="form-important">
        <div class="form-container">
          <div class="tag">
            <campaign-stamp
              v-if="newCampaign.symbol"
              :large="isDesktop"
              :symbol="newCampaign.symbol"
              :value="newCampaign.value"
            />
          </div>

          <div class="form">
            <campaign-form
              :campaign="newCampaign"
              :rescues="rescues"
              @update="synchronize"
            />
          </div>
        </div>

        <div class="important-container">
          <c-title
            icon="warning-circle"
            size="3"
          >
            Informações importantes
          </c-title>

          <campaign-information-important
            class="input-important"
            :parse-important="parseImportant"
            @update="synchronize"
          />

          <div class="options">
            <span
              class="help-formatation"
              ref="popoverTrigger"
              @click="showPopover = !showPopover"
            >Ajuda para formatação</span>
            <span
              v-if="newCampaign.important"
              class="input-length"
            >{{ newCampaign.important.length + ' CARACTERES' }}</span>
          </div>
        </div>

        <campaign-mark-down
          :show-popover="showPopover"
          :target="$refs['popoverTrigger']"
          @blur="showPopover = false"
        />
      </c-card>

      <c-card data-cy="how-to-use-card" class="how-to-use">
        <c-title
          class="title"
          icon="smiley"
          size="3"
        >
          Como utilizar
        </c-title>

        <div class="number-steps">
          <span class="label-steps">Nº DE PASSOS</span>
          <c-select
            class="options"
            :items="steps"
            :value="step"
            @input="v => step = v"
            track-by="value"
            display-by="value"
          />
        </div>

        <div class="steps">
          <template v-for="(_step, index) in stepHandler">
            <campaign-information-step
              :key="index"
              :step="hasCampaign ? _step : ''"
              :event="rescue"
              :number="index + 1"
              :last="index + 1 === (hasCampaign ? newCampaign.howtouse.length : step)"
              :voucher="newCampaign.voucher ? newCampaign.voucher : ''"
              is-text-area
              @voucher="synchronize"
              @update="updateStep"
              v-on="$listeners"
            />
          </template>
        </div>

        <campaign-limit-codes
          v-if="rescue === 'uniqueCode' || rescue == 'payment'"
          :rescue="rescue"
          :limit="newCampaign.eventData.limit"
          :three-steps="newCampaign.howtouse.length === 3"
          @update="synchronize"
        />

        <campaign-link
          v-if="rescue === 'online'"
          class="campaign-link"
          :rescue="rescue"
          :onlinelink="newCampaign.eventData.online_link"
          :two-steps="newCampaign.howtouse.length === 2"
          @update="synchronize"
        />
      </c-card>
    </div>

    <campaign-upload-codes
      :rescue="rescue"
      @change="$event && $event[0] ? file = $event[0].file : file = ''"
    />

    <unified-registration
      v-if="showUnifiedRegistration"
      class="unified-component"
      :companies="companies"
      :current-company="currentCompany"
      :unique-code="rescue === 'uniqueCode'"
      @selected-companies="ev => selectedCompanies = ev"
    />

    <campaign-actions
      :is-processing="isProcessing"
      :is-valid="isValid"
      @setCampaign="saveAction()"
    />

    <confirm-unified-registration
      :is-opened="openConfirmModal"
      @accept-confirm-unified="submitAndClose()"
      @close-confirm-unified="openConfirmModal = false"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import * as types from '@/store/types'

import CCard from '@/components/CComponents/CCard'
import CampaignStamp from '@/components/Campaign/Listing/CampaignStamp'
import CampaignTypesRescue from '@/components/Campaign/Form/CampaignTypesRescue'
import CampaignInformationImportant from '@/components/Campaign/Form/CampaignInformationImportant'
import CampaignMarkDown from '@/components/Campaign/Form/CampaignMarkDown'
import CampaignLimitCodes from '@/components/Campaign/Form/CampaignLimitCodes'
import CampaignLink from '@/components/Campaign/Form/CampaignLink'
import CampaignUploadCodes from '@/components/Campaign/Form/CampaignUploadCodes'
import CampaignActions from '@/components/Campaign/Form/CampaignActions'
import CampaignForm from '@/components/Campaign/Editing/CampaignForm'
import CampaignInformationStep from '@/components/Campaign/Form/CampaignInformationStep'
import UnifiedRegistration from '@/components/UnifiedRegistration/UnifiedRegistration'
import ConfirmUnifiedRegistration from '@/components/UnifiedRegistration/ConfirmUnifiedRegistration'

import moment from 'moment'

export default {
  components: {
    CCard,
    CampaignStamp,
    CampaignTypesRescue,
    CampaignInformationImportant,
    CampaignMarkDown,
    CampaignLimitCodes,
    CampaignLink,
    CampaignUploadCodes,
    CampaignActions,
    CampaignForm,
    CampaignInformationStep,
    UnifiedRegistration,
    ConfirmUnifiedRegistration
  },

  props: {
    partner: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      isProcessing: false,
      isLoading: false,
      showPopover: false,
      newCampaign: {
        howtouse: ['', '', ''],
        eventData: { limit: '', online_link: '' },
        symbol: '%'
      },
      rescues: [
        { name: 'info', label: 'Info' },
        { name: 'voucher', label: 'Voucher' },
        { name: 'online', label: 'Online' },
        // { name : 'letter', label: 'Carta vínculo' },
        { name: 'uniqueCode', label: 'Código único' }
      ],
      steps: [
        { name: '2', value: 2 },
        { name: '3', value: 3 }
      ],
      rescue: '',
      step: 3,
      file: '',
      selectedCompanies: [],
      openConfirmModal: false
    }
  },

  computed: {
    ...mapGetters({
      campaign: types.CAMPAIGN,
      companies: types.CONFIG_COMPANY_LIST,
      currentCompany: types.CONFIG_COMPANY_ID,
      unifiedPermission: types.CONFIG_UNIFIED_REGISTRATION
    }),

    hasCampaign () {
      return Object.getOwnPropertyNames(this.campaign).length > 0
    },

    stepHandler () {
      if (this.step === 2 && this.newCampaign.howtouse.length === 3) {
        // removes the last step
        this.newCampaign.howtouse.pop()

        return this.newCampaign.howtouse
      } else if (this.step === 3 && this.newCampaign.howtouse.length < 3) {
        // add a new empty step
        this.newCampaign.howtouse.push('')

        return this.newCampaign.howtouse
      }
      return this.newCampaign.howtouse
    },

    parseImportant () {
      if (this.hasCampaign && this.newCampaign.important) {
        const cut = /\\-/gi

        return this.newCampaign.important.replace(cut, '\n')
      }

      return ''
    },

    isValid () {
      let requireds = ['title', 'important', 'start_date', 'end_date']

      if (this.newCampaign.symbol === '%' || this.newCampaign.symbol === 'R$') {
        requireds.push('value')
      }

      if (this.rescue === 'online') {
        requireds = [ ...requireds, 'online_link' ]
      } else if (this.rescue === 'uniqueCode') {
        requireds = [ ...requireds, 'limit' ]
      }

      const data = Object.entries(this.newCampaign).map(([key, value]) => ({ [key]: value }))

      const hasProperties = prop => data.some(obj => obj.eventData ? obj.eventData[prop] : obj[prop])
      const isValid = (requiredProps, data) => requiredProps.every(hasProperties)

      return !(isValid(requireds, data) && this.rescue)
    },

    showUnifiedRegistration () {
      return !!this.unifiedPermission &&
        !!this.partner.unified_id &&
        !this.hasCampaign &&
        this.companies.length > 1
    }
  },

  watch: {
    rescue (current, previous) {
      const defaultRescues = ['info', 'voucher', 'letter'].some(rescue => rescue === current)
      if (defaultRescues) delete this.newCampaign.eventData

      if (current !== 'online') {
        Object.assign(this.newCampaign, { voucher: null })
      }

      if (current === 'online' && !!previous) {
        Object.assign(this.newCampaign, { eventData: { online_link: '' } })
      }

      if (current === 'uniqueCode' && !!previous) {
        Object.assign(this.newCampaign, { eventData: { limit: '' } })
      }

      if (current !== 'uniqueCode' && !!previous) this.file = ''
    }
  },

  async mounted () {
    await this.loadCampaign()
    if (this.hasCampaign) {
      // remove references
      const campaign = JSON.parse(JSON.stringify(this.campaign))

      this.newCampaign = {
        ...this.newCampaign,
        ...campaign,
        start_date: new Date(this.campaign.start_date * 1000),
        end_date: new Date(this.campaign.end_date * 1000)
      }

      this.rescue = this.newCampaign.event
      this.step = this.newCampaign.howtouse.length
    }
  },

  methods: {
    ...mapActions({
      getCampaign: types.CAMPAIGN,
      campaignCreate: types.CAMPAIGN_CREATE,
      campaignUpdate: types.CAMPAIGN_UPDATE
    }),

    async loadCampaign () {
      this.isLoading = true

      const campaign = this.$route.params['campaign']

      if (campaign) await this.getCampaign({ slug: campaign, isUpdate: true })
      this.isLoading = false
    },

    synchronize (valueEmited) {
      const { error, ...rest } = valueEmited
      this.newCampaign = { ...this.newCampaign, ...rest }
    },

    updateStep ({ index, value }) {
      this.newCampaign.howtouse[index] = value
    },

    formatStartDateEn (date) {
      return `${moment(date).format('YYYY-MM-DD')} 00:00:00`
    },

    formatEndDateEn (date) {
      return `${moment(date).format('YYYY-MM-DD')} 23:59:59`
    },

    saveAction () {
      this.showUnifiedRegistration && !!this.selectedCompanies.length
        ? this.openConfirmModal = true
        : this.setCampaign()
    },

    submitAndClose () {
      this.openConfirmModal = false
      this.setCampaign()
    },

    async setCampaign () {
      this.isProcessing = true
      const perk = this.partner.id

      // discards unnecessary properties to prevent request errors
      const {
        online_link,
        is_highlighted,
        partner,
        partner_id,
        slug,
        addresses,
        vouchersCount,
        ...data
      } = this.newCampaign

      let campaign = {
        ...data,
        value: parseFloat((this.newCampaign.value || '').toString().replace('.', '').replace(',', '.')),
        start_date: this.formatStartDateEn(this.newCampaign.start_date),
        end_date: this.formatEndDateEn(this.newCampaign.end_date),
        name: this.newCampaign.title,
        event: this.rescue
      }

      // id already exists - action: edit
      if (this.newCampaign.id) {
        // removes the id that already exists
        delete campaign.id

        const edit = await this.campaignUpdate({ campaign, perk, file: this.file, id: this.newCampaign.id })

        if (edit) {
          const campaignDetails = { name: 'parceiros-slug-campanhas-campaign' }
          this.$router.push(campaignDetails)
        }
      } else {
        if (this.selectedCompanies.length > 0) {
          campaign = { ...campaign, companies: this.selectedCompanies }
        }

        const slug = await this.campaignCreate({ campaign, perk, file: this.file })

        if (slug) {
          const campaignListRoute = {
            name: 'parceiros-slug-campanhas-campaign',
            params: { ...this.$route.params, campaign: slug }
          }
          this.$router.push(campaignListRoute)
        }
      }

      this.isProcessing = false
    }
  }
}
</script>

<style lang="scss">
@import '~@/styles/reference';

.campaign-information-container {
  display: flex;
  flex-direction: column;
  padding: 0 10px;

  @include desktop {

    @include container;
  }

  & > .title { margin-top: 30px; }

  & > .information-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    flex-wrap: nowrap;
    flex-basis: 100%;
    margin-top: 25px;

    @include desktop {
      flex-direction: row;
    }
    & > .form-important {
      display: flex;
      flex-direction: column;
      flex-basis: 50%;
      padding: 20px;
      margin-right: 5px;

      @include mobile {
        margin-right: 0;
        margin-bottom: 20px;
      }

      & > .form-container {
        padding-bottom: 20px;
        display: flex;
        flex-direction: column;
        width: 100%;
        border-bottom: 1px solid $border-color;

        @include desktop { flex-direction: row; }

        & > .tag {
          flex-basis: 30%;

          @include mobile { margin: 0 auto; }
          & > .campaign-stamp { margin-right: 20px; }
        }

        & > .form { flex-basis: 70%; }
      }

      & > .important-container {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin: { top: 20px }

        & > .input-important { margin: { top: 20px; bottom: 0px; } }

        & > .options {
          display: flex;
          justify-content: space-between;

          & > .input-length {
            color: $title-color;
            opacity: 0.6;
            font-size: 12px;
          }

          & > .help-formatation {
            color: $primary-color-placeholder;
            font-size: 12px;
            text-decoration: underline;
            cursor: pointer;
          }
        }
      }

      & > .popover {
        display: flex;
        flex-direction: column;
        width: 300px;

        & > .content > .markdown {
          padding: 10px;
          font-size: 14px;

          & > .header {
            display: flex;
            justify-content: space-between;
            padding-bottom: 10px;
          }

          & > .column {
            padding-top: 10px;
            padding-bottom: 20px;
            border-bottom: 1px solid $border-color;
            font-family: $base-font-family;

            & > .title {
              color: $title-color !important;
              font-family: $base-font-family;
              font-size: 14px;
              line-height: 19px;
              font-weight: 600;
            }

            & > .item {
              color: $title-color !important;
              font-family: $base-font-family;
              font-size: 14px;
              line-height: 19px;
            }

            & .br { padding-bottom: 20px; }

            & > .link {
              font-weight: bold;
              color: $title-color;
              text-decoration: underline;
              margin-top: 10px;
            }
          }

          & > .last {
            border-bottom: unset;
            padding-bottom: 0;
          }
        }
      }
    }

    & > .how-to-use {
      flex-basis: 50%;
      margin-left: 5px;

      @include mobile { margin-left: 0; }

      padding: 20px;
      display: flex;
      flex-direction: column;
      padding-bottom: 30px;

      @include desktop {
        margin-left: 5px;
      }
      & > .number-steps {
        display: flex;
        flex-direction: column;
        margin: { top: 20px; bottom: 20px }

        & > .label-steps {
          flex-basis: 25%;
          font-size: 12px;
          margin-bottom: 10px;
        }
        & > .options { flex-basis: 75%;  }
      }
    }

    & > .campaign-link {
      background: red;
    }
  }

  > .unified-component {
    margin-top: 20px;
  }
}
</style>
