<template>
  <div class="user-import-confirmation-total">
    <div class="wrapper">
      <p class="text">
        <span class="total">
          {{ total }}
        </span>
        Total de CPFs enviados
      </p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    total: [Number, String],
  },
}
</script>

<style lang="scss">
@import '~@/styles/reference';

.user-import-confirmation-total {
  background-color: $another-color;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;

  & > .wrapper {
    display: flex;
    min-width: 270px;
    padding-left: 70px;

    & > .text {
      color: $base-color;
      font-weight: bold;

      & > .total {
        display: inline-block;

        text-align: center;
        min-width: 35px;
        margin-right: 10px;
      }
    }
  }
}
</style>
