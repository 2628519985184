<template>
  <div class="registration-info-card">
    <donut-card
      :loading="loadingUserRegistration"
      :error="userRegistrationError"
      :graph-data="userRegistrationInformationData"
    />
    <dash-table
      :error="!loadingCitiesWithMostUsers && citiesWithMostUsersError.error"
      :loading="loadingCitiesWithMostUsers"
      :title="citiesWithMostUsersData.title"
      :labels="citiesWithMostUsersData.labels"
      :results="citiesWithMostUsersData.results"
      :disable-selection="true"
    />
  </div>
</template>

<script>
import NotAvailable from '@/components/Dashboard/base/notAvailable.vue'
import DashTable from '@/components/Dashboard/base/DashTable.vue'
import DonutCard from '@/components/Dashboard/base/GraphCard.vue'
import { mapGetters, mapActions } from 'vuex'
import { DASH_USER_REGISTRATION_INFORMATION, DASH_CITIES_WITH_MOST_USERS } from '@/store/types'

export default {
  name: 'RegistrationInfoCard',
  components: {
    NotAvailable,
    DonutCard,
    DashTable
  },
  props: {
    reloadDataQuery: Boolean
  },
  data () {
    return {
      userRegistrationError: false,
      citiesWithMostUsersError: false,
      loadingUserRegistration: true,
      loadingCitiesWithMostUsers: true
    }
  },
  computed: {
    ...mapGetters({
      userRegistrationInformationData: DASH_USER_REGISTRATION_INFORMATION,
      citiesWithMostUsersData: DASH_CITIES_WITH_MOST_USERS
    })
  },
  watch: {
    reloadDataQuery () {
      if (this.reloadDataQuery) {
        this.getUserRegistrationInformation()
        this.getCitiesWithMostUsers()
      }
    }
  },
  mounted () {
    this.getUserRegistrationInformation()
    this.getCitiesWithMostUsers()
  },
  methods: {
    ...mapActions({
      getUserRegistrationInformationData: DASH_USER_REGISTRATION_INFORMATION,
      getCitiesWithMostUsersData: DASH_CITIES_WITH_MOST_USERS
    }),
    getUserRegistrationInformation () {
      this.loadingUserRegistration = true
      this.getUserRegistrationInformationData().then((success) => {
        this.userRegistrationError = !success
        this.loadingUserRegistration = false
      })
    },
    getCitiesWithMostUsers () {
      this.loadingCitiesWithMostUsers = true
      this.getCitiesWithMostUsersData().then((success) => {
        this.citiesWithMostUsersError = !success
        this.loadingCitiesWithMostUsers = false
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/styles/reference.scss';

.registration-info-card {
  display: grid;
  grid-template-columns: 1fr 2fr;
  width: 100%;
  height: 421px;
  max-width: 676px;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.16);
  border-radius: 6px;

  @include mobile {
    grid-template-columns: 1fr;
    height: 100%;
  }

  > .graph-card {
    width: 100%;
    min-width: 290px;
    box-shadow: none;
  }

  > .dash-table {
    width: 100%;
    height: 100%;
    border-radius: 6px;
    & :deep .table {
      .row:hover {
        background: white;
      }
    }
  }
}
</style>
