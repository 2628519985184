<template>
  <icon
    v-if="!isLoading"
    class="c-icon"
    :icon="icon"
    :width="'' + size"
    :height="'' + size"
  />
</template>

<script>
import Icon from 'vue-svgicon'

export default {
  components: { Icon },
  props: {
    size: [Number, String],
    icon: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      isLoading: false
    }
  },
  watch: {
    icon (newIcon, oldIcon) {
      if (process.env.NODE_ENV !== 'development' && newIcon !== oldIcon) {
        this.loadIcon()
      }
    }
  },
  mounted () {
    this.loadIcon()
  },
  methods: {
    loadIcon: async function () {
      this.isLoading = true
      await import('@/assets/icons/' + this.icon)
      this.isLoading = false
    }
  }
}
</script>

<style lang="scss">
@import '~@/styles/reference';

.c-icon {
  fill: $icon-color;
  display: inline-block;
}
</style>
