<template>
  <c-input-container
    v-bind="$attrs"
    :class="classes"
    :validation="isOpened ? '' : validation"
    :label="formLabel"
    :required="required"
    data-cy="c-select"
  >
    <div
      v-if="!isMultiselect"
      class="inner"
    >
      <div
        class="input"
        :tabindex="tabindex"
        @click="openingHandler"
        @focus="handlerFocus"
        v-click-outside="outside"
      >
        <span
          ref="searchable"
          contenteditable
          :class="['selected', { '-placeholder': !selected }]"
          @keydown.self.down.prevent="pointerForward"
          @keydown.self.up.prevent="pointerBackward"
          @keydown.enter.tab.stop.self="addPointerElement"
          @keydown.tab.stop.self="escHandler"
          @keyup.esc.passive="escHandler"
          @input="search"
          @click="resetQuery"
        >
          {{ (typeof this.selected === 'object' && this.selected[this.displayBy]) || this.selected }}
        </span>

        <c-icon
          size="10"
          class="icon"
          icon="chevron-down"
        />
      </div>

      <div
        v-if="isOpened && options.length"
        class="items"
      >
        <div
          v-for="(option, index) in options"
          :key="index"
          :class="['item', { '-selected': isSelected(option), '-active': index === pointer }]"
          aria-hidden="true"
          @click.stop="selected = index"
          @mouseenter.self="pointerSet(index)"
        >
          <span class="text">{{ option[displayBy] }}</span>
        </div>
      </div>
    </div>

    <div
      v-if="isMultiselect"
      class="c-multiselect inner"
      :class="multiSelectClasses"
    >
      <transition name="c-multiselect-feedback">
        <span
          v-if="feedbackShow && feedbackMessage"
          class="feedback"
        >
          <c-icon
            v-if="validationIcon"
            :icon="validationIcon"
            size="15"
            class="icon"
          />
          {{ feedbackMessage }}
        </span>
      </transition>

      <c-icon
        v-if="icon"
        :icon="icon"
        class="icon"
        width="20px"
        height="20px"
      />
      <multiselect
        v-on="$listeners"
        v-bind="attrs"
        v-model="values"
        open-direction="bottom"
        :placeholder="placeholder"
        :select-label="'Pressione enter para selecionar'"
        :selected-label="'Selecionado'"
        :deselect-label="'Selecionado'"
      >
        <template slot="noResult">
          {{ noResultMessage }}
        </template>

        <template
          slot="caret"
          slot-scope="props"
        >
          <div>
            <div
              @mousedown.prevent.stop="props.toggle()"
              class="multiselect__select"
            >
              <c-icon
                size="15"
                class="icon"
                icon="chevron-down"
              />
            </div>
          </div>
        </template>

        <template slot="maxElements">
          <div class="option-warning">
            <c-icon
              :icon="validationIcon"
              size="15"
              class="icon"
            />
            Máximo de {{ max }} opções selecionadas.
          </div>
        </template>
      </multiselect>
    </div>
  </c-input-container>
</template>

<script>
import Multiselect from 'vue-multiselect'

import CInputContainer from '@/components/CComponents/CInputContainer'

import * as object from '@/modules/object'
import matches from '@/modules/matches'
import Scroll from '@/modules/directives/Scroll'

export default {
  components: {
    CInputContainer,
    Multiselect
  },

  directives: {
    'click-outside': {
      bind: function (el, binding, vNode) {
        const handler = (e) => {
          if (!el.contains(e.target) && el !== e.target) {
          	binding.value(e)
          }
        }
        el.__vueClickOutside__ = handler

        document.addEventListener('click', handler)
      },

      unbind: function (el, binding) {
        document.removeEventListener('click', el.__vueClickOutside__)
        el.__vueClickOutside__ = null
      }
    },
    Scroll
  },

  props: {
    tabindex: {
      type: Number,
      default: 0
    },
    value: [String, Object, Number, Array],
    items: Array,
    displayBy: {
      type: String,
      default: 'name'
    },
    trackBy: String,
    placeholder: {
      type: String,
      default: 'Selecione uma opção'
    },
    closeOnSelect: Boolean,
    clearOnSelect: Boolean,
    validation: String,
    disabled: Boolean,
    success: Boolean,
    isMultiselect: {
      type: Boolean,
      default: false
    },
    feedbackType: {
      type: String,
      default: 'error'
    },
    feedbackMessage: {
      type: String,
      default: ''
    },
    feedbackShow: {
      type: Boolean,
      default: false
    },
    noResultMessage: {
      type: String,
      default: 'Não foram encontradas opções. Considere alterar o filtro.'
    },
    formLabel: {
      type: String,
      default: ''
    },
    required: {
      type: Boolean,
      default: false
    },
    icon: {
      type: String,
      default: ''
    },
    max: [Number, String],
    flex: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      pointer: -1,
      isOpened: false,
      searchQuery: '',
      validationIcon: 'warning-circle',
      values: []
    }
  },
  computed: {
    classes () {
      return [ 'c-select',
        {
          '-is-opened': this.isOpened,
          '-has-validation': this.validation || this.success,
          '-disabled': this.disabled,
          '-success': this.success,
          '-flex': this.flex
        }
      ]
    },

    multiSelectClasses () {
      return {
        '-success': this.feedbackType === 'success',
        '-error': this.feedbackType === 'error',
        '-show-feedback': this.feedbackShow,
        '-has-icon': this.icon
      }
    },

    attrs () {
      return { 'track-by': 'slug', ...this.$attrs, ...(this.max ? { max: +this.max } : { }) }
    },

    selected: {
      get () {
        if (this.placeholder && this.value === '') return this.placeholder

        if (this.value) {
          return this.options.find(item => {
            return this.trackBy ? item[this.trackBy] === this.value : object.equals(item, this.value)
          })
        } else {
          return this.placeholder
        }
      },

      set (index) {
        if (index >= 0) {
          const tracked = this.trackBy
            ? this.options[index][this.trackBy]
            : this.options[index]

          this.outside()
          this.$emit('input', tracked)
        }
      }
    },

    options () {
      return this.searchQuery
        ? this.items.filter((item) => {
          const itemName = this.displayBy ? item[this.displayBy] : item
          return typeof itemName === 'string'
            ? matches(this.searchQuery.toLowerCase(), itemName.toLowerCase())
            : matches(this.searchQuery.toString().toLowerCase(), itemName.toString().toLowerCase())
        })
        : this.items
    }
  },
  mounted () {
    this.setInitialValues()
  },

  methods: {
    setInitialValues () {
      if (this.isMultiselect) this.values = this.value
    },
    handlerFocus () {
      this.isOpened = true
      this.resetQuery()
      this.setFocus()
    },

    openingHandler () {
      if (this.isOpened) this.isOpened = false
      this.isOpened = true

      this.resetQuery()
      this.setFocus()
    },

    escHandler () {
      if (this.isOpened) {
        this.outside()
        this.unsetFocus()
      }
    },

    resetQuery () {
      this.$refs['searchable'].innerHTML = ''
      this.$emit('input', this.value)
    },

    isSelected (item) {
      if (!this.selected) return false

      return this.trackBy
        ? this.selected[this.trackBy] === item[this.trackBy]
        : object.equals(item, this.value)
    },

    outside () {
      this.isOpened = false
      this.blur()
    },

    clear () {
      this.clearOnSelect ? this.itemSelected = '' : this.itemSelected
    },

    search ({ target: { textContent } }) {
      this.isOpened = true
      this.searchQuery = textContent
    },

    blur () {
      setTimeout(() => {
        const contentDefault = this.items.find(item => item.name === this.value)
        const content = typeof this.selected === 'object' && this.selected[this.displayBy] || contentDefault && contentDefault[this.displayBy] || this.value || this.placeholder

        if (this.$refs['searchable']) this.$refs['searchable'].innerHTML = content
        this.searchQuery = ''
        this.pointerReset()
      }, 100)
    },

    pointerReset () {
      this.pointer = -1
    },

    pointerSet (option) {
      this.pointer = option
    },

    pointerForward () {
      if (this.pointer < this.items.length - 1) {
        this.pointer++
      }
    },

    pointerBackward () {
      if (this.pointer > 0) {
        this.pointer--
      }
    },

    setFocus () {
      this.$refs.searchable.focus()
    },

    unsetFocus () {
      this.$refs.searchable.blur()
    },

    addPointerElement ({ key } = 'Enter') {
      if (this.items.length > 0 && key === 'Enter') {
        this.selected = this.pointer

        this.$nextTick(() => {
          this.unsetFocus()
          this.outside()
        })
      }
    }
  }
}
</script>

<style lang="scss">
@import '~@/styles/reference';
@import '~vue-multiselect/dist/vue-multiselect.min';

$c-select-border-color: #E9EAEE;
$c-select-highlight-background: #f3f4f6 !default;

$c-input-disabled-background-color: #eaedef !default;
$c-input-disabled-color:            #bdc0d1 !default;

.c-select {
  position: relative;

  &.-flex {
    >label {
      position: absolute;
      right: 100%;
    }
    & > .inner {
      width: 264px;
      max-width: 100%;
    }
  }

  &.-disabled {
    cursor: default;
    pointer-events: none;

    & > .inner > .input {
      background-color: $c-input-disabled-background-color;
      border-color: $c-input-disabled-background-color;

      pointer-events: none;

      & > .selected,
      & > .selected.-placeholder {
        color: $c-input-disabled-color;
        font-weight: 300;
      }

      & > .icon { fill: $c-input-disabled-color; }
    }
  }

  & > .inner {
    position: relative;
    width: 100%;

    & > .input {
      position: relative;
      border: 1px solid $c-select-border-color;
      display: flex;
      align-items: center;
      height: 40px;
      background-color: white;
      border-radius: 3px;
      font-size: 14px;
      transition: border-color .3s ease, box-shadow .3s ease;
      cursor: pointer;

      & > .selected {
        display: inline-flex;
        align-items: center;

        color: $text-color;
        padding-left: 10px;
        padding-right: 35px;

        line-height: 40px;
        min-height: inherit;
        outline: none;

        &.-placeholder {
          color: $icon-color;
          font-size: 14px;
          font-weight: 400;
        }
      }

      & > .icon {
        position: absolute;
        top: 50%;
        right: 10px;
        transform: translateY(-50%);
        transition: transform 500ms ease;
      }

      &:hover,
      &:active,
      &:focus {
        border-color: $secondary-color-placeholder;
        border-color: var(--color-secondary);
        box-shadow: 0 0 0 2px $secondary-color-placeholder;
        box-shadow: 0 0 0 2px var(--color-secondary);
      }
    }

    .items {
      border-radius: 8px;
      max-height: 300px;
      overflow-y: auto;
      overflow-x: hidden;
      z-index: 2;
      width: 100%;
      box-sizing: border-box;
      background-color: #fff;
      position: absolute;
      left: 0;
      top: calc(100% + 2px);

      border: 1px solid #E8E8E8;
      border-top: none;

      & > .item {
        cursor: pointer;
        min-height: 40px;
        display: flex;
        align-items: center;

        &.-selected { font-weight: 700; }
        &.-active { background-color: $c-select-highlight-background; }

        &:first-child {
          border-top-left-radius: 8px;
          border-top-right-radius: 8px;
        }

        &:last-child {
          border-bottom-left-radius: 8px;
          border-bottom-right-radius: 8px;
        }

        & > .text {
          font-size: 14px;
          color: $title-color;
          transition: color .3s ease;
          color: $text-color;
          padding: 10px;
          padding-left: 12px;
        }

        &:hover {
          background-color: $c-select-highlight-background;

          & > .text {
            color: $primary-color-placeholder;
            color: var(--color-primary);
          }
        }
      }
    }

    &.-is-opened { @extend %c-select-opened; }
  }
}

%c-select-opened {
  & > .input {
    border-radius: 3px 3px 0 0;

    & > .entry > .icon { transform: translateY(-50%) rotate(-180deg); }
  }
}

.c-multiselect {
  position: relative;

  & > .label {
    color: $title-color;
    text-transform: uppercase;
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 9px;

    & > span {
      display: flex;
    }
  }

  & > .label .required {
    color: $title-color;
    display: inline-block;
    margin-left: 3.5px;
  }

  & > .feedback {
    display: flex;
    align-items: center;
    font-size: 13px;
    position: absolute;
    bottom: 3px;
    left: 0;
    right: 0;
    line-height: 1;
    z-index: 1;

    & > .icon { margin-right: 5px; }
  }

  &.-show-feedback {
    padding-bottom: outer-base();
  }

  & .multiselect__tag {
    background-color: #E0E1E6 !important;
    color: $title-color !important;
    font-weight: 600 !important;
    font-size: 13px !important;
  }

  &.-show-feedback.-error {
    & .multiselect .multiselect__tags {
      border-color: #ff7987;
    }

    & > .feedback {
      color: #ff7986;

      & > .icon { fill: #ff7986; }
    }
  }

  &.-has-icon > .inner {
    position: relative;

    & > .icon {
      position: absolute;
      top: 50%;
      left: 15px;
      transform: translateY(-50%);
      z-index: 2;
    }

    .multiselect__single {
      text-indent: 30px;
      padding-top: 2px;
      color: rgba($base-color, 0.3);
    }
  }

  .multiselect {
    z-index: 1;

    &,
    &__input,
    &__single {
      font-size: 13px;
      font-family: $c-font-family-base;
      color: $title-color;
    }

    &__input,
    &__single {
      &::placeholder { color: rgba($base-color, 0.3); }
    }

    &__select {
      height: 2.5625rem;

      &::before {
        border-top-color: gray;
        color: $text-color;
      }
    }

    &__tags {
      border-color: $c-select-border-color;
    }

    &__tags,
    &__input {
      background-color: #FFFFFF;
    }

    &__content-wrapper {
      margin-top: .3125rem;
      border-radius: 8px;
      max-height: 240px !important;
    }

    &__option {
      &.multiselect__option > .option-warning {
        display: flex;
        color: $failure-color;

        & > .icon {
          fill: $failure-color;
          margin-right: 5px;
        }
      }

      &.multiselect__option--selected,
      &.multiselect__option--selected::after {
        background-color: unset;
        color: unset;
      }

      &--highlight,
      &--highlight::after {
        background-color: $c-select-highlight-background !important;
        color: $title-color !important;
      }

      &--selected,
      &--selected::after {
        background-color: $c-color-primary;
        color: $title-color;
      }
    }

    & .multiselect__tags {
      transition: border 0.3s ease-in-out;
      will-change: border;

      &:focus {
        outline: none;
     }
    }

    &--active {
      z-index: 100;

      &::after { opacity: 1; }
      & .multiselect__tags {
        border-radius: 3px;
        transition: box-shadow 0.3s ease;
        box-shadow: 0 0 0 3px var(--color-secondary);
        border-color: transparent;
      }
    }

    &--disabled {
      opacity: 1 !important;
    }

    &--disabled .multiselect__tags,
    &--disabled .multiselect__input {
      color: #bdc0d1;
      background-color: #eaedef;
      border-color: #eaedef;
      border-radius: 3px;
      pointer-events: all;

      &:hover { cursor: default; }
      &::placeholder { color: #bdc0d1; }
    }

    &__tag {
      background-color: $c-color-primary;
      &-icon:hover { background-color: $c-color-primary; }
      &-icon::after { color: $title-color; }
    }
  }

  &.-show-feedback.-error .multiselect--active {
    & .multiselect__tags {
      box-shadow: 0 0 0 3px #ff7987;
    }
  }

  .multiselect__single { color: rgba($base-color, 0.3) !important; }
}

.c-multiselect-feedback-enter-active,
.c-multiselect-feedback-leave-active {
  transition: all 0.3s ease-out;
}

.c-multiselect-feedback-enter,
.c-multiselect-feedback-leave-to {
  opacity: 0;
  transform: translateY(-10px);
}

.multiselect__select {
  svg {
    margin-top: 8px;
  }

  &:before {
    display: none;
  }
}

</style>
