<template>
  <div class="user-import-page">
    <user-import-processing
      v-if="isImporting"
      class="processing"
    />
    <div class="title-container">
      <c-title
        class="title"
        icon="import"
      >
        Histórico de importação
      </c-title>
      <p
        class="trial-warning"
        data-cy="trial-warning"
        v-if="isUserQuotaExceeded"
      >
        <k-icon
          icon="lock3"
          size="20px"
          class="icon"
        />
        <span class="text">Você já atingiu o limite de 10 usuários convidados</span>
      </p>
    </div>

    <c-button
      v-if="!isDesktop"
      class="button-import"
      primary
      size="lg"
      icon="plus"
      :disabled="isImporting === undefined || isUserQuotaExceeded"
      data-cy="import-users-button"
      @click="redirectOrOpenModal"
    >
      Importar usuários
    </c-button>

    <user-import-list />

    <user-import-processing-modal
      v-if="importingFeedback"
      :is-opened="importingFeedback"
      @close="importingFeedback = false"
    />
  </div>
</template>

<script>
import { KIcon } from 'kaledo-components'
import { mapGetters, mapActions } from 'vuex'
import * as types from '@/store/types'

export default {
  components: {
    CButton: () => import('@/components/CComponents/CButton'),
    CTitle: () => import('@/components/CComponents/CTitle'),
    UserImportList: () => import('@/containers/User/UserImportList'),
    UserImportProcessing: () => import('@/components/User/Import/UserImportProcessing'),
    KIcon
  },
  data () {
    return {
      importingFeedback: false
    }
  },
  computed: {
    ...mapGetters({
      company: types.COMPANY,
      userCount: types.USER_COUNT,
      isImporting: types.USER_IMPORT_PROCESSING
    }),
    isUserQuotaExceeded () {
      const plansWith10Users = ['trial']
      if (this.company.trial || plansWith10Users.includes(this.company.plan_type)) {
        return parseInt(this.userCount.total_users) >= 11
      } else {
        return false
      }
    }
  },
  mounted () {
    this.getUserCount()
  },
  methods: {
    ...mapActions({
      getUserCount: types.USER_COUNT
    }),
    redirectOrOpenModal () {
      if (!this.isImporting) { this.$router.push({ name: 'usuarios-importacao-nova' }) } else { this.importingFeedback = true }
    }
  }
}
</script>

<style lang="scss">
@import '~@/styles/reference';

.user-import-page {
  @include container;
  .title-container {
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .trial-warning {
      color: #00B2E2;
      font-size: 16px;
      font-weight: 700;
    }
  }

  & > .button-import {
    width: 100%;
    margin-bottom: 20px;
  }
}
</style>
