<template>
  <c-modal
    v-bind="$attrs"
    v-on="$listeners"
    class="review-modal"
    name="review-modal"
  >
    <div class="review-modal-body">
      <c-title>
        <b>Revisão</b>
      </c-title>
      <div class="content">
        <div class="value-info">
          <div class="list">
            <span>
              Subtotal
            </span>
            <span>
              {{ valueFormated }}
            </span>
          </div>
          <div class="list">
            <span>
              Taxa de serviço
            </span>
            <span>
              {{ formatedServiceCharge }}
            </span>
          </div>
          <div
            data-cy="order-total-info"
            class="resume"
          >
            <span>Total a pagar</span>
            <span
              data-cy="total-value"
              class="price"
            >
              {{ totalToPay }}
            </span>
          </div>
        </div>
        <div class="item">
          <h2>Forma de Pagamento</h2>
          <span>
            Boleto bancário
          </span>
        </div>
        <div class="item">
          <h2>Observação</h2>
          <span>{{ description }}</span>
        </div>
      </div>
    </div>
    <div class="review-modal-actions">
      <c-button
        size="lg"
        class="btn-action -cancel"
        @click="$emit('close')"
      >
        voltar
      </c-button>
      <c-button
        primary
        size="lg"
        class="btn-action -confirm"
        @click="$emit('confirm')"
        :disabled="isLoading"
      >
        confirmar
      </c-button>
    </div>
  </c-modal>
</template>

<script>
import CModal from '@/components/CComponents/CModal'
import CTitle from '@/components/CComponents/CTitle.vue'
import formatToCurrency from '@/helpers/formatToCurrency'

export default {
  components: { CModal, CTitle },
  props: {
    isLoading: {
      type: Boolean,
      required: true
    },
    value: {
      type: Number,
      required: true
    },
    description: {
      type: String,
      required: true
    }
  },
  computed: {
    valueFormated () {
      return formatToCurrency(this.value)
    },
    serviceCharge () {
      return (this.value * process.env.ORDER_CHARGE_PAYMENT_SLIP)
    },
    formatedServiceCharge () {
      const formatDecimal = parseFloat(this.serviceCharge.toFixed(2))
      return formatToCurrency(formatDecimal)
    },
    totalToPay () {
      return formatToCurrency(this.value + this.serviceCharge)
    }
  }
}
</script>

<style lang="scss">
@import '~@/styles/reference';

.review-modal {
  background-color: #FFF !important;
  z-index: 30 !important;
  width: 540px;

  & > .content {
    display: flex;
    align-items: center;
    flex-direction: column;

    @include mobile { justify-content: center; }

    .review-modal-body {
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      margin-top: 20px;
      width: 460px;

      > .content {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        width: 100%;
        gap: 32px;
        margin-top: 32px;
      }

      .value-info {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        width: 100%;
        gap: 16px;

        .list {
          display: flex;
          padding: 0 8px;
          justify-content: space-between;
          color: #5E6684;

          h3 {
            font-size: 16px;
            font-weight: 600;
          }
        }

        .resume {
          padding: 8px 10px;
          display: flex;
          border-top: 1px solid #D8D8D8;
          border-bottom: 1px solid #D8D8D8;
          border-width: 1px, 0px, 1px, 0px;
          justify-content: space-between;
          align-items: center;
          font-weight: 600;
          font-size: 16px;
          text-transform: uppercase;
          color: #202950;

          .price {
            font-weight: 700;
          }
        }
      }

       .item {
        & > h2 {
          text-transform: uppercase;
          color: #202950;
        }
        & > span {
           color: #5E6684;
        }
      }
    }

    .review-modal-actions {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-bottom: 25px;
      margin-top: 32px;

      & > .btn-action {
        width: 100%;

        @include desktop { max-width: 180px; }
        &:not(:last-child) { margin-right: 10px; }

        &.-cancel {
          border: 1px solid rgba(94, 102, 132, 1);
          border: 1px solid var(--color-secondary);
          transition: all 0.1s ease;

          &:hover {
            border-width: 2px;
          }
        }
        &.-confirm {
          background: #121E48;
          background: var(--color-primary);
          transition: all 0.3s ease;

          &:hover {
            opacity: 0.9;
            background: var(--color-primary-8);
          }
        }
      }
    }
  }
}
</style>
