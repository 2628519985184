<template>
  <div class="painel-preview">
    <painel-desktop
      v-if="isDesktopMode"
      :data="data"
    />
    <painel-mobile
      v-else
      :data="data"
    />
    <div
      class="fake-image"
      :class="isDesktopMode ? '' : '-mobile'"
    />
  </div>
</template>

<script>
import PainelDesktop from './PainelDesktop.vue'
import PainelMobile from './PainelMobile.vue'
export default {
  name: 'PainelPreview',
  components: {
    PainelDesktop,
    PainelMobile
  },
  props: {
    isDesktopMode: {
      type: Boolean,
      default: true
    },
    data: {
      type: Object,
      default: () => {}
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/styles/reference';
.painel-preview {
  .fake-image {
    width: 100%;
    height: 230px;
    background: #D8D8D866;
    &.-mobile {
      height: 136px;
    }
  }
}
</style>
