<template>
  <div
    id="sidebar-menu"
    :class="classes"
  >
    <div class="content">
      <c-button
        primary
        size="lg"
        icon="cross"
        class="btn-close"
        @click="$emit('close')"
      />

      <user-profile />

      <c-button
        class="more-accounts"
        only-bottom
        @click="redirectAndClose"
      >
        Visualize todos os clubes
      </c-button>

      <layout-sidebar-item
        v-for="(item, index) in primaryMenu"
        :id="`menu-item-${index}`"
        :item="item"
        :key="item.name"
        class="item"
        arrow
        @click.native="$emit('close')"
      />

      <span class="separator" />

      <layout-sidebar-item
        v-for="(item, index) in secondaryMenu"
        :id="`menu-item-${index}`"
        :item="item"
        :key="item.name"
        :arrow="item.arrow"
        class="item -no-border"
        @click.native="$emit('close')"
      />
    </div>
  </div>
</template>

<script>
import { COMPANY_PERMISSIONS } from '@/store/types'
import { mapGetters } from 'vuex'
import UserProfile from '@/components/User/UserProfile'
import LayoutSidebarItem from '@/components/Layout/LayoutSidebarItem'
import { primaryMenuMobile, secondaryMenu } from '@/content/menuItems'

export default {
  components: {
    UserProfile,
    LayoutSidebarItem
  },

  props: {
    opened: {
      type: Boolean,
      required: true
    }
  },

  data () {
    return {
      secondaryMenu
    }
  },

  computed: {
    ...mapGetters({
      permissions: COMPANY_PERMISSIONS
    }),

    primaryMenu () {
      return primaryMenuMobile(this.permissions)
    },

    classes () {
      return [
        'layout-sidebar', {
          '-opened': this.opened
        }]
    }
  },

  methods: {
    redirectAndClose () {
      this.$router.push({ name: 'troca-de-contas' })

      this.$emit('close')
    }
  }
}
</script>

<style lang="scss">
@import '~@/styles/reference';

.layout-sidebar {
  background-color: #F8F8FD;
  z-index: 10;
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  max-width: 300px;
  text-align: center;
  transform: translateX(-100%);
  transition: transform 0.3s ease;
  will-change: transform;
  display: flex;

  overflow-x: visible;
  overflow-y: scroll;

  & > .content {
    flex: 100%;
    position: relative;

    .btn-close {
      position: absolute;
      z-index: 15;
      right: 10px;
      top: 10px;
    }

    & > .more-accounts {
      margin: 2px 0 30px 0;
      font-size: 12px;
    }

    & > .item:not(:first-of-type):not(.-no-border) {
      border-top: 1px solid $border-color;
    }

    & > .separator {
      display: block;
      width: 200px;
      height: 1px;
      margin: 5px auto;

      border-top: 1px solid rgba($base-color, 0.25);

      & + .item { border-top: 0; }
    }
  }

  &.-opened {
    transform: translateX(0);
  }
}
</style>
