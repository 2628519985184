<template>
  <div class="balance-container">
    <div class="container">
      <h2>Seu saldo</h2>
      <c-spinner v-if="loading" />
      <h3 v-else>{{ formatedBalance }}</h3>
    </div>
    <div class="actions">
      <c-button
        class="button"
        @click="openHowToUseLink()"
      >
        <k-icon
          class="btn-icon"
          size="20"
          icon="question-circle"
        />
        <span class="text">Como utilizar</span>
      </c-button>
      <c-button
        primary
        class="button"
        icon="plus"
        style="margin-left: 24px"
        @click="$emit('new-order')"
      >
        Adicionar saldo
      </c-button>
    </div>
  </div>
</template>

<script>
import { KIcon } from 'kaledo-components'
import CSpinner from '@/components/CComponents/CSpinner.vue'
export default {
  components: { KIcon, CSpinner },
  props: {
    loading: Boolean,
    balance: Number
  },
  computed: {
    formatedBalance () {
      if (!this.balance) return 'R$ 0,00'
      return (this.balance/100).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })
    }
  },
  methods: {
    openHowToUseLink() {
      window.open('https://kaledo.zendesk.com', '_blank')
    }
  }

}
</script>

<style lang="scss" scoped>
.balance-container {
  margin: 24px 0;
  display: flex;
  width: 1022px;
  padding: 24px 40px;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  border: 1px solid var(--gray-400, #BEC0D3);
  background: var(--background-white, #FDFDFD);
  & > .container {
    display: flex;
    flex-direction: column;
    & > h2 {
      color: var(--gray-600, #5E6684);
      font-feature-settings: 'clig' off, 'liga' off;
      font-family: Nunito Sans;
      font-size: 18px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px;
      margin-bottom: 16px;
    }
    & > h3 {
      color: var(--brand-dark-blue, #202950);
      font-feature-settings: 'clig' off, 'liga' off;
      font-family: Nunito Sans;
      font-size: 22px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px;
    }
  }
  & > .actions {
    & > .button {
      height: 40px;
      border-radius: 32px;
      :deep .content .text {
        display: flex;
        align-items: center;
        & > .btn-icon{
          margin-right: 8px;
        }
      }
    }
  }
}
</style>
