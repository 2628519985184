<template>
  <div class="account-creation-step-three">
    <div class="password-div">
      <k-input
        data-cy="password-input"
        class="kinput"
        required
        :has-error="!!password && !validatePassword"
        :error-message="validatePasswordMessage"
        label="SENHA"
        type="password"
        v-model="password"
        placeholder="defina uma senha"
        :has-html-protection="false"
        @input="update('password', $event )"
      />
      <checks
        :password="password"
        class="activate-form-password"
      />
    </div>
    <k-input
      data-cy="confirm-password-input"
      class="kinput"
      required
      :has-error="!validateConfirmPassword"
      :error-message="confirmPasswordErrorMessage"
      v-model="password_confirmation"
      label="CONFIRMAR SENHA"
      type="password"
      placeholder="insira novamente sua senha"
      :has-html-protection="false"
      @input="update('password_confirmation', $event )"
    />
    <div class="user-terms-approval">
      <input
        data-cy="accept-terms-checkbox"
        class="checkbox"
        type="checkbox"
        v-model="terms"
        checked="checked"
        @change="update('terms', terms)"
      />
      <p>
        Li e aceito a <a
          href="https://kaledo.com.br/privacy-policy"
          target="_blank"
          rel="noopener noreferrer"
        >Política de Privacidade</a> e os <a
          href="https://kaledo.com.br/terms"
          target="_blank"
          rel="noopener noreferrer"
        >Termos de Uso.</a>
      </p>
    </div>
  </div>
</template>

<script>
import { KInput } from 'kaledo-components'
import Checks from '@/components/CComponents/Checks.vue'
import { isPasswordValid, maxLengthValidation } from '@/modules/validate/validators.js'
import { maxLengthValidationMessage } from '@/modules/validate/helpers.js'

export default {
  name: 'AccountCreationForm',
  components: {
    KInput,
    Checks
  },
  props: {
    stepValue: Object
  },
  data () {
    return {
      password: '',
      password_confirmation: '',
      terms: false
    }
  },
  computed: {
    validatePassword () {
      return isPasswordValid(this.password) && maxLengthValidation(this.password, 100)
    },
    validatePasswordMessage () {
      return !maxLengthValidation(this.password, 100) ? maxLengthValidationMessage('senha', 100) : 'Senha inválida'
    },
    validateConfirmPassword () {
      return this.password === this.password_confirmation || !this.password_confirmation
    },
    confirmPasswordErrorMessage () {
      return !this.password_confirmation ? 'Campo Obrigatório' : 'Senhas devem ser iguais'
    }
  },
  created () {
    this.password = this.stepValue.password
    this.password_confirmation = this.stepValue.password_confirmation
    this.terms = this.stepValue.terms
  },
  methods: {
    update (key, value) {
      this.$emit('input', { ...this.stepValue, [key]: value })
    }
  }
}
</script>

<style lang='scss' scoped>
@import '~@/styles/reference';
.account-creation-step-three {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  width: 100%;

  @include mobile {
    gap: 16px;
  }

  .kinput {
    width: 100%;
    max-width: 508px;

    &:first-of-type {
      margin-bottom: 10px;
    }

    @include tablet {
      max-width: 640px;

      :deep > .k-input {
        max-width: 640px;
      }
    }
  }
  .password-div {
    width: 100%;
    max-width: 508px;

    @include tablet {
      max-width: 640px;
    }

    .checks {
      width: 100%;
      margin-top: 0px;
    }
  }
  .user-terms-approval {
    display: flex;
    justify-content: flex-start;
    font-size: 14px;
    color: rgba(18, 30, 72, 0.70);
    width: 100%;
    max-width: 508px;
    text-align: left;

    @include tablet {
      max-width: 640px;
    }

    p {
      display: block;
    }

    .checkbox {
      width: 18px;
      height: 18px;
      margin-right: 8px;
      cursor: pointer;
    }

    a {
      color: #EB6365;
      margin: 0px 3px;
      width: 100%;
    }
  }
}
</style>
