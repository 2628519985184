<template>
  <login-container />
</template>

<script>
import LoginContainer from '@/containers/Login/LoginContainer'

export default {
  components: { LoginContainer },
  layout: 'unauthenticated',
  middleware: 'checkauth'
}
</script>
