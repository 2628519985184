<template>
  <activate-form
    v-if="!isProcessing"
    @submit="submit"
    @clear-error="clearError"
    @clear-field-error="clearFieldError"
    :is-loading="isProcessing"
    :companies="companiesActive"
    :company="company"
    :credentials="credentials"
    :error="errorCode"
    :field-errors="fieldErrors"
  />
</template>

<script>
import * as types from '@/store/types'
import { mapActions, mapGetters } from 'vuex'
import moment from 'moment'

import ActivateForm from '@/components/Login/ActivateForm'

const errorCodes =
{ 0: { type: '', data: {} }
, 7: { type: 'userNotFound', data: {} }
, 8: { type: 'isActive', data: {} }
, 9: { type: 'invalidCredentials', data: {} }
, 10: { type: 'invalidLogin', data: {} }
, 11: { type: 'serverError', data: {}}
}

export default {
  components: { ActivateForm },
  data () {
    return {
      isProcessing: false,
      errorCode: errorCodes[0],
      fieldErrors: { },

    }
  },
  computed: {
    ...mapGetters({
      companiesEnabled: types.CONFIG_COMPANIES_ENABLED,
      companiesActive: types.CONFIG_COMPANIES_ACTIVE,
      credentials: types.AUTH_CREDENTIALS
    }),
    company() {
      const id = this.$route.params['company']
      return (this.companiesEnabled || []).find(c => c.id === id) || {}
    }
  },
  methods: {
    ...mapActions({
      activateCompany: types.AUTH_ACTIVATE_COMPANY,
      login: types.AUTH_LOGIN
    }),
    async submit (userData) {
      this.isProcessing = true
      const company = this.$route.params['company']
      const birthday = moment(userData.birthday, 'DD/MM/YYYY').format('YYYY-MM-DD')
      if (this.credentials.password) userData.password = this.credentials.password
      userData = this.removeUserFields(userData)
      const { error, errors, ...data } = await this.activateCompany({ company, ...userData, birthday })

      if (errors) {
        this.setFieldErrors(errors)
      } else if (error) {
        this.errorCode = errorCodes[error]
        this.errorCode.data = errors || data
      } else {
        this.$router.push({ name: 'index' })
      }

      this.isProcessing = false
    },
    removeUserFields (userData) {
      const attributesToRemove = ['id', 'created_at', 'updated_at', 'vidalink_expire_date']
      attributesToRemove.forEach(attribute => {
        if (userData.hasOwnProperty(attribute)) {
          delete userData[attribute];
        }
      })

      return userData
    },
    setFieldErrors(errors) {
      Object.keys(errors).forEach((error) => {
        this.fieldErrors[error] = errors[error][0]
      })
    },
    clearError () {
      this.errorCode = errorCodes[0]
    },
    clearFieldError (field) {
      this.fieldErrors[field] = ''
    }
  },
  mounted () {
    if (!this.credentials.document)
      this.$router.push({ name: 'login' })
  }
}
</script>
