<template>
  <div class="activate-form">
    <div class="content">
      <div class="header">
        <c-logo
          class="logo"
          kaledo
        />
        <h2 class="title">
          Ativação da conta
        </h2>
        <p class="description">
          Preencha as informações abaixo para ativar a sua conta de gestor.
        </p>
      </div>
      <div
        class="form"
      >
        <hr v-if="!!credentials.password" />
        <div class="content-top">
          <!-- Só é exibido caso usuario tenha acesso a outros clubes -->
          <div
            class="club-list"
            v-if="!!credentials.password"
          >
            <div class="list">
              <p class="featured">
                Você está nos clubes:
              </p>

              <p
                class="club"
                v-for="(company, index) in companies"
                :key="index"
              >
                {{ company.name }}
              </p>
            </div>

            <hr />

            <div class="attention">
              <p class="featured">
                Atenção
              </p>
              <p class="text">
                Todas as informações inseridas serão alteradas em todas as contas do clube de benefício atrelados a este CPF
              </p>
            </div>
          </div>

          <hr />

          <div class="wrapper-user">
            <label class="user">
              <c-image
                data-cy="activation-avatar-input"
                class="image"
                :src="avatarFile"
                :placeholder="imagePlaceholder"
                width="85px"
                height="85px"
              />

              <c-button
                class="btn-pic"
                icon="camera3"
                type="button"
                @click="$refs['inputFile'].click()"
              />

              <input
                type="file"
                accept="image/*"
                v-show="false"
                ref="inputFile"
                @input="uploadAvatar"
              />

              <span
                data-cy="activation-document-label"
                class="cpf"
              >
                CPF: {{ credentials.document }}
              </span>
            </label>
          </div>
        </div>

        <!-- nome -->
        <c-input
          data-cy="activation-name-input"
          ref="name"
          label="Nome"
          placeholder="Insira seu nome"
          class="input-data"
          name="name"
          validation-icon="warning-circle"
          v-validate="{ required: true, regex: /\w+/ }"
          data-vv-delay="10"
          data-vv-as="nome"
          :disabled="isLoading"
          :feedback-show="!!getFieldErrors('name')"
          :feedback-message="getFieldErrors('name')"
          v-model="userData.name"
        />

        <!-- sobrenome -->
        <c-input
          data-cy="activation-last-name-input"
          ref="last_name"
          label="Sobrenome"
          placeholder="Insira seu sobrenome"
          class="input-data"
          name="last_name"
          validation-icon="warning-circle"
          v-validate="{ required: true, regex: /\w+/ }"
          data-vv-delay="10"
          data-vv-as="sobrenome"
          :disabled="isLoading"
          :feedback-show="!!getFieldErrors('last_name')"
          :feedback-message="getFieldErrors('last_name')"
          v-model="userData.last_name"
        />

        <!-- e-mail -->
        <c-input
          data-cy="activation-email-input"
          ref="email"
          label="E-Mail"
          placeholder="Insira seu e-mail"
          type="email"
          class="input-data"
          name="email"
          data-vv-as="e-mail"
          validation-icon="warning-circle"
          v-validate="{ required: true, regex: /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/ }"
          data-vv-delay="10"
          :disabled="isLoading"
          :feedback-show="!!getFieldErrors('email')"
          :feedback-message="getFieldErrors('email')"
          v-model="userData.email"
        />

        <!-- celular -->
        <c-input
          data-cy="activation-phone-input"
          ref="phone"
          label="Celular"
          placeholder="Insira seu numero"
          :mask="['(##) ####-####', '(##) #####-####']"
          class="input-data"
          name="phone"
          data-vv-as="celular"
          validation-icon="warning-circle"
          v-validate="{ required: true, regex: /^\([1-9]{2}\) [0-9]{4,5}\-[0-9]{4}$/ }"
          data-vv-delay="10"
          :disabled="isLoading"
          :feedback-show="!!getFieldErrors('phone')"
          :feedback-message="getFieldErrors('phone')"
          v-model="userData.phone"
        />

        <!-- data de nascimento -->
        <c-input
          data-cy="activation-birthday-input"
          ref="birthday"
          name="birthday"
          mask="##/##/####"
          class="input-data"
          placeholder="DD/MM/AAAA"
          label="Data de Nascimento"
          data-vv-as="data de nascimento"
          data-vv-delay="10"
          v-validate="{ required: true, date_format: 'dd/MM/yyyy' }"
          validation-icon="warning-circle"
          :disabled="isLoading"
          :feedback-show="!!getFieldErrors('birthday')"
          :feedback-message="getFieldErrors('birthday')"
          v-model="userData.birthday"
        />

        <!-- genero -->
        <c-select
          data-cy="activation-gender-input"
          ref="gender"
          class="input-data bank"
          form-label="Gênero"
          placeholder="Selecione"
          track-by="value"
          display-by="name"
          name="gender"
          data-vv-delay="10"
          v-validate="'required'"
          :validation="getFieldErrors('gender')"
          :disabled="isLoading"
          :items="genderOptions"
          :value="userData.gender"
          @input="userData.gender = $event"
        />

        <!-- estado/cidade -->
        <div :class="[ 'select-group', { '-has-error': getFieldErrors('cidade') || getFieldErrors('estado') }]">
          <c-select
            data-cy="activation-state-input"
            ref="state"
            class="input-data bank"
            form-label="Estado"
            placeholder="Selecione"
            track-by="sigla"
            display-by="sigla"
            name="state"
            data-vv-delay="10"
            v-validate="'required'"
            :validation="getFieldErrors('state')"
            :disabled="isLoading"
            :items="stateOptions"
            :value="userData.state"
            @input="updateState"
          />
          <c-select
            data-cy="activation-city-input"
            ref="city"
            class="input-data bank"
            form-label="Cidade"
            placeholder="Selecione"
            track-by="name"
            display-by="name"
            name="city"
            data-vv-delay="10"
            v-validate="'required'"
            :validation="getFieldErrors('city')"
            :disabled="isLoading || !cityOptions.length"
            :items="cityOptions"
            :value="userData.city"
            @input="userData.city = $event"
          />
        </div>
        <!-- senha (Só é exibido caso seja primeiro acesso/sem acesso a outros clubes) -->
        <password
          data-cy="activation-password-input"
          v-if="askPassword"
          has-checks
          v-model="userData.new_password"
          :disabled="isLoading"
          :feedback-message="'Senha inválida'"
          @is-valid="(isValid) => isPasswordValid = isValid"
        />
        <!-- confirmar senha (Só é exibido caso seja primeiro acesso/sem acesso a outros clubes) -->
        <password
          data-cy="activation-confirm-password-input"
          v-if="askPassword"
          label="Confirmar Senha"
          v-model="userData.confirm_password"
          :disabled="isLoading"
          :show-error="!matchPasswords"
          :feedback-message="'Senhas não coincidem'"
          @is-valid="(isValid) => isPasswordValid = isValid"
        />

        <hr v-if="askPassword" />

        <div class="underform">
          <!-- Só é exibido caso usuario tenha acesso a outros clubes -->
          <div
            class="text-group"
            v-if="askPassword"
          >
            <p class="maintext">
              SENHA
            </p>
            <p class="text">
              A sua senha será a mesma cadastrada antes, caso não se lembre,
              clique em
              <nuxt-link
                data-cy="forget-password-link"
                :to="{ name: 'esqueci-minha-senha' }"
                class="link"
              >
                esqueci minha senha
              </nuxt-link>
            </p>
          </div>

          <hr />

          <p class="terms">
            Ao clicar em "Ativar Conta" você concorda com nosso
            <a
              data-cy="user-terms-link"
              class="link"
              target="_blank"
              href="https://clube-alelo.convenia.com.br/termos/usuario"
            >Termo de Usuário</a>
            e que leu a nossa
            <a
              data-cy="data-policy-link"
              class="link"
              target="_blank"
              href="https://clube-alelo.convenia.com.br/termos/privacidade"
            >Política de Dados</a>,
            incluindo nosso uso de cookies.
          </p>

          <!-- botão de ativar conta -->
          <c-button
            data-cy="account-activation-button"
            class="btn"
            size="lg"
            primary
            @click="validate"
            :disabled="isLoading || isFormInvalid"
          >
            Ativar conta
          </c-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { states } from '@/modules/statesCities'
import Password from '@/components/CComponents/Password.vue'
import CForm from '@/components/CComponents/CForm'
import CLogo from '@/components/CComponents/CLogo'
import CImage from '@/components/CComponents/CImage'

const genderOptions = [
  { name: 'Feminino', value: 'Feminino' },
  { name: 'Masculino', value: 'Masculino' },
  { name: 'Outros', value: 'Outros' }
]

const USER_DEFAULT = {
  name: '',
  last_name: '',
  email: '',
  phone: '',
  birthday: '',
  gender: '',
  state: '',
  city: ''
}

export default {
  components: {
    CForm,
    CLogo,
    CImage,
    Password
  },

  props: {
    companies: {
      type: Array,
      required: true
    },

    company: {
      type: Object,
      required: true
    },

    credentials: {
      type: Object,
      required: true
    },

    error: {
      type: Object,
      required: true
    },

    fieldErrors: {
      type: Object,
      required: true
    },

    isLoading: {
      type: Boolean,
      required: true
    }
  },

  data () {
    const credentials = Object.keys(this.credentials)
      .reduce((acc, key) => ({
        ...acc,
        ...(!!this.credentials[key] ? { [key]: this.credentials[key] } : {})
      }), {})

    return {
      imagePlaceholder: require('@/assets/img/campaign-placeholder.svg'),
      userData: { ...USER_DEFAULT, avatar: null, ...credentials },
      avatarFile: this.credentials.avatar || null,
      forceError: !!this.credentials.password,
      touched: false,
      isPasswordValid: false,
      genderOptions,
      isMounted: false
    }
  },

  computed: {
    isDirty () {
      if (this.touched) return true
      this.touched = Object.keys(this.userData).some(field => !!this.userData[field])

      return this.touched
    },

    cityOptions () {
      const state = states.find(s => s.sigla === this.userData.state)
      if (!state) return []

      return state.cidades.map(c => ({ name: c }))
    },

    stateOptions () {
      return states.map(({ sigla, nome }) => ({ sigla, nome }))
    },

    askPassword () {
      return !this.credentials.password
    },

    isFormInvalid () {
      const requireds = [ ...Object.keys(USER_DEFAULT) ]
      return requireds.some(field => !this.isValid(field)) || requireds.some(field => this.userData[field] === '') || !this.passwordValidate
    },

    passwordValidate () {
      if (!this.askPassword) { return true }
      return this.askPassword && this.isPasswordValid &&
        (this.userData.new_password === this.userData.confirm_password)
    },

    matchPasswords () {
      return !this.userData.confirm_password || (this.userData.new_password === this.userData.confirm_password)
    }
  },

  watch: {
    userData: {
      handler: function (value) {
        Object.keys(value)
          .filter(key => Object.keys(this.fieldErrors).includes(key) && !!this.fieldErrors[key])
          .forEach(field => this.$emit('clear-field-error', field))
      },
      deep: true
    }
  },

  mounted () {
    if (this.credentials.password) { this.$validator.validate() }
    const stateAcronym = this.getStateAcronymByName(this.userData.state);
    if (stateAcronym) {
      this.userData.state = stateAcronym;
    }
    this.isMounted = true
  },

  methods: {
    isValid (key) {
      const fields =
      { name: /\w+/,
        last_name: /\w+/,
        // eslint-disable-next-line
        email: /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
        // eslint-disable-next-line
        phone: /^\([1-9]{2}\) [0-9]{4,5}\-[0-9]{4}$/,
        // eslint-disable-next-line
        birthday: /^[0-9]{2}[\/][0-9]{2}[\/][0-9]{4}$/,
        gender: /^(Masculino|Feminino|Outros)$/,
        state: /^[A-Z]{2}$/,
        city: /\w+/,
        new_password: /^.+$/,
        confirm_password: /^.+$/
      }

      return fields[key].test(this.userData[key] || '')
    },

    getFieldErrors (field) {
      if (this.userData[field] !== '' && !this.isMounted) {
        this.$nextTick(() => {
          return this.errors.first(field) || this.fieldErrors[field]
        })
      } else {
        return this.errors.first(field) || this.fieldErrors[field]
      }
    },

    updateState (state) {
      if (this.userData.state === state) return

      this.userData.state = state
      this.userData.city = ''
    },

    getStateAcronymByName (stateName) {
      const state = states.find(obj => obj.nome === stateName);
      return state ? state.sigla : null;
    },

    uploadAvatar (ev) {
      if (!ev.target['files'][0]) return

      this.userData.avatar = ev.target['files'][0]
      const reader = new FileReader()

      reader.onload = (e) => this.avatarFile = e.target.result
      reader.readAsDataURL(this.userData.avatar)
    },

    validate (ev) {
      if (this.isFormInvalid) {
        ev.preventDefault()
        this.$validator.validate()
        return false
      } else {
        this.$emit('submit', this.userData)
        return true
      }
    }
  }
}
</script>

<style lang="scss">
@import '~@/styles/reference';

.activate-form {
  & >.content {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #F8F9FD;
    padding: 0 10px;

    & >.header {
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 10px;
      margin-top: 20px;
      padding-top: 50px;

      .logo {
        margin-bottom: 30px;
      }

      .title {
        text-align: center;
        color: #202950;
        font-size: 18px;
      }

      .description {
        color: #5E6684;
      }
    }

    hr {
      margin: 0 auto;
      margin-top: 20px;
      margin-bottom: 20px;
      opacity: 0.2;
      background-color: #121E48;
    }

    & >.title {
      min-width: 280px;
      margin-top: 20px;
      margin-bottom: 45px;

      @media only screen and (min-width: 600px) {
        width: 500px;
      }
    }

    & >.form {
      min-width: 280px;
      margin-bottom: 100px;

      @media only screen and (min-width: 600px) {
        width: 378px;
      }

      & >.content-top {
        text-align: center;

        & >.club-list {
          & >.list {
            & >.club {
              font-size: 12px;
              font-weight: bold;
              color: #202950;
              opacity: 0.8;
              text-transform: uppercase;
              margin-bottom: 5px;
            }
          }
          & >.attention {
            margin-top: 40px;
            margin-bottom: 30px;

            & >.text {
              margin: 0 auto;
              margin-top: 15px;
              width: 70%;
              font-size: 14px;
              color: #202950;
            }
          }
        }
        & > .wrapper-user > .user {
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-bottom: 40px;

          & >.image {
            border-radius: 50%;
          }
          & >.btn-pic {
            width: 30px;
            transform: translate(100%, -100%);
            background-color: #fff;
          }

          & >.cpf {
            color: #202950;
            font-size: 12px;
            font-weight: bold;
            opacity: 0.8;
          }
        }
      }

      & > .input-data {
        margin-top: 30px;
      }
      & .password {
        margin-top: 30px !important;

        .password-button {
          top: 10px;
        }
        .c-input-container > .label {
          white-space: nowrap;
          position: absolute;
          right: 100%;
          top: 50%;
          transform: translateY(-50%);
          margin-right: 10px;
        }
      }

      .featured {
        margin: 0 auto;
        text-align: center;
        display: table;
        font-weight: 600;
        opacity: 0.8;
        margin-bottom: 10px;
        color: #202950;
        font-size: 14px;

        &::after {
          width: 80%;
          margin: 0 auto;
          height: 3px;
          display: block;
          margin-top: 5px;
          background-color: $secondary-color-placeholder;
          background-color: var(--color-secondary);
          content: '';
        }
      }

      & >.select-group {
        display: flex;
        justify-content: space-between;
        margin-top: 30px;

        .c-select { width: 40%; margin-bottom: 0px !important; }
      }

      & >.input-data, .bank {

        @media screen and (min-width: 675px) and (max-width: 768px) {
          & > .label { padding-right: 10px; }
        }

        & > .label {
          white-space: nowrap;

          width: auto;
          position: absolute;
          right: 100%;
          top: 50%;
          transform: translateY(-50%);
        }

        @media screen and (max-width: 675px) {

          & > .label {
            position: initial;
            right: initial;
            top: initial;
            transform: none;
            justify-content: flex-start;
          }
        }

        & >.c-input-container {
          position: relative;
          width: 100%;
          @include desktop { display: flex; }

          & >.label {
            flex: 35%;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            margin: 0 10px 0 0;

            white-space: nowrap;

            position: absolute;
            right: 100%;
            top: 50%;
            transform: translateY(-50%);
          }

          div.input {
            width: 100%;
          }

          @media screen and (max-width: 675px) {

            & > .label {
              margin: 0 10px 0 0;
              position: initial;
              right: initial;
              top: initial;
              transform: none;
              justify-content: flex-start;
            }
          }
        }
      }
      .bank {
        width: 100%;

        a {
          color: var(--color-primary);
        }

        & >.label {
          margin: 0;
          flex: 30%;
          display: flex;

          @include desktop {
            justify-content: flex-end;
            align-items: center;
            margin: 0 10px 0 0;
          }
        }
        div.input {
          width: 100%;
          font-weight: 400;
        }
      }

      & > .password-error {
        margin: 0;
        display: flex;
        padding-top: 5px;
        align-items: center;

        & > .icon {
          margin-right: 5px;
          fill: #ff7987;
        }
        & > .text {
          color: #ff7986;
          font-size: 12px;
        }
      }

      .link {
        cursor: pointer;
        color: #608CEA;
      }

      & >.underform {
        text-align: center;

        hr { width: 100%; }

        & >.text-group {
          width: 100%;
          margin: 0 auto;
          text-align: left;

          & >.maintext {
            font-size: 12px;
            color: #202950;
            font-weight: 500;
            margin-bottom: 5px;
          }
          & >.text {
            font-size: 14px;
            color: #202950;
          }
        }

        & >.terms {
          color: #0A0D1A;
          width: 100%;
          font-size: 14px;
          text-align: left;
          margin: 0 auto;
          margin-bottom: 30px;

          a {
            cursor: pointer;
            color: #608CEA;
          }
        }

        & >.btn {
          width: 100%;
          border-radius: 8px;
          font-size: 12px;
          padding: 0 15px;
        }
      }
    }
  }
}
</style>
