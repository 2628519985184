<template>
  <c-modal
    class="c-cannot-create-modal"
    name="c-cannot-create-modal"
    :is-opened="true"
    :show-close="false"
  >
    <div class="c-cannot-create-modal-body">
      <template v-if="!$slots.body">
        <c-character
          type="disappointed"
          class="c-cannot-create-modal-character"
        />
        <div class="warning-text">
          <h3 class="lead">
            Número máximo de banners ativos atingido
          </h3>
          <p class="text">
            Para criar um novo banner, por favor, desative um banner ativo ou modifique a data de início de algum dos banners futuros
          </p>
        </div>
      </template>
      <slot name="body" />
    </div>
    <div class="c-cannot-create-modal-actions">
      <c-button
        size="lg"
        class="btn-action"
        @click="$router.push({ name: 'comunicacao-midias' })"
      >
        Voltar
      </c-button>
    </div>
  </c-modal>
</template>

<script>
import CModal from '@/components/CComponents/CModal'
import CCharacter from '@/components/CComponents/CCharacter'

export default {
  name: 'CannotCreateModal',
  components: {
    CModal,
    CCharacter
  }
}
</script>

<style lang='scss'>
@import '~@/styles/reference';
 @include mobile {
  .c-modal.c-cannot-create-modal {
    flex-direction: column;
    justify-content: center;
  }
}

.c-cannot-create-modal {
  .c-character {
    margin: 0 auto;
  }
  .warning-text {
    margin-top: 20px;
    text-align: center;
    margin-bottom: 40px;
    .lead {
      color: $title-color;
      margin-bottom: 20px;
    }
    .text {
      font-size: 14px;
      color: $text-color;
      max-width: 410px;
      margin: 0 auto;
    }
  }
  .c-cannot-create-modal-actions {
    text-align: center;
    margin-bottom: 25px;
  }
  .c-cannot-create-modal-body {
    @include mobile {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
}
</style>
