<template>
  <c-modal
    class="delete-users-modal"
    name="delete-users-modal"
    :is-opened="isOpened"
    @close="$emit('close')"
  >
    <c-title
      class="title"
      size="3"
      icon="user-plus"
    >
      Cadastro de usuários
    </c-title>
    <div class="content">
      <transition name="fade">
        <div v-if="!users || users.length === 0">
          <c-spinner />
        </div>
        <div v-else>
          <h4 class="text">
            Para premiar usuários, antes você deve cadastrá-los no seu clube. O cadastro só estará concluído após o pagamento do pedido de premiação.
          </h4>
          <div class="selected-users">
            {{ users.length }} usuários selecionados
          </div>
          <delete-users-table
            :use-cpf-mask="useCpfMask"
            :rows="users"
          />
          <div class="warning">
            <h5 class="title">
              <k-icon
                icon="warning"
                class="icon"
              />
              Atenção
            </h5>
            <p class="text">
              Ao cadastrar usuários, sua mensalidade aumentará.
            </p>
          </div>
          <div class="buttons">
            <c-button @click="$emit('close')">
              Cancelar
            </c-button>
            <c-button
              class="send"
              icon="chevron-right"
              primary
              @click="handleClick"
            >
              Confirmar
            </c-button>
          </div>
        </div>
      </transition>
    </div>
  </c-modal>
</template>

<script>
import CModal from '@/components/CComponents/CModal'
import DeleteUsersTable from '@/components/General/tables/DeleteUsersTable.vue'
import { KIcon } from 'kaledo-components'

export default {
  name: 'RegisterUsersModal',
  components: {
    CModal,
    DeleteUsersTable,
    KIcon
  },
  props: {
    isOpened: {
      type: Boolean,
      required: true
    },
    useCpfMask: {
      type: Boolean,
      default: true
    },
    users: Array
  },
  methods: {
    handleClick () {
      this.$emit('delete-users')
      this.$emit('close')
    }
  }
}
</script>

<style lang='scss' scoped>
@import '~@/styles/reference';

.delete-users-modal {
  .content {
    margin-top: 24px;
    .selected-users {
      background: #BEC0D3;
      font-size: 12px;
      color: $base-color;
      max-width: 145px;
      width: 100%;
      text-align: center;
      padding: 0 8px;
      border-radius: 8px;
      margin-bottom: 16px;
    }
    .text {
      margin: 16px 0;
      font-size: 16px;
      font-weight: 400;
      color: $base-color;
    }
    .warning {
      margin-top: 24px;
      width: 100%;
      background: rgba(0, 51, 150, 0.4);
      border-radius: 8px;
      padding: 8px;
      .title {
        color: #0A0D1A;
        font-size: 16px;
        font-weight: 700;
        margin-bottom: 0;
        .icon {
          font-size: 20px;
          margin-right: 8px;
        }
      }
      .text {
        margin-top: 8px;
        margin-bottom: 0px;
        color: #0A0D1A;
        font-weight: 600;
      }
    }
    .buttons {
      margin: 24px 0px;
      text-align: right;
      .send {
        margin-left: 16px;
      }
    }
  }
}
</style>
