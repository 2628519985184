<template>
  <section
    class="campaign-units-container"
    v-if="!isLoading"
  >
    <div class="campaign-units-header">
      <c-title
        icon="map-marker"
        icon-size="18"
      >
        Editar unidades participantes
      </c-title>
    </div>

    <campaign-unit-list
      :pagination="addresses"
      :all-selected="allSelected"
      :addresses="addresses.list"
      :search-term="filters.search"
      :active-addresses="selectedAddresses"
      :is-paginating="isPaginating"
      @toggle-address="toggleAddress"
      @paginate-list="paginate"
      @search="searchAddresses"
      @select-all="selectAll"
    />

    <div class="campaign-units-actions">
      <c-button
        size="lg"
        class="btn-action"
        @click="$router.push({
          name: 'parceiros-slug-campanhas-campaign',
          query: { activeTab: 'partner' }
        })"
      >
        Cancelar
      </c-button>

      <c-button
        primary
        size="lg"
        icon-size="sm"
        class="btn-action"
        icon="chevron-right"
        @click="submitForm"
      >
        Salvar
      </c-button>
    </div>
  </section>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import Loadable from '@/modules/loadable'
import * as types from '@/store/types'
import debounce from 'debounce'

import CampaignUnitList from '@/components/Campaign/Editing/CampaignUnitList'

const search = debounce(function (filters) {
  this.getAddresses({ ...filters })
}, 300)

export default {
  components: { CampaignUnitList },
  mixins: [ Loadable ],
  data () {
    return {
      isPaginating: false,
      selectedAddresses: [],
      newState: [],
      filters: {
        perk: this.$route.params['slug'] || undefined,
        search: undefined,
        limit: 3,
        page: 1
      }
    }
  },
  computed: {
    ...mapGetters({
      partner: types.PARTNER,
      campaign: types.CAMPAIGN,
      addresses: types.ADDRESS_PAGINATE
    }),
    allSelected () {
      return (this.partner.address || []).length ===
        (this.selectedAddresses || []).length
    }
  },
  methods: {
    ...mapActions({
      getPartner: types.PARTNER,
      getCampaign: types.CAMPAIGN,
      getAddresses: types.ADDRESS_FILTER,
      updateCampaign: types.CAMPAIGN_UPDATE,
      paginateAddresses: types.ADDRESS_PAGINATE
    }),
    async load () {
      await Promise.all([
        this.getAddresses({ ...this.filters }),
        this.getPartner({ slug: this.$route.params['slug'] }),
        this.getCampaign({ slug: this.$route.params['campaign'] })
      ])

      this.selectedAddresses = this.campaign.addresses.map(a => a.id)
    },
    async submitForm () {
      await this.updateCampaign({
        id: this.campaign.id,
        campaign: { addresses: this.selectedAddresses }
      })

      this.$router.push({
        name: 'parceiros-slug-campanhas-campaign',
        query: { activeTab: 'partner' }
      })
    },
    async paginate () {
      this.isPaginating = true
      this.filters.page = this.addresses.current_page + 1

      await this.paginateAddresses({ ...this.filters })

      this.isPaginating = false
    },
    async searchAddresses (term) {
      this.filters.search = term || undefined
      this.filters.page = term ? 1 : this.filters.oage

      if (term.length > 0) { 
        await search.call(this, this.filters) 
      } else { 
        await this.getAddresses({ ...this.filters }) 
      }
    },
    toggleAddress (id) {
      if (this.selectedAddresses.includes(id)) {
        const index = this.selectedAddresses.findIndex(addressId => addressId === id)
        this.selectedAddresses.splice(index, 1)
      } else {
        this.selectedAddresses.push(id)
      }
    },
    async selectAll () {
      if (this.allSelected) {
        this.selectedAddresses = []
      } else {
        this.partner.address.forEach(({ id }) => {
          if (!this.selectedAddresses.includes(id)) { this.selectedAddresses.push(id) }
        })
      }
    }
  }
}
</script>

<style lang="scss">
@import '~@/styles/reference';

.campaign-units-container {
  margin-top: 20px;

  @include desktop { @include container; }
  @media screen and (max-width: 1055px) { padding: 0 20px; }

  & > .campaign-units-actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    padding-top: 25px;
    margin-top: 25px;

    border-top: 2px solid $border-color;

    & > .btn-action:not(:last-child) { margin-right: 10px; }
    & > .btn-action {
      width: 100%;

      @include desktop { max-width: 190px; }
    }
  }
}
</style>
