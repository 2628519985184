<template>
  <new-pwd-container />
</template>

<script>
import NewPwdContainer from '@/containers/Login/NewPwdContainer'

export default {
  components: { NewPwdContainer },
  layout: 'unauthenticated',
  middleware: ['checkToken' ]
}
</script>
