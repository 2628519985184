<template>
  <div :class="classes">
    <div class="side-button">
      <c-button
        class="side-button-toggle"
        size="lg"
        icon="swap-horizontal"
        @click="$emit('side-toggle')"
        :primary="!sideOpen"
        :ghost="sideOpen"
      />
    </div>
    <div class="content">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    sideOpen: {
      type: Boolean,
      default: false
    },
    displayControls: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    classes () {
      return [ 'side-toggle', {
        '-closed': !this.sideOpen
      }]
    }
  }
}
</script>

<style lang="scss">
@import '~@/styles/reference';

.side-toggle {
  z-index: 22;
  position: fixed;
  right: 0;
  top: 0;
  height: 100%;
  min-height: 100vh;
  width: 440px;
  transition: transform 0.3s;
  transform: translateX(0);
	background-color: $background-color;
  box-shadow: 1px 0px 13px -2px rgba(0,0,0,0.75);

	& > .content {
    width: 100%;
    height: 100%;
    transition: opacity 0.3s;
    position: relative;
    overflow: auto;

    & > .close {
      z-index: 15;
      position: absolute;
      top: 10px;
      right: 20px;
    }
	}

  & > .side-button {
    width: 50px;
    height: 100px;
    background-image: url('~/static/img/contorno_draggable.svg');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: right;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: absolute;
    left: 0;
    top: 50%;
    transform: translate(-100%, -100%);
  }

  &.-closed {
    transform: translateX(100%);

    & > .content {
      opacity: 0;
    }
  }
}
</style>
