<template>
  <div data-cy="partner-information-div" class="partner-information">
    <div
      class="about"
      v-if="partner.description"
    >
      <c-title
        size="3"
        icon="info-circle"
      >
        Sobre
      </c-title>
      <c-card class="content">
        <p>{{ partner.description }}</p>
      </c-card>
    </div>

    <unit-list
      :filters="{ campaign: campaignSlug }"
      :partner-has-units="!!(partner.address && partner.address.length)"
    >
      <template slot="action">
        <c-button
          v-if="partner.is_exclusive"
          size="lg"
          icon-size="sm"
          icon="pencil"
          @click="$router.push({
            name: 'parceiros-slug-campanhas-campaign-unidades',
            params: $route.params
          })"
        >
          {{ isDesktop ? 'Editar' : '' }}
        </c-button>
      </template>
    </unit-list>
  </div>
</template>

<script>
import CCard from '@/components/CComponents/CCard'

export default {
  components: {
    UnitList: () => import('@/containers/Unit/UnitList'),
    CCard
  },
  props: {
    partner: {
      type: Object,
      required: true
    },
    campaignSlug: {
      type: String,
      required: true
    }
  }
}
</script>

<style lang="scss">
@import '~@/styles/reference';

.partner-information {
  padding: 10px;
  margin-top: 10px;

  & > .about {
    & > .c-title { margin-bottom: 20px; }
    & > .content {
      padding: 20px;
      margin-bottom: 20px;
    }
  }
}
</style>
