<template>
  <div class="new-campaign">
    <step-count
      class="step-count"
      :current-step="step"
      :steps="steps"
      @step-click="handleUpdateStep('prev')"
    />
    <div class="step-div">
      <step-one
        v-if="step === 1"
        :step-value="stepsData.first"
        v-model="stepsData.first"
      />
      <step-two
        v-if="step === 2"
        v-model="stepsData.second"
        :step-value="stepsData.second"
        :preview-data="stepsData.first"
      />
    </div>
    <div
      class="footer"
    >
      <c-button
        data-cy="cancel-campaign-button"
        class="button cancel-button"
        @click="openCancelModal = true"
      >
        Cancelar
      </c-button>
      <c-button
        data-cy="next-step-button"
        primary
        class="button"
        icon="chevron-right"
        :disabled="!enableNextStep"
        @click="handleUpdateStep('next')"
      >
        {{ continueButtonText }}
      </c-button>
    </div>
    <c-confirm-modal
      :btn-props="{ primary: true }"
      cancel-text="Voltar"
      confirm-text="Cancelar campanha"
      character="embarrassed"
      title="Tem certeza que deseja cancelar a criação da campanha?"
      message="Se cancelar, todas as informações já inseridas não serão processadas."
      :is-opened="openCancelModal"
      @close="openCancelModal = false"
      @confirm="confirmCancel"
    />
    <review-info-modal
      :show-modal="openReviewModal"
      :campaign="campaign"
      :loading="loading"
      @close="openReviewModal = false"
      @confirm="$emit('create-campaign', campaign)"
    />
  </div>
</template>

<script>
import { COMPANY, FEEDBACK, ENGAGEMENT_CAMPAIGNS_STEP } from '@/store/types'
import { mapGetters, mapMutations, mapActions } from 'vuex'
import StepCount from '@/components/General/StepCount'
import StepOne from './NewSteps/StepOne'
import StepTwo from './NewSteps/StepTwo'
import CConfirmModal from '@/components/CComponents/CConfirmModal'
import ReviewInfoModal from '~/components/Engagement/modals/ReviewInfoModal'
import moment from 'moment'

export default {
  components: {
    StepCount,
    StepOne,
    StepTwo,
    CConfirmModal,
    ReviewInfoModal
  },
  props: {
    campaigns: Array,
    loading: Boolean
  },
  data () {
    return {
      openCancelModal: false,
      openReviewModal: false,
      steps: [
        {
          number: 1,
          text: 'Campanha'
        },
        {
          number: 2,
          text: 'Personalização'
        }
      ],
      stepsData: {
        first: {
          title: '',
          description: '',
          value: '',
          start_date: '',
          end_date: ''
        },
        second: {
          image: ''
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      company: COMPANY,
      step: ENGAGEMENT_CAMPAIGNS_STEP
    }),
    validDates () {
      const today = moment()
      const start_date = this.stepsData.first.start_date
      const end_date = this.stepsData.first.end_date
      const formated_start_date = moment(start_date, 'DD/MM/YYYY', true)
      const formated_end_date = moment(end_date, 'DD/MM/YYYY', true)

      const isInitialValid = !start_date || formated_start_date.isValid()
      const isEndValid = !end_date || formated_end_date.isValid()
      const isOrderValid = !start_date || !end_date || formated_start_date.isSameOrBefore(formated_end_date)
      const isAfterToday = (!end_date || formated_end_date.isAfter(today)) && (!start_date || formated_start_date.isAfter(today.subtract(1, 'days')))
      const isEndDateFilledAndStarDateEmpty = end_date.length > 0 && start_date.length === 0
      return isInitialValid && isEndValid && isOrderValid && isAfterToday && !isEndDateFilledAndStarDateEmpty
    },
    campaign () {
      return {
        ...this.stepsData.first,
        ...this.stepsData.second
      }
    },
    enableNextStep () {
      let enable = true
      Object.keys(this.stepsData).forEach((key, index) => {
        if (this.step === (index + 1)) {
          enable = this.insideObject(key)
        }
      })
      return enable && this.validDates
    },
    continueButtonText () {
      if (this.step === 1) return 'Continuar'
      else return 'Criar campanha'
    }
  },
  created () {
    this.updateStep(1)
  },
  methods: {
    ...mapMutations({
      updateStep: ENGAGEMENT_CAMPAIGNS_STEP
    }),
    ...mapActions({
      feedback: FEEDBACK
    }),
    handleAnalytics (event, data) {
      window.dataLayer.push(
        {
          event,
          ...data
        }
      )
    },
    confirmCancel () {
      this.handleAnalytics('pontos-campanha-criacao-abandonar')
      this.feedback({
        type: 'warning',
        title: 'Campanha cancelada'
      })
      this.$router.push({ name: 'engajamento-campanhas' })
    },
    insideObject (obj) {
      const { [obj]: stepsDataObj } = this.stepsData
      const hasValidValue = value => !(value === 0 || value === '' || value === null || value.length === 0 || value < 0 || value === '0')

      for (const key in stepsDataObj) {
        const value = stepsDataObj[key]

        if (!hasValidValue(value) && (key !== 'start_date') && (key !== 'end_date')) {
          return false
        }
      }

      return true
    },
    handleUpdateStep (func) {
      if (func === 'next') {
        if (this.step === 2) {
          this.handleAnalytics(`pontos-campanha-criacao-passo${this.step}`)
          this.openReviewModal = true
        } else {
          this.handleAnalytics(`pontos-campanha-criacao-passo${this.step}`)
          this.updateStep(2)
        }
      } else {
        this.handleAnalytics(`pontos-campanha-criacao-passo${this.step}`)
        this.updateStep(1)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/styles/reference';

.new-campaign {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  max-width: 1020px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;

  > .step-count {
    margin-top: 30px;
  }

  > .step-div {
    width: 100%;
    height: 100%;
    display: block;
  }
  & >.footer {
    width: 100%;
    justify-content: flex-end;
    display: flex;
    padding-top: 25px;

    & >.button {
      margin: 0 5px;
      width: 180px;
      height: 40px;
      border-radius: 30px;

      &.cancel-button {
        background: #FFFFFF;
        border: 1px solid #5E6684;
        border: 1px solid var(--color-secondary);
      }
    }
  }
}
</style>
