<template>
  <c-modal
    v-bind="$attrs"
    v-on="$listeners"
    :is-opened="showModal"
    :show-close="false"
    class="trial-onboarding-modal"
    name="trial-onboarding-modal"
  >
    <div class="modal-content">
      <div class="header">
        <h3>
          Boas-vindas ao painel do gestor!
        </h3>
      </div>
      <div class="content">
        <p>
          Aqui você poderá controlar tudo o que acontece no seu
          clube! Convide novos membros, gerencie os seus parceiros e
          ofertas e verifique como está o engajamento da sua base
          de usuários!
        </p>
      </div>
      <hr />
      <div class="footer">
        <div />
        <button
          class="driver-popover-next-btn"
          @click="initOnboarding()"
        >
          Próximo
        </button>
      </div>
    </div>
  </c-modal>
</template>

<script>
import { driver } from 'driver.js'
import 'driver.js/dist/driver.css'
import CModal from '@/components/CComponents/CModal'
import {
  USER_PANEL_ONBOARDING,
  USER
} from '@/store/types'
import { mapGetters, mapActions } from 'vuex'

const driverObj = driver({
  overlayOpacity: 0.4,
  popoverClass: 'trial-onboarding-popover',
  showProgress: true,
  showButtons: ['next'],
  allowClose: false,
  nextBtnText: 'Próximo',
  prevBtnText: 'Voltar',
  doneBtnText: 'Próximo',
  progressText: '{{current}}/5',
  onDestroyed: () => {
    window.dispatchEvent(new Event('show-last-onboarding-step'))
  },
  steps: [
    {
      element: '#menu-item-0',
      popover: { description: 'Aqui você poderá gerenciar os seus usuários, parceiros e campanhas do seu clube', side: 'bottom', align: 'center' }
    },
    {
      element: '#menu-item-1',
      popover: { description: 'Aqui você poderá distribuir prêmios para a sua base de usuários. Adicione saldo nas contas de seus usuários e deixe que eles utilizem em uma de nossas centenas de opções', side: 'bottom', align: 'center' }
    },
    {
      element: '#menu-item-2',
      popover: { description: 'Aqui você poderá criar o seu sistema de pontos. Defina o nome da sua moeda, crie campanhas e engaje os seus usuários em ações do dia-a-dia', side: 'bottom', align: 'center' }
    },
    {
      element: '#menu-item-3',
      popover: { description: 'Por fim, aqui você irá acompanhar todo o desempenho da sua plataforma em nossos poderosos relatórios que irão dar detalhes sobre cada ação dos seus usuários', side: 'bottom', align: 'center' }
    }
  ]
})

export default {
  components: { CModal },
  data () {
    return {
      showModal: true,
      driver: driverObj,
      auxiliarDriver: driverObj
    }
  },
  computed: {
    ...mapGetters({
      user: USER
    })
  },
  created () {
    this.$bus.$on('close-trial-onboarding', () => {
      this.destroyOnboarding()
    })
    window.addEventListener(
      'finish-onboarding', async () => {
        await this.finishOnboarding()
        await this.getUser(this.user.id)
      }
    )
    window.addEventListener(
      'show-last-onboarding-step', () => {
        this.$bus.$emit('close-sidebar-menu')
        this.highlightLastStep()
      }
    )
  },
  beforeDestroy () {
    window.removeEventListener(
      'finish-onboarding', async () => {
        await this.finishOnboarding()
        await this.getUser(this.user.id)
      }
    )
    window.removeEventListener(
      'show-last-onboarding-step', () => {
        this.$bus.$emit('close-sidebar-menu')
        this.highlightLastStep()
      }
    )
  },
  methods: {
    ...mapActions({
      finishOnboarding: USER_PANEL_ONBOARDING
    }),

    initOnboarding () {
      this.showModal = false
      if (!this.isDesktop) {
        this.$emit('open-menu')
      }
      this.driver.drive()
    },
    destroyOnboarding () {
      this.driver.destroy()
      this.auxiliarDriver.destroy()
    },
    highlightLastStep () {
      this.auxiliarDriver.highlight({
        element: '#missions-button',
        popover: {
          description: 'Clique aqui para visualizar as suas missões e receber um prêmio especial após cumprir tudo </br> </br> <span class="driver-popover-progress-text" style="display: block;">5/5</span>', side: 'bottom', align: 'center'
        },
        onDeselected: () => {
          window.dispatchEvent(new Event('finish-onboarding'))
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~@/styles/reference';

.trial-onboarding-modal {
  background-color: #FFF !important;
  z-index: 30 !important;

  :deep .c-modal {
    padding: 20px 0 20px 0 !important;
  }

  > .content {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    padding-bottom: 20px;

    @include mobile { justify-content: center; }

    .modal-content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 350px;

      @include mobile { height: 100%; }

      .title {
        color: #202950;
        text-align: center;
        font-size: 24px;
        font-weight: 500;
        opacity: 0.9;
        margin-bottom: 8px;
      }

      .description {
        color: #202950;
        text-align: center;
        font-size: 16px;
        font-weight: 400;
        margin-bottom: 24px;
      }

      .action-button {
        height: 56px;
        border-radius: 8px;
        background:  #FFD200;
        color: #202950;
        box-shadow: 0px 4px 8px 0px rgba(140, 146, 177, 0.24);

        &:hover {
          opacity: 0.8;
        }
      }
      .header {
        padding: 20px;
        width: 100%;
        height: 120px;
        color: white;
        background: $base-color;
        display: flex;
        justify-content: center;
        align-items: center;

        h3 {
          font-size: 28px;
          text-align: center;
        }
      }
      .content {
        display: flex;
        justify-content: center;
        align-items: flex-start;
        height: 100%;
        margin-top: 20px;
        padding: 20px;

        p {
          text-align: center;
          font-size: 16px;
          color: #2d2d2d;
        }
      }
      .footer {
        padding: 0 20px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;

        span {
          font-size: 13px;
          font-weight: 400;
          color: #727272;
        }
      }
    }
  }
}

</style>

<style lang="scss">
@import '~@/styles/reference';
.trial-onboarding-popover {
  font-size: 16px;
  .driver-popover-next-btn {
    width: 90px;
    height: 42px;
    box-shadow: none;
    font-family: $title-font-family;
    font-size: 14px;
    border: 0;
    border-radius: 8px;
    cursor: pointer;
    font-weight: 500;
    text-shadow: none;
    text-align: center;
    display: inline-block;
    background: $base-color;
    color: white;
    line-height: 1;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      background: #8C92B1;
    }
    &:focus {
      background: $base-color;
    }
  }
}

.driver-popover-next-btn {
  width: 90px;
  height: 42px;
  box-shadow: none;
  font-family: $title-font-family;
  font-size: 14px;
  border: 0;
  border-radius: 8px;
  cursor: pointer;
  font-weight: 500;
  text-shadow: none;
  text-align: center;
  display: inline-block;
  background: $base-color;
  color: white;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background: #8C92B1;
  }
  &:focus {
    background: $base-color;
  }
}
</style>
