<template>
  <c-input
    required
    validation-icon="warning-circle"
    class="campaign-information-important"
    textarea
    rows="10"
    maxlength="1000"
    :value="parseImportant"
    data-vv-validate-on="blur"
    @input="v => emitter('important', v)"
    data-vv-delay="10"
    name="informações importantes"
    v-validate="'required'"
    :feedback-show="errors.has('informações importantes')"
    :feedback-message="errors.first('informações importantes')"
  />
</template>

<script>
export default {
  props: {
    parseImportant: String
  },

  methods: {
    emitter (key, value) {
      setTimeout(() => {
        this.$emit('update', { [key]: value, error: this.errors.all() })
      }, 50)
    }
  }
}
</script>
<style lang="scss">
@import '~@/styles/reference';

.campaign-information-important {
  & > .feedback {
    position: unset;
    padding-bottom: 5px;
  }
}
</style>
