<template>
  <c-modal
    v-bind="$attrs"
    v-on="$listeners"
    class="award-order-info-modal"
    name="award-order-info-modal"
  >
    <c-title data-cy="awards-order-modal-info-title">
      <k-icon
        class="icon"
        icon="award-star"
      />
      Pedido de premiação
    </c-title>
    <div class="modal-content">
      <p>
        Crie um pedido de premiação para selecionar usuário(s) e valore(s) para premiar. <br /><br />
        É possível inserir este novo pedido em uma campanha já existente ou criar uma nova campanha e personalizar a comunicação.
      </p>
    </div>
    <div
      class="modal-footer"
      @click="$emit('link-click')"
    >
      Campanhas de premiação
      <k-icon
        class="icon"
        icon="question"
      />
    </div>
  </c-modal>
</template>

<script>
import { KIcon } from 'kaledo-components'
import CModal from '@/components/CComponents/CModal'

export default {
  components: { KIcon, CModal }
}
</script>

<style lang="scss">
@import '~@/styles/reference';

.award-order-info-modal {
  background-color: #FFF !important;
  z-index: 30 !important;

  > .content {
    display: flex;
    align-items: flex-start;
    flex-direction: column;

    @include mobile { justify-content: center; }

    .c-title {
      margin-bottom: 30px;

      .icon {
        font-size: 20px;
        margin-right: 10px;
      }

    }

    .modal-content {
      display: flex;
      flex-direction: column;

      > p {
        font-weight: 500;
        font-size: 16px;
        line-height: 20px;
        color: #202950;
        margin-bottom: 10px;
      }
    }

    .modal-footer {
      display: flex;
      align-items: center;
      margin-top: 20px;
      font-weight: 400;
      font-size: 13px;
      color: #5E6684;
      cursor: pointer;

      .icon {
        font-size: 20px;
        margin-left: 10px;
        color: #202950;
      }
    }
  }
}
</style>
