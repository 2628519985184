<template>
  <div :class="isWallet ? 'wallet-height historic-card' : 'historic-card'">
    <details-card
      title="Histórico"
    >
      <template
        #content
      >
        <div
          class="historic"
          v-if="status !== 0"
        >
          <span
            class="step"
            v-for="(step, i) in adjustedSteps"
            :class="getStatusClass(i+1)"
            :key="step"
          >
            {{ step }}
          </span>
        </div>
        <div
          class="historic -canceled"
          v-else
        >
          <span
            class="step"
            :class="`-canceled-status-${i}`"
            v-for="(step, i) in adjustedCanceledSteps"
            :key="step"
          >
            {{ step }}
          </span>
        </div>
      </template>
    </details-card>
  </div>
</template>

<script>
import DetailsCard from './DetailsCard'

export default {
  components: {
    DetailsCard
  },
  props: {
    isAwards: Boolean,
    isWallet: Boolean,
    status: Number
  },
  computed: {
    currentStep () {
      switch (this.status) {
      case 0:
        return 0
      case 1:
        return 6
      case 2:
        return 2
      case 3:
        return 3
      case 4:
        return 4
      default:
        return 1
      }
    },
    steps () {
      if (this.isWallet) {
        return [
          'Pedido realizado',
          'Aguardando pagamento',
          'Pedido aprovado'
        ]
      } else {
        return [
          'Pedido realizado',
          'Aguardando liberação interna',
          'Aguardando pagamento',
          'Entrega agendada'
        ]
      }
    },
    canceledSteps () {
      if (this.isWallet) {
        return [
          'Pedido realizado',
          'Pedido cancelado',
          'Pedido aprovado'
        ]
      } else {
        return [
          'Pedido realizado',
          'Pagamento não realizado',
          'Pedido cancelado',
          'Entrega agendada'
        ]
      }
    },
    adjustedSteps () {
      let lastItem = this.isAwards ? 'Entrega do prêmio' : 'Pedido entregue'
      if (this.isWallet) lastItem = 'Saldo alterado'
      return [...this.steps, lastItem]
    },
    adjustedCanceledSteps () {
      let lastItem = this.isAwards ? 'Entrega do prêmio' : 'Pedido entregue'
      if (this.isWallet) lastItem = 'Saldo alterado'
      return [...this.canceledSteps, lastItem]
    }
  },
  methods: {
    getStatusClass (status) {
      if (status === this.currentStep) {
        return '-current'
      } else if (status < this.currentStep) {
        return '-concluded'
      } else {
        return '-waiting'
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.wallet-height {
  height: 219px !important;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.16);
}
.historic-card {
  height: 225px;

  .details-card {
    :deep .details-content {
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;

      .historic {
        width: 100%;
        display: flex;
        flex-direction: column;
        margin-top: 15px;
        &.-canceled {
          height: 100%;
        }

        .step {
          font-weight: 700;
          font-size: 14px;
          color: #D8D8D8;
          display: flex;
          align-items: center;

          &::before {
            content: '';
            display: inline-block;
            width: 12px;
            height: 12px;
            background: #D8D8D8;
            border-radius: 30px;
            margin-right: 15px;
          }

          &.-concluded::before {
            background: linear-gradient(180deg, #3CE797 0%, #4CAD50 100%);
          }

          &.-current {
            color: #202950;
            &::before {
              background: linear-gradient(180deg, #FFC330 0%, #F5A623 100%);
            }
          }
          &.-canceled-status-0 {
            &::before {
              background: linear-gradient(180deg, #3CE797 0%, #4CAD50 100%);
            }
          }
          &.-canceled-status-1, &.-canceled-status-2 {
            color: #202950;
            &::before {
              background: linear-gradient(180deg, #ee5250 0%, #ee5250 100%);
            }
          }
          &:not(:first-child) {
            margin-top: 15px;
          }
        }
      }
    }
  }
}
</style>
