<template>
  <user-list></user-list>
</template>

<script>
import UserList from '~/containers/User/Listing/UserList'
export default {
  components: { UserList }
}
</script>

<style>

</style>
