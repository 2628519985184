<template>
  <div class="loading-bar" />
</template>

<script>
export default {
  name: 'LoadingBar'
}
</script>

<style lang="scss" scoped>
  .loading-bar {
    width: 100%;
    max-width: 80px;
    height: 16px;
    filter: blur(2px);
    animation-duration: 1.5s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeHolderShimmer;
    animation-timing-function: linear;
    background: #f6f7f8;
    background: linear-gradient(to right, #fafafa 8%, #e7e5e5 38%, #f8f8f8 54%);
    background-size: 1000px 640px;
    position: relative;
  }

  @keyframes placeHolderShimmer{
    0%{
      background-position: -468px 0
    }
    100%{
      background-position: 468px 0
    }
  }
</style>
