<template>
  <under-construction-card
    v-if="underConstruction"
    :tab-name="tabName"
  />
  <div
    v-else
    class="dashboard-partners-tab"
  >
    <div class="first-cards">
      <partners-card
        class="partners-tab-card"
        :loading="loading"
      />
      <graph-card
        class="partners-tab-donut"
        :tabs="categoriesData.tabs"
        :graph-data="categoriesData"
        :loading="loading"
      />
    </div>
    <offers-info :loading="loading" />
    <campaigns-card
      class="partners-campaign-card"
      :loading="loading"
    />
    <highlight-campaigns-card />
  </div>
</template>

<script>
import PartnersCard from '@/components/Dashboard/cards/PartnersCard.vue'
import CampaignsCard from '@/components/Dashboard/cards/CampaignsCard.vue'
import HighlightCampaignsCard from '@/components/Dashboard/cards/HighlightCampaignsCard.vue'
import GraphCard from '@/components/Dashboard/base/GraphCard.vue'
import OffersInfo from '@/components/Dashboard/cards/OffersInfo.vue'
import UnderConstructionCard from '@/components/Dashboard/cards/UnderConstructionCard.vue'

export default {
  name: 'DashboardPartnersTab',
  components: {
    PartnersCard,
    GraphCard,
    CampaignsCard,
    OffersInfo,
    HighlightCampaignsCard,
    UnderConstructionCard
  },
  props: {
    underConstruction: Boolean,
    tabName: String
  },
  data () {
    return {
      loading: false,
      categoriesData: {
        title: 'Categorias',
        info: {
          title: 'Categorias',
          text: 'Categorias mais acessadas e com mais resgates'
        },
        tabs: [
          'Mais Acessos',
          'Mais Resgates'
        ],
        columns: [
          [
            ['data1', 25, 20],
            ['data2', 24, 1.5],
            ['data3', 13, 15],
            ['data4', 14, 9],
            ['data5', 12, 8],
            ['data6', 12, 10]
          ],
          [
            ['data1', 30, 20],
            ['data2', 21, 1.5],
            ['data3', 12, 15],
            ['data4', 19, 9],
            ['data5', 10, 8],
            ['data6', 9, 10]
          ]
        ],
        names: {
          data1: 'Produtos e Serviços',
          data2: 'Item de leg c/ ícone',
          data3: 'Bem-estar e saúde',
          data4: 'Alimentos e bebidas',
          data5: 'Mercados',
          data6: 'Educação'
        },
        icons: [
          'bag-shopping-circle',
          'album',
          'fork-spoon',
          'heart-pulse',
          'graduation',
          'package-box-delivery-square'
        ],
        comparisonPeriod: {
          period_1: {
            start: 1666200980,
            end: 1666200989
          },
          period_2: {
            start: 1666201419,
            end: 1666201437
          }
        },
        comparisonValues: {
          data1: 25,
          data2: 1,
          data3: 10,
          data4: 8,
          data5: 11,
          data6: 10
        },
        comparisonResults: {
          data1: 0.1,
          data2: -0.3,
          data3: -10,
          data4: 0,
          data5: 6,
          data6: 2
        }
      }
    }
  },
  mounted () {
    this.loading = true
    setTimeout(() => {
      this.loading = false
    }, 5000)
  }
}
</script>

<style lang="scss" scoped>
@import '~@/styles/reference.scss';

.dashboard-partners-tab {
  margin-top: 36px;
  .first-cards {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    flex-wrap: wrap;
    >.partners-tab-card {
      margin-right: 12px;
      @include mobile {
        margin-right: 0px;
      }
    }
    >.partners-tab-donut {
      height: 539px;
      width: 334px;
      @include mobile {
        width: 100%;
      }
    }
  }
  .partners-campaign-card {
    margin-bottom: 24px;
  }
}
</style>
