<template>
  <div>
    <div class="media-header">
      <c-title-progress
        title="Mídias/limite de mídias"
        :active="numberPartners"
        :total="3"
        :percentage="percentage"
      />

      <div
        class="header-actions"
      >
        <div
          class="wrapper-search-field"
        >
          <search-field
            :term="searchTerm"
            placeholder="Encontre uma mídia"
            @reset="$emit('clear-search')"
            @input="name => $emit('change-search', name)"
            @submit="$emit('search')"
            @focusout="handleAnalytics('buscar-midia', { company_name: company.name, searched_term: searchTerm })"
          />
        </div>

        <c-button
          v-if="isDesktop"
          ref="orderPopover"
          after
          size="lg"
          icon="chevron-down"
          icon-size="sm"
          @click.native="showOrder = !showOrder"
        >
          Ordenar
        </c-button>
        <div class="buttons-group">
          <c-button
            v-if="!isDesktop"
            @click="$router.push({ name: 'comunicacao-criar' })"
            icon="plus"
            size="lg"
            icon-size="sm"
            primary
            class="create-button"
          >
            Nova mídia
          </c-button>

          <c-button
            ref="filterToggle"
            size="lg"
            :icon="isDesktop ? 'chevron-down' : ''"
            icon-size="sm"
            after
            @click.native="filterAction"
            class="filter-button"
          >
            Filtrar
          </c-button>
        </div>
      </div>
    </div>

    <div class="header-popovers">
      <order-popover
        :show="showOrder"
        :ordinators="ordenators"
        :target="$refs['orderPopover']"
        :params="params"
        @handleOrder="handleOrder($event)"
        @apply-filters="applyOrderFilter(params)"
        @change-show="showOrder = $event"
        @clean="clean($event)"
      />

      <filter-popover
        :show="showFilter"
        :categories="false"
        :target="$refs['filterToggle']"
        :params="params"
        :status="status"
        @handleStatus="handleStatus"
        @change-show="showFilter = $event"
        @apply-filters="applyFilters(params)"
        @clean="clean($event)"
      />
    </div>

    <filter-menu
      :filters="filters"
      :show-categories="false"
      :is-opened="isFiltersOpened"
      @apply-filters="$emit('apply-filters', params)"
      @update-filters="updateFilters"
      @close="isFiltersOpened = false"
      @clean="clean()"
    />
  </div>
</template>

<script>
import CTitleProgress from '@/components/CComponents/CTitleProgress.vue'
import SearchField from '@/components/CComponents/SearchField'
import FilterPopover from '@/components/Partners/Listing/FilterPopover.vue'
import OrderPopover from '@/components/Partners/Listing/OrderPopover.vue'
import FilterMenu from '@/components/Filter/FilterMenu'

import ordinators from '@/store/filters/ordinators.json'
import filters from '@/store/filters/filters.json'
import { reduce } from '@/modules/object'

import { mapGetters } from 'vuex'
import * as types from '@/store/types'

export default {
  name: 'MediaHeader',

  components: {
    CTitleProgress,
    SearchField,
    FilterPopover,
    OrderPopover,
    FilterMenu
  },

  props: {
    searchTerm: String,
    numberPartners: Number
  },

  data () {
    return {
      showOrder: false,
      showFilter: false,
      isFiltersOpened: false,
      filterValue: '',
      filters: {
        asc: null,
        desc: null,
        status: ''
      },
      ordenators: [],
      status: [],
      params: {}
    }
  },

  computed: {
    ...mapGetters({ company: types.COMPANY }),
    percentage () {
      const percent = (this.numberPartners / 3) * 100
      return percent.toString()
    }
  },

  created () {
    this.status = filters.status
    this.ordenators = ordinators
    this.params = {
      ...this.getActions(filters.status),
      ...this.getActions(this.ordenators)
    }
  },

  methods: {
    applyFilters (params) {
      this.$emit('apply-filters', params)
      this.handleAnalytics('filtrar-midia', {
        company_name: this.company.name
      })
    },
    applyOrderFilter (params) {
      this.$emit('apply-filters', params)
      this.handleAnalytics('ordenar-midia', {
        company_name: this.company.name
      })
    },
    handleStatus (value) {
      this.params[value] = true
      if (value === '0') this.params[1] = false
      else if (value === '1') this.params[0] = false
    },

    handleOrder (value) {
      this.params[value] = true
      if (value === 'asc') this.params.desc = false
      else if (value === 'desc') this.params.asc = false
    },

    updateFilters (property) {
      if (property.status) {
        if (property.status === '0') {
          this.params[0] = true
          this.params[1] = false
        } else if (property.status === '1') {
          this.params[1] = true
          this.params[0] = false
        }
      } else {
        const keys = Object.keys(property)
        for (const key in this.params) {
          if (keys.indexOf(key) > -1) {
            if (property[key] === 'name') this.params[key] = true
            else this.params[key] = false
          }
        }
      }
    },

    filterAction () {
      if (this.isDesktop) this.showFilter = !this.showFilter
      else this.isFiltersOpened = !this.isFiltersOpened
    },

    clean () {
      this.params = {
        ...this.getActions(filters.status),
        ...this.getActions(this.ordenators)
      }
      this.$emit('apply-filters', this.params)
    },

    getActions (filters) {
      const actions = filters.map((_) => _.action)
      return reduce(actions, (_, property) => ({ [property]: false }))
    },
    handleAnalytics (event, data) {
      window.dataLayer.push(
        {
          event: event,
          ...data
        }
      )
    }
  }
}
</script>

<style lang='scss' scoped>
@import '~@/styles/reference';

.media-header {
  display: flex;

  @include mobile {
    flex-direction: column;
  }

  >.header-actions {
    margin-left: 30px;
    display: flex;
    align-items: center;
    order: 1;
    width: 100%;

    @include mobile {
      padding-left: 10px;
      padding-right: 10px;
      margin-left: 0;
      margin-top: 20px;
      flex-wrap: wrap;
    }
    .c-button:not(:first-child) { margin-left: 10px; }
  }

  .wrapper-search-field {
    @include mobile {
      order: 3;
      flex-grow: 1;
      margin-top: 10px;
    }
  }
  .buttons-group {
    margin-left: 10px;
    @include mobile {
      margin-left: 0;
      display: flex;
      width: 100%;
      >.create-button, >.filter-button {
        width: 100%
      }
    }
  }

  .header-popovers > .c-popover > .content > .filters {
    @include popoverFilter;
    min-width: 390px;
  }
}
</style>
