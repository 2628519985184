<template>
  <div class="campaign-information-voucher">
    <h5 class="voucher">
      {{ voucher }}
    </h5>
    <c-button
      class="button campaign-information-voucher-copy"
      size="lg"
      primary
      @click="notify"
      :data-clipboard-text="voucher"
    >
      Copiar
    </c-button>
  </div>
</template>

<script>
import Clipboard from 'clipboard'

export default {
  props: {
    voucher: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      clipboard: undefined
    }
  },
  mounted () {
    this.clipboard = new Clipboard('.campaign-information-voucher-copy')
  },
  beforeDestroy () {
    this.clipboard.destroy()
  },
  methods: {
    notify () {
      const feedback = {
        title: 'Sucesso!',
        text: 'O cupom foi copiado',
        type: 'success'
      }
      this.$emit('copy', feedback)
    }
  }
}
</script>

<style lang="scss">
@import '~@/styles/reference';

.campaign-information-voucher {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  border: 1px solid $border-color;
  border-radius: 4px;

  & > .voucher {
    padding: 0 15px;

    letter-spacing: 0.8px;
    text-transform: uppercase;
    color: $title-color;
    font-weight: 900;
  }
  & > .c-button.button {
    border-radius: 0 4px 4px 0;
  }
}
</style>
