<template>
  <notifications-container />
</template>

<script>
import NotificationsContainer from '@/containers/Communication/Notifications/NotificationsContainer.vue'
export default {
  components: { NotificationsContainer }
}
</script>
