<template>
  <div
    data-cy="award-campaign-card"
    class="award-campaign-card"
    :class="selected ? '-selected' : ''"
    v-if="campaign"
    @click="$emit('select')"
  >
    <div class="campaign-image-div">
      <input
        v-if="hasCheckbox"
        class="campaign-checkbox"
        type="checkbox"
        :checked="selected"
      />
      <img
        class="campaign-image"
        :src="campaign.image"
        alt="Imagem do tipo de campanha"
      />
    </div>
    <h2 class="campaign-title">
      {{ campaign.title }}
    </h2>
    <div class="campaign-objective">
      <h3 class="title">
        Objetivo:
      </h3>
      <span class="description">
        {{ campaign.reason }}
      </span>
    </div>
    <p class="campaign-description">
      {{ formatedText(campaign.description) }}
    </p>
    <k-button
      data-cy="campaign-action-button"
      class="new-order-button"
      no-icon
      @click.native.stop="$emit('buttonEvent')"
    >
      {{ cardText }}
    </k-button>
  </div>
  <div
    class="empty-campaign-card"
    v-else-if="campaign === null"
  >
    <h2>
      Nova campanha
    </h2>
    <k-icon icon="plus-add3" />
  </div>
</template>

<script>
import { KButton, KIcon } from 'kaledo-components'

export default {
  components: {
    KButton,
    KIcon
  },
  props: {
    campaign: Object,
    hasCheckbox: Boolean,
    selected: Boolean,
    text: {
      type: String,
      default: ''
    }
  },
  computed: {
    cardText () {
      if (this.text === '') return 'Adicionar pedido'
      else return this.text.charAt(0).toUpperCase() + this.text.slice(1).toLowerCase()
    }
  },
  methods: {
    formatedText (text) {
      return text.length > 80 ? `${text.substring(0, 80)}...` : text
    }
  }
}
</script>

<style lang="scss" scoped>
.empty-campaign-card, .award-campaign-card {
  cursor: pointer;
  background: #FDFDFD;
  width: 250px;
  height: 323px;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.16);
  transition: all 0.3s ease;
  will-change: border;
}

.empty-campaign-card {
  border: 2px solid rgba(190, 192, 211, 1);
  transition: all 0.3s ease;

  &:hover {
    background: #F8F9FD;
  }
}

.award-campaign-card {
  padding: 8px;
  justify-content: space-between;
  text-align: left;
  border: 1px solid #FDFDFD;

  &:hover {
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.16);
    border-color: rgba(190, 192, 211, 1);
  }

  &.-selected {
    border-color: rgba(94, 102, 132, 1);
  }

  .campaign-image-div {
    position: relative;
  }

  .campaign-checkbox {
    position: absolute;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    top: 11px;
    left: 12px;
    height: 25px;
    width: 25px;
    background-color: #FDFDFD;
    border-radius: 2px;
    outline: 4px solid white;
    cursor: pointer;
  }

  .campaign-image {
    width: 230px;
    height: 103px;
    border-radius: 2px;
  }

  .campaign-title {
    color: #202950;
    font-size: 16px;
    line-height: 20px;
    text-align: left;
    width: 100%;
  }

  .campaign-objective {
    width: 100%;
    .title {
      font-size: 12px;
      color: #5E6684;
    }
    .description {
      font-size: 14px;
      color: #202950;
    }
  }

  .campaign-description {
    font-size: 12px;
    color: #5E6684;
  }

  .new-order-button {
    width: 230px;
    height: 40px;
    background: #FDFDFD;
    color: #202950;
    border: 1px solid rgba(190, 192, 211, 1);
    transition: all .1s ease;
    text-transform: initial;

    &:focus {
      border: 1px solid rgba(94, 102, 132, 1);
    }

    &:hover {
      background: #FDFDFD;
      border: 2px solid rgba(32, 41, 80, 1);
      border: 2px solid var(--color-secondary);
    }
  }
}
</style>
