<template>
  <c-input-container
    v-bind="$attrs"
    class="c-radio"
  >
    <div
      v-for="option in options"
      :key="option.value"
      class="option"
      :class="customClasses"
      @click="internalValue = option.value"
      data-cy="c-radio"
    >
      <label :class="getClasses(option)">
        <input
          v-model="internalValue"
          :style="{ display: 'none' }"
          type="radio"
          @click.prevent
        />
      </label>

      <div class="text">
        {{ option.name }}
      </div>
    </div>
  </c-input-container>
</template>

<script>
import CInputContainer from '@/components/CComponents/CInputContainer'

export default {
  components: {
    CInputContainer
  },
  props: {
    options: Array,
    value: [ String, Number, Boolean ],
    inline: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    internalValue: {
      set (value) {
        this.$emit('input', value)
      },
      get () {
        return this.value
      }
    },
    customClasses () {
      const customClasses = [{
        '-inline-radio': this.inline
      }]
      return customClasses
    }
  },
  methods: {
    getClasses (option) {
      const isActive = this.internalValue === option.value
      return [ 'radio', { '-active': isActive } ]
    }
  }
}
</script>

<style lang="scss">
@import '~@/styles/reference';

$c-radio-size: 24px;

.c-radio {
  display: flex;
  flex-flow: wrap;

  & > .-inline-radio {
    cursor: pointer;
  }

  & > .option {
    display: inline-flex;
    flex-flow: row wrap;
    border: 1px solid $border-color;
    background-color: white;
    height: 40px;
    padding: 0 11px;
    border-radius: 99px;
    align-items: center;
    margin-bottom: 30px;
    margin-right: 5px;

    & > .radio {
      height: $c-radio-size;
      width: $c-radio-size;
      flex: $c-radio-size 0 0;
      border: 1px solid $border-color;
      border-radius: 100%;
      position: relative;
      background-color: white;
      display: inline-block;

      &:before {
        content: '';
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        background-color: $secondary-color-placeholder;
        background-color: var(--color-secondary);
        height: 0px;
        width: 0px;
        border-radius: 100%;
        transition: height .15s ease,
          width .15s ease;
      }

      &.-active:before {
        height: 16px;
        width: 16px;
      }
    }

    & > .text {
      font-size: 12px;
      margin-left: 12px;
      color: $text-color;
      flex: 1 1 0;
      display: flex;
      align-items: center;
    }
  }
}
</style>
