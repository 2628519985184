<template>
  <transition-group
    v-if="isOpened"
    name="c-modal-fade"
    appear
  >
    <c-modal-mask
      @close="emit(true)"
      show
      :key="actionText + 'mask'"
    />

    <div
      :class="classes"
      :key="name + 'modal'"
      data-cy="c-modal"
    >
      <div class="content">
        <slot />
      </div>

      <c-button
        v-if="showClose"
        size="lg"
        icon="cross"
        class="close"
        @click="emit()"
        data-cy="close-c-modal"
        v-bind="btnProps"
      />

      <c-button
        v-if="actionText"
        data-cy="modal-action-button"
        class="modal-action-button"
        icon-size="10"
        :block="!isDesktop"
        :primary="isDesktop"
        :icon="actionIcon"
        :to="actionExternalLink || null"
        :external="!!actionExternalLink"
        :target="actionExternalTarget"
        :download="actionExternalDownload || null"
        @click="$emit('action')"
      >
        {{ actionText }}
      </c-button>
    </div>
  </transition-group>
</template>

<script>
import CModalMask from '@/components/CComponents/CModalMask'

export default {
  components: {
    CModalMask
  },

  props: {
    name: {
      type: String,
      required: true
    },
    isOpened: Boolean,
    isFullscreen: {
      type: Boolean,
      default: true
    },
    isChangeAccount: {
      type: Boolean,
      default: false
    },
    actionExternalDownload: String,
    actionExternalLink: String,
    actionExternalTarget: String,
    actionText: String,
    feedback: {
      type: Boolean
    },
    showClose: {
      type: Boolean,
      default: true
    },
    actionIcon: {
      type: String,
      default: 'chevron-right'
    },
    btnProps: {
      type: Object,
      default: () => ({ })
    },
    closeOnOutsideClick: {
      type: Boolean,
      default: true
    }
  },

  computed: {
    classes () {
      return [
        'c-modal',
        this.name, {
          '-fullscreen': this.isFullscreen,
          '-has-action': this.actionText,
          '-feedback': this.feedback,
          '-is-change-account': this.isChangeAccount
        }]
    }
  },

  watch: {
    isOpened (v, old) {
      if (v === old) { return }
      this.checkOverflow()
    }
  },

  mounted () {
    this.checkOverflow()
  },

  beforeDestroy () {
    this.close()
  },

  methods: {
    open () {
      const styles = {
        overflow: 'hidden',
        position: 'relative',
        height: '100%'
      }
      Object.assign(document.body.style, styles)
      Object.assign(document.documentElement.style, styles)
    },

    close () {
      const styles = {
        overflow: '',
        position: '',
        height: ''
      }
      Object.assign(document.body.style, styles)
      Object.assign(document.documentElement.style, styles)
    },

    emit (isModalMaskAction) {
      if (isModalMaskAction && this.closeOnOutsideClick || !isModalMaskAction) {
        this.$emit('close')
        this.close()
      }
    },

    checkOverflow () {
      if (this.isOpened) { this.open() } else { this.close() }
    }
  }
}
</script>

<style lang="scss">
@import '~@/styles/reference';

$c-modal-spacing: 10px;
$c-modal-color: #ffffff;
$c-modal-height: 40px + $c-modal-spacing * 2;

.c-modal {
  position: fixed;
  display: flex;
  top: 0;
  right: 0;
  left: 0;
  min-height: $c-modal-height;
  background-color: $c-modal-color;
  z-index: 5;
  overflow: auto;
  max-width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding: 20px;
  border-radius: 3px;

  > .c-button {
    border: 1px solid rgba(94, 102, 132, 1);
    transition: all .1s ease;

    &:hover {
      border: 2px solid rgba(94, 102, 132, 1);
    }
  }

  &.-is-change-account {
    z-index: 5;
    max-width: 712px;
    width: 712px;
    min-height: 510px;
    padding: 50px;
    border-radius: 10px;
    overflow: hidden;

    &::before {
      content: '';
      position: fixed;
      top: 460px;
      height: 150px;
      width: 712px;
      border-bottom-right-radius: 20px;
      border-bottom-left-radius: 20px;
      background: linear-gradient(to bottom, rgba(255,255,255,0) 20%, #F8F9FD 100%);
      z-index: 20;
    }

    &::after {
      content: '';
      position: fixed;
      top: 260px;
      height: 50px;
      width: 712px;
      border-bottom-right-radius: 20px;
      border-bottom-left-radius: 20px;
      background: linear-gradient(to top, rgba(255,255,255,0) 0%, #FFF 100%);
      z-index: 20;
    }
  }

  &.-has-action {
    @include mobile { padding-bottom: 75px; }
  }

  &.-feedback {
    border-radius: 5px;

    @include mobile {
      max-width: 340px;
      height: 350px;
      top: 25%;
    }

    @include desktop {
      width: 500px;
      min-height: 300px;
      max-height: calc(100% - 20px)

    }
  }

  @include desktop {
    top: 100px;
    right: 20%;
    left: 20%;
    max-width: 600px;
    overflow-y: auto;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding-bottom: 15px;
  }

  &.-fullscreen {
    @include mobile { height: 100%; }

    @include desktop { max-height: 500px }
  }

  & > .content { width: 100%; }

  & > .close {
    position: absolute;
    top: 0;
    right: 0;
    margin: $c-modal-spacing;
  }
}

.c-modal-fade {
  @at-root {
    #{&}-enter-active,
    #{&}-leave-active {
      transition: opacity .4s ease;
    }
    #{&}-enter,
    #{&}-leave-to {
      opacity: 0;
    }
  }
}
</style>
