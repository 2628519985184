<template>
  <div
    data-cy="notification-visualization-card"
    class="notification-visualization-card"
  >
    <h2
      data-cy="notification-visualization-title"
      class="title"
    >
      Visualização
    </h2>
    <order-communication-preview-card
      data-cy="communication-preview-card"
      class="preview-card"
      :title="notification.title"
      :message="notification.message"
      :image="notification.image"
    />
  </div>
</template>

<script>
import OrderCommunicationPreviewCard from '@/components/General/OrderCommunicationPreviewCard.vue'
export default {

  components: {
    OrderCommunicationPreviewCard
  },
  props: {
    notification: {
      type: Object,
      default () { return {} }
    }
  }
}
</script>

<style lang="scss" scoped>
.notification-visualization-card {
  width: 100%;
  display: flex;
  padding: 32px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  border-radius: 4px;
  background: #FDFDFD;
  box-shadow: 0px 2px 4px 0px rgba(94, 102, 132, 0.12);

  .title {
    color: var(--bsicas-primary-base, #202950);
    font-size: 18px;
    font-weight: 500;
    line-height: 22px;
  }
  .preview-card {
    border-bottom: 1px solid #BFBFBF;
  }
}
</style>
