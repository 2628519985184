<template>
  <awards-resume-container />
</template>

<script>
import AwardsResumeContainer from '~/containers/Awards/AwardsResumeContainer'
export default {
  components: { AwardsResumeContainer }
}
</script>

<style>

</style>
