<template>
  <component
    v-on="$listeners"
    v-bind="$attrs"
    class="c-button"
    ref="button"
    :class="buttonClass"
    :is="component"
    :to="to ? to : null"
    :href="external && to"
    :disabled="disabled"
    @mouseenter="showPopover = true"
    @mouseleave="showPopover = false"
    data-cy="c-button"
  >
    <div class="content">
      <k-icon
        data-cy="back-button"
        v-if="icon && useKIcon"
        :icon="icon"
        :size="fixedIconSize"
      />
      <c-icon
        class="icon"
        v-else-if="icon"
        :icon="icon"
        :size="fixedIconSize"
      />
      <span :class="isBold ? 'bold text' : 'text'">
        <slot />
      </span>

      <c-popover
        v-if="popoverLabel && showPopover"
        class="popover"
        :position="['top', 'left']"
        :left-pixels="leftPixels"
        :arrow="false"
        :target="$refs.button"
      >
        <span v-html="popoverLabel" />
      </c-popover>
    </div>
  </component>
</template>

<script>
import CPopover from './CPopover'
import { KIcon } from 'kaledo-components'
export default {
  components: {
    CPopover,
    KIcon
  },

  props: {
    to: [Object, String],
    size: {
      type: [Number, String],
      default: 'md',
      validator: (size) => ['sm', 'md', 'lg', 'xl'].includes(size)
    },
    icon: String,
    useKIcon: {
      type: Boolean,
      default: false
    },
    after: Boolean,
    iconSize: {
      type: String,
      validator: (size) => ['sm', 'md', 'lg', 'xl'].includes(size)
    },
    isBold: Boolean,
    external: Boolean,
    primary: Boolean,
    alternative: Boolean,
    preview: {
      type: Boolean,
      default: false
    },
    previewAlternative: {
      type: Boolean,
      default: false
    },
    warning: Boolean,
    block: Boolean,
    fullSize: Boolean,
    ghost: Boolean,
    default: Boolean,
    defaultBorder: Boolean,
    pink: Boolean,
    pinkBorder: Boolean,
    borderOnly: Boolean,
    disabled: Boolean,
    noPadding: Boolean,
    iconOnly: Boolean,
    danger: Boolean,
    popoverLabel: String,
    leftPixels: String,
    onlyBottom: Boolean,
    noborder: Boolean
  },

  data () {
    return {
      showPopover: false
    }
  },

  computed: {
    component () {
      if (this.to && this.external) {
        return 'a'
      } else if (this.to) {
        return 'router-link'
      }
      return 'button'
    },

    hasText () {
      const hasText = !!this.$slots.default
      return hasText
    },

    buttonClass () {
      const buttonClass = [
        '-' + this.size, {
          '-primary': this.primary,
          '-preview': this.preview,
          '-preview-alternative': this.previewAlternative,
          '-alternative': this.alternative,
          '-warning': this.warning,
          '-icon': !!this.icon,
          '-icon-only': (!this.hasText && this.icon) || this.iconOnly,
          '-after': this.after,
          '-block': this.block,
          '-full-size': this.fullSize,
          '-ghost': this.ghost,
          '-pink': this.pink,
          '-pink-border': this.pinkBorder,
          '-default': this.default,
          '-default-border': this.defaultBorder,
          '-disabled': this.disabled,
          '-no-padding': this.noPadding,
          '-danger': this.danger,
          '-only-bottom': this.onlyBottom,
          '-no-border': this.noborder
        }]
      return buttonClass
    },

    fixedIconSize () {
      const sizes = {
        sm: '11',
        md: '15',
        lg: '19',
        xl: '17'
      }
      const fixedIconSize = this.iconSize ? sizes[this.iconSize] : sizes[this.size]
      return fixedIconSize
    }
  }
}
</script>

<style lang="scss">
@import '~@/styles/reference';

$c-sm-button-height: 20px;
$c-md-button-height: 30px;
$c-lg-button-height: 40px;
$c-xl-button-height: 48px;
.-no-border{
  border: 0px !important;
  &:hover{
    border: 0px !important;
  }
  @include desktop {
    &:hover{
      border: 0px !important;
    }
  }
}
.c-button {
  height: $c-md-button-height;
  border-radius: calc(#{$c-md-button-height} / 2);
  min-width: $c-md-button-height;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: 1px solid $border-color;
  line-height: $c-md-button-height;
  vertical-align: middle;
  text-decoration: none;
  background-color: transparent;
  transition: border-color .3s ease,
              box-shadow .3s ease;
  will-change: border, box-shadow;
  cursor: pointer;

  & > .content {
    display: flex;
    align-items: center;
  }

  &:not(.-block) > .content > .icon { margin: auto; }

  &:focus {
    box-shadow: none;
    outline: none;
  }

  @include desktop {
    &:hover {
      border-color: $secondary-color-placeholder;
      border-color: var(--color-secondary);
      box-shadow: 0 0 0 2px $secondary-color-placeholder;
      box-shadow: 0 0 0 2px var(--color-secondary);
    }
  }

  & > .content > .text {
    line-height: 1;
    text-transform: uppercase;
    color: $title-color;
    font-weight: 500;
    font-size: 12px;

    white-space: nowrap;

    &.bold { font-weight: bold; }

    &:not(:only-child) { margin-left: 5px; }
  }

  &.-sm {
    height: $c-sm-button-height;
    line-height: $c-sm-button-height;
    border-radius: calc(#{$c-sm-button-height} / 2);
    min-width: $c-sm-button-height;
  }

  &.-lg {
    height: $c-lg-button-height;
    line-height: $c-lg-button-height;
    border-radius: calc(#{$c-lg-button-height} / 2);
    min-width: $c-lg-button-height;
  }

  &.-xl {
    height: $c-xl-button-height;
    line-height: $c-xl-button-height;
    border-radius: calc(#{$c-xl-button-height} / 2);
    min-width: $c-xl-button-height;
  }

  &.-primary,
  &.-alternative,
  &.-warning {
    border: none;
    transition: background-color .3s ease;
    will-change: background-color;

    &:hover { box-shadow: none; }

    & > .content > .text { color: #fff; }
  }

  &.-primary {
    background-color: $primary-color-placeholder;
    background-color: var(--color-primary);

    @media screen and (min-width: 768px) {
      &:hover {
        background-color: rgba($primary-color-placeholder, .8);
        background-color: var(--color-primary-8);
      }
    }
    &.-icon > .content > .icon {
      fill: $secondary-color-placeholder;
      fill: var(--color-secondary);
    }
  }

  &.-preview {
    background-color: $primary-color-placeholder;

    @media screen and (min-width: 768px) {
      &:hover {
        background-color: rgba($primary-color-placeholder, .8);
      }
    }
    & > .content > .text {
      color: inherit;
    }
  }
  &.-preview-alternative {
    &.-icon > .content > .icon {
      fill: $primary-color-placeholder;
      fill: var(--color-primary);
    }
    & > .content > .text {
      color: inherit;
    }
  }

  &.-alternative {
    background-color: $secondary-color-placeholder;
    background-color: var(--color-secondary);

    &.-icon > .content > .icon {
      fill: $primary-color-placeholder;
      fill: var(--color-primary);
    }
    & > .content > .text {
      color: $primary-color-placeholder;
      color: var(--color-primary);
    }
    @media screen and (min-width: 768px) {
      &:hover {
        background-color: rgba($secondary-color-placeholder, .8);
        background-color: var(--color-secondary-8);
      }
    }
  }

  &.-warning {
    background-color: $terciary-color-placeholder;
    background-color: var(--color-terciary);

    &:hover {
      background-color: rgba($primary-color-placeholder, .8);
      background-color: var(--color-terciary-8);
    }
    &.-icon > .icon { fill: $icon-color; }
  }

  &.-danger {
    background-color: $like-color;

    &:hover {
      background-color: rgba($like-color, .8);
    }

    &.-icon > .icon { fill: $icon-color; }

    .text { color: white; }
  }

  &.-only-bottom {
    border:none;
    box-shadow: none;
    border-radius: 0;

    &:not(.-icon-only) {
      padding: 0 5px;
    }

    &:hover {
      transition: none;
      border-radius: 0;
      border-bottom: 2px solid var(--color-secondary);
      box-shadow: none;
    }
  }

  &.-after {
    & > .content {
      flex-flow: row-reverse;

      & > .icon {
        margin-left: 10px;
      }

      & > .text {
        margin-left: 0;
      }
    }
  }

  &:not(.-icon-only) {
    padding-left: 22px;
    padding-right: 22px;

    & > .content > .icon { flex-shrink: 0; }
  }

  &.-no-padding { padding: 0 !important; }

  &.-icon-only > .content {
    margin-left: 1px;

    & > .text { display: none; }
  }

  &.-block {
    height: 55px;
    background-color: $primary-color-placeholder;
    background-color: var(--color-primary);
    border-radius: 0;
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 5;

    & > .content > .icon {
      fill: $secondary-color-placeholder;
      fill: var(--color-secondary);
    }
    & > .content > .text {
      color: white;
      font-family: $title-font-family;
      font-weight: $title-font-weight;
      font-size: 12px;
      line-height: 16px;
    }

    & > .content > .icon { margin-right: 20px; }
  }

  &.-full-size {
    width: 100%;
  }

  &.-ghost {
    background-color: transparent;
    border: none;
  }

  &.-pink {
    background-color: $color-primary-login;
    border: none;

    &:hover {
      border: none;
      outline: none;
      box-shadow: none;
    }

    & > .content > .text {
      color: white;
      font-size: 12px;
      line-height: 16px;
    }
  }

    &.-pink-border {
      background-color: #fff;
      border: none;

      &:hover {
        border: $color-primary-login 1px solid;
        outline: none;
        box-shadow: 0 0 0 2px $color-primary-login;
      }
      &:focus {
        border: $color-primary-login 1px solid;
        outline: none;
        box-shadow: 0 0 0 2px $color-primary-login;
      }
    }

  &.-default {
    background-color: white;

    &:hover {
      border-color: $secondary-color-placeholder;
      border-color: var(--color-secondary);
      box-shadow: 0 0 0 2px $secondary-color-placeholder;
      box-shadow: 0 0 0 2px var(--color-secondary);

    }
  }

  &.-default-border {
    background-color: transparent;
    border: 1px solid rgba(18, 30, 72, 0.1);
    &:hover {
      border: 2px solid rgba(18, 30, 72, 0.1);
      box-shadow: 0 0 0 0 #fff;
      box-shadow: 0 0 0 0 #fff;
    }
  }

  &.-border-only { background-color: transparent; }

  &.-disabled {
    pointer-events: none;
    cursor: default;
    opacity: 0.5;
  }
}
</style>
