<template>
  <div
    class="campaign-information-important"
    :class="{ '-hidden': !isOpened }"
  >
    <c-title
      size="3"
      icon="warning-circle"
    >
      Informações importantes
    </c-title>

    <div class="information">
      <div
        ref="container"
        class="container"
      >
        <p
          ref="text"
          class="text"
          v-html="parse(information)"
        />
      </div>

      <c-button
        class="more"
        size="lg"
        v-if="contentGreater"
        @click="isOpened = !isOpened"
      >
        {{ isOpened ? 'Ver menos' : 'Ver mais' }}
      </c-button>
    </div>
  </div>
</template>

<script>
import parse from '@/modules/parse'

export default {
  props: {
    information: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      isOpened: false,
      textHeight: 0
    }
  },
  computed: {
    contentGreater () {
      const contentGreater = this.textHeight > 82
      return contentGreater
    }
  },
  mounted () {
    this.getHeight()
    window.addEventListener('resize', () => { this.getHeight() })
  },
  beforeDestroy () {
    window.removeEventListener('resize', () => { this.getHeight() })
  },
  methods: {
    parse,
    getHeight () {
      const { text } = this.$refs
      this.textHeight = (text && text.scrollHeight) || 0
    }
  }
}
</script>

<style lang="scss">
@import '~@/styles/reference';

$campaign-information-line-height: 16px;
$campaign-information-lines: 5;

.campaign-information-important {

  & > .c-title { padding: 20px 0; }

  & > .information {
    display: flex;
    flex-direction: column;

    & > .container {
      overflow: hidden;

      & > .text {
        font-size: 14px;

        &, & > * { line-height: 1.4 !important; }
        & a {
          color: $primary-color-placeholder;
          color: var(--color-primary);
          text-decoration: none;
        }
      }
    }

    & > .more {
      margin-top: 20px;
      width: 100px;
      align-self: flex-end;
    }
  }

  &.-hidden > .information {
    & > .container {
      max-height: calc(#{$campaign-information-line-height} * #{$campaign-information-lines});

      & > .text {
        -webkit-line-clamp: $campaign-information-lines;
        word-wrap: break-word;
        text-overflow: -o-ellipsis-lastline;
        display: -webkit-box;
        -webkit-box-orient: vertical;
      }
    }
  }
}
</style>
