<template>
  <div data-cy="c-paginate" class="c-paginate">
    <div data-cy="controls" class="controls">
      <c-button
        data-cy="paginate-first-page-button"
        icon="first"
        alternative
        class="button"
        :class="disablePrevButton ? '-disabled' : ''"
        :disabled="disablePrevButton"
        @click="updatePaginate('first-page')"
      />
      <c-button
        data-cy="paginate-previous-page-button"
        icon="previous"
        alternative
        class="button"
        :class="disablePrevButton ? '-disabled' : ''"
        :disabled="disablePrevButton"
        @click="updatePaginate('previous-page')"
      />

      <p data-cy="paginate-info" class="info">
        {{ info }}
      </p>

      <c-button
        data-cy="paginate-next-page-button"
        icon="next"
        class="button"
        :class="disableNextButton ? '-disabled' : ''"
        :disabled="disableNextButton"
        @click="updatePaginate('next-page')"
      />
      <c-button
        data-cy="paginate-last-page-button"
        icon="last"
        class="button"
        :class="disableNextButton ? '-disabled' : ''"
        :disabled="disableNextButton"
        @click="updatePaginate('last-page')"
      />
    </div>
  </div>
</template>

<script>
import reconstruct from 'reconstruct'

const DEFAULT_PAGINATE = {
  total: 0,
  per_page: 10,
  current_page: 1,
  last_page: 1,
  from: 0,
  to: 0
}

const properties = reconstruct(
  DEFAULT_PAGINATE,
  (placeholder, property) => ({
    [property]: function () {
      const value = this.paginate[property]
      const isPositive = !!(typeof value === 'number' && value >= 0)
      return isPositive ? value : placeholder
    }
  })
)

export default {
  props: {
    isFetching: Boolean,
    paginate: {
      type: Object,
      default: () => ({ ...DEFAULT_PAGINATE })
    }
  },
  computed: {
    ...properties,
    pages () {
      const pages = Math.ceil(this.total / this.per_page) || 1
      return pages
    },
    disablePrevButton () {
      const isDisabled = this.current_page <= 1
      return isDisabled || this.isFetching
    },
    disableNextButton () {
      const isDisabled = this.current_page >= this.pages
      return isDisabled || this.isFetching
    },
    info () {
      const info = `${this.from} - ${this.to} de ${this.total}`
      return info
    }
  },
  methods: {
    changePerPage (limit) {
      this.$emit('update-paginate', {
        limit,
        page: DEFAULT_PAGINATE.current_page
      })
    },
    updatePaginate (action) {
      const actions = {
        'first-page': 1,
        'previous-page': this.disablePrevButton ? 1 : this.current_page - 1,
        'next-page': this.disableNextButton ? this.pages : this.current_page + 1,
        'last-page': this.pages
      }
      this.$emit('update-paginate', {
        limit: this.per_page,
        page: actions[action] || DEFAULT_PAGINATE.current_page
      })
    }
  }
}
</script>

<style lang="scss">
@import '~@/styles/reference.scss';

$c-paginate-control-background: transparent;

.c-paginate {
  display: flex;
  align-items: center;

  & > .controls {
    @extend %c-paginate-controls;
    margin-left: auto;

    > .button {
      border: 1px solid #D8D8D8;
      background-color: #FDFDFD !important;

      > .content > .c-icon {
        fill: #5E6684;
      }
      &.-disabled > .content > .c-icon {
        fill: #D8D8D8;
      }
    }
  }
}

%c-paginate-controls {
  display: flex;
  align-items: center;

  & > * + * { margin-left: 5px; }

  & > .button {
    background-color: $c-paginate-control-background;
  }
}
</style>
