<template>
  <div class="partner-form">
    <div class="section-header">
      <c-title
        icon="plus-circle"
        icon-size="18"
      >
        {{ title }}
      </c-title>
    </div>

    <div class="section-controls">
      <c-input
        has-image
        size="md"
        type="file"
        class="input-file"
        name="partnerFile"
        placeholder="Selecionar imagem"
        key="1"
        :url="formData.logo"
        :exts="['jpg', 'jpeg', 'png']"
        :feedback-message="errors.first('partnerFile')"
        :feedback-show="errors.has('partnerFile')"
        @change="logoChanged"
      />
      <span class="limite">Limite de 100kb</span>

      <c-input
        required
        label="Parceiro"
        name="partnerName"
        placeholder="Nome do parceiro"
        key="2"
        class="form-control"
        :text="isDesktop ? 'Selecionar arquivo' : 'Selecionar arquivo de imagem'"

        :value="formData.name"
        @input="name => $emit('update-form', { name })"

        data-vv-as='"nome do parceiro"'
        data-vv-delay="10"
        v-validate="'required'"

        feedback-message="Campo obrigatório"
        :feedback-show="errors.has('partnerName')"
      />

      <c-select
        :searchable="false"
        placeholder="Selecione a(s) categoria(s)"
        class="form-control-select"
        form-label="Categorias"
        track-by="slug"
        label="name"
        multiple
        required
        is-multiselect

        max="2"
        :options="categories"
        :value="formData.categories"
        :feedback-show="showCategoriesError"
        feedback-message="Campo obrigatório"
        @input="changeCategory"
      />

      <c-input
        required
        label="Sobre o parceiro"
        name="description"
        placeholder="Insira informações sobre o parceiro (ex: seguimento, especialidade, tempo no mercado...)"
        class="form-control"
        textarea

        :value="formData.description"
        @input="description => $emit('update-form', { description })"

        data-vv-as='"descrição"'
        data-vv-delay="10"
        :count-characters="true"
        key="3"
        v-validate="'required|max:255'"
        :feedback-message="errors.first('description')"
        :feedback-show="errors.has('description')"
      />
    </div>
  </div>
</template>

<script>

export default {
  props: {
    formData: {
      type: Object,
      required: true
    },
    categories: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      isCategoriesDirty: false
    }
  },
  computed: {
    title () {
      return this.$route.params['slug'] ? 'Editar Parceiro' : 'Novo Parceiro'
    },
    showCategoriesError () {
      return this.isCategoriesDirty && this.formData.categories.length === 0
    }
  },
  methods: {
    changeCategory (categories) {
      this.isCategoriesDirty = true
      this.$emit('update-form', { categories })
    },
    logoChanged (uploadedFile) {
      this.$emit('upload-image', uploadedFile[0]['file'])
    }
  }
}
</script>

<style lang="scss">
@import '~@/styles/reference';

.partner-form {
  & >.section-controls {
    & >.input-file {
      margin-bottom: 2px;
      width: 100%;
      max-width: 440px;
    }

    & >.limite {
      color: $text-color;
      font-size: 10px;
      font-weight: 600;
      margin-bottom: 30px;
      text-transform: uppercase;
    }
  }
}
</style>
