<template>
  <new-notification-container />
</template>

<script>
import NewNotificationContainer from '~/containers/Communication/Notifications/NewNotificationContainer'
export default {
  components: { NewNotificationContainer }
}
</script>

<style>
</style>
