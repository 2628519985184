<template>
  <c-modal
    name="user-status-confirmation"
    class="user-status-confirmation"
    :is-opened="showModalDelete"
    @close="$emit('close')"
  >
    <c-character type="embarrassed" />

    <div class="container">
      <h3 class="title">
        {{ title }}
      </h3>
      <p class="sub-title">
        {{ message }}
      </p>
    </div>

    <div class="actions">
      <c-button
        size="lg"
        :primary="isManager || isSelf"
        @click="$emit('cancel')"
      >
        {{ isManager || isSelf ? 'Entendi' : 'Cancelar' }}
      </c-button>
      <c-button
        v-if="!(isManager || isSelf)"
        primary
        size="lg"
        class="action-delete"
        @click="$emit('delete')"
      >
        Excluir
      </c-button>
    </div>
  </c-modal>
</template>

<script>
import CModal from '@/components/CComponents/CModal'
import CCharacter from '@/components/CComponents/CCharacter'

const modalMessage =
{ self:
  `Você está habilitado como gestor e não pode ser excluído! Solicite
   que outro gestor desabilite sua permissão para acessar este painel
   e tente novamente.`,
manager:
  `Este usuário está habilitado como gestor e não pode ser excluído!
   Desabilite sua permissão para acessar este painel e tente novamente.`,
normal:
  `Se excluí-lo, ele não poderá acessar o clube e todas as informações
   armazenadas hoje serão deletadas permanentemente.`
}

export default {
  components: {
    CModal,
    CCharacter
  },

  props: {
    isSelf: Boolean,
    isManager: Boolean,
    showModalDelete: Boolean
  },

  computed: {
    title () {
      return this.isManager || this.isSelf
        ? 'Ops!'
        : 'Tem certeza que deseja excluir este usuário?'
    },
    message () {
      return this.isSelf
        ? modalMessage.self
        : this.isManager
          ? modalMessage.manager
          : modalMessage.normal
    }
  }
}
</script>

<style lang="scss">
@import '~@/styles/reference';

.user-status-confirmation {
  display: flex;
  justify-content: center;

  & > .content {
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 20px;

    & > .container {
      margin-top: 20px;

      & > .title {
        margin-top: 20px;
        text-align: center;
      }

      & > .sub-title {
        margin-top: 20px;
        text-align: center;
      }
    }

    & > .actions {
      margin-top: 20px;

      & >.action-delete {
        margin-left: 10px;
      }
    }
  }
}
</style>
