<template>
  <div class="title-progress">
    <c-title
      icon="crown"
      :show-border="false"
    >
      {{ title }}

      <div class="count-wrapper">
        <span class="count active">{{ active }}</span>
        <span class="count">/{{ total }}</span>
      </div>
    </c-title>
    <c-progress :value="percentage" />
  </div>
</template>

<script>
import CProgress from '@/components/CComponents/CProgress'

export default {
  name: 'CTitleProgress',
  components: {
    CProgress
  },
  props: {
    title: String,
    active: Number,
    total: Number,
    percentage: String
  }
}
</script>

<style lang='scss' scoped>
@import '~@/styles/reference';

.title-progress {
    flex-grow: 1;
    width: 100%;

    @include mobile {
      padding: 0 15px;
    }

    .c-title {
      margin-bottom: 9px;

      .count-wrapper {
        font-size: 24px;

        .count { font-weight: 200; }
        .count.active {
          font-weight: bold;
          color: $primary-color-placeholder;
          color: var(--color-primary);
        }
      }
    }
  }
</style>
