<template>
  <div
    data-cy="rewarded-users"
    class="rewarded-users"
  >
    <details-card :title="title">
      <template #header>
        <c-button
          data-cy="download-button"
          class="download-button"
          @click="downloadSheet"
        >
          <k-icon
            class="btn-icon"
            size="20"
            icon="download-save-circle"
          />
          <span class="text">Baixar Planilha</span>
        </c-button>
      </template>
      <template #content>
        <header>
          <c-chip
            data-cy="rewarded-users-count-card"
            :text="isAwards ? 'Usuários premiados:' : 'Quantidade de usuários:'"
            :value="users.length"
          />
          <c-chip
            v-if="!isAwards"
            data-cy="rewards-total-count-card"
            text="Total de moedas:"
            :value="total"
          />
        </header>
        <rewarded-users-table
          data-cy="rewarded-users-table"
          class="rewarded-users-table"
          :rows="users"
          :cols="tableCols"
          :total="total"
          :use-int-value="useIntValue"
          :is-awards="isAwards"
          :is-formatted="isFormatted"
        />
      </template>
    </details-card>
  </div>
</template>

<script>
import { KIcon } from 'kaledo-components'
import CButton from '@/components/CComponents/CButton'
import DetailsCard from '@/components/General/cards/DetailsCard'
import CChip from '@/components/CComponents/CChip'
import RewardedUsersTable from '@/components/General/tables/RewardedUsersTable'

export default {
  components: {
    CButton,
    KIcon,
    DetailsCard,
    CChip,
    RewardedUsersTable
  },
  props: {
    isAwards: Boolean,
    tableCols: Array,
    isFormatted: {
      type: Boolean,
      default: false
    },
    hasDate: {
      type: Boolean,
      default: true
    },
    users: {
      type: Array,
      default: () => []
    },
    total: Number,
    releaseDate: {
      type: Number,
      default: 0
    },
    useIntValue: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    },
    fileName: {
      type: String,
      default: ''
    }
  },
  methods: {
    downloadSheet () {
      this.csvMaker(this.users)
    },
    csvMaker (data) {
      const csvRows = []
      const headers = ['cpf', 'email', 'valor']
      csvRows.push(headers.join(','))
      data.forEach(row => {
        let joinedRow
        if (row.user && row.user.document) {
          joinedRow = [
            row.user.document,
            row.user.email,
            this.isAwards ? parseFloat(row.value / 100).toFixed(2) : row.value / 100
          ].join(',')
        } else {
          joinedRow = [
            row.document,
            row.email,
            this.isAwards ? parseFloat(row.value).toFixed(2) : row.value
          ].join(',')
        }
        csvRows.push(joinedRow)
      })

      this.download(csvRows.join('\n'))
    },
    setFileTitle () {
      return `${this.fileName}`
    },
    download (data) {
      const blob = new Blob([data], { type: 'text/csv' })
      const url = window.URL.createObjectURL(blob)
      const a = document.createElement('a')
      let title = this.title.replaceAll('/', '-')
      if (this.fileName.length !== 0) {
        title = this.setFileTitle()
      }
      a.setAttribute('href', url)
      a.setAttribute('download', `${title}.csv`)
      a.click()
    }
  }
}
</script>

<style lang="scss">
@import '~@/styles/reference';

.rewarded-users {
  width: 100%;
  height: 100%;

  .details-card {
    max-width: 100% !important;

    > .details-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;

      > .title {
        max-width: 200px;
      }

      > .download-button {
        width: 180px;
        height: 40px;
        border-radius: 30px;
        border: 1px solid rgba(94, 102, 132, 1);
        border: 1px solid var(--color-secondary);
        margin-top: 16px;
        color: #5E6684;

        .btn-icon {
          margin-right: 5px;
        }

        .text {
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }

    > .details-content {
      header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 15px;
        margin-top: 10px;

        .info-card {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 26px;
          padding: 4px 8px;
          background: #FDFDFD;
          box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.16);
          border-radius: 4px;
          color: #202950;
          font-size: 11px;
          text-transform: uppercase;
        }
      }
    }
  }
}
</style>
